import type { ValueOf } from '@jane/business-admin/util';
import {
  AlertIcon,
  CheckCircledIcon,
  EyeIcon,
  Flex,
  HideEyeIcon,
  Typography,
} from '@jane/shared/reefer';

const EmptyStateVariant = {
  hidden: 'hidden',
  noProductsFound: 'noProductsFound',
  published: 'published',
  unpublished: 'unpublished',
} as const;

type EmptyStateVariant = ValueOf<typeof EmptyStateVariant>;

export const EmptyState = ({ variant }: { variant: EmptyStateVariant }) => {
  return (
    <Flex
      width="auto"
      height="100%"
      mx={40}
      justifyContent="center"
      alignItems="center"
      border="grays-light"
      borderRadius="lg"
    >
      <Flex
        my={128}
        mx={40}
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
      >
        {variant === EmptyStateVariant.hidden ? (
          <HideEyeIcon size="xxl" mb={24} />
        ) : variant === EmptyStateVariant.published ? (
          <EyeIcon size="xxl" mb={24} />
        ) : variant === EmptyStateVariant.unpublished ? (
          <CheckCircledIcon size="xxl" mb={24} />
        ) : variant === EmptyStateVariant.noProductsFound ? (
          <AlertIcon size="xxl" mb={24} />
        ) : null}
        <Flex flexDirection="column">
          <Typography mb={8} variant="header-bold" textAlign="center">
            {variant === EmptyStateVariant.noProductsFound
              ? 'No products found'
              : `No ${variant} products`}
          </Typography>
          <Typography
            variant="body"
            mb={24}
            color="grays-dark"
            textAlign="center"
          >
            {variant === EmptyStateVariant.noProductsFound
              ? "Hmm, we couldn't find any products that matched your search. Please broaden your filters and try again."
              : `When your store has ${variant} products, they will display here.`}
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};

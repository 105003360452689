import capitalize from 'lodash/capitalize';

import { useDebouncedTrack } from '@jane/business-admin/hooks';
import type {
  CustomLineageLabelsV2,
  MenuProductForProductsTable,
  UnpublishedProduct,
} from '@jane/business-admin/types';
import {
  BODY_LINE_HEIGHT,
  EventNames,
  ModalNames,
  SettingNames,
} from '@jane/business-admin/util';
import {
  Flex,
  Form,
  List,
  Skeleton,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

import { CategoryAndSubcategorySelect } from '../../../../CategoryAndSubcategorySelect';
import { RevertableInput } from '../../../../RevertableInput';
import { RevertableInputWrapper } from '../../../../RevertableInputWrapper';
import { BrandsTypeAhead } from './BrandsTypeAhead';
import { LineageField } from './LineageField';

export const LoadingDetailsCard = () => {
  return (
    <>
      <Typography variant="header-bold" mb={40}>
        Details
      </Typography>
      <Flex flexDirection="column">
        <Flex mb={40} width="100%">
          {Array(3)
            .fill(null)
            .map((_, index) => {
              return (
                <Skeleton animate width="100%" key={`loading_1${index}`}>
                  <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="80%" />
                  <Skeleton.Bone height={BODY_LINE_HEIGHT} width="80%" />
                </Skeleton>
              );
            })}
        </Flex>
        <Flex mb={40} width="100%">
          <Skeleton animate width="100%" key={`loading_name_input`}>
            <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
            <Skeleton.Bone height={48} width="100%" />
          </Skeleton>
        </Flex>
        <Flex mb={40} width="100%">
          <Skeleton animate width="100%" key={`loading_brand`}>
            <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="20%" />
            <Skeleton.Bone height={BODY_LINE_HEIGHT} width="20%" />
          </Skeleton>
        </Flex>
        <Flex mb={40} width="100%">
          {Array(2)
            .fill(null)
            .map((_, index) => {
              return (
                <Skeleton animate width="100%" key={`loading_2${index}`}>
                  <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="60%" />
                  <Skeleton.Bone height={BODY_LINE_HEIGHT} width="80%" />
                </Skeleton>
              );
            })}
        </Flex>
        <Flex mb={40} gap={24} width="100%">
          {Array(2)
            .fill(null)
            .map((_, index) => {
              return (
                <Skeleton animate width="100%" key={`loading_3${index}`}>
                  <Skeleton.Bone
                    height={BODY_LINE_HEIGHT}
                    mb={12}
                    width="40%"
                  />
                  <Skeleton.Bone height={48} width="100%" />
                </Skeleton>
              );
            })}
        </Flex>
        <Flex mb={40} width="100%">
          <Skeleton animate width="100%" key={`loading_description_input`}>
            <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
            <Skeleton.Bone height={76} width="100%" />
          </Skeleton>
        </Flex>
        <Flex mb={40} width="100%">
          {Array(2)
            .fill(null)
            .map((_, index) => {
              return (
                <Skeleton animate width="100%" key={`loading_4${index}`}>
                  <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="60%" />
                  <Skeleton.Bone height={BODY_LINE_HEIGHT} width="80%" />
                </Skeleton>
              );
            })}
        </Flex>
      </Flex>
    </>
  );
};

export const DetailsCard = ({
  attributes,
  menuProduct,
  customLabels,
  isSelfPublished,
  isUnpublished,
  tableType,
}: {
  attributes: any;
  customLabels?: CustomLineageLabelsV2 | null;
  isEditable: boolean;
  isSelfPublished: boolean;
  isUnpublished?: boolean;
  menuProduct: MenuProductForProductsTable | UnpublishedProduct;
  tableType: 'published' | 'hidden' | 'unpublished' | string;
}) => {
  const { setValue, watch } = useFormContext();
  const debouncedTrack = useDebouncedTrack(1000);
  const {
    default_product_description = '',
    pos_sku = [],
    pos_data = {},
    product_id = '',
    name = '',
    status = '',
    category = '',
    subcategory = '',
  } = {
    ...menuProduct,
  };

  const skuValues: any = pos_data.sku ? [['', pos_data.sku]] : pos_sku;

  const categoryWatch = watch('category');

  // Dev note: decide number of rows based on overall word count for a description
  const descriptionRowsCount =
    attributes.description?.split(/[ ,]+/).filter(Boolean).length > 30 ? 7 : 2;

  return (
    <>
      <Typography variant="header-bold" mb={40}>
        Details
      </Typography>
      <Flex mb={40} gap={24}>
        {!isUnpublished && product_id && (
          <Flex width="50%">
            <Flex flexDirection="column" width="50%">
              <Typography variant="caps" color="grays-mid" mb={2}>
                Jane ID
              </Typography>
              <Flex>{product_id}</Flex>
            </Flex>
          </Flex>
        )}
        <Flex width="50%">
          <Flex flexDirection="column" width="50%">
            <Typography variant="caps" color="grays-mid" mb={2}>
              Status
            </Typography>
            <Flex>{status || 'N/A'}</Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" width="50%">
          <Typography variant="caps" color="grays-mid" mb={2}>
            POS SKU
          </Typography>
          <List label="SKU">
            {!skuValues.length
              ? 'N/A'
              : skuValues.map(
                  ([variant, sku]: [string, string], idx: number) => (
                    <List.Item px={0} py={4} key={`${variant || 'sku'}-${idx}`}>
                      {[variant, sku].filter((s) => s).join(': ') || 'N/A'}
                    </List.Item>
                  )
                )}
          </List>
        </Flex>
      </Flex>
      <Flex mb={40} gap={24}>
        <RevertableInput
          label="Name"
          name="name"
          defaultValue={attributes.defaultProductName}
          initialValue={name || attributes.defaultProductName}
          required={true}
          notRevertable={!attributes.defaultProductName}
          fromPOS={attributes.fromPOS}
          onChange={(value) => {
            debouncedTrack({
              event: EventNames.ModifiedSetting,
              modal_name: ModalNames.EditProduct,
              revert: !value || value === 'Indica',
              setting_name:
                SettingNames[
                  `${capitalize(
                    tableType
                  )}ProductName` as keyof typeof SettingNames
                ],
            });
          }}
        />
      </Flex>
      <Flex flexDirection="column" mb={40}>
        {isUnpublished || isSelfPublished ? (
          <BrandsTypeAhead
            isLoading={false}
            productBrand={attributes.brand || ''}
            onChange={(val: any) =>
              debouncedTrack({
                event: EventNames.ModifiedSetting,
                modal_name: ModalNames.EditProduct,
                revert: !val || val === attributes.brand,
                setting_name:
                  SettingNames[
                    `${capitalize(tableType)}Brand` as keyof typeof SettingNames
                  ],
              })
            }
          />
        ) : (
          <>
            <Typography variant="caps" color="grays-mid" mb={2}>
              Brand
            </Typography>
            <Flex>{attributes.brand || 'N/A'}</Flex>
          </>
        )}
      </Flex>
      <Flex mb={40} gap={24}>
        {isUnpublished || isSelfPublished ? (
          <Flex flexDirection="column" width="100%">
            <CategoryAndSubcategorySelect
              allowAllOption={false}
              categoryProps={{
                name: 'category',
                placeholder: 'Select a category',
                defaultValue: pos_data.kind || '',
                'data-testid': 'details-category-select',
                onChange: (selectedCategory: string) => {
                  if (selectedCategory !== (pos_data.kind || category)) {
                    // Reset subcategory when category is selected, also works to clear field on load
                    setValue('subcategory', '');
                    debouncedTrack({
                      event: EventNames.ModifiedSetting,
                      modal_name: ModalNames.EditProduct,
                      revert:
                        !selectedCategory || selectedCategory === pos_data.kind,
                      setting_name:
                        SettingNames[
                          `${capitalize(
                            tableType
                          )}Category` as keyof typeof SettingNames
                        ],
                    });
                  }
                  setValue(
                    'lineage',
                    selectedCategory === 'flower' ? 'cbd' : 'none'
                  );
                },
              }}
              subcategoryProps={{
                name: 'subcategory',
                required: false,
                defaultValue: pos_data?.root_subtype || '',
                onChange: (value: string) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.EditProduct,
                    revert: !value || value === pos_data?.root_subtype,
                    setting_name:
                      SettingNames[
                        `${capitalize(
                          tableType
                        )}Subcategory` as keyof typeof SettingNames
                      ],
                  });
                },
              }}
            />
            {isUnpublished && categoryWatch !== pos_data.kind && (
              <Flex>
                <Flex width="50%">
                  <Typography mt={12} color="grays-mid">{`POS Default: ${
                    pos_data.kind && capitalize(pos_data.kind)
                  }`}</Typography>
                </Flex>
                {pos_data?.root_subtype && (
                  <Flex width="50%" pl={12}>
                    <Typography
                      mt={12}
                      color="grays-mid"
                    >{`POS Default: ${capitalize(
                      pos_data?.root_subtype
                    )}`}</Typography>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        ) : (
          <>
            <Flex flexDirection="column" width="50%">
              <Typography variant="caps" color="grays-mid" mb={2}>
                Category
              </Typography>
              {capitalize(category) || 'N/A'}
            </Flex>
            <Flex flexDirection="column" width="50%">
              <Typography variant="caps" color="grays-mid" mb={2}>
                Sub Category
              </Typography>
              {(subcategory && capitalize(subcategory)) || 'N/A'}
            </Flex>
          </>
        )}
      </Flex>
      <Flex mb={40} gap={24}>
        {customLabels && (
          <LineageField
            tableType={tableType}
            isUnpublished={isUnpublished}
            lineageAttribute={attributes.lineage}
            customLabels={customLabels}
          />
        )}
        <Flex flexDirection="column" width="50%">
          <RevertableInput
            label="Brand Subtype"
            name="brand_subtype"
            defaultValue={attributes.productBrandSubtype ?? ''}
            initialValue={attributes.productBrandSubtype ?? undefined}
            fromPOS={attributes.fromPOS}
            notRevertable={!attributes.productBrandSubtype}
            onChange={(value) => {
              debouncedTrack({
                event: EventNames.ModifiedSetting,
                modal_name: ModalNames.EditProduct,
                revert: !value || value === attributes.productBrandSubtype,
                setting_name:
                  SettingNames[
                    `${capitalize(
                      tableType
                    )}BrandSubtype` as keyof typeof SettingNames
                  ],
              });
            }}
          />
        </Flex>
      </Flex>
      <Flex mb={40}>
        <RevertableInputWrapper
          defaultValue={default_product_description || attributes.description}
          initialValue={attributes.description}
          name="description"
          fromPOS={attributes.fromPOS}
        >
          <Form.TextAreaField
            defaultValue={attributes.description}
            label="Description"
            name="description"
            rows={descriptionRowsCount}
            width={'100%'}
            onChange={(value) => {
              debouncedTrack({
                event: EventNames.ModifiedSetting,
                modal_name: ModalNames.EditProduct,
                revert: !value || value === attributes.description,
                setting_name:
                  SettingNames[
                    `${capitalize(
                      tableType
                    )}ProductDescription` as keyof typeof SettingNames
                  ],
              });
            }}
          />
        </RevertableInputWrapper>
      </Flex>
      {isUnpublished ? (
        <Flex mb={40} gap={24}>
          <RevertableInput
            label="Size"
            name="size"
            defaultValue={attributes.amount || ''}
            initialValue={attributes.amount || undefined}
            fromPOS={attributes.fromPOS}
            notRevertable={!attributes.amount}
            onChange={(value) => {
              debouncedTrack({
                event: EventNames.ModifiedSetting,
                modal_name: ModalNames.EditProduct,
                revert: !value || value === attributes.amount,
                setting_name:
                  SettingNames[
                    `${capitalize(
                      tableType
                    )}ProductSize` as keyof typeof SettingNames
                  ],
              });
            }}
          />
          <RevertableInput
            label="Dosage"
            name="dosage"
            defaultValue={attributes.dosage || ''}
            initialValue={attributes.dosage || undefined}
            fromPOS={attributes.fromPOS}
            notRevertable={!attributes.dosage}
            onChange={(value) => {
              debouncedTrack({
                event: EventNames.ModifiedSetting,
                modal_name: ModalNames.EditProduct,
                revert: !value || value === attributes.dosage,
                setting_name:
                  SettingNames[
                    `${capitalize(
                      tableType
                    )}ProductDosage` as keyof typeof SettingNames
                  ],
              });
            }}
          />
        </Flex>
      ) : (
        <Flex mb={40} gap={24}>
          <Flex flexDirection="column" width="50%">
            <Typography variant="caps" color="grays-mid" mb={2}>
              Size
            </Typography>
            <Flex>{attributes.amount || 'N/A'}</Flex>
          </Flex>
          <Flex width="50%">
            <Flex flexDirection="column" width="50%">
              <Typography variant="caps" color="grays-mid" mb={2}>
                Dosage
              </Typography>
              <Flex>{attributes.dosage || 'N/A'}</Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { NavigationSourceIds } from '@jane/business-admin/util';
import { EventNames, normalizePath, track } from '@jane/business-admin/util';

export const useNavigateAndTrack = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { storeId } = useContext(StoreDetailsContext);

  return (
    navigatePath: string,
    sourceId: NavigationSourceIds,
    storeIdProp?: string | number
  ) => {
    const storeIdToSearch = storeIdProp?.toString() || storeId;

    track({
      event: EventNames.Navigated,
      from_url: normalizePath(pathname, storeIdToSearch),
      to_url: normalizePath(navigatePath, storeIdToSearch),
      trigger_source_id: sourceId,
    });
    navigate(navigatePath);
  };
};

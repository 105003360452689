import compact from 'lodash/compact';
import get from 'lodash/get';
import join from 'lodash/join';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  useFetchCategories,
  useFetchStoreCategories,
} from '@jane/business-admin/data-access';
import { ModalNames } from '@jane/business-admin/util';
import type {
  KindCondition,
  ProductKind,
  StoreCategory,
} from '@jane/shared/models';
import { isSoldByWeight } from '@jane/shared/util';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import { ConditionType } from '../../form';
import { convertRulesToString } from '../convertRulesToString';
import { ConditionByModal } from '../generic_components/ConditionByModal';
import { LoadingCondition } from '../generic_components/LoadingCondition';
import { CategorySelectInputModal } from './CategorySelectInputModal';
import { CategorySelectInputModalProvider } from './CategorySelectInputModalProvider';
import { convertCategoryConditionToRules, transformData } from './util/rules';

export type CategoryOption = {
  label: string;
  subItems?: CategoryOption[];
  value: string;
};

export type CategorySelectInputProps = {
  categoryOptions: CategoryOption[];
  onDirty?: (dirty: boolean) => void;
  onSave: (selectedCategories: any) => void;
  selectedCategories: string[] | CategoryOption[];
  useDefaultModal?: boolean;
};

export const CategoryConditions = ({
  fieldPrefix,
  isGlobalSpecial,
  type,
  onConditionsUpdate,
}: {
  fieldPrefix: string;
  isGlobalSpecial?: boolean;
  onConditionsUpdate: (
    conditions: KindCondition | [],
    includesOrExcludes: 'includes' | 'excludes'
  ) => void;
  type: ConditionType.Categories | ConditionType.ExcludeCategories;
}) => {
  const { id: storeId = '' } = useParams<'id'>();
  const [categoryConditionsModalOpen, setCategoryConditionsModalOpen] =
    useState(false);
  const [selectedCategories, setSelectedCategories] = useState<StoreCategory[]>(
    []
  );

  const useGetConditions = () => {
    const { data: allCategories, isFetching: isAllCategoriesFetching } =
      useFetchStoreCategories(storeId, !isGlobalSpecial);

    const { data: globalCategories, isFetching: isGlobalCategoriesFetching } =
      useFetchCategories(isGlobalSpecial || false);

    return {
      data: isGlobalSpecial ? globalCategories : allCategories,
      isFetching: isGlobalSpecial
        ? isGlobalCategoriesFetching
        : isAllCategoriesFetching,
    };
  };

  const { data: allCategories, isFetching } = useGetConditions();

  const { watch } = useFormContext();

  const rulesWatch = watch(`${fieldPrefix}.rules`);
  const conditionsWatch = watch(`conditions`);
  const specialTypeWatch = watch('special_type');

  const includesOrExcludes =
    type === ConditionType.Categories ? 'includes' : 'excludes';

  const rules =
    rulesWatch && rulesWatch[includesOrExcludes]
      ? rulesWatch[includesOrExcludes]
      : null;

  const categoryMap = useMemo(() => {
    return get(rulesWatch, `${includesOrExcludes}.0.kinds`, []);
  }, [convertRulesToString(rulesWatch), JSON.stringify(conditionsWatch)]);

  const categoryOptions = useMemo(() => {
    if (!allCategories) return [];

    let renderedKinds = allCategories;

    if (specialTypeWatch === 'bulk_pricing') {
      renderedKinds = allCategories.filter(
        (k): k is { kind: ProductKind; subtypes: any[] } => {
          return isSoldByWeight(k.kind as ProductKind);
        }
      );
    }

    return transformData(renderedKinds);
  }, [storeId, allCategories, specialTypeWatch]);

  const selectedCategoryWithDetails = allCategories?.filter(
    (category: StoreCategory) => categoryMap?.includes(category)
  );

  const conditionsToDisplay = categoryMap?.reduce(
    (acc: any[], category: any) => {
      if (
        specialTypeWatch === 'bulk_pricing' &&
        !isSoldByWeight(category.kind)
      ) {
        return acc;
      }

      const parts = compact([
        category.kind,
        category.root_subtype,
        category.brand_subtype,
      ]);

      const value = join(parts, '/');
      acc.push({ value, label: value });

      return acc;
    },
    []
  );

  const onSubmit = (data: any) => {
    const formattedKinds = convertCategoryConditionToRules(data);
    onConditionsUpdate(formattedKinds, includesOrExcludes);

    setSelectedCategories(conditionsToDisplay);
    setCategoryConditionsModalOpen(false);
  };

  useEffect(() => {
    if (!selectedCategoryWithDetails) {
      return;
    }

    setSelectedCategories(selectedCategoryWithDetails);
  }, [JSON.stringify(selectedCategoryWithDetails)]);

  return (
    <>
      {!conditionsToDisplay ? (
        <LoadingCondition />
      ) : (
        <ConditionByModal
          onOpenModal={() => setCategoryConditionsModalOpen(true)}
          type={type}
          conditions={conditionsToDisplay}
          conditionsLoading={isFetching}
          onConditionsUpdate={(data) => onSubmit(data)}
        />
      )}
      <ConfirmWrapperWithTracking
        open={categoryConditionsModalOpen}
        setOpen={() => {
          setCategoryConditionsModalOpen(false);
        }}
        hasChanges={selectedCategories.length !== conditionsToDisplay?.length}
        modalName={ModalNames.CategoryConditions}
        overlayClose={true}
      >
        <CategorySelectInputModalProvider rules={rules?.[0]?.kinds}>
          <CategorySelectInputModal
            categoryOptions={categoryOptions}
            onSave={onSubmit}
          />
        </CategorySelectInputModalProvider>
      </ConfirmWrapperWithTracking>
    </>
  );
};

import { Flex, Form, Typography } from '@jane/shared/reefer';

export const GoogleSSOSection = () => {
  return (
    <>
      <Typography variant="body-bold">Google SSO</Typography>
      <Typography color="grays-mid" mb={24}>
        These fields can be used to configure Google SSO.
      </Typography>
      <Flex flexDirection="column">
        <Flex alignItems="flex-start" gap={24}>
          <Form.TextField
            disableMobileInputStyling
            type="text"
            autocomplete="off"
            label="Google SSO Client ID"
            name="google_sso_client_id"
          />
          <Form.TextField
            disableMobileInputStyling
            type="text"
            autocomplete="off"
            label="Google SSO Client Secret"
            name="google_sso_client_secret"
          />
        </Flex>
      </Flex>
    </>
  );
};

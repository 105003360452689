import * as t from 'io-ts';

import { tReservationMode } from '@jane/shared/models';

export const tMaxOrderSetting = t.interface({
  day_of_week: t.string,
  enabled: t.boolean,
  id: t.number,
  max_order_limit: t.number,
  reservation_mode: tReservationMode,
  start_time: t.string,
});
export type MaxOrderSetting = t.TypeOf<typeof tMaxOrderSetting>;

import styled from '@emotion/styled';
import type { SVGProps } from 'react';

const BaseSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.8889 12.8878L18.7222 26.8878L20.7919 20.7908L26.8889 18.7212L12.8889 12.8878Z"
      stroke="#0E0E0E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1384 21.1374L26.0886 26.0875"
      stroke="#0E0E0E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.775 5L11.6815 8.37983M8.381 11.6803L5 10.7738M18.664 7.11283L16.1883 9.5885M9.5885 16.1872L7.11517 18.6628"
      stroke="#0E0E0E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClickIcon = styled(BaseSvg)({
  height: '32px',
  width: '32px',
  flexShrink: 0,
});

export default ClickIcon;

import styled from '@emotion/styled';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import type { Brand } from '@jane/business-admin/types';
import {
  CheckboxField,
  Flex,
  PartnerLogo,
  Typography,
} from '@jane/shared/reefer';

type BrandCheckboxRowProps = {
  brand: Brand;
  'data-testid'?: string;
  divider?: boolean;
  onClick: (brand: Brand) => void;
  selected: boolean;
};

const Container = styled.div<{ divider?: boolean }>(({ divider }) => ({
  cursor: 'pointer',
  boxShadow: divider ? 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)' : undefined,
}));

export const BrandCheckboxRow = forwardRef(
  (
    {
      brand,
      'data-testid': testId,
      divider,
      selected,
      onClick,
    }: BrandCheckboxRowProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Container
        aria-label={`select brand ${brand.name}`}
        onClick={() => onClick?.(brand)}
        role="button"
        data-testid={testId}
        divider={divider}
        ref={ref}
      >
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          py={12}
          px={24}
        >
          <Flex flexDirection="row" alignItems="center" gap={16}>
            <PartnerLogo
              name={brand.name}
              variant="brand"
              size="xs"
              image={brand.logoUrl}
            />
            <Typography variant="body-bold">{brand.name}</Typography>
          </Flex>
          <Flex flexDirection="row" alignItems="center">
            <CheckboxField
              label={`Select brand ${brand.name}`}
              name={`${brand.id}-checkbox`}
              checked={selected}
              labelHidden
            />
          </Flex>
        </Flex>
      </Container>
    );
  }
);

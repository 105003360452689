import truncate from 'lodash/truncate';
import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  EventNames,
  ExternalLinkSourceIds,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import type { CheckoutAgreementV2 } from '@jane/shared/models';
import type { FlexProps } from '@jane/shared/reefer';
import { Flex, Link, Typography } from '@jane/shared/reefer';

type AgreementDetailsProps = Pick<
  CheckoutAgreementV2,
  'external_link' | 'label'
> & {
  showHeading?: boolean;
  truncateLabelTo: number | null;
};

export const AgreementDetails = ({
  label,
  external_link,
  truncateLabelTo = null,
  showHeading = false,
  ...flexProps
}: AgreementDetailsProps & FlexProps) => {
  const { storeId } = useContext(StoreDetailsContext);
  const { pathname } = useLocation();

  const truncatedLabel = useMemo(() => {
    if (!truncateLabelTo) return label;
    return truncate(label, {
      length: truncateLabelTo,
      separator: /\s/,
    });
  }, [label, truncateLabelTo]);

  const trackExternalLink = useCallback(
    (to_url: string) => {
      track({
        event: EventNames.OpenExternalLink,
        from_url: normalizePath(pathname, storeId),
        to_url,
        trigger_source_id: ExternalLinkSourceIds.CheckoutAgreementDetails,
      });
    },
    [pathname, storeId]
  );

  return (
    <Flex flexDirection="column" {...flexProps}>
      {showHeading && (
        <Typography variant="caps" color="grays-mid" mb={8}>
          Details
        </Typography>
      )}
      <Typography whiteSpace="pre-wrap">{truncatedLabel}</Typography>
      {external_link && (
        <Link
          onClick={() => trackExternalLink(external_link)}
          href={external_link}
          mt={24}
        >
          {external_link}
        </Link>
      )}
    </Flex>
  );
};

export type LeadTimeUnit = 'days' | 'hours' | 'minutes';

const UNIT_TO_MINUTES: { [K in LeadTimeUnit]: number } = {
  days: 24 * 60,
  hours: 60,
  minutes: 1,
};
const UNITS = Object.keys(UNIT_TO_MINUTES) as LeadTimeUnit[];

export const updateDurationOnChange = (
  unitToUpdate: LeadTimeUnit,
  value: number,
  duration: any
) => {
  return UNITS.reduce((prev, currUnit) => {
    const minuteMultiplier = UNIT_TO_MINUTES[currUnit];
    if (unitToUpdate === currUnit) {
      const unitValue = (value && value >= 0 ? value : null) || 0;
      return prev + minuteMultiplier * unitValue;
    }
    return prev + minuteMultiplier * duration[currUnit]();
  }, 0);
};

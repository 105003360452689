import pluralise from 'pluralise';
import type { ReactNode } from 'react';

import type { StaffMemberWithIdAndStoreNames } from '@jane/business-admin/types';
import { formatRole } from '@jane/business-admin/util';
import type { FlexProps } from '@jane/shared/reefer';
import { ErrorIcon, Flex, Tag, Typography } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import { CollapsedBorderTable } from '../TableWithBorderSeparator';

type TableCellProps = FlexProps & { children: ReactNode; width?: string };

const HeaderCell = ({ children, width, ...flexProps }: TableCellProps) => (
  <Table.HeaderCell width={width}>
    <Flex {...flexProps}>{children}</Flex>
  </Table.HeaderCell>
);

const PaddedCell = ({ children, ...flexProps }: TableCellProps) => (
  <Table.Cell>
    <Flex py={24} {...flexProps}>
      {children}
    </Flex>
  </Table.Cell>
);

const formatStoreName = (
  storeNames: string[],
  index: number,
  remainingStores: number
) =>
  [
    storeNames[index],
    storeNames[index + 1] ? ',' : '',
    index === storeNames.length - 1 && remainingStores > 0
      ? `, and ${remainingStores} more`
      : '',
  ]
    .filter(Boolean)
    .join('');

const rowTextColor = (staffMember: StaffMemberWithIdAndStoreNames) =>
  staffMember?.registered ? 'grays-dark' : 'grays-mid';

export const StaffList = ({
  onRowClick,
  staffMembers = [],
}: {
  onRowClick: (id: number) => void;
  staffMembers: StaffMemberWithIdAndStoreNames[];
}) => {
  const countLabel = `${staffMembers?.length ?? 0} ${pluralise(
    staffMembers?.length ?? 0,
    'staff member'
  )}`;

  return (
    <CollapsedBorderTable>
      <Table.Head>
        <Table.Row>
          <HeaderCell width="20%" pl={24}>
            <Typography variant="caps" color="grays-mid">
              {countLabel}
            </Typography>
          </HeaderCell>
          <HeaderCell width="20%">
            <Typography variant="caps" color="grays-mid">
              Role
            </Typography>
          </HeaderCell>
          <HeaderCell width="20%">
            <Typography variant="caps" color="grays-mid">
              Store
            </Typography>
          </HeaderCell>
          <HeaderCell>
            <Typography variant="caps" color="grays-mid">
              Phone number
            </Typography>
          </HeaderCell>
          <HeaderCell width="20%">
            <Typography variant="caps" color="grays-mid">
              Status
            </Typography>
          </HeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {staffMembers.length ? (
          staffMembers.map((staffMember) => {
            const storeNames = staffMember.store_names ?? [];
            const remainingStores =
              staffMember.store_ids.length - storeNames.length;
            const textColor = rowTextColor(staffMember);

            return (
              <Table.Row
                key={staffMember.id}
                onClick={() => onRowClick(staffMember.id as number)}
              >
                <PaddedCell pl={24}>
                  <Flex justifyContent="space-between" width="100%">
                    <Flex flexDirection="column">
                      <Typography color={textColor} variant="body-bold">
                        {staffMember.name ?? ''}
                      </Typography>
                      <Typography color={textColor}>
                        {staffMember.email}
                      </Typography>
                    </Flex>
                    {!staffMember.registered && (
                      <Flex justifyContent="flex-end" pl={16} width="50%">
                        <ErrorIcon
                          title="User has not yet registered"
                          color="error"
                        />
                      </Flex>
                    )}
                  </Flex>
                </PaddedCell>
                <PaddedCell>
                  <Typography color={textColor}>
                    {formatRole(staffMember)}
                  </Typography>
                </PaddedCell>
                <PaddedCell flexDirection="column">
                  {storeNames.map(
                    (name, index) =>
                      name.length > 0 && (
                        <Typography
                          color={textColor}
                          key={`staff-${staffMember.id}-store-${index}`}
                        >
                          {formatStoreName(storeNames, index, remainingStores)}
                        </Typography>
                      )
                  )}
                </PaddedCell>
                <PaddedCell>
                  <Typography color={textColor}>{staffMember.phone}</Typography>
                </PaddedCell>
                <PaddedCell>
                  {staffMember?.registered ? (
                    <Tag
                      label="Registered"
                      color="grays-mid"
                      background="grays-ultralight"
                    />
                  ) : (
                    <Tag
                      label="Unregistered"
                      background="error-light"
                      color="error-dark"
                    />
                  )}
                </PaddedCell>
              </Table.Row>
            );
          })
        ) : (
          <Table.Row>
            <td colSpan={6}>
              <Flex my={128} justifyContent="center">
                <Typography textAlign="center">
                  Staff has not been added to this store. Click "Invite" to
                  bring your staff on board.
                </Typography>
              </Flex>
            </td>
          </Table.Row>
        )}
      </Table.Body>
    </CollapsedBorderTable>
  );
};

import { useEffect, useRef, useState } from 'react';

import { trackError } from '@jane/shared/util';

import { initCable } from './cable';
import { parseToken } from './jwt';
import { RuntimeError } from './loadable';

const MANAGER_AUTH_CHANNEL = 'ManagerAuthChannel';

export const useManagerAuthService = (
  initJwt: string | undefined | null,
  onLogout: () => void
) => {
  const connection = useRef<ActionCable.Channel | null>();
  const cable = useRef<ActionCable.Cable | null>();
  const [jwt, setJwt] = useState<string | null>(
    initJwt ? parseToken(initJwt) : null
  );

  const close = () => {
    connection.current && connection.current.unsubscribe();
    connection.current = null;
    cable.current && cable.current.disconnect();
    cable.current = null;
  };

  useEffect(() => {
    if (!initJwt) return;
    const newToken = parseToken(initJwt);

    if (newToken === jwt) {
      return;
    }

    if (newToken !== jwt) {
      close();
      setJwt(newToken);
    }

    if (!cable.current) {
      cable.current = initCable(newToken);
    }

    connection.current = cable.current.subscriptions.create(
      { channel: MANAGER_AUTH_CHANNEL },
      {
        connected: () => {
          return null;
        },
        rejected: () => {
          const error = new RuntimeError('ActionCable subscription rejected');
          trackError(error, { channel: MANAGER_AUTH_CHANNEL });
        },
        disconnected: () => {
          return null;
        },
        received: (data) => {
          if (data) {
            // Seems to only be used when user resets password
            if (data.action && data.action === 'log_out') {
              onLogout();
            }
          }
        },
      }
    );
  }, [initJwt]);
};

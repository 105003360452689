import * as t from 'io-ts';

export enum FlavorsType {
  butter = 'butter',
  cheese = 'cheese',
  chemical = 'chemical',
  citrus = 'citrus',
  coffee = 'coffee',
  diesel = 'diesel',
  earth = 'earth',
  flower = 'flower',
  fruit = 'fruit',
  honey = 'honey',
  lavender = 'lavender',
  mint = 'mint',
  nut = 'nut',
  pepper = 'pepper',
  pine = 'pine',
  sage = 'sage',
  skunk = 'skunk',
  spicy = 'spicy',
  sweet = 'sweet',
  tea = 'tea',
  tropical = 'tropical',
  vanilla = 'vanilla',
  woody = 'woody',
}

export const tFlavors = t.interface({
  flavors: t.array(t.keyof(FlavorsType)),
});

export type Flavors = t.TypeOf<typeof tFlavors>;

export enum EffectsType {
  alert = 'alert',
  appetite = 'appetite',
  creativity = 'creativity',
  daytime = 'daytime',
  energetic = 'energetic',
  euphoric = 'euphoric',
  nighttime = 'nighttime',
  pain_relief = 'pain relief',
  relaxed = 'relaxed',
  sleep_aid = 'sleep aid',
}

const effectsEnumAsObject = Object.fromEntries(
  Object.values(EffectsType).map((key) => [key, key])
);

export const tEffects = t.interface({
  effects: t.array(t.keyof(effectsEnumAsObject)),
});

export type Effects = t.TypeOf<typeof tEffects>;

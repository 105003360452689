import upperFirst from 'lodash/upperFirst';

import type {
  StaffMember,
  StaffMemberWithIdAndStoreNames,
} from '@jane/business-admin/types';

export const formatRole = (
  staffMember: StaffMember | StaffMemberWithIdAndStoreNames
) => {
  if (staffMember.role === 'staff_member') {
    const reservationModes = [
      staffMember.pickup && 'pickup',
      staffMember.delivery && 'delivery',
    ]
      .filter(Boolean)
      .join(' & ');
    return `Staff member - ${reservationModes}`;
  }

  return upperFirst(staffMember.role);
};

import { useContext, useMemo } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { BODY_LINE_HEIGHT, ModalNames } from '@jane/business-admin/util';
import { Flex, Skeleton, Typography } from '@jane/shared/reefer';

import { EditableCard } from '../../../../EditableCard';
import { GoogleAnalyticsModal } from './GoogleAnalyticsModal';

export const GoogleAnalyticsCard = () => {
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const { data: storeSettings, isFetching: storeSettingsLoading } =
    useStoreSettings(storeId);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.GoogleAnalytics
  );

  const gaIntegrationEnabled = useMemo(() => {
    if (!storeSettings || !storeSettings.analytics_integration) {
      return false;
    }

    return storeSettings.analytics_integration.enabled;
  }, [JSON.stringify(storeSettings)]);

  return (
    <>
      <EditableCard
        title="Google analytics"
        onEdit={() => openModal()}
        isLoading={storeSettingsLoading}
        showTextButton={!storeSettingsLoading && !gaIntegrationEnabled}
        textButtonLabel="Enable Google analytics"
        disabled={!canEditStore}
      >
        <Flex pt={24}>
          {storeSettingsLoading ? (
            <Skeleton animate>
              <Skeleton.Bone width="50%" height={BODY_LINE_HEIGHT} />
            </Skeleton>
          ) : (
            <Typography>
              {gaIntegrationEnabled
                ? 'Enabled'
                : 'Google analytics have not been enabled for this store.'}
            </Typography>
          )}
        </Flex>
      </EditableCard>
      {modalOpen && storeSettings?.analytics_integration && (
        <GoogleAnalyticsModal
          open={modalOpen}
          setOpen={() => closeModal()}
          analyticsIntegration={storeSettings.analytics_integration}
          storeName={storeSettings.store?.name}
        />
      )}
    </>
  );
};

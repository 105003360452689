import { Card, Flex, Typography } from '@jane/shared/reefer';

export const ROW_COPY = {
  best_selling: {
    header: 'Best Sellers',
    subheader: 'Display your best selling products automatically.',
  },
  sale: {
    header: 'On Sale',
    subheader: 'Display your discounted products automatically.',
  },
  specials: {
    header: 'Specials',
    subheader: 'Display all active specials in a new shoppable menu row.',
  },
};

export const DynamicRowCard = ({ row_type }: { row_type: string }) => {
  return (
    <Card flat mb={24}>
      <Card.Content background="grays-ultralight">
        <Flex flexDirection="column" p={8}>
          <Typography variant="body-bold">
            {ROW_COPY[row_type as keyof typeof ROW_COPY].header}
          </Typography>
          <Typography>
            {ROW_COPY[row_type as keyof typeof ROW_COPY].subheader}
          </Typography>
        </Flex>
      </Card.Content>
    </Card>
  );
};

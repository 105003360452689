import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ALL_DAYS, getTwentyFourHours } from '@jane/business-admin/util';
import {
  Button,
  CheckboxField,
  CopyIcon,
  Flex,
  Form,
} from '@jane/shared/reefer';

import { CopyHoursPopover } from '../../../../stores/detail/settings/details/hours/CopyHoursPopover';
import { validateTimeWindow } from './ScheduleTimeValidation';

const hourOptions = getTwentyFourHours();

export const ScheduleOverrideHourPicker = ({
  alignment,
  copy,
  day,
  disabled = false,
}: {
  alignment: boolean;
  copy: ({
    dayToCopyFrom,
    daysToCopyTo,
  }: {
    dayToCopyFrom: string;
    daysToCopyTo: string[];
  }) => void;
  day: string;
  disabled?: boolean;
}) => {
  const { setValue, watch } = useFormContext();
  const startKey = `schedule.start_time_${day}`;
  const endKey = `schedule.end_time_${day}`;
  const allDayKey = `schedule.enabled_${day}_all_day`;
  const allDayWatch = watch(allDayKey);

  const startTime = useWatch({ name: startKey });
  const endTime = useWatch({ name: endKey });

  const isAllDay = useWatch({ name: allDayKey });

  const copyHoursOptions = useMemo(() => {
    return ALL_DAYS.filter((item) => item !== day).map((relevantDay) => {
      return {
        value: relevantDay,
        label: capitalize(relevantDay),
      };
    });
  }, [day]);

  const triggerCopy = (days: string[]) => {
    copy({
      dayToCopyFrom: day,
      daysToCopyTo: days,
    });
  };

  return (
    <Flex width="80%" alignItems="center">
      <CheckboxField
        label="All day"
        checked={isAllDay}
        name={allDayKey}
        width="50%"
        disabled={disabled}
        mr={48}
        onChange={(val) => {
          if (val !== allDayWatch) {
            setValue(allDayKey, val, { shouldDirty: true });
            setValue(startKey, val === false ? '12:00 AM' : '', {
              shouldDirty: true,
            });
            setValue(endKey, val === false ? '11:45 PM' : '', {
              shouldDirty: true,
            });
          }
        }}
      />
      <Form.SelectField
        width="100%"
        labelHidden
        options={hourOptions}
        name={startKey}
        label={`${capitalize(day)} Start Time`}
        mr={24}
        placeholder="Select a start time"
        disabled={disabled || isAllDay}
        validate={() => validateTimeWindow(endTime, startTime, 'start')}
      />
      <Form.SelectField
        width="100%"
        labelHidden
        options={hourOptions}
        name={endKey}
        label={`${capitalize(day)} End Time`}
        placeholder="Select an end time"
        disabled={disabled || isAllDay}
        validate={() => validateTimeWindow(endTime, startTime, 'end')}
      />
      {disabled ? (
        <Button.Icon
          ml={8}
          label={`Copy ${day} hours to other days`}
          icon={<CopyIcon color="grays-mid" />}
          disabled
        />
      ) : (
        <CopyHoursPopover
          target={
            <Button.Icon
              ml={8}
              label={`Copy ${day} hours to other days`}
              icon={<CopyIcon color="grays-mid" />}
            />
          }
          label="Copy hours to other days"
          triggerCopy={triggerCopy}
          options={copyHoursOptions}
          topAlignment={alignment}
        />
      )}
    </Flex>
  );
};

import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';

const ROOTS_ONLY_CUSTOM_ROW_STORE_IDS = (
  config.rootsOnlyCustomRowStoreIds || ''
)
  .split(',')
  .map((s: string) => s.trim())
  .filter((s) => s !== '');

export const rootOnlyCustomRowsAllowed = (storeId: Id) => {
  return ROOTS_ONLY_CUSTOM_ROW_STORE_IDS.includes(`${storeId}`);
};

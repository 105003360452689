import capitalize from 'lodash/capitalize';
import { useCallback, useContext, useEffect, useState } from 'react';

import type { ToggleFulfillmentsParams } from '@jane/business-admin/data-access';
import {
  useStoreSettings,
  useToggleFulfillmentModes,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useLastToggled,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { CardNames, EventNames, track } from '@jane/business-admin/util';
import {
  Card,
  Flex,
  SwitchField,
  Typography,
  useToast,
} from '@jane/shared/reefer';

export const OrderAheadCard = () => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating order ahead settings. Please try again.'
  );

  const toast = useToast();
  const { lastToggled, setLastToggled, clearLastToggled } =
    useLastToggled<ToggleFulfillmentsParams>();
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const { data: storePayload, isFetching } = useStoreSettings(storeId);
  const {
    mutateAsync: toggleOrderAhead,
    isLoading: isSaving,
    isSuccess: toggleFulfillmentModeSuccess,
    isError: toggleFulfillmentModeError,
  } = useToggleFulfillmentModes(storeId, setLastToggled);

  const [mountCards, setMountCards] = useState(false);

  const saveOrderAheadData = useCallback(
    (
      name: 'allow_off_hours_ordering' | 'allow_future_day_ordering',
      value: boolean
    ) => {
      track({
        event: EventNames.EditedStoreSettings,
        card_name: CardNames.OrderAhead,
        changed_attributes: [name],
      });

      const requestData = {
        [name]: value,
      };
      catchSubmitErrors({
        submitMethod: () => toggleOrderAhead(requestData),
        requestData,
        onValidationError: () => null,
        // TODO: Maybe use this to show error toast?
        // TODO: Also need to reset toggle to original state if update fails
        callback: () => null,
      });
    },
    []
  );

  useEffect(() => {
    // So the Switches set defaultChecked correctly, don't render them until data is loaded
    if (storePayload?.store.allow_off_hours_ordering !== undefined) {
      setMountCards(true);
    }
  }, [storePayload?.store.allow_off_hours_ordering]);

  useEffect(() => {
    if (toggleFulfillmentModeSuccess && lastToggled !== null) {
      const [[type, value]] = Object.entries(lastToggled);
      toast.add({
        label: `${capitalize(type).replace(/_/g, ' ')} successfully toggled ${
          value ? 'on' : 'off'
        }.`,
        variant: 'success',
      });
      clearLastToggled();
    }

    if (toggleFulfillmentModeError && lastToggled !== null) {
      const [[type, value]] = Object.entries(lastToggled);
      toast.add({
        label: `${capitalize(type).replace(/_/g, ' ')} could not be toggled ${
          value ? 'on' : 'off'
        }. Please try again.`,
        variant: 'error',
      });
    }
  }, [toggleFulfillmentModeSuccess, toggleFulfillmentModeError]);

  return !mountCards ? null : (
    <Card border="grays-light" flat mb={24}>
      <Card.Content background={!canEditStore ? 'grays-ultralight' : undefined}>
        <Flex p={24} flexDirection="column">
          <Typography mb={40} variant="header-bold">
            Order ahead
          </Typography>

          <SwitchField
            defaultChecked={storePayload?.store.allow_off_hours_ordering}
            name="allow_off_hours_ordering"
            onChange={(value) =>
              saveOrderAheadData('allow_off_hours_ordering', value)
            }
            label={
              <>
                <Typography mb={8}>Allow off hours ordering</Typography>
                <Typography color="grays-mid">
                  Off hours ordering allows customers to place orders for the
                  next business day when the store is closed.
                </Typography>
              </>
            }
            mb={16}
            disabled={isSaving || isFetching || !canEditStore}
          />
          <SwitchField
            defaultChecked={storePayload?.store.allow_future_day_ordering}
            name="allow_future_day_ordering"
            onChange={(value) =>
              saveOrderAheadData('allow_future_day_ordering', value)
            }
            label={
              <>
                <Typography mb={8}>Allow future day ordering</Typography>
                <Typography color="grays-mid">
                  Future day ordering allows customers to place orders more than
                  one business day in advance.
                </Typography>
              </>
            }
            disabled={isSaving || isFetching || !canEditStore}
          />
        </Flex>
      </Card.Content>
    </Card>
  );
};

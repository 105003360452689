import * as t from 'io-ts';

import { tId } from './common';

const tLocation = t.interface({
  id: t.string,
  name: t.string,
});

export const tSquareIntegration = t.interface({
  id: tId,
  location_id: t.union([t.string, t.null]),
  enabled: t.union([t.boolean, t.undefined]),
  authorized: t.union([t.boolean, t.undefined]),
  locations: t.array(tLocation),
});
export type SquareIntegration = t.TypeOf<typeof tSquareIntegration>;

export const tSquareAuthorizationUrlPayload = t.interface({
  url: t.string,
});
export type SquareAuthorizationUrlPayload = t.TypeOf<
  typeof tSquareAuthorizationUrlPayload
>;

export const tSquareAuthorizationPayload = t.interface({
  store_id: tId,
});
export type SquareAuthorizationPayload = t.TypeOf<
  typeof tSquareAuthorizationPayload
>;

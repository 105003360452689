import styled from '@emotion/styled';

import { mediaQueries } from '@jane/shared/reefer-emotion';

export const ProgressBar = styled.div({
  height: 8,
  borderRadius: 12,
  width: '100%',
  background: 'rgba(0, 0, 0, 0.05)',
});

export const ProgressFill = styled.div<{
  completed: boolean;
  progress: number;
}>(({ theme, progress, completed }) => ({
  background: completed
    ? theme.colors.brand.sunset.main
    : theme.colors.brand.purple.main,
  borderRadius: 12,
  height: '100%',
  width: `${progress}%`,
}));

export const ProgressContainer = styled.div(({ theme }) => ({
  scrollMargin: '55px',
  [mediaQueries.tablet('max')]: {
    background: theme.colors.grays.ultralight,
    borderRadius: 0,
  },
}));

import styled from '@emotion/styled';
import type { SVGProps } from 'react';

const BaseSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.13051 25.5061C5.85771 25.9863 6.02585 26.5967 6.50606 26.8695C6.98627 27.1423 7.5967 26.9742 7.86949 26.4939L6.13051 25.5061ZM12.1818 16.8784L12.5008 15.9306L11.7195 15.6677L11.3123 16.3844L12.1818 16.8784ZM17 18.5L16.681 19.4478L17.4699 19.7133L17.8742 18.9856L17 18.5ZM25.4615 4.72528C25.3098 4.19424 24.7563 3.88675 24.2253 4.03848L15.5716 6.51097C15.0405 6.66269 14.733 7.21618 14.8848 7.74721C15.0365 8.27825 15.59 8.58574 16.121 8.43401L23.8132 6.23625L26.011 13.9284C26.1627 14.4595 26.7162 14.767 27.2472 14.6152C27.7782 14.4635 28.0857 13.91 27.934 13.379L25.4615 4.72528ZM7.86949 26.4939L13.0513 17.3723L11.3123 16.3844L6.13051 25.5061L7.86949 26.4939ZM11.8628 17.8261L16.681 19.4478L17.319 17.5522L12.5008 15.9306L11.8628 17.8261ZM17.8742 18.9856L25.3742 5.48564L23.6258 4.51436L16.1258 18.0144L17.8742 18.9856Z"
      fill="#0E0E0E"
    />
  </svg>
);

export const StocksIcon = styled(BaseSvg)({
  height: '32px',
  width: '32px',
  flexShrink: 0,
});

export default StocksIcon;

import type { FormNumberFieldProps } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer';

/**
 * `PercentageInput` components are used to validate percentage decimal amounts.
 * `PercentageInput` components need to be used within a `Form`.
 * `PercentageInput` components have a default step amount of "0.01" and will only allow 2 integers after a decimal point, unless otherwise specified.
 */

export const PercentageInput = (fieldProps: FormNumberFieldProps) => (
  <Form.NumberField
    width="100%"
    min={0}
    max={100}
    step={0.01}
    allowedDecimalPlaces={2}
    {...fieldProps}
  />
);

import type { SVGProps } from 'react';

const SvgHeaderImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={284}
    height={71}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={58.5} cy={-7.5} r={78.5} fill="#5E1FF0" />
    <circle cx={58.5} cy={-7.5} r={78.5} fill="#fff" fillOpacity={0.8} />
    <g
      clipPath="url(#header-image_svg__a)"
      stroke="#5E1FF0"
      strokeWidth={4}
      strokeLinecap="round"
      strokeDasharray="8 24"
      opacity={0.6}
    >
      <path d="m-260 324 480-480M-237 324l480-480M-214 324l480-480M-191 324l480-480M-168 324l480-480M-145 324l480-480M-122 324l480-480M-99 324l480-480M-76 324l480-480M-53 324l480-480M-30 324l480-480M-7 324l480-480M16 324l480-480M39 324l480-480M62 324l480-480M85 324l480-480M108 324l480-480M131 324l480-480M154 324l480-480M177 324l480-480M200 324l480-480M223 324l480-480M-283 324l480-480M-306 324l480-480M-329 324l480-480M-352 324l480-480M-375 324l480-480M-398 324 82-156M-421 324 59-156M-444 324 36-156" />
    </g>
    <defs>
      <clipPath id="header-image_svg__a">
        <rect x={-260} y={-156} width={544} height={216} rx={40} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgHeaderImage;

import styled from '@emotion/styled';

import { Flex, Typography, useFormContext } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import type { CartLimitRule } from '../../mockData';

const PreviewWrapper = styled(Flex)(({ theme }) => ({
  backgroundColor: theme.colors.brand.ember.main,
  ...spacing({ mx: -40, px: 40, py: 24 }),
}));

export const RulePreview = () => {
  const { watch } = useFormContext<CartLimitRule>();
  const name = watch('product_group_name');
  const value = watch('limit_value');
  const unit = watch('limit_unit');

  return (
    <PreviewWrapper>
      <Typography variant="body-bold" color="grays-white">
        Your cart has {value}
        {unit} of {name} products.
      </Typography>
    </PreviewWrapper>
  );
};

import { useManager } from '@jane/business-admin/data-access';
import { catchSubmitErrors } from '@jane/business-admin/util';

/**
 * genericMessage: Message that will be thrown for anything that isn't a validation error.
 *
 * redactedKeyPaths: List of object key paths that should be redacted from requestData.
 * If redacted data is an array of objects, can use "array_index" keyword to indicate where an array is expected.
 */
export const useCatchErrorsWithManager = (
  genericMessage: string,
  redactedKeyPaths?: string[][]
): (({
  submitMethod,
  requestData,
  onValidationError,
  callback,
}: {
  callback?: (validationErrors: any) => void;
  onValidationError: (validationErrors: any) => void;
  requestData: Record<string, any>;
  submitMethod: () => Promise<any>;
}) => void) => {
  const { data: managerData } = useManager();

  return ({ submitMethod, requestData, onValidationError, callback }) =>
    catchSubmitErrors(
      submitMethod,
      onValidationError,
      genericMessage,
      callback,
      requestData,
      {
        id: managerData?.manager.id || 0,
        email: managerData?.manager.email || '',
      },
      redactedKeyPaths
    );
};

import * as t from 'io-ts';

import { tReservationMode } from '@jane/shared/models';

export const tPaymentOptionV2 = t.interface({
  reservation_mode: tReservationMode,
  enabled: t.boolean,
  payment_option: t.string,
  id: t.union([t.string, t.number, t.null]),
});
export type PaymentOptionV2 = t.TypeOf<typeof tPaymentOptionV2>;

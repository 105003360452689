import styled from '@emotion/styled';

import type { Spacing } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

export const TableCell = styled.td<{
  pl?: Spacing;
  py?: Spacing;
}>(({ pl = 16, py = 20 }) => ({
  // TODO: support all padding props once reefer exports PaddingProperties
  ...spacing({ pl, py }),
}));

import styled from '@emotion/styled';

import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const MenuTabScrollArea = styled.div(({ theme }) => ({
  borderBottom: '1px solid transparent',
  borderTop: '1px solid transparent',
  overflowX: 'scroll',
  position: 'relative',
  scrollbarWidth: 'none',
  MsOverflowStyle: 'none',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    width: 0,
  },
  ...spacing({ ml: -24, pl: 24, pr: 64 }),
  ':focus-visible': {
    outline: 'none',
    borderBottom: `1px solid ${theme.colors.primary.main}`,
    borderTop: `1px solid ${theme.colors.primary.main}`,
  },
  [mediaQueries.tablet('min')]: {
    ...spacing({ mx: 0, pl: 0 }),
    overflowX: 'hidden',
    scrollSnapType: 'x mandatory',
  },
}));

export const MenuTabGrid = styled.ul<{
  gap: number;
  width: number;
}>(({ gap, width }) => ({
  display: 'grid',
  gridAutoColumns: width + gap,
  gridAutoFlow: 'column',
  gridTemplateRows: 'auto',
  [mediaQueries.tablet('min')]: {
    gridAutoColumns: width,
    gap: gap,
  },
}));

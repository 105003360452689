import * as t from 'io-ts';

import { tReservationModeV2 } from '@jane/shared/models';

export const tFulfillmentConfig = t.interface({
  id: t.number,
  cart_minimum: t.number,
  display_windows_by_day: t.boolean,
  fulfillment_type: tReservationModeV2,
  interval_minutes: t.number,
  last_call_interval_seconds: t.number,
  min_lead_time_minutes: t.number,
  max_lead_time_minutes: t.number,
  max_orders_per_window: t.union([t.number, t.null]),
  window_minutes: t.number,
});
export type FulfillmentConfig = t.TypeOf<typeof tFulfillmentConfig>;

import styled from '@emotion/styled';
import { useMaskito } from '@maskito/react';
import noop from 'lodash/noop';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import type { UpsertBloomMenuTemplateArgs } from '@jane/business-admin/data-access';
import {
  useFetchBloomMenuTemplate,
  useFetchCurrentBloomMenu,
  useUpsertBloomMenuTemplate,
} from '@jane/business-admin/data-access';
import { normalizeUrl, urlMask } from '@jane/business-admin/util';
import { Flex, Form, Typography, useToast } from '@jane/shared/reefer';

import { ModalPageWrapper } from './components/ModalPageWrapper';

const BorderedFlex = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));

export const PlusFooter = () => {
  const urlRef = useMaskito({ options: urlMask });
  const toast = useToast();
  const bloomMenuQuery = useFetchCurrentBloomMenu();
  const bloomMenuTemplate = useFetchBloomMenuTemplate('footer');
  const { mutate: updateBloomMenuTemplate, isLoading } =
    useUpsertBloomMenuTemplate({
      menuId: bloomMenuQuery.data?.id,
    });
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<UpsertBloomMenuTemplateArgs>({
    defaultValues: {
      source_content_selector: bloomMenuTemplate.data?.source_content_selector,
      source_url: bloomMenuTemplate.data?.source_url,
    },
  });

  useEffect(() => {
    if (bloomMenuTemplate.data) {
      reset({
        source_content_selector:
          bloomMenuTemplate.data?.source_content_selector ?? 'footer',
        source_url: bloomMenuTemplate.data?.source_url ?? 'https://',
      });
    }
  }, [bloomMenuTemplate.data]);

  const importFooter = useCallback(
    ({ source_content_selector, source_url }: UpsertBloomMenuTemplateArgs) => {
      updateBloomMenuTemplate(
        {
          source_content_selector,
          source_url: normalizeUrl(source_url),
          template_type: 'footer',
        },
        {
          onSuccess: () => {
            toast.add({
              label: 'Footer imported!',
              variant: 'success',
            });
            reset({ source_content_selector, source_url });
          },
          onError: (error) => {
            toast.add({
              label: 'An error occurred.',
              variant: 'error',
            });
          },
        }
      );
    },
    [toast, updateBloomMenuTemplate]
  );

  return (
    <ModalPageWrapper
      hasUnsavedChanges={isDirty}
      publish={{
        onClick: handleSubmit(importFooter),
        disable: isLoading,
        loading: isLoading,
      }}
      title="Footer"
    >
      <Form name="footer_form" onSubmit={noop}>
        <BorderedFlex justifyContent="center" py={40}>
          <Flex alignItems="center" flexDirection="column" maxWidth={660}>
            <Controller
              name="source_url"
              control={control}
              render={({ field }) => (
                <Form.TextField
                  {...field}
                  label="Source"
                  helperText="Provide the URL of your footer website to directly import the footer"
                  mb={24}
                  endIcon={<Typography color="grays-mid">URL</Typography>}
                  ref={urlRef}
                />
              )}
            />
            <Controller
              name="source_content_selector"
              control={control}
              render={({ field }) => (
                <Form.TextField
                  {...field}
                  label="Content selector"
                  helperText="Provide the CSS content selector of the footer element to import"
                  endIcon={<Typography color="grays-mid">Selector</Typography>}
                />
              )}
            />
          </Flex>
        </BorderedFlex>
      </Form>
    </ModalPageWrapper>
  );
};

import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useStoreMenu,
  useStoreSettings,
  useUpdateAppearance,
} from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type { Appearance } from '@jane/business-admin/types';
import {
  ErrorReasons,
  EventNames,
  ModalNames,
  SettingNames,
  normalizePath,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Flex,
  Form,
  FormValidationError,
  Modal,
  Skeleton,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ColorPreviewTextbox } from '../../../ColorPreviewTextbox';
import { ConfirmWrapperWithTracking } from '../../../ConfirmWrapperWithTracking';

const FORM_ERROR_NAME = 'appearance-modal-errors';

export const AppearanceModal = ({ closeModal }: { closeModal: () => void }) => {
  const { pathname } = useLocation();
  const { id = '' } = useParams<'id'>();
  const { data: menuRowData, isFetched: menuDataFetched } = useStoreMenu(id);
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(id);
  const { mutateAsync: updateAppearance, isSuccess: updateAppearanceSuccess } =
    useUpdateAppearance(id);
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating store appearance. Please try again'
  );

  const theme = useTheme();
  const toast = useToast();
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const appearanceData = menuRowData?.appearance;

  const onSubmit = (data: any) => {
    const requestData = {
      ...data,
      pdp_hide_description: data.pdp_hide_description || false,
      pdp_hide_effects: data.pdp_hide_effects || false,
    };

    const trackSubmit = (eventProps = {}) => {
      track({
        event: EventNames.EditedStoreMenuConfig,
        modal_name: ModalNames.Appearance,
        setting_name: 'Appearance',
        action: 'update',
        url: normalizePath(pathname, id),
        changed_attributes: Object.keys(dirtyFields),
        successful: true,
        ...eventProps,
      });
    };

    const submitMethod = async () => {
      await updateAppearance(requestData);
      trackSubmit();
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.InvalidParams,
        });
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      // track errors other than invalid params
      callback: () => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.ServerError,
        });
        throw new Error('Error updating store appearance. Please try again');
      },
    });
  };

  const trackColorChange =
    (colorProp: keyof Appearance, settingName: SettingNames) =>
    (value: string, isValid: boolean) => {
      // isValid serves as a rate limiter for hex color fields rather than debouncing. Only valid hex code changes get tracked.
      if (isValid) {
        const isRevert = [
          appearanceData?.[colorProp],
          theme.colors.primary.main.toString(),
        ].includes(value);

        track({
          event: EventNames.ModifiedSetting,
          revert: isRevert,
          setting_name: settingName,
          modal_name: ModalNames.Appearance,
        });
      }
    };

  useEffect(() => {
    if (updateAppearanceSuccess) {
      toast.add({
        label: 'Store appearance updated.',
        variant: 'success',
      });
      closeModal();
    }
  }, [updateAppearanceSuccess]);

  return (
    <ConfirmWrapperWithTracking
      open
      setOpen={closeModal}
      variant="standard"
      hasChanges={isDirty}
      modalName={ModalNames.Appearance}
    >
      <Form.BaseForm
        name="appearance modal"
        onSubmit={onSubmit}
        formMethods={formMethods}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Appearance"
          subtitle={storeSettingsLoading ? '' : storePayload?.store.name}
          actions={
            <Form.SubmitButton variant="primary" label="Publish" ml={16} />
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          {menuDataFetched ? (
            <>
              <ColorPreviewTextbox
                label="Theme color"
                name="white_label_theme_color"
                helperText="Applies to buttons and banners"
                defaultColor={
                  appearanceData?.white_label_theme_color ||
                  theme.colors.primary.main.toString()
                }
                onChange={trackColorChange(
                  'white_label_theme_color',
                  SettingNames.MenuThemeColor
                )}
                shouldValidateThemeColor={true}
              />
              <ColorPreviewTextbox
                label="Navigation color"
                name="white_label_nav_color"
                helperText="Applies to navigation bar links and icons"
                defaultColor={
                  appearanceData?.white_label_nav_color ||
                  theme.colors.primary.main.toString()
                }
                onChange={trackColorChange(
                  'white_label_nav_color',
                  SettingNames.MenuNavigationColor
                )}
              />
              <Flex flexDirection="column">
                <Typography variant="body-bold" mb={24}>
                  Default menu format
                </Typography>
                <Form.RadioFieldGroup
                  name="view_default"
                  defaultChecked={appearanceData?.view_default || 'grid_view'}
                  onChange={(value) => {
                    track({
                      event: EventNames.ModifiedSetting,
                      revert: value === appearanceData?.view_default,
                      setting_name: SettingNames.DefaultMenuFormat,
                      modal_name: ModalNames.Appearance,
                    });
                  }}
                  options={[
                    {
                      id: 'grid_view',
                      label: 'Grid view',
                      value: 'grid_view',
                    },
                    {
                      id: 'list_view',
                      label: 'List view',
                      value: 'list_view',
                    },
                  ]}
                />
              </Flex>
              <Modal.ContentDivider />
              <Typography variant="body-bold" mb={24}>
                Product detail page
              </Typography>
              <Form.CheckboxField
                name="pdp_hide_description"
                label="Hide all descriptions"
                defaultChecked={appearanceData?.pdp_hide_description}
                onChange={(value) => {
                  track({
                    action: 'toggle',
                    event: EventNames.ModifiedSetting,
                    revert: value === appearanceData?.pdp_hide_description,
                    setting_name: SettingNames.ProductDetailPageHideDescription,
                    modal_name: ModalNames.Appearance,
                  });
                }}
                mb={24}
              />
              <Form.CheckboxField
                name="pdp_hide_effects"
                label="Hide top feelings"
                defaultChecked={appearanceData?.pdp_hide_effects}
                onChange={(value) => {
                  track({
                    action: 'toggle',
                    event: EventNames.ModifiedSetting,
                    revert: value === appearanceData?.pdp_hide_effects,
                    setting_name: SettingNames.ProductDetailPageHideEffects,
                    modal_name: ModalNames.Appearance,
                  });
                }}
              />
            </>
          ) : (
            <Skeleton animate>
              <Flex width="50%" mb={72} mt={48}>
                <Skeleton.Bone height={48} />
                <Skeleton.Bone height={48} ml={24} width={48} />
              </Flex>
              <Modal.ContentDivider padding={false} />
              <Flex width="50%" my={72}>
                <Skeleton.Bone height={48} />
                <Skeleton.Bone height={48} ml={24} width={48} />
              </Flex>
              <Modal.ContentDivider padding={false} />
              <Flex my={72} flexDirection="column" width="33%">
                <Skeleton.Bone height={24} mb={12} />
                <Skeleton.Bone height={24} mb={12} />
                <Skeleton.Bone height={24} />
              </Flex>
              <Modal.ContentDivider padding={false} />
              <Flex mt={64} flexDirection="column" width="33%">
                <Skeleton.Bone height={24} mb={12} />
                <Skeleton.Bone height={24} mb={12} />
              </Flex>
            </Skeleton>
          )}
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

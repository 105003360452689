import { useQuery } from '@tanstack/react-query';
import * as t from 'io-ts';

import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type { NuProductType } from '@jane/shared/models';
import { tNuProductType } from '@jane/shared/models';

export const PRODUCT_TYPES_URL = '/business/product_types';

const fetchProductTypes = (): Promise<any> =>
  fetchWithDecode(
    janeApiV2.get(PRODUCT_TYPES_URL),
    t.array(tNuProductType),
    PRODUCT_TYPES_URL
  );
export const useProductTypes = () =>
  useQuery<NuProductType[]>({
    placeholderData: [],
    queryFn: () => fetchProductTypes(),
    queryKey: ['productTypes'],
    staleTime: Infinity,
    useErrorBoundary: true,
  });

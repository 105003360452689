export const normalizePath = (
  urlPath: string,
  storeId: string,
  productId?: string
) => {
  const storeIdNormalized = storeId ? urlPath.replace(storeId, ':id') : urlPath;
  const productIdNormalized = productId
    ? storeIdNormalized.replace(productId, ':product_id')
    : storeIdNormalized;
  return productIdNormalized;
};

import type { ReactNode } from 'react';

import { useGetJwt, useManager } from '@jane/business-admin/data-access';
import { useLogoutAndNavigateToLogin } from '@jane/business-admin/hooks';
import { useCheckAuthTimer, useManagerAuthService } from '@jane/shared/auth';

export const Authenticated = ({ children }: { children: ReactNode }) => {
  const { data: jwt } = useGetJwt();

  const logout = useLogoutAndNavigateToLogin();

  // Check for jwt validity every 5 minutes
  useCheckAuthTimer(jwt, () => {
    // TODO: Should this logout? Not clear what's happening on the old app
    logout();
  });
  // This is a webhook that logs a user out when they reset their password
  // TODO: Does this need to be unsubscribed when destroyed?
  useManagerAuthService(jwt, () => {
    logout();
  });

  const { isFetched } = useManager();

  return <div>{isFetched ? children : null}</div>;
};

const hide = () =>
  ({
    border: '0',
    clip: 'rect(0 0 0 0)',
    clipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
    whiteSpace: 'nowrap',
  } as const);

const fallbackCopyToClipboard = (str: string) => {
  const selection = window.getSelection();
  if (selection === null) {
    return false;
  }

  const input = document.createElement('input');
  input.value = str;
  Object.entries(hide()).forEach(([property, value]) => {
    input.style[property as keyof ReturnType<typeof hide>] = value;
  });
  input.contentEditable = 'true';
  input.readOnly = true;
  document.body.appendChild(input);

  const range = document.createRange();
  range.selectNodeContents(input);
  selection.removeAllRanges();
  selection.addRange(range);
  input.setSelectionRange(0, 999999);

  const result = document.execCommand('copy');

  document.body.removeChild(input);
  return result;
};

export const copyToClipboard = async (str: string) => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(str);
  } else {
    const result = fallbackCopyToClipboard(str);
    return result
      ? Promise.resolve()
      : Promise.reject(new Error('Failed to copy from fallback'));
  }
};

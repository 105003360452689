import type { StoreDismissalMessage } from '@jane/business-admin/types';
import type { StatusMessageV2, StoreChatSetting } from '@jane/shared/models';

export interface NotificationSettingsForm {
  chat_setting?: StoreChatSetting;
  dismissal_messages: StoreDismissalMessage[];
  send_cart_status_updates_via_email?: boolean;
  status_messages: StatusMessageV2[];
}

export interface StoreNotificationsFormData {
  chat_setting?: StoreChatSetting;
  dismissal_messages?: StoreDismissalMessage[];
  send_cart_status_updates_via_email?: boolean;
  status_messages?: StatusMessageV2[];
}

export const STATUS_MESSAGES_FIELD = 'status_messages';
export const DISMISSAL_MESSAGES_FIELD = 'dismissal_messages';
export const SEND_EMAIL_UPDATES_FIELD = 'send_cart_status_updates_via_email';
export const STORE_CHAT_SETTINGS_FIELD = 'store_chat_setting';
export const MAX_MESSAGE_LENGTH = 280;

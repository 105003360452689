import * as t from 'io-ts';

export const tCloudPrinterSetting = t.union([
  t.null,
  t.interface({
    store_id: t.number,
    store_description: t.union([t.null, t.string]),
    print_store_name: t.boolean,
    print_date_of_birth: t.boolean,
    print_medical_id_number: t.boolean,
    print_fulfillment_type: t.boolean,
    print_pos_identifier: t.boolean,
    print_pos_product_name: t.boolean,
    print_items_grouped_by_kind: t.boolean,
  }),
]);

export type CloudPrinterSetting = t.TypeOf<typeof tCloudPrinterSetting>;

import { useParams } from 'react-router-dom';

import { useUpdateSeenNotifications } from '@jane/business-admin/data-access';
import type { ManagerNotification } from '@jane/business-admin/types';
import { EventNames, track } from '@jane/business-admin/util';
import {
  Button,
  Card,
  Flex,
  JanePayLogo,
  Link,
  Typography,
} from '@jane/shared/reefer';

export const JanePayUpsellCard = ({
  notification,
}: {
  notification: ManagerNotification;
}) => {
  const { id: storeId = '' } = useParams<'id'>();
  const { mutateAsync: updateSeenNotifications } =
    useUpdateSeenNotifications(storeId);

  return (
    <Card border="grays-ultralight" mb={24} flat>
      <Card.Content background="grays-ultralight">
        <Flex justifyContent="space-between" gap={24} p={24}>
          <Flex flexDirection="column" alignItems="flex-start">
            <JanePayLogo style={{ width: '120px' }} />
            <Typography variant="mini">
              Jane Pay allows you to accept ACH payments and offer Jane Gold
              rewards program for your customers.{' '}
              <Link href="https://www.iheartjane.com/janepay">Learn more</Link>
            </Typography>
          </Flex>
          <Flex alignItems="center" gap={24} mr={24}>
            <Link
              onClick={() =>
                updateSeenNotifications({ notification_ids: [notification.id] })
              }
            >
              Dismiss
            </Link>
            <Button
              label="Get in touch"
              variant="secondary"
              href={`mailto:partnersuccess@iheartjane.com`}
              onClick={() => {
                track({
                  event: EventNames.JanePayGetInTouch,
                  notification_type: 'card',
                  store_id: storeId,
                });
              }}
            />
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
};

import { useContext, useEffect, useMemo } from 'react';

import type { CrmIntegrationParams } from '@jane/business-admin/data-access';
import { useSaveStoreIntegrations } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { StoreCrmIntegration } from '@jane/shared/models';
import { CrmProvider } from '@jane/shared/models';
import {
  Flex,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useForm,
  useFormContext,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';

export interface LoyaltyFormItem {
  automatic_redemption?: boolean;
  bypass_redemption?: boolean;
  enabled?: boolean;
  max_redemption_count?: boolean;
  token?: string;
}
export interface LoyaltyFormData {
  enabled?: CrmProvider | 'none';
  [CrmProvider.alpineiq]?: LoyaltyFormItem;
  [CrmProvider.data_owl]?: LoyaltyFormItem;
  [CrmProvider.springbig]?: LoyaltyFormItem;
}

export const CRM_PROVIDERS: { label: string; value: CrmProvider }[] = [
  {
    value: CrmProvider.alpineiq,
    label: 'AlpineIQ',
  },
  {
    value: CrmProvider.data_owl,
    label: 'Data Owl',
  },
  {
    value: CrmProvider.springbig,
    label: 'Springbig',
  },
];

const LoyaltyItem = ({
  provider: { value, label },
  integration,
  children,
}: {
  children: React.ReactNode;
  integration: LoyaltyFormItem | undefined;
  provider: {
    label: string;
    value: string;
  };
}) => {
  const tokenKey = `${value}.token`;
  const redemptionKey = `${value}.automatic_redemption`;
  const byPassRedemptionKey = `${value}.bypass_redemption`;
  const maxRedemptionKey = `${value}.max_redemption_count`;

  const { watch, setValue, getValues } = useFormContext();
  const isEnabled = useMemo(() => integration?.enabled || false, [integration]);
  const formEnabled = watch('enabled');
  const watchAutomaticRedemption = watch(redemptionKey);
  const automaticRedemptionValue = getValues(redemptionKey);

  const bypassRedemptionFlag = useFlag(FLAGS.scBypassPointDeductionFrontend);

  useEffect(() => {
    if (isEnabled) {
      setValue('enabled', value);
    }
  }, [isEnabled]);

  const isDisabled = useMemo(() => {
    // On form load, use value from API data
    if (formEnabled === undefined) {
      return !isEnabled;
    }
    // When user is interacting with form, use that value
    return formEnabled !== value;
  }, [formEnabled, isEnabled]);

  const showBypassRedemption = () => {
    if (!bypassRedemptionFlag) {
      return false;
    }

    return watchAutomaticRedemption || automaticRedemptionValue;
  };

  return (
    <>
      {children}
      {!isDisabled && (
        <Flex mt={24} width={'auto'}>
          <Form.TextField
            defaultValue={integration?.token || undefined}
            disabled={isDisabled}
            label="Token"
            width="50%"
            name={tokenKey}
            data-testid={tokenKey}
            mr={24}
            required={!isDisabled}
          />
          <Flex width="65%" flexDirection="column">
            {/* // TODO: Need 0.3 opacity here when disabled to match the inputs/labels */}
            <Typography
              color={isDisabled ? 'grays-light' : 'grays-mid'}
              mb={20}
            >
              Online redemption
            </Typography>
            <Form.CheckboxField
              defaultChecked={integration?.automatic_redemption || false}
              disabled={isDisabled}
              label="Allow online reward redemption"
              name={redemptionKey}
              data-testid={redemptionKey}
              mb={24}
            />
            {showBypassRedemption() && (
              <Form.CheckboxField
                defaultChecked={integration?.bypass_redemption || false}
                label="Deduct loyalty points within the POS"
                name={byPassRedemptionKey}
                data-testid={byPassRedemptionKey}
                mb={24}
                ml={40}
              />
            )}
            <Form.CheckboxField
              defaultChecked={integration?.max_redemption_count || false}
              disabled={isDisabled}
              label="Limit to one redemption per cart"
              name={maxRedemptionKey}
              data-testid={maxRedemptionKey}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

const FORM_ERROR_NAME = 'loyalty-errors';

export const LoyaltyModal = ({
  enabledProvider,
  integrations,
  open,
  setOpen,
  storeName,
}: {
  enabledProvider: string | null;
  integrations: StoreCrmIntegration[];
  open: boolean;
  setOpen: (open: boolean) => void;
  storeName?: string;
}) => {
  const groupedIntegrations = useMemo(() => {
    return CRM_PROVIDERS.reduce<{ [key: string]: LoyaltyFormItem }>(
      (groupedData, { value }) => {
        const foundEntry = integrations.find(
          ({ crm_provider }) => value === crm_provider
        );
        groupedData[value] = foundEntry as LoyaltyFormItem;
        return groupedData;
      },
      {
        [CrmProvider.alpineiq]: { enabled: false },
        [CrmProvider.data_owl]: { enabled: false },
        [CrmProvider.springbig]: { enabled: false },
      }
    );
  }, [integrations]);

  const formMethods = useForm({
    defaultValues: {
      enabled: enabledProvider,
      ...groupedIntegrations,
    },
  });
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating loyalty settings. Please try again.',
    [['crm_integrations', 'array_index', 'token']]
  );

  const toast = useToast();
  const { storeId } = useContext(StoreDetailsContext);
  const formName = 'Loyalty settings form';
  const { mutateAsync: saveStoreIntegrations, isSuccess: saveSuccess } =
    useSaveStoreIntegrations(storeId);

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Loyalty settings updated',
        variant: 'success',
      });
      setOpen(false);
    }
  }, [saveSuccess]);

  const onSubmit = (formData: LoyaltyFormData) => {
    const formEnabled = formData.enabled;
    const disableAll = formEnabled === 'none';

    let modifiedIntegration: CrmIntegrationParams;
    // If user selected 'none', then disable currently enabled integration
    if (disableAll) {
      modifiedIntegration = {
        enabled: false,
      };
    } else {
      // Otherwise save form data
      const formDataEntry = formData[formEnabled as CrmProvider];

      modifiedIntegration = {
        ...formDataEntry,
        enabled: true,
        crm_provider: formEnabled,
        max_redemption_count: formDataEntry?.max_redemption_count ? 1 : null,
      } as StoreCrmIntegration;
    }

    const requestData = {
      crm_integrations: [modifiedIntegration],
    };

    return catchSubmitErrors({
      submitMethod: () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.LoyaltySettings,
          changed_attributes: disableAll
            ? ['disabled_all']
            : Object.keys(dirtyFields),
        });
        return saveStoreIntegrations(requestData);
      },
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors['crm_integrations'])
        );
      },
    });
  };

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={setOpen}
      hasChanges={isDirty}
      modalName={ModalNames.LoyaltySettings}
    >
      <Form.BaseForm
        name={formName}
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Loyalty"
          subtitle={storeName}
          actions={<Form.SubmitButton variant="primary" label="Save" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Form.RadioFieldGroup
            name="enabled"
            defaultChecked={enabledProvider ? enabledProvider : 'none'}
            options={[
              {
                id: 'none',
                label: 'No loyalty provider',
                value: 'none',
              },
              ...CRM_PROVIDERS.map((provider, index) => {
                return {
                  id: provider.value,
                  label: provider.label,
                  value: provider.value,
                  wrapper: (children: React.ReactNode) => (
                    <>
                      <Modal.ContentDivider />
                      <LoyaltyItem
                        integration={groupedIntegrations[provider.value]}
                        provider={provider}
                      >
                        {children}
                      </LoyaltyItem>
                    </>
                  ),
                };
              }),
            ]}
          />
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

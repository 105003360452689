import { useInfiniteQuery } from '@tanstack/react-query';

import type { BrandResponse } from '@jane/business-admin/types';
import { zBrandResponse } from '@jane/business-admin/types';
import { janeApiV2 } from '@jane/shared/data-access';

import { STORES_URL } from './stores';

type fetchBrandsParams = {
  id?: string;
  name?: string;
  order?: string;
  page?: number;
  page_offset?: number;
  per_page?: number;
};

const fetchBrands = async (
  storeId: string,
  params: fetchBrandsParams
): Promise<BrandResponse> => {
  const urlParams = new URLSearchParams();
  params.name && urlParams.append('name', params.name);
  params.id && urlParams.append('id', params.id);
  params.order && urlParams.append('order', params.order);
  params.page && urlParams.append('page', params.page.toString());
  params.per_page && urlParams.append('per_page', params.per_page.toString());
  params.page_offset &&
    urlParams.append('page_offset', params.page_offset.toString());

  const url = `${STORES_URL}/${storeId}/store_brands?${urlParams.toString()}`;
  const response = await janeApiV2.get<BrandResponse>(url);

  return await zBrandResponse.parseAsync(response);
};

const fetchBrandsQueryKey = (storeId: string, params: fetchBrandsParams) => {
  return ['storeBrands', storeId, params];
};

export const useFetchBrands = (
  storeId: string,
  params: fetchBrandsParams,
  enabled: boolean
) => {
  const brandsQuery = useInfiniteQuery<BrandResponse>({
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchBrands(storeId, {
        order: params.order,
        per_page: params.per_page,
        page: pageParam,
        page_offset: params.page_offset,
        name: params.name,
        id: params.id,
      });
      return {
        ...data,
        pageParam,
      };
    },
    queryKey: fetchBrandsQueryKey(storeId, params),
    getNextPageParam: (lastPage) => {
      const hasNextPage = lastPage?.meta.page < lastPage.meta.number_of_pages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    enabled,
    staleTime: Infinity,
    useErrorBoundary: true,
  });

  let brands = undefined;
  if (brandsQuery.data) {
    const { pages } = brandsQuery.data;
    brands = pages.flatMap((body) => body.brands);
  }

  return { ...brandsQuery, data: brands };
};

type fetchGlobalBrandsParams = {
  id?: string;
  name?: string;
  order?: string;
  page?: number;
  page_offset?: number;
  per_page?: number;
};

const fetchGlobalBrands = async (
  params: fetchGlobalBrandsParams
): Promise<BrandResponse> => {
  const urlParams = new URLSearchParams();
  params.name && urlParams.append('filters[name]', params.name);
  params.id && urlParams.append('id', params.id);
  params.order && urlParams.append('order', params.order);
  params.page && urlParams.append('page', params.page.toString());
  params.per_page && urlParams.append('per_page', params.per_page.toString());
  params.page_offset &&
    urlParams.append('page_offset', params.page_offset.toString());

  const url = `/business/specials/brands?${urlParams.toString()}`;
  const response = await janeApiV2.get<BrandResponse>(url);

  return await zBrandResponse.parseAsync(response);
};

const fetchGlobalBrandsQueryKey = (params: fetchGlobalBrandsParams) => {
  return ['globalBrands', params];
};

export const useFetchGlobalBrands = (
  params: fetchGlobalBrandsParams,
  enabled: boolean
) => {
  const brandsQuery = useInfiniteQuery<BrandResponse>({
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchGlobalBrands({
        order: params.order,
        per_page: params.per_page,
        page: pageParam,
        page_offset: params.page_offset,
        name: params.name,
        id: params.id,
      });
      return {
        ...data,
        pageParam,
      };
    },
    queryKey: fetchGlobalBrandsQueryKey(params),
    getNextPageParam: (lastPage) => {
      const hasNextPage = lastPage?.meta.page < lastPage.meta.number_of_pages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    enabled,
    staleTime: Infinity,
    useErrorBoundary: true,
  });

  let brands = undefined;
  if (brandsQuery.data) {
    const { pages } = brandsQuery.data;
    brands = pages.flatMap((body) => body.brands);
  }

  return { ...brandsQuery, data: brands };
};

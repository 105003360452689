import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

type RuleSelectorKey = 'includes' | 'excludes';

export const RULE_OPTIONS = [
  {
    name: 'All products',
    value: 'ALL',
  },
  {
    name: 'Individual products',
    value: 'INDIVIDUAL',
  },
  {
    name: 'Conditions',
    value: 'CONDITIONS',
  },
];

type ValueRule = Record<string, string[] | number[] | null>;

interface ValueRules {
  excludes: ValueRule[];
  includes: ValueRule[];
}

interface Values {
  rules: ValueRules;
}

export const getDefaultRulesFor = (
  values: Values,
  ruleSelectorKey: RuleSelectorKey
) => {
  const rules = values['rules'];

  if (!rules) {
    return undefined;
  }

  const excludes = rules['excludes'];
  const includes = rules['includes'];

  if (
    ruleSelectorKey === 'excludes' &&
    !isEmpty(excludes) &&
    !isEmpty(excludes[0]['product_ids'])
  ) {
    return RULE_OPTIONS[1].value;
  } else if (
    ruleSelectorKey === 'excludes' &&
    !isEmpty(excludes) &&
    isEmpty(excludes[0]['product_ids'])
  ) {
    return RULE_OPTIONS[2].value;
  } else if (
    ruleSelectorKey === 'includes' &&
    !isEmpty(includes) &&
    !isEmpty(includes[0]['product_ids'])
  ) {
    return RULE_OPTIONS[1].value;
  } else if (
    !isEmpty(includes) &&
    (!isEmpty(includes[0]['brands']) ||
      !isEmpty(includes[0]['kinds']) ||
      !isEmpty(includes[0]['weights']) ||
      !isEmpty(includes[0]['lineages']))
  ) {
    return RULE_OPTIONS[2].value;
  } else if (
    ruleSelectorKey === 'excludes' &&
    isEmpty(excludes) &&
    !isEmpty(includes)
  ) {
    return RULE_OPTIONS[1].value;
  } else {
    return RULE_OPTIONS[0].value;
  }
};

const SUBCATEGORY_NOT_SET = '(Subcategory not set)';

const cleanUpRules = (rules: any) => {
  if (isNil(rules)) return;
  const { includes, excludes, ...rest } = rules;

  const removeAllUndefinedValues = (obj: any) => {
    Object.keys(obj).forEach((key) => {
      if (isNil(obj[key])) {
        delete obj[key];
      }
    });

    return obj;
  };

  const removeNotSetRootSubTypes = (conditions: any) =>
    conditions.map((condition: any) => {
      if (condition.root_subtype === SUBCATEGORY_NOT_SET) {
        delete condition.root_subtype;
      }
      return condition;
    });

  /*
    We should revisit this whenever we handle more than one rule set for each type
  */
  if (get(includes, ['0', 'kinds'])) {
    includes[0].kinds = removeNotSetRootSubTypes(includes[0].kinds);
  }

  if (get(excludes, ['0', 'kinds'])) {
    excludes[0].kinds = removeNotSetRootSubTypes(excludes[0].kinds);
  }

  return {
    excludes: excludes
      ? excludes.filter((rule: any) => !isEmpty(removeAllUndefinedValues(rule)))
      : [],
    includes: includes
      ? includes.filter((rule: any) => !isEmpty(removeAllUndefinedValues(rule)))
      : [],
    ...rest,
  };
};

export const prepareRules = (updates: any) => {
  switch (updates.special_type) {
    case 'cart_total':
      if (!updates.rules) {
        // rules can be null if the rules form field is not available
        updates.rules = {
          excludes: [],
          includes: [],
        };
      }
      updates.rules['settings'] = {
        cart_threshold: updates.conditions_type_cart_total.threshold,
      };

      return cleanUpRules(updates.rules);
    default:
      return cleanUpRules(updates.rules);
  }
};

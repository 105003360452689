import { useMemo } from 'react';

import { useProductTypes } from '@jane/business-admin/data-access';
import { useProductTypeSelectOptions } from '@jane/business-admin/hooks';
import type { FlexProps, SelectFieldProps } from '@jane/shared/reefer';
import { Flex, Form, useFormContext } from '@jane/shared/reefer';

interface Props extends FlexProps {
  allowAllOption?: boolean;
  categoryProps: Partial<Omit<SelectFieldProps, 'options'>>;
  isLoading?: boolean;
  subcategoryProps: Partial<Omit<SelectFieldProps, 'options'>>;
}

export const CategoryAndSubcategorySelect = ({
  allowAllOption = true,
  isLoading = false,
  categoryProps,
  subcategoryProps,
  children,
  ...flexProps
}: Props) => {
  const { setValue, watch } = useFormContext();
  const categoryWatch = watch(categoryProps.name || 'category_select');

  const { data: allProductTypes, isFetched } = useProductTypes();
  const { categoryOptions, subcategoryOptions } = useProductTypeSelectOptions(
    allProductTypes,
    allowAllOption
  );

  const loadingComplete = useMemo(
    () => !isLoading && isFetched && !!categoryOptions.length,
    [isFetched, categoryOptions, isLoading]
  );

  const handleCategoryChange = (value: any) => {
    setValue(
      subcategoryProps.name || 'subcategory_select',
      subcategoryOptions[value as keyof typeof subcategoryOptions][0]?.value
    );
  };

  return (
    <Flex gap={24} justifyContent="space-between" width="100%" {...flexProps}>
      <Form.SelectField
        loading={!loadingComplete}
        name="category_select"
        label="Category"
        options={categoryOptions}
        required
        width="100%"
        onChange={handleCategoryChange}
        placeholder={categoryProps.placeholder}
        {...categoryProps}
      />
      <Form.SelectField
        loading={!loadingComplete}
        name="subcategory_select"
        label="Subcategory"
        placeholder={subcategoryProps.placeholder}
        options={
          categoryWatch
            ? subcategoryOptions[
                categoryWatch as keyof typeof subcategoryOptions
              ]
            : categoryProps.defaultValue
            ? subcategoryOptions[
                categoryProps.defaultValue as keyof typeof subcategoryOptions
              ]
            : subcategoryOptions['flower']
        }
        required
        width="100%"
        {...subcategoryProps}
      />
      {children}
    </Flex>
  );
};

import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useStoreMenu,
  useStoreSettings,
  useTags,
  useUpdateFiltersAndLabels,
} from '@jane/business-admin/data-access';
import type { Tag } from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useDebouncedTrack,
  useIsMounted,
} from '@jane/business-admin/hooks';
import type { CustomLabel, FiltersAndLabels } from '@jane/business-admin/types';
import {
  ErrorReasons,
  EventNames,
  ModalNames,
  SettingNames,
  hasDuplicateCustomLabels,
  normalizePath,
  parseProductLabels,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Flex,
  Form,
  FormValidationError,
  Grid,
  Modal,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';
import { RevertableInput } from '../../../../RevertableInput';
import { SelectAllCheckbox } from '../../../../SelectAllCheckbox';
import {
  CustomProductLabels,
  SUBCATEGORY_ERRORS_NAME,
} from './CustomProductLabels';
import {
  CheckboxSkeleton,
  FourWideSkeleton,
  ThreeWideSkeleton,
} from './FiltersAndLabelsModalSkeletons';

const FORM_ERROR_NAME = 'menu-row-filters-and-labels-errors';

const DEFAULT_INDICA_LINEAGE_ID = 1;
const DEFAULT_SATIVA_LINEAGE_ID = 2;
const DEFAULT_HYBRID_LINEAGE_ID = 3;
const DEFAULT_CBD_LINEAGE_ID = 4;

export const FiltersAndLabelsModal = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const { id = '' } = useParams<'id'>();
  const { pathname } = useLocation();
  const debouncedTrack = useDebouncedTrack(1000);
  const { data: menuRowData, isFetched: menuRowDataFetched } = useStoreMenu(id);
  const { mutateAsync: updateFiltersAndLabels, isSuccess: updateSuccess } =
    useUpdateFiltersAndLabels(id);
  const { data: tagData, isFetched: tagsFetched } = useTags();
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(id);

  const toast = useToast();
  const isDataFetched = menuRowDataFetched && tagsFetched;
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating filters and labels. Please try again'
  );

  const filtersAndLabels = menuRowData?.filters_and_labels
    ? menuRowData.filters_and_labels
    : null;
  const complianceLabels =
    filtersAndLabels?.compliance_language_settings_labels;
  const customLabels = filtersAndLabels?.custom_labels;
  const customLineageLabels = useMemo(
    () =>
      customLabels?.filter((label) => label.customizable_type === 'Lineage'),
    [customLabels]
  );
  const customProductLabels = useMemo(() => {
    return customLabels?.reduce<CustomLabel[]>((prevValue, currValue) => {
      if (currValue.customizable_type === 'ProductType') {
        prevValue.push({
          ...currValue,
          product_subtype: currValue.product_subtype || 'all',
        });
      }
      return prevValue;
    }, []);
  }, [customLabels]);
  const reservationModeLabels = filtersAndLabels?.reservation_mode_labels;
  const disabledFeelingsAndActivityTags =
    filtersAndLabels?.disabled_feeling_and_activity_tags;

  const activityTags = useMemo(
    () => tagData?.tags.filter((tag) => tag.tag_type === 'Activity'),
    [tagData]
  );

  const feelingTags = useMemo(
    () => tagData?.tags.filter((tag) => tag.tag_type === 'Feeling'),
    [tagData]
  );

  const activityTagIds = useMemo(
    () => activityTags?.map(({ id }) => id.toString()) ?? [],
    [activityTags]
  );

  const feelingTagIds = useMemo(
    () => feelingTags?.map(({ id }) => id.toString()) ?? [],
    [feelingTags]
  );

  const isMounted = useIsMounted();

  const formMethods = useForm();
  const {
    setError,
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const onSubmit = (formData: any) => {
    if (hasDuplicateCustomLabels(formData.product_labels)) {
      setError(SUBCATEGORY_ERRORS_NAME, {
        type: 'onSubmit',
        message: 'Duplicate product subcategory labels are not allowed.',
      });
      return;
    }

    const tagIds = tagData?.tags.map((tag) => tag.id);
    const disabledTags: { id?: number; tag_id: number }[] = [];
    tagIds?.forEach((tagId) => {
      if (!formData[tagId]) {
        disabledTags.push({ tag_id: tagId });
      }
    });

    const lineageLabels = [
      ...(formData.Indica && [
        {
          customizable_type: 'Lineage',
          customizable_id: DEFAULT_INDICA_LINEAGE_ID,
          custom_label: formData.Indica,
        },
      ]),
      ...(formData.Sativa && [
        {
          customizable_type: 'Lineage',
          customizable_id: DEFAULT_SATIVA_LINEAGE_ID,
          custom_label: formData.Sativa,
        },
      ]),
      ...(formData.Hybrid && [
        {
          customizable_type: 'Lineage',
          customizable_id: DEFAULT_HYBRID_LINEAGE_ID,
          custom_label: formData.Hybrid,
        },
      ]),
      ...(formData.CBD && [
        {
          customizable_type: 'Lineage',
          customizable_id: DEFAULT_CBD_LINEAGE_ID,
          custom_label: formData.CBD,
        },
      ]),
    ];

    const requestData: FiltersAndLabels = {
      compliance_language_settings_labels: {
        specials: formData.Specials,
        bundle: formData.Bundle,
        sales_badge: formData['Sales badge'],
      },
      custom_labels: [
        ...parseProductLabels(formData.product_labels),
        ...lineageLabels,
      ],
      reservation_mode_labels: {
        pickup_label: formData.Pickup,
        delivery_label: formData.Delivery,
        curbside_label: formData.Curbside,
      },
      disabled_feeling_and_activity_tags: disabledTags,
    };

    const trackSubmit = (eventProps = {}) => {
      track({
        event: EventNames.EditedStoreMenuConfig,
        modal_name: ModalNames.FiltersAndLabels,
        setting_name: ModalNames.FiltersAndLabels,
        successful: true,
        action: 'update',
        changed_attributes: Object.keys(dirtyFields),
        url: normalizePath(pathname, id),
        ...eventProps,
      });
    };

    const submitMethod = async () => {
      await updateFiltersAndLabels(requestData);
      trackSubmit();
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.InvalidParams,
        });

        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      callback: () => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.ServerError,
        });
        throw new Error('Error updating filters and labels. Please try again');
      },
    });
  };

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (updateSuccess) {
      toast.add({
        label: 'Filters and labels updated.',
        variant: 'success',
      });
      closeModal();
    }
  }, [updateSuccess, isMounted]);

  const renderCheckbox = useCallback((tag: Tag) => {
    const isDisabled = disabledFeelingsAndActivityTags?.some(
      ({ tag_id }) => tag_id === tag.id
    );

    return (
      <Grid.Item xs={3} key={tag.id}>
        <Flex alignItems="center" height="100%">
          <Form.CheckboxField
            name={tag.id.toString()}
            label={tag.value}
            defaultChecked={!isDisabled}
            onChange={(checked: boolean) => {
              track({
                event: checked
                  ? EventNames.SelectedCheckbox
                  : EventNames.DeselectedCheckbox,
                checkbox_label: tag.value,
                modal_name: ModalNames.FiltersAndLabels,
              });
            }}
          />
        </Flex>
      </Grid.Item>
    );
  }, []);

  return (
    <ConfirmWrapperWithTracking
      open
      setOpen={closeModal}
      variant="standard"
      hasChanges={isDirty}
      modalName={ModalNames.FiltersAndLabels}
    >
      <Form.BaseForm
        name="filters and labels form"
        onSubmit={onSubmit}
        formMethods={formMethods}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Filters and labels"
          subtitle={storeSettingsLoading ? '' : storePayload?.store.name}
          actions={
            <Form.SubmitButton variant="primary" label="Publish" ml={16} />
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Flex alignItems="center">
            {/* // TODO: There is no backend support for a toggle like this, so commenting out for the time being.
                <Form.SwitchField
                  name="activities"
                  label="activities"
                  labelHidden
                  mr={16}
                /> */}
            <div>
              <Typography variant="body-bold">Activities filters</Typography>
              <Typography>
                These filters are populated by patients and customers who have
                left verified product reviews.
              </Typography>
            </div>
          </Flex>
          {isDataFetched ? (
            <>
              <SelectAllCheckbox
                label="Show all activities"
                name="Show all activities"
                applicableIds={activityTagIds}
              />
              <Flex height="112px" ml={24}>
                {!!activityTags && activityTags.length > 0 && (
                  <Grid.Container spacing={0}>
                    {activityTags.map(renderCheckbox)}
                  </Grid.Container>
                )}
              </Flex>
            </>
          ) : (
            <CheckboxSkeleton />
          )}
          <Modal.ContentDivider />

          <Flex alignItems="center">
            {/* // TODO: There is no backend support for a toggle like this, so commenting out for the time being.
                <Form.SwitchField
                  name="feelings"
                  label="feelings"
                  labelHidden
                  mr={16}
                /> */}
            <div>
              <Typography variant="body-bold">Feelings filters</Typography>
              <Typography>
                These filters are populated by patients and customers who have
                left verified product reviews.
              </Typography>
            </div>
          </Flex>
          {isDataFetched ? (
            <>
              <SelectAllCheckbox
                applicableIds={feelingTagIds}
                label="Show all feelings"
                name="Show all feelings"
              />
              <Flex height="112px" ml={24}>
                {!!feelingTags && feelingTags.length > 0 && (
                  <Grid.Container spacing={0}>
                    {feelingTags.map(renderCheckbox)}
                  </Grid.Container>
                )}
              </Flex>
            </>
          ) : (
            <CheckboxSkeleton />
          )}
          <Modal.ContentDivider />

          <div>
            <Typography variant="body-bold">Lineage</Typography>
            <Typography>
              Customize how lineages appear in your store.
            </Typography>
          </div>
          {isDataFetched ? (
            <Flex gap={24} mt={24}>
              <RevertableInput
                label="Indica"
                name="Indica"
                defaultValue={{ display: 'Indica', value: '' }}
                initialValue={
                  customLineageLabels
                    ?.filter(
                      (label) =>
                        label.customizable_id === DEFAULT_INDICA_LINEAGE_ID
                    )
                    .map((label) => label.custom_label)[0]
                }
                onChange={(value) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'Indica',
                    setting_name: SettingNames.LineageLabelIndica,
                  });
                }}
              />
              <RevertableInput
                label="Sativa"
                name="Sativa"
                defaultValue={{ display: 'Sativa', value: '' }}
                initialValue={
                  customLineageLabels
                    ?.filter(
                      (label) =>
                        label.customizable_id === DEFAULT_SATIVA_LINEAGE_ID
                    )
                    .map((label) => label.custom_label)[0]
                }
                onChange={(value) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'Sativa',
                    setting_name: SettingNames.LineageLabelSativa,
                  });
                }}
              />
              <RevertableInput
                label="Hybrid"
                name="Hybrid"
                defaultValue={{ display: 'Hybrid', value: '' }}
                initialValue={
                  customLineageLabels
                    ?.filter(
                      (label) =>
                        label.customizable_id === DEFAULT_HYBRID_LINEAGE_ID
                    )
                    .map((label) => label.custom_label)[0]
                }
                onChange={(value) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'Hybrid',
                    setting_name: SettingNames.LineageLabelHybrid,
                  });
                }}
              />
              <RevertableInput
                label="CBD"
                name="CBD"
                defaultValue={{ display: 'CBD', value: '' }}
                initialValue={
                  customLineageLabels
                    ?.filter(
                      (label) =>
                        label.customizable_id === DEFAULT_CBD_LINEAGE_ID
                    )
                    .map((label) => label.custom_label)[0]
                }
                onChange={(value) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'CBD',
                    setting_name: SettingNames.LineageLabelCBD,
                  });
                }}
              />
            </Flex>
          ) : (
            <FourWideSkeleton />
          )}
          <Modal.ContentDivider />

          <div>
            <Typography variant="body-bold">Specials labels</Typography>
            <Typography>
              Customize how specials labels appear in your store.
            </Typography>
          </div>
          {isDataFetched ? (
            <Flex gap={24} mt={24} width="100%">
              <RevertableInput
                label="Specials"
                name="Specials"
                defaultValue={{ display: 'Specials', value: '' }}
                initialValue={complianceLabels?.specials}
                onChange={(value: string) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'Specials',
                    setting_name: SettingNames.SpecialsLabel,
                  });
                }}
              />
              <RevertableInput
                label="Sales badge"
                name="Sales badge"
                defaultValue={{ display: 'Sales badge', value: '' }}
                initialValue={complianceLabels?.sales_badge}
                onChange={(value: string) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'Sales badge',
                    setting_name: SettingNames.SalesBadgeLabel,
                  });
                }}
              />
              <RevertableInput
                label="Bundle"
                name="Bundle"
                defaultValue={{ display: 'Bundle', value: '' }}
                initialValue={complianceLabels?.bundle}
                onChange={(value: string) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'Bundle',
                    setting_name: SettingNames.BundleLabel,
                  });
                }}
              />
            </Flex>
          ) : (
            <ThreeWideSkeleton />
          )}
          <Modal.ContentDivider />

          <div>
            <Typography variant="body-bold">Fulfillment</Typography>
            <Typography>
              Customize how checkout reservation options appear in your store.
            </Typography>
          </div>
          {isDataFetched ? (
            <Flex gap={24} mt={24}>
              <RevertableInput
                label="Curbside"
                name="Curbside"
                defaultValue={{ display: 'Curbside', value: '' }}
                initialValue={reservationModeLabels?.curbside_label}
                onChange={(value: string) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'Curbside',
                    setting_name: SettingNames.FulfillmentLabelCurbside,
                  });
                }}
              />
              <RevertableInput
                label="Pickup"
                name="Pickup"
                defaultValue={{ display: 'Pickup', value: '' }}
                initialValue={reservationModeLabels?.pickup_label}
                onChange={(value: string) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'Pickup',
                    setting_name: SettingNames.FulfillmentLabelPickup,
                  });
                }}
              />
              <RevertableInput
                label="Delivery"
                name="Delivery"
                defaultValue={{ display: 'Delivery', value: '' }}
                initialValue={reservationModeLabels?.delivery_label}
                onChange={(value: string) => {
                  debouncedTrack({
                    event: EventNames.ModifiedSetting,
                    modal_name: ModalNames.FiltersAndLabels,
                    revert: !value || value === 'Delivery',
                    setting_name: SettingNames.FulfillmentLabelDelivery,
                  });
                }}
              />
            </Flex>
          ) : (
            <ThreeWideSkeleton />
          )}
          <Modal.ContentDivider />

          {isDataFetched ? (
            <>
              {Array.isArray(customProductLabels) ? (
                <CustomProductLabels
                  storeId={id}
                  customProductLabels={customProductLabels}
                />
              ) : null}
            </>
          ) : (
            <ThreeWideSkeleton />
          )}
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

import compact from 'lodash/compact';
import isArray from 'lodash/isArray';
import lowerCase from 'lodash/lowerCase';

import { Button, Card, EditIcon, Flex, Typography } from '@jane/shared/reefer';

export interface EditableCardProps {
  children: React.ReactNode;
  'data-testid'?: string;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  isLoading?: boolean;
  onEdit?: () => void;
  showTextButton?: boolean;
  startAdornment?: React.ReactNode;
  textButtonLabel?: string;
  title: string;
}

export const EditableCard = ({
  'data-testid': testId,
  title,
  onEdit = () => undefined,
  isLoading,
  showTextButton,
  textButtonLabel,
  children,
  startAdornment,
  endAdornment,
  disabled,
}: EditableCardProps) => {
  return (
    <Card border="grays-light" data-testid={testId} flat mb={24}>
      <Card.Content background={disabled ? 'grays-ultralight' : undefined}>
        <Flex p={24} flexDirection="column">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb={
              !isArray(children) ||
              (children && compact(children as any).length)
                ? 24
                : 0
            }
          >
            <Flex alignItems="center" gap={16}>
              {startAdornment}
              <Typography variant="header-bold">{title}</Typography>
              {endAdornment}
            </Flex>

            {disabled ? null : showTextButton ? (
              <Button
                disabled={isLoading}
                label={textButtonLabel || `Add ${lowerCase(title)}`}
                variant="secondary"
                onClick={onEdit}
                data-testid="editable-card-add-button"
              />
            ) : (
              <Button.Icon
                disabled={isLoading}
                icon={<EditIcon />}
                variant="secondary"
                label="Edit"
                onClick={onEdit}
                data-testid="editable-card-edit-button"
              />
            )}
          </Flex>

          {children}
        </Flex>
      </Card.Content>
    </Card>
  );
};

import { useEffect, useMemo } from 'react';

import { useSaveStoreSubdomain } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Form,
  FormValidationError,
  Modal,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';

interface SubdomainFormData {
  home_url?: string;
  hostname?: string;
}

const FORM_ERROR_NAME = 'subdomain-error';

export const SubdomainModal = ({
  open,
  setOpen,
  subtitle,
  settings,
  storeId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  settings: SubdomainFormData;
  storeId: number;
  subtitle: string;
}) => {
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating subdomain. Please try again.'
  );

  const toast = useToast();
  const {
    mutateAsync: saveStoreSubdomain,
    isSuccess: saveSuccess,
    isLoading: isSaving,
  } = useSaveStoreSubdomain(storeId);

  const formName = 'Subdomain settings form';

  const hostname_helper_text = useMemo(() => {
    if (!settings || !settings.hostname) {
      return 'Example format: menu.yourstore.com. You will also need to setup the CNAME record with your domain registrar. It should point to www.iheartjane.com. Your subdomain listed with Jane should match your CNAME.';
    } else {
      return 'To make changes to the hostname, please reach out to Jane Partner Success.';
    }
  }, [settings]);

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Subdomain updated',
        variant: 'success',
      });
      setOpen(false);
    }
  }, [saveSuccess, setOpen]);

  const onSubmit = (data: SubdomainFormData) => {
    return catchSubmitErrors({
      submitMethod: () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.Subdomain,
          changed_attributes: Object.keys(dirtyFields),
        });
        return saveStoreSubdomain(data);
      },
      requestData: data,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={setOpen}
      hasChanges={isDirty}
      modalName={ModalNames.Subdomain}
    >
      <Form.BaseForm
        name={formName}
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Subdomain"
          subtitle={subtitle}
          actions={
            <Form.SubmitButton
              variant="primary"
              label="Save"
              loading={isSaving}
            />
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Form.TextField
            label="Hostname"
            name="hostname"
            width="100%"
            mb={24}
            disabled={!!settings.hostname}
            defaultValue={settings.hostname}
            helperText={hostname_helper_text}
          />
          <Form.TextField
            label="Home URL"
            name="home_url"
            width="100%"
            defaultValue={settings.home_url}
            placeholder="http://www.example.com"
            helperText='Set a custom url for the "Back home" button'
          />
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import upperFirst from 'lodash/upperFirst';
import { useCallback, useContext, useEffect, useState } from 'react';

import {
  useCreateCheckoutAgreement,
  useUpdateCheckoutAgreement,
} from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  AGREEMENT_TYPES,
  CHECKOUT_AGREEMENT_RESERVATION_MODES,
  CONSENT_REQUIREMENTS,
  EventNames,
  MAX_LENGTH,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import type { CheckoutAgreementV2 } from '@jane/shared/models';
import {
  Card,
  Flex,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useForm,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';

type CheckoutAgreementFormData = Partial<CheckoutAgreementV2>;

export interface AgreementSettingsModalProps {
  agreement?: CheckoutAgreementV2;
  create?: boolean;
  onClose: () => void;
  open: boolean;
}

const FORM_ERROR_NAME = 'agreement-error';

export const AgreementSettingsModal = ({
  open,
  onClose,
  agreement,
  create = false,
}: AgreementSettingsModalProps) => {
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;
  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error ${
      create ? 'creating' : 'updating'
    } checkout agreement. Please try again.`
  );

  const { storeId } = useContext(StoreDetailsContext);
  const { mutateAsync: saveCheckoutAgreement, isSuccess: saveSuccess } =
    useUpdateCheckoutAgreement(storeId);
  const { mutateAsync: createCheckoutAgreement, isSuccess: createSuccess } =
    useCreateCheckoutAgreement(storeId);

  const [formData, setFormData] = useState<CheckoutAgreementFormData>({
    active: true,
    consent_default: false,
    name: AGREEMENT_TYPES[0],
  });
  const [showDiscard, setShowDiscard] = useState(false);

  useEffect(() => {
    if (agreement) {
      setFormData(agreement);
    }
  }, [JSON.stringify(agreement)]); //ASK TEAM ABOUT THIS. WHY DO WE STRINGIFY HERE?

  useEffect(() => {
    if (saveSuccess || createSuccess) {
      onClose();
    }
  }, [saveSuccess, createSuccess]);

  const onSubmit = () => {
    let submitMethod;
    if (create) {
      submitMethod = () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.AddCheckoutAgreement,
          changed_attributes: Object.keys(dirtyFields),
        });
        return createCheckoutAgreement(formData as CheckoutAgreementV2);
      };
    } else {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.EditCheckoutAgreement,
        changed_attributes: Object.keys(dirtyFields),
      });
      submitMethod = () => {
        return saveCheckoutAgreement(formData as CheckoutAgreementV2);
      };
    }
    return catchSubmitErrors({
      submitMethod,
      requestData: formData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  const setDataOnChange = useCallback(
    (field: string) => (value: string | boolean | number | null) => {
      setFormData((formData) => ({
        ...formData,
        [field]: value,
      }));
    },
    []
  );

  const allReservationsOptions = {
    label: 'All reservations',
    id: 'all',
    value: 'all',
  };

  const checkout_agreement_res_mode = CHECKOUT_AGREEMENT_RESERVATION_MODES.map(
    (reservation_mode) => ({
      label: startCase(reservation_mode),
      id: reservation_mode,
      value: reservation_mode,
    })
  );

  const reservationModeOptions = [
    allReservationsOptions,
    ...checkout_agreement_res_mode,
  ];
  const consentRequirementOptions = CONSENT_REQUIREMENTS.map(
    (consent_requirement, i) => ({
      label: upperFirst(lowerCase(consent_requirement)),
      id: consent_requirement,
      value: consent_requirement,
    })
  );

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={onClose}
      hasChanges={isDirty}
      variant="full-screen"
      background="grays-ultralight"
      appId="checkout-agreements"
      showDiscardChanges={showDiscard}
      onCloseDiscardChanges={() => setShowDiscard(false)}
      modalName={`${create ? 'Add' : 'Edit'} Checkout Agreement`}
    >
      <Form.BaseForm
        name="checkout options"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={`${create ? 'Create' : 'Edit'} checkout agreement`}
          actions={<Form.SubmitButton label="Save" variant="primary" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Flex justifyContent="center">
            <Card.Group border="grays-light" width={896}>
              <Card.Content>
                <Flex flexDirection="column" p={24}>
                  <Typography variant="header-bold" mb={40}>
                    Agreement details
                  </Typography>
                  <Form.SwitchField
                    name="active"
                    label="Active"
                    defaultChecked={agreement?.active || formData.active}
                    onChange={setDataOnChange('active')}
                  />
                </Flex>
              </Card.Content>
              <Card.Content border="grays-light" borderWidth="1px 0 0 0">
                <Flex px={24} pt={24} gap={24} mb={32}>
                  <Flex flexDirection="column" width="50%">
                    <Form.SelectField
                      label="Agreement type"
                      name="name"
                      defaultValue={agreement?.name || AGREEMENT_TYPES[0]}
                      onChange={setDataOnChange('name')}
                      options={AGREEMENT_TYPES.map((value) => ({
                        label: value,
                        value,
                      }))}
                      required
                    />
                  </Flex>
                  <Flex flexDirection="column" width="50%">
                    <Form.TextField
                      label={
                        <Typography>
                          Link to agreement{' '}
                          <Typography as="span" color="grays-mid">
                            (optional)
                          </Typography>
                        </Typography>
                      }
                      name="external_link"
                      placeholder="Agreement link"
                      defaultValue={agreement?.external_link || ''}
                      onChange={setDataOnChange('external_link')}
                    />
                  </Flex>
                </Flex>
                <Flex flexDirection="column" px={24} pb={24} gap={24}>
                  <Form.TextAreaField
                    label={
                      <>
                        Text to display ({formData?.label?.length ?? 0} /{' '}
                        {MAX_LENGTH.toLocaleString()} characters used)
                      </>
                    }
                    name="label"
                    placeholder="Agreement content"
                    defaultValue={agreement?.label}
                    onChange={setDataOnChange('label')}
                    maxLength={MAX_LENGTH}
                    required
                    rows={3}
                  />
                  <Form.CheckboxField
                    label="Agreement is checked by default"
                    name="consent_default"
                    defaultChecked={agreement?.consent_default || false}
                    onChange={setDataOnChange('consent_default')}
                  />
                </Flex>
              </Card.Content>
              <Card.Content border="grays-light" borderWidth="1px 0 0 0">
                <Flex pt={24} px={24}>
                  <Flex flexDirection="column" width="50%" mr={24}>
                    <Form.RadioFieldGroup
                      options={reservationModeOptions}
                      name="reservation_mode"
                      defaultChecked={
                        agreement?.reservation_mode === null
                          ? 'all'
                          : agreement?.reservation_mode
                      }
                      onChange={(value: string) =>
                        value === 'all'
                          ? setDataOnChange('reservation_mode')(null)
                          : setDataOnChange('reservation_mode')(value)
                      }
                      legend="Applies to"
                      mb={24}
                    />
                  </Flex>
                  <Flex flexDirection="column" width="50%">
                    <Form.RadioFieldGroup
                      options={consentRequirementOptions}
                      name="consent_requirement"
                      defaultChecked={agreement?.consent_requirement}
                      onChange={setDataOnChange('consent_requirement')}
                      legend="Agreement is"
                      required
                      mb={24}
                    />
                  </Flex>
                </Flex>
              </Card.Content>
            </Card.Group>
          </Flex>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

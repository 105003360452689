import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import type {
  MenuResponse,
  StoreSpecialsResponse,
} from '@jane/business-admin/data-access';
import {
  useCurrentStoreSpecials,
  useFetchAllStoreSpecials,
  useStoreMenu,
  useStoreSettings,
} from '@jane/business-admin/data-access';
import type { MenuRow } from '@jane/business-admin/types';
import { useSearch } from '@jane/search/data-access';
import type { AlgoliaProduct, SearchResponse } from '@jane/search/types';
import { JANE_DEFINED_ROWS, getKindFacetBuckets } from '@jane/search/util';
import { CardCarousel } from '@jane/shared/components';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Flex } from '@jane/shared/reefer';

import { ListItem } from './ListItem';
import { PreviewCardsListItem } from './PreviewCardsListItem';
import { SpecialsListItem } from './SpecialsListItem';
import { CARD_HEIGHT, CARD_WIDTH, getRowLabel } from './utils';

const LoadingCarousel = () => {
  const eightItems = new Array(8).fill(null);
  return (
    <>
      {eightItems.map((_, index) => {
        return (
          <li style={{ marginBottom: 20 }} key={`loading-item-${index}`}>
            <CardCarousel
              name="Loading..."
              mode="ideal-width"
              cardWidth={CARD_WIDTH}
              cardHeight={CARD_HEIGHT}
              cardWidthMobile={212}
              cardHeightMobile={328}
              isLoading={true}
            >
              <CardCarousel.Card>
                <p>Loading...</p>
              </CardCarousel.Card>
            </CardCarousel>
          </li>
        );
      })}
    </>
  );
};

interface Props {
  onOpenMenuRowModal: (row: MenuRow) => void;
}
export const MenuRowList = ({ onOpenMenuRowModal }: Props) => {
  const { id: storeId = '' } = useParams<'id'>();
  const { data: menuData, isFetching: menuFetching } = useStoreMenu(storeId);
  const { data: storeSpecials, isFetching: specialsFetching } =
    useFetchAllStoreSpecials(storeId);
  const { data: currentStoreSpecials, isFetching: currentSpecialsFetching } =
    useCurrentStoreSpecials(storeId, 0, 10);
  const { data: storeSettings, isFetching: storeSettingsFetching } =
    useStoreSettings(storeId);
  const myHighMenu = useFlag(FLAGS.myHighMenu);

  // TODO: Move to a custom hook
  const { data: facetResults, isFetching: facetsFetching } =
    useSearch<AlgoliaProduct>({
      facets: ['*'],
      hitsPerPage: 9,
      indexPrefix: 'menu-products-',
      staticFilters: `store_id = ${storeId}`,
    });

  const isFetching = useMemo(() => {
    return (
      menuFetching ||
      facetsFetching ||
      specialsFetching ||
      currentSpecialsFetching ||
      storeSettingsFetching
    );
  }, [
    menuFetching,
    facetsFetching,
    specialsFetching,
    currentSpecialsFetching,
    storeSettingsFetching,
  ]);

  const menuRows = useMemo(() => {
    if (!menuData || !storeSettings) {
      return [];
    }

    const { menu_rows: rows } = menuData;
    const { magic_row_enabled: isMagicRowEnabled } = storeSettings;

    let rowsToRender = rows;

    if (!isMagicRowEnabled) {
      rowsToRender = rowsToRender.filter(
        (rowData) => rowData.row_type !== 'magic_row'
      );
    }

    if (myHighMenu) {
      rowsToRender = rowsToRender.filter((rowData) => !rowData.is_category);
    } else {
      rowsToRender = rowsToRender.filter(
        (rowData) => rowData.row_type !== 'buy_again_row'
      );
    }

    return rowsToRender;
  }, [menuData, storeSettings, myHighMenu]);

  return (
    <Flex pb={24} pt={myHighMenu ? 8 : 24}>
      <ol style={{ width: '100%' }}>
        {isFetching ? (
          <LoadingCarousel />
        ) : (
          menuRows.map((row, index) => (
            <li key={`${row.id}-${index}`}>
              <ListItems
                menuRow={row}
                menuData={menuData}
                currentStoreSpecials={currentStoreSpecials}
                facetResults={facetResults}
                onOpenMenuRowModal={onOpenMenuRowModal}
                storeSpecials={storeSpecials}
              />
            </li>
          ))
        )}
      </ol>
    </Flex>
  );
};

interface ListItemProps extends Props {
  currentStoreSpecials?: StoreSpecialsResponse;
  facetResults?: SearchResponse<AlgoliaProduct>;
  menuData?: MenuResponse;
  menuRow: MenuRow;
  storeSpecials?: StoreSpecialsResponse;
}

const ListItems = ({
  menuData,
  currentStoreSpecials,
  storeSpecials,
  facetResults,
  menuRow,
  onOpenMenuRowModal,
}: ListItemProps) => {
  const { id: storeId = '' } = useParams<'id'>();

  const menuRowFacets = useMemo(() => {
    const custom_rows = menuData?.menu_rows
      .filter((row) => !row.is_category)
      .map((row) => row.row_type);
    const fakeStore = {
      custom_rows,
      id: storeId,
    };
    return facetResults
      ? getKindFacetBuckets(facetResults, fakeStore as any)
      : [];
  }, [facetResults, menuData, storeId]);

  const findFacetForRow = useCallback(
    (row: MenuRow) => {
      return menuRowFacets?.find((value) => {
        return value.name === row.row_type || value.kind === row.row_type;
      });
    },
    [menuRowFacets]
  );

  const shouldUseAlgolia = useCallback((row: MenuRow) => {
    return row.is_category || JANE_DEFINED_ROWS.includes(row.row_type);
  }, []);

  switch (menuRow.row_type) {
    case 'specials':
      return (
        <SpecialsListItem
          complianceLanguageSettings={
            menuData?.filters_and_labels?.compliance_language_settings_labels
          }
          currentStoreSpecials={currentStoreSpecials?.specials || []}
          openMenuRowModal={onOpenMenuRowModal}
          row={menuRow}
        />
      );
    case 'buy_again_row':
      return (
        <PreviewCardsListItem
          cardText="Purchased Product"
          row={menuRow}
          rowHelperText="Each logged in customer will see products they have previously purchased."
        />
      );
    case 'magic_row':
      return (
        <PreviewCardsListItem
          cardText="Recommended Product"
          row={menuRow}
          rowHelperText="Each customer will see personalized products tailored to their unique tastes and preferences."
        />
      );
    default:
      return (
        <ListItem
          allStoreSpecials={storeSpecials?.specials}
          row={menuRow}
          rowFacet={findFacetForRow(menuRow)}
          rowLabel={getRowLabel(
            menuRow,
            menuData?.filters_and_labels.custom_labels
          )}
          openMenuRowModal={onOpenMenuRowModal}
          shouldUseAlgolia={shouldUseAlgolia(menuRow)}
        />
      );
  }
};

import styled from '@emotion/styled';
import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useHasPermissions,
  useKeyPress,
  useNavigateAndTrack,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { StoreImagesPayload } from '@jane/business-admin/types';
import {
  EventNames,
  NavigationSourceIds,
  track,
} from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import {
  ChevronDownIcon,
  Flex,
  Link,
  PartnerLogo,
  Skeleton,
  Tabs,
  Typography,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { StoreSwitcherModal } from '../StoreSwitcherModal';

const DetailSubnav = styled.nav(
  {
    ...spacing({ px: 64 }),
  },
  ({ theme }) => ({
    boxShadow: `0px -1px inset ${theme.colors.grays.light}`,
    background: theme.colors.grays.white,
  })
);

const DetailHeader = styled.header(
  {
    ...spacing({ py: 24, px: 64 }),
  },
  ({ theme }) => ({
    background: theme.colors.grays.white,
  })
);

export const StoreDetailsHeader = ({
  isLoading,
  storeImagesPayload,
}: {
  isLoading: boolean;
  storeImagesPayload?: StoreImagesPayload;
}) => {
  const navigate = useNavigateAndTrack();
  const { storeId } = useContext(StoreDetailsContext);
  const canEditMenu = useHasPermissions([Permission.EditMenuCustomizations]);
  const [storeSwitcherOpen, setStoreSwitcherOpen] = useState(false);

  // REVIEW: Selected tab is being derived from path. Is there a better way to handle?
  const location = useLocation();
  const tabRegex = /\/stores\/\d*\/([a-z]*)/;
  const defaultTab = 'settings';
  const currentTab = useMemo(() => {
    const matches = location.pathname.match(tabRegex);
    return matches ? matches[1] || defaultTab : defaultTab;
  }, [location]);

  const onTabClick = (tabValue: string) => {
    navigate(
      `/stores/${storeId}/${tabValue}`,
      NavigationSourceIds.StoreDetailsTabs
    );
  };

  const toggleStoreSwitcher = (fromClick = false) => {
    if (!storeSwitcherOpen) {
      track({
        event: EventNames.OpenedStoreSwitcher,
        trigger_source_id: fromClick
          ? NavigationSourceIds.StoreSwitcherClick
          : NavigationSourceIds.StoreSwitcherKeyboard,
      });
    }
    setStoreSwitcherOpen(!storeSwitcherOpen);
  };

  useKeyPress(['k'], () => toggleStoreSwitcher(), document, { ctrl: true }); // CTRL-k (all os)
  useKeyPress(['k'], () => toggleStoreSwitcher(), document, { meta: true }); // CMD-k (mac) WINDOWS-k (windows)

  return (
    <>
      <DetailHeader>
        <Flex alignItems="center" gap={24}>
          {isLoading || !storeImagesPayload ? (
            <Skeleton direction="row" animate>
              <Skeleton.Bone
                height="96px"
                width="96px"
                borderRadius="sm"
                mr={24}
              />
              <Flex flexDirection="column">
                <Skeleton.Bone height="40px" width="275px" mb={8} />
                <Skeleton.Bone width="100px" />
              </Flex>
            </Skeleton>
          ) : (
            <>
              <PartnerLogo
                name={storeImagesPayload?.name || ''}
                variant="store"
                image={storeImagesPayload?.photo || null}
                size="sm"
              />
              <Flex flexDirection="column">
                <Link
                  variant="minimal"
                  onClick={() => toggleStoreSwitcher(true)}
                >
                  <Flex alignItems="center">
                    <Typography variant="header-bold" mb={8}>
                      {storeImagesPayload?.name}
                    </Typography>
                    <ChevronDownIcon mb={4} ml={12} />
                  </Flex>
                </Link>
                {/** Need a wrapper so the link doesn't fill up the whole Flex container */}
                {/* <div>
                  <Link onClick={() => alert('view live store')}>
                    View live store
                  </Link>
                </div> */}
              </Flex>
            </>
          )}
        </Flex>
      </DetailHeader>
      <DetailSubnav aria-label="Store details navigation">
        <Tabs value={currentTab} onChange={onTabClick}>
          <Tabs.Tab label={'Settings'} value={'settings'}></Tabs.Tab>
          {canEditMenu ? (
            <Tabs.Tab label={'Menu'} value={'menu'}></Tabs.Tab>
          ) : (
            <Flex ariaLabel="menu tab"></Flex>
          )}
          <Tabs.Tab label={'Products'} value={'products'}></Tabs.Tab>
          <Tabs.Tab label={'Specials'} value={'specials'}></Tabs.Tab>
          <Tabs.Tab label={'Staff'} value={'staff'}></Tabs.Tab>
        </Tabs>
      </DetailSubnav>
      {storeSwitcherOpen ? (
        <StoreSwitcherModal
          isOpen={storeSwitcherOpen}
          setOpen={setStoreSwitcherOpen}
        />
      ) : null}
    </>
  );
};

import * as t from 'io-ts';

import { tId } from './common';

export const tStrongholdIntegrationV2 = t.interface({
  id: tId,
  publishable_key: t.string,
  secret_key: t.string,
  enabled: t.union([t.boolean, t.undefined]),
});
export type StrongholdIntegrationV2 = t.TypeOf<typeof tStrongholdIntegrationV2>;

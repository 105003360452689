import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo, useState } from 'react';

import {
  useCreateStaffMember,
  useDeleteStaffMember,
  useReinviteStaffMember,
  useStores,
  useUpdateStaffMember,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useDebouncedTrack,
  useHasPermissions,
  useModalActionsWithTracking,
  useMutationStatusToasts,
  useResultsById,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type {
  AbbreviatedStoreV2,
  StaffMember,
} from '@jane/business-admin/types';
import {
  EventNames,
  MODAL_CARD_WIDTH,
  ModalNames,
  SearchSubjects,
  getRolesOptions,
  parseValidationErrors,
  shouldShowDelete,
  shouldShowPermissions,
  track,
} from '@jane/business-admin/util';
import {
  SUBMIT_BUTTON_VARIANTS,
  StoreSelectModal,
} from '@jane/shared-b2b/components';
import { Permission } from '@jane/shared/auth';
import { ConfirmChangeModal } from '@jane/shared/components';
import {
  Banner,
  Button,
  Card,
  EditIcon,
  ErrorIcon,
  Flex,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useForm,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../ConfirmWrapperWithTracking';
import { StaffMemberPermissions } from './StaffMemberPermissions';

interface StaffMemberModalProps {
  onClose: () => void;
  open: boolean;
  staffMember: StaffMember | null;
}

const FORM_ERROR_NAME = 'staff-members-error';

export const StaffMemberModal = ({
  open,
  onClose,
  staffMember,
}: StaffMemberModalProps) => {
  const { storeId } = useContext(StoreDetailsContext);
  const [confirmChangeModalOpen, setConfirmChangeModalOpen] = useState(false);
  // This is for the user operating the page and not each indiviual staff member in the list
  const userCanEditStaff = useHasPermissions([Permission.EditStaff]);
  const userCanEditClient = useHasPermissions([Permission.EditClientRole]);
  const isCreateMode = !staffMember;
  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error ${
      isCreateMode ? 'inviting' : 'updating'
    } staff member. Please try again.`
  );
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.StaffMemberStoreSelect
  );
  const defaultStoreIds = storeId ? [parseInt(storeId, 10)] : [];
  const defaultPermissions = Object.values(Permission);

  const formMethods = useForm({
    defaultValues: {
      ...(staffMember
        ? staffMember
        : {
            role: 'staff_member',
            store_ids: defaultStoreIds,
            permissions: defaultPermissions,
          }),
    },
  });
  const {
    formState: { isDirty, dirtyFields, errors },
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    getValues,
  } = formMethods;

  const {
    mutateAsync: createStaffMember,
    isLoading: isCreating,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
  } = useCreateStaffMember();
  const {
    mutateAsync: updateStaffMember,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateStaffMember(staffMember?.id as number);
  const {
    mutateAsync: reinviteStaffMember,
    isLoading: isReinvitingStaffMember,
    isSuccess: isReinviteSuccess,
    isError: isReinviteError,
  } = useReinviteStaffMember(staffMember?.id as number);
  const {
    mutateAsync: deleteStaffMember,
    isLoading: isDeleting,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
  } = useDeleteStaffMember(staffMember?.id as number);
  const { data: stores, isFetching: isFetchingStores } = useStores();
  const selectedRole = watch('role');
  const selectedStoreIds = watch('store_ids');
  const deliveryEnabled = watch('delivery');
  const pickupEnabled = watch('pickup');
  const storeCount = selectedStoreIds?.length ?? 0;
  const storesHeaderText = `Stores (${storeCount})`;

  const isEditable = useMemo(() => {
    if (!isCreateMode) {
      if (selectedRole !== 'client') return userCanEditStaff;

      return userCanEditClient;
    }

    return userCanEditClient || userCanEditStaff;
  }, [userCanEditClient, userCanEditStaff]);

  const { permissions: staffMemberPermissions } = getValues();

  const storesById = useResultsById(stores);
  const staffMemberStores = useMemo<AbbreviatedStoreV2[]>(
    () =>
      (selectedStoreIds ?? [])
        .map((id: number) => storesById[id])
        .filter(Boolean),
    [selectedStoreIds, storesById]
  );

  const onSelectStores = (storeIds: any) => {
    setValue('store_ids', storeIds);

    if (storeIds.length) {
      clearErrors();

      // The form thinks it's invalid unless we specifically trigger re-validation of the
      // email field after calling clearErrors(), even when the field contains a valid
      // email address.
      trigger('email');
    } else {
      setError('store_ids', { message: 'Please select at least 1 store.' });
    }

    closeModal();
  };

  const onSubmit = (formData: StaffMember) => {
    // Manually ensure that at least 1 store is selected before allowing submission
    if (!formData?.store_ids?.length) {
      setError('store_ids', { message: 'Please select at least 1 store.' });
      return;
    }

    const permissions = Object.entries(formData)
      .filter(([key, val]) => /^(edit|view)_/.test(key) && val)
      .map(([key]) => key);

    const filteredFormData = Object.entries(formData)
      .filter(([key]) => !key.includes('edit_'))
      .reduce((acc, [key, val]) => {
        acc[key] = val;
        return acc;
      }, {} as any);

    const requestData = {
      ...filteredFormData,
      permissions,
    };

    const submitMethod = () => {
      track({
        action: isCreateMode ? 'create' : 'update',
        event: EventNames.EditedStaffMember,
        changed_attributes: Object.keys(dirtyFields),
      });

      return isCreateMode
        ? createStaffMember(requestData)
        : updateStaffMember(requestData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  useMutationStatusToasts({
    isMutating: isCreating,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
    successMessage: 'Staff member successfully invited',
    errorMessage: 'Error inviting staff member. Please try again.',
  });

  useMutationStatusToasts({
    isMutating: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    successMessage: 'Staff member successfully updated',
    errorMessage: 'Error updating staff member. Please try again.',
  });

  useMutationStatusToasts({
    isMutating: isReinvitingStaffMember,
    isSuccess: isReinviteSuccess,
    isError: isReinviteError,
    successMessage: 'Invitation successfully re-sent.',
    errorMessage: 'Error re-sending invitation. Please try again.',
  });

  useMutationStatusToasts({
    isMutating: isDeleting,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    successMessage: 'Staff member successfully removed.',
    errorMessage: 'Error removing staff member. Please try again.',
  });

  useEffect(() => {
    if (deliveryEnabled || pickupEnabled) {
      clearErrors(['pickup', 'delivery']);
    }
  }, [deliveryEnabled, pickupEnabled]);

  const isSuccess = useMemo(
    () =>
      (isCreateMode && isCreateSuccess) ||
      (!isCreateMode && isUpdateSuccess) ||
      (!isCreateMode && isReinviteSuccess) ||
      (!isCreateMode && isDeleteSuccess),
    [
      isCreateMode,
      isCreateSuccess,
      isUpdateSuccess,
      isReinviteSuccess,
      isDeleteSuccess,
    ]
  );

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onDeleteStaffMember = () => {
    setConfirmChangeModalOpen(true);
  };
  const onConfirmDelete = () => {
    track({
      action: 'delete',
      event: EventNames.EditedStaffMember,
      changed_attributes: [],
    });
    setConfirmChangeModalOpen(false);
    return catchSubmitErrors({
      submitMethod: () => deleteStaffMember(),
      requestData: { id: staffMember?.id },
      onValidationError: () => null,
    });
  };
  const onCancelToggle = () => {
    setConfirmChangeModalOpen(false);
  };
  const trackSearch = useDebouncedTrack(1000);

  return (
    <>
      <ConfirmWrapperWithTracking
        open={open}
        setOpen={onClose}
        hasChanges={isDirty}
        variant="full-screen"
        background="grays-ultralight"
        modalName={
          ModalNames[isCreateMode ? 'InviteStaffMember' : 'UpdateStaffMember']
        }
      >
        <Form.BaseForm
          name="staff member settings"
          formMethods={formMethods}
          onSubmit={onSubmit}
          formErrorName={FORM_ERROR_NAME}
        >
          <Modal.Header
            title={`${isCreateMode ? 'Invite' : 'Edit'} staff`}
            actions={
              <>
                {!isCreateMode &&
                  isEditable &&
                  shouldShowDelete(
                    isCreateMode,
                    staffMember,
                    selectedRole,
                    userCanEditClient,
                    userCanEditStaff
                  ) && (
                    <Button
                      variant="destructive"
                      label="Delete"
                      data-testid="staff-member-delete"
                      onClick={() => {
                        onDeleteStaffMember();
                      }}
                      mr={8}
                      loading={isDeleteSuccess}
                    />
                  )}
                {isEditable && (
                  <Form.SubmitButton
                    label="Save"
                    variant="primary"
                    data-testid="staff-member-save"
                  />
                )}
              </>
            }
          />
          <Modal.Content>
            <Form.ErrorBanner name={FORM_ERROR_NAME} />
            <Flex alignItems="center" flexDirection="column">
              <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
                <Card.Content>
                  <Flex p={24} flexDirection="column">
                    <Typography variant="header-bold" mb={40}>
                      Details
                    </Typography>
                    {isCreateMode ? (
                      <Form.TextField
                        required
                        type="email"
                        label="Email"
                        name="email"
                        width="100%"
                        mb={40}
                        disabled={!isEditable}
                      />
                    ) : (
                      <Flex mb={40} gap={24}>
                        <Flex flexDirection="column" width="50%">
                          <Typography variant="caps" color="grays-mid" mb={2}>
                            Email
                          </Typography>
                          <Flex>{staffMember?.email}</Flex>
                        </Flex>
                        <Flex width="50%">
                          <Flex flexDirection="column" width="50%">
                            <Typography variant="caps" color="grays-mid" mb={2}>
                              Status
                            </Typography>
                            <Flex>
                              {staffMember?.registered
                                ? 'Registered'
                                : 'Not registered'}
                            </Flex>
                          </Flex>
                          {!staffMember?.registered && (
                            <Flex width="50%" justifyContent="flex-end">
                              <Button
                                variant="secondary"
                                label="Resend Invitation"
                                onClick={() => {
                                  reinviteStaffMember();
                                }}
                                loading={isReinvitingStaffMember}
                              />
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    )}

                    {!isCreateMode && (
                      <Flex mb={40} gap={24}>
                        <Form.TextField
                          required
                          defaultValue={staffMember?.first_name || ''}
                          label="First name"
                          name="first_name"
                          disabled={!isEditable}
                        />
                        <Form.TextField
                          required
                          defaultValue={staffMember?.last_name || ''}
                          label="Last name"
                          name="last_name"
                          disabled={!isEditable}
                        />
                      </Flex>
                    )}
                    <Flex mb={40} gap={24}>
                      <Form.SelectField
                        required
                        label="Role"
                        name="role"
                        defaultValue={capitalize(staffMember?.role)}
                        options={getRolesOptions(
                          userCanEditClient,
                          userCanEditStaff,
                          isCreateMode,
                          staffMember
                        )}
                        width="100%"
                        disabled={!isEditable}
                      />

                      {!isCreateMode && (
                        <Form.TextField
                          required
                          type="tel"
                          label="Phone number"
                          name="phone"
                          defaultValue={staffMember?.phone || ''}
                          width="100%"
                          disabled={!isEditable}
                        />
                      )}
                    </Flex>
                    {selectedRole === 'staff_member' && (
                      <Flex flexDirection="column" mb={40} width="50%">
                        <Typography variant="body-bold" mb={16}>
                          Reservation modes
                        </Typography>
                        <Flex gap={24}>
                          <Form.SwitchField
                            width="50%"
                            label="Pickup"
                            name="pickup"
                            defaultChecked={
                              staffMember ? staffMember?.pickup : true
                            }
                          />
                          <Form.SwitchField
                            width="50%"
                            label="Delivery"
                            name="delivery"
                            defaultChecked={
                              staffMember ? staffMember?.delivery : true
                            }
                          />
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                </Card.Content>
              </Card>
              {shouldShowPermissions(
                isCreateMode,
                staffMember,
                selectedRole,
                userCanEditClient
              ) ? (
                <StaffMemberPermissions
                  isCreateMode={isCreateMode}
                  staffMemberPermissions={staffMemberPermissions}
                />
              ) : null}
              <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
                <Card.Content>
                  <Flex p={24} flexDirection="column">
                    {!!errors['store_ids']?.message && (
                      <Banner
                        full
                        variant="error"
                        icon={<ErrorIcon />}
                        label={errors['store_ids']?.message
                          ?.toString()
                          ?.replace(/store_ids/, '')}
                        mt={16}
                        mb={16}
                      />
                    )}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      mb={40}
                    >
                      <Typography variant="header-bold">
                        {storesHeaderText}
                      </Typography>
                      <Button.Icon
                        disabled={!isEditable}
                        icon={<EditIcon />}
                        onClick={() => openModal()}
                        label="Edit stores"
                        variant="secondary"
                        data-testid="stores-edit"
                      />
                    </Flex>
                    <Flex flexWrap="wrap">
                      {staffMemberStores.map((store) => (
                        <Flex
                          key={store.id}
                          alignItems="center"
                          width="50%"
                          mb={16}
                        >
                          <Typography>{store.name}</Typography>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </Card.Content>
              </Card>
            </Flex>
          </Modal.Content>
        </Form.BaseForm>
      </ConfirmWrapperWithTracking>
      {modalOpen && (
        <StoreSelectModal
          onSearchCallback={(query: string, successful?: boolean) =>
            trackSearch({
              event: EventNames.Search,
              arguments: query,
              subject: SearchSubjects.Stores,
              successful,
            })
          }
          storesData={stores || []}
          isFetchingStores={isFetchingStores}
          onSubmit={onSelectStores}
          selectedStoreIds={selectedStoreIds.map((id) => id.toString()) ?? []}
          submitButtonType={SUBMIT_BUTTON_VARIANTS.select}
          disableSubmit={!isEditable}
          closeModal={() => closeModal()}
        />
      )}
      <ConfirmChangeModal
        onConfirm={onConfirmDelete}
        onCancel={onCancelToggle}
        open={confirmChangeModalOpen}
      />
    </>
  );
};

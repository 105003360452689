import styled from '@emotion/styled';

import { Button } from '@jane/shared/reefer';

export const ThemedButton = styled(Button)<{ janePay: boolean }>(
  ({ janePay, theme }) => ({
    ...(janePay && {
      backgroundColor: theme.colors.brand.purple.main,
      borderColor: theme.colors.brand.purple.main,
    }),
  })
);

import { useEffect } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';

import { useCheckoutAccordion } from '../../CheckoutAccordion/CheckoutAccordionProvider';
import { useFulfillmentSchedule } from './useFulfillmentSchedule';

const SLOT_ERRORS = ['time is no longer available', 'time is required'];

const OPTIONS_ERRORS = [...SLOT_ERRORS, 'store is no longer accepting'];

const CART_ERRORS = [
  'products in your cart are no longer available',
  'exceeded the delivery reservation limit',
  'on your cart have changed',
  'total has been updated',
];

const PAYMENT_ERRORS = [
  'authorization failed',
  'checkout completion failed',
  'payment options',
  'payment method',
  'Stronghold checkout completion failed',
  'Payment failed',
];

const AUTO_DISMISS_ERRORS = [...SLOT_ERRORS];
const AUTO_DISMISS_DELAY = 5 * 1000;

export const useHandleCheckoutErrors = () => {
  const { setExpanded } = useCheckoutAccordion();
  const {
    appInfo: { checkoutError },
    dispatches: { dismissCheckoutError, openCart },
  } = useCheckout();
  const { refetchSlots } = useFulfillmentSchedule();

  const doesContainError = (errors: string[]) => {
    return errors.some((partialString) =>
      checkoutError?.includes(partialString)
    );
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (checkoutError) {
      if (doesContainError(SLOT_ERRORS)) refetchSlots();

      if (doesContainError(OPTIONS_ERRORS)) setExpanded('options');

      if (doesContainError(PAYMENT_ERRORS)) setExpanded('payments');

      if (doesContainError(CART_ERRORS)) openCart();

      if (doesContainError(AUTO_DISMISS_ERRORS)) {
        timeout = setTimeout(() => dismissCheckoutError(), AUTO_DISMISS_DELAY);
      }
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [checkoutError]);
};

import type {
  ComplianceLanguageSettings,
  MenuRow,
} from '@jane/business-admin/types';
import { CardCarousel, StoreSpecialCard } from '@jane/shared/components';
import type { StoreSpecial } from '@jane/shared/models';

import { ListContainer } from './ListContainer';
import { HeaderEditButton, HeaderToggle } from './ListItemHeaders';

interface Props {
  complianceLanguageSettings?: ComplianceLanguageSettings;
  currentStoreSpecials: StoreSpecial[];
  openMenuRowModal: (row: MenuRow) => void;
  row: MenuRow;
}
export const SpecialsListItem = ({
  complianceLanguageSettings,
  currentStoreSpecials,
  openMenuRowModal,
  row,
}: Props) => {
  return (
    <ListContainer rowEnabled={!!row.enabled}>
      <CardCarousel
        afterHeader={
          <HeaderEditButton
            onOpenMenuRow={() => openMenuRowModal(row)}
            showCTAButton={false}
          />
        }
        beforeHeader={
          <HeaderToggle
            row={row}
            rowLabel={row.custom_display_name || row.row_type}
            itemsLength={0}
            showProductsCTA={false}
          />
        }
        name={row.custom_display_name || row.row_type}
        mode="fixed"
        cardWidth={315}
        cardHeight={240}
        cardWidthMobile={212}
        cardHeightMobile={328}
        mb={row.enabled ? 40 : 0}
      >
        {row.enabled &&
          currentStoreSpecials.map((special) => (
            <CardCarousel.Card key={`${special.id}`}>
              <StoreSpecialCard
                special={special}
                store={{
                  store_compliance_language_settings:
                    complianceLanguageSettings,
                }}
              />
            </CardCarousel.Card>
          ))}
      </CardCarousel>
    </ListContainer>
  );
};

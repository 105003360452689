import { config } from '@jane/shared/config';

import { api } from './api';

const buildUrl = (url: string) => `${config.apiPathV2}${url}`;

export const janeApiV2 = {
  delete: <T>(url: string, params?: object): Promise<T | null> =>
    api.delete(`${buildUrl(url)}`, params),
  get: <T>(url: string, params?: object): Promise<T> =>
    api.get(`${buildUrl(url)}`, params),
  patch: <T>(url: string, data: unknown, params?: object): Promise<T> =>
    api.patch(`${buildUrl(url)}`, data, params),
  post: <T>(url: string, data: unknown, params?: object): Promise<T> =>
    api.post(`${buildUrl(url)}`, data, params),
  put: <T>(url: string, data: unknown, params?: object): Promise<T> =>
    api.put(`${buildUrl(url)}`, data, params),
};

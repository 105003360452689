import { getModalCategoriesToShow } from './rules';

export type Counts = {
  brandSubCategory: number;
  category: number;
  subCategory: number;
};

export const countKeyTypes = (
  selectionMap: any,
  indeterminates: any
): Counts => {
  const counts: Counts = {
    category: 0,
    subCategory: 0,
    brandSubCategory: 0,
  };

  const modalCategoriesToShow = getModalCategoriesToShow(
    selectionMap,
    indeterminates
  );

  modalCategoriesToShow.forEach((key: string) => {
    const delimiterCount = (key.match(/:/g) || []).length;

    switch (delimiterCount) {
      case 0:
        counts.category += 1;
        break;
      case 1:
        counts.subCategory += 1;
        break;
      case 2:
        counts.brandSubCategory += 1;
        break;
    }
  });

  return counts;
};

export const pluralizeCounts = (counts: Counts): string => {
  if (
    counts.category === 0 &&
    counts.subCategory === 0 &&
    counts.brandSubCategory === 0
  ) {
    return '0 categories selected';
  }

  const parts: string[] = [];

  if (counts.category > 0) {
    const categoryWord = counts.category === 1 ? 'category' : 'categories';
    parts.push(`${counts.category} ${categoryWord}`);
  }

  if (counts.subCategory > 0) {
    const subCategoryWord =
      counts.subCategory === 1 ? 'subcategory' : 'subcategories';
    parts.push(`${counts.subCategory} ${subCategoryWord}`);
  }

  if (counts.brandSubCategory > 0) {
    const brandSubCategoryWord =
      counts.brandSubCategory === 1 ? 'brand subtype' : 'brand subtypes';
    parts.push(`${counts.brandSubCategory} ${brandSubCategoryWord}`);
  }

  return parts.join(', ') + ' selected.';
};

import range from 'lodash/range';

import type { StoreDismissalMessage } from '@jane/business-admin/types';
import { Skeleton, Typography } from '@jane/shared/reefer';

import { TableCell } from '../../../../TableCell';
import {
  CollapsedBorderTable,
  TableRowWithBorder,
} from '../../../../TableWithBorderSeparator';
import { StatusMessagePreview } from './StatusMessagePreview';

const SkeletonCell = () => (
  <TableCell>
    <Skeleton animate height="fit-content" width="100%">
      <Skeleton.Bone width="100%" height="60px" mr={16} />
    </Skeleton>
  </TableCell>
);

const SkeletonRows = ({ rows }: { rows: number }) => (
  <>
    {range(1, rows).map((row) => (
      <TableRowWithBorder key={`skeleton-row-${row}`}>
        <SkeletonCell />
        <SkeletonCell />
      </TableRowWithBorder>
    ))}
  </>
);

const MessageRow = ({ message }: { message: string }) => (
  <TableRowWithBorder data-testid="dismissal-message-row">
    <TableCell pl={0}>
      <Typography>Additional dismissal</Typography>
    </TableCell>
    <TableCell>
      <StatusMessagePreview status="dismissed" message={message} />
    </TableCell>
  </TableRowWithBorder>
);

type DismissalMessageRow = Pick<StoreDismissalMessage, 'id' | 'message'>;

interface Props {
  dismissalMessages?: DismissalMessageRow[] | null;
  emptyText?: string;
  isFetched?: boolean;
  isLoading?: boolean;
}

export const DismissalMessageTable = ({
  emptyText = 'Additonal dismissal messages have not been set up for this store.',
  dismissalMessages,
  isFetched,
  isLoading,
}: Props) => {
  if (isFetched && (!dismissalMessages || !dismissalMessages.length)) {
    return <Typography variant="body">{emptyText}</Typography>;
  }

  return (
    <CollapsedBorderTable>
      <thead>
        <TableRowWithBorder>
          <TableCell pl={0} py={4} width="40%">
            <Typography variant="caps">Status</Typography>
          </TableCell>
          <TableCell py={4}>
            <Typography variant="caps">Preview</Typography>
          </TableCell>
        </TableRowWithBorder>
      </thead>

      <tbody>
        {isLoading ? (
          <SkeletonRows rows={3} />
        ) : (
          dismissalMessages?.map(({ id, message }) => (
            <MessageRow key={id} message={message} />
          ))
        )}
      </tbody>
    </CollapsedBorderTable>
  );
};

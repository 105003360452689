import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useUpdateSquareAuthorization } from '@jane/business-admin/data-access';
import { businessPaths } from '@jane/business-admin/util';
import { Banner, Flex, Loading } from '@jane/shared/reefer';
import { trackError } from '@jane/shared/util';

export const SquareOAuth = () => {
  const [searchParams] = useSearchParams();
  const authenticityToken = searchParams.get('state') || '';
  const code = searchParams.get('code') || '';

  const {
    mutateAsync: updateSquareAuthorization,
    isLoading,
    isError,
  } = useUpdateSquareAuthorization(authenticityToken, code);

  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);

  useEffect(() => {
    const authorizeSquare = async () => {
      try {
        const squareAuthorizationResult = await updateSquareAuthorization();

        if (squareAuthorizationResult && squareAuthorizationResult.store_id) {
          setIsAuthorized(true);
          const storeId = squareAuthorizationResult.store_id.toString();
          const businessStoreCheckoutSettingsPath =
            businessPaths.storeSettingsCheckout(storeId);

          window.location.replace(
            `${businessStoreCheckoutSettingsPath}?squareOAuthRedirect=1`
          );
        } else {
          setIsApiError(true);
        }
      } catch (err: any) {
        trackError('Error authorizing Square');
      }
    };

    if (!isLoading) {
      authorizeSquare();
    }
  }, []);

  if (!isLoading && !isAuthorized && (isError || isApiError))
    return (
      <Flex m={96}>
        <Banner
          full
          label="There was an error authorizing Square. Please try again."
          typography="header"
          variant="error"
        />
      </Flex>
    );

  return <Loading color="purple" />;
};

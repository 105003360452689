import * as t from 'io-ts';

export const tJanePayIntegration = t.interface({
  autocapture_on_checkout: t.union([t.boolean, t.undefined]),
  autocapture_on_completion: t.union([t.boolean, t.undefined]),
  enabled: t.union([t.boolean, t.undefined]),
  id: t.union([t.number, t.undefined]),
  location_id: t.union([t.string, t.null]),
  location_uuid: t.union([t.string, t.null, t.undefined]),
  merchant_id: t.string,
  reservation_modes: t.union([
    t.array(t.string),
    t.type({ delivery: t.boolean, pickup: t.boolean, curbside: t.boolean }),
  ]),
});
export type JanePayIntegration = t.TypeOf<typeof tJanePayIntegration>;

import {
  StoreDetailsCard,
  StoreHoursCard,
  StoreImageCard,
} from '@jane/business-admin/components';
import { Card, Flex } from '@jane/shared/reefer';

export const StoreSettingsDetails = () => {
  return (
    <Flex flexDirection="column">
      <StoreImageCard />
      <Card width="100%" flat border="grays-light" mb={24}>
        <StoreDetailsCard />
      </Card>
      <Card width="100%" flat border="grays-light">
        <StoreHoursCard />
      </Card>
    </Flex>
  );
};

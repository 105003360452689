import { useEffect, useMemo } from 'react';
import type { ReactNode } from 'react';

import { useManager } from '@jane/business-admin/data-access';
import { useNavigateAndTrack } from '@jane/business-admin/hooks';
import {
  EventNames,
  NavigationSourceIds,
  businessPaths,
  track,
} from '@jane/business-admin/util';

export const RequireLoggedInUser = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigateAndTrack();
  const { data: currentManager, isFetched } = useManager();

  const isLoggedIn = useMemo(() => {
    if (isFetched && currentManager?.manager?.id) return true;
    return false;
  }, [isFetched, currentManager]);

  useEffect(() => {
    if (!isFetched) return;

    // redirect if user does not have currentManager data
    if (!isLoggedIn) {
      track({
        event: EventNames.AccessDenied,
        reason: 'Not logged in',
      });

      navigate(businessPaths.root(), NavigationSourceIds.Automatic);
      return;
    }
  }, [currentManager?.manager?.id, isFetched, isLoggedIn]);

  return !isLoggedIn ? null : <>{children}</>;
};

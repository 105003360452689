import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';

import { spacing } from '@jane/shared/reefer-emotion';

const Wrapper = styled.div({
  display: 'flex',
  ...spacing({ px: 64, py: 40 }),
});

export const Specials = () => (
  <Wrapper>
    <h1>Specials</h1>
    <Outlet />
  </Wrapper>
);

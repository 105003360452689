import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useSendFeedback } from '@jane/business-admin/data-access';
import type { FeedbackFormData } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import {
  EventNames,
  businessPaths,
  normalizePath,
} from '@jane/business-admin/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import {
  Button,
  Flex,
  Form,
  Modal,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import { trackWithCallbackAsync } from '@jane/shared/util';

const FORM_ERROR_NAME = 'feedback-error';

const moreFeedbackOptions = [
  {
    id: 'yes-to-more-feedback',
    label: 'Yes',
    value: true,
    wrapper: (children: React.ReactNode) => (
      <Flex mb={24}>
        {children}
        <Typography color="grays-mid" variant="mini" ml={24}>
          If yes, a member of the Jane team will reach out to you.
        </Typography>
      </Flex>
    ),
  },
  {
    id: 'no-to-more-feedback',
    label: 'No',
    value: false,
  },
];

interface FeedbackModalProps {
  feedbackModalOpen: boolean;
  setFeedbackModalOpen: (feedbackModalOpen: boolean) => void;
  shouldRedirect: boolean;
}

export const FeedbackModal = ({
  feedbackModalOpen,
  setFeedbackModalOpen,
  shouldRedirect,
}: FeedbackModalProps) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error submitting feedback. Please try again.'
  );

  const { pathname } = useLocation();
  const toast = useToast();
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);

  const redirectUrl = businessPaths.legacyRoot();

  const { mutateAsync: sendFeedback, isSuccess: feedbackSuccess } =
    useSendFeedback();

  useEffect(() => {
    if (feedbackSuccess && !shouldRedirect) {
      toast.add({
        label: 'Feedback submitted!',
        variant: 'success',
      });
      setFeedbackModalOpen(false);
    }
  }, [feedbackSuccess, shouldRedirect]);

  const { id: storeId = '' } = useParams<'id'>();
  const onSubmit = async (data: FeedbackFormData) => {
    await catchSubmitErrors({
      submitMethod: () => sendFeedback(data),
      requestData: data,
      onValidationError: () => null,
    });
    await trackWithCallbackAsync(
      EventNames.SubmitFeedbackForm,
      {
        source: normalizePath(pathname, storeId),
      },
      {
        send_immediately: true,
      }
    );

    if (shouldRedirect) {
      await trackWithCallbackAsync(
        EventNames.NavigatedToLegacy,
        {
          from_url: normalizePath(pathname, storeId),
          to_url: normalizePath(redirectUrl, storeId),
        },
        {
          send_immediately: true,
        }
      );
      window.location.replace(redirectUrl);
    }
  };

  const conditionalButtonProps = shouldRedirect
    ? { target: undefined, href: redirectUrl }
    : { onClick: () => setFeedbackModalOpen(false) };

  return (
    <ConfirmDiscardWrapper
      setOpen={setFeedbackModalOpen}
      open={feedbackModalOpen}
      hasChanges={formIsDirty}
    >
      <Form
        name="Feedback Form"
        onDirty={setFormIsDirty}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="We'd love your feedback!"
          actions={
            <>
              <Button
                mr={16}
                variant="secondary"
                label="Skip"
                {...conditionalButtonProps}
              />
              <Form.SubmitButton variant="primary" label="Submit" />
            </>
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Typography variant="header">Your details</Typography>
          <Flex gap={24} py={24}>
            <Form.TextField label="Full Name" name="name" required />
            <Form.TextField label="Email" name="email" required />
          </Flex>
          <Form.TextField label="Role/Title" name="role" required />
          <Form.TextAreaField
            required
            label={
              <Typography variant="header" mt={24} mb={8}>
                Please describe your experience using the new Business Dashboard
                beta.
              </Typography>
            }
            name="description"
          />
          <Typography variant="header" my={24}>
            Are you interested in providing more feedback to the Jane team?
          </Typography>
          <Form.RadioFieldGroup
            name="willing_to_provide_feedback"
            options={moreFeedbackOptions}
            required
          />
        </Modal.Content>
      </Form>
    </ConfirmDiscardWrapper>
  );
};

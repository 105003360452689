import { useQuery } from '@tanstack/react-query';
import * as t from 'io-ts';

import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';

const tPayload = t.type({ any_active_jane_pay_integrations: t.boolean });
type payload = t.TypeOf<typeof tPayload>;

const fetchAnyActiveJanePayIntegrations = async (
  storeId?: string
): Promise<any> => {
  const urlParams = new URLSearchParams();
  storeId && urlParams.append('store_id', storeId);

  const url = `/business/jane_pay_integration_status?${urlParams.toString()}`;
  const data = await fetchWithDecode(
    janeApiV2.get<payload>(url),
    tPayload,
    url
  );

  return data;
};

export const useFetchAnyActiveJanePayIntegrations = (storeId?: string) => {
  return useQuery<payload>({
    queryFn: () => fetchAnyActiveJanePayIntegrations(storeId),
    queryKey: ['fetch_jane_pay', storeId],
    useErrorBoundary: true,
  });
};

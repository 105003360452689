import {
  AllProductsIcon,
  ExtractIcon,
  FlowerIcon,
  GearIcon,
  GrowIcon,
  GummyIcon,
  MerchIcon,
  PrerollIcon,
  StarFilledIcon,
  TagFilledIcon,
  TinctureIcon,
  TopicalIcon,
  VapeIcon,
} from '@jane/shared/reefer';
import type { ColorKey, IconComponentProps } from '@jane/shared/reefer';
import type { MenuCategoryPath } from '@jane/shared/types';

export interface MenuCategory {
  Icon: IconComponentProps;
  color: ColorKey;
  iconColor?: ColorKey;
}

interface MenuCategoryTabs {
  [category: string | MenuCategoryPath]: MenuCategory;
}

export const MENU_CATEGORY_TABS: MenuCategoryTabs = {
  featured: {
    Icon: StarFilledIcon,
    color: 'gold-light',
  },
  specials: {
    Icon: TagFilledIcon,
    color: 'sunset-light',
    iconColor: 'sunset',
  },
  flower: {
    Icon: FlowerIcon,
    color: 'purple-light',
  },
  edible: {
    Icon: GummyIcon,
    color: 'tangerine-light',
  },
  'pre-roll': {
    Icon: PrerollIcon,
    color: 'ember-light',
  },
  extract: {
    Icon: ExtractIcon,
    color: 'gold-light',
  },
  tincture: {
    Icon: TinctureIcon,
    color: 'grape-light',
  },
  topical: {
    Icon: TopicalIcon,
    color: 'sunset-light',
  },
  vape: {
    Icon: VapeIcon,
    color: 'sunset-light',
  },
  merch: {
    Icon: MerchIcon,
    color: 'palm-light',
  },
  gear: {
    Icon: GearIcon,
    color: 'seafoam-light',
    iconColor: 'seafoam',
  },
  grow: {
    Icon: GrowIcon,
    color: 'palm-light',
  },
  all: {
    Icon: AllProductsIcon,
    color: 'purple-light',
  },
};

import { z } from 'zod';

import {
  zAbbreviatedMenuProduct,
  zMenuProduct,
  zPhoto,
} from '@jane/shared/types';

import { zFlavorsAndEffectsKind } from './menuProductForProductTable';

// Copied from: https://github.com/janetechinc/iheartjane/blob/master/client/libs/monolith/shared/src/lib/business/components/storeEdit/products/types/product.ts#L21
const zGlobalSpecialProduct = z.object({
  id: z.number(),
  brand: z.string().nullable(),
  brand_subtype: z.string().nullable(),
  category: zMenuProduct.shape.category.or(z.literal('')),
  description: z.string().nullable(),
  effects: z.array(zFlavorsAndEffectsKind),
  flavors: z.array(zFlavorsAndEffectsKind),
  full_name: z.string(),
  image_urls: z.array(z.string()),
  kind: zAbbreviatedMenuProduct.shape.kind,
  name: z.string(),
  photos: z.array(zPhoto),
  product_id: z.number().optional(),
  root_subtype: z.string().nullable(),
  store_id: z.number().optional(),
  type: z.string().nullable(),
});
export type GlobalSpecialProduct = z.infer<typeof zGlobalSpecialProduct>;

import { useMutation } from '@tanstack/react-query';

import { janeApiV2 } from '@jane/shared/data-access';

export type ContactUsFormData = {
  description: string;
  reason: 'account' | 'reservation' | 'other';
};

const emailContactUs = (
  data: ContactUsFormData
): Promise<ContactUsFormData> => {
  return janeApiV2.post<ContactUsFormData>(`/business/contact_us`, {
    contact_us: data,
  });
};

export const useEmailContactUs = () => {
  return useMutation({
    mutationFn: (data: ContactUsFormData) => {
      return emailContactUs(data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      console.log('sent');
    },
  });
};

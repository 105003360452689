import { config } from '@jane/shared/config';
import { trackError } from '@jane/shared/util';

const loadSquareCard = async (locationId: string) => {
  const typedWindow = window as any;

  if (!typedWindow.Square) {
    trackError(new Error('Square Web Payments SDK failed to load properly'));
  }

  const payments = typedWindow.Square.payments(
    config.squareApplicationId,
    locationId
  );

  const card = await payments.card();
  return card;
};

export { loadSquareCard };

import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { EventNames, track } from '@jane/business-admin/util';
import { useNavigateBack } from '@jane/shared/hooks';
import { Button, ErrorIcon, Flex, Typography } from '@jane/shared/reefer';

const TitleText = styled(Typography)({
  fontSize: '32px',
});

export const UnauthorizedAccess = ({
  resourceName,
  variant = 'edit',
}: {
  resourceName: string;
  variant?: 'edit' | 'view';
}) => {
  const { pathname } = useLocation();
  const { id = '' } = useParams<'id'>();
  const goBack = useNavigateBack();

  const trackAndRedirect = useCallback(() => {
    track({
      event: EventNames.AccessDenied,
      reason: 'Missing permission',
    });

    goBack();
  }, [pathname, id]);

  return (
    <Flex width="432px" mx="auto" justifyContent="center">
      <Flex
        my={128}
        mx={40}
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        width="432px"
      >
        <ErrorIcon size="xxl" mb={24} />
        <Flex flexDirection="column" width="432px">
          <TitleText mb={8} variant="title-bold" textAlign="center">
            Unauthorized Access
          </TitleText>
          <Typography
            variant="body"
            mb={24}
            color="grays-dark"
            textAlign="center"
          >
            You do not have permission to {variant} {resourceName}. Contact your
            account administrator for access.
          </Typography>
          <Button
            full
            variant="primary"
            onClick={trackAndRedirect}
            label="Go back"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

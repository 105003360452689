import { useMemo } from 'react';

interface QueryDataRecord {
  id: string | number;
}

export const useResultsById = <T extends QueryDataRecord>(
  queryData?: T[]
): Record<string, T> =>
  useMemo(
    () =>
      (queryData ?? []).reduce<Record<string, T>>((result, record) => {
        result[record.id.toString()] = record;
        return result;
      }, {}),
    [queryData]
  );

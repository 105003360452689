import styled from '@emotion/styled';
import pluralise from 'pluralise';
import { useMemo } from 'react';

import type { JanePrinterStore } from '@jane/business-admin/types';
import {
  Box,
  Button,
  Card,
  EditIcon,
  Flex,
  Typography,
} from '@jane/shared/reefer';

import { StoreDetails } from './StoreDetails';

const Separator = styled(Box)({ flex: '1 1 auto' });

export const StoreSection = ({
  openSaveToModal,
  stores,
}: {
  openSaveToModal: () => void;
  stores?: JanePrinterStore[];
}) => {
  const storeCountLabel = useMemo(() => stores?.length || 0, [stores]);

  return (
    <Card border="grays-light" width={944}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <Flex justifyContent="space-between" alignItems="center" mb={24}>
            <Typography variant="header-bold">Stores</Typography>
            {storeCountLabel > 0 ? (
              <Button.Icon
                icon={<EditIcon />}
                variant="secondary"
                label="Edit"
                onClick={openSaveToModal}
                data-testid="printer-stores-add"
              />
            ) : (
              <Button
                variant="secondary"
                label={'Select stores'}
                onClick={openSaveToModal}
                data-testid="printer-stores-edit"
              />
            )}
          </Flex>

          {storeCountLabel > 0 ? (
            <Typography mb={24}>
              Available at {storeCountLabel}{' '}
              {pluralise(storeCountLabel, 'store')}
            </Typography>
          ) : (
            <Typography mb={24}>No stores selected</Typography>
          )}

          <Flex flexDirection="column">
            {stores?.map((store) => (
              <div key={store.id}>
                <StoreDetails store={store} my={8} width="100%" />
                <Separator height={1} background="grays-light" />
              </div>
            ))}
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
};

import {
  AppSwitcherHeader,
  BusinessHeader,
} from '@jane/business-admin/components';

export const Shell = ({ children }: { children: React.ReactChild }) => (
  <div>
    <AppSwitcherHeader />
    <BusinessHeader />
    <main>{children}</main>
  </div>
);

import { useEffect, useMemo, useState } from 'react';

import { useDuplicatesValidation } from '@jane/business-admin/hooks';
import type { StoreSettingsPayload } from '@jane/business-admin/types';
import { EventNames, ModalNames, track } from '@jane/business-admin/util';
import {
  Button,
  Flex,
  Form,
  TrashIcon,
  Typography,
  useFieldArray,
  useFormContext,
} from '@jane/shared/reefer';

import type { PaymentFormData, PaymentOption } from './PaymentModal';

const Option = ({
  duplicateIndexes,
  index,
  modeOptions,
  option,
  providerValueMemoized,
  removeUsedOption,
}: {
  duplicateIndexes: Set<number>;
  index: number;
  modeOptions: {
    label: string;
    value: string;
  }[];
  option: PaymentOption;
  providerValueMemoized: PaymentOption[];
  removeUsedOption: (index: number) => void;
}) => {
  const { watch } = useFormContext();
  const [formError, setFormError] = useState<string | undefined>();

  useEffect(() => {
    if (duplicateIndexes.has(index)) {
      const resMode = watch(`payment_options.${index}.reservation_mode`);
      setFormError(
        `This payment option for ${resMode} is already in use, try again.`
      );
    } else {
      setFormError(undefined);
    }
  }, [duplicateIndexes]);

  return (
    <Flex alignItems="flex-start" mb={48} key={option.id}>
      <Flex height={48} alignItems="center">
        <Form.SwitchField
          label="Payment option enabled"
          labelHidden
          name={`payment_options.${index}.enabled`}
          mr={24}
        />
      </Flex>
      <Flex flexDirection="column" width="100%">
        <Flex width="100%" justifyContent="space-between" gap={16}>
          <Form.TextField
            label="Payment option"
            labelHidden
            name={`payment_options.${index}.payment_option`}
            placeholder="Payment option"
            disabled={!providerValueMemoized[index]?.enabled}
            required={providerValueMemoized[index]?.enabled}
          />

          <Form.SelectField
            width="100%"
            label="Reservation mode"
            labelHidden
            name={`payment_options.${index}.reservation_mode`}
            options={modeOptions}
            disabled={!providerValueMemoized[index]?.enabled}
            required={providerValueMemoized[index]?.enabled}
          />
        </Flex>
        {formError && (
          <Typography color="error" mt={16}>
            {formError}
          </Typography>
        )}
      </Flex>

      <Button.Icon
        icon={<TrashIcon />}
        label="Remove payment option"
        onClick={() => {
          removeUsedOption(index);
        }}
        data-testid={`remove-${index}-option`}
      />
    </Flex>
  );
};

export interface PaymentOptionsProps {
  kioskEnabled: boolean;
  payloadPaymentOptions: StoreSettingsPayload['payment_options'];
}

export const PaymentOptions = ({
  kioskEnabled,
  payloadPaymentOptions,
}: PaymentOptionsProps) => {
  const { control, clearErrors, setError, setValue, trigger, watch } =
    useFormContext<PaymentFormData>();
  const {
    fields: paymentOptions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'payment_options',
  });

  const value = watch('payment_options');

  const { duplicateIndexes } = useDuplicatesValidation({
    fieldArrayData: value,
    mappingMethod: (option: PaymentOption) => {
      return `${option.payment_option}: ${option.reservation_mode}`;
    },
    setError,
    clearErrors,
    trigger,
  });

  const removeUsedOption = (index: number) => {
    track({
      event: EventNames.DeleteStoreEntity,
      modal_name: ModalNames.Payments,
      entity_name: 'additional_payment_options',
    });
    remove(index);
  };

  const [mountAdditionalOptions, setMountAdditionalOptions] = useState(false);
  const [watchValue, setWatchValue] =
    useState<StoreSettingsPayload['payment_options']>();

  useEffect(() => {
    setValue('payment_options', payloadPaymentOptions);
  }, [JSON.stringify(payloadPaymentOptions), setValue]);

  useEffect(() => {
    // This ensures the additional options fields load with the correct enabled/disabled state on initial render
    if (value !== undefined && value.length) {
      setMountAdditionalOptions(true);
      setWatchValue(value);
    }
  }, [JSON.stringify(value)]);

  const modeOptions = useMemo(() => {
    const options = [
      {
        label: 'Pickup',
        value: 'pickup',
      },
      {
        label: 'Delivery',
        value: 'delivery',
      },
    ];
    if (kioskEnabled) {
      options.push({
        label: 'Kiosk',
        value: 'kiosk',
      });
    }
    return options;
  }, [kioskEnabled]);

  const providerValueMemoized = useMemo(() => {
    if (watchValue === undefined) {
      return payloadPaymentOptions;
    }

    return watchValue;
  }, [JSON.stringify(payloadPaymentOptions), JSON.stringify(watchValue)]);

  return (
    <>
      {mountAdditionalOptions &&
        watchValue !== undefined &&
        paymentOptions.map((option: PaymentOption, index: number) => {
          return (
            <Option
              key={option.id}
              option={option}
              index={index}
              modeOptions={modeOptions}
              removeUsedOption={removeUsedOption}
              providerValueMemoized={providerValueMemoized}
              duplicateIndexes={duplicateIndexes}
            />
          );
        })}
      <Flex justifyContent="center">
        <Button
          label="Add new"
          variant="secondary"
          onClick={() =>
            append({
              id: null,
              enabled: true,
              payment_option: '',
              reservation_mode: 'pickup',
            })
          }
          data-testid="add-new-button"
        />
      </Flex>
    </>
  );
};

import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import { useContext } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import {
  useModalActionsWithTracking,
  useParseHours,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { ALL_DAYS, ModalNames } from '@jane/business-admin/util';
import {
  Button,
  Card,
  EditIcon,
  Flex,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';

import { TableRowWithBorder } from '../../../../../TableWithBorderSeparator';
import { HoursModal } from './HoursModal';
import { HOURS_TABS } from './helpers';

const LoadingTableColumns = () => (
  <>
    {HOURS_TABS.map((hour) => (
      <td key={hour}>
        <Skeleton animate height="fit-content" width="100%">
          <Skeleton.Bone width="150px" height="24px" mx={16} />
        </Skeleton>
      </td>
    ))}
  </>
);

const TableHeaderItem = ({
  label,
  disabled,
}: {
  disabled?: boolean;
  label: string;
}) => (
  <td>
    <Typography
      variant="caps"
      ml={16}
      mb={16}
      color={disabled ? 'grays-mid' : 'grays-dark'}
    >
      {label} {disabled && '(Off)'}
    </Typography>
  </td>
);

const TableContentItem = ({ label }: { label?: string }) => (
  <td>
    <Typography m={16} color={label === 'Closed' ? 'grays-mid' : 'grays-dark'}>
      {label}
    </Typography>
  </td>
);

const StoreHoursTable = styled.table({
  borderCollapse: 'collapse',
});

export const StoreHoursCard = () => {
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const { data: storePayload, isFetching } = useStoreSettings(storeId);

  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.StoreHours
  );

  const schedules = useParseHours(storePayload);

  const checkForClosed = (schedule: string[]) => {
    const uniqueValues = new Set(schedule);
    return uniqueValues.has('Closed') && uniqueValues.size === 1;
  };

  return (
    <Card.Content background={!canEditStore ? 'grays-ultralight' : undefined}>
      <Flex p={24} flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center" mb={24}>
          <Typography variant="header-bold">Hours</Typography>

          {canEditStore && (
            <Button.Icon
              disabled={isFetching}
              icon={<EditIcon />}
              variant="secondary"
              label="Edit"
              onClick={() => openModal()}
            />
          )}
        </Flex>
        <Flex flexDirection="column">
          <StoreHoursTable
            style={{ borderCollapse: 'collapse' }}
            aria-label="Store hours table"
          >
            <thead>
              <tr>
                <TableHeaderItem label="Day" />
                {HOURS_TABS.map((tab, tabIndex) => (
                  <TableHeaderItem
                    key={tabIndex}
                    label={capitalize(tab)}
                    disabled={!isFetching && checkForClosed(schedules[tab])}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {ALL_DAYS.map((day, dayIndex) => (
                <TableRowWithBorder key={dayIndex} hasBorder={dayIndex < 6}>
                  <TableContentItem label={capitalize(day)} />
                  {isFetching ? (
                    <LoadingTableColumns />
                  ) : (
                    Object.values(schedules).map((schedule, scheduleIndex) => (
                      <TableContentItem
                        key={scheduleIndex}
                        label={(schedule as string[])[dayIndex]}
                      />
                    ))
                  )}
                </TableRowWithBorder>
              ))}
            </tbody>
          </StoreHoursTable>
        </Flex>
        {modalOpen && <HoursModal open setOpen={() => closeModal()} />}
      </Flex>
    </Card.Content>
  );
};

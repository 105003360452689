import type { SVGProps } from 'react';

const SvgSwitchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 14"
    {...props}
  >
    <path d="m18 4-4-4v3H7v2h7v3M4 6l-4 4 4 4v-3h7V9H4V6Z" fill="#5E1FF0" />
  </svg>
);
export default SvgSwitchIcon;

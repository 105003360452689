import { useMutation } from '@tanstack/react-query';

import { janeApiV2 } from '@jane/shared/data-access';

export type FeedbackFormData = {
  description: string;
  email: string;
  name: string;
  role: string;
  store_id: number | null;
  willing_to_provide_feedback: boolean;
};

const sendFeedback = (data: FeedbackFormData): Promise<FeedbackFormData> => {
  const storePath = window.location.pathname.match(/\/stores\/(\d*)\/([a-z]*)/);
  data.store_id = storePath ? parseInt(storePath[1]) : null;

  return janeApiV2.post<FeedbackFormData>(`/business/feedback`, {
    feedback: data,
  });
};

export const useSendFeedback = () => {
  return useMutation({
    mutationFn: (data: FeedbackFormData) => {
      return sendFeedback(data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      console.log('sent');
    },
  });
};

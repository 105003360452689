/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Eye24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Eye 24">
      <g className="SVG">
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
          clipRule="evenodd"
        />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M22.582 9.957a3.33 3.33 0 0 1 0 4.087c-6.173 7.942-14.991 7.941-21.164-.001a3.33 3.33 0 0 1 0-4.087c6.173-7.942 14.99-7.941 21.164.001Zm-1.58 2.86C18.197 16.425 14.987 18 12 18c-2.987 0-6.197-1.575-9.003-5.184a1.33 1.33 0 0 1 0-1.632C5.803 7.574 9.013 6 12 6c2.986 0 6.197 1.575 9.002 5.184a1.33 1.33 0 0 1 0 1.632Z"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Eye24,
  sm: Eye24,
  lg: Eye24,
  xl: Eye24,
  xxl: Eye24,
  xxxl: Eye24,
};

export const EyeIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'eye-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { Flex, Typography } from '@jane/shared/reefer';
import type { ColorKey, IconComponentProps } from '@jane/shared/reefer';

export const MenuTab = ({
  Icon,
  color,
  name,
  width,
}: {
  Icon: IconComponentProps;
  color: ColorKey;
  name: string;
  width: number;
}) => (
  <Flex
    data-testid="menu-tab"
    flexDirection="column"
    alignItems="center"
    width={width}
    height="100%"
    gap={4}
  >
    <Flex
      background="transparent"
      height={40}
      width={40}
      alignItems="center"
      justifyContent="center"
    >
      <Icon color={color} />
    </Flex>
    <Typography color="grays-mid" whiteSpace="nowrap" truncateAt="100%">
      {name}
    </Typography>
  </Flex>
);

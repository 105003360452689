import styled from '@emotion/styled';

import { Button, Flex } from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';

export const BundleProductCardsContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: 136,
  marginBottom: 20,
  overflowX: 'auto',
});

export const BundleProductContainer = styled(Flex)<{
  borderColor?: string;
  hasImage?: boolean;
}>(({ hasImage }) => ({
  background: hasImage ? 'white' : 'none',
  width: 120,
  height: 120,
  flex: '0 0 auto',
}));

export const StyledButtonIcon = styled(Button.Icon)({
  boxShadow: shadows.hard,
  height: '32px',
  width: '32px',
  minWidth: '32px',
  padding: 0,
});

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useSaveStoreIntegrations } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { AnalyticsIntegrationV2 } from '@jane/business-admin/types';
import {
  EventNames,
  ExternalLinkSourceIds,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Form,
  FormValidationError,
  Link,
  Modal,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';

interface GoogleAnalyticsFormData {
  enabled?: boolean;
  provider_id?: string;
}

const FORM_ERROR_NAME = 'ga-error';

export const GoogleAnalyticsModal = ({
  open,
  setOpen,
  analyticsIntegration,
  storeName,
}: {
  analyticsIntegration: AnalyticsIntegrationV2;
  open: boolean;
  setOpen: (open: boolean) => void;
  storeName: string;
}) => {
  const toast = useToast();
  const formName = 'Google Analytics settings form';

  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating Google analytics settings. Please try again.',
    [['analytics_integration', 'provider_id']]
  );

  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
    watch,
  } = formMethods;
  const enabledFormValue = watch('enabled');

  const { storeId } = useContext(StoreDetailsContext);
  const { mutateAsync: saveStoreIntegrations, isSuccess: saveSuccess } =
    useSaveStoreIntegrations(storeId);

  const onSubmit = (data: GoogleAnalyticsFormData) => {
    const requestData = {
      analytics_integration: data,
    };
    return catchSubmitErrors({
      submitMethod: () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.GoogleAnalytics,
          changed_attributes: Object.keys(dirtyFields),
        });

        return saveStoreIntegrations(requestData);
      },
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors['analytics_integration'])
        );
      },
    });
  };

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Google analytics settings updated',
        variant: 'success',
      });
      setOpen(false);
    }
  }, [saveSuccess]);

  const providerIdEnabled = useMemo(() => {
    if (enabledFormValue === undefined) {
      return analyticsIntegration.enabled;
    }

    return enabledFormValue;
  }, [enabledFormValue, analyticsIntegration.enabled]);

  const googleUrl =
    'https://developers.google.com/analytics/devguides/collection/gtagjs#install_the_google_tag';

  const { pathname } = useLocation();
  const trackExternalLink = useCallback(() => {
    track({
      event: EventNames.OpenExternalLink,
      from_url: pathname,
      to_url: googleUrl,
      trigger_source_id: ExternalLinkSourceIds.GoogleAnalyticsModal,
    });
  }, [pathname]);

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={setOpen}
      hasChanges={isDirty}
      variant="standard-dialogue"
      modalName={ModalNames.GoogleAnalytics}
    >
      <Form.BaseForm
        name={formName}
        onSubmit={onSubmit}
        formMethods={formMethods}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Google Analytics"
          subtitle={storeName}
          actions={<Form.SubmitButton variant="primary" label="Save" />}
        />

        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Form.SwitchField
            defaultChecked={analyticsIntegration.enabled}
            label="Enable"
            name={'enabled'}
            mb={24}
          />
          <Form.TextField
            defaultValue={analyticsIntegration.provider_id || ''}
            label="Tracking ID"
            name="provider_id"
            width="100%"
            mb={24}
            disabled={!providerIdEnabled}
            required={providerIdEnabled}
          />
          {/* // TODO: Add link to GA snippet */}
          <Typography color="grays-mid">
            A code snippet addition to your website is required if you would
            like to see accurate referral sources in Google Analytics. Please
            add the code snippet,{' '}
            <Link href={googleUrl} onClick={trackExternalLink}>
              located here
            </Link>
            , to your website. You can add this in a script tag in your primary
            HTML document or your primary JavaScript file.
          </Typography>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

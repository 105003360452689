import type { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';

export const RequirePlusAccess = ({ children }: PropsWithChildren) => {
  const plusEnabled = useFlag(FLAGS.bloomPlusBetaNav);

  if (!plusEnabled) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

import type { ReactNode } from 'react';
import React from 'react';

interface Props {
  canEditStore: boolean;
  children: ReactNode;
  storeId: string;
  storeName?: string;
}

export const StoreDetailsContext = React.createContext<Omit<Props, 'children'>>(
  {
    storeId: '',
    storeName: '',
    canEditStore: false,
  }
);

export const StoreDetailsProvider = ({
  children,
  storeId,
  storeName,
  canEditStore,
}: Props) => (
  <StoreDetailsContext.Provider
    value={{
      storeId,
      storeName,
      canEditStore,
    }}
  >
    {children}
  </StoreDetailsContext.Provider>
);

import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo } from 'react';

import {
  useProductTypes,
  useUpdateAutoPublishSettings,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useHasPermissions,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import type { ProductKind } from '@jane/shared/models';
import {
  Button,
  Flex,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useForm,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';

interface AutoPublishModalProps {
  onClose: () => void;
  open: boolean;
  productTypes?: ProductKind[];
  storeName?: string;
}

const FORM_ERROR_NAME = 'auto-publish-error';

export const AutoPublishModal = ({
  open,
  onClose,
  productTypes,
  storeName,
}: AutoPublishModalProps) => {
  const { storeId } = useContext(StoreDetailsContext);
  const { data: allProductTypes, isFetching: isFetchingProductTypes } =
    useProductTypes();
  const topLevelProductTypes = useMemo(() => {
    if (isFetchingProductTypes || !allProductTypes?.length) {
      return [];
    }

    return allProductTypes
      .filter(
        ({ product_type, product_subtype }) => product_type && !product_subtype
      )
      .reduce((result: ProductKind[], { product_type, product_subtype }) => {
        if (product_type && !product_subtype) {
          result.push(product_type);
        }
        return result;
      }, [])
      .sort((a, b) => a.localeCompare(b));
  }, [allProductTypes, isFetchingProductTypes]);

  const userCanEditProducts = useHasPermissions([Permission.EditProducts]);
  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error updating auto-publish settings. Please try again.`
  );
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
    setValue,
    watch,
  } = formMethods;
  const inputValues = watch();
  const selectionCount = useMemo(
    () =>
      (Object.values(inputValues).length
        ? Object.values(inputValues).filter((val) => val)
        : productTypes ?? []
      ).length,
    [inputValues]
  );
  const isEditable = useMemo(() => userCanEditProducts, [userCanEditProducts]);
  const {
    mutateAsync: updateAutoPublishSettings,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateAutoPublishSettings(storeId);

  const onSubmit = (formData: any) => {
    const requestData = {
      product_types: Object.entries(formData).reduce(
        (selections: ProductKind[], [name, selected]) => {
          if (selected) {
            selections.push(name as ProductKind);
          }
          return selections;
        },
        []
      ),
    };

    const submitMethod = () => {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.ProductAutoPublish,
        changed_attributes: Object.keys(dirtyFields),
      });

      return updateAutoPublishSettings(requestData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  const selectAll = () => {
    topLevelProductTypes.forEach((typeName) => {
      setValue(typeName, true);
    });
  };

  const selectNone = () => {
    topLevelProductTypes.forEach((typeName) => {
      setValue(typeName, false);
    });
  };

  useMutationStatusToasts({
    isMutating: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    successMessage: 'Auto-publish settings successfully updated',
    errorMessage: 'Error updating auto-publish settings. Please try again.',
  });

  useEffect(() => {
    if (!topLevelProductTypes.length) return;

    const selections = new Set(productTypes);

    topLevelProductTypes.forEach((typeName) => {
      if (selections.has(typeName)) {
        setValue(typeName, true, { shouldDirty: false });
      }
    });
  }, [topLevelProductTypes, productTypes]);

  useEffect(() => {
    if (isUpdateSuccess) {
      onClose();
    }
  }, [isUpdateSuccess]);

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={onClose}
      hasChanges={isDirty}
      modalName={ModalNames.ProductAutoPublish}
      variant="standard-dialogue"
    >
      <Form.BaseForm
        name="auto-publish settings"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Auto-publish unpublished products"
          subtitle={storeName}
          actions={
            <>
              {isEditable && (
                <Form.SubmitButton
                  variant="primary"
                  label="Save"
                  data-testid="auto-publish-settings-save"
                />
              )}
            </>
          }
        />
        <Modal.Content>
          <Typography mb={24}>
            Select categories to automatically publish any products that include
            required fields (i.e. product name and category)
          </Typography>
          <Flex flexDirection="row" flexWrap="wrap">
            {topLevelProductTypes.map((optionValue) => (
              <Flex width="50%" mb={24} key={`product-type_${optionValue}`}>
                <Form.CheckboxField
                  label={capitalize(optionValue)}
                  name={optionValue}
                />
              </Flex>
            ))}
          </Flex>
        </Modal.Content>
        <Modal.Footer>
          <Flex flexWrap="nowrap" alignItems="center">
            <Flex width="50%" justifyContent="flex-start" alignItems="center">
              <Typography color="grays-mid">
                {selectionCount} categories selected
              </Typography>
            </Flex>
            <Flex width="50%" justifyContent="flex-end">
              <Button
                variant="tertiary"
                label="Select all"
                mr={16}
                onClick={selectAll}
                disabled={selectionCount === topLevelProductTypes.length}
              />
              <Button
                variant="tertiary"
                label="Select none"
                onClick={selectNone}
                disabled={selectionCount === 0}
              />
            </Flex>
          </Flex>
        </Modal.Footer>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

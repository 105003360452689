import * as t from 'io-ts';

const tCartStatusOptions = t.union([
  t.literal('verification'),
  t.literal('preparing'),
  t.literal('delivery_started'),
  t.literal('ready_for_pickup'),
  t.literal('dismissed'),
]);

export const tStatusMessage = t.interface({
  cart_status: tCartStatusOptions,
  enabled: t.boolean,
  message_text: t.union([t.string, t.null]),
  use_default: t.boolean,
  store_id: t.union([t.string, t.number]),
  id: t.union([t.string, t.number]),
  pickup: t.union([t.null, t.boolean]),
  delivery: t.union([t.null, t.boolean]),
  kiosk: t.union([t.null, t.boolean]),
});

export type StatusMessage = t.TypeOf<typeof tStatusMessage>;

const tCartStatusOptionsV2 = t.union([
  t.literal('cancelled'),
  t.literal('delivery_finished'),
  t.literal('delivery_started'),
  t.literal('dismissed'),
  t.literal('finished_without_review'),
  t.literal('finished'),
  t.literal('pending'),
  t.literal('preparing'),
  t.literal('ready_for_pickup'),
  t.literal('staff_member_review'),
  t.literal('verification'),
  t.literal('with_review'),
]);

export const tStatusMessageV2 = t.interface({
  cart_status: tCartStatusOptions,
  enabled: t.boolean,
  message_text: t.union([t.string, t.null]),
  use_default: t.boolean,
  id: t.union([t.string, t.number]),
  kiosk: t.union([t.null, t.boolean]),
});
export type StatusMessageV2 = t.TypeOf<typeof tStatusMessageV2>;

export type CartStatusOptions = t.TypeOf<typeof tCartStatusOptions>;

export type CartStatusOptionsV2 = t.TypeOf<typeof tCartStatusOptionsV2>;

import type { DayJs, ManipulateTimeUnit } from '@jane/shared/types';

interface GetTimeRangeProps {
  end: DayJs;
  increment: number;
  outputFormat?: string;
  start: DayJs;
  unit: ManipulateTimeUnit;
}

export const getTimeRange = ({
  start,
  end,
  increment,
  unit,
  outputFormat,
}: GetTimeRangeProps): string[] | DayJs[] => {
  const range = [];
  let current = start;

  while (!current.isAfter(end)) {
    range.push(current);
    current = current.add(increment, unit);
  }
  return outputFormat ? range.map((time) => time.format(outputFormat)) : range;
};

import * as t from 'io-ts';

export enum SmsProvider {
  cm = 'cm',
  twilio = 'twilio',
}

const tMeta = t.interface({
  account_sid: t.string,
});
const tSmsSettingRequired = t.interface({
  id: t.union([t.string, t.number, t.null]),
  provider: t.keyof(SmsProvider),
  token: t.string,
  from_phone: t.string,
  meta: t.union([tMeta, t.null]),
});
const tSmsSettingOptional = t.partial({
  _destroy: t.union([t.undefined, t.boolean]),
});

export const tSmsSetting = t.intersection([
  tSmsSettingRequired,
  tSmsSettingOptional,
]);
export type SmsSetting = t.TypeOf<typeof tSmsSetting>;

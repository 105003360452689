import styled from '@emotion/styled';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';

import {
  useFetchNotifications,
  useUpdateSeenNotifications,
} from '@jane/business-admin/data-access';
import { useShouldShowJanePayUpsell } from '@jane/business-admin/hooks';
import type {
  AbbreviatedStoreV2,
  ManagerNotification,
} from '@jane/business-admin/types';
import { EventNames, track } from '@jane/business-admin/util';
import { Box, Button, Flex, Modal, Typography } from '@jane/shared/reefer';

import ClickIcon from '../assets/ClickIcon';
import ComplianceIcon from '../assets/ComplianceIcon';
import { JanePayUpsellImg } from '../assets/JanePayUpsellImg';
import StocksIcon from '../assets/StocksIcon';

const StyledModal = styled(Modal)({
  maxWidth: '575px',
});

const HeroImageContainer = styled.div(({ theme }) => ({
  height: '240px',
  backgroundColor: theme.colors.brand.purple.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const HeroImage = styled.div({
  background: JanePayUpsellImg,
  backgroundSize: 'cover',
  width: '575px',
  height: '240px',
  marginRight: '36px',
  flexShrink: 0,
});

const LineItem = ({
  bodyString,
  headerString,
  icon,
}: {
  bodyString: string;
  headerString: string;
  icon: ReactNode;
}) => (
  <Flex gap={32} alignItems="center" mb={24}>
    {icon}
    <Flex flexDirection="column">
      <Typography variant="body-bold">{headerString}</Typography>
      <Typography>{bodyString}</Typography>
    </Flex>
  </Flex>
);

export const JanePayUpsellModal = ({
  stores,
}: {
  stores: AbbreviatedStoreV2[];
}) => {
  const associatedStoreId = useMemo(() => {
    if (stores.length === 0) return '0';

    return stores[0].id.toString();
  }, [stores]);

  const [janePayUpsellModalOpen, setJanePayUpsellModalOpen] = useState(false);

  const { data: notificationsPayload, isFetched: notificationsFetched } =
    useFetchNotifications();
  const { mutateAsync: updateSeenNotifications } =
    useUpdateSeenNotifications(associatedStoreId);

  const janePayUpsellNotification =
    notificationsFetched &&
    notificationsPayload.notifications.modal &&
    notificationsPayload.notifications.modal.find(
      (notification: ManagerNotification) =>
        notification.header?.toLowerCase() === 'janepay'
    );

  const showJanePayUpsellModal = useShouldShowJanePayUpsell(
    janePayUpsellNotification
  );

  useEffect(() => {
    setJanePayUpsellModalOpen(showJanePayUpsellModal);
  }, [showJanePayUpsellModal]);

  return (
    <StyledModal
      open={janePayUpsellModalOpen}
      onRequestClose={() => setJanePayUpsellModalOpen(false)}
      variant="standard-dialogue"
      appId="root"
    >
      <HeroImageContainer>
        <HeroImage />
      </HeroImageContainer>

      <Modal.Content>
        <Box px={24}>
          <Flex justifyContent="center">
            <Typography variant="title-bold" mb={24}>
              Introducing Jane Pay
            </Typography>
          </Flex>
          <LineItem
            headerString="$500+ increase per customer"
            bodyString="Increase revenue by >$500 per customer annually by allowing them to pay online and earn online rewards."
            icon={<StocksIcon />}
          />
          <LineItem
            headerString="Compliant & safe"
            bodyString="Give consumers the power of safe & easy checkout, and reduce reliance on cash management for store & employee safety."
            icon={<ComplianceIcon />}
          />
          <LineItem
            headerString="Ecommerce integration"
            bodyString="Get more orders with higher cart sizes. Jane integration ensures transactions are accurate to the penny."
            icon={<ClickIcon />}
          />
          <Flex justifyContent="center" mb={24}>
            <Typography variant="mini" color="grays-mid">
              You can offer Jane Pay by enabling it in your store’s checkout
              settings.
            </Typography>
          </Flex>
          <Flex width="100%" gap={16}>
            <Button
              label="Close"
              variant="tertiary"
              full
              onClick={() => {
                setJanePayUpsellModalOpen(false);
                updateSeenNotifications({
                  notification_ids: [janePayUpsellNotification.id],
                });
              }}
            />
            <Button
              label="Get in touch"
              variant="primary"
              full
              href={`mailto:partnersuccess@iheartjane.com`}
              onClick={() => {
                track({
                  event: EventNames.JanePayGetInTouch,
                  notification_type: 'modal',
                });
              }}
            />
          </Flex>
        </Box>
      </Modal.Content>
    </StyledModal>
  );
};

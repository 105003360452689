import { Button, Flex } from '@jane/shared/reefer';

export const SeeAllButton = ({
  onClick,
}: {
  onClick: (() => void) | undefined;
}) => {
  return (
    <Flex justifyContent="center" mt={24}>
      <div style={{ width: 200 }}>
        <Button variant="tertiary" full label="See all" onClick={onClick} />
      </div>
    </Flex>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { tNotification } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';

export const NOTIFICATIONS_URL = '/business/notifications';

const fetchNotifications = async (): Promise<any> => {
  const data = await fetchWithDecode(
    janeApiV2.get(NOTIFICATIONS_URL),
    tNotification,
    NOTIFICATIONS_URL
  );

  return data;
};

export const useFetchNotifications = () => {
  return useQuery({
    queryFn: () => fetchNotifications(),
    queryKey: ['notifications'],
    refetchInterval: 5 * (60 * 1000),
    useErrorBoundary: true,
  });
};

export type SeenNotifications = {
  notification_ids: number[];
};
const updateSeenNotifications = async (
  data: SeenNotifications
): Promise<null> =>
  await janeApiV2.patch<null>(`${NOTIFICATIONS_URL}/mark_seen`, data);

export const useUpdateSeenNotifications = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SeenNotifications) => {
      return updateSeenNotifications(data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications']);
    },
  });
};

import { useEffect } from 'react';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { useManager } from '@jane/business-admin/data-access';
import { businessPaths } from '@jane/business-admin/util';

export const RequireAnonymousUser = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { data: currentManager, isFetched } = useManager();

  useEffect(() => {
    //TODO: This is some janky shit. Figure out a better way to handle this.
    document.body.style.background =
      'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #5E1FF0';
    return () => {
      //TODO: This is some janky shit. Figure out a better way to handle this.
      document.body.style.background = '#FFF';
    };
  }, []);

  useEffect(() => {
    // redirect if user has currentManager data
    if (isFetched && currentManager?.manager?.id) {
      // TODO: Causing multiple events to be logged when user logs in
      // track({
      //   event: EventNames.AccessDenied,
      //   reason: 'Already logged in',
      // });

      navigate(businessPaths.loggedInRoot());
    }
  }, [currentManager?.manager?.id, isFetched]);

  return !isFetched ? null : <>{children}</>;
};

import styled from '@emotion/styled';

export const StyledListItem = styled.li<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    width: '100%',
    paddingRight: 1,
    scrollSnapAlign: 'start',
    '&:hover .menu-category--icon': {
      transition: 'transform 150ms',
      transform: !isSelected ? 'scale(1.15)' : 'scale(1)',
    },
  })
);

export const StyledInnerItem = styled.div({
  margin: '0 auto',
  width: '100%',
});

export const StyledMenuTabButton = styled.button<{ width: number }>(
  ({ theme, width }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width,
    height: '100%',
    gap: 4,
    '&:focus-visible': {
      background: theme.colors.grays.light,
      borderRadius: theme.borderRadius.sm,
      outline: 'none',
    },
  })
);

export const MenuTabIconContainer = styled.div({
  borderRadius: '50%',
  overflow: 'hidden',
});

import styled from '@emotion/styled';

import { BusinessNav } from './BusinessNav';

const HeaderWrapper = styled.div(({ theme }) => ({
  background: theme.colors.grays.ultralight,
}));

export const BusinessHeader = () => (
  <HeaderWrapper>
    <BusinessNav />
  </HeaderWrapper>
);

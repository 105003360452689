import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { DeliveryGeofenceV2 } from '@jane/business-admin/types';
import { janeApiV2 } from '@jane/shared/data-access';

import { STORES_URL } from './stores';

type GeofenceData = Omit<
  Partial<DeliveryGeofenceV2>,
  'cart_minimum' | 'fee'
> & {
  cart_minimum?: number;
  fee?: number;
};

const createGeofence = async (
  data: GeofenceData,
  storeId?: string
): Promise<DeliveryGeofenceV2> => {
  return await janeApiV2.post<DeliveryGeofenceV2>(
    `${STORES_URL}/${storeId}/fulfillments/delivery/geofence`,
    data
  );
};

export const useCreateGeofence = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: GeofenceData) => createGeofence(data, storeId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId]);
    },
  });
};

const updateGeofence = async (
  data: GeofenceData,
  storeId?: string
): Promise<DeliveryGeofenceV2> => {
  return await janeApiV2.patch<DeliveryGeofenceV2>(
    `${STORES_URL}/${storeId}/fulfillments/delivery/geofence`,
    data
  );
};

export const useUpdateGeofence = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: GeofenceData) => updateGeofence(data, storeId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId]);
    },
  });
};

const deleteGeofence = async (
  geofenceId: number,
  storeId?: string
): Promise<null> => {
  return await janeApiV2.delete(
    `${STORES_URL}/${storeId}/fulfillments/delivery/geofence/${geofenceId}`
  );
};

export const useDeleteGeofence = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (geofenceId: number) => {
      return deleteGeofence(geofenceId, storeId);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId]);
    },
  });
};

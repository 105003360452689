import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { StoreDetailsContext } from '@jane/business-admin/providers';
import { SpecialStatus } from '@jane/business-admin/types';
import { ButtonToggle, Flex, Tag, Typography } from '@jane/shared/reefer';

import { ButtonToggleStylesOverrideWrapper } from '../shared/tables/ButtonToggleStylesOverrideWrapper';

type CountsByStatus = Record<SpecialStatus, number>;

interface SpecialStatusFilterProps {
  countsByStatus?: CountsByStatus;
  isFetching: boolean;
  onChange: (value: SpecialStatus) => void;
  showResultCounts?: boolean;
  value: string;
}

const isValidSpecialStatus = (value: unknown): value is SpecialStatus =>
  typeof value === 'string' &&
  (Object.values(SpecialStatus) as string[]).includes(value);

export const SpecialStatusFilter = ({
  value,
  onChange,
  showResultCounts = false,
  countsByStatus,
  isFetching,
}: SpecialStatusFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { storeId } = useContext(StoreDetailsContext);
  const { special_id: specialId = '' } = useParams<'special_id'>();

  const prevCounts = useRef<CountsByStatus>(
    Object.keys(SpecialStatus).reduce((obj, key) => {
      obj[key as SpecialStatus] = 0;
      return obj;
    }, {} as CountsByStatus)
  );

  const [selected, setSelected] = useState<SpecialStatus>(
    (value as SpecialStatus) || SpecialStatus.live
  );
  // When tab is selected, update URL
  useEffect(() => {
    if (selected && !specialId) {
      localStorage.setItem('currentSpecialStatus', selected);
      setSearchParams((prev) => {
        prev.set('status', selected);
        return prev;
      });
    }
  }, [selected, storeId, specialId]);

  // When page is loaded with tab in URL, update selected tab
  useEffect(() => {
    setSelected(searchParams.get('status') as SpecialStatus);
  }, [searchParams.get('status')]);

  useEffect(() => {
    if (countsByStatus) {
      prevCounts.current = countsByStatus;
    }
  }, [countsByStatus]);

  const counts = useMemo(() => {
    if (isFetching) return prevCounts.current;
    return countsByStatus;
  }, [isFetching, prevCounts, countsByStatus]);

  return (
    <Flex gap={16} mb={24} mx={64} alignItems="center">
      <ButtonToggleStylesOverrideWrapper>
        <ButtonToggle
          value={selected}
          onChange={(value) => {
            setSelected(value as SpecialStatus);
            if (isValidSpecialStatus(value)) {
              onChange(value as SpecialStatus);
            }
          }}
          full={false}
        >
          {Object.keys(SpecialStatus).map((status) => {
            const noResults = counts && counts[status as SpecialStatus] === 0;

            return (
              <ButtonToggle.Button
                key={`special-status-btn-${status}`}
                value={status}
                disabled={noResults}
              >
                <Flex flexDirection="row">
                  <Typography variant="body-bold" as="span" color="inherit">
                    {capitalize(status)}
                  </Typography>

                  {showResultCounts && !!counts && !noResults && (
                    <Tag
                      ml={8}
                      background="primary-light"
                      color="primary-dark"
                      label={counts[status as SpecialStatus].toString()}
                    />
                  )}
                </Flex>
              </ButtonToggle.Button>
            );
          })}
        </ButtonToggle>
      </ButtonToggleStylesOverrideWrapper>
    </Flex>
  );
};

import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { Accordion } from '@jane/shared/components';
import { useJaneUser } from '@jane/shared/data-access';
import type { Spacing } from '@jane/shared/reefer';
import {
  Box,
  Button,
  EditIcon,
  Flex,
  Link,
  Typography,
} from '@jane/shared/reefer';

import { CheckoutStep } from '../checkout.styles';
import { useCheckoutAccordion } from './CheckoutAccordionProvider';
import type { AccordionItems } from './CheckoutAccordionProvider';

interface CheckoutAccordionItemProps {
  children: ReactNode;
  header: string;
  id: AccordionItems;
  mt?: Spacing;
  summary?: ReactNode;
}

const NoWrapLink = styled(Link)({
  whiteSpace: 'nowrap',
});

export const CheckoutAccordionItem = ({
  children,
  header,
  id,
  mt,
  summary,
}: CheckoutAccordionItemProps) => {
  const {
    appInfo: { disableAuthFeatures },
    guestCheckout: { setShowLoginModal },
  } = useCheckout();
  const { data: janeUserData } = useJaneUser();
  const { expandableItems, expanded, setExpanded } = useCheckoutAccordion();

  const signedIn = Boolean(janeUserData?.user?.id);
  const showLogin = !signedIn && id === 'options' && !disableAuthFeatures;
  const isExpanded = expanded === id;

  return (
    <CheckoutStep mt={mt} width="100%">
      <Accordion.Item id={id} expanded={isExpanded}>
        <Accordion.Heading
          onClick={() => setExpanded(id)}
          disabled={!expandableItems.includes(id)}
        >
          <Flex alignItems="center">
            <Typography variant="header-bold" branded>
              {header}
            </Typography>

            {(!isExpanded || showLogin) && (
              <Flex flexDirection="row" ml="auto">
                {showLogin && (
                  <NoWrapLink
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowLoginModal(true);
                    }}
                    mr={isExpanded ? 0 : 16}
                    ml={16}
                    my="auto"
                  >
                    Sign in
                  </NoWrapLink>
                )}

                {!isExpanded && (
                  <Button.Icon
                    disabled={!expandableItems.includes(id)}
                    variant="tertiary"
                    ml="auto"
                    icon={<EditIcon />}
                  />
                )}
              </Flex>
            )}
          </Flex>
          {!isExpanded && summary && <Box mt={24}>{summary}</Box>}
        </Accordion.Heading>
        <Accordion.Content>
          <Box mt={24}>{children}</Box>
        </Accordion.Content>
      </Accordion.Item>
    </CheckoutStep>
  );
};

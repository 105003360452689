import { useState } from 'react';

import type { CartLimitRuleV2 } from '@jane/business-admin/types';
import { Button, Flex, Form, Modal, Typography } from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../../../ConfirmWrapperWithTracking';
import { CategoryList } from './CategoryList';
import { RulePreview } from './RulePreview';
import { RuleTypeToggle } from './RuleTypeToggle';
import { UnitSelect } from './UnitSelect';

export interface RuleSettingsModalProps {
  isNewRule: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSubmit: (params: unknown) => void;
  open: boolean;
  rule?: CartLimitRuleV2;
}

export const RuleSettingsModal = ({
  open,
  rule,
  isNewRule,
  onClose,
  onSubmit,
  onDelete,
}: RuleSettingsModalProps) => {
  const [formIsDirty, setFormIsDirty] = useState(false);

  const handleSubmit = (formData: any) => {
    if (rule?.id) formData.id = rule.id;
    onSubmit(formData);
    onClose();
  };

  const handleDelete = () => {
    onClose();
    onDelete();
  };

  const handleClose = () => {
    if (isNewRule) onDelete();
    onClose();
  };

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={handleClose}
      hasChanges={formIsDirty}
      appId="policy-settings"
      modalName={`${isNewRule ? 'Add' : 'Edit'} Cart Limit Rule`}
    >
      <Form
        name="rule settings"
        onDirty={setFormIsDirty}
        onSubmit={handleSubmit}
      >
        <Modal.Header
          title={`${isNewRule ? 'Add' : 'Edit'} rule`}
          actions={
            <>
              {!isNewRule && (
                <Button
                  type="button"
                  label="Delete"
                  variant="destructive"
                  onClick={handleDelete}
                  mr={16}
                />
              )}
              <Form.SubmitButton
                label={isNewRule ? 'Add' : 'Update'}
                variant="primary"
              />
            </>
          }
        />

        <Modal.Content>
          <Flex flexDirection="column" mb={40}>
            <Typography variant="body-bold" mb={8}>
              Set policy rules
            </Typography>
            <Typography color="grays-mid">
              Define amounts for products to apply to the cart. These rules will
              display as a warning during checkout.
            </Typography>
          </Flex>

          <Flex flexDirection="column" mb={40}>
            <Typography variant="caps" mb={8}>
              Cart limit preview
            </Typography>

            <RulePreview />
          </Flex>

          <Flex flexDirection="column">
            <Typography variant="caps" mb={16}>
              Select Product Rule Type
            </Typography>

            <Flex mb={40}>
              <RuleTypeToggle type={rule?.rule_type} />
            </Flex>

            <Flex justifyContent="space-between" gap={24} mb={40}>
              <Form.TextField
                label="Rule label"
                name="product_group_name"
                defaultValue={rule?.product_group_name ?? 'Vape'}
                placeholder="Rule label"
                width="100%"
                required
              />

              <Form.NumberField
                label="Limit amount"
                name="limit_value"
                defaultValue={
                  rule?.limit_value
                    ? parseInt(rule.limit_value ?? 0)
                    : undefined
                }
                placeholder="Limit amount"
                width="100%"
                required
              />

              <UnitSelect limitUnit={rule?.limit_unit} />
            </Flex>

            <CategoryList ruleProductTypes={rule?.product_types} />
          </Flex>
        </Modal.Content>
      </Form>
    </ConfirmWrapperWithTracking>
  );
};

import { z } from 'zod';

export const zBrand = z.object({
  id: z.string().uuid(),
  productBrandId: z.number().int().positive(),
  name: z.string(),
  logoUrl: z.string().nullable(),
});

export const zBrandResponse = z.object({
  brands: z.array(zBrand),
  meta: z.object({
    number_of_pages: z.number(),
    page: z.number(),
    page_total: z.number(),
    per_page: z.number(),
    total_brands: z.number(),
  }),
});

export type Brand = z.infer<typeof zBrand>;
export type BrandResponse = z.infer<typeof zBrandResponse>;

import {
  NotificationsCard,
  SmsSettingsCard,
} from '@jane/business-admin/components';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Flex } from '@jane/shared/reefer';

export const StoreSettingsNotifications = () => {
  const hasSmsAccess = useFlag(FLAGS.scSmsIntegration);

  return (
    <Flex flexDirection="column">
      {hasSmsAccess && <SmsSettingsCard />}
      <NotificationsCard />
    </Flex>
  );
};

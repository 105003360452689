import { arrayMove } from '@dnd-kit/sortable';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import escape from 'lodash/escape';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useStoreMenu,
  useStoreSettings,
} from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type {
  MenuRow,
  MenuTab,
  StoreSettingsPayload,
} from '@jane/business-admin/types';
import type { ModalNames, SettingNames } from '@jane/business-admin/util';
import {
  ErrorReasons,
  EventNames,
  blankArrayOfLength,
  normalizePath,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { SortableList } from '@jane/shared/components';
import type { SortableItem } from '@jane/shared/components';
import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Button,
  Flex,
  Form,
  FormValidationError,
  HideEyeIcon,
  List,
  Modal,
  MoreIcon,
  Popover,
  Skeleton,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../ConfirmWrapperWithTracking';
import { getRowLabel, getTabLabel } from './landing/utils';

const FORM_ERROR_NAME = 'menu-row-arrange-errors';

type ArrangeModalType = 'row' | 'tab';

type ArrangeRowProps<MenuItem> = Pick<
  ArrangeModalProps<MenuItem, []>,
  | 'allowDisablingForItem'
  | 'allowRearrangingForItem'
  | 'enabledKey'
  | 'getItemCountLabel'
  | 'getItemEnabled'
  | 'getItemId'
  | 'getItemToggleDisabled'
  | 'rankKey'
  | 'showHiddenIconForItem'
  | 'type'
> & {
  item: MenuItem;
  itemLabel: string;
  modalName: ModalNames;
  moveToBottom: (
    item: MenuItem,
    modalName: ModalNames,
    settingName: SettingNames
  ) => void;
  moveToTop: (
    item: MenuItem,
    modalName: ModalNames,
    settingName: SettingNames
  ) => void;
  rankKey: keyof MenuItem;
  rowEnabledChanged: (rank: number, enabled: boolean) => void;
  settingName: SettingNames;
};

const ArrangeRow = <MenuItem,>({
  allowDisablingForItem,
  allowRearrangingForItem,
  enabledKey,
  getItemCountLabel,
  getItemEnabled,
  getItemId,
  getItemToggleDisabled,
  item,
  itemLabel,
  modalName,
  moveToBottom,
  moveToTop,
  rankKey,
  rowEnabledChanged,
  settingName,
  showHiddenIconForItem,
  type,
}: ArrangeRowProps<MenuItem>) => {
  const handleMoveToTop = (closePopover: () => void) => {
    moveToTop(item, modalName, settingName);

    closePopover();
  };

  const handleMoveToBottom = (closePopover: () => void) => {
    moveToBottom(item, modalName, settingName);

    closePopover();
  };

  const handleToggleVisibility = (enabled: boolean) => {
    rowEnabledChanged(item[rankKey] as number, enabled);

    track({
      event: EventNames.ToggleVisibility,
      final_state: enabled ? 'visible' : 'hidden',
      trigger_source_id: 'arrange modal',
      successful: true,
      object: `menu ${type}`,
    });
  };

  const itemCountLabel = getItemCountLabel && getItemCountLabel(item);
  const allowRearraging = allowRearrangingForItem
    ? allowRearrangingForItem(item)
    : true;
  const enabled = getItemEnabled
    ? getItemEnabled(item)
    : (item[enabledKey] as boolean);
  const toggleDisabled = getItemToggleDisabled
    ? getItemToggleDisabled(item)
    : false;

  return (
    <Flex data-nonworking-example width="100%" justifyContent="space-between">
      <Flex gap={16} alignItems="center">
        <Typography>{itemLabel}</Typography>
        {showHiddenIconForItem(item) ? <HideEyeIcon /> : null}
      </Flex>
      <Flex flexDirection={'row'} gap={16} alignItems="center">
        {itemCountLabel && <Typography>{itemCountLabel}</Typography>}
        {allowDisablingForItem(item) && (
          <Form.SwitchField
            data-testid={`enabled-checkbox-${getItemId(item)}`}
            defaultChecked={enabled}
            labelHidden
            label="visible"
            name={escape(itemLabel)}
            onChange={handleToggleVisibility}
            disabled={toggleDisabled}
          />
        )}
        {allowRearraging && (
          <Popover
            target={<Button.Icon icon={<MoreIcon />} />}
            openOn="click"
            alignment={{ horizontal: 'right' }}
            label="more options"
            closeOnTargetClick
          >
            {({ closePopover }: PopoverContextProps) => (
              <Popover.Content>
                <List label="more options items">
                  <List.Item
                    ariaLabel="Bring to top"
                    onClick={() => handleMoveToTop(closePopover)}
                  >
                    <Typography>Bring to top</Typography>
                  </List.Item>
                  <List.Item
                    ariaLabel="Bring to bottom"
                    onClick={() => handleMoveToBottom(closePopover)}
                  >
                    <Typography>Bring to bottom</Typography>
                  </List.Item>
                </List>
              </Popover.Content>
            )}
          </Popover>
        )}
      </Flex>
    </Flex>
  );
};

interface ArrangeModalProps<MenuItem, UpdateBody extends Array<unknown>> {
  allowDisablingForItem: (item: MenuItem) => boolean;
  allowRearrangingForItem?: (item: MenuItem) => boolean;
  closeModal: () => void;
  enabledKey: keyof MenuItem;
  filterMenuItems?: (
    items: MenuItem[],
    storeSettings?: StoreSettingsPayload
  ) => MenuItem[];
  formatItem?: (item: { id: string; item: MenuItem }) => UpdateBody[number];
  getItemCountLabel?: (item: MenuItem) => string;
  getItemEnabled?: (item: MenuItem) => boolean;
  getItemId: (item: MenuItem) => string;
  getItemToggleDisabled?: (item: MenuItem) => boolean;
  menuDataKey: 'menu_rows' | 'menu_tabs';
  modalName: ModalNames;
  rankKey: keyof MenuItem;
  settingName: SettingNames;
  showHiddenIconForItem: (item: MenuItem) => boolean;
  title?: string;
  type: ArrangeModalType;
  updateMenuOrder: UseMutateAsyncFunction<null, unknown, UpdateBody, unknown>;
  updateMenuOrderSuccess: boolean;
}

export const ArrangeModal = <MenuItem, UpdateBody extends Array<unknown>>({
  allowDisablingForItem,
  allowRearrangingForItem,
  closeModal,
  enabledKey,
  filterMenuItems,
  formatItem,
  getItemCountLabel,
  getItemEnabled,
  getItemId,
  getItemToggleDisabled,
  menuDataKey,
  modalName,
  rankKey,
  settingName,
  showHiddenIconForItem,
  title,
  type,
  updateMenuOrder,
  updateMenuOrderSuccess,
}: ArrangeModalProps<MenuItem, UpdateBody>) => {
  const { id = '' } = useParams<'id'>();
  const { pathname } = useLocation();
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(id);
  const { data: menuData, isFetched } = useStoreMenu(id);
  const formMethods = useForm();
  const [sortedMenuItems, setSortedMenuItems] = useState<MenuItem[]>([]);
  const originalRowData = useRef<MenuItem[]>();
  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error updating menu ${type} order. Please try again.`
  );
  const toast = useToast();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  useEffect(() => {
    if (isFetched && !!menuData) {
      originalRowData.current = [
        // Seems ridiculous, but otherwise array objects get modified downstream and we can't revert to original data when changes are discarded
        ...JSON.parse(JSON.stringify(menuData[menuDataKey])),
      ];
      setSortedMenuItems(originalRowData.current);
    } else {
      setSortedMenuItems([]);
    }
  }, [isFetched, JSON.stringify(menuData)]);

  const onCloseDiscardChanges = () => {
    setSortedMenuItems(originalRowData.current || []);
  };

  const onReorder = (items: SortableItem<MenuItem>[]) => {
    const reformat = items.map(({ item }) => item!);
    setSortedMenuItems(reformat);
    track({
      action: 'dragged',
      event: EventNames.ModifiedSetting,
      revert: false,
      setting_name: settingName,
      modal_name: modalName,
    });
  };

  useEffect(() => {
    if (updateMenuOrderSuccess) {
      toast.add({
        label: `Menu ${type} order updated.`,
        variant: 'success',
      });
      closeModal();
    }
  }, [updateMenuOrderSuccess]);

  const onSubmit = () => {
    const formattedData = [] as unknown as UpdateBody;
    sortedDisplayMenuItems.forEach((item) => {
      const formattedItem = formatItem ? formatItem(item) : item;
      formattedData.push(formattedItem);
    });

    const changedKeys = Object.keys(dirtyFields);
    if (hasChangedOrder) {
      changedKeys.push('menuRowOrder');
    }

    const trackSubmit = (eventProps = {}) => {
      track({
        event: EventNames.EditedStoreMenuConfig,
        action: 'update',
        changed_attributes: changedKeys,
        modal_name: modalName,
        setting_name: settingName,
        successful: true,
        url: normalizePath(pathname, id),
        ...eventProps,
      });
    };

    return catchSubmitErrors({
      submitMethod: async () => {
        await updateMenuOrder(formattedData);
        trackSubmit();
      },
      requestData: formattedData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.InvalidParams,
        });
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      callback: () => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.ServerError,
        });
        throw new Error(`Error updating menu ${type} order. Please try again.`);
      },
    });
  };

  const moveToTop = (
    item: MenuItem,
    modalName: ModalNames,
    settingName: SettingNames
  ) => {
    // Get current index in array rather than using ranking, as
    // ranking does not take into account disabled items in the array
    const currentIndex = sortedMenuItems.indexOf(item);

    // Find the first item in list that can be rearranged
    const firstAvailableSlot = sortedMenuItems.find((item) =>
      allowRearrangingForItem ? allowRearrangingForItem(item) : true
    );
    const newSlot = firstAvailableSlot
      ? sortedMenuItems.indexOf(firstAvailableSlot)
      : 0;
    const newOrder = arrayMove(sortedMenuItems, currentIndex, newSlot);
    setSortedMenuItems(newOrder);
    track({
      action: 'bring to top',
      event: EventNames.ModifiedSetting,
      setting_name: settingName,
      revert: false,
      modal_name: modalName,
    });
  };

  const moveToBottom = (
    item: MenuItem,
    modalName: ModalNames,
    settingName: SettingNames
  ) => {
    // Get current index in array rather than using ranking, as
    // ranking does not take into account disabled items in the array
    const currentIndex = sortedMenuItems.indexOf(item);
    const newIndex = sortedMenuItems.length - 1;
    const newOrder = arrayMove(sortedMenuItems, currentIndex, newIndex);
    setSortedMenuItems(newOrder);
    track({
      action: 'bring to bottom',
      event: EventNames.ModifiedSetting,
      setting_name: settingName,
      revert: false,
      modal_name: modalName,
    });
  };

  const changeRowEnabled = (rank: number, enabled: boolean) => {
    setSortedMenuItems((items) => {
      (items[rank][enabledKey] as boolean) = enabled;
      return items;
    });
  };

  const filteredDisplayMenuItems = useMemo(() => {
    return filterMenuItems
      ? filterMenuItems(sortedMenuItems, storePayload)
      : sortedMenuItems;
  }, [filterMenuItems, sortedMenuItems, storePayload]);

  const sortedDisplayMenuItems = useMemo(() => {
    return filteredDisplayMenuItems.map((item, index) => {
      (item[rankKey] as number) = index;

      return {
        id: getItemId(item),
        item,
      };
    });
  }, [JSON.stringify(filteredDisplayMenuItems)]);

  const hasChangedOrder = useMemo(() => {
    if (isFetched && !!menuData) {
      return (
        JSON.stringify(sortedMenuItems) !==
        JSON.stringify(menuData[menuDataKey])
      );
    }
    return false;
  }, [isFetched, menuData, sortedMenuItems]);

  return (
    <ConfirmWrapperWithTracking
      open
      setOpen={closeModal}
      variant="standard"
      hasChanges={isDirty || hasChangedOrder}
      modalName={modalName}
      onCloseDiscardChanges={onCloseDiscardChanges}
    >
      <Form.BaseForm
        name={`arrange menu ${type}s form`}
        onSubmit={onSubmit}
        formMethods={formMethods}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={title || `Arrange menu ${type}s`}
          subtitle={storeSettingsLoading ? '' : storePayload?.store.name}
          actions={
            <Form.SubmitButton variant="primary" label="Publish" ml={16} />
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          {isFetched && menuData && sortedDisplayMenuItems.length ? (
            <SortableList
              disabledForItem={
                allowRearrangingForItem
                  ? ({ item }) => !allowRearrangingForItem(item!)
                  : undefined
              }
              direction="vertical"
              items={sortedDisplayMenuItems}
              onChange={onReorder}
              renderItem={({ item }) => (
                <ArrangeRow<MenuItem>
                  allowDisablingForItem={allowDisablingForItem}
                  allowRearrangingForItem={allowRearrangingForItem}
                  enabledKey={enabledKey}
                  getItemCountLabel={getItemCountLabel}
                  getItemEnabled={getItemEnabled}
                  getItemId={getItemId}
                  getItemToggleDisabled={getItemToggleDisabled}
                  modalName={modalName}
                  moveToTop={moveToTop}
                  item={item!}
                  itemLabel={
                    type === 'row'
                      ? getRowLabel(
                          item as MenuRow,
                          menuData.filters_and_labels?.custom_labels
                        )
                      : getTabLabel(
                          item as MenuTab,
                          menuData.filters_and_labels?.custom_labels
                        )
                  }
                  moveToBottom={moveToBottom}
                  rowEnabledChanged={changeRowEnabled}
                  rankKey={rankKey}
                  settingName={settingName}
                  showHiddenIconForItem={showHiddenIconForItem}
                  type={type}
                />
              )}
            />
          ) : (
            <ArrangeModalSkeleton />
          )}
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

const length10Array = blankArrayOfLength(10);

function ArrangeModalSkeleton() {
  return (
    <Skeleton direction="column" animate>
      {length10Array.map((i) => (
        <Skeleton.Bone
          key={`skeleton-${i}`}
          mb={24}
          height="48px"
          width="100%"
          borderRadius="sm"
        />
      ))}
    </Skeleton>
  );
}

import styled from '@emotion/styled';
import groupBy from 'lodash/groupBy';
import { Fragment, useContext, useMemo, useState } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import {
  useHasPermissions,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { ModalNames } from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import type { CheckoutAgreementV2 } from '@jane/shared/models';
import { Flex, Skeleton } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { CardSection } from '../../../../../CardSection';
import { EditableCard } from '../../../../../EditableCard';
import { AgreementConditions } from './AgreementConditions';
import { AgreementDetails } from './AgreementDetails';
import { AgreementReportLink } from './AgreementReportLink';
import { CheckoutAgreementsModal } from './CheckoutAgreementsModal';
import { OptInReportModal } from './OptInReportModal';

const AgreementSkeleton = () => (
  <CardSection isLoading label={''}>
    <AgreementWrapper hasBorder={false}>
      <Flex>
        <Skeleton animate>
          <Skeleton.Bone width={300} height={24} />
          <Skeleton.Bone width={300} height={24} mt={24} />
        </Skeleton>
      </Flex>
      <Flex width={180}>
        <Skeleton animate>
          <Skeleton.Bone height={24} />
          <Skeleton.Bone height={24} mt={24} />
        </Skeleton>
      </Flex>
    </AgreementWrapper>
  </CardSection>
);

const AgreementWrapper = styled(Flex)<{ hasBorder?: boolean }>(
  ({ theme, hasBorder }) => ({
    borderColor: theme.colors.grays.light,
    borderBottomWidth: hasBorder ? 1 : 0,
    borderBottomStyle: 'solid',
    margin: 24,
    paddingBottom: 24,
    marginBottom: 0,
    justifyContent: 'space-between',
  })
);

const GroupSeparator = styled.div(({ theme }) => ({
  ...spacing({ mb: 24 }),
  borderColor: theme.colors.grays.light,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
}));

export const Agreement = ({
  label,
  external_link,
  consent_requirement,
  reservation_mode,
  lastItem,
  openReportModal,
}: CheckoutAgreementV2 & {
  lastItem: boolean;
  openReportModal: () => void;
}) => {
  return (
    <AgreementWrapper hasBorder={!lastItem}>
      <AgreementDetails
        showHeading
        grow={1}
        shrink={1}
        basis={'0'}
        label={label}
        external_link={external_link}
        truncateLabelTo={180}
      />
      <AgreementConditions
        showHeading
        consent_requirement={consent_requirement}
        reservation_mode={reservation_mode}
        width={180}
        ml={12}
      />
      <AgreementReportLink
        showHeading
        ml={12}
        width={140}
        onClick={openReportModal}
      />
    </AgreementWrapper>
  );
};

export const CheckoutAgreementsCard = () => {
  const { storeId } = useContext(StoreDetailsContext);
  const { data: storeSettings, isFetching: storeSettingsLoading } =
    useStoreSettings(storeId);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.CheckoutAgreements
  );
  const {
    modalOpen: reportModalOpen,
    openModal: setReportModalOpen,
    closeModal: setReportModalClosed,
  } = useModalActionsWithTracking(ModalNames.CheckoutAgreementReportModal);
  const [reportAgreementId, setReportAgreementId] = useState<null | number>(
    null
  );
  const openReportModal = (agreementId: number) => () => {
    setReportAgreementId(agreementId);
    setReportModalOpen();
  };
  const closeReportModal = () => {
    setReportModalClosed();
    setReportAgreementId(null);
  };

  const groupedActiveAgreements = useMemo(
    () =>
      Object.entries(
        groupBy(
          storeSettings?.checkout_agreements?.filter(({ active }) => active),
          'name'
        )
      ),
    [storeSettings?.checkout_agreements]
  );

  const hasPermission = useHasPermissions([Permission.EditCheckoutAgreements]);

  return (
    <>
      <EditableCard
        title="Checkout agreements"
        onEdit={() => openModal()}
        disabled={!hasPermission}
      >
        {storeSettingsLoading ? (
          <>
            <AgreementSkeleton />
            <GroupSeparator />
            <AgreementSkeleton />
          </>
        ) : groupedActiveAgreements.length ? (
          groupedActiveAgreements.map(([type, agreements], groupedIndex) => (
            <Fragment key={type}>
              <CardSection
                label={type}
                mb={groupedIndex < groupedActiveAgreements.length - 1 ? 16 : 0}
              >
                {agreements.map((agreement, agreementIndex) => (
                  <Agreement
                    lastItem={agreementIndex === (agreements.length || 1) - 1}
                    key={agreement.id}
                    openReportModal={openReportModal(agreement.id)}
                    {...agreement}
                  />
                ))}
              </CardSection>
              {groupedIndex < groupedActiveAgreements.length - 1 && (
                <GroupSeparator />
              )}
            </Fragment>
          ))
        ) : (
          `${
            !storeSettings?.checkout_agreements?.length
              ? 'You have not created any checkout agreements.'
              : 'You do not have any active checkout agreements.'
          } Click the edit icon to set this up.`
        )}
      </EditableCard>

      <CheckoutAgreementsModal
        open={modalOpen}
        onClose={() => closeModal()}
        storeName={storeSettings?.store?.name || ''}
        agreements={storeSettings?.checkout_agreements || []}
      />

      <OptInReportModal
        open={reportModalOpen}
        agreementId={reportAgreementId}
        store={storeSettings?.store ?? null}
        onClose={closeReportModal}
      />
    </>
  );
};

import * as t from 'io-ts';

import { Permission } from '@jane/shared/auth';

import { tId } from './common';

const tStaffMemberOptional = t.partial({
  accepted_terms_and_conditions: t.boolean,
  name: t.string,
  email: t.string,
  id: tId,
  pickup: t.boolean,
  delivery: t.boolean,
  registered: t.boolean,
  password: t.boolean,
  _destroy: t.boolean,
});

const permissionEnumAsObject = Object.fromEntries(
  Object.values(Permission).map((key) => [key, key])
);

export const tStaffMemberRequired = t.interface({
  first_name: t.union([t.string, t.null]),
  last_name: t.union([t.string, t.null]),
  phone: t.union([t.string, t.null]),
  role: t.union([
    t.literal('manager'),
    t.literal('staff_member'),
    t.literal('client'),
  ]),
  store_ids: t.array(t.number),
  permissions: t.array(t.keyof(permissionEnumAsObject)),
  pushback_integration: t.boolean,
});

export const tStaffMember = t.intersection([
  tStaffMemberRequired,
  tStaffMemberOptional,
]);

export type StaffMember = t.TypeOf<typeof tStaffMember>;

export interface StaffMemberWithIdAndStoreNames extends StaffMember {
  id: string | number;
  store_names?: string[];
}

import styled from '@emotion/styled';

import { Box } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const StyledSpecialsGrid = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  [mediaQueries.mobile('lg', 'min')]: {
    display: 'grid',
    placeItems: 'stretch',
    gridTemplateColumns: 'repeat(auto-fill,  minmax(250px, 1fr))',
    gridAutoRows: 'min-content',
  },
  [mediaQueries.desktop('sm', 'min')]: {
    display: 'grid',
    placeItems: 'stretch',
    gridTemplateColumns: 'repeat(3,  minmax(0, 1fr))',
    gridAutoRows: 'min-content',
  },
});

import type { ReactNode } from 'react';
import React from 'react';

import type { ThoughtspotLiveboards } from '@jane/business-admin/types';

interface Props {
  authState: 'Success' | 'Failed' | undefined;
  authorizedLiveboards: ThoughtspotLiveboards;
  children: ReactNode;
  isAuthenticating: boolean;
  isFetchingLiveboards: boolean;
  navLiveboards: ThoughtspotLiveboards;
  username: string;
}

export const AnalyticsPageContext = React.createContext<
  Omit<Props, 'children'>
>({
  authState: undefined,
  username: '',
  authorizedLiveboards: {},
  isAuthenticating: true,
  isFetchingLiveboards: true,
  navLiveboards: {},
});

export const AnalyticsPageProvider = ({
  authState,
  children,
  username = '',
  authorizedLiveboards = {},
  navLiveboards = {},
  isFetchingLiveboards = true,
  isAuthenticating = true,
}: Props) => (
  <AnalyticsPageContext.Provider
    value={{
      authState,
      username,
      authorizedLiveboards,
      navLiveboards,
      isFetchingLiveboards,
      isAuthenticating,
    }}
  >
    {children}
  </AnalyticsPageContext.Provider>
);

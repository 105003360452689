import get from 'lodash/get';
import { useCallback, useMemo } from 'react';

import type { SpecialRulesV2 } from '@jane/business-admin/types';

import { convertRulesToString } from '../convertRulesToString';

export const useUpdateIncludedRule = (
  fieldName: string,
  rules: SpecialRulesV2,
  setRules: (value: SpecialRulesV2) => void
) => {
  const dataFromRules = useMemo(() => {
    return get(rules?.includes, `[0].${fieldName}`, []);
  }, [convertRulesToString(rules)]);

  const onUpdate = useCallback(
    (newData: string[]) => {
      // TODO: Also used in ProductConditions, move to reusable thingy
      const firstCondition = (rules['includes'] && rules['includes'][0]) || {};
      setRules({
        ...rules,
        includes: [{ ...firstCondition, [fieldName]: newData }],
      });
    },
    [convertRulesToString(rules)]
  );

  return { data: dataFromRules, onUpdate };
};

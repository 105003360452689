import type { ModalNames } from '@jane/business-admin/util';
import { EventNames, track } from '@jane/business-admin/util';
import type { ConfirmDiscardWrapperProps } from '@jane/shared/components';
import { ConfirmDiscardWrapper } from '@jane/shared/components';

interface ConfirmWrapperWithTrackingProps extends ConfirmDiscardWrapperProps {
  modalName: ModalNames | string;
}

export const ConfirmWrapperWithTracking = ({
  modalName,
  ...props
}: ConfirmWrapperWithTrackingProps) => {
  const trackDiscard = () => {
    track({
      event: EventNames.DiscardChanges,
      modal_name: modalName,
    });
    props.onCloseDiscardChanges && props.onCloseDiscardChanges();
  };

  return (
    <ConfirmDiscardWrapper {...props} onCloseDiscardChanges={trackDiscard} />
  );
};

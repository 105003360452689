import styled from '@emotion/styled';

import { ErrorIcon, Flex, Typography } from '@jane/shared/reefer';

const TitleText = styled(Typography)({
  fontSize: '32px',
});

export const NotFoundPage = () => {
  return (
    <Flex width="432px" mx="auto" justifyContent="center">
      <Flex
        my={128}
        mx={40}
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        width="432px"
      >
        <ErrorIcon size="xxl" mb={24} />
        <Flex flexDirection="column" width="432px">
          <TitleText mb={8} variant="title-bold" textAlign="center">
            Oops. Didn&rsquo;t find what you were looking for.
          </TitleText>
        </Flex>
      </Flex>
    </Flex>
  );
};

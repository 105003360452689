import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useFetchNotifications,
  useUpdateSeenNotifications,
} from '@jane/business-admin/data-access';
import type { ManagerNotification } from '@jane/business-admin/types';
import { NotificationType } from '@jane/business-admin/types';
import {
  EventNames,
  ExternalLinkSourceIds,
  businessPaths,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import {
  BellIcon,
  Button,
  Flex,
  Popover,
  Typography,
} from '@jane/shared/reefer';
import type { PopoverContextProps } from '@jane/shared/reefer';

import { NotificationList } from './NotificationList';

const Dot = styled(Flex)(({ theme }) => ({
  borderRadius: '50%',
}));

export const NotificationsPopover = () => {
  const { id: storeId = '' } = useParams<'id'>();
  const { data: notifications, isFetched } = useFetchNotifications();
  const { pathname } = useLocation();

  const unseenAlertIds = useMemo(
    () =>
      (notifications?.notifications.alert &&
        notifications?.notifications.alert
          .filter((notification: ManagerNotification) => !notification.seen)
          .map((unseen: ManagerNotification) => unseen.id)) ||
      [],
    [notifications?.notifications.alert]
  );

  const unseenUpsellIds = useMemo(
    () =>
      (notifications?.notifications.upsell &&
        notifications?.notifications.upsell
          .filter((notification: ManagerNotification) => !notification.seen)
          .map((unseen: ManagerNotification) => unseen.id)) ||
      [],
    [notifications?.notifications.upsell]
  );

  const { mutateAsync: updateSeenNotifications } =
    useUpdateSeenNotifications(storeId);

  const hasUnseenNotifications = () =>
    unseenAlertIds?.length || unseenUpsellIds?.length;

  const markNotificationsSeen = () => {
    if (hasUnseenNotifications()) {
      const processNotifications = (notificationType: string) => {
        const unseenNotifications = notifications?.notifications[
          notificationType
        ]?.filter((notification: ManagerNotification) => !notification.seen);

        unseenNotifications?.forEach((notification: ManagerNotification) => {
          track({
            critical_state: notification.critical,
            event: EventNames.ViewedNotification,
            notification_header: notification.header,
            notification_subtext: notification.subheader,
            notification_type: notification.notification_type,
            to_url: notification.action_url,
          });
        });
      };

      Object.values(NotificationType).forEach((type) =>
        processNotifications(type)
      );

      updateSeenNotifications({
        notification_ids: [...unseenUpsellIds, ...unseenAlertIds],
      });
    }
  };

  return (
    <Popover
      target={
        <Button.Icon
          icon={
            <>
              <BellIcon />
              {isFetched && hasUnseenNotifications() ? (
                <Dot
                  ariaLabel="unseen notifications icon"
                  alignItems="center"
                  justifyContent="center"
                  background="ember"
                  position="absolute"
                  height={12}
                  width={12}
                  right={8}
                  top={9}
                />
              ) : null}
            </>
          }
          variant="minimal"
        />
      }
      openOn="click"
      alignment={{ horizontal: 'right' }}
      label="Notifications"
      mx={20}
      disableMobileStyling
      onClose={() => markNotificationsSeen()}
    >
      {({ closePopover }: PopoverContextProps) => {
        return (
          <Popover.Content maxHeight="80vh" label="info" padding={false}>
            <Flex
              minWidth={640}
              px={40}
              pt={40}
              pb={20}
              width={'100%'}
              flexDirection={'column'}
            >
              <Typography variant="header-bold">Alerts</Typography>
              {notifications?.notifications.alert ? (
                <NotificationList
                  notifications={notifications?.notifications.alert}
                  listKey={'alert'}
                  type={'alert'}
                  closePopover={closePopover}
                />
              ) : (
                <Typography variant="body" mt={24}>
                  No new messages
                </Typography>
              )}
            </Flex>
            <Popover.Divider />
            <Flex
              minWidth={640}
              px={40}
              pt={20}
              pb={40}
              width={'100%'}
              flexDirection={'column'}
            >
              <Typography variant="header-bold">Grow your business</Typography>
              {notifications?.notifications.upsell && (
                <NotificationList
                  notifications={notifications?.notifications.upsell}
                  listKey={'grow-business'}
                  type={'grow-business'}
                  closePopover={closePopover}
                />
              )}
              <Button
                mt={16}
                label="Explore all solutions"
                variant="secondary"
                to={businessPaths.businessSolutions()}
                onClick={() => {
                  track({
                    event: EventNames.OpenExternalLink,
                    from_url: normalizePath(pathname, storeId),
                    to_url: normalizePath(
                      businessPaths.businessSolutions(),
                      storeId
                    ),
                    trigger_source_id:
                      ExternalLinkSourceIds.NotificationsPopover,
                  });
                }}
              />
            </Flex>
          </Popover.Content>
        );
      }}
    </Popover>
  );
};

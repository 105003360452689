import * as t from 'io-ts';

import { tReservationMode } from './reservationMode';

export const tConsentRequirement = t.union([
  t.literal('required_once'),
  t.literal('required_always'),
  t.literal('optional_until_consented'),
  t.literal('optional_always'),
]);

export const tCheckoutAgreementVersion = t.interface({
  id: t.number,
  reservation_mode: t.union([tReservationMode, t.null]),
  label: t.string,
  consent_default: t.union([t.boolean, t.null]),
  external_link: t.union([t.string, t.null]),
  name: t.union([t.string, t.null]),
  consent_requirement: tConsentRequirement,
});

export type CheckoutAgreementVersion = t.TypeOf<
  typeof tCheckoutAgreementVersion
>;

export type ConsentRequirement = t.TypeOf<typeof tConsentRequirement>;

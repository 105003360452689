import { useMemo } from 'react';

interface IntersectionObserverOptions {
  root?: Element | Document;
  rootMargin?: string;
  threshold?: number | number[];
}

export const useIntersectionObserver = (
  onIntersect: (
    entries: IntersectionObserverEntry[],
    observer?: IntersectionObserver
  ) => void,
  observerOptions: IntersectionObserverOptions = {},
  deps: Array<any> = []
) =>
  useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          onIntersect(entries);
        },
        { ...observerOptions }
      ),
    [...deps]
  );

import { useEffect, useMemo, useState } from 'react';

import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import type {
  CartLimitPolicyV2,
  CartLimitRuleV2,
} from '@jane/business-admin/types';
import { EventNames, ModalNames, track } from '@jane/business-admin/util';
import {
  Button,
  Card,
  EditIcon,
  Flex,
  List,
  TrashIcon,
  Typography,
  useFieldArray,
  useFormContext,
} from '@jane/shared/reefer';

import { RuleSettingsModal } from './rule/RuleSettingsModal';

export const DEFAULT_NEW_RULE: CartLimitRuleV2 = {
  limit_unit: 'g',
  limit_value: '1.0',
  product_group_name: 'New rule',
  product_types: [],
  rule_type: 'product',
};

const Rule = ({
  rule,
  onEdit,
  onRemove,
}: {
  onEdit: () => void;
  onRemove: () => void;
  rule: CartLimitRuleV2;
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column">
        <Typography
          variant="caps-bold"
          color="grays-mid"
          mb={8}
          data-testid="rule-name"
        >
          {rule.product_group_name}
        </Typography>

        <Typography data-testid="rule-description">
          {rule.limit_value}
          {rule.limit_unit} of{' '}
          {rule.product_types?.map((type) => type.product_type).join(', ')}{' '}
          products
        </Typography>
      </Flex>

      <Flex>
        <Button.Icon
          icon={<EditIcon color="grays-mid" />}
          label="Edit"
          onClick={onEdit}
          data-testid="rule-edit"
        />
        <Button.Icon
          icon={<TrashIcon color="grays-mid" />}
          label="Remove"
          onClick={onRemove}
          data-testid="rule-remove"
        />
      </Flex>
    </Flex>
  );
};

export const RuleSection = ({
  parentModalName,
  policyRules,
}: {
  parentModalName: string;
  policyRules?: CartLimitRuleV2[];
}) => {
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.CartLimitRules
  );
  const [editIndex, setEditIndex] = useState<number>();
  const [creatingNewRule, setCreatingNewRule] = useState(false);
  const { control, setValue } = useFormContext<CartLimitPolicyV2>();
  const {
    fields: rules,
    append,
    update,
    remove,
  } = useFieldArray({
    control,
    name: 'cart_limit_rules',
    keyName: '_key',
    rules: {
      required: true,
    },
  });

  useEffect(() => {
    if (policyRules) setValue('cart_limit_rules', policyRules);
  }, [policyRules, setValue]);

  const rulesExist = useMemo(
    () => rules.filter((rule) => !rule._destroy).length > 0,
    [rules]
  );

  const addNewRule = () => {
    append(DEFAULT_NEW_RULE);
    setEditIndex(rules.length);
    setCreatingNewRule(true);
    openModal('Add Cart Limit Rule');
  };

  const removeRule = (index: number, rule: CartLimitRuleV2) => {
    if (!rule?.id) return remove(index);
    update(index, {
      ...rule,
      _destroy: true,
    });
  };

  const editRule = (index: number) => {
    setEditIndex(index);
    setCreatingNewRule(false);
    openModal('Edit Cart Limit Rule');
  };

  const closeRuleSettings = () => {
    closeModal(`${creatingNewRule ? 'Add' : 'Edit'} Cart Limit Rule`);
    setEditIndex(undefined);
  };

  const updateRule = (index: number, params: any) => {
    update(index, params);
  };

  return (
    <>
      <Card.Group border="grays-light" width={896} mb={32}>
        <Card.Content>
          <Flex p={24} flexDirection="column">
            <Typography variant="header-bold">Rules</Typography>
            <Typography color="grays-mid">
              Define amounts for products to apply to the cart. These rules will
              display as a warning during checkout.
            </Typography>
          </Flex>
        </Card.Content>

        {rulesExist ? (
          <Card.Content
            border={rulesExist ? 'grays-light' : 'none'}
            borderWidth="1px 0 0 0"
          >
            <List label="Rule list" px={24} py={8}>
              {rules.map((rule, index) =>
                rule._destroy ? null : (
                  <List.Item key={rule._key} px={0} py={16}>
                    <Rule
                      rule={rule}
                      onEdit={() => editRule(index)}
                      onRemove={() => {
                        track({
                          event: EventNames.DeleteStoreEntity,
                          modal_name: parentModalName,
                          entity_name: 'cart_limit_rules',
                        });
                        removeRule(index, rule);
                      }}
                    />
                  </List.Item>
                )
              )}
            </List>
          </Card.Content>
        ) : null}

        <Card.Content
          border={rulesExist ? 'grays-light' : 'none'}
          borderWidth="1px 0 0 0"
        >
          <Flex justifyContent="center" p={24}>
            <Button
              variant="secondary"
              label={`Add${rulesExist ? ' another' : ''} rule`}
              onClick={addNewRule}
            />
          </Flex>
        </Card.Content>
      </Card.Group>
      <RuleSettingsModal
        open={modalOpen}
        rule={rules[editIndex ?? 0]}
        isNewRule={creatingNewRule}
        onSubmit={(params) => updateRule(editIndex ?? 0, params)}
        onClose={closeRuleSettings}
        onDelete={() => {
          // When creating a new rule, this is triggered by the "X" button, which we don't care to track
          if (!creatingNewRule) {
            track({
              event: EventNames.DeleteStoreEntity,
              modal_name: 'Edit Cart Limit Rule',
              entity_name: 'cart_limit_rules',
            });
          }

          removeRule(editIndex ?? 0, rules[editIndex ?? 0]);
        }}
      />
    </>
  );
};

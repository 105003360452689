import difference from 'lodash/difference';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import type { UpdateCartLimitPolicyParams } from '@jane/business-admin/data-access';
import {
  useCreateCartLimitPolicy,
  useStores,
  useUpdateCartLimitPolicy,
  useUpdateCartLimitPolicyStores,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useDebouncedTrack,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { CartLimitPolicyV2 } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  SearchSubjects,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { StoreSelectModal } from '@jane/shared-b2b/components';
import {
  Button,
  Flex,
  Form,
  FormValidationError,
  Modal,
  useForm,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../../ConfirmWrapperWithTracking';
import { DetailSection } from './DetailSection';
import { RuleSection } from './RuleSection';
import { StoreSection } from './StoreSection';

export interface PolicySettingsModalProps {
  onClose: () => void;
  open: boolean;
  policy?: CartLimitPolicyV2;
}

const FORM_ERROR_NAME = 'policy-settings-error';

export const PolicySettingsModal = ({
  open,
  onClose,
  policy,
}: PolicySettingsModalProps) => {
  const isCreateMode = !policy;
  const modalContent = useRef<HTMLDivElement>(null);

  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error ${
      isCreateMode ? 'creating' : 'updating'
    } cart limit policy. Please try again.`
  );

  const formMethods = useForm();
  const {
    clearErrors,
    formState: { isDirty, dirtyFields },
    setError,
  } = formMethods;

  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.CartLimitStoreSelect
  );
  const { storeId } = useContext(StoreDetailsContext);

  const { mutateAsync: updateCartLimitPolicy, isSuccess: isUpdateSuccess } =
    useUpdateCartLimitPolicy(policy?.id.toString() ?? '', storeId);
  const { mutateAsync: createCartLimitPolicy, isSuccess: isCreateSuccess } =
    useCreateCartLimitPolicy();
  const {
    mutateAsync: updateCartLimitPolicyStores,
    isSuccess: isSetStoresSuccess,
    isError: isSetStoresError,
  } = useUpdateCartLimitPolicyStores();

  const isSuccess = useMemo(
    () =>
      (isCreateMode && isCreateSuccess) || (!isCreateMode && isUpdateSuccess),
    [isUpdateSuccess, isCreateSuccess, isCreateMode]
  );

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSetStoresSuccess || isSetStoresError) {
      closeModal();
      modalContent.current?.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  }, [isSetStoresSuccess, isSetStoresError]);

  const onSubmit = useCallback(
    (formData: UpdateCartLimitPolicyParams) => {
      let submitMethod;
      if (isCreateMode) {
        submitMethod = () => {
          track({
            event: EventNames.EditedStoreSettings,
            modal_name: ModalNames.CreateCartLimitPolicy,
            changed_attributes: Object.keys(dirtyFields),
          });
          return createCartLimitPolicy(formData);
        };
      } else {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.EditCartLimitPolicy,
          changed_attributes: Object.keys(dirtyFields),
        });
        submitMethod = () => updateCartLimitPolicy(formData);
      }
      return catchSubmitErrors({
        submitMethod,
        requestData: formData,
        onValidationError: (validationErrors: Record<string, unknown>) => {
          throw new FormValidationError(
            FORM_ERROR_NAME,
            parseValidationErrors(validationErrors)
          );
        },
      });
    },
    [dirtyFields]
  );

  const selectedStoreIds = useMemo(() => {
    if (!policy) return [];
    return policy.stores.map((store) => store.id.toString());
  }, [policy]);

  const onUpdateStoresSubmit = async (
    updatedStoreIds: number[],
    selectionsChanged?: boolean
  ) => {
    const disabledStores = difference(
      selectedStoreIds.map((id) => parseInt(id, 10)),
      updatedStoreIds
    ).map((id) => ({ id, selected: false }));
    const enabledStores = updatedStoreIds.map((id) => ({
      id,
      selected: true,
    }));
    const params = [...enabledStores, ...disabledStores];

    clearErrors(FORM_ERROR_NAME);

    track({
      event: EventNames.EditedStoreSettings,
      modal_name: ModalNames.CartLimitStoreSelect,
      changed_attributes: selectionsChanged ? ['store_selection'] : [],
    });

    try {
      await updateCartLimitPolicyStores({
        policyId: policy?.id ?? '',
        stores: params,
      });
    } catch (error) {
      setError(FORM_ERROR_NAME, {
        type: 'onSubmit',
        message: 'Error updating cart limit policy stores. Please try again.',
      });
    }
  };

  const openSaveToModal = () => openModal();

  const { data: storesData, isFetching: isFetchingStores } = useStores();
  const trackSearch = useDebouncedTrack(1000);

  return (
    <>
      <ConfirmWrapperWithTracking
        open={open}
        setOpen={onClose}
        hasChanges={isDirty}
        variant="full-screen"
        background="grays-ultralight"
        appId="cart-limits-list"
        parentId="policy-settings"
        modalName={`${isCreateMode ? 'Create' : 'Edit'} Cart Limit Policy`}
      >
        <Form.BaseForm
          name="policy settings"
          formMethods={formMethods}
          onSubmit={onSubmit}
          formErrorName={FORM_ERROR_NAME}
        >
          <Modal.Header
            title={`${isCreateMode ? 'Create' : 'Edit'} cart limit policy`}
            actions={
              <>
                <Button
                  label="Save to"
                  variant="secondary"
                  onClick={() => openModal()}
                  mr={16}
                  data-testid="policy-settings-save-to"
                  disabled={isCreateMode}
                />

                <Form.SubmitButton
                  label="Save"
                  variant="primary"
                  data-testid="policy-settings-save"
                />
              </>
            }
          />
          <Modal.Content ref={modalContent}>
            <Form.ErrorBanner name={FORM_ERROR_NAME} />
            <Flex alignItems="center" flexDirection="column">
              <DetailSection policyName={policy?.name} />

              <RuleSection
                policyRules={policy?.cart_limit_rules}
                parentModalName={`${
                  isCreateMode ? 'Create' : 'Edit'
                } Cart Limit Policy`}
              />

              {!isCreateMode && (
                <StoreSection
                  policy={policy}
                  openSaveToModal={openSaveToModal}
                />
              )}
            </Flex>
          </Modal.Content>
        </Form.BaseForm>
      </ConfirmWrapperWithTracking>

      {modalOpen && (
        <StoreSelectModal
          onSearchCallback={(query: string, successful?: boolean) =>
            trackSearch({
              event: EventNames.Search,
              arguments: query,
              subject: SearchSubjects.Stores,
              successful,
            })
          }
          storesData={storesData || []}
          isFetchingStores={isFetchingStores}
          onSubmit={onUpdateStoresSubmit}
          subtitle={policy?.name}
          selectedStoreIds={selectedStoreIds}
          closeModal={closeModal}
          extraColumn={{
            label: 'current cart limit',
            render: (store) => store?.cart_limit_policy?.name ?? null,
          }}
        />
      )}
    </>
  );
};

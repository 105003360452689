import { Form, Modal, Typography } from '@jane/shared/reefer';

export const GeneralSettingsSection = ({
  variant,
}: {
  variant: 'subdomain' | 'wordpress';
}) => {
  return (
    <>
      <Typography variant="body-bold">General Settings</Typography>
      <Typography color="grays-mid" mb={24}>
        These fields can be used to set customized content.
      </Typography>
      <Form.SwitchField label="Show Age Gate" name="show_age_gate" mb={24} />
      {variant === 'wordpress' && <WordpressSpecificFields />}
      {variant === 'subdomain' && <SubdomainSpecificFields />}
      <Form.TextField
        label="Favicon URL"
        name="favicon_url"
        width="100%"
        helperText="Images should be in PNG format, sized to 32x32."
      />
      <Modal.ContentDivider />
    </>
  );
};

const WordpressSpecificFields = () => {
  return (
    <Form.TextField
      label="URL"
      name="partner_menu_url"
      width="100%"
      mb={24}
      helperText="The URL where your menu will be hosted, e.g: https://littlegreenpotshop.com/store"
      required
    />
  );
};

const SubdomainSpecificFields = () => {
  return (
    <>
      <Form.TextField
        label="Host"
        name="host"
        width="100%"
        mb={24}
        startIcon="https://"
        helperText="e.g: menu.littlegreenpotshop.com"
        required
      />
      <Form.TextField
        label="Path"
        name="path"
        width="100%"
        startIcon="/"
        mb={24}
        helperText="e.g: santa-cruz"
      />
    </>
  );
};

import {
  type QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import * as t from 'io-ts';

import {
  tEffects,
  tFlavors,
  zProductDetailResponse,
  zProductIndexResponse,
} from '@jane/business-admin/types';
import type {
  AbbreviatedMenuRow,
  Effects,
  Flavors,
  GlobalSpecialProduct,
  MenuProductForProductsTable,
  ProductIndexResponse,
} from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import { tProductKind } from '@jane/shared/models';
import type { Id, ProductKind } from '@jane/shared/models';
import type { Photo } from '@jane/shared/types';

import { STORES_URL } from './stores';

export const PRODUCTS_URL = '/business/products';

export const invalidateAllProductQueries = (
  queryClient: QueryClient,
  storeId: Id
) => {
  queryClient.invalidateQueries(['searchStoreProducts', storeId]);
  queryClient.invalidateQueries(['storeProducts', storeId]);
  queryClient.invalidateQueries(['storeUnpublishedProducts', storeId]);
};

const fetchMenuRows = async (
  storeId: string,
  customDisplayName?: string
): Promise<AbbreviatedMenuRow[]> => {
  const urlParams = new URLSearchParams();
  customDisplayName &&
    urlParams.append('custom_display_name', customDisplayName);

  const url = `${STORES_URL}/${storeId}/menu_rows?${urlParams.toString()}`;
  const tPayload = t.type({
    custom_rows: t.any,
  });

  const data = await fetchWithDecode(
    janeApiV2.get<{ custom_rows: AbbreviatedMenuRow[] }>(url),
    tPayload,
    url
  );
  return data.custom_rows;
};
export const useFetchMenuRows = (
  storeId: string,
  customDisplayName?: string
) => {
  return useQuery<AbbreviatedMenuRow[]>({
    queryFn: () => fetchMenuRows(storeId, customDisplayName),
    queryKey: ['stores', storeId, customDisplayName],
    useErrorBoundary: true,
  });
};

const fetchProductFlavors = async (): Promise<any> => {
  const url = `${PRODUCTS_URL}/flavors`;

  const data = await fetchWithDecode(
    janeApiV2.get<Flavors>(url),
    tFlavors,
    url
  );
  return data;
};

export const useFetchProductFlavors = () => {
  return useQuery<Flavors>({
    queryFn: () => fetchProductFlavors(),
    queryKey: ['products', 'flavors'],
    useErrorBoundary: true,
  });
};

const fetchProductEffects = async (): Promise<any> => {
  const url = `${PRODUCTS_URL}/effects`;

  const data = await fetchWithDecode(
    janeApiV2.get<Effects>(url),
    tEffects,
    url
  );
  return data;
};
export const useFetchProductEffects = () => {
  return useQuery<Effects>({
    queryFn: () => fetchProductEffects(),
    queryKey: ['products', 'effects'],
    useErrorBoundary: true,
  });
};

export const fetchProductDetails = async (
  storeId: string,
  menuProductId?: string,
  productId?: string
) => {
  const pathEnd = menuProductId ? menuProductId : 'show';
  const queryParams = productId ? `?product_id=${productId}` : '';
  const url = `${STORES_URL}/${storeId}/products/${pathEnd}${queryParams}`;
  const response = await janeApiV2.get<{
    product: MenuProductForProductsTable;
  }>(url);

  return await zProductDetailResponse.parseAsync(response);
};

export const useFetchProductDetails = (
  storeId: string,
  { menuProductId, productId }: { menuProductId?: string; productId?: string }
) => {
  return useQuery({
    queryKey: ['storeProducts', storeId, menuProductId, productId],
    queryFn: () => fetchProductDetails(storeId, menuProductId, productId),
  });
};

/**
 * Used to fetch details for multiple product_ids at once.
 * @param storeId Store id
 * @param product_ids List of product_ids, one fetch will happen per product_id
 * @returns List of queries with data containing the product details
 */
export const useFetchMultipleProductDetails = (
  storeId: string,
  ids: number[],
  useMenuProductId = false,
  enabled = true
) => {
  return useQueries({
    queries: !enabled
      ? []
      : ids.map((id) => {
          return {
            queryKey: ['storeProducts', storeId, id, useMenuProductId],
            queryFn: () =>
              fetchProductDetails(
                storeId,
                useMenuProductId ? id?.toString() : undefined,
                useMenuProductId ? undefined : id?.toString()
              ),
          };
        }),
  });
};

export type fetchProductsProps = {
  brand?: string;
  category?: string;
  content?: string;
  custom_rows?: string;
  include_out_of_stock?: boolean;
  lineage?: string;
  order?: string;
  page?: number;
  per_page?: number;
  query?: string;
  sort?: string;
  status?: string;
  subcategory?: string;
  weight?: string;
};

export const fetchProducts = async (
  storeId: string,
  params: fetchProductsProps
): Promise<ProductIndexResponse> => {
  const urlParams = new URLSearchParams();
  params.sort && urlParams.append('sort', params.sort);
  params.order && urlParams.append('order', params.order);
  params.brand && urlParams.append('brand', params.brand);
  params.content && urlParams.append('content', params.content);
  params.query && urlParams.append('query', params.query);
  params.custom_rows && urlParams.append('custom_rows', params.custom_rows);
  params.category && urlParams.append('category', params.category);
  params.lineage && urlParams.append('lineage', params.lineage);
  params.status && urlParams.append('status', params.status);
  params.include_out_of_stock &&
    urlParams.append(
      'include_out_of_stock',
      params.include_out_of_stock.toString()
    );
  params.page && urlParams.append('page', params.page.toString());
  params.subcategory && urlParams.append('subcategory', params.subcategory);
  params.weight && urlParams.append('weight', params.weight);
  params.per_page && urlParams.append('per_page', params.per_page.toString());

  const url = `${STORES_URL}/${storeId}/products?${urlParams.toString()}`;
  const response = await janeApiV2.get<ProductIndexResponse>(url);

  return await zProductIndexResponse.parseAsync(response);
};

export const fetchProductQueryKey = (
  storeId: string,
  params: fetchProductsProps
) => {
  return ['storeProducts', storeId, params];
};

export const useFetchProducts = (
  storeId: string,
  params: fetchProductsProps,
  disabled?: boolean
) => {
  return useInfiniteQuery<ProductIndexResponse>({
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchProducts(storeId, {
        sort: params.sort,
        order: params.order,
        brand: params.brand,
        content: params.content,
        query: params.query,
        custom_rows: params.custom_rows,
        category: params.category,
        lineage: params.lineage,
        status: params.status,
        include_out_of_stock: params.include_out_of_stock,
        subcategory: params.subcategory,
        weight: params.weight,
        per_page: params.per_page,
        page: pageParam,
      });
      return {
        ...data,
        pageParam,
      };
    },
    enabled: !disabled,
    queryKey: fetchProductQueryKey(storeId, params),
    getNextPageParam: (lastPage) => {
      const hasNextPage = lastPage?.meta.page < lastPage.meta.number_of_pages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    staleTime: Infinity,
    useErrorBoundary: true,
  });
};

const updateProductVisibility = async (
  data: { visible: boolean },
  storeId: string,
  productId: string
): Promise<null> => {
  return await janeApiV2.put<null>(
    `${STORES_URL}/${storeId}/products/${productId}`,
    {
      attributes: data,
    }
  );
};

export const useUpdateProductVisibility = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      data,
      productId,
    }: {
      data: { visible: boolean };
      productId: string;
    }) => updateProductVisibility(data, storeId, productId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      invalidateAllProductQueries(queryClient, storeId);
    },
  });
};

const updateBulkProductVisibility = async (
  data: { visible: boolean },
  storeId: string,
  productIds: string[]
): Promise<null> => {
  return await janeApiV2.put<null>(
    `${STORES_URL}/${storeId}/products/bulk_update`,
    {
      attributes: data,
      product_ids: productIds,
    }
  );
};

export const useUpdateBulkProductVisibility = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      data,
      productIds,
    }: {
      data: { visible: boolean };
      productIds: string[];
    }) => updateBulkProductVisibility(data, storeId, productIds),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      invalidateAllProductQueries(queryClient, storeId);
    },
  });
};

interface AutoPublishSettingsPayload {
  product_types: ProductKind[];
}

const fetchAutoPublishSettings = async (
  storeId: string
): Promise<ProductKind[]> => {
  const url = `${STORES_URL}/${storeId}/products/auto_publish`;

  const data = await fetchWithDecode(
    janeApiV2.get<AutoPublishSettingsPayload>(url),
    t.interface({ product_types: t.array(tProductKind) }),
    url
  );

  return data.product_types;
};

export const useAutoPublishSettings = (storeId: string) =>
  useQuery<ProductKind[]>({
    queryFn: () => fetchAutoPublishSettings(storeId),
    queryKey: ['stores', storeId, 'auto_publish'],
    useErrorBoundary: true,
    staleTime: Infinity,
  });

const updateAutoPublishSettings = async (
  storeId: string,
  data: AutoPublishSettingsPayload
): Promise<null> =>
  janeApiV2.put<null>(`${STORES_URL}/${storeId}/products/auto_publish`, data);

export const useUpdateAutoPublishSettings = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: AutoPublishSettingsPayload) =>
      updateAutoPublishSettings(storeId, data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId, 'auto_publish']);
    },
  });
};

export interface UnavailablePrice {
  _destroy?: boolean;
  id?: number;
  price_id: string;
}

export interface FlavorEffectKinds {
  _destroy?: boolean;
  id?: number;
  kind: string;
}

export type CustomRowUpdateData = {
  enabled: boolean;
  store_custom_menu_row_setting_id: number;
}[];
export type UpdateMenuProductParams = {
  attributes: Omit<MenuProductForProductsTable, 'custom_rows' | 'lineage'> & {
    custom_rows: CustomRowUpdateData;
    lineage: '' | 'cbd' | 'hybrid' | 'indica' | 'sativa' | 'none' | null;
  };
  deleted_photos: Photo[];
  photos: Photo[];
  photos_order: { [id: number]: string };
};

const updateMenuProduct = (
  storeId: Id,
  id: Id,
  menuProduct: UpdateMenuProductParams
): Promise<null> =>
  janeApiV2.patch(`${STORES_URL}/${storeId}/products/${id}`, {
    ...menuProduct,
  });
export const useUpdateMenuProduct = (storeId: Id, id: Id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateMenuProductParams) =>
      updateMenuProduct(storeId, id, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      invalidateAllProductQueries(queryClient, storeId);
    },
  });
};

export const fetchGlobalProductDetails = async (menuProductId?: string) => {
  const url = `/business/specials/products/${menuProductId}`;
  const response = await janeApiV2.get<{ data: GlobalSpecialProduct }>(url);

  return response;
};

export const useFetchMultipleGlobalProductDetails = (
  ids: number[],
  enabled: boolean
) => {
  return useQueries({
    queries: !enabled
      ? []
      : ids.map((id) => {
          return {
            queryKey: ['globalProducts', id],
            queryFn: () => fetchGlobalProductDetails(id.toString()),
          };
        }),
  });
};

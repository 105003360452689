import { useParams } from 'react-router-dom';

import {
  CartLimitsCard,
  CheckoutAgreementsCard,
  CheckoutOptionsCard,
  JanePayUpsellCard,
  PaymentCard,
  TaxesAndFeesCard,
} from '@jane/business-admin/components';
import { useFetchNotifications } from '@jane/business-admin/data-access';
import { useShouldShowJanePayUpsell } from '@jane/business-admin/hooks';
import type { ManagerNotification } from '@jane/business-admin/types';
import { Flex } from '@jane/shared/reefer';

export const StoreSettingsCheckout = () => {
  const { id: storeId = '' } = useParams<'id'>();
  const { data: notificationsPayload, isFetched: notificationsFetched } =
    useFetchNotifications();

  const janePayUpsellNotification =
    notificationsFetched &&
    notificationsPayload.notifications.card &&
    notificationsPayload.notifications.card.find(
      (notification: ManagerNotification) =>
        notification.header?.toLowerCase() === 'janepay'
    );

  const showJanePayUpsellCard = useShouldShowJanePayUpsell(
    janePayUpsellNotification,
    storeId
  );

  return (
    <Flex flexDirection="column">
      {showJanePayUpsellCard ? (
        <JanePayUpsellCard notification={janePayUpsellNotification} />
      ) : null}
      <CheckoutOptionsCard />
      <CartLimitsCard />
      <CheckoutAgreementsCard />
      <PaymentCard />
      <TaxesAndFeesCard />
    </Flex>
  );
};

import { useEffect, useMemo, useState } from 'react';

import {
  useCartLimitPolicies,
  useUpdateCartLimitPolicyStores,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import type { CartLimitPolicyV2, StoreV2 } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Button,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useFormContext,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import { TableCell } from '../../../../../TableCell';
import {
  CollapsedBorderTable,
  TableRowWithBorder,
} from '../../../../../TableWithBorderSeparator';
import { PolicyRow } from './PolicyRow';
import { PolicySettingsModal } from './settings/PolicySettingsModal';

const PolicyOptions = ({
  policies,
  defaultChecked,
}: {
  defaultChecked?: string;
  policies: CartLimitPolicyV2[];
}) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (defaultChecked) setValue('selected_policy', defaultChecked?.toString());
  }, [defaultChecked]);

  return (
    <Form.RadioFieldGroup
      defaultChecked={defaultChecked?.toString()}
      name="selected_policy"
      options={policies.map((policy) => ({
        label: <Typography variant="body-bold">{policy.name}</Typography>,
        id: policy.id.toString(),
        value: policy.id.toString(),
        wrapper: (children) => (
          <PolicyRow policy={policy}>{children}</PolicyRow>
        ),
      }))}
    />
  );
};

interface CartLimitsModalProps {
  onClose: () => void;
  open: boolean;
  selectedId?: string | number;
  store?: StoreV2;
}

const FORM_ERROR_NAME = 'cart-limits-error';

export function CartLimitsModal({
  store,
  selectedId,
  open,
  onClose,
}: CartLimitsModalProps) {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating cart limits. Please try again.'
  );
  const toast = useToast();
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.CreateCartLimitPolicy
  );
  const { data: policies } = useCartLimitPolicies();
  const { mutateAsync: updateStoreSelections, isSuccess: policySaved } =
    useUpdateCartLimitPolicyStores();

  const onSubmit = (formData: { selected_policy: string }) => {
    if (!store) return;

    const requestData = {
      policyId: formData.selected_policy,
      stores: [{ id: store.id, selected: true }],
    };
    const submitMethod = () => {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.CartLimits,
        changed_attributes: ['selected_policy'],
      });
      return updateStoreSelections(requestData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors['stores'])
        );
      },
    });
  };

  useEffect(() => {
    if (policySaved) {
      toast.add({
        label: 'Cart limits updated',
        variant: 'success',
      });
      onClose();
    }
  }, [policySaved]);

  const orderedPolicies = useMemo(
    () =>
      policies?.sort((a, b) => {
        const selectedIdNum = selectedId as number;
        return a.id === selectedIdNum ? -1 : b.id === selectedIdNum ? 1 : 0;
      }),
    [policies, selectedId]
  );

  return (
    <>
      <ConfirmWrapperWithTracking
        open={open}
        setOpen={onClose}
        hasChanges={formIsDirty}
        parentId="cart-limits-list"
        modalName={ModalNames.CartLimits}
      >
        <Form
          name="cart limits"
          onSubmit={onSubmit}
          onDirty={setFormIsDirty}
          formErrorName={FORM_ERROR_NAME}
        >
          <Modal.Header
            title="Cart limits"
            subtitle={store?.name}
            actions={
              <>
                <Button
                  label="Add cart limit policy"
                  variant="tertiary-selected"
                  onClick={() => openModal()}
                  data-testid="add-cart-limit-button"
                  mr={16}
                />
                <Form.SubmitButton label="Save" />
              </>
            }
          />
          <Modal.Content>
            <Form.ErrorBanner name={FORM_ERROR_NAME} />
            <CollapsedBorderTable aria-label="Checkout Agreements">
              <thead>
                <TableRowWithBorder hasBorder>
                  <TableCell>
                    <Typography variant="caps" color="grays-mid">
                      Policy
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caps" color="grays-mid">
                      Limits
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caps" color="grays-mid">
                      Locations
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRowWithBorder>
              </thead>

              <tbody>
                {orderedPolicies && orderedPolicies.length > 0 && (
                  <PolicyOptions
                    policies={orderedPolicies}
                    defaultChecked={selectedId?.toString()}
                  />
                )}
              </tbody>
            </CollapsedBorderTable>
          </Modal.Content>
        </Form>
      </ConfirmWrapperWithTracking>
      {modalOpen && (
        <PolicySettingsModal open={modalOpen} onClose={() => closeModal()} />
      )}
    </>
  );
}

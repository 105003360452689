import type { ReactNode } from 'react';

import {
  useManager,
  useThoughtspotLiveboards,
} from '@jane/business-admin/data-access';
import type { ThoughtspotBucketNames } from '@jane/business-admin/types';
import { Loading } from '@jane/shared/reefer';

import { UnauthorizedAccess } from '../UnauthorizedAccess';
import { AnalyticsUpsellScreen } from './AnalyticsUpsellScreen';

export const RequireThoughtspotUserBucket = ({
  bucketName,
  unauthorizedResourceName = 'analytics',
  upsellScreenTitle,
  upsellScreenDescription,
  internalOnly = false,
  children,
}: {
  bucketName: ThoughtspotBucketNames;
  children: ReactNode;
  internalOnly?: boolean;
  unauthorizedResourceName?: string;
  upsellScreenDescription?: string;
  upsellScreenTitle?: string;
}) => {
  const { data: currentManager } = useManager();
  const { data: liveboardsData, isFetched: liveboardsFetched } =
    useThoughtspotLiveboards({ enabled: !!currentManager?.manager?.id });

  if (!liveboardsFetched) return <Loading size="lg" color="black" />;

  if (internalOnly && currentManager?.manager?.internal !== true) {
    return (
      <UnauthorizedAccess
        resourceName={unauthorizedResourceName}
        variant="view"
      />
    );
  }

  if (!liveboardsData?.thoughtspot_user_buckets.includes(bucketName)) {
    if (upsellScreenTitle && upsellScreenDescription) {
      return (
        <AnalyticsUpsellScreen
          title={upsellScreenTitle}
          description={upsellScreenDescription}
        />
      );
    }

    return (
      <UnauthorizedAccess
        resourceName={unauthorizedResourceName}
        variant="view"
      />
    );
  }

  return <>{children}</>;
};

import { useMemo } from 'react';

import { useManager } from '@jane/business-admin/data-access';

export const useAuthenticated = () => {
  const { data: currentManager } = useManager();

  const isLoggedIn = useMemo(() => {
    if (currentManager?.manager?.id) return true;
    return false;
  }, [currentManager]);

  return isLoggedIn;
};

import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Flex, Link, Typography } from '@jane/shared/reefer';

type DefaultValue = string | { display: string; value: string };

export const RevertableInputWrapper = ({
  defaultValue,
  initialValue,
  name,
  fromPOS,
  children,
}: {
  children: React.ReactNode;
  defaultValue: DefaultValue;
  fromPOS?: boolean;
  initialValue?: string;
  name: string;
}) => {
  const { setValue } = useFormContext();
  // Can't explain it but if you just use "watch" here,
  // you'll get an undefined value initially no matter how you set this field
  const value = useWatch({ name, defaultValue: initialValue });

  const defaultDisplayValue =
    typeof defaultValue === 'object' ? defaultValue?.display : defaultValue;

  const defaultRevertValue =
    typeof defaultValue === 'object' ? defaultValue?.value : defaultValue;

  const isDefaultValue = useMemo(
    () => value && value === defaultRevertValue,
    [value, defaultRevertValue]
  );

  const revert = () => {
    setValue(name, defaultRevertValue, { shouldDirty: true });
  };

  return (
    <div style={{ width: '100%' }}>
      {children}
      {defaultValue && !isDefaultValue ? (
        <Flex justifyContent="space-between" data-testid={`revertable-${name}`}>
          <Typography variant="body" color="grays-mid" mt={8} mr={12}>
            {fromPOS ? 'POS d' : 'D'}efault: {defaultDisplayValue}
          </Typography>
          <Link mt={8} onClick={revert}>
            Revert
          </Link>
        </Flex>
      ) : null}
    </div>
  );
};

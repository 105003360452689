import capitalize from 'lodash/capitalize';
import { useContext, useMemo, useState } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { ModalNames } from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { StatusMessageV2 } from '@jane/shared/models';
import { Flex } from '@jane/shared/reefer';

import { EditableCard } from '../../../../EditableCard';
import { DismissalMessageTable } from './DismissalMessageTable';
import { StatusMessageTable } from './StatusMessageTable';
import { ChatSettingsCard } from './settings/ChatSettingsCard';
import { DismissalMessageSettings } from './settings/DismissalMessageSettings';
import { EmailNotificationSettingsCard } from './settings/EmailNotificationSettingsCard';
import { NotificationSettingsModal } from './settings/NotificationSettingsModal';
import { StatusMessageSettings } from './settings/StatusMessageSettings';

type SelectedMessageType = 'pickupDelivery' | 'kiosk' | null;

export const NotificationsCard = () => {
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.Notifications
  );
  const [editingDismissalMessages, setEditingDismissalMessages] =
    useState(false);
  const [selectedStatusMessageType, setSelectedStatusMessageType] =
    useState<SelectedMessageType>(null);
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const {
    data: storePayload,
    isFetched,
    isFetching,
  } = useStoreSettings(storeId);
  const modalTitle = useMemo(() => {
    if (editingDismissalMessages) return ModalNames.AdditionalDismissalMessages;
    if (selectedStatusMessageType) {
      return selectedStatusMessageType === 'kiosk'
        ? ModalNames.KioskNotifications
        : ModalNames.PickupAndDeliveryNotifications;
    }

    return 'Notifications';
  }, [editingDismissalMessages, selectedStatusMessageType]);
  const dismissalMessages = storePayload?.store_dismissal_messages ?? [];
  const showDismissalTextButton = dismissalMessages.length < 1;
  const statusMessages = useMemo(
    () =>
      (storePayload?.status_messages ?? []).reduce(
        (result, msg) => {
          if (msg.kiosk) {
            result.kiosk.push(msg);
          } else {
            result.pickupDelivery.push(msg);
          }

          return result;
        },
        { kiosk: [], pickupDelivery: [] } as {
          kiosk: StatusMessageV2[];
          pickupDelivery: StatusMessageV2[];
        }
      ),
    [storePayload?.status_messages]
  );

  const hasCardSettingAccess = useFlag(FLAGS.scNotificationSettingsCardAccess);

  const editMessageType = (type: SelectedMessageType) => () => {
    setSelectedStatusMessageType(type);
    openModal(
      type === 'kiosk'
        ? ModalNames.KioskNotifications
        : ModalNames.PickupAndDeliveryNotifications
    );
  };

  const editDismissalMessages = () => {
    stopEditing();
    setEditingDismissalMessages(true);
    openModal(ModalNames.AdditionalDismissalMessages);
  };

  const stopEditing = () => {
    setEditingDismissalMessages(false);
    setSelectedStatusMessageType(null);
    closeModal(modalTitle);
  };

  const modalStatusMessages = useMemo(() => {
    if (!selectedStatusMessageType) return [];
    return statusMessages[selectedStatusMessageType];
  }, [selectedStatusMessageType, statusMessages]);

  return (
    <>
      {hasCardSettingAccess && (
        <EditableCard
          showTextButton={!statusMessages.pickupDelivery.length}
          textButtonLabel="Add notifications"
          title={capitalize(ModalNames.PickupAndDeliveryNotifications)}
          onEdit={editMessageType('pickupDelivery')}
          disabled={!canEditStore}
        >
          <Flex pt={24}>
            <StatusMessageTable
              statusMessages={statusMessages.pickupDelivery}
              isLoading={isFetching}
              isFetched={isFetched}
            />
          </Flex>
        </EditableCard>
      )}
      {hasCardSettingAccess && !!statusMessages.kiosk.length && (
        <EditableCard
          showTextButton={!statusMessages.kiosk.length}
          textButtonLabel="Add notifications"
          title={capitalize(ModalNames.KioskNotifications)}
          onEdit={editMessageType('kiosk')}
          disabled={!canEditStore}
        >
          <Flex pt={24}>
            <StatusMessageTable
              statusMessages={statusMessages.kiosk}
              emptyText="Kiosk notifications have not been set up for this store."
              isLoading={isFetching}
              isFetched={isFetched}
            />
          </Flex>
        </EditableCard>
      )}
      {hasCardSettingAccess && (
        <EditableCard
          showTextButton={showDismissalTextButton}
          textButtonLabel="Add dismissal notifications"
          title="Dismissal notifications"
          onEdit={editDismissalMessages}
          disabled={!canEditStore}
        >
          <Flex pt={24}>
            <DismissalMessageTable
              dismissalMessages={dismissalMessages}
              isLoading={isFetching}
              isFetched={isFetched}
            />
          </Flex>
        </EditableCard>
      )}
      <EmailNotificationSettingsCard
        sendEmailUpdates={
          !!storePayload?.store?.send_cart_status_updates_via_email
        }
        isFetched={isFetched}
      />
      <ChatSettingsCard
        chatSetting={storePayload?.store_chat_setting}
        isFetched={isFetched}
      />
      <NotificationSettingsModal
        title={modalTitle}
        open={modalOpen}
        onClose={stopEditing}
        subtitle={storePayload?.store?.name ?? ''}
      >
        {editingDismissalMessages ? (
          <DismissalMessageSettings messages={dismissalMessages} />
        ) : (
          <StatusMessageSettings messages={modalStatusMessages} />
        )}
      </NotificationSettingsModal>
    </>
  );
};

import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { IdentifyManager } from '@jane/business-admin/components';
import { JaneApplicationWrapper } from '@jane/shared/components';
import { config } from '@jane/shared/config';
import { asyncFlagProvider } from '@jane/shared/feature-flags';
import type { CustomThemeConfig } from '@jane/shared/reefer';
import { Loading, ToastProvider } from '@jane/shared/reefer';
import { initDatadogRum, initMixpanel, initTrackJS } from '@jane/shared/util';

import App from './app/App';
// Styles for use in the Preview modal, so the Comms Banner styles match marketplace -->
import './base.css';

const appName = config.trackJsApplication
  ? config.trackJsApplication.replace('jane', 'business-admin')
  : undefined;
initTrackJS(appName);

initMixpanel(process.env.NX_BUSINESS_MIXPANEL_CUSTOMER_TOKEN, {
  useLocalStorage: true,
});

initDatadogRum({
  applicationId: process.env.NX_BUSINESS_DATADOG_APP_ID || '',
  clientToken: process.env.NX_BUSINESS_DATADOG_CLIENT_TOKEN || '',
  service: 'business-admin',
  sessionSampleRate: 100,
  allowedTracingUrls: [
    (url) => url.startsWith(process.env.NX_BUSINESS_V2_URL || ''),
  ],
  traceSampleRate: 50,
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const BUSINESS_THEME: CustomThemeConfig = {
  borderRadius: {
    sm: '12px',
  },
  components: {
    Button: {
      shared: {
        borderRadius: 'sm',
      },
      variants: {
        tertiary: {
          backgroundColor: 'grays-ultralight',
          borderColor: 'grays-ultralight',
          typographyColor: 'grays-black',
          typographyVariant: 'body-bold',
        },
      },
    },
    Card: {
      styles: {
        borderRadius: 'lg',
      },
    },
  },
};

(async () => {
  /**
   * Use async flag provider so that the flags load before our app,
   * preventing any flicker.
   *
   * TODO (kyrsten):
   * Extract this behavior and apply across all the apps.
   */
  const FlagProvider = await asyncFlagProvider(
    config.businessLaunchDarklyClientID
  );

  root.render(
    <FlagProvider>
      <JaneApplicationWrapper theme={BUSINESS_THEME}>
        <Suspense fallback={<Loading />}>
          <ToastProvider verticalOffset={96}>
            <IdentifyManager />
            <App />
          </ToastProvider>
        </Suspense>
      </JaneApplicationWrapper>
    </FlagProvider>
  );
})();

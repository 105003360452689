import { Flex, Skeleton } from '@jane/shared/reefer';

export const LoadingCondition = () => {
  return (
    <Flex justifyContent="space-between" alignItems="center" mb={24}>
      <Skeleton animate width="25%">
        <Skeleton.Bone height={32} />
      </Skeleton>
      <Skeleton animate width="130px">
        <Skeleton.Bone borderRadius="sm" height={48} />
      </Skeleton>
    </Flex>
  );
};

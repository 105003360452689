import type { Manager } from '@jane/business-admin/types';

export const TEMPORARY_USER_ID = 'GUEST_MANAGER';

export const getManagerFlagContext = (manager?: Manager, storeId?: number) => {
  const flagManager = { key: TEMPORARY_USER_ID, anonymous: true };

  if (manager?.id) {
    return {
      anonymous: false,
      key: manager?.id.toString(),
      kind: 'user',
      firstName: manager?.first_name,
      lastName: manager?.last_name,
      email: manager?.email,
      role: manager?.role,
      permissions: manager?.permissions,
      stores: manager?.internal ? [] : manager?.stores,
      internal: manager?.internal ? 'internal' : 'external',
      storeId,
    };
  }
  return flagManager;
};

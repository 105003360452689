import { useEffect } from 'react';

import { useToast } from '@jane/shared/reefer';

interface Props {
  errorMessage: string;
  isError: boolean;
  isMutating: number | boolean;
  isSuccess: boolean;
  successMessage: string;
}

export const useMutationStatusToasts = ({
  isMutating,
  isSuccess,
  isError,
  successMessage,
  errorMessage,
}: Props) => {
  const toast = useToast();

  return useEffect(() => {
    if (!isMutating) {
      if (isSuccess) {
        toast.add({
          label: successMessage,
          variant: 'success',
        });
      }

      if (isError) {
        toast.add({
          label: errorMessage,
          variant: 'error',
        });
      }
    }
  }, [isMutating, isSuccess, isError]);
};

import type { Column } from '@tanstack/react-table';

import type { AbbreviatedSpecialV2 } from '@jane/business-admin/types';
import type { SpecialType } from '@jane/shared/models';
import {
  Box,
  Button,
  CheckboxField,
  ChevronDownIcon,
  Flex,
  List,
  Popover,
  Tag,
  Typography,
} from '@jane/shared/reefer';

import { specialTypeStyles } from './SpecialTypeCell';

type ValidSpecialTypeValue = SpecialType | '';

export const SpecialTypeFilter = ({
  column,
}: {
  column?: Column<AbbreviatedSpecialV2, unknown>;
}) => {
  const filterValue = column?.getFilterValue() as string[];

  const handleToggleSelect = (clickedOption: ValidSpecialTypeValue) => {
    const currentValues = new Set(column?.getFilterValue() as string[]);

    if (currentValues.size === 0) {
      currentValues.add(clickedOption);
    } else if (currentValues.has(clickedOption)) {
      currentValues.delete(clickedOption);
    } else {
      currentValues.add(clickedOption);
    }

    const newValues =
      currentValues.size === 0 ? undefined : Array.from(currentValues);

    column?.setFilterValue(newValues);
  };

  return (
    <Popover
      target={
        <Flex>
          <Button
            variant={
              filterValue === undefined || filterValue?.length === 0
                ? 'tertiary'
                : 'tertiary-selected'
            }
            label={
              <Flex gap={8}>
                <Typography variant="body-bold">Type</Typography>
                {filterValue?.length > 0 ? (
                  <Tag
                    label={`${filterValue.length}`}
                    background="primary-light"
                    color="primary-dark"
                  />
                ) : null}
              </Flex>
            }
            endIcon={<ChevronDownIcon size="sm" />}
          />
        </Flex>
      }
      label=""
    >
      {() => (
        <Popover.Content>
          <Box width="210px">
            <List label="special-type-list">
              {Object.entries(specialTypeStyles).map(
                ([specialType, { label }]) => (
                  <List.Item
                    ariaLabel={label}
                    disableHover
                    key={`${specialType.toString()}-checkbox`}
                    px={8}
                    onClick={() => {
                      handleToggleSelect(specialType as SpecialType);
                    }}
                  >
                    <Flex flexDirection="row" gap={16}>
                      <CheckboxField
                        label={`special-type-checkbox-${specialType.toString()}`}
                        name={`special-type-checkbox-${specialType.toString()}`}
                        labelHidden
                        checked={filterValue?.includes(
                          specialType as SpecialType
                        )}
                      />
                      <Typography>{label}</Typography>
                    </Flex>
                  </List.Item>
                )
              )}
            </List>
          </Box>
        </Popover.Content>
      )}
    </Popover>
  );
};

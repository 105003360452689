import { Suspense, useMemo, useState } from 'react';

import {
  JanePayUpsellModal,
  StoreFilters,
  StoresLandingFooter,
  StoresList,
} from '@jane/business-admin/components';
import {
  useStores,
  useStoresWithCounts,
} from '@jane/business-admin/data-access';
import { StoreFiltersProvider } from '@jane/business-admin/providers';

export const StoresLanding = () => {
  const { data: stores, isFetching, isError } = useStores();
  const { data: storesWithCounts, isFetching: isCountLoading } =
    useStoresWithCounts();
  const [searchFilter, setSearchFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');

  const filteredStores = useMemo(() => {
    const storesToFilter = storesWithCounts || stores;
    let storesForUser = storesToFilter?.filter(
      (store) =>
        store.name.toLowerCase().includes(searchFilter) ||
        store.city?.toLowerCase().includes(searchFilter) ||
        store.id.toString() === searchFilter
    );
    if (typeFilter !== '') {
      storesForUser = storesForUser?.filter(
        (store) =>
          store.recreational === (typeFilter === 'Recreational' ? true : false)
      );
    }
    if (stateFilter !== '') {
      storesForUser = storesForUser?.filter((store) =>
        store.state?.includes(stateFilter)
      );
    }
    return storesForUser || [];
  }, [stores, storesWithCounts, searchFilter, typeFilter, stateFilter]);

  if (isError) {
    return <div>You probably need to login or run your backend...</div>;
  }

  return (
    <Suspense
      fallback={
        <>
          <StoreFilters />
          <StoresLandingFooter />
        </>
      }
    >
      <StoreFiltersProvider
        typeFilter={typeFilter}
        stateFilter={stateFilter}
        searchFilter={searchFilter}
        setTypeFilter={setTypeFilter}
        setStateFilter={setStateFilter}
        setSearchFilter={setSearchFilter}
        filteredStores={filteredStores}
        isFetchingStores={isFetching}
      >
        <StoreFilters />
        <StoresList isLoading={isFetching} isCountLoading={isCountLoading} />
        <StoresLandingFooter />
        <JanePayUpsellModal stores={filteredStores} />
      </StoreFiltersProvider>
    </Suspense>
  );
};

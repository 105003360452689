/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const AllProducts24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="All products 24">
      <g className="svg">
        <path
          fill="#5E1FF0"
          fillRule="evenodd"
          d="M17.572 9.343a2.914 2.914 0 1 1 0-5.829 2.914 2.914 0 0 1 0 5.829Zm-4.714-2.914a4.714 4.714 0 1 1 9.428 0 4.714 4.714 0 0 1-9.429 0ZM8.846 8.914H3.975l2.447-4.753 2.425 4.753Zm1.985-.07a1.286 1.286 0 0 1-1.145 1.87H3.13A1.286 1.286 0 0 1 1.988 8.84l3.293-6.395a1.286 1.286 0 0 1 2.288.004l3.263 6.395Zm3.825 6.585c0-.426.346-.772.772-.772h4.286c.426 0 .771.346.771.772v4.285a.771.771 0 0 1-.771.772h-4.286a.771.771 0 0 1-.771-.772V15.43Zm-1.8 0a2.571 2.571 0 0 1 2.572-2.572h4.286a2.571 2.571 0 0 1 2.571 2.572v4.285a2.571 2.571 0 0 1-2.571 2.572h-4.286a2.571 2.571 0 0 1-2.572-2.572V15.43Zm-6.21-.578-2.29 1.103-.567 2.48 1.586 1.988H7.92l1.586-1.989-.566-2.479-2.291-1.103Zm.558-1.73a1.285 1.285 0 0 0-1.115 0L3.352 14.44c-.352.17-.608.49-.695.872l-.677 2.962c-.087.382.005.782.249 1.088l1.894 2.376c.244.306.614.484 1.005.484h3.039c.391 0 .761-.178 1.005-.484l1.895-2.376c.244-.306.335-.706.248-1.088l-.676-2.962a1.285 1.285 0 0 0-.696-.872l-2.737-1.32Z"
          className="Fill-Main"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: AllProducts24,
  sm: AllProducts24,
  lg: AllProducts24,
  xl: AllProducts24,
  xxl: AllProducts24,
  xxxl: AllProducts24,
};

export const AllProductsIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'all-products-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

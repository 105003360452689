import capitalize from 'lodash/capitalize';
import { useEffect } from 'react';

import { useDebouncedTrack } from '@jane/business-admin/hooks';
import type { MenuProductForProductsTable } from '@jane/business-admin/types';
import {
  BODY_LINE_HEIGHT,
  EventNames,
  ModalNames,
  SettingNames,
} from '@jane/business-admin/util';
import {
  Flex,
  Form,
  Skeleton,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

export const LabTestResultsCard = ({
  isLoading,
  menuProduct,
  tableType,
}: {
  isLoading: boolean;
  menuProduct?: Pick<
    MenuProductForProductsTable,
    'percent_cbd' | 'percent_cbda' | 'percent_thc' | 'percent_thca'
  > | null;
  tableType: 'published' | 'hidden' | 'unpublished' | string;
}) => {
  // Each field needs it's own instance of debouncedTrack,
  // otherwise it will only call 'track' on the last field if they are all edited
  const debouncedTrackTHC = useDebouncedTrack(1000);
  const debouncedTrackCBD = useDebouncedTrack(1000);
  const debouncedTrackCBDA = useDebouncedTrack(1000);
  const debouncedTrackTHCA = useDebouncedTrack(1000);
  const {
    percent_cbd = '',
    percent_cbda = '',
    percent_thc = '',
    percent_thca = '',
  } = {
    ...menuProduct,
  };

  const { watch } = useFormContext();
  const [watchTHC, watchCBD, watchTHCA, watchCBDA] = watch([
    'percent_thc',
    'percent_cbd',
    'percent_thca',
    'percent_cbda',
  ]);

  useEffect(() => {
    if (watchTHC.toString() !== percent_thc) {
      debouncedTrackTHC({
        event: EventNames.ModifiedSetting,
        modal_name: ModalNames.EditProduct,
        revert: !watchTHC || watchTHC.toString() !== percent_thc,
        setting_name:
          SettingNames[
            `${capitalize(tableType)}THC` as keyof typeof SettingNames
          ],
      });
    }
  }, [watchTHC]);
  useEffect(() => {
    if (watchCBD.toString() !== percent_cbd) {
      debouncedTrackCBD({
        event: EventNames.ModifiedSetting,
        modal_name: ModalNames.EditProduct,
        revert: !watchCBD || watchCBD.toString() !== percent_cbd,
        setting_name:
          SettingNames[
            `${capitalize(tableType)}CBD` as keyof typeof SettingNames
          ],
      });
    }
  }, [watchCBD]);
  useEffect(() => {
    if (watchCBDA.toString() !== percent_cbda) {
      debouncedTrackCBDA({
        event: EventNames.ModifiedSetting,
        modal_name: ModalNames.EditProduct,
        revert: !watchCBDA || watchCBDA.toString() !== percent_cbda,
        setting_name:
          SettingNames[
            `${capitalize(tableType)}CBDA` as keyof typeof SettingNames
          ],
      });
    }
  }, [watchCBDA]);
  useEffect(() => {
    if (watchTHCA.toString() !== percent_thca) {
      debouncedTrackTHCA({
        event: EventNames.ModifiedSetting,
        modal_name: ModalNames.EditProduct,
        revert: !watchTHCA || watchTHCA.toString() !== percent_thca,
        setting_name:
          SettingNames[
            `${capitalize(tableType)}THCA` as keyof typeof SettingNames
          ],
      });
    }
  }, [watchTHCA]);

  const LoadingLabTests = () => {
    return (
      <>
        {Array(4)
          .fill(null)
          .map((_, index) => {
            return (
              <Skeleton animate width="100%" key={`loading_lab_tests_${index}`}>
                <Skeleton.Bone
                  height={BODY_LINE_HEIGHT}
                  my={4}
                  mb={12}
                  width="100%"
                />
                <Skeleton.Bone height={48} my={4} width="100%" />
              </Skeleton>
            );
          })}
      </>
    );
  };

  return (
    <>
      <Typography variant="header-bold" mb={40}>
        Lab test results
      </Typography>
      <Flex mb={40} gap={24}>
        {isLoading ? (
          <LoadingLabTests />
        ) : (
          <>
            <Flex width="25%">
              <Form.NumberField
                label="THC"
                name="percent_thc"
                endUnit="%"
                min={0}
                max={100}
              />
            </Flex>
            <Flex width="25%">
              <Form.NumberField
                label="CBD"
                name="percent_cbd"
                endUnit="%"
                min={0}
                max={100}
              />
            </Flex>
            <Flex width="25%">
              <Form.NumberField
                label="THCA"
                name="percent_thca"
                endUnit="%"
                min={0}
                max={100}
              />
            </Flex>
            <Flex width="25%">
              <Form.NumberField
                label="CBDA"
                name="percent_cbda"
                endUnit="%"
                min={0}
                max={100}
              />
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

import { useCallback, useContext, useEffect } from 'react';

import { useSaveStoreNotifications } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  CardNames,
  EventNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import type { StoreChatSetting } from '@jane/shared/models';
import {
  Card,
  Flex,
  Form,
  FormValidationError,
  Skeleton,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { STORE_CHAT_SETTINGS_FIELD } from './form';

const FORM_ERROR_NAME = 'notification-error';

const LoadingSkeleton = () => (
  <Skeleton animate direction="column" height="fit-content" width="100%">
    <Flex mt={32} flexDirection="row">
      <Skeleton.Bone width="56px" />

      <Flex flexDirection="column" width="100%" ml={16}>
        <Skeleton.Bone width="500px" height="14px" mb={4} />
        <Skeleton.Bone width="800px" height="14px" />
      </Flex>
    </Flex>
  </Skeleton>
);

interface Props {
  chatSetting?: StoreChatSetting | null;
  isFetched: boolean;
}

const ChatSettingsForm = ({ chatSetting }: Pick<Props, 'chatSetting'>) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating notification settings. Please try again.'
  );

  const toast = useToast();
  const { storeId } = useContext(StoreDetailsContext);
  const {
    mutateAsync: saveNotifications,
    isSuccess: saveNotificationsSuccess,
  } = useSaveStoreNotifications(storeId);
  const formMethods = useForm({
    defaultValues: {
      [STORE_CHAT_SETTINGS_FIELD]: chatSetting?.enabled,
    },
  });
  const { setValue } = formMethods;

  useEffect(() => {
    if (saveNotificationsSuccess) {
      toast.add({
        label: 'Chat settings updated',
        variant: 'success',
      });
    }
  }, [saveNotificationsSuccess]);

  const onToggle = useCallback(
    (enabled: boolean) => {
      if (enabled === chatSetting?.enabled) return;

      const requestData = {
        status_messages: [],
        dismissal_messages: [],
        chat_setting: {
          id: chatSetting?.id,
          enabled: enabled,
        },
      };
      const submitMethod = () => {
        track({
          event: EventNames.EditedStoreSettings,
          card_name: CardNames.ChatSettings,
          changed_attributes: ['chat_setting'],
        });
        return saveNotifications(requestData);
      };

      return catchSubmitErrors({
        submitMethod,
        requestData,
        onValidationError: (validationErrors: Record<string, unknown>) => {
          throw new FormValidationError(
            FORM_ERROR_NAME,
            parseValidationErrors(validationErrors)
          );
        },
        callback: () => {
          setValue(STORE_CHAT_SETTINGS_FIELD, chatSetting?.enabled);
          toast.add({
            label: 'Error updating notification settings. Please try again.',
            variant: 'error',
          });
        },
      });
    },
    [chatSetting?.enabled]
  );

  return (
    <Form.BaseForm
      name="notification-settings"
      formMethods={formMethods}
      onSubmit={() => null}
    >
      <Flex flexDirection="column" data-testid="email-settings" mt={32}>
        <Form.SwitchField
          label="Allow retail managers to chat with customers"
          name={STORE_CHAT_SETTINGS_FIELD}
          defaultChecked={!!chatSetting?.enabled}
          onChange={onToggle}
        />
        <Typography color="grays-mid" mb={40} ml={56}>
          Customers will be sent a text message to continue the conversation via
          our web portal, where you both can chat about an upcoming or in
          progress reservation. If your chat is inactive for 3 months, we
          automatically disable the feature in the fulfillment dashboard, but it
          can always be turned back on.
        </Typography>
      </Flex>
    </Form.BaseForm>
  );
};

export const ChatSettingsCard = ({
  chatSetting,
  isFetched,
}: {
  chatSetting: StoreChatSetting | undefined | null;
  isFetched: boolean;
}) => {
  return (
    <Card border="grays-light" flat mb={24}>
      <Card.Content
        background={
          !chatSetting?.enabled && isFetched ? 'grays-ultralight' : undefined
        }
      >
        <Flex p={24} flexDirection="column">
          <Flex alignItems="center">
            <Typography variant="header-bold">Reservation Chat</Typography>
          </Flex>

          {isFetched ? (
            <ChatSettingsForm chatSetting={chatSetting} />
          ) : (
            <LoadingSkeleton />
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
};

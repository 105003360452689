import get from 'lodash/get';
import { useContext, useMemo } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { BODY_LINE_HEIGHT, ModalNames } from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { StoreCrmIntegration } from '@jane/shared/models';
import { CrmProvider } from '@jane/shared/models';
import { Flex, Skeleton, Typography } from '@jane/shared/reefer';

import { CardSection } from '../../../../CardSection';
import { EditableCard } from '../../../../EditableCard';
import { LoyaltyModal } from './LoyaltyModal';

export const CRM_PROVIDER_MAPPING = {
  [CrmProvider.alpineiq]: 'AlpineIQ',
  [CrmProvider.data_owl]: 'Data Owl',
  [CrmProvider.springbig]: 'Springbig',
  // No idea if these are still in use...
  // blackbird: 'Blackbird',
  // sprout: 'Sprout',
  // happy_cabbage: 'Happy Cabbage',
};

export const LoyaltyCard = () => {
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const { data: storeSettings, isFetching: storeSettingsLoading } =
    useStoreSettings(storeId);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.LoyaltySettings
  );

  const bypassRedemptionFlag = useFlag(FLAGS.scBypassPointDeductionFrontend);

  const enabledStoreCRMIntegration = useMemo(() => {
    if (!storeSettings?.store_crm_integrations?.length) {
      return null;
    }

    return (
      (storeSettings?.store_crm_integrations.find(
        ({ enabled }) => enabled
      ) as StoreCrmIntegration) || null
    );
  }, [storeSettings?.store_crm_integrations]);

  const enabledLoyaltyProvider = useMemo(() => {
    return get(enabledStoreCRMIntegration, 'crm_provider', null);
  }, [enabledStoreCRMIntegration]);

  const enabledLoyaltyProviderOnlineRedemption = useMemo(() => {
    return get(enabledStoreCRMIntegration, 'automatic_redemption', false);
  }, [enabledStoreCRMIntegration]);

  const enabledLoyaltyProviderBypassRedemption = useMemo(() => {
    return get(enabledStoreCRMIntegration, 'bypass_redemption', false);
  }, [enabledStoreCRMIntegration]);

  const enabledLoyaltyProviderMaxRedemptionCount = useMemo(() => {
    return get(enabledStoreCRMIntegration, 'max_redemption_count', null);
  }, [enabledStoreCRMIntegration]);

  return (
    <>
      <EditableCard
        title="Loyalty"
        onEdit={() => openModal()}
        isLoading={storeSettingsLoading}
        showTextButton={!storeSettingsLoading && !enabledLoyaltyProvider}
        textButtonLabel={`${
          storeSettings?.store_crm_integrations?.length ? 'Enable' : 'Add'
        } loyalty rewards`}
        disabled={!canEditStore}
      >
        <Flex pt={24}>
          {storeSettingsLoading ? (
            <Skeleton animate>
              <Skeleton.Bone height={BODY_LINE_HEIGHT} width={200} />
            </Skeleton>
          ) : enabledLoyaltyProvider ? (
            <>
              <CardSection label="Provider" width="25%">
                <Typography>
                  {CRM_PROVIDER_MAPPING[enabledLoyaltyProvider]}
                </Typography>
              </CardSection>
              <CardSection label="Online Redemption" width="75%">
                {enabledLoyaltyProviderOnlineRedemption && (
                  <Typography>Allow online reward redemption</Typography>
                )}
                {bypassRedemptionFlag &&
                  enabledLoyaltyProviderBypassRedemption && (
                    <Typography>
                      Deduct loyalty points within the POS
                    </Typography>
                  )}
                {enabledLoyaltyProviderMaxRedemptionCount === 1 && (
                  <Typography>Limit to one redemption per cart</Typography>
                )}
              </CardSection>
            </>
          ) : (
            <Typography>
              Loyalty rewards have not been enabled for this store.
            </Typography>
          )}
        </Flex>
      </EditableCard>
      {modalOpen && (
        <LoyaltyModal
          storeName={storeSettings?.store?.name}
          enabledProvider={enabledLoyaltyProvider}
          integrations={storeSettings?.store_crm_integrations || []}
          open={modalOpen}
          setOpen={() => closeModal()}
        />
      )}
    </>
  );
};

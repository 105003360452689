import { useQueryClient } from '@tanstack/react-query';
import type { ColumnDef, Row, SortingState } from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import upperCase from 'lodash/upperCase';
import { useContext } from 'react';

import { fetchUnpublishedProductQueryKey } from '@jane/business-admin/data-access';
import {
  ProductsTableContext,
  StoreDetailsContext,
} from '@jane/business-admin/providers';
import { EventNames, ModalNames, track } from '@jane/business-admin/util';
import type { CheckboxFieldProps } from '@jane/shared/reefer';
import { Button, Flex, Image, Typography } from '@jane/shared/reefer';

import { SortableHeader } from '../../../../shared/tables/SortableHeader';
import { DefaultBrandLogo, ImageWrapper } from './DefaultBrandLogo';
import { UnpublishedProductCell } from './UnpublishedProductCell';
import { WeightCell } from './WeightCell';

export { SortableHeader };

const preventPropagationToRowOnClick: Required<CheckboxFieldProps>['onClick'] =
  (event) => {
    event.stopPropagation();
  };

const MainCell = ({ content, status }: { content: any; status: any }) => (
  <Typography color={status !== 'publishing' ? 'grays-black' : 'grays-light'}>
    {content}
  </Typography>
);

const BrandCell = ({ row }: { row: Row<any> }) => (
  <Flex flexDirection="row" gap={4}>
    {row.original.brand_logo ? (
      <ImageWrapper visible={row.original.visible}>
        <Image
          borderRadius="100%"
          height="24px"
          width="24px"
          src={row.original.brand_logo}
          altText={`${row.original.pos_data.brand} logo`}
        />
      </ImageWrapper>
    ) : (
      <DefaultBrandLogo
        brand={row.original.pos_data.brand}
        visible={row.original.visible}
      />
    )}
    <Typography color={row.original.visible ? 'grays-black' : 'grays-light'}>
      {row.original.pos_data.brand}
    </Typography>
  </Flex>
);

const ActionCell = ({
  isDisabled = false,
  isPublishing,
  publishProduct,
  row,
  openUnpublishedModal,
}: {
  isDisabled?: boolean;
  isPublishing: boolean;
  openUnpublishedModal: (row: any) => void;
  publishProduct: any;
  row: Row<any>;
}) => {
  const { canEditProducts } = useContext(ProductsTableContext);
  const { storeId } = useContext(StoreDetailsContext);

  const queryClient = useQueryClient();

  const handleEditClick = (event: any) => {
    preventPropagationToRowOnClick(event);
    openUnpublishedModal(row.original);
  };

  const handlePublishClick = (event: any) => {
    preventPropagationToRowOnClick(event);

    const formData = {
      product: {
        name: row.original.pos_data.name,
        kind: row.original.pos_data.kind,
        unmapped_product_id: row.original.unmapped_product_id,
        category: row.original.pos_data.category,
        brand: row.original.pos_data.brand,
        percent_cbd: row.original.pos_data.lab_results.percent_cbd,
        percent_thc: row.original.pos_data.lab_results.percent_thc,
        cbd_dosage_milligrams: 0,
        thc_dosage_milligrams: 0,
        description: row.original.pos_data.description,
        image_urls: row.original.pos_data.image_urls,
      },
    };

    queryClient.invalidateQueries({
      queryKey: fetchUnpublishedProductQueryKey(storeId, {}),
    });

    publishProduct(formData);
    track({
      event: EventNames.ClickedButton,
      button_label: 'Publish',
      modal_name: ModalNames.EditProduct,
    });
  };

  const label = row.original.status === 'invalid_data' ? 'Edit' : 'Publish';

  return (
    <Flex>
      <Button
        size="small"
        variant="secondary"
        label={label}
        disabled={
          !canEditProducts ||
          row.original.status === 'publishing' ||
          isDisabled ||
          isPublishing
        }
        onClick={
          row.original.status === 'invalid_data'
            ? handleEditClick
            : handlePublishClick
        }
      />
    </Flex>
  );
};

export const buildUnpublishedColumns = ({
  isBulkEditTable = false,
  isFetched,
  isPublishing,
  publishProduct,
  openUnpublishedModal,
  sorting,
  totalRowCount,
}: {
  isBulkEditTable: boolean;
  isFetched: boolean;
  isPublishing: boolean;
  openUnpublishedModal: (row: any) => void;
  publishProduct: any;
  sorting: SortingState;
  totalRowCount: number;
}): ColumnDef<any>[] => [
  {
    accessorKey: 'name',
    enableColumnFilter: true,
    id: 'name',
    header: ({ column }) => (
      <SortableHeader
        sorting={sorting}
        column={column}
        headerText={!isFetched ? 'xxx Products' : `${totalRowCount} Products`}
      />
    ),
    cell: ({ row }) => {
      const handleClick: Required<CheckboxFieldProps>['onClick'] = (event) => {
        track({
          event: (event.target as HTMLInputElement).checked
            ? EventNames.SelectedObject
            : EventNames.DeselectedObject,
          objects: row.original.name,
          trigger_source_id: 'checkbox',
        });

        preventPropagationToRowOnClick(event);
        row.toggleSelected();
      };

      const invalid = row.original.status === 'invalid_data';

      return (
        <UnpublishedProductCell
          row={row}
          invalid={invalid}
          handleClick={handleClick}
          isBulkEditTable={isBulkEditTable}
        />
      );
    },
  },
  {
    accessorKey: 'visible',
    id: 'visible',
    header: () => (
      <Typography variant="caps" color="grays-mid">
        action
      </Typography>
    ),
    cell: ({ row }) => (
      <ActionCell
        isDisabled={isBulkEditTable}
        publishProduct={publishProduct}
        isPublishing={isPublishing}
        row={row}
        openUnpublishedModal={openUnpublishedModal}
      />
    ),
    enableColumnFilter: false,
  },
  {
    header: ({ column }) => (
      <SortableHeader sorting={sorting} column={column} headerText="brand" />
    ),
    cell: ({ row }) => {
      return row.original.pos_data.brand ? <BrandCell row={row} /> : null;
    },
    accessorKey: 'brand',
    id: 'brand',
    footer: 'Brand',
  },
  {
    header: () => (
      <Typography variant="caps" color="grays-mid">
        status
      </Typography>
    ),
    cell: ({ row }) => {
      return (
        <Typography
          color={
            row.original.status === 'publishing'
              ? 'grays-light'
              : row.original.status === 'invalid_data'
              ? 'error'
              : 'grays-black'
          }
        >
          {row.original.status}
        </Typography>
      );
    },
    enableColumnFilter: !isBulkEditTable,
    accessorKey: 'status',
    id: 'status',
    footer: 'Status',
  },
  {
    header: ({ column }) => (
      <SortableHeader sorting={sorting} column={column} headerText="category" />
    ),
    cell: ({ row }) => {
      return row.original.pos_data.kind ? (
        <MainCell
          content={capitalize(row.original.pos_data.kind)}
          status={row.original.status}
        />
      ) : null;
    },
    accessorKey: 'category',
    id: 'category',
    footer: 'Category',
  },
  {
    header: ({ column }) => (
      <SortableHeader
        sorting={sorting}
        column={column}
        headerText="subcategory"
      />
    ),
    cell: ({ row }) => (
      <MainCell
        content={row.original.pos_data.kind_subtype}
        status={row.original.status}
      />
    ),
    accessorKey: 'subcategory',
    id: 'subcategory',
    footer: 'Subcategory',
  },
  {
    header: ({ column }) => (
      <SortableHeader sorting={sorting} column={column} headerText="lineage" />
    ),
    cell: ({ row }) => {
      return row.original.pos_data.category ? (
        <MainCell
          content={
            row.original.pos_data.category === 'cbd'
              ? upperCase(row.original.pos_data.category)
              : capitalize(row.original.pos_data.category)
          }
          status={row.original.status}
        />
      ) : null;
    },
    accessorKey: 'lineage',
    id: 'lineage',
    footer: 'Lineage',
  },
  {
    header: () => (
      <Typography variant="caps" color="grays-mid">
        pos sku
      </Typography>
    ),
    cell: ({ row }) => (
      <MainCell
        content={row.original.pos_data.sku}
        status={row.original.status}
      />
    ),
    enableColumnFilter: false,
    accessorKey: 'pos_sku',
    id: 'pos_sku',
  },
  {
    header: () => <Typography variant="caps">each</Typography>,
    cell: ({ row }) => {
      return row.original.pos_data?.pricing?.per_each ? (
        <WeightCell
          columnPrice={row.original.pos_data.pricing.per_each}
          visible={row.original.status !== 'publishing'}
          includesPrice="each"
        />
      ) : null;
    },
    accessorKey: 'price_each',
    enableColumnFilter: false,
    id: 'price_each',
  },
  {
    header: () => <Typography variant="caps">0.5g</Typography>,
    cell: ({ row }) => {
      return row.original.pos_data?.pricing?.per_half_gram ? (
        <WeightCell
          columnPrice={row.original.pos_data.pricing.per_half_gram}
          visible={row.original.status !== 'publishing'}
          includesPrice="half_gram"
        />
      ) : null;
    },
    accessorKey: 'price_half_gram',
    enableColumnFilter: false,
    id: 'price_half_gram',
  },
  {
    header: () => <Typography variant="caps">1g</Typography>,
    cell: ({ row }) => {
      return row.original.pos_data?.pricing?.per_gram ? (
        <WeightCell
          columnPrice={row.original.pos_data.pricing.per_gram}
          visible={row.original.status !== 'publishing'}
          includesPrice="gram"
        />
      ) : null;
    },
    accessorKey: 'price_gram',
    enableColumnFilter: false,
    id: 'price_gram',
  },
  {
    header: () => <Typography variant="caps">2g</Typography>,
    cell: ({ row }) => {
      return row.original.pos_data?.pricing?.per_two_gram ? (
        <WeightCell
          columnPrice={row.original.pos_data.pricing.per_two_gram}
          visible={row.original.status !== 'publishing'}
          includesPrice="two_gram"
        />
      ) : null;
    },
    accessorKey: 'price_two_gram',
    enableColumnFilter: false,
    id: 'price_two_gram',
  },
  {
    header: () => <Typography variant="caps">3.5g</Typography>,
    cell: ({ row }) => {
      return row.original.pos_data?.pricing?.per_eighth_ounce ? (
        <WeightCell
          columnPrice={row.original.pos_data.pricing.per_eighth_ounce}
          visible={row.original.status !== 'publishing'}
          includesPrice="eighth_ounce"
        />
      ) : null;
    },
    accessorKey: 'price_eighth_ounce',
    enableColumnFilter: false,
    id: 'price_eighth_ounce',
  },
  {
    header: () => <Typography variant="caps">7g</Typography>,
    cell: ({ row }) => {
      return row.original.pos_data?.pricing?.per_quarter_ounce ? (
        <WeightCell
          columnPrice={row.original.pos_data.pricing.per_quarter_ounce}
          visible={row.original.status !== 'publishing'}
          includesPrice="quarter_ounce"
        />
      ) : null;
    },
    accessorKey: 'price_quarter_ounce',
    enableColumnFilter: false,
    id: 'price_quarter_ounce',
  },
  {
    header: () => <Typography variant="caps">14g</Typography>,
    cell: ({ row }) => {
      return row.original.pos_data?.pricing?.per_half_ounce ? (
        <WeightCell
          columnPrice={row.original.pos_data.pricing.per_half_ounce}
          visible={row.original.status !== 'publishing'}
          includesPrice="half_ounce"
        />
      ) : null;
    },
    accessorKey: 'price_half_ounce',
    enableColumnFilter: false,
    id: 'price_half_ounce',
  },
  {
    header: () => <Typography variant="caps">28g</Typography>,
    cell: ({ row }) => {
      return row.original.pos_data?.pricing?.per_ounce ? (
        <WeightCell
          columnPrice={row.original.pos_data.pricing.per_ounce}
          visible={row.original.status !== 'publishing'}
          includesPrice="ounce"
        />
      ) : null;
    },
    accessorKey: 'price_ounce',
    enableColumnFilter: false,
    id: 'price_ounce',
  },
];

import { useMediaQuery } from 'react-responsive';

export const BusinessSolutions = () => {
  // Custom media query since this marketing page doesn't conform with any previous established screen size breaks
  const isTablet = useMediaQuery({
    query: `(max-width: 1214px)`,
  });

  return (
    <iframe
      src="https://www.iheartjane.com/ui/explore-solutions"
      title="Business Solutions"
      width="100%"
      height="100%"
      // Hardcoded height cause any kind of dynamic sizing runs into CORS issues
      style={{ height: isTablet ? 1700 : 1200, border: 0 }}
    />
  );
};

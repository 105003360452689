/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const PosDevice16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="POS device 16">
      <path
        fill="#0E0E0E"
        d="m5.217 10-.216 4.47a.748.748 0 0 0 .032.255l.257.84a.64.64 0 0 0 .62.435h4.18a.64.64 0 0 0 .62-.435l.257-.84a.75.75 0 0 0 .032-.255L10.783 10H5.217Z"
        className="Vector"
      />
      <rect
        width={12}
        height={8}
        x={2}
        y={2}
        stroke="#0E0E0E"
        strokeWidth={2}
        className="Rectangle 729"
        rx={1}
      />
    </g>
  </svg>
);

const PosDevice24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="POS device 24">
      <path
        fill="#0E0E0E"
        d="m8.5 15-.499 6.704c-.005.13.01.26.043.384l.343 1.26a.866.866 0 0 0 .826.652h5.574c.383 0 .72-.266.826-.653l.343-1.259a1.27 1.27 0 0 0 .043-.384L15.5 15h-7Z"
        className="Vector"
      />
      <rect
        width={20}
        height={13}
        x={2}
        y={2}
        stroke="#0E0E0E"
        strokeWidth={2}
        className="Rectangle 729"
        rx={1.5}
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: PosDevice16,
  md: PosDevice24,
  lg: PosDevice24,
  xl: PosDevice24,
  xxl: PosDevice24,
  xxxl: PosDevice24,
};

export const PosDeviceIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'pos-device-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { useQuery } from '@tanstack/react-query';

import { api } from '@jane/shared/data-access';

import type { GoogleFontFamily } from './googleFonts.types';

interface ListFontsParams {
  /** Your developer API key */
  apiKey: string;
  capability?: 'vf' | 'woff2';
  /** Case-sensitive name of a font family */
  family?: string;
  sort?: 'alpha' | 'date' | 'popularity' | 'style' | 'trending';
  /**
   * Name of a font subset.
   * @example "latin", "latin-ext", "arabic"
   */
  subset?: string;
}

/**
 * The Developer API gives access to the metadata for all families served by Google Fonts.
 * @see https://developers.google.com/fonts/docs/developer_api
 */
const listGoogleFonts = async ({
  apiKey,
  capability = 'woff2',
  sort = 'popularity',
  subset = 'latin',
  ...rest
}: ListFontsParams) => {
  const params = new URLSearchParams({
    key: apiKey,
    capability,
    sort,
    subset,
    ...rest,
  });

  const response = await api.get<{
    items: GoogleFontFamily[];
    kind: string;
  }>(`https://www.googleapis.com/webfonts/v1/webfonts?${params.toString()}`, {
    mode: 'cors',
  });
  return response?.items ?? [];
};

export const useGoogleFonts = (params: ListFontsParams) =>
  useQuery({
    queryFn: () => listGoogleFonts(params),
    queryKey: ['googleFonts', params],
  });

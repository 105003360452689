import { SettingNames } from './tracking/eventNames';

export const getLeadTimeTrackSetting = (
  unitToUpdate: string,
  isMin: boolean
) => {
  switch (unitToUpdate) {
    case 'days':
      return isMin
        ? SettingNames.GeofenceMinimumDays
        : SettingNames.GeofenceMaxDays;
    case 'hours':
      return isMin
        ? SettingNames.GeofenceMinimumHours
        : SettingNames.GeofenceMaxHours;
    case 'minutes':
      return isMin
        ? SettingNames.GeofenceMinimumMinutes
        : SettingNames.GeofenceMaxMinutes;
    default:
      return SettingNames.GeofenceLeadTime;
  }
};

export const getWindowIntervalTrackSetting = (
  unitToUpdate: string,
  windowOrInterval: string
) => {
  if (windowOrInterval === 'window') {
    if (unitToUpdate === 'hours') {
      return SettingNames.GeofenceWindowHours;
    } else {
      return SettingNames.GeofenceWindowMinutes;
    }
  } else {
    if (unitToUpdate === 'hours') {
      return SettingNames.GeofenceIntervalHours;
    } else {
      return SettingNames.GeofenceIntervalMinutes;
    }
  }
};

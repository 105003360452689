import { useParams } from 'react-router-dom';

import {
  BoostConfigCard,
  SubdomainCard,
} from '@jane/business-admin/components';
import { useManager, useStoreSettings } from '@jane/business-admin/data-access';
import { Flex } from '@jane/shared/reefer';

export const StoreSettingsSubdomain = () => {
  const { data: currentManager } = useManager();
  const { id: storeId = '' } = useParams<'id'>();
  const { data: storeSettings, isFetching } = useStoreSettings(storeId);

  return (
    <Flex flexDirection="column">
      <BoostConfigCard />
      {(!isFetching && !storeSettings?.store.boost_enabled) ||
      currentManager?.manager?.internal ? (
        <SubdomainCard />
      ) : null}
    </Flex>
  );
};

import type { Column } from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { MenuProductForProductsTable } from '@jane/business-admin/types';
import { EventNames, SearchSubjects, track } from '@jane/business-admin/util';
import {
  Button,
  CheckboxField,
  ChevronDownIcon,
  Flex,
  Form,
  List,
  Popover,
  RadioFieldGroup,
  SearchField,
  Tag,
  Typography,
} from '@jane/shared/reefer';

interface FilterProps {
  column: Column<MenuProductForProductsTable, unknown>;
  data: any;
}

const ContentAndStatusFilter = ({ column, data }: FilterProps) => {
  const columnName = column.columnDef.footer;
  const filterValue = column.getFilterValue() as string[];
  const label = columnName?.toString() || '';

  return (
    <Popover
      key={`${label}-key`}
      target={
        <Flex minWidth={columnName === 'Custom Rows' ? '150px' : 'auto'}>
          <Button
            variant={
              filterValue === undefined || filterValue?.length === 0
                ? 'tertiary'
                : 'tertiary-selected'
            }
            label={
              <Flex gap={8}>
                <Typography variant="body-bold">{capitalize(label)}</Typography>
                {filterValue?.length > 0 ? (
                  <Tag
                    label={`${filterValue.length}`}
                    background="primary-light"
                    color="primary-dark"
                  />
                ) : null}
              </Flex>
            }
            endIcon={<ChevronDownIcon size="sm" />}
          />
        </Flex>
      }
      label=""
    >
      {() => (
        <Popover.Content>
          <RadioFieldGroup
            name={`${label}-list`}
            defaultChecked={filterValue ? filterValue.join(' ') : undefined}
            options={data.map((option: string) => ({
              label: <Typography>{option}</Typography>,
              id: option.toLowerCase().split(' ')[0],
              value:
                option === 'Invalid data'
                  ? option.toLowerCase().split(' ').join('_')
                  : option.toLowerCase().split(' ')[0],
              wrapper: (children: any) => (
                <Flex px={24} py={12}>
                  {children}
                </Flex>
              ),
            }))}
            onChange={(value) => {
              column.setFilterValue([value]);
              track({
                event: EventNames.SelectedFilter,
                filter_name: columnName?.toString() || '',
                selected_value: value?.toString() || '',
                all_selected_values: [value?.toString() || ''],
                url: '',
              });
            }}
          />
        </Popover.Content>
      )}
    </Popover>
  );
};

export const TableFilter = ({ column, data }: FilterProps) => {
  const columnName = column.columnDef.footer;
  const filterValue = column.getFilterValue() as string[];
  const label = columnName?.toString() || '';

  const [searchParams, setSearchParams] = useSearchParams();
  const [filterSearch, setFilterSearch] = useState('');
  const productId = searchParams.get('product_id') || '';

  const listItems = data.filter((item: any) => {
    if (column.id === 'custom_rows') {
      return item.custom_display_name
        .toLowerCase()
        .includes(filterSearch.toLowerCase());
    } else {
      return item.toLowerCase().includes(filterSearch.toLowerCase());
    }
  });

  const handleMultiSelectFilterClick = (
    setOption: string,
    filterValue: string[]
  ) => {
    let newValues;
    if (filterValue === undefined) {
      newValues = [setOption];
    } else if (filterValue?.includes(setOption)) {
      newValues = filterValue?.filter((clickedValue: string) => {
        return clickedValue !== setOption;
      });
      if (newValues.length === 0) {
        newValues = undefined;
      }
      track({
        event: EventNames.DeselectedFilter,
        filter_name: columnName?.toString() || '',
        deselected_value: setOption?.toString() || '',
        all_selected_values: newValues || [],
        url: '',
      });
    } else {
      newValues = [...filterValue, setOption];
      track({
        event: EventNames.SelectedFilter,
        filter_name: columnName?.toString() || '',
        selected_value: setOption?.toString() || '',
        all_selected_values: newValues || [],
        url: '',
      });
    }
    column.setFilterValue(newValues);
    setFilterSearch('');
  };

  if (data.length === 0) {
    return null;
  }

  if (column.id === 'name') {
    return (
      <Flex minWidth="250px">
        <Form.SearchField
          onChange={(value) => {
            // When value is cleared and exists in URL, remove it to load full list
            if (value === '' && productId) {
              searchParams.delete('product_id');
              setSearchParams(searchParams);
            }
            column.setFilterValue(value);
            track({
              event: EventNames.Search,
              arguments: value,
              subject: SearchSubjects.Products,
            });
          }}
          placeholder="Search products"
          label="Search products"
          labelHidden={true}
          name="search"
          isDebounced
          debounceDelay={750}
        />
      </Flex>
    );
  }

  if (columnName === 'Content' || columnName === 'Status') {
    return <ContentAndStatusFilter column={column} data={data} />;
  }

  return (
    <Popover
      key={`${label}-key`}
      onClose={() => setFilterSearch('')}
      target={
        <Flex minWidth={columnName === 'Custom Rows' ? '150px' : 'auto'}>
          <Button
            variant={
              filterValue === undefined || filterValue?.length === 0
                ? 'tertiary'
                : 'tertiary-selected'
            }
            label={
              <Flex gap={8}>
                <Typography variant="body-bold">{capitalize(label)}</Typography>
                {filterValue?.length > 0 ? (
                  <Tag
                    label={`${filterValue.length}`}
                    background="primary-light"
                    color="primary-dark"
                  />
                ) : null}
              </Flex>
            }
            endIcon={<ChevronDownIcon size="sm" />}
          />
        </Flex>
      }
      label=""
    >
      {() => (
        <Popover.Content>
          <SearchField
            name={`${label}-search`}
            label={`${label}-search`}
            labelHidden
            onChange={setFilterSearch}
          />
          <List label={`${label}-list`}>
            {listItems.map((option: any) => {
              const displayName =
                column.id === 'custom_rows'
                  ? option.custom_display_name
                  : option;

              const isChecked = !!filterValue?.includes(displayName);

              return (
                <List.Item
                  ariaLabel={displayName}
                  key={`${label}-${displayName}`}
                  onClick={() =>
                    handleMultiSelectFilterClick(displayName, filterValue)
                  }
                >
                  <Flex flexDirection="row" gap={16}>
                    {columnName !== 'Content' ? (
                      <CheckboxField
                        label={`${label}-${displayName}-checkbox`}
                        name={`${label}-${displayName}-checkbox`}
                        labelHidden
                        checked={isChecked}
                      />
                    ) : null}
                    <Typography>
                      {label === 'Category' || label === 'Lineage'
                        ? capitalize(displayName)
                        : displayName}
                    </Typography>
                  </Flex>
                </List.Item>
              );
            })}
          </List>
        </Popover.Content>
      )}
    </Popover>
  );
};

import type { StoreSchedule } from '@jane/business-admin/types';
import { ALL_DAYS, RESERVATION_MODES } from '@jane/business-admin/util';
import { formatTime } from '@jane/shared/util';

import type { StoreHourFormItem } from './HoursModal';
import { INITIAL_HOURS } from './HoursModal';

// TODO: Have to cast as any, otherwise it complains about object keys
export const parseDateObj = (schedule: StoreSchedule): StoreHourFormItem => {
  const modifiedSchedule: StoreHourFormItem = {
    ...INITIAL_HOURS,
    ...schedule,
  };

  // Convert 24hr time to 12hr
  for (const hourKey in modifiedSchedule) {
    if (
      hourKey !== 'id' &&
      hourKey !== 'schedule_type' &&
      modifiedSchedule[hourKey as keyof StoreSchedule]
    ) {
      modifiedSchedule[
        hourKey as keyof Omit<StoreSchedule, 'schedule_type' | 'id'>
      ] = formatTime(
        modifiedSchedule[hourKey as keyof StoreSchedule] as string,
        'h:mm A',
        'hh:mm'
      );
    }
  }

  // Set the enabled field for checkboxes
  ALL_DAYS.forEach((day: string) => {
    const dayKey = `${day}_enabled` as keyof Pick<
      StoreHourFormItem,
      | 'sunday_enabled'
      | 'monday_enabled'
      | 'tuesday_enabled'
      | 'thursday_enabled'
      | 'friday_enabled'
      | 'saturday_enabled'
    >;

    modifiedSchedule[dayKey] =
      !!modifiedSchedule[`${day}_hours_start` as keyof StoreHourFormItem] &&
      !!modifiedSchedule[`${day}_hours_end` as keyof StoreHourFormItem];
  });

  return modifiedSchedule;
};

export const HOURS_TABS = ['retail', ...RESERVATION_MODES];

import { useContext } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  BODY_LINE_HEIGHT,
  LARGE_SKELETON_WIDTH,
  ModalNames,
  formatPhoneNumber,
} from '@jane/business-admin/util';
import { Flex, Skeleton, Typography } from '@jane/shared/reefer';

import { CardSection } from '../../../../CardSection';
import { EditableCard } from '../../../../EditableCard';
import { SmsSettingsModal } from './SmsSettingsModal';

export const PROVIDER_DISPLAY_MAPPING: { [key: string]: string } = {
  cm: 'CM',
  twilio: 'Twilio',
};

const LoadingContent = () => (
  <Flex gap={24}>
    <Flex width="100%">
      <CardSection label="" isLoading={true}>
        <Skeleton animate height="fit-content" width="100%">
          <Skeleton.Bone
            width={LARGE_SKELETON_WIDTH}
            height={BODY_LINE_HEIGHT}
            mt={8}
          />
        </Skeleton>
      </CardSection>
    </Flex>
    <Flex width="100%">
      <CardSection label="" isLoading={true}>
        <Skeleton animate height="fit-content" width="100%">
          <Skeleton.Bone
            width={LARGE_SKELETON_WIDTH}
            height={BODY_LINE_HEIGHT}
            mt={8}
          />
        </Skeleton>
      </CardSection>
    </Flex>
    <Flex width="100%">
      <CardSection label="" isLoading={true}>
        <Skeleton animate height="fit-content" width="100%">
          <Skeleton.Bone
            width={LARGE_SKELETON_WIDTH}
            height={BODY_LINE_HEIGHT}
            mt={8}
          />
        </Skeleton>
      </CardSection>
    </Flex>
  </Flex>
);

export const SmsSettingsCard = () => {
  const { storeId } = useContext(StoreDetailsContext);
  const { data: storeSettings, isFetching } = useStoreSettings(storeId);

  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.SmsSettings
  );

  return (
    <>
      <EditableCard
        title={'SMS settings'}
        isLoading={isFetching}
        showTextButton={
          !isFetching && !storeSettings?.store_sms_provider_setting
        }
        textButtonLabel="Enable SMS settings"
        onEdit={() => openModal()}
      >
        {isFetching || !storeSettings ? (
          <LoadingContent />
        ) : !storeSettings?.store_sms_provider_setting ? (
          <Typography>
            SMS settings have not been enabled for this store.
          </Typography>
        ) : (
          <Flex gap={24}>
            <Flex width="100%">
              <CardSection label="Provider">
                <Typography>
                  {
                    PROVIDER_DISPLAY_MAPPING[
                      storeSettings.store_sms_provider_setting.provider
                    ]
                  }
                </Typography>
              </CardSection>
            </Flex>
            <Flex width="100%">
              <CardSection label="Token">
                <Typography>
                  {storeSettings.store_sms_provider_setting.token}
                </Typography>
              </CardSection>
            </Flex>
            <Flex width="100%">
              <CardSection label="From phone number">
                <Typography>
                  {formatPhoneNumber(
                    storeSettings.store_sms_provider_setting.from_phone
                  )}
                </Typography>
              </CardSection>
            </Flex>
            {storeSettings.store_sms_provider_setting.meta &&
              storeSettings.store_sms_provider_setting.meta['account_sid'] && (
                <Flex width="100%">
                  <CardSection label="Account SID">
                    <Typography>
                      {
                        storeSettings.store_sms_provider_setting.meta[
                          'account_sid'
                        ]
                      }
                    </Typography>
                  </CardSection>
                </Flex>
              )}
          </Flex>
        )}
      </EditableCard>
      {storeSettings && (
        <SmsSettingsModal
          open={modalOpen}
          closeModal={closeModal}
          store={storeSettings.store}
          setting={storeSettings.store_sms_provider_setting}
        />
      )}
    </>
  );
};

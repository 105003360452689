import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  tSpecialDetailResponse,
  tSpecialsIndexResponse,
} from '@jane/business-admin/types';
import type {
  SpecialsIndexResponse,
  StoreSpecialV2,
} from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type { Id, StackingOptions, StoreSpecial } from '@jane/shared/models';
import { type Photo } from '@jane/shared/types';

import { STORES_URL } from './stores';

type fetchSpecialsProps = {
  exclude_global?: boolean;
  order?: string;
  page?: string;
  per_page?: string;
  query?: string;
  sort?: string;
  special_type?: string;
  status?: string;
  store_id?: string;
};

const fetchSpecials = async (
  params: fetchSpecialsProps
): Promise<SpecialsIndexResponse> => {
  const urlParams = new URLSearchParams();
  params.status && urlParams.append('status', params.status);
  params.query && urlParams.append('query', params.query);
  params.sort && urlParams.append('sort', params.sort);
  params.order && urlParams.append('order', params.order);
  params.special_type && urlParams.append('special_type', params.special_type);
  params.page && urlParams.append('page', params.page);
  params.per_page && urlParams.append('per_page', params.per_page);
  params.exclude_global &&
    urlParams.append('exclude_global', params.exclude_global.toString());

  const urlBase = params.store_id
    ? `/business/stores/${params.store_id}/specials`
    : '/business/specials';
  const url = `${urlBase}?${urlParams.toString()}`;
  const data = await fetchWithDecode(
    janeApiV2.get<SpecialsIndexResponse>(url),
    tSpecialsIndexResponse,
    url
  );

  return data;
};

export const useFetchSpecials = (params: Partial<fetchSpecialsProps>) =>
  useInfiniteQuery<SpecialsIndexResponse>({
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchSpecials({
        ...params,
        page: pageParam.toString(),
      });

      return {
        ...data,
        pageParam,
      };
    },
    queryKey: ['store_specials', params.store_id, params],
    getNextPageParam: (lastPage) => {
      const numPages = Math.ceil(
        lastPage?.meta.total / lastPage?.meta.per_page
      );
      const hasNextPage = lastPage?.meta.page < numPages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    staleTime: Infinity,
    useErrorBoundary: true,
  });

const toggleSpecialEnabled = async (
  storeId: string,
  specialId: string,
  enabled: boolean
): Promise<null> => {
  const url = `/business/stores/${storeId}/specials/${specialId}`;

  return await janeApiV2.put<null>(url, {
    enabled,
  });
};

export const useToggleSpecialEnabled = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      enabled,
      specialId,
    }: {
      enabled: boolean;
      specialId: string;
    }) => toggleSpecialEnabled(storeId, specialId, enabled),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['current_specials_legacy', storeId]);
      queryClient.resetQueries(['store_specials']);
    },
  });
};

const fetchStoreSpecial = async (
  storeId: string,
  specialId: string | number
): Promise<StoreSpecialV2> => {
  const url = `${STORES_URL}/${storeId}/specials/${specialId}`;
  const data = await fetchWithDecode(
    janeApiV2.get<{ special: StoreSpecialV2 }>(url),
    tSpecialDetailResponse,
    url
  );
  return data.special;
};
export const useFetchStoreSpecial = (
  storeId: string,
  specialId: string | number,
  disabled?: boolean
) =>
  useQuery({
    queryKey: ['store_specials', storeId, specialId],
    queryFn: () => fetchStoreSpecial(storeId, specialId),
    enabled: !disabled,
    // Don't keep cached data, causes form defaultValues to be outdated
    cacheTime: 0,
  });

type ValidatePromoCodeParams = {
  promoCode: string;
  specialId?: string;
};
const validatePromoCode = (
  storeId: string,
  promoCode: string,
  specialId?: string
): Promise<any> => {
  const urlParams = new URLSearchParams();
  promoCode && urlParams.append('promo_code', promoCode);
  specialId && urlParams.append('special_id', specialId);

  return janeApiV2.get(
    `${STORES_URL}/${storeId}/specials/validate_promo_code?${urlParams.toString()}`
  );
};
export const useValidatePromoCode = (storeId: string, specialId?: string) =>
  useMutation({
    mutationKey: ['validate_promo_code', storeId, specialId],
    mutationFn: ({ promoCode, specialId }: ValidatePromoCodeParams) =>
      validatePromoCode(storeId, promoCode, specialId),
  });

interface CreateSpecialResponse {
  special: StoreSpecial;
}

export type UpdateSpecialParams = Omit<StoreSpecialV2, 'photo'> & {
  enabled_date_end: string | null | undefined;
  enabled_date_start: string | null | undefined;
  end_date: string | null | undefined;
  is_duplicate: boolean;
  photo_attributes: {
    _destroy: boolean | 0 | null | undefined;
    file: Photo;
    id: number | null | undefined;
  };
  start_date: string | null | undefined;
  use_store_close_time: boolean;
};

const createSpecial = (
  storeId: string,
  special: UpdateSpecialParams
): Promise<CreateSpecialResponse> =>
  janeApiV2.post(`${STORES_URL}/${storeId}/specials`, {
    ...special,
  });
export const useCreateSpecial = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateSpecialParams) => createSpecial(storeId, params),
    mutationKey: ['store_specials', storeId],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['current_specials_legacy', storeId]);
      queryClient.resetQueries(['store_specials', storeId]);
    },
  });
};

const updateSpecial = (
  storeId: Id,
  id: Id,
  special: UpdateSpecialParams
): Promise<CreateSpecialResponse> =>
  janeApiV2.patch(`${STORES_URL}/${storeId}/specials/${id}`, {
    ...special,
  });
export const useUpdateSpecial = (storeId: Id, id: Id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: UpdateSpecialParams) =>
      updateSpecial(storeId, id, params),
    mutationKey: ['store_specials', storeId, id],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['current_specials_legacy', storeId]);
      queryClient.resetQueries(['store_specials']);
    },
  });
};

export interface BulkUpdateSpecialParams {
  archive?: boolean;
  attributes?: { enabled?: boolean; stacking_setting?: StackingOptions };
  special_ids: Id[];
}
export interface BulkUpdateResponse {
  success: string[];
}

const bulkUpdateSpecial = (
  storeId: Id,
  params: BulkUpdateSpecialParams
): Promise<BulkUpdateResponse> =>
  janeApiV2.patch(`${STORES_URL}/${storeId}/specials/bulk`, params);
export const useBulkUpdateSpecial = (storeId: Id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: BulkUpdateSpecialParams) =>
      bulkUpdateSpecial(storeId, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      // NOTE: No idea why reset actually clears the table data, but invalidate keeps old entries around
      queryClient.resetQueries(['current_specials_legacy', storeId]);
      queryClient.resetQueries(['store_specials', storeId]);
    },
  });
};

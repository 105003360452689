import take from 'lodash/take';

import type {
  DeliveryGeofenceV2,
  FulfillmentConfig,
} from '@jane/business-admin/types';

import { GeofenceSettingItem } from '../../GeofenceSettingItem';
import { SeeAllButton } from './SeeAllButton';

export const GeofencesList = ({
  geofenceData,
  onClickSeeAll,
  config,
}: {
  config: FulfillmentConfig;
  geofenceData: DeliveryGeofenceV2[];
  onClickSeeAll: () => void;
}) => {
  const firstThreeGeofences = take(geofenceData, 3);

  return (
    <>
      <ul style={{ marginTop: 24 }}>
        {firstThreeGeofences.map((geofence, index) => (
          <GeofenceSettingItem
            key={index}
            geofence={geofence}
            mb={index !== 2 ? 24 : 0}
            config={config}
          />
        ))}
      </ul>
      {geofenceData.length > 3 && <SeeAllButton onClick={onClickSeeAll} />}
    </>
  );
};

import type { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ManagerRegistrationForm } from '@jane/business-admin/components';
import { useInvitation } from '@jane/business-admin/data-access';
import { Card, Flex, JaneLogo, Typography } from '@jane/shared/reefer';

const FormWrapper = ({ children }: { children: ReactNode }) => (
  <Flex justifyContent="center">
    <Card my={128} width={756}>
      <Card.Content background="grays-white">
        <Flex m={96} flexDirection="column" justifyContent="center">
          <Flex role="row" justifyContent="center">
            <JaneLogo size="lg" />
          </Flex>
          <Flex role="row" justifyContent="center" mt={-24} mb={40}>
            <Typography variant="caps-bold">BUSINESS</Typography>
          </Flex>
          <Flex mb={40} flexDirection="column" gap={16}>
            <Typography>
              You&apos;ve been invited to use the Jane Business Dashboard.
              Complete the info below to get up and running on your store&apos;s
              platform.
            </Typography>
          </Flex>
          {children}
        </Flex>
      </Card.Content>
    </Card>
  </Flex>
);

export const ManagerRegistration = () => {
  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get('invitation_token') ?? '';
  const { data: invitation, isFetching } = useInvitation(invitationToken ?? '');

  return (
    <FormWrapper>
      <ManagerRegistrationForm
        invitationToken={invitationToken}
        staffMember={invitation}
        isLoading={isFetching}
      />
    </FormWrapper>
  );
};

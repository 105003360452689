export enum Permission {
  EditCheckoutAgreements = 'edit_checkout_agreements',
  EditClientRole = 'edit_client_role',
  EditEmbedConfigs = 'edit_embed_configs',
  EditMenuCustomizations = 'edit_menu_customizations',
  EditPrinters = 'edit_printers',
  EditProducts = 'edit_products',
  EditReservations = 'edit_reservations',
  EditSms = 'edit_sms',
  EditSpecials = 'edit_specials',
  EditStaff = 'edit_staff',
  EditStore = 'edit_store',
  EditUnpublishedProducts = 'edit_unpublished_products',
  ViewAnalytics = 'view_analytics',
}

import styled from '@emotion/styled';

import type { BloomStore } from '@jane/business-admin/types';
import { Flex, Tag, Typography } from '@jane/shared/reefer';

const StyledFlex = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
  paddingBottom: 24,

  '&:last-child': {
    borderBottom: 0,
    paddingBottom: 0,
  },
}));

export const StoreSection = ({ store }: { store: BloomStore }) => {
  const storeAddress = `${store.address}, ${store.city}, ${store.state} ${store.zip}`;
  return (
    <StyledFlex flexDirection="column" pl={24} pt={24}>
      <Typography variant="body-bold">{store.name}</Typography>
      <Flex mt={8}>
        <Tag label={store.recreational ? 'Rec' : 'Med'} />
        <Typography ml={8} color="grays-mid">
          {storeAddress}
        </Typography>
      </Flex>
    </StyledFlex>
  );
};

import { useEffect, useMemo } from 'react';

import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Form, useFormContext } from '@jane/shared/reefer';

export const QualifiedGroup = ({
  name,
  options,
}: SpecialTypeProps['qualified_group']) => {
  const { getValues, watch, setValue } = useFormContext();
  const qualifiedGroupValue = getValues('conditions.qualified_group');
  const qualifiedGroupWatch = watch('conditions.qualified_group');
  const specialTypeWatch = watch('special_type');

  const qualifiedGroupType = useMemo(
    () => qualifiedGroupWatch?.type || qualifiedGroupValue?.type,
    [qualifiedGroupWatch?.type, qualifiedGroupValue?.type]
  );

  const isSenior = useMemo(
    () => !!(qualifiedGroupType && qualifiedGroupType === 'senior'),
    [qualifiedGroupType]
  );

  useEffect(() => {
    if (!qualifiedGroupType && specialTypeWatch === 'qualified_group') {
      setValue('conditions.qualified_group.type', 'senior');
    }
  }, []);

  return (
    <Flex justifyContent="space-between" width="100%" gap={24}>
      <Flex width="100%" gap={24} mb={24}>
        <Form.SelectField
          width={isSenior ? '100%' : '50%'}
          name={`${name}.type` || ''}
          label="Qualified group"
          options={options || []}
        />
        {isSenior ? (
          <Form.NumberField
            name={`${name}.required_age`}
            label="Age requirement"
            required
            width="100%"
          />
        ) : null}
      </Flex>
    </Flex>
  );
};

import type { CustomLabel } from '@jane/business-admin/types';

export const hasDuplicateCustomLabels = (productLabels: CustomLabel[]) => {
  const seenPairs = new Set();
  return productLabels.some(({ product_type, product_subtype }) => {
    const pair = `${product_type}-${product_subtype}`;
    if (seenPairs.has(pair)) {
      return true;
    } else {
      seenPairs.add(pair);
      return false;
    }
  });
};

export const parseProductLabels = (productLabels: CustomLabel[]) =>
  productLabels.reduce<CustomLabel[]>(
    (
      newArray: CustomLabel[],
      { product_subtype, ...restOfLabel }: CustomLabel
    ) => {
      const newLabel = {
        ...restOfLabel,
        ...(product_subtype !== 'all' && { product_subtype }),
      };
      if (restOfLabel.custom_label) {
        newArray.push(newLabel);
      }
      return newArray;
    },
    []
  );

import styled from '@emotion/styled';
import type {
  RowSelectionState,
  Table as TanstackTable,
} from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useProductSearchFilters } from '@jane/business-admin/data-access';
import { ProductsTableContext } from '@jane/business-admin/providers';
import {
  EventNames,
  blankArrayOfLength,
  track,
} from '@jane/business-admin/util';
import { Box, Button, Flex, Link, Skeleton } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import { EmptyState } from './EmptyStates';
import { TableFilter } from './TableFilter';

export const ROW_HEIGHT = '72';
const StyledRow = styled(Table.Row)(
  ({ useDefaultCursor }: { useDefaultCursor?: boolean }) => ({
    height: `${ROW_HEIGHT}px`,
    cursor: useDefaultCursor ? 'default' : 'pointer',
  })
);

const TableWrapper = styled.div({
  overflowX: 'auto',
});

const TableWithHorizontalScrollBar = styled(Table)({
  overflowY: 'scroll',
  maxHeight: '1000px',
});

const reduceAndSortFilterData = (
  productData: string[],
  filterData: string[]
) => {
  return productData
    .reduce(
      (acc: string[], curr: string | null) =>
        curr === null || acc.includes(curr) || curr === ''
          ? acc
          : [...acc, curr],
      [...filterData]
    )
    .filter(Boolean)
    .sort((a: string, b: string) => a.localeCompare(b));
};

const length10Array = blankArrayOfLength(10);

const LoadingRow = ({
  index = 0,
  table,
}: {
  index?: number;
  table: TanstackTable<any>;
}) => (
  <StyledRow key={`${index}-row-loading`}>
    {table.getAllColumns().map((column) => (
      <Table.Cell key={`${column.id}-loading-cell`}>
        <Skeleton animate>
          <Skeleton.Bone />
        </Skeleton>
      </Table.Cell>
    ))}
  </StyledRow>
);

const LinkWrapper = styled.div({ minWidth: '80px' });

export const BaseProductTable = ({
  allIds,
  fetchNextPage,
  handleRowClick,
  hasNextPage,
  isBulkEditTable,
  isFetched,
  isFetching,
  isSuccess,
  productData,
  table,
  tableType,
}: {
  allIds: RowSelectionState;
  fetchNextPage: () => void;
  handleRowClick: (
    row: any,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;
  hasNextPage: boolean;
  isBulkEditTable: boolean;
  isFetched: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  productData: any;
  table: TanstackTable<any>;
  tableType: 'published' | 'hidden' | 'unpublished';
}) => {
  const { canEditProducts } = useContext(ProductsTableContext);

  const { id = '' } = useParams<'id'>();
  const loadNextRef = useRef(null);

  const { data: filterData, isFetching: filtersFetching } =
    useProductSearchFilters(id, undefined);

  const filters = {
    name: [''],
    brand:
      !filtersFetching && isFetched && filterData
        ? reduceAndSortFilterData(
            productData.map((product: any) => product.brand),
            filterData?.filters?.brands
          )
        : [],
    category:
      !filtersFetching && isFetched && filterData
        ? reduceAndSortFilterData(
            productData.map((product: any) => product.category),
            filterData?.filters?.categories
          )
        : [],
    subcategory:
      !filtersFetching && isFetched && filterData
        ? reduceAndSortFilterData(
            productData.map((product: any) => product.subcategory),
            []
          )
        : [],
    lineage:
      reduceAndSortFilterData(
        productData.map((product: any) => product.lineage),
        []
      ) || [],
    status: ['Publishing', 'Unpublished', 'Invalid data', 'Archived'],
    custom_rows: filterData?.filters?.custom_rows || [],
    content_type: ['Edited', 'Jane defined', 'Store defined'],
  };

  type FilterKeys = keyof typeof filters;

  const isFilterKey = (k: any): k is FilterKeys => {
    return Object.keys(filters).includes(`${k}`);
  };

  const isInvalidColumnFilter = (id: string, value: any) => {
    if (id === 'status' && value?.includes('unpublished')) {
      return true;
    }

    return false;
  };

  const hasFilters = () => {
    const filtersWithValues: any[] = [];

    const cols = table.getAllColumns();
    cols.forEach((col) => {
      return (
        !isInvalidColumnFilter(col.id, col.getFilterValue()) &&
        filtersWithValues.push(col.getFilterValue())
      );
    });

    return (
      filtersWithValues.filter((filter) => {
        if (filter) {
          return filter.length > 0;
        } else {
          return null;
        }
      }).length > 0
    );
  };

  const clearFilters = () => {
    const cols = table.getAllColumns();
    cols.forEach((col) => {
      col.setFilterValue('');
    });
  };

  // Initialize infinite scroll observer
  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        const lastProduct = entries[0];

        if (lastProduct.isIntersecting) {
          fetchNextPage();
          observer.unobserve(lastProduct.target);
        }
      },
      { rootMargin: '300px' }
    );
  }, [fetchNextPage]);

  // When searchData changes re-observe last product
  useEffect(() => {
    if (loadNextRef.current) {
      observer.observe(loadNextRef.current);
    }
    return () => observer.disconnect();
  }, [observer, productData]);

  return (
    <>
      <Flex justifyContent="space-between" mx={64}>
        <Flex>
          {table.getHeaderGroups().map((headerGroup) => (
            <Flex key={headerGroup.id} flexWrap="wrap" gap={16}>
              {headerGroup.headers.map((header) => {
                const key = header.id?.toString() || '';
                const isKeyAString = typeof key === 'string';
                if (!isKeyAString || !isFilterKey(key)) {
                  return;
                }

                return (
                  header.column.getCanFilter() && (
                    <TableFilter
                      column={header.column}
                      data={filters[key]}
                      key={header.column.columnDef.footer?.toString() || ''}
                    />
                  )
                );
              })}
              {hasFilters() ? (
                <Button
                  key="clearfilters"
                  label="Clear filters"
                  onClick={clearFilters}
                  variant="minimal"
                />
              ) : null}
            </Flex>
          ))}
        </Flex>
        {isBulkEditTable ? (
          <Flex alignItems="center">
            <LinkWrapper>
              <Link
                onClick={() => {
                  table.setRowSelection(allIds);
                  track({
                    event: EventNames.ClickedButton,
                    button_label: 'Select all',
                    modal_name: `${tableType} bulk edit`,
                  });
                }}
              >
                Select all
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link
                onClick={() => {
                  table.setRowSelection({});
                  track({
                    event: EventNames.ClickedButton,
                    button_label: 'Select none',
                    modal_name: `${tableType} bulk edit`,
                  });
                }}
              >
                Select none
              </Link>
            </LinkWrapper>
          </Flex>
        ) : null}
      </Flex>
      <TableWrapper>
        {!isFetching && Object.keys(allIds).length === 0 ? (
          <Box mt={24}>
            <EmptyState variant="noProductsFound" />
          </Box>
        ) : (
          <TableWithHorizontalScrollBar freezeFirstColumn scrollable>
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeaderCell key={header.id}>
                      {header.isPlaceholder ? null : (
                        <Box
                          minWidth={
                            header.column.columnDef.footer === 'Content'
                              ? '150px'
                              : header.column.columnDef.id === 'name'
                              ? '400px'
                              : '100px'
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                      )}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>

            <Table.Body>
              {isFetching
                ? length10Array.map((i) => (
                    <LoadingRow key={i} index={i} table={table} />
                  ))
                : table.getRowModel().rows.map((row) => (
                    <StyledRow
                      key={row.id}
                      onClick={(e) => handleRowClick(row.original, e)}
                      useDefaultCursor={!canEditProducts}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Table.Cell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        );
                      })}
                    </StyledRow>
                  ))}
            </Table.Body>

            {!isFetching ? (
              <>
                {hasNextPage && (
                  <Table.Body>
                    <LoadingRow table={table} />
                  </Table.Body>
                )}
                <tfoot ref={loadNextRef}></tfoot>
              </>
            ) : (
              <></>
            )}
          </TableWithHorizontalScrollBar>
        )}
      </TableWrapper>
    </>
  );
};

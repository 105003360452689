import styled from '@emotion/styled';
import { Suspense, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  AnalyticsSideNav,
  RumErrorBoundary as ErrorBoundary,
  ErrorView,
} from '@jane/business-admin/components';
import {
  useManager,
  useThoughtspotLiveboards,
} from '@jane/business-admin/data-access';
import { useThoughtspot } from '@jane/business-admin/hooks';
import { AnalyticsPageProvider } from '@jane/business-admin/providers';
import { ThoughtspotBucketNames } from '@jane/business-admin/types';
import { spacing } from '@jane/shared/reefer-emotion';

const Wrapper = styled.div({
  display: 'flex',
  ...spacing({ px: 40, py: 12, pr: 24 }),
});

const Main = styled.div({
  width: '85%',
});

export const Analytics = () => {
  const navigate = useNavigate();
  const { data: managerData } = useManager();
  const username = useMemo(
    () => `${managerData?.manager?.email}-bizdash-prod`,
    [managerData?.manager?.email]
  );
  const {
    data: liveboardsResponse,
    isFetching: isFetchingLiveboards,
    isFetched: liveboardsFetched,
  } = useThoughtspotLiveboards({ enabled: !!managerData?.manager?.id });
  const { authState, isAuthenticating } = useThoughtspot({
    username,
    readyToAuth: liveboardsFetched && !!username,
  });

  useEffect(() => {
    if (!liveboardsResponse?.thoughtspot_user_buckets) return;
    let redirectPath;
    const buckets = liveboardsResponse.thoughtspot_user_buckets;

    if (
      buckets.includes(ThoughtspotBucketNames.OFF_MENU) &&
      !buckets.includes(ThoughtspotBucketNames.DEFAULT_LIVEBOARDS)
    ) {
      redirectPath = '/analytics/off-menu-advertising';
    } else {
      redirectPath = '/analytics/kpis';
    }

    navigate(redirectPath, { replace: true });
  }, [JSON.stringify(liveboardsResponse?.thoughtspot_user_buckets)]);

  return (
    <Suspense
      fallback={
        <Wrapper>
          <AnalyticsSideNav />
        </Wrapper>
      }
    >
      <Wrapper>
        <AnalyticsPageProvider
          authState={authState}
          username={username}
          authorizedLiveboards={liveboardsResponse?.authorized_liveboards ?? {}}
          navLiveboards={liveboardsResponse?.nav_liveboards ?? {}}
          isAuthenticating={isAuthenticating}
          isFetchingLiveboards={isFetchingLiveboards}
        >
          <AnalyticsSideNav />
          <Main>
            <ErrorBoundary fallback={ErrorView} scope="analytics">
              <Outlet />
            </ErrorBoundary>
          </Main>
        </AnalyticsPageProvider>
      </Wrapper>
    </Suspense>
  );
};

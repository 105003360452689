import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLogout } from '@jane/business-admin/data-access';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { EventNames, normalizePath, track } from '@jane/business-admin/util';

export const useLogoutAndNavigateToLogin = () => {
  const { pathname } = useLocation();
  const { storeId } = useContext(StoreDetailsContext);
  const { mutate: logout, isSuccess: logoutSuccess } = useLogout();

  useEffect(() => {
    if (logoutSuccess) {
      track({
        event: EventNames.LoggedOut,
        // TODO: storeId is always an empty string here
        from_url: normalizePath(pathname, storeId),
      });
    }
  }, [logoutSuccess, storeId]);

  return () => {
    logout();
  };
};

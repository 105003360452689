/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Sparkle16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Sparkle 16">
      <path
        fill="#0E0E0E"
        d="M9.617 6.29c.108-.387.658-.387.766 0l.356 1.265a3.186 3.186 0 0 0 2.206 2.207l1.264.355c.388.108.388.658 0 .766l-1.264.356a3.186 3.186 0 0 0-2.207 2.206l-.355 1.264c-.108.388-.658.388-.766 0l-.355-1.264a3.186 3.186 0 0 0-2.207-2.207l-1.264-.355c-.388-.108-.388-.658 0-.766l1.264-.355a3.186 3.186 0 0 0 2.207-2.207l.355-1.264Z"
        className="Star 10"
      />
      <path
        fill="#0E0E0E"
        d="M3.744 2.694c.073-.259.44-.259.512 0l.236.843c.2.713.758 1.27 1.471 1.47l.843.237c.259.073.259.44 0 .512l-.843.236c-.713.2-1.27.758-1.47 1.471l-.237.843c-.073.259-.44.259-.512 0l-.236-.843a2.124 2.124 0 0 0-1.471-1.47l-.843-.237c-.259-.073-.259-.44 0-.512l.843-.236c.713-.2 1.27-.758 1.47-1.471l.237-.843Z"
        className="Star 11"
      />
      <path
        fill="#0E0E0E"
        d="M12.287 1.161c.06-.215.366-.215.426 0l.197.703a1.77 1.77 0 0 0 1.226 1.226l.703.197c.215.06.215.366 0 .426l-.703.197a1.77 1.77 0 0 0-1.226 1.226l-.197.703c-.06.215-.366.215-.426 0l-.197-.703a1.77 1.77 0 0 0-1.226-1.226l-.703-.197c-.215-.06-.215-.366 0-.426l.703-.197a1.77 1.77 0 0 0 1.226-1.226l.197-.703Z"
        className="Star 12"
      />
    </g>
  </svg>
);

const Sparkle24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Sparkle 24">
      <path
        fill="#0E0E0E"
        d="M14.425 9.436c.163-.581.987-.581 1.15 0l.533 1.897a4.779 4.779 0 0 0 3.309 3.31l1.897.532c.581.163.581.987 0 1.15l-1.897.533a4.779 4.779 0 0 0-3.31 3.309l-.532 1.897c-.163.581-.987.581-1.15 0l-.533-1.897a4.779 4.779 0 0 0-3.309-3.31l-1.897-.532c-.581-.163-.581-.987 0-1.15l1.897-.533a4.779 4.779 0 0 0 3.31-3.309l.532-1.897Z"
        className="Star 10"
      />
      <path
        fill="#0E0E0E"
        d="M5.617 4.04c.108-.387.658-.387.766 0l.355 1.265a3.186 3.186 0 0 0 2.207 2.207l1.264.355c.388.108.388.658 0 .766l-1.264.355a3.186 3.186 0 0 0-2.207 2.207l-.355 1.264c-.108.388-.658.388-.766 0l-.355-1.264a3.186 3.186 0 0 0-2.207-2.207l-1.264-.355c-.388-.108-.388-.658 0-.766l1.264-.355a3.186 3.186 0 0 0 2.207-2.207l.355-1.264Z"
        className="Star 11"
      />
      <path
        fill="#0E0E0E"
        d="M18.43 1.742c.091-.323.549-.323.64 0l.295 1.054c.25.892.947 1.588 1.839 1.839l1.054.296c.323.09.323.548 0 .638l-1.054.296a2.655 2.655 0 0 0-1.839 1.839l-.295 1.054c-.091.323-.549.323-.64 0l-.295-1.054a2.655 2.655 0 0 0-1.839-1.839l-1.054-.296c-.323-.09-.323-.548 0-.638l1.054-.296a2.655 2.655 0 0 0 1.839-1.839l.295-1.054Z"
        className="Star 12"
      />
    </g>
  </svg>
);

const Sparkle32 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g className="Sparkle 32">
      <path
        fill="#0E0E0E"
        d="M19.233 12.581c.218-.775 1.316-.775 1.534 0l.71 2.53a6.371 6.371 0 0 0 4.412 4.412l2.53.71c.775.218.775 1.316 0 1.534l-2.53.71a6.371 6.371 0 0 0-4.412 4.412l-.71 2.53c-.218.775-1.316.775-1.534 0l-.71-2.53a6.371 6.371 0 0 0-4.412-4.412l-2.53-.71c-.775-.218-.775-1.316 0-1.534l2.53-.71a6.371 6.371 0 0 0 4.412-4.412l.71-2.53Z"
        className="Star 10"
      />
      <path
        fill="#0E0E0E"
        d="M7.489 5.387c.145-.516.877-.516 1.022 0l.474 1.687a4.248 4.248 0 0 0 2.941 2.941l1.687.474c.516.145.516.877 0 1.022l-1.687.474a4.248 4.248 0 0 0-2.941 2.941l-.474 1.687c-.145.516-.877.516-1.022 0l-.474-1.687a4.248 4.248 0 0 0-2.941-2.941l-1.687-.474c-.516-.145-.516-.877 0-1.022l1.687-.474a4.248 4.248 0 0 0 2.941-2.941l.474-1.687Z"
        className="Star 11"
      />
      <path
        fill="#0E0E0E"
        d="M24.574 2.323c.12-.43.731-.43.852 0l.395 1.405a3.54 3.54 0 0 0 2.45 2.451l1.406.395c.43.12.43.731 0 .852l-1.405.395a3.54 3.54 0 0 0-2.451 2.45l-.395 1.406c-.12.43-.731.43-.852 0l-.395-1.405a3.54 3.54 0 0 0-2.45-2.451l-1.406-.395c-.43-.12-.43-.731 0-.852l1.405-.395a3.54 3.54 0 0 0 2.451-2.45l.395-1.406Z"
        className="Star 12"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Sparkle16,
  md: Sparkle24,
  lg: Sparkle32,
  xl: Sparkle32,
  xxl: Sparkle32,
  xxxl: Sparkle32,
};

export const SparkleIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'sparkle-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import styled from '@emotion/styled';

import { Flex, Link, Typography } from '@jane/shared/reefer';

export const TextWrapper = styled(Flex)({
  overflow: 'hidden',
  position: 'relative',
});

export const SlidingText = styled(Typography)<{ currentIndex: number }>(
  ({ currentIndex }) => ({
    transform: `translateX(-${currentIndex * 100}%)`,
    transition: 'transform 0.5s ease-in-out',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  })
);

export const EllipsisLink = styled(Link)({
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
});

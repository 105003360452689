import styled from '@emotion/styled';
import type { RowSelectionState } from '@tanstack/react-table';
import type { ReactNode } from 'react';

import { ModalNames } from '@jane/business-admin/util';
import { Box, Modal } from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';

const ModalHeaderWrapper = styled.div(({ theme }) => ({
  '& > [data-id="modal-header"]': {
    backgroundColor: theme.colors.brand.purple.main,
    color: theme.colors.text.inverse,
    '& h2': {
      color: 'inherit',
    },
  },
}));

export const BulkEditModal = ({
  headerButton,
  onClose,
  open,
  productTable,
  selectedProducts,
}: {
  headerButton: ReactNode;
  onClose: () => void;
  open: boolean;
  productTable: ReactNode;
  selectedProducts: RowSelectionState;
}) => {
  const isDirty = Object.values(selectedProducts).length > 0;

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={onClose}
      hasChanges={isDirty}
      variant="full-screen"
      background="text-inverse"
      modalName={ModalNames.BulkEditProducts}
    >
      <>
        <ModalHeaderWrapper>
          <Modal.Header title="Select products" actions={<>{headerButton}</>} />
        </ModalHeaderWrapper>
        <Modal.Content padding={false}>
          <Box mt={40}>{productTable}</Box>
        </Modal.Content>
      </>
    </ConfirmWrapperWithTracking>
  );
};

import { useContext, useEffect, useMemo, useState } from 'react';

import {
  useDeleteGeofence,
  useStoreSettings,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { DeliveryGeofenceV2 } from '@jane/business-admin/types';
import { EventNames, ModalNames, track } from '@jane/business-admin/util';
import { ConfirmDeleteModal } from '@jane/shared/components';
import { Banner, Button, Flex } from '@jane/shared/reefer';

import {
  GeofenceLoadingItem,
  GeofenceSettingItem,
} from '../../../GeofenceSettingItem';
import { GeofenceModal } from './GeofenceModal';

export const GeofenceSettings = () => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error deleting geofence. Please try again.'
  );

  const { storeId } = useContext(StoreDetailsContext);
  const { mutateAsync: deleteGeofence } = useDeleteGeofence(storeId);
  const { isFetching, data: storePayload } = useStoreSettings(storeId);

  const deliveryGeofences = useMemo(() => {
    if (storePayload?.delivery_geofences) {
      return storePayload.delivery_geofences;
    }
    return [];
  }, [JSON.stringify(storePayload?.delivery_geofences)]);

  const [deletingId, setDeletingId] = useState<number>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [displayErrorBanner, setDisplayErrorBanner] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setIsDeleting(false);
    }
  }, [isFetching]);

  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.Geofence
  );
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [selectedGeofence, setSelectedGeofence] =
    useState<DeliveryGeofenceV2 | null>(null);

  const openGeofenceModal = (geofence: DeliveryGeofenceV2 | null) => {
    setDisplayErrorBanner(false);
    openModal(`${geofence ? 'Edit' : 'Add'} Geofence`);
    setSelectedGeofence(geofence);
  };

  const handleDeleteGeofence = (geofenceId: number) => {
    setDisplayErrorBanner(false);
    setDeletingId(geofenceId);
    setConfirmDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    track({
      event: EventNames.DeleteStoreEntity,
      modal_name: ModalNames.DeliverySettings,
      entity_name: 'geofences',
    });
    setIsDeleting(true);
    setConfirmDeleteModalOpen(false);
    if (deletingId) {
      return catchSubmitErrors({
        submitMethod: () => deleteGeofence(deletingId),
        requestData: { id: deletingId },
        onValidationError: () => null,
        callback: () => {
          setDisplayErrorBanner(true);
          setIsDeleting(false);
        },
      });
    }
  };

  return (
    <>
      <Flex flexDirection="column" mt={40} as="ul">
        {displayErrorBanner && (
          <Banner
            variant="error"
            label="Error deleting geofence. Please try again."
            full
            mb={24}
          />
        )}
        {deliveryGeofences.map((geofence: any, index: number) =>
          isDeleting || isFetching ? (
            <GeofenceLoadingItem key={`geofence_loading_${index}`} />
          ) : (
            <GeofenceSettingItem
              key={index}
              onEdit={() => openGeofenceModal(geofence)}
              onDelete={handleDeleteGeofence}
              readonly={false}
              geofence={geofence}
              index={index}
              mb={index !== deliveryGeofences.length - 1 ? 24 : 0}
              config={storePayload?.delivery_fulfillment_config}
            />
          )
        )}
        <Flex justifyContent="center" mt={24} as="li">
          <Button
            variant="secondary"
            label="Add geofence"
            onClick={() => openGeofenceModal(null)}
          />
        </Flex>
      </Flex>

      {modalOpen && (
        <GeofenceModal
          setOpen={() =>
            closeModal(`${selectedGeofence ? 'Edit' : 'Add'} Geofence`)
          }
          geofence={selectedGeofence}
        />
      )}
      {confirmDeleteModalOpen && (
        <ConfirmDeleteModal
          open={confirmDeleteModalOpen}
          setOpen={setConfirmDeleteModalOpen}
          confirmDelete={handleConfirmDelete}
        />
      )}
    </>
  );
};

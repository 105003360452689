import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { Store, StoreHours } from '@jane/shared/types';
import { parseApiTimezones } from '@jane/shared/util';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

const getDates = (
  schedule?: StoreHours,
  storeTimezone = '',
  yesterday = false
) => {
  const startTime = schedule?.period?.from;
  const endTime = schedule?.period?.to;

  if (startTime && endTime) {
    const startHour = Number(startTime.split(':')[0]);
    const startMinute = Number(startTime.split(':')[1]);

    const endHour = Number(endTime.split(':')[0]);
    const endMinute = Number(endTime.split(':')[1]);

    const formattedStartTime = dayjs()
      .tz(storeTimezone)
      .set('hour', startHour)
      .set('minute', startMinute)
      .subtract(yesterday ? 1 : 0, 'day');

    const formattedEndTime = dayjs()
      .tz(storeTimezone)
      .set('hour', endHour)
      .set('minute', endMinute)
      .subtract(yesterday ? 1 : 0, 'day');

    // ex. 8am-2am hours, 2am is technically "tomorrow".
    const adjustedEndTime = formattedStartTime.isSameOrAfter(formattedEndTime)
      ? formattedEndTime.add(1, 'day')
      : formattedEndTime;

    return [formattedStartTime, adjustedEndTime];
  }

  return [null, null];
};

export const isAcceptingOrders = (
  store: Store,
  reservationMode: 'delivery' | 'pickup' | 'curbside'
): boolean => {
  if (!store[reservationMode === 'delivery' ? 'delivery' : 'pickup'])
    return false;

  // If the store allows future day/off hours ordering, we don't need to parse the schedule,
  // we just make sure a schedule exists for this reservation mode.
  if (
    (store.allow_off_hours_ordering || store.allow_future_day_ordering) &&
    !!store[`${reservationMode}_hours`]
  ) {
    return true;
  }

  const storeTimezone = parseApiTimezones(store.time_zone_identifier);
  const now = dayjs().tz(storeTimezone);
  const today = now.day();
  const yesterday = now.subtract(1, 'day').day();

  const scheduleToday = store[`${reservationMode}_hours`]?.[today];
  const scheduleYesterday = store[`${reservationMode}_hours`]?.[yesterday];

  const [todayOpen, todayClose] = getDates(scheduleToday, storeTimezone);
  const [yesterdayOpen, yesterdayClose] = getDates(
    scheduleYesterday,
    storeTimezone,
    true
  );

  const openToday = !!todayOpen && !!todayClose;
  const openYesterday = !!yesterdayOpen && !!yesterdayClose;

  const lastCallInSeconds =
    reservationMode === 'pickup'
      ? store.last_call_interval
      : store[`${reservationMode}_last_call_interval`];

  if (
    openToday &&
    now.isBetween(
      todayOpen,
      todayClose.subtract(lastCallInSeconds || 0, 'seconds')
    )
  ) {
    return true;
  }

  // In some situations, we need to also check yesterday's schedule.
  // ex. A store is open Tue 8am-2am and it is currently 1am on Wed.
  if (
    openYesterday &&
    now.isBetween(
      yesterdayOpen,
      yesterdayClose.subtract(lastCallInSeconds || 0, 'seconds')
    )
  ) {
    return true;
  }

  return false;
};

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  useGetGlobalProductDetails,
  useGetProductDetails,
} from '@jane/business-admin/hooks';
import type { MenuProductForProductsTable } from '@jane/business-admin/types';
import { useToast } from '@jane/shared/reefer';

import { ProductSearchModalUpdated } from '../../../../ProductSearchModalUpdated';
import type { ProductSearchSelection } from '../../../../ProductSearchModalUpdated';
import { ConditionType } from '../form';
import { ConditionByModal } from './generic_components/ConditionByModal';
import type { Condition } from './generic_components/ConditionByModal';
import { LoadingCondition } from './generic_components/LoadingCondition';

export const ProductsConditions = ({
  fieldPrefix,
  isGlobalSpecial,
  type,
}: {
  fieldPrefix: string;
  isGlobalSpecial?: boolean;
  type: ConditionType.Products | ConditionType.ExcludeProducts;
}) => {
  const { setValue, watch } = useFormContext();
  const rulesWatch = watch(`${fieldPrefix}.rules`);

  const toast = useToast();

  const [productIds, setProductIds] = useState();
  const [productDetails, setProductDetails] = useState<Condition[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    ProductSearchSelection[]
  >([]);
  const [productSearchModalOpen, setProductSearchModalOpen] = useState(false);

  const includesOrExcludes =
    type === ConditionType.Products ? 'includes' : 'excludes';

  useEffect(() => {
    if (rulesWatch && rulesWatch[includesOrExcludes]) {
      // rules.includes and rules.excludes are arrays, so we pull all product_ids out of all the nested arrays
      const productIdsMap = rulesWatch[includesOrExcludes].reduce(
        (accumulator: number[], currentValue: { product_ids: number[] }) => {
          return currentValue.product_ids
            ? accumulator.concat(currentValue.product_ids)
            : accumulator;
        },
        []
      );
      setProductIds(productIdsMap);
    }
  }, [JSON.stringify(rulesWatch)]);

  const useGetProducts = (productIds: number[]) => {
    const {
      data: globalProductData,
      hasError: hasGlobalError,
      isLoading: isGlobalLoading,
    } = useGetGlobalProductDetails(productIds, isGlobalSpecial || false);

    const {
      data: productData,
      hasError: hasProductError,
      isLoading: isProductLoading,
    } = useGetProductDetails(productIds, false, !isGlobalSpecial);

    return {
      data: isGlobalSpecial ? globalProductData : productData,
      hasError: isGlobalSpecial ? hasGlobalError : hasProductError,
      isLoading: isGlobalSpecial ? isGlobalLoading : isProductLoading,
    };
  };

  const {
    data: productData,
    hasError,
    isLoading,
  } = useGetProducts(productIds || []);

  useEffect(() => {
    if (productData) {
      setProductDetails(
        productData.map((product) => ({
          menu_product_id: product['id'],
          value: product['product_id']?.toString() || '',
          label: product['name'],
        }))
      );
      setSelectedProducts(
        productData.map<ProductSearchSelection>((product) => ({
          id: product['id'].toString(),
          menu_product_id: product['id'],
          product_id: product.product_id?.toString() || '',
        }))
      );
    }
  }, [JSON.stringify(productData)]);

  useEffect(() => {
    if (hasError) {
      // TODO: Toast won't show up on top of a modal, what do we do instead?
      toast.add({
        label: 'Error getting product details',
        variant: 'error',
      });
    }
  }, [hasError]);

  const updateConditions = (data: any, fromModal?: boolean) => {
    let values;
    if (fromModal) {
      values = (data as MenuProductForProductsTable[]).map(({ product_id }) => {
        return Number(product_id);
      });
    } else {
      values = (data as Condition[]).map(({ value }) => Number(value));
    }

    const firstCondition =
      (rulesWatch[includesOrExcludes] && rulesWatch[includesOrExcludes][0]) ||
      {};
    setValue(
      `${fieldPrefix}.rules`,
      {
        ...rulesWatch,
        [includesOrExcludes]: [{ ...firstCondition, product_ids: values }],
      },
      { shouldDirty: true }
    );
  };

  return (
    <>
      {!productDetails ? (
        <LoadingCondition />
      ) : (
        <ConditionByModal
          onOpenModal={() => setProductSearchModalOpen(true)}
          type={type}
          conditions={productDetails}
          conditionsLoading={isLoading}
          onConditionsUpdate={(data) => updateConditions(data)}
          lastCondition={type === ConditionType.ExcludeProducts}
        />
      )}
      {productSearchModalOpen && (
        // TODO: This currently only shows "visible" products - will need an API change to fix that
        <ProductSearchModalUpdated
          isGlobalSpecial={isGlobalSpecial}
          closeModal={() => setProductSearchModalOpen(false)}
          onlyShowInStockProducts={false}
          onSubmit={(data) => updateConditions(data, true)}
          selectedProducts={selectedProducts}
        />
      )}
    </>
  );
};

import { useCallback, useRef } from 'react';

export const useLastToggled = <T,>() => {
  const lastToggled = useRef<T | null>(null);
  const setLastToggled = useCallback((val: T) => {
    lastToggled.current = val;
  }, []);

  const clearLastToggled = useCallback(() => {
    lastToggled.current = null;
  }, []);

  return {
    lastToggled: lastToggled.current,
    setLastToggled,
    clearLastToggled,
  } as const;
};

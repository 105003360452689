import { useContext, useMemo } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  BODY_LINE_HEIGHT,
  LARGE_SKELETON_WIDTH,
  MEDIUM_SKELETON_WIDTH,
  ModalNames,
  formatPhoneNumber,
} from '@jane/business-admin/util';
import {
  Button,
  Card,
  EditIcon,
  Flex,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';

import { CardSection } from '../../../../../CardSection';
import { StoreDetailsModal } from './StoreDetailsModal';

const LoadingSkeleton = () => (
  <Card.Content>
    <Flex p={24} flexDirection="column">
      <Flex justifyContent="space-between" alignItems="center" mb={24}>
        <Typography variant="header-bold">Details</Typography>
        <Button.Icon
          icon={<EditIcon />}
          variant="secondary"
          label="Edit"
          onClick={Function.prototype()}
        />
      </Flex>
      <Flex mb={24}>
        <Flex flexDirection="column" width="25%">
          <Skeleton animate height="fit-content" width="100%">
            <Skeleton.Bone
              width={LARGE_SKELETON_WIDTH}
              height={BODY_LINE_HEIGHT}
              mt={8}
            />
          </Skeleton>
        </Flex>
        <Flex flexDirection="column" width="25%">
          <Skeleton animate height="fit-content" width="100%">
            <Skeleton.Bone
              width={MEDIUM_SKELETON_WIDTH}
              height={BODY_LINE_HEIGHT}
              mt={8}
            />
            <Skeleton.Bone
              width={LARGE_SKELETON_WIDTH}
              height={BODY_LINE_HEIGHT}
              mt={8}
            />
            <Skeleton.Bone
              width={MEDIUM_SKELETON_WIDTH}
              height={BODY_LINE_HEIGHT}
              mt={8}
            />
          </Skeleton>
        </Flex>
        <Flex flexDirection="column" width="25%">
          <Skeleton animate height="fit-content" width="100%">
            <Skeleton.Bone
              width={LARGE_SKELETON_WIDTH}
              height={BODY_LINE_HEIGHT}
              mt={8}
            />
          </Skeleton>
        </Flex>
        <Flex flexDirection="column" width="25%">
          <Skeleton animate height="fit-content" width="100%">
            <Skeleton.Bone
              width={LARGE_SKELETON_WIDTH}
              height={BODY_LINE_HEIGHT}
              mt={8}
            />
          </Skeleton>
        </Flex>
      </Flex>
      <Flex flexDirection="column">
        <Skeleton animate height="fit-content" width="100%">
          <Skeleton.Bone
            width={LARGE_SKELETON_WIDTH}
            height={BODY_LINE_HEIGHT}
            mt={8}
          />
        </Skeleton>
      </Flex>
    </Flex>
  </Card.Content>
);

export const StoreDetailsCard = () => {
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const {
    data: storeSettings,
    isFetching,
    isLoading: isFirstLoad,
  } = useStoreSettings(storeId);

  const concatAddress = useMemo(() => {
    if (!storeSettings || !storeSettings.store) return '';

    const { store } = storeSettings;

    return `${store.address}${store.address2 ? `, ${store.address2}` : ''}`;
  }, [storeSettings]);

  const concatCityState = useMemo(() => {
    if (!storeSettings || !storeSettings.store) return '';

    const { store } = storeSettings;

    let addressString = '';
    if (store.city) {
      addressString += store.city;
    }
    if (store.state) {
      addressString += `, ${store.state}`;
    }
    if (store.zip) {
      addressString += ` ${store.zip}`;
    }
    return addressString;
  }, [storeSettings]);

  const hasOwnership = useMemo(() => {
    if (!storeSettings) return false;

    // Get truthy values, will always return at least 1 since the store id is in the object
    return (
      Object.values(storeSettings?.ownership_identification || {}).filter(
        (key) => key
      ).length > 1
    );
  }, [storeSettings]);

  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.StoreDetails
  );

  if (isFirstLoad || !storeSettings) return <LoadingSkeleton />;

  const { store } = storeSettings;

  return (
    <>
      {isFetching || !store ? (
        <LoadingSkeleton />
      ) : (
        <Card.Content
          background={!canEditStore ? 'grays-ultralight' : undefined}
        >
          <Flex p={24} flexDirection="column">
            <Flex justifyContent="space-between" alignItems="center" mb={24}>
              <Typography variant="header-bold">Details</Typography>

              {canEditStore && (
                <Button.Icon
                  icon={<EditIcon />}
                  variant="secondary"
                  label="Edit"
                  onClick={() => openModal()}
                />
              )}
            </Flex>
            <Flex mb={24}>
              <Flex flexDirection="column" pr={48}>
                <CardSection label="Type">
                  <Typography>
                    {store.recreational ? 'Recreational' : 'Medical'}
                  </Typography>
                </CardSection>
              </Flex>
              <Flex flexDirection="column" px={48}>
                <CardSection label="Address">
                  <Typography>{concatAddress}</Typography>
                  <Typography>{concatCityState}</Typography>
                  <Typography>{formatPhoneNumber(store.phone)}</Typography>
                </CardSection>
              </Flex>
              {hasOwnership && storeSettings.ownership_identification && (
                <Flex flexDirection="column" px={48}>
                  <CardSection label="Ownership">
                    {storeSettings.ownership_identification.bipoc && (
                      <Typography>BIPOC</Typography>
                    )}
                    {storeSettings.ownership_identification.lgbtq && (
                      <Typography>LGBTQ+</Typography>
                    )}
                    {storeSettings.ownership_identification.woman && (
                      <Typography>Woman</Typography>
                    )}
                    {storeSettings.ownership_identification.veteran && (
                      <Typography>Veteran</Typography>
                    )}
                  </CardSection>
                </Flex>
              )}
              {store.wheelchair_access && (
                <Flex flexDirection="column" px={48}>
                  <CardSection label="Accessibility">
                    <Typography>Wheelchair Access</Typography>
                  </CardSection>
                </Flex>
              )}
            </Flex>
            {store.description && (
              <Flex flexDirection="column">
                <CardSection label="Description">
                  <Typography>{store.description}</Typography>
                </CardSection>
              </Flex>
            )}
          </Flex>
        </Card.Content>
      )}
      <StoreDetailsModal
        store={store}
        ownership={
          storeSettings.ownership_identification || {
            id: 1,
            bipoc: false,
            lgbtq: false,
            veteran: false,
            woman: false,
          }
        }
        open={modalOpen}
        setOpen={() => closeModal()}
      />
    </>
  );
};

import { useMemo } from 'react';

import type { StaffMember } from '@jane/business-admin/types';
import {
  Button,
  CheckboxField,
  ChevronDownIcon,
  List,
  Popover,
} from '@jane/shared/reefer';

const ROLE_OPTIONS: Record<string, string> = {
  staff_member: 'Staff member',
  manager: 'Manager',
  client: 'Client',
};

export const StaffFilterRole = ({
  onChange,
  staffMembers = [],
  value,
}: {
  onChange: (value: string[]) => void;
  staffMembers?: StaffMember[];
  value: string[];
}) => {
  const availableRoles = useMemo(
    () =>
      staffMembers.length
        ? Array.from(
            staffMembers.reduce<Set<string>>((roles, staffMember) => {
              roles.add(staffMember.role);
              return roles;
            }, new Set())
          )
        : Object.keys(ROLE_OPTIONS),
    [staffMembers]
  );
  const handleChange = (role: string, selected: boolean) => {
    const roles = new Set(value);

    if (selected) {
      roles.add(role);
    } else {
      roles.delete(role);
    }

    onChange(Array.from(roles));
  };

  const label = 'Role';

  return (
    <Popover
      label={label}
      target={
        <Button
          label={label}
          variant="tertiary"
          endIcon={<ChevronDownIcon size="sm" />}
        />
      }
    >
      <Popover.Content label={label}>
        <List label="staff-roles">
          {availableRoles.map((role) => {
            const label = ROLE_OPTIONS[role];
            return (
              <List.Item key={`role-item-${role}`}>
                <CheckboxField
                  label={label}
                  name={`filter-${label.replace(/\s+/, '_')}`}
                  onChange={(checked) => handleChange(role, checked)}
                  defaultChecked={value.includes(role)}
                />
              </List.Item>
            );
          })}
        </List>
      </Popover.Content>
    </Popover>
  );
};

import { useContext, useMemo } from 'react';

import { FulfillmentSettingsContext } from '@jane/business-admin/providers';
import {
  Flex,
  Form,
  Grid,
  Modal,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

import { DollarInput } from '../../../../../../DollarInput';

export const FreeDeliverySection = () => {
  const { storePayload } = useContext(FulfillmentSettingsContext);
  const { store_free_delivery_config: setting } = storePayload;

  const { watch } = useFormContext();
  const freeDeliveryEnabled = watch('free_delivery');

  const defaultValue = useMemo(() => {
    if (typeof setting?.cart_minimum === 'number') {
      return setting?.cart_minimum;
    } else if (typeof setting?.cart_minimum === 'string') {
      return parseFloat(setting?.cart_minimum);
    } else {
      return 0;
    }
  }, [setting?.cart_minimum]);

  const isMinimumDisabled = useMemo(() => {
    if (freeDeliveryEnabled === undefined) {
      return !setting;
    } else {
      return !freeDeliveryEnabled;
    }
  }, [freeDeliveryEnabled, setting]);

  return (
    <>
      <Typography variant="body-bold" mb={24}>
        Free delivery
      </Typography>

      <Form.SwitchField
        defaultChecked={!!setting}
        name="free_delivery"
        label="Enabled"
        mb={24}
      />

      <Flex>
        <Grid.Container spacing={40}>
          <Grid.Item xs={4}>
            <DollarInput
              defaultValue={defaultValue}
              name="free_delivery_cart_minimum"
              label="Minimum cart size for free delivery"
              disabled={isMinimumDisabled}
            />
          </Grid.Item>
          <Grid.Item xs={4}></Grid.Item>
        </Grid.Container>
      </Flex>

      <Modal.ContentDivider />
    </>
  );
};

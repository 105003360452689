import { useMenu } from '@jane/shared-ecomm/providers';
import {
  BipocIcon,
  Box,
  Flex,
  LgbtqIcon,
  Typography,
  VeteranIcon,
  WomanIcon,
} from '@jane/shared/reefer';
import type { IconComponentProps } from '@jane/shared/reefer';

import { StyledGrid } from './MenuHeader.styles';

const IDENTIFICATION: {
  [id: string]: { icon: IconComponentProps; name: string };
} = {
  lgbtq: { name: 'LGBTQ+', icon: LgbtqIcon },
  bipoc: { name: 'BIPOC', icon: BipocIcon },
  veteran: { name: 'Veteran', icon: VeteranIcon },
  woman: { name: 'Woman', icon: WomanIcon },
};

export const MenuOwnership = () => {
  const {
    store: { ownership_identifications },
  } = useMenu();

  return (
    <Box>
      <Typography variant="body-bold" pb={8}>
        Ownership
      </Typography>
      <StyledGrid>
        {ownership_identifications?.map((id) => {
          const Icon = IDENTIFICATION[id].icon;
          return (
            <Flex key={id}>
              <Icon />
              <Typography pl={8}>{IDENTIFICATION[id].name}</Typography>
            </Flex>
          );
        })}
      </StyledGrid>
    </Box>
  );
};

import * as t from 'io-ts';

export const tQuickAction = t.type({
  id: t.string,
  title: t.string,
  description: t.string,
  tags: t.array(t.string),
  url: t.string,
});

export type QuickAction = t.TypeOf<typeof tQuickAction>;

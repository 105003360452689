import styled from '@emotion/styled';
import pluralise from 'pluralise';
import { useParams } from 'react-router-dom';

import { useStoreMenu } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { ModalNames } from '@jane/business-admin/util';
import { MENU_CATEGORY_TABS } from '@jane/shared-ecomm/pages';
import { CardCarousel } from '@jane/shared/components';
import { Box, Button, Flex, Typography } from '@jane/shared/reefer';

import { ArrangeMenuTabsModal } from '../ArrangeMenuTabsModal';
import { MenuTab } from './MenuTab';
import { getTabLabel } from './utils';

const MenuCategoryTabsContainer = styled(Flex)(({ theme }) => ({
  boxShadow: `0px -1px inset ${theme.colors.grays.light}, 0px 1px inset ${theme.colors.grays.light}`,
  margin: '0 -64px',
  marginTop: 24,
}));

export const MenuCategoryTabs = () => {
  const { id = '' } = useParams<'id'>();
  const { data: menuData, isFetched } = useStoreMenu(id);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.ArrangeMenuTabs
  );

  const tabsToRender = menuData?.menu_tabs.filter(
    (menuTab) =>
      menuTab.enabled &&
      (menuTab.tab_name === 'featured' || menuTab.menu_product_count > 0)
  );

  const header = (
    <Flex width="100%" justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column">
        <Typography as="h2" variant="header-bold" branded>
          Category tabs
        </Typography>
        <Typography color="grays-black">
          Each customer can use these tabs to browse products by category.
        </Typography>
      </Flex>
      <Typography whiteSpace="nowrap">
        {tabsToRender?.length || 0}{' '}
        {pluralise(tabsToRender?.length || 0, 'tab')}
      </Typography>
    </Flex>
  );

  return (
    <MenuCategoryTabsContainer py={40} px={64}>
      <CardCarousel
        beforeHeader={header}
        afterHeader={
          <Button
            ml={16}
            label="Arrange"
            onClick={() => openModal()}
            variant="secondary"
          />
        }
        cardWidth={83}
        cardWidthMobile={83}
        mode="ideal-width"
        name="Category tabs"
        isLoading={!isFetched}
      >
        {tabsToRender?.map((menuTab) => (
          <Box mt={56} key={menuTab.id}>
            <MenuTab
              Icon={
                MENU_CATEGORY_TABS[
                  menuTab.tab_name === 'all-products' ? 'all' : menuTab.tab_name
                ].Icon
              }
              name={getTabLabel(menuTab)}
              color="purple"
              width={83}
            />
          </Box>
        ))}
      </CardCarousel>
      {modalOpen && <ArrangeMenuTabsModal closeModal={() => closeModal()} />}
    </MenuCategoryTabsContainer>
  );
};

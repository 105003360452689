import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useUpdateMenuRow } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type { MenuRow } from '@jane/business-admin/types';
import {
  EventNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Button,
  EditIcon,
  Flex,
  Form,
  FormValidationError,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

interface HeaderEditButtonProps {
  onOpenMenuRow: () => void;
  showCTAButton: boolean;
}

export const HeaderEditButton = ({
  onOpenMenuRow,
  showCTAButton,
}: HeaderEditButtonProps) => {
  return showCTAButton ? (
    <Button label="Add products" onClick={onOpenMenuRow} />
  ) : (
    <Button.Icon
      icon={<EditIcon />}
      variant="secondary"
      label="Edit Menu Row"
      onClick={onOpenMenuRow}
      data-testid="menu-row-edit-button"
      ml={16}
    />
  );
};

interface HeaderProps {
  itemsLength: number;
  row: MenuRow;
  rowHelperText?: string;
  rowLabel: string;
  showProductsCTA: boolean;
}

export const HeaderWithoutToggle = ({
  row,
  rowLabel,
  itemsLength,
}: Omit<HeaderProps, 'showProductsCTA'>) => {
  const rowProductText =
    row?.is_category && itemsLength > 0
      ? `${itemsLength} items`
      : 'No inventory';

  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center">
      <Typography as="h2" branded variant="header-bold">
        {rowLabel}
      </Typography>
      <Typography>{rowProductText}</Typography>
    </Flex>
  );
};

export const HeaderToggle = ({
  row,
  rowLabel,
  itemsLength,
  showProductsCTA,
  rowHelperText,
}: HeaderProps) => {
  const FIELD_NAME = `enabled_${row.id}`;
  const toast = useToast();

  const formMethods = useForm({
    defaultValues: {
      [FIELD_NAME]: row.enabled,
    },
  });
  const { setValue } = formMethods;

  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error editing menu row. Please try again.`
  );

  const { id: storeId = '' } = useParams<'id'>();
  const { mutateAsync: updateMenuRow, isSuccess: updateSuccess } =
    useUpdateMenuRow(storeId, row?.id || 0);

  useEffect(() => {
    if (updateSuccess) {
      toast.add({
        label: 'Menu row settings updated',
        variant: 'success',
      });
    }
  }, [updateSuccess]);

  const onToggle = useCallback(
    (toggled: boolean) => {
      if (!row || toggled === row.enabled) {
        return;
      }

      const requestData = {
        ...row,
        custom_display_name: row.custom_display_name || row.row_type,
        enabled: toggled,
      };
      const submitMethod = async () => {
        await updateMenuRow(requestData as any);

        track({
          event: EventNames.ToggleVisibility,
          object: 'menu row',
          final_state: toggled ? 'visible' : 'hidden',
          trigger_source_id: 'menu index page',
          successful: true,
        });
      };

      return catchSubmitErrors({
        submitMethod,
        requestData,
        onValidationError: (validationErrors: Record<string, unknown>) => {
          track({
            event: EventNames.ToggleVisibility,
            object: 'menu row',
            final_state: toggled ? 'visible' : 'hidden',
            trigger_source_id: 'menu index page',
            successful: false,
            error_reason: '400 Bad Request',
          });
          throw new FormValidationError(
            'menu-row-error',
            parseValidationErrors(validationErrors)
          );
        },
        callback: () => {
          setValue(FIELD_NAME, row.enabled);
          track({
            event: EventNames.ToggleVisibility,
            object: 'menu row',
            final_state: toggled ? 'visible' : 'hidden',
            trigger_source_id: 'menu index page',
            successful: false,
            error_reason: 'Server error',
          });
          toast.add({
            label: 'Error updating menu row settings. Please try again.',
            variant: 'error',
          });
        },
      });
    },
    [row.enabled]
  );

  const displayProductCount =
    row.row_type !== 'specials' &&
    row.row_type !== 'magic_row' &&
    row.row_type !== 'buy_again_row';

  return (
    <Flex grow={1} justifyContent="space-between" alignItems="center">
      {/* // TODO: Label is misaligned with the toggle, requires adding a flex property to the FieldWrapper thats on SwitchField */}
      <Form.BaseForm
        name="menu-row-toggle"
        formMethods={formMethods}
        onSubmit={() => null}
      >
        <Form.SwitchField
          name={FIELD_NAME}
          onChange={onToggle}
          helperText={rowHelperText}
          label={
            <Typography as="h2" branded variant="header-bold">
              {rowLabel}
            </Typography>
          }
        />
      </Form.BaseForm>
      {displayProductCount && (
        <Typography>
          {showProductsCTA ? 'No products added' : `${itemsLength} items`}
        </Typography>
      )}
    </Flex>
  );
};

import pluralise from 'pluralise';
import { useMemo } from 'react';

import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import type { CartLimitPolicyV2 } from '@jane/business-admin/types';
import { ModalNames, getPolicyRuleDetails } from '@jane/business-admin/util';
import { Button, EditIcon, Flex, Typography } from '@jane/shared/reefer';

import { TableCell } from '../../../../../TableCell';
import { TableRowWithBorder } from '../../../../../TableWithBorderSeparator';
import { PolicySettingsModal } from './settings/PolicySettingsModal';

export const PolicyRow = ({
  policy,
  children,
}: {
  children: React.ReactNode;
  policy: CartLimitPolicyV2;
}) => {
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.EditCartLimitPolicy
  );

  const storeCount = useMemo(() => {
    const count = policy?.stores.length || 0;
    return `${count} ${pluralise(count, 'store')}`;
  }, [policy?.stores]);

  return (
    <>
      <TableRowWithBorder key={policy.id} hasBorder>
        <TableCell>{children}</TableCell>
        <TableCell>
          <Flex width={275}>
            <Typography color="grays-mid">
              {getPolicyRuleDetails(policy?.cart_limit_rules)}
            </Typography>
          </Flex>
        </TableCell>
        <TableCell>
          <Typography color="grays-mid">{storeCount}</Typography>
        </TableCell>
        <TableCell>
          <Flex justifyContent="end">
            <Button.Icon
              icon={<EditIcon color="grays-mid" />}
              label="Edit cart limit policy"
              onClick={() => openModal()}
            />
          </Flex>
        </TableCell>
      </TableRowWithBorder>
      {modalOpen && (
        <PolicySettingsModal
          open={modalOpen}
          onClose={() => closeModal()}
          policy={policy}
        />
      )}
    </>
  );
};

import noop from 'lodash/noop';
import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';

interface Props {
  children: ReactNode;
}

interface ProductsTableContext {
  canEditProducts?: boolean;
  setCanEditProducts: (canEdit: boolean) => void;
}

export const ProductsTableContext = React.createContext<ProductsTableContext>({
  setCanEditProducts: noop,
});

export const ProductsTableProvider = ({ children }: Props) => {
  // Catchall for published/unpublished/hidden permissions, switches based on currently selected table
  const [canEditProducts, setCanEditProducts] = useState(false);

  const contextValue = useMemo(
    () => ({
      canEditProducts,
      setCanEditProducts,
    }),
    [canEditProducts, setCanEditProducts]
  );

  return (
    <ProductsTableContext.Provider value={contextValue}>
      {children}
    </ProductsTableContext.Provider>
  );
};

import * as t from 'io-ts';

export enum NotificationType {
  alert = 'alert',
  card = 'card',
  modal = 'modal',
  upsell = 'upsell',
}

export const tManagerNotification = t.type({
  id: t.number,
  action_enabled: t.boolean,
  action_text: t.union([t.string, t.null]),
  action_url: t.union([t.string, t.null]),
  critical: t.boolean,
  enabled: t.boolean,
  header: t.union([t.string, t.null]),
  notification_type: t.keyof(NotificationType),
  seen: t.boolean,
  subheader: t.union([t.string, t.null]),
});

export type ManagerNotification = t.TypeOf<typeof tManagerNotification>;

export const tManagerNotificationAlert = t.type({
  alert: t.array(tManagerNotification),
});

export type ManagerNotificationAlert = t.TypeOf<
  typeof tManagerNotificationAlert
>;

export const tManagerNotificationUpsell = t.type({
  upsell: t.array(tManagerNotification),
});
export type ManagerNotificationUpsell = t.TypeOf<
  typeof tManagerNotificationUpsell
>;

export const tNotification = t.type({
  notifications: t.intersection([
    t.partial({
      alert: t.array(tManagerNotification),
    }),
    t.partial({
      upsell: t.array(tManagerNotification),
    }),
    t.partial({
      modal: t.array(tManagerNotification),
    }),
    t.partial({
      card: t.array(tManagerNotification),
    }),
  ]),
});

export type Notification = t.TypeOf<typeof tNotification>;

import styled from '@emotion/styled';

import { spacing } from '@jane/shared/reefer-emotion';

export const LoginBanner = styled.div<{ isError?: boolean }>(
  ({ theme, isError }) => {
    const color = isError ? theme.colors.error.main : theme.colors.primary.main;

    return {
      ...spacing({ p: 24, mb: 32 }),
      border: `2px solid ${color}`,
      borderRadius: theme.borderRadius.sm,
      textAlign: 'center',
    };
  }
);

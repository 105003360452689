import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as t from 'io-ts';

import type {
  CartLimitPolicyV2,
  CartLimitRuleV2,
} from '@jane/business-admin/types';
import { tCartLimitPolicyV2 } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type { Id } from '@jane/shared/models';

export const CART_LIMITS_URL = `/business/cart_limit_policies`;

const fetchCartLimitPolicies = async (): Promise<any> => {
  const data = await fetchWithDecode(
    janeApiV2.get(CART_LIMITS_URL),
    t.array(tCartLimitPolicyV2),
    CART_LIMITS_URL
  );
  return data;
};
export const useCartLimitPolicies = () =>
  useQuery<CartLimitPolicyV2[]>({
    queryFn: () => fetchCartLimitPolicies(),
    queryKey: ['cart_limit_policies'],
    /** @todo: Shouldn't we always grab the latest cart limits list when modal is opened? */
    // staleTime: Infinity,
    useErrorBoundary: true,
  });

export interface UpdateCartLimitPolicyParams {
  cart_limit_rules?: CartLimitRuleV2[];
  name?: string;
  storeIds?: string[];
}

const updateCartLimitPolicy = async (
  id: string,
  params: UpdateCartLimitPolicyParams
): Promise<null> => {
  return janeApiV2.patch<null>(`${CART_LIMITS_URL}/${id}`, {
    cart_limit_policy: params,
  });
};

export const useUpdateCartLimitPolicy = (id: string, storeId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateCartLimitPolicyParams) => {
      return updateCartLimitPolicy(id, data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['cart_limit_policies']);
      queryClient.invalidateQueries(['stores', storeId?.toString()]);
    },
  });
};

const createCartLimitPolicy = async (
  params: UpdateCartLimitPolicyParams
): Promise<CartLimitPolicyV2> => {
  return janeApiV2.post(CART_LIMITS_URL, {
    cart_limit_policy: params,
  });
};

export const useCreateCartLimitPolicy = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateCartLimitPolicyParams) => {
      return createCartLimitPolicy(data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['cart_limit_policies']);
    },
  });
};

interface CartLimitStoreUpdateParams {
  policyId: Id;
  stores: Array<{ id: Id; selected: boolean }>;
}
interface CartLimitStoreUpdateResponse {
  deselected: number[];
  selected: number[];
}
const updateCartLimitPolicyStores = async ({
  policyId,
  stores,
}: CartLimitStoreUpdateParams): Promise<CartLimitStoreUpdateResponse> =>
  janeApiV2.post(`${CART_LIMITS_URL}/${policyId}/stores`, { stores });

export const useUpdateCartLimitPolicyStores = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: CartLimitStoreUpdateParams) =>
      updateCartLimitPolicyStores(params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(['cart_limit_policies']);
      queryClient.invalidateQueries({ queryKey: ['stores'], exact: true });

      // Refresh the settings queries of all affected stores if they're cached:
      for (const storeId of [...response.selected, ...response.deselected]) {
        queryClient.invalidateQueries({
          queryKey: ['stores', storeId.toString()],
          exact: true,
        });
      }
    },
  });
};

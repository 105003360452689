import { duration } from '@jane/shared/util';

export const getMinutes = (timeoutDuration: any) =>
  Math.floor(timeoutDuration.asMinutes());

export const changeKioskTimeout = (
  combinedTimeouts: any,
  unit: 'minutes' | 'seconds',
  value: number
) => {
  const newTimeoutSeconds =
    unit === 'minutes'
      ? combinedTimeouts.seconds() + value * 60
      : getMinutes(combinedTimeouts) * 60 + value;
  return duration(newTimeoutSeconds, 'seconds');
};

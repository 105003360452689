import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as t from 'io-ts';

import type { JanePrinterV2 } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type { CloudPrinterSetting as StorePrinterSettingV2 } from '@jane/shared/models';
import {
  tJanePrinter as tJanePrinterV2,
  tCloudPrinterSetting as tStorePrinterSettingV2,
} from '@jane/shared/models';

const STORES_URL = `/business/stores`;

const fetchStorePrintersSettings = async (
  storeId: string
): Promise<StorePrinterSettingV2> => {
  const url = `${STORES_URL}/${storeId}/store_printer_settings`;
  const data = await fetchWithDecode(
    janeApiV2.get(url),
    tStorePrinterSettingV2,
    url
  );

  return data;
};
export const useStorePrintersSettings = (storeId: string) => {
  return useQuery<StorePrinterSettingV2>({
    queryFn: () => fetchStorePrintersSettings(storeId),
    queryKey: ['stores', storeId, 'store_printer_settings'],
    staleTime: Infinity,
  });
};

const createStorePrintersSettings = async (
  storeId: string,
  data: StorePrinterSettingV2
): Promise<null> =>
  await janeApiV2.post<null>(
    `${STORES_URL}/${storeId}/store_printer_settings`,
    data
  );
export const useCreateStorePrintersSettings = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: StorePrinterSettingV2) => {
      return createStorePrintersSettings(storeId, data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        'stores',
        storeId,
        'store_printer_settings',
      ]);
    },
  });
};

const updateStorePrintersSettings = async (
  storeId: string,
  data: StorePrinterSettingV2
): Promise<null> =>
  await janeApiV2.put<null>(
    `${STORES_URL}/${storeId}/store_printer_settings`,
    data
  );
export const useUpdateStorePrintersSettings = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: StorePrinterSettingV2) => {
      return updateStorePrintersSettings(storeId, data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        'stores',
        storeId,
        'store_printer_settings',
      ]);
    },
  });
};

const fetchJanePrinters = async (storeId: string): Promise<JanePrinterV2[]> => {
  const url = `${STORES_URL}/${storeId}/jane_printers`;
  const data = await fetchWithDecode(
    janeApiV2.get(url),
    t.array(tJanePrinterV2),
    url
  );

  return data;
};
export const useFetchJanePrinters = (storeId: string) => {
  return useQuery<JanePrinterV2[]>({
    placeholderData: [],
    queryFn: () => fetchJanePrinters(storeId),
    queryKey: ['stores', storeId, 'jane_printers'],
    staleTime: Infinity,
  });
};

const createJanePrinter = async (
  storeId: string,
  data: JanePrinterV2
): Promise<null> =>
  await janeApiV2.post<null>(`${STORES_URL}/${storeId}/jane_printers`, data);

export const useCreateJanePrinter = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: JanePrinterV2) => {
      return createJanePrinter(storeId, data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId, 'jane_printers']);
    },
  });
};

const deleteJanePrinter = async (storeId: string, janePrinterId: string) =>
  await janeApiV2.delete<null>(
    `${STORES_URL}/${storeId}/jane_printers/${janePrinterId}`
  );
export const useDeleteJanePrinter = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (janePrinterId: string) => {
      return deleteJanePrinter(storeId, janePrinterId);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId, 'jane_printers']);
    },
  });
};

const updateJanePrinter = async (
  storeId: string,
  janePrinterId: string,
  data: Partial<JanePrinterV2>
): Promise<null> =>
  await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/jane_printers/${janePrinterId}`,
    data
  );
export const useUpdateJanePrinter = (
  storeId: string,
  janePrinterId: string
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Partial<JanePrinterV2>) =>
      updateJanePrinter(storeId, janePrinterId, data),
    onError: (error) => console.error(error),
    onSuccess: () =>
      queryClient.invalidateQueries(['stores', storeId, 'jane_printers']),
  });
};

const sendTestPrint = async (storeId: number): Promise<null> =>
  await janeApiV2.post<null>(
    `${STORES_URL}/${storeId}/store_jane_printers/test_print`,
    {}
  );
export const useSendTestPrint = () => {
  return useMutation({
    mutationFn: (storeId: number) => sendTestPrint(storeId),
    onError: (error) => console.error(error),
  });
};

const clearQueue = async (
  storeId: string,
  janePrinterId: string
): Promise<{ number_of_queued_jobs: number }> =>
  await janeApiV2.post<{ number_of_queued_jobs: number }>(
    `${STORES_URL}/${storeId}/jane_printers/${janePrinterId}/clear_queue`,
    {}
  );
export const useClearQueue = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      janePrinterId,
      storeId,
    }: {
      janePrinterId: string;
      storeId: string;
    }) => clearQueue(storeId, janePrinterId),
    onError: (error) => console.error(error),
    onSuccess: () =>
      queryClient.invalidateQueries(['stores', storeId, 'jane_printers']),
  });
};

const generatePassword = async (
  storeId: string,
  janePrinterId: string
): Promise<{ password: string }> =>
  await janeApiV2.post<{ password: string }>(
    `${STORES_URL}/${storeId}/jane_printers/${janePrinterId}/generate_password`,
    {}
  );
export const useGeneratePassword = (storeId: string) => {
  return useMutation({
    mutationFn: ({
      janePrinterId,
      storeId,
    }: {
      janePrinterId: string;
      storeId: string;
    }) => generatePassword(storeId, janePrinterId),
    onError: (error) => console.error(error),
  });
};

import pick from 'lodash/pick';
import { useEffect, useMemo } from 'react';

import { useSavePaymentOptions } from '@jane/business-admin/data-access';
import type { SavePaymentOptionsParam } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type {
  AeropayIntegrationV2,
  DeleteObject,
  JanePayIntegration,
  PaymentOptionV2,
  SquareIntegration,
  StoreSettingsPayload,
  StoreV2,
  StrongholdIntegrationV2,
} from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type {
  CanPayV2RemotePayIntegration,
  CanpayV2Integration,
  MonerisIntegration,
  PayfirmaIntegration,
  ReservationMode,
} from '@jane/shared/models';
import {
  Flex,
  Form,
  FormValidationError,
  JanePayLogo,
  Modal,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import { PaymentOptions } from './PaymentOptions';
import { SquareSettings } from './SquareSettings';

export interface PaymentOption {
  _destroy?: boolean;
  enabled: boolean;
  id: number | string | null;
  payment_option: string;
  reservation_mode: ReservationMode;
}

export type PaymentFormData = {
  aeropay_integration: Omit<
    AeropayIntegrationV2,
    | 'store_id'
    | 'location_id'
    | 'custom_button_image_url'
    | 'custom_payment_method_label'
  >;
  canpay_v2_integration: CanpayV2Integration;
  canpay_v2remotepay_integration: CanPayV2RemotePayIntegration;
  jane_pay_integration: Omit<
    JanePayIntegration,
    'autocapture_on_checkout' | 'autocapture_on_completion' | 'merchant_id'
  > & {
    autocapture:
      | 'no_autocapture'
      | 'autocapture_on_checkout'
      | 'autocapture_on_completion';
  };
  moneris_integration: MonerisIntegration;
  payfirma_integration: PayfirmaIntegration;
  payment_options: PaymentOptionV2[];
  square_integration: SquareIntegration;
  store: Pick<
    StoreV2,
    | 'canpay_enabled'
    | 'canpay_lane_id'
    | 'canpay_merchant_id'
    | 'canpay_store_code'
  >;
  stronghold_integration: StrongholdIntegrationV2;
};

export interface CheckoutOptionsModalProps {
  onClose: () => void;
  open: boolean;
  storeId: string;
  storePayload?: StoreSettingsPayload;
}

const FORM_ERROR_NAME = 'payment-settings-error';

export const PaymentModal = ({
  open,
  onClose,
  storeId,
  storePayload,
}: CheckoutOptionsModalProps) => {
  const reservationModes = useFlag(FLAGS.scJanePayReservationModeSettings);

  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating payment settings. Please try again.',
    [
      ['store', 'canpay_lane_id'],
      ['store', 'canpay_merchant_id'],
      ['store', 'canpay_store_code'],
      ['aeropay_integration', 'location_uuid'],
      ['canpay_v2_integration', 'app_key'],
      ['canpay_v2_integration', 'api_secret'],
      ['canpay_v2remotepay_integration', 'app_key'],
      ['canpay_v2remotepay_integration', 'api_secret'],
      ['jane_pay_integration', 'location_id'],
      ['jane_pay_integration', 'merchant_id'],
      ['moneris_integration', 'moneris_api_token'],
      ['moneris_integration', 'moneris_checkout_id'],
      ['moneris_integration', 'moneris_store_id'],
      ['payfirma_integration', 'payfirma_custom_id'],
      ['payfirma_integration', 'payfirma_client_id'],
      ['payfirma_integration', 'payfirma_client_secret'],
      ['square_integration', 'location_id'],
      ['stronghold_integration', 'secret_key'],
      ['stronghold_integration', 'publishable_key'],
    ]
  );

  const toast = useToast();
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
    watch,
    setValue,
  } = formMethods;

  const aeropayEnabled = watch(
    'aeropay_integration.enabled',
    storePayload?.aeropay_integration?.enabled
  );

  const providerAeroPayEnabled = useMemo(() => {
    if (aeropayEnabled === undefined) {
      return storePayload?.aeropay_integration?.enabled;
    }

    return aeropayEnabled;
  }, [aeropayEnabled, storePayload?.aeropay_integration?.enabled]);

  const janePayEnabled = watch(
    'jane_pay_integration.enabled',
    storePayload?.jane_pay_integration?.enabled
  );

  const providerJanePayEnabled = useMemo(() => {
    if (janePayEnabled === undefined) {
      return storePayload?.jane_pay_integration?.enabled;
    }

    return janePayEnabled;
  }, [janePayEnabled, storePayload?.jane_pay_integration?.enabled]);

  const strongholdEnabled = watch(
    'stronghold_integration.enabled',
    storePayload?.stronghold_integration?.enabled
  );

  const providerStrongholdEnabled = useMemo(() => {
    if (strongholdEnabled === undefined) {
      return storePayload?.stronghold_integration?.enabled;
    }

    return strongholdEnabled;
  }, [strongholdEnabled, storePayload?.stronghold_integration?.enabled]);

  const canpayEnabled = watch(
    'store.canpay_enabled',
    storePayload?.store.canpay_enabled
  );

  const providerCanpayEnabled = useMemo(() => {
    if (canpayEnabled === undefined) {
      return storePayload?.store?.canpay_enabled;
    }

    return canpayEnabled;
  }, [canpayEnabled, storePayload?.store?.canpay_enabled]);

  const canpayV2Enabled = watch(
    'canpay_v2_integration.enabled',
    storePayload?.canpay_v2_integration?.enabled
  );

  const providerCanpayV2Enabled = useMemo(() => {
    if (canpayV2Enabled === undefined) {
      return storePayload?.canpay_v2_integration?.enabled;
    }

    return canpayV2Enabled;
  }, [canpayV2Enabled, storePayload?.canpay_v2_integration?.enabled]);

  const canpayV2remotepayEnabled = watch(
    'canpay_v2remotepay_integration.enabled',
    storePayload?.canpay_v2remotepay_integration?.enabled
  );

  const providerCanpayV2remotepayEnabled = useMemo(() => {
    if (canpayV2remotepayEnabled === undefined) {
      return storePayload?.canpay_v2remotepay_integration?.enabled;
    }

    return canpayV2remotepayEnabled;
  }, [
    canpayV2remotepayEnabled,
    storePayload?.canpay_v2remotepay_integration?.enabled,
  ]);

  const handleCanPayV2RemotePayEnabledChange = () => {
    if (!canpayV2remotepayEnabled) {
      formMethods.setValue('canpay_v2_integration.enabled', false);
    }
  };

  const payfirmaEnabled = watch(
    'payfirma_integration.enabled',
    storePayload?.payfirma_integration?.enabled
  );

  const providerPayfirmaEnabled = useMemo(() => {
    if (payfirmaEnabled === undefined) {
      return storePayload?.payfirma_integration?.enabled;
    }

    return payfirmaEnabled;
  }, [payfirmaEnabled, storePayload?.payfirma_integration?.enabled]);

  const monerisEnabled = watch(
    'moneris_integration.enabled',
    storePayload?.moneris_integration?.enabled
  );

  const providerMonerisEnabled = useMemo(() => {
    if (monerisEnabled === undefined) {
      return storePayload?.moneris_integration?.enabled;
    }

    return monerisEnabled;
  }, [monerisEnabled, storePayload?.moneris_integration?.enabled]);

  const squareEnabled = watch(
    'square_integration.enabled',
    storePayload?.square_integration?.enabled
  );

  const providerSquareEnabled = useMemo(() => {
    const payloadSquareEnabled = storePayload?.square_integration?.enabled;

    if (
      !isDirty &&
      payloadSquareEnabled !== undefined &&
      squareEnabled !== payloadSquareEnabled
    ) {
      setValue('square_integration.enabled', payloadSquareEnabled);
      return payloadSquareEnabled;
    }

    return squareEnabled;
  }, [
    isDirty,
    setValue,
    squareEnabled,
    storePayload?.square_integration?.enabled,
  ]);

  const { mutateAsync: savePaymentOptions, isSuccess: saveSuccess } =
    useSavePaymentOptions(storeId);

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Payment settings updated',
        variant: 'success',
      });
      onClose();
    }
  }, [saveSuccess]);

  const maybeDeleteOptions = (
    storePayloadPaymentOptions: PaymentOption[] | undefined,
    formDataPaymentOptions: PaymentOption[] | undefined
  ) => {
    return storePayloadPaymentOptions?.filter((object1: PaymentOption) => {
      return !formDataPaymentOptions?.some((object2: PaymentOption) => {
        return object1.id === object2.id;
      });
    });
  };

  const validateFilledIfEnabled =
    (providerEnabled: boolean) => (val: string) => {
      const isFilled = !!val?.trim();
      if (!providerEnabled || isFilled) return true;
      return 'Please fill out this field';
    };

  useEffect(() => {
    formMethods.clearErrors();
    formMethods.trigger();
  }, [
    formMethods,
    providerAeroPayEnabled,
    providerJanePayEnabled,
    providerStrongholdEnabled,
    providerCanpayEnabled,
    providerCanpayV2Enabled,
    providerCanpayV2remotepayEnabled,
    providerPayfirmaEnabled,
    providerMonerisEnabled,
  ]);

  const onSubmit = (data: PaymentFormData) => {
    const storePayloadPaymentOptions = storePayload?.payment_options;

    // NOTE: We don't show additional payment options if square is enabled, should we be deleting existing entries or just hiding the UI?
    // Currently square is hidden so not a problem now
    const paymentOptions = data.payment_options || [];
    const optionsWithDeleted: (Omit<PaymentOptionV2, 'id'> | DeleteObject)[] = [
      ...paymentOptions,
    ];
    maybeDeleteOptions(storePayloadPaymentOptions, paymentOptions)?.forEach(
      (object: PaymentOption) => {
        optionsWithDeleted.push({
          ...object,
          _destroy: true,
        });
      }
    );

    const { autocapture, ...janePayIntegration } =
      data?.jane_pay_integration || {};

    const janePayReservationModes =
      data?.jane_pay_integration && data?.jane_pay_integration.reservation_modes
        ? data?.jane_pay_integration.reservation_modes
        : {};
    const aeropayReservationModes =
      data?.aeropay_integration && data?.aeropay_integration.reservation_modes
        ? data?.aeropay_integration.reservation_modes
        : {};

    const requestData: SavePaymentOptionsParam = {
      store: {
        ...pick(storePayload?.store, [
          'canpay_enabled',
          'canpay_lane_id',
          'canpay_merchant_id',
          'canpay_store_code',
        ]),
        ...pick(data?.store, [
          'canpay_enabled',
          'canpay_lane_id',
          'canpay_merchant_id',
          'canpay_store_code',
        ]),
      },
      canpay_v2_integration: {
        ...pick(data?.canpay_v2_integration, [
          'enabled',
          canpayV2Enabled && 'app_key',
          canpayV2Enabled && 'api_secret',
        ]),
        id: storePayload?.canpay_v2_integration?.id,
        store_id: storePayload?.store.id || '',
      } as CanpayV2Integration,
      canpay_v2remotepay_integration: {
        ...pick(data?.canpay_v2remotepay_integration, [
          'enabled',
          canpayV2remotepayEnabled && 'app_key',
          canpayV2remotepayEnabled && 'api_secret',
        ]),
        id: storePayload?.canpay_v2remotepay_integration?.id,
      } as CanPayV2RemotePayIntegration,
      aeropay_integration: {
        ...pick(data?.aeropay_integration, [
          'enabled',
          aeropayEnabled && 'location_uuid',
          aeropayEnabled && 'autocapture_on_checkout',
        ]),
        id: storePayload?.aeropay_integration?.id,
        reservation_modes: Object.keys(aeropayReservationModes).filter(
          (key) =>
            aeropayReservationModes[
              key as keyof typeof aeropayReservationModes
            ] !== false
        ),
      } as Partial<AeropayIntegrationV2>,
      jane_pay_integration: {
        ...pick(janePayIntegration, [
          'enabled',
          janePayEnabled && 'location_id',
          janePayEnabled && 'merchant_id',
        ]),
        autocapture_on_checkout: autocapture === 'autocapture_on_checkout',
        autocapture_on_completion: autocapture === 'autocapture_on_completion',
        id: storePayload?.jane_pay_integration?.id,
        reservation_modes: Object.keys(janePayReservationModes).filter(
          (key) =>
            janePayReservationModes[
              key as keyof typeof janePayReservationModes
            ] !== false
        ),
      } as JanePayIntegration,
      moneris_integration: {
        ...pick(data?.moneris_integration, [
          'enabled',
          'autocapture_on_checkout',
          monerisEnabled && 'moneris_store_id',
          monerisEnabled && 'moneris_checkout_id',
          monerisEnabled && 'moneris_api_token',
        ]),
        id: storePayload?.moneris_integration?.id,
      } as MonerisIntegration,
      payfirma_integration: {
        ...pick(data?.payfirma_integration, [
          'enabled',
          payfirmaEnabled && 'payfirma_client_id',
          payfirmaEnabled && 'payfirma_client_secret',
          payfirmaEnabled && 'payfirma_custom_id',
        ]),
        id: storePayload?.payfirma_integration?.id,
        currency: storePayload?.payfirma_integration?.currency || 'USD',
      } as PayfirmaIntegration,
      stronghold_integration: {
        ...pick(data?.stronghold_integration, [
          'enabled',
          strongholdEnabled && 'secret_key',
          strongholdEnabled && 'publishable_key',
        ]),
        id: storePayload?.stronghold_integration?.id || '',
      } as StrongholdIntegrationV2,
      store_payment_options: optionsWithDeleted || [],
    };
    if (squareEnabled) {
      requestData.square_integration = {
        ...data?.square_integration,
        id: storePayload?.square_integration?.id || '',
      };
    }

    const submitMethod = () => {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.Payments,
        // TODO: This will only show 'stronghold_integration', 'payment_options', etc if anything was changed there, is that ok?
        changed_attributes: Object.keys(dirtyFields),
      });

      return savePaymentOptions(requestData);
    };
    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: PaymentFormData) => {
        const parsedErrors = [
          'store',
          'store_payment_options',
          'canpay_v2_integration',
          'canpay_v2remotepay_integration',
          'aeropay_integration',
          'jane_pay_integration',
          'stronghold_integration',
          'payfirma_integration',
          'moneris_integration',
        ].reduce<any[]>((allValidationErrors, key) => {
          allValidationErrors.push(
            ...parseValidationErrors(
              validationErrors[key as keyof typeof validationErrors] || {},
              { namePrepend: key }
            )
          );
          return allValidationErrors;
        }, []);
        throw new FormValidationError(FORM_ERROR_NAME, parsedErrors);
      },
    });
  };

  const reservationModeDefault = (
    integrationType: 'janePay' | 'aeropay',
    reservationMode: 'pickup' | 'curbside' | 'delivery'
  ) => {
    const integration =
      integrationType === 'janePay'
        ? storePayload?.jane_pay_integration
        : storePayload?.aeropay_integration;

    return (
      !integration ||
      (Array.isArray(integration?.reservation_modes) &&
        integration?.reservation_modes.includes(reservationMode))
    );
  };

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={onClose}
      hasChanges={isDirty}
      modalName={ModalNames.Payments}
      parentId="payment-settings-modal"
    >
      <Form.BaseForm
        name="payment settings"
        onSubmit={onSubmit}
        formMethods={formMethods}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Payments"
          subtitle={storePayload?.store.name}
          actions={<Form.SubmitButton label="Save" />}
        />
        <Modal.Content>
          <>
            <Form.ErrorBanner name={FORM_ERROR_NAME} />
            <Flex mb={40} alignItems={'flex-start'}>
              <Typography variant={'body-bold'}>Payment provider(s)</Typography>
            </Flex>
            <Form.SwitchField
              label={<JanePayLogo height={24} />}
              name="jane_pay_integration.enabled"
              defaultChecked={providerJanePayEnabled}
              mb={32}
            />

            <Flex justifyContent="space-between" gap={16} my={24}>
              <Form.TextField
                label="Merchant ID"
                name="jane_pay_integration.merchant_id"
                defaultValue={
                  storePayload?.jane_pay_integration?.merchant_id || ''
                }
                placeholder="Merchant ID"
                disabled={!providerJanePayEnabled}
                validate={validateFilledIfEnabled(providerJanePayEnabled)}
              />

              <Form.TextField
                label="Location ID"
                name="jane_pay_integration.location_id"
                defaultValue={
                  storePayload?.jane_pay_integration?.location_id || ''
                }
                placeholder="Location ID"
                disabled={!providerJanePayEnabled}
              />
            </Flex>
            <Form.RadioFieldGroup
              disabled={!providerJanePayEnabled}
              name="jane_pay_integration.autocapture"
              defaultChecked={
                storePayload?.jane_pay_integration?.autocapture_on_checkout
                  ? 'autocapture_on_checkout'
                  : storePayload?.jane_pay_integration
                      ?.autocapture_on_completion
                  ? 'autocapture_on_completion'
                  : 'no_autocapture'
              }
              options={[
                {
                  id: 'no_autocapture',
                  value: 'no_autocapture',
                  label: (
                    <>
                      <Typography>No autocapture</Typography>
                      <Typography color="text-light" mt={8}>
                        When selected, Jane will only preauthorize the funds and
                        the store will be responsible for completing the payment
                        with Aeropay.
                      </Typography>
                    </>
                  ),
                },
                {
                  id: 'autocapture_on_checkout',
                  value: 'autocapture_on_checkout',
                  label: (
                    <>
                      <Typography>Autocapture on checkout</Typography>
                      <Typography color="text-light" mt={8}>
                        When selected, Jane will automatically capture the funds
                        on checkout.
                      </Typography>
                    </>
                  ),
                },
                {
                  id: 'autocapture_on_completion',
                  value: 'autocapture_on_completion',
                  label: (
                    <>
                      <Typography>Autocapture on order completion</Typography>
                      <Typography color="text-light" mt={8}>
                        When selected, Jane will automatically capture the funds
                        when the order is marked completed. This is recommended
                        for stores with pushback and cart API integrations set
                        up with their Point-of-sale.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
            {reservationModes && (
              <>
                <Typography
                  variant={'body-bold'}
                  color={providerJanePayEnabled ? 'text-main' : 'grays-light'}
                >
                  Apply to reservation
                </Typography>
                <Flex flexDirection="row" gap={24} mt={24}>
                  <Form.CheckboxField
                    name="jane_pay_integration.reservation_modes.delivery"
                    label="Delivery"
                    defaultChecked={reservationModeDefault(
                      'janePay',
                      'delivery'
                    )}
                    disabled={!providerJanePayEnabled}
                  />
                  <Form.CheckboxField
                    name="jane_pay_integration.reservation_modes.pickup"
                    label="Pickup"
                    defaultChecked={reservationModeDefault('janePay', 'pickup')}
                    disabled={!providerJanePayEnabled}
                  />
                  <Form.CheckboxField
                    name="jane_pay_integration.reservation_modes.curbside"
                    label="Curbside"
                    defaultChecked={reservationModeDefault(
                      'janePay',
                      'curbside'
                    )}
                    disabled={!providerJanePayEnabled}
                  />
                </Flex>
              </>
            )}
            <Modal.ContentDivider />
            <Form.SwitchField
              label={<Typography variant="body-bold">AeroPay</Typography>}
              name="aeropay_integration.enabled"
              defaultChecked={providerAeroPayEnabled}
              mb={32}
            />
            <Form.TextField
              label="Location UUID"
              name="aeropay_integration.location_uuid"
              defaultValue={
                storePayload?.aeropay_integration?.location_uuid || ''
              }
              placeholder="Location UUID"
              disabled={!providerAeroPayEnabled}
              validate={validateFilledIfEnabled(providerAeroPayEnabled)}
              my={24}
            />
            <Form.CheckboxField
              defaultChecked={
                storePayload?.aeropay_integration?.autocapture_on_checkout
              }
              label={
                <>
                  <Typography variant="body-bold">
                    Autocapture on checkout
                  </Typography>
                  <Typography>
                    When checked, Jane will automatically capture the funds on
                    checkout. When unchecked, Jane will only preauthorize the
                    funds and the store will be responsible for completing the
                    payment with Aeropay.
                  </Typography>
                </>
              }
              name="aeropay_integration.autocapture_on_checkout"
              disabled={!providerAeroPayEnabled}
              mb={24}
            />
            {reservationModes && (
              <>
                <Typography
                  variant={'body-bold'}
                  color={providerAeroPayEnabled ? 'text-main' : 'grays-light'}
                >
                  Apply to reservation
                </Typography>
                <Flex flexDirection="row" gap={24} mt={24}>
                  <Form.CheckboxField
                    name="aeropay_integration.reservation_modes.delivery"
                    label="Delivery"
                    defaultChecked={reservationModeDefault(
                      'aeropay',
                      'delivery'
                    )}
                    disabled={!providerAeroPayEnabled}
                  />
                  <Form.CheckboxField
                    name="aeropay_integration.reservation_modes.pickup"
                    label="Pickup"
                    defaultChecked={reservationModeDefault('aeropay', 'pickup')}
                    disabled={!providerAeroPayEnabled}
                  />
                  <Form.CheckboxField
                    name="aeropay_integration.reservation_modes.curbside"
                    label="Curbside"
                    defaultChecked={reservationModeDefault(
                      'aeropay',
                      'curbside'
                    )}
                    disabled={!providerAeroPayEnabled}
                  />
                </Flex>
              </>
            )}
            <Modal.ContentDivider />
            <Form.SwitchField
              label={<Typography variant="body-bold">Stronghold</Typography>}
              name="stronghold_integration.enabled"
              data-testid={'stronghold_enabled'}
              defaultChecked={providerStrongholdEnabled}
              mb={32}
            />
            <Flex justifyContent="space-between" gap={16} my={24}>
              <Form.TextField
                label="Secret Key"
                name="stronghold_integration.secret_key"
                defaultValue={storePayload?.stronghold_integration?.secret_key}
                placeholder="Secret Key"
                disabled={!providerStrongholdEnabled}
                validate={validateFilledIfEnabled(providerStrongholdEnabled)}
              />
              <Form.TextField
                label="Publishable Key"
                name="stronghold_integration.publishable_key"
                defaultValue={
                  storePayload?.stronghold_integration?.publishable_key
                }
                placeholder="Publishable Key"
                disabled={!providerStrongholdEnabled}
                validate={validateFilledIfEnabled(providerStrongholdEnabled)}
              />
            </Flex>
            <Modal.ContentDivider />
            <Form.SwitchField
              label={<Typography variant="body-bold">Moneris</Typography>}
              name="moneris_integration.enabled"
              defaultChecked={providerMonerisEnabled}
              mb={32}
            />
            <Flex justifyContent="space-between" gap={16} my={24}>
              <Form.TextField
                label="Moneris Store ID"
                name="moneris_integration.moneris_store_id"
                defaultValue={
                  storePayload?.moneris_integration?.moneris_store_id
                }
                placeholder="Moneris Store ID"
                disabled={!providerMonerisEnabled}
                validate={validateFilledIfEnabled(providerMonerisEnabled)}
              />
              <Form.TextField
                label="Moneris Checkout ID"
                name="moneris_integration.moneris_checkout_id"
                defaultValue={
                  storePayload?.moneris_integration?.moneris_checkout_id
                }
                placeholder="Moneris Checkout ID"
                disabled={!providerMonerisEnabled}
                validate={validateFilledIfEnabled(providerMonerisEnabled)}
              />
              <Form.TextField
                label="Moneris API Token"
                name="moneris_integration.moneris_api_token"
                defaultValue={
                  storePayload?.moneris_integration?.moneris_api_token
                }
                placeholder="Moneris API Token"
                disabled={!providerMonerisEnabled}
                validate={validateFilledIfEnabled(providerMonerisEnabled)}
              />
            </Flex>
            <Form.CheckboxField
              defaultChecked={
                storePayload?.moneris_integration?.autocapture_on_checkout
              }
              label={
                <>
                  <Typography variant="body-bold">
                    Autocapture on checkout
                  </Typography>
                  <Typography>
                    When checked, Jane will automatically capture the funds on
                    checkout. When unchecked, Jane will only preauthorize the
                    funds and the store will be responsible for completing the
                    payment with Moneris.
                  </Typography>
                </>
              }
              name="moneris_integration.autocapture_on_checkout"
              disabled={!providerMonerisEnabled}
              mb={24}
            />
            <Modal.ContentDivider />
            <Form.SwitchField
              label={<Typography variant="body-bold">CanPay</Typography>}
              name="store.canpay_enabled"
              defaultChecked={providerCanpayEnabled}
              mb={32}
            />
            <Flex justifyContent="space-between" gap={16} my={24}>
              <Form.TextField
                label="Merchant ID"
                name="store.canpay_merchant_id"
                defaultValue={storePayload?.store.canpay_merchant_id || ''}
                placeholder="Merchant ID"
                disabled={!providerCanpayEnabled}
                validate={validateFilledIfEnabled(providerCanpayEnabled)}
              />
              <Form.TextField
                label="Store code"
                name="store.canpay_store_code"
                defaultValue={storePayload?.store.canpay_store_code || ''}
                placeholder="Store code"
                disabled={!providerCanpayEnabled}
                validate={validateFilledIfEnabled(providerCanpayEnabled)}
              />
              <Form.TextField
                label="Lane ID (optional)"
                name="store.canpay_lane_id"
                defaultValue={storePayload?.store?.canpay_lane_id || ''}
                placeholder="Lane ID"
                disabled={!providerCanpayEnabled}
              />
            </Flex>
            <Modal.ContentDivider />
            <Form.SwitchField
              label={<Typography variant="body-bold">CanPay V2</Typography>}
              name="canpay_v2_integration.enabled"
              defaultChecked={providerCanpayV2Enabled}
              disabled={providerCanpayV2remotepayEnabled}
              mb={32}
            />
            <Flex justifyContent="space-between" gap={16} my={24}>
              <Form.TextField
                label="App Key"
                name="canpay_v2_integration.app_key"
                defaultValue={storePayload?.canpay_v2_integration?.app_key}
                placeholder="App Key"
                disabled={!providerCanpayV2Enabled}
                validate={validateFilledIfEnabled(providerCanpayV2Enabled)}
              />
              <Form.TextField
                label="API Secret"
                name="canpay_v2_integration.api_secret"
                defaultValue={storePayload?.canpay_v2_integration?.api_secret}
                placeholder="API Secret"
                disabled={!providerCanpayV2Enabled}
                validate={validateFilledIfEnabled(providerCanpayV2Enabled)}
              />
            </Flex>
            <Modal.ContentDivider />
            <Form.SwitchField
              label={
                <Typography variant="body-bold">CanPay V2 RemotePay</Typography>
              }
              name="canpay_v2remotepay_integration.enabled"
              defaultChecked={providerCanpayV2remotepayEnabled}
              onChange={handleCanPayV2RemotePayEnabledChange}
              mb={32}
            />
            <Flex justifyContent="space-between" my={24}>
              <Form.TextField
                width="100%"
                label="App Key"
                name="canpay_v2remotepay_integration.app_key"
                defaultValue={
                  storePayload?.canpay_v2remotepay_integration?.app_key
                }
                placeholder="App Key"
                disabled={!providerCanpayV2remotepayEnabled}
                validate={validateFilledIfEnabled(
                  providerCanpayV2remotepayEnabled
                )}
              />
              <Form.TextField
                width="100%"
                label="API Secret"
                name="canpay_v2remotepay_integration.api_secret"
                defaultValue={
                  storePayload?.canpay_v2remotepay_integration?.api_secret
                }
                placeholder="API Secret"
                disabled={!providerCanpayV2remotepayEnabled}
                validate={validateFilledIfEnabled(
                  providerCanpayV2remotepayEnabled
                )}
                mx={16}
              />
            </Flex>
            <Modal.ContentDivider />
            <Form.SwitchField
              label={<Typography variant="body-bold">Payfirma</Typography>}
              name="payfirma_integration.enabled"
              defaultChecked={providerPayfirmaEnabled}
              mb={32}
            />
            <Flex justifyContent="space-between" gap={16} my={24}>
              <Flex flexDirection={'column'} alignItems={'center'}>
                <Form.TextField
                  label="Custom ID"
                  name="payfirma_integration.payfirma_custom_id"
                  defaultValue={
                    storePayload?.payfirma_integration?.payfirma_custom_id
                  }
                  placeholder="Custom ID"
                  disabled={!providerPayfirmaEnabled}
                  validate={validateFilledIfEnabled(providerPayfirmaEnabled)}
                />
                <Typography mt={16} mb={64} color="grays-mid">
                  Each Payfirma partner is assigned a three letter word to help
                  track transactions. It should be in your sale request packet;
                  if not, please ask them.
                </Typography>
              </Flex>
              <Flex flexDirection={'column'} alignItems={'center'}>
                <Form.TextField
                  label="Client ID"
                  name="payfirma_integration.payfirma_client_id"
                  defaultValue={
                    storePayload?.payfirma_integration?.payfirma_client_id
                  }
                  placeholder="Client ID"
                  disabled={!providerPayfirmaEnabled}
                  validate={validateFilledIfEnabled(providerPayfirmaEnabled)}
                />
                <Typography mt={16} mb={64} color="grays-mid">
                  You can create and manage your client ID in Merrco Payfirma's
                  Settings &#62; eCommerce section within your Merrco Payfirma
                  PayHQ account.
                </Typography>
              </Flex>
              <Flex flexDirection={'column'} alignItems={'center'}>
                <Form.TextField
                  label="Client Secret"
                  name="payfirma_integration.payfirma_client_secret"
                  defaultValue={
                    storePayload?.payfirma_integration?.payfirma_client_secret
                  }
                  placeholder="Client Secret"
                  disabled={!providerPayfirmaEnabled}
                />
                <Typography mt={16} mb={64} color="grays-mid">
                  You can create and manage your client secret in Merrco
                  Payfirma's Settings &#62; eCommerce section within your Merrco
                  Payfirma PayHQ account.
                </Typography>
              </Flex>
            </Flex>
            <SquareSettings
              storeId={storeId}
              storePayload={storePayload}
              providerSquareEnabled={providerSquareEnabled}
              isDirty={isDirty}
              setValue={setValue}
            />
            {!providerSquareEnabled && (
              <div data-testid="additional-payment-options">
                <Modal.ContentDivider />
                <Typography variant="body-bold" mb={12}>
                  Additional payment options
                </Typography>
                <Typography variant="mini" mb={24}>
                  Add additional forms of accepted payment here.
                </Typography>
                {storePayload?.payment_options && (
                  <PaymentOptions
                    kioskEnabled={storePayload.kiosk?.enabled || false}
                    payloadPaymentOptions={storePayload.payment_options}
                  />
                )}
              </div>
            )}
          </>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

import styled from '@emotion/styled';
import type { ColumnFiltersState } from '@tanstack/react-table';
import { useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import {
  SpecialsTableProvider,
  StoreDetailsContext,
} from '@jane/business-admin/providers';
import type { AbbreviatedSpecialV2 } from '@jane/business-admin/types';
import { ModalNames, businessPaths } from '@jane/business-admin/util';
import { ConditionalWrapper } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { SpecialsTable } from '../../../specials/SpecialsTable';
import { BulkEditModal } from '../../../specials/bulk_editing/BulkEditModal';

const Wrapper = styled.div({
  display: 'flex',
  ...spacing({ py: 40 }),
});

export const StoreSpecialsLanding = () => {
  // TODO: useNavigateAndTrack when ready for full release to production
  const navigate = useNavigate();

  const { storeId } = useContext(StoreDetailsContext);

  const [bulkSelectedSpecials, setBulkSelectedSpecials] = useState<number[]>(
    []
  );
  // Filters have to be here so they persist when the bulk edit modal is opened
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const {
    modalOpen: bulkEditModalOpen,
    openModal: setBulkEditModalOpen,
    closeModal: setBulkEditModalClose,
  } = useModalActionsWithTracking(ModalNames.BulkEditSpecials);

  const openModal = ({ id }: { id?: number | string }) => {
    if (id) {
      navigate(businessPaths.storeSpecialDetail(storeId, String(id)));
    } else {
      navigate(businessPaths.storeSpecialDetail(storeId, 'create'));
    }
  };

  const table = (
    <SpecialsTableProvider
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      bulkEditModalOpen={bulkEditModalOpen}
      bulkSelectedSpecials={bulkSelectedSpecials}
      setBulkSelectedSpecials={setBulkSelectedSpecials}
    >
      <SpecialsTable
        onEditSpecial={(special: Partial<AbbreviatedSpecialV2>) => {
          if (bulkEditModalOpen) return;
          openModal({ id: special?.id });
        }}
        openModal={openModal}
        setBulkEditModalOpen={setBulkEditModalOpen}
      />
    </SpecialsTableProvider>
  );

  return (
    <Wrapper>
      <ConditionalWrapper
        condition={bulkEditModalOpen}
        wrapper={(child) => (
          <BulkEditModal
            bulkSelectedSpecials={bulkSelectedSpecials}
            onClose={setBulkEditModalClose}
            variant="store"
          >
            {child}
          </BulkEditModal>
        )}
      >
        {table}
      </ConditionalWrapper>
      <Outlet />
    </Wrapper>
  );
};

import styled from '@emotion/styled';

import { getJaneGoldFAQPath } from '@jane/shared-ecomm/util';
import {
  Box,
  ConditionalWrapper,
  Flex,
  JaneGoldIcon,
  Link,
  Typography,
  useDesktopMediaQuery,
} from '@jane/shared/reefer';

const RoundDiv = styled(Flex)({
  borderRadius: '50%',
});

export const JaneGoldTitle = () => {
  const isDesktop = useDesktopMediaQuery({});

  return (
    <Flex alignItems="center">
      <RoundDiv
        width={isDesktop ? 96 : 48}
        height={isDesktop ? 96 : 48}
        maxWidth={isDesktop ? 96 : 48}
        maxHeight={isDesktop ? 96 : 48}
        justifyContent="center"
        alignItems="center"
        background="gold-light"
        grow
      >
        <JaneGoldIcon size={isDesktop ? 'xl' : 'md'} />
      </RoundDiv>

      <Box ml={isDesktop ? 24 : 16}>
        <Typography variant="title-bold">Jane Gold</Typography>
        <Typography pt={isDesktop ? 8 : 4}>
          Earn exclusive cash back rewards from cannabis brands with{' '}
          <ConditionalWrapper
            condition={isDesktop}
            wrapper={(children) => (
              <Link href={getJaneGoldFAQPath()}>{children}</Link>
            )}
          >
            Jane Gold
          </ConditionalWrapper>
        </Typography>
      </Box>
    </Flex>
  );
};

import type { Column, SortingState } from '@tanstack/react-table';

import { Flex, Link, SortUpIcon, Typography } from '@jane/shared/reefer';

export const SortableHeader = ({
  column,
  headerText,
  sorting,
  shiftRight = false,
}: {
  column: Column<any, unknown>;
  headerText?: string;
  shiftRight?: boolean;
  sorting: SortingState;
}) => {
  const margin = shiftRight ? 64 : 0;
  const sortedColumn = sorting[0] ? sorting[0].id : null;

  return (
    <Flex alignItems="center" ml={margin}>
      <Link
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        variant="minimal"
      >
        <Flex alignItems="center">
          <Typography variant="caps">{headerText}</Typography>
          <SortUpIcon
            color={sortedColumn === column.id ? 'primary-light' : 'default'}
            rotate={column.getIsSorted() === 'asc' ? 'up' : 'down'}
          />
        </Flex>
      </Link>
    </Flex>
  );
};

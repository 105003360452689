import debounce from 'lodash/debounce';
import { useMemo } from 'react';

import { track } from '@jane/business-admin/util';
import type { TrackedEvent } from '@jane/business-admin/util';

export const useDebouncedTrack = (timeout: number) =>
  useMemo(
    () =>
      debounce((event: TrackedEvent) => {
        track(event);
      }, timeout),
    [timeout]
  );

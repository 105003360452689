import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactNode } from 'react';
import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import type { RouterProviderProps } from 'react-router-dom';
import { BrowserRouter, RouterProvider } from 'react-router-dom';

import { defaultQueryClientConfig } from '@jane/shared/config';
import { ConditionalWrapper, ReeferThemeProvider } from '@jane/shared/reefer';
import type { CustomThemeConfig } from '@jane/shared/reefer';

/**
 * Application Wrapper
 *   - StrictMode
 *   - HelmetProvider
 *   - QueryClientProvider (react-query)
 *   - ReeferThemeProvider
 *   - BrowserRouter (react-router) OR RouterProvider
 *
 * Wrappers should only be added here if they are meant to be used
 * in *EVERY* Jane application.
 */

/**
 * @param {Router} [JaneApplicationWrapperProps.router] - Router object used for RouterProvider
 * - When passed in, RouterProvider will take over instead of the usual BrowserRouter
 * - This property enables the usage of the data APIs provided to use via ReactRouter v6.4
 * - Read more about Data APIs in their [Data APIs Docs Page](https://reactrouter.com/en/main/routers/picking-a-router#using-v64-data-apis)
 * @param {QueryClient} [JaneApplicationWrapperProps.queryClient] - Custom Query Client passed in through application to be used for the QueryClientProvider
 * - This parameter should be used in conjunction with `JaneApplicationWrapperProps.router`
 * - Supplying your own queryClient will facilitate in the creation of your routes objects and using loaders
 * @param {ReactNode} [JaneApplicationWrapperProps.children] - Your application rendered via `useRoutes` hook which is supplied to the `BrowserRouter` component
 * - When passing in Children, we are assuming that `BrowserRouter` has been chosen as the router
 * - By using Children, the data API functions provided through `@tanstack/react-router` v6.4 are unavailable to use
 * @returns Application wrapped with providers
 */
export function JaneApplicationWrapper({
  children,
  queryClient,
  router,
  renderThemeProvider = true,
  theme,
}: {
  children?: ReactNode;
  queryClient?: QueryClient;
  renderThemeProvider?: boolean;
  router?: RouterProviderProps['router'];
  theme?: CustomThemeConfig;
}) {
  const wrapperQueryClient = queryClient
    ? queryClient
    : new QueryClient(defaultQueryClientConfig);

  return (
    <StrictMode>
      <HelmetProvider>
        <QueryClientProvider client={wrapperQueryClient}>
          <ConditionalWrapper
            condition={renderThemeProvider}
            wrapper={(children) => (
              <ReeferThemeProvider
                renderProvider={(theme, children) => (
                  <ThemeProvider theme={theme}>{children}</ThemeProvider>
                )}
                theme={theme}
              >
                {children}
              </ReeferThemeProvider>
            )}
          >
            {router ? (
              <RouterProvider router={router} />
            ) : (
              <BrowserRouter>{children}</BrowserRouter>
            )}
          </ConditionalWrapper>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </HelmetProvider>
    </StrictMode>
  );
}

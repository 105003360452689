import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useTopOfMenuRow, useTopOfMenuRowTimer } from '@jane/dm/internal';
import type { Category, Filters } from '@jane/dm/sdk';
import { useSearchContext } from '@jane/search/providers';
import { formatFeaturedCategory } from '@jane/search/util';
import { MenuProductCarousel } from '@jane/shared-ecomm/components';
import { useMenu } from '@jane/shared-ecomm/providers';
import { CardCarousel } from '@jane/shared/components';
import { config } from '@jane/shared/config';
import type { MenuProduct } from '@jane/shared/models';
import { ZoneStoreMenu, ZoneStoreMenuInline } from '@jane/shared/models';
import { useMobileMediaQuery } from '@jane/shared/reefer';
import type { MenuCategoryPath } from '@jane/shared/types';
import { insertIfArr, sentenceCase } from '@jane/shared/util';

import { MenuProductCard } from '../../MenuComponents';
import type { MenuCarouselProps } from '../../MenuPages/MenuFeatured/MenuBuyAgainCarousel';

export const MenuSponsoredCarousel = ({
  mode = 'ideal-width',
}: MenuCarouselProps) => {
  const cardRef = useRef<{ resetScroll: () => void }>(null);
  const isMobile = useMobileMediaQuery({});
  const { category, featured_category: featuredCategory } = useParams<{
    category: MenuCategoryPath;
    featured_category: string;
  }>();

  const { searchState } = useSearchContext();
  const {
    appInfo: { appMode, janeDeviceId },
    listView,
    paths,
    store,
  } = useMenu();

  const isFeaturedPage = category === undefined;
  const isFilteringProducts =
    Object.values(searchState).filter(Boolean).length > 0;
  const filterByCategory = category && !['all'].includes(category);

  const { featuredTitle } = formatFeaturedCategory(store, featuredCategory);

  const nextBreadcrumbs = [
    ...insertIfArr(isFeaturedPage, {
      title: 'Featured',
      url: paths.menuCategory('featured'),
    }),
    ...insertIfArr(!!category, {
      title: category === 'all' ? 'All products' : sentenceCase(category || ''),
      url: paths.menuCategory(category as MenuCategoryPath),
    }),
    ...insertIfArr(!!featuredCategory, {
      title: featuredTitle,
      url: paths.menuFeaturedCategory(featuredCategory || ''),
    }),
  ];

  const isModalOpen = document.body.classList.contains(
    'ReactModal__Body--open'
  );

  const sponsoredRowFilters: Filters = {
    categories: (searchState?.filters?.['category'] as Category[]) || [],
    rootTypes: [
      ...(searchState?.filters?.['root_types'] || ''),
      filterByCategory ? category : '',
    ].filter(Boolean) as string[],
  };

  const { isTimerFinished, pauseTimer, restartTimer, unpauseTimer } =
    useTopOfMenuRowTimer(15);

  const { isError, isLoading, rowInstance } = useTopOfMenuRow({
    appMode,
    enableRefetch: !isModalOpen && !searchState?.searchText,
    filters: sponsoredRowFilters,
    isTimerFinished,
    jdid: janeDeviceId,
    pageCategory: category,
    restartTimer,
    storeId: Number(store.id),
  });

  const rowName = rowInstance?.title ?? '';
  const isInline = !isFeaturedPage || isFilteringProducts;

  const visibleProducts = useMemo(() => {
    return (
      rowInstance?.products.filter((product) =>
        Boolean(Object.keys(product.attributes).length)
      ) ?? []
    );
  }, [rowInstance?.products]);

  const hideSponsoredCarousel =
    isLoading || isError || !visibleProducts.length || searchState?.searchText;

  useEffect(() => {
    if (!hideSponsoredCarousel) {
      rowInstance?.render();

      if (cardRef.current) {
        cardRef.current.resetScroll();
      }
    }
  }, [rowInstance, hideSponsoredCarousel]);

  if (hideSponsoredCarousel) return null;

  return (
    <MenuProductCarousel
      cardRef={cardRef}
      mode={mode}
      listView={listView}
      name={rowName}
      onHover={() => {
        pauseTimer();
      }}
      onLeave={() => {
        unpauseTimer();
      }}
      variant={isInline ? 'sponsored-inline' : 'sponsored'}
      mx={isMobile ? 24 : 64}
      mb={24}
    >
      {visibleProducts.map((product, index) => (
        <CardCarousel.Card
          key={`row-ad-${product.productId}`}
          width="100%"
          widthMobile="100%"
        >
          <MenuProductCard
            algoliaIndexName={`menu-products-${config.algoliaEnv}`}
            appliedWeightFilter=""
            breadcrumbs={nextBreadcrumbs}
            carouselView={true}
            columnPosition={index}
            menuRowName={rowName}
            productLocation={`${
              featuredTitle || category || 'featured'
            }/${rowName}`}
            product={product.attributes as unknown as MenuProduct}
            topOfMenuRowProductInstance={product}
            rowPosition={0}
            zone={isInline ? ZoneStoreMenu : ZoneStoreMenuInline}
          />
        </CardCarousel.Card>
      ))}
    </MenuProductCarousel>
  );
};

import styled from '@emotion/styled';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { Card, Skeleton } from '@jane/shared/reefer';

const StoreBannerFallback = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    backgroundColor,
    height: '100%',
  })
);

export const StoreBannerImage = ({
  primaryGalleryImage,
}: {
  primaryGalleryImage: string | null;
}) => {
  const { storeId } = useContext(StoreDetailsContext);
  const { data: storeSettings, isFetching: storeSettingsLoading } =
    useStoreSettings(storeId);
  const [bannerLoaded, setBannerLoaded] = useState(false);

  const fallbackColor = useMemo(() => {
    if (
      storeSettings?.store?.white_label_enabled &&
      storeSettings?.store?.custom_theme_color
    ) {
      return storeSettings?.store?.custom_theme_color;
    }
    return '#fff';
  }, [JSON.stringify(storeSettings?.store)]);

  useEffect(() => {
    if (!primaryGalleryImage) {
      return;
    }

    const testLoader = new Image();
    testLoader.src = primaryGalleryImage;
    testLoader
      .decode()
      .then(() => {
        setBannerLoaded(true);
      })
      .catch(() => {
        setBannerLoaded(false);
      });
  }, [primaryGalleryImage]);

  return storeSettingsLoading ? (
    <Skeleton direction="column" animate={true} height="200px" width="100%">
      <Skeleton.Bone height="100%" width="100%" />
    </Skeleton>
  ) : bannerLoaded && primaryGalleryImage ? (
    <Card.Image height="200px" alt={'Store banner'} src={primaryGalleryImage} />
  ) : (
    <StoreBannerFallback backgroundColor={fallbackColor} />
  );
};

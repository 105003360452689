import type { CategoryOption, StoreCategory } from '@jane/shared/models';

export const separateKeys = (keys: string[], searchString: string) => {
  const childrenString: string[] = [];

  keys.forEach((key) => {
    const index = key.indexOf(`${searchString}/`);

    if (index !== -1) {
      childrenString.push(key);
    }
  });

  return { childrenString };
};

export const findKeysWithSubstring = (
  searchString: string,
  iterator: any = new Map(),
  result: string[] = []
): string[] => {
  const { value: key, done } = iterator.next();

  // If nothing left to iterate over, return the result
  if (done) {
    return result;
  }

  // Check if the current key includes the search string regardless of its position in the string
  if (key.includes(searchString)) {
    result.push(key);
  }

  return findKeysWithSubstring(searchString, iterator, result);
};

export const getChildrenKeys = (value: string, selectionMap: any) => {
  return findKeysWithSubstring(value, selectionMap.keys());
};

// shape the data from the b/e to play nicely with reefer
export const transformData = (data: StoreCategory[]): CategoryOption[] => {
  return data.map((category) => ({
    label: category.kind,
    value: category.kind.toLowerCase(),
    subItems: category.subtypes
      .filter((subtype) => subtype.subtype)
      .map((subtype) => ({
        label: subtype.subtype,
        value: subtype.subtype.toLowerCase(),
        subItems: subtype.brand_subtypes.map((brandSubtype) => ({
          label: brandSubtype,
          value: brandSubtype.toLowerCase(),
        })),
      })),
  }));
};

export const getModalCategoriesToShow = (
  selectionMap: any,
  indeterminates: Set<string>
): string[] => {
  const selectionMapKeys: string[] = [];

  Array.from(selectionMap.keys()).forEach((selectionMapKey: any) => {
    if (
      selectionMap.get(selectionMapKey) &&
      !indeterminates.has(selectionMapKey)
    ) {
      selectionMapKeys.push(selectionMapKey);
    }
  });

  const sortedKeys = selectionMapKeys.sort();

  const modalCategoriesToShow: string[] = [];

  sortedKeys.forEach((sortedKey) => {
    const splitKey = sortedKey.split('/');

    const isKind = splitKey.length === 1;
    const isSubCategory = splitKey.length === 2;
    const isBrandSubType = splitKey.length === 3;

    if (isKind) {
      modalCategoriesToShow.push(sortedKey);
    } else if (
      isSubCategory &&
      modalCategoriesToShow.indexOf(`${splitKey[0]}`) === -1
    ) {
      modalCategoriesToShow.push(sortedKey);
    } else if (
      // if the length is 3, make sure the previous 2 strings in the array when concat'd together aren't already in array
      isBrandSubType &&
      modalCategoriesToShow.indexOf(`${splitKey[0]}`) === -1 &&
      modalCategoriesToShow.indexOf(`${splitKey[0]}/${splitKey[1]}`) === -1
    ) {
      modalCategoriesToShow.push(sortedKey);
    }
  });

  return modalCategoriesToShow;
};

export const convertCategoryConditionToRules = (
  input: string[] | { label: string; value: string }[]
) => {
  if (!input?.length) return [];

  const rulesInput: any = input.map(
    (item: string | { label: string; value: string }) => {
      const parts =
        typeof item === 'string' ? item.split('/') : item.value.split('/');

      if (parts.length === 1) {
        return { kind: parts[0] };
      } else if (parts.length === 2) {
        return { kind: parts[0], root_subtype: parts[1] };
      } else if (parts.length === 3) {
        return {
          kind: parts[0],
          root_subtype: parts[1],
          brand_subtype: parts[2],
        };
      }

      return {};
    }
  );
  return rulesInput;
};

import styled from '@emotion/styled';

export const ButtonToggleStylesOverrideWrapper = styled.div(({ theme }) => ({
  '& > div': {
    backgroundColor: theme.colors.grays.ultralight,
    borderRadius: theme.borderRadius.sm,

    '> button': {
      borderRadius: theme.borderRadius.sm,
      paddingLeft: '24px',
      paddingRight: '24px',
      marginRight: 0,
    },
  },
}));

import styled from '@emotion/styled';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';

import { useStores } from '@jane/business-admin/data-access';
import { useKeyPress, useNavigateAndTrack } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { AbbreviatedStoreV2 } from '@jane/business-admin/types';
import {
  EventNames,
  NavigationSourceIds,
  concatAddress,
  track,
} from '@jane/business-admin/util';
import {
  Box,
  Card,
  CheckIcon,
  Flex,
  Grid,
  List,
  Loading,
  Modal,
  SearchField,
  Tag,
  Typography,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

const ModalContent = styled.div({}, ({ theme }) => ({
  overflowY: 'hidden',
  overflowX: 'hidden',
  height: '564px',
  width: '600px',
}));

const StaticSearch = styled.div({}, ({ theme }) => ({
  position: 'fixed',
  zIndex: 100,
  background: theme.colors.background,
  width: '100%',
}));

const ScrollableContent = styled(Flex)({
  height: 'calc(100% - 80px)',
  overflowY: 'auto',
  marginTop: 80,
  // TODO: We can hide the scrollbar? Styling it doesn't really seem to work
  // '&::-webkit-scrollbar': {
  //   display: 'none',
  // },
});

const LoadingWrapper = styled.div({
  position: 'relative',
  padding: 24,
  width: '100%',
  margin: 'auto',
});

const StoreItemBox = styled(Box)<{ selected: boolean }>(
  ({ selected, theme }) => ({
    background: selected ? theme.colors.brand.grape.light : 'transparent',
    '&:hover': {
      background: selected ? theme.colors.brand.grape.light : 'rgba(0,0,0,.1)',
    },
  }),
  {
    ...spacing({ py: 12, px: 24 }),
  }
);

const INITIAL_VISIBLE_STORE_COUNT = 50;
const VISIBLE_STORE_INCREMENT = 50;

export const StoreSwitcherModal = ({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigateAndTrack();
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visibleStoreCount, setVisibleStoreCount] = useState(
    INITIAL_VISIBLE_STORE_COUNT
  );
  const { data: allStores } = useStores({ includeCounts: false });
  const { ref, inView } = useInView();

  const location = useLocation();
  const tabRegex = /\/stores\/(\d*)\/(.*)/;
  const defaultTab = 'settings';
  const currentTab = useMemo(() => {
    const matches = location.pathname.match(tabRegex);
    return matches ? matches[2] || defaultTab : defaultTab;
  }, [location]);

  const currentStoreId = useMemo(() => {
    const matches = location.pathname.match(tabRegex);
    return matches ? +matches[1] : '';
  }, [location]);

  const filteredStores = useMemo(() => {
    if (allStores?.length) {
      const sortedStores = [...allStores];
      const currentIndex = sortedStores.findIndex(
        ({ id }) => id === currentStoreId
      );
      const [currentStore] = sortedStores.splice(currentIndex, 1);
      return [currentStore, ...sortedStores].filter(
        (store: AbbreviatedStoreV2) =>
          store.name.toLowerCase().includes(searchFilter) ||
          store.city?.toLowerCase().includes(searchFilter) ||
          store.id.toString() === searchFilter
      );
    }
    return [];
  }, [allStores, currentStoreId, searchFilter]);

  const selectedStore = useMemo(
    () => filteredStores[selectedIndex],
    [filteredStores, selectedIndex]
  );

  const onNavigate = (
    storeId: string,
    selectedStoreId: string,
    currentTab: string,
    keyboard = false
  ) => {
    setOpen(false);
    track({
      event: EventNames.ChangedStore,
      from_store_id: storeId,
      to_store_id: selectedStoreId,
    });
    navigate(
      `/stores/${selectedStoreId}/${currentTab}`,
      keyboard
        ? NavigationSourceIds.StoreSwitcherKeyboard
        : NavigationSourceIds.StoreSwitcherClick
    );
  };

  const { storeId } = useContext(StoreDetailsContext);
  const navigateToStoreKeyboard = useMemo(() => {
    return () => {
      if (selectedStore) {
        onNavigate(storeId, selectedStore.id.toString(), currentTab, true);
      }
    };
  }, [selectedStore, currentTab, storeId]);

  const navigateToStoreOnClick = useMemo(() => {
    return (id: string | number) => {
      onNavigate(storeId, id.toString(), currentTab);
    };
  }, [currentTab, storeId]);

  const incrementSelectedIndex = () => {
    if (selectedIndex < filteredStores.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const decrementSelectedIndex = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSearchFilter('');
  };

  useKeyPress(['ArrowDown'], incrementSelectedIndex);
  useKeyPress(['ArrowUp'], decrementSelectedIndex);
  useKeyPress(['Enter'], navigateToStoreKeyboard);

  useEffect(() => {
    if (inView) {
      setVisibleStoreCount(visibleStoreCount + VISIBLE_STORE_INCREMENT);
    }
  }, [inView, visibleStoreCount]);

  return (
    <Modal
      appId="root"
      variant="dialogue"
      overlayClose={true}
      onRequestClose={closeModal}
      open={isOpen}
    >
      <ModalContent>
        <StaticSearch>
          <Flex alignContent="center" mx={24} my={16} pb={0}>
            <SearchField
              width="100%"
              label=""
              name="search_input"
              placeholder="Search store name, city, or id"
              defaultValue={searchFilter}
              autoFocus={true}
              autocomplete="off"
              onChange={(val) => {
                setSearchFilter(val.toLowerCase());
                setSelectedIndex(0);
              }}
              isDebounced={false}
            />
          </Flex>
        </StaticSearch>
        <ScrollableContent flexDirection="column" pt={0} py={16}>
          <Flex width="100%" flexDirection="column" alignItems="center">
            <List label="List of stores" px={0} m={0}>
              {filteredStores
                ?.slice(0, visibleStoreCount)
                .map((store: AbbreviatedStoreV2, index: number) => (
                  <List.Item key={store.id} px={0} py={0}>
                    <StoreItemBox
                      width="100%"
                      height="100%"
                      selected={selectedIndex === index}
                    >
                      <Card
                        flat={true}
                        width="100%"
                        ariaLabel={`Switch to store ${store.name}`}
                        onClick={() => {
                          navigateToStoreOnClick(store.id);
                        }}
                      >
                        <Grid.Container direction="row">
                          <Grid.Item xs={2}>
                            <Tag
                              label={store.recreational ? 'Rec' : 'Med'}
                              mr={12}
                              mt={8}
                            />
                          </Grid.Item>
                          <Grid.Item xs={8}>
                            <Flex flexDirection="column">
                              <Flex>
                                <Typography
                                  variant={
                                    currentStoreId === store.id
                                      ? 'body-bold'
                                      : 'body'
                                  }
                                >
                                  {store.name}
                                </Typography>{' '}
                              </Flex>
                              <Flex>
                                <Typography color="grays-mid">
                                  {concatAddress(store)}
                                </Typography>
                              </Flex>
                            </Flex>
                          </Grid.Item>
                          <Grid.Item xs={2}>
                            <Flex
                              height="100%"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {currentStoreId === store.id ? (
                                <CheckIcon color="purple" />
                              ) : null}
                            </Flex>
                          </Grid.Item>
                        </Grid.Container>
                      </Card>
                    </StoreItemBox>
                  </List.Item>
                ))}
              {visibleStoreCount < filteredStores.length ? (
                <LoadingWrapper ref={ref}>
                  <Loading color="purple" />
                </LoadingWrapper>
              ) : null}
            </List>
          </Flex>
        </ScrollableContent>
      </ModalContent>
    </Modal>
  );
};

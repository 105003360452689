import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useManager } from '@jane/business-admin/data-access';
import { getManagerFlagContext } from '@jane/business-admin/util';
import { useFlagClient } from '@jane/shared/feature-flags';

export const IdentifyManager = () => {
  const flagClient = useFlagClient();
  const { data, isLoading: managerLoading } = useManager();
  const { manager } = data || {};
  const { pathname } = useLocation();

  const signedIn = useRef(false);

  const storeId = useMemo(() => {
    const matches = pathname.match(/(?<=\/stores\/)\d*/gm);
    return matches ? Number(matches[0]) : undefined;
  }, [pathname]);

  useEffect(() => {
    if (!managerLoading && manager?.id) {
      signedIn.current = true;

      if (flagClient) {
        flagClient.identify(getManagerFlagContext(manager, storeId));
      }

      janeMixpanel.identify(manager.id.toString());
      janeMixpanel.people.set({
        $email: manager.email,
        $first_name: manager.first_name,
        $last_name: manager.last_name,
        Permissions: manager.permissions,
        'User Id': manager.id,
        'User Role': manager.role,
      });

      janeMixpanel.people.set_once({
        $created: new Date(),
      });

      janeMixpanel.register({
        permissions: manager.permissions,
        user_role: manager.role,
        user_type: manager.internal ? 'internal' : 'external',
      });
    }
  }, [
    manager?.id,
    manager?.email,
    manager?.first_name,
    manager?.last_name,
    JSON.stringify(manager?.permissions),
    manager?.role,
    manager?.internal,
    JSON.stringify(manager?.stores),
    managerLoading,
    flagClient,
    storeId,
  ]);

  return null;
};

import { StaticContent } from '@jane/business-admin/components';
import { usePrivacyPolicy } from '@jane/business-admin/data-access';

export const PrivacyPolicy = () => {
  const { data: privacyPolicy } = usePrivacyPolicy();

  return (
    <StaticContent
      content={privacyPolicy?.text ?? ''}
      header="Privacy Policy"
    />
  );
};

import styled from '@emotion/styled';
import { useContext } from 'react';

import { useSendTestPrint } from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { JanePrinterV2 } from '@jane/business-admin/types';
import {
  EventNames,
  MEDIUM_SKELETON_WIDTH,
  ModalNames,
  track,
} from '@jane/business-admin/util';
import { Box, Flex, Link, Skeleton, Typography } from '@jane/shared/reefer';

import { TableCell } from '../../../../../TableCell';
import {
  CollapsedBorderTable,
  TableRowWithBorder,
} from '../../../../../TableWithBorderSeparator';
import { PrinterSettingsSection } from './PrinterSettingsSection';

export const LoadingPrinters = () => {
  return (
    <Flex flexDirection="column" width="100%">
      <Flex flexDirection="row" alignItems="center" mb={40}>
        <Flex width="100%" flexDirection="column">
          <Skeleton.Bone height="20px" width="80px" mb={4} />
          <Skeleton.Bone height="20px" width={MEDIUM_SKELETON_WIDTH} />
        </Flex>
        <Flex width="100%" flexDirection="row" justifyContent="flex-end">
          <Flex>
            <Skeleton.Bone height="20px" width={MEDIUM_SKELETON_WIDTH} />
          </Flex>
          <Flex>
            <Skeleton.Bone
              height="20px"
              width={MEDIUM_SKELETON_WIDTH}
              ml={16}
            />
          </Flex>
        </Flex>
      </Flex>

      <CollapsedBorderTable>
        <thead>
          <TableRowWithBorder hasBorder>
            <Flex flexDirection="row">
              <Flex justifyContent="start" width="45%">
                <TableCell>
                  <Skeleton.Bone height="20px" width="80px" />
                </TableCell>
              </Flex>
              <Flex justifyContent="start">
                <TableCell>
                  <Skeleton.Bone height="20px" width="80px" />
                </TableCell>
              </Flex>
            </Flex>
          </TableRowWithBorder>
        </thead>
        <tbody>
          <TableRowWithBorder hasBorder>
            <Flex>
              <Flex justifyContent="start" alignItems="center" width="45%">
                <TableCell>
                  <Skeleton.Bone height="20px" width="200px" />
                </TableCell>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width="55%"
              >
                <TableCell>
                  <Skeleton.Bone height="20px" width={MEDIUM_SKELETON_WIDTH} />
                </TableCell>
                <TableCell>
                  <Skeleton.Bone height="20px" width={MEDIUM_SKELETON_WIDTH} />
                </TableCell>
              </Flex>
            </Flex>
          </TableRowWithBorder>
          <TableRowWithBorder>
            <Flex>
              <Flex justifyContent="start" alignItems="center" width="45%">
                <TableCell>
                  <Skeleton.Bone height="20px" width="200px" mb={12} />
                </TableCell>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width="55%"
              >
                <TableCell>
                  <Skeleton.Bone
                    height="20px"
                    width={MEDIUM_SKELETON_WIDTH}
                    mb={12}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton.Bone
                    height="20px"
                    width={MEDIUM_SKELETON_WIDTH}
                    mb={12}
                  />
                </TableCell>
              </Flex>
            </Flex>
          </TableRowWithBorder>
        </tbody>
      </CollapsedBorderTable>
    </Flex>
  );
};

const Separator = styled(Box)({ flex: '1 1 auto' });

export const Printers = ({
  isInternal,
  janePrinters,
  onEditClick,
}: {
  isInternal: boolean;
  janePrinters: JanePrinterV2[];
  onEditClick: (janePrinter: JanePrinterV2) => void;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating printer. Please try again.'
  );
  const { storeId } = useContext(StoreDetailsContext);
  const {
    mutateAsync: sendTestPrint,
    isLoading: isSending,
    isSuccess: isSuccessSendTestPrint,
    isError: isErrorSendTestPrint,
  } = useSendTestPrint();

  useMutationStatusToasts({
    isMutating: isSending,
    isSuccess: isSuccessSendTestPrint,
    isError: isErrorSendTestPrint,
    successMessage: 'Test print sent',
    errorMessage: 'Error sending test print. Please try again.',
  });

  const onSendTestPrint = (storeId: number) => {
    track({
      event: EventNames.ClickedButton,
      button_label: 'send test print',
      modal_name: ModalNames.EditPrinter,
    });

    return catchSubmitErrors({
      submitMethod: () => sendTestPrint(storeId),
      requestData: {},
      onValidationError: () => null,
      callback: () => null,
    });
  };

  return (
    <Flex flexDirection="column" width="100%">
      {janePrinters.map((janePrinter, index) => (
        <div key={index}>
          <PrinterSettingsSection
            janePrinter={janePrinter}
            isInternal={isInternal}
            storeId={storeId}
            onEditClick={onEditClick}
          />

          <CollapsedBorderTable aria-label={`Printer ${index}`}>
            <thead>
              <TableRowWithBorder hasBorder>
                <Flex flexDirection="row">
                  <Flex justifyContent="start" width="45%">
                    <TableCell>
                      <Typography variant="caps" color="grays-mid">
                        Stores enabled
                      </Typography>
                    </TableCell>
                  </Flex>
                  <Flex justifyContent="start">
                    <TableCell>
                      <Typography variant="caps" color="grays-mid">
                        Store ID
                      </Typography>
                    </TableCell>
                  </Flex>
                </Flex>
              </TableRowWithBorder>
            </thead>

            <tbody>
              {janePrinter.store_jane_printers.map(
                (storePrinter, storePrinterIndex) => (
                  <TableRowWithBorder
                    hasBorder={
                      storePrinterIndex <
                      janePrinter.store_jane_printers.length - 1
                    }
                    key={storePrinter.id}
                  >
                    <Flex>
                      <Flex
                        justifyContent="start"
                        alignItems="center"
                        width="45%"
                      >
                        <TableCell>
                          <Typography my={12}>
                            {storePrinter.store_name}
                          </Typography>
                        </TableCell>
                      </Flex>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        width="55%"
                      >
                        <TableCell>
                          <Typography>{storePrinter.store_id}</Typography>
                        </TableCell>
                        <TableCell>
                          <Link
                            onClick={() =>
                              onSendTestPrint(storePrinter.store_id)
                            }
                          >
                            Send test print
                          </Link>
                        </TableCell>
                      </Flex>
                    </Flex>
                  </TableRowWithBorder>
                )
              )}
            </tbody>
          </CollapsedBorderTable>

          {index < janePrinters.length - 1 && (
            <Separator my={40} height={1} background="grays-light" mx={-40} />
          )}
        </div>
      ))}
    </Flex>
  );
};

import { useQuery } from '@tanstack/react-query';
import * as t from 'io-ts';

import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';

const tTag = t.interface({
  id: t.number,
  value: t.string,
  tag_type: t.string,
});
const tTagResponse = t.interface({ tags: t.array(tTag) });
export type Tag = t.TypeOf<typeof tTag>;
type TagResponse = t.TypeOf<typeof tTagResponse>;

const fetchTags = async (): Promise<TagResponse> => {
  const url = `/business/tags`;
  const tPayload = t.interface({
    tags: t.array(tTag),
  });
  const data = await fetchWithDecode(
    janeApiV2.get<TagResponse>(url),
    tPayload,
    url
  );

  return data;
};

export const useTags = () => {
  return useQuery<TagResponse>({
    queryFn: () => fetchTags(),
    queryKey: ['tags'],
    staleTime: Infinity,
    useErrorBoundary: true,
  });
};

import styled from '@emotion/styled';
import omit from 'lodash/omit';
import pluralise from 'pluralise';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import { useStores } from '@jane/business-admin/data-access';
import type { GlobalSpecialStore } from '@jane/business-admin/types';
import { MODAL_CARD_WIDTH, concatAddress } from '@jane/business-admin/util';
import {
  SUBMIT_BUTTON_VARIANTS,
  StoreSelectModal,
} from '@jane/shared-b2b/components';
import {
  Banner,
  Button,
  Card,
  EditIcon,
  ErrorIcon,
  Flex,
  Form,
  InfoIcon,
  Modal,
  Skeleton,
  Tag,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

const StyledStoreWrapper = styled(Flex)<{ isHidden: boolean }>(
  ({ isHidden }) => ({
    ...(isHidden && { display: 'none' }),
  })
);

const StoreRow = ({
  handleChange,
  index,
  isChecked,
  isHidden = false,
  isLast = false,
  store,
  storeAddress,
}: {
  handleChange: (checked: boolean) => void;
  index: number;
  isChecked?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  isLast?: boolean;
  store: GlobalSpecialStore;
  storeAddress: string;
}) => {
  const tagProps = store.recreational
    ? ({
        label: 'Rec',
        color: 'primary-dark',
        background: 'primary-light',
      } as const)
    : ({
        label: 'Med',
        color: 'palm-dark',
        background: 'palm-light',
      } as const);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <StyledStoreWrapper
          isHidden={isHidden}
          my={16}
          alignItems="center"
          gap={24}
        >
          <Tag {...tagProps} />
          <div>
            <Typography variant="body-bold">{store.name}</Typography>
            <Typography color="grays-mid">{storeAddress}</Typography>
          </div>
        </StyledStoreWrapper>
        <Flex gap={16} mx={8}>
          <Form.SwitchField
            label={`stores.${index}.enabled`}
            labelHidden
            name={`stores.${index}.enabled`}
            defaultChecked={isChecked}
            checked={isChecked}
            onChange={handleChange}
            shouldUnregister={false}
          />
          <Typography>{`${isChecked ? 'Enabled' : 'Disabled'}`}</Typography>
        </Flex>
      </Flex>
      {!isHidden && !isLast ? <Modal.ContentDivider padding={false} /> : null}
    </>
  );
};

const lengthEightArray = Array(8).fill(null);

const StoreSelectorSkeleton = () => {
  return (
    <Skeleton animate>
      <Skeleton.Bone height={48} mt={40} mb={16} />
      {lengthEightArray.map((_, i) => (
        <Skeleton.Bone key={`skeleton-${i}`} height={48} my={16} />
      ))}
    </Skeleton>
  );
};

export interface StoreSelectModalProps {
  disableSubmit?: boolean;
  specialIsLoading: boolean;
  submitButtonType?: SUBMIT_BUTTON_VARIANTS;
  subtitle?: string;
}

export const StoreSelectorCard = ({
  specialIsLoading,
}: StoreSelectModalProps) => {
  const [storeSelectModalOpen, setStoreSelectModalOpen] = useState(false);
  const [showToggleOffWarning, setShowToggleOffWarning] = useState(false);

  const { data: storesData, isFetching: isFetchingStores } = useStores();

  const {
    control,
    setValue,
    clearErrors,
    trigger,
    setError,
    watch,
    formState: { errors },
  } = useFormContext();
  const { fields: storeSelectFields, replace } = useFieldArray({
    control,
    name: 'stores',
    keyName: '_key',
  });

  const storesWatch = watch('stores');

  const [showAll, setShowAll] = useState(false);

  const isTogglingAll = useRef(false);

  useEffect(() => {
    isTogglingAll.current = false;
  }, [storesWatch]);

  const stores = useMemo(
    () =>
      storesWatch?.map(({ id, ...restStore }: any) => ({
        id: `${id}`,
        ...restStore,
      })),
    [storeSelectFields]
  );

  const storesForSelectModal = useMemo(
    () =>
      storesData?.map(({ id, ...restStore }) => ({
        id: `${id}`,
        ...restStore,
      })),
    [storesData]
  );

  const StoreIds = {
    asString: (id: number | string) => (typeof id === 'number' ? `${id}` : id),
    asNumber: (id: number | string) =>
      typeof id === 'string' ? parseInt(id, 10) : id,
  };

  const selectedStoreIds: string[] = (
    storeSelectFields as GlobalSpecialStore[]
  ).map((store: GlobalSpecialStore) => StoreIds.asString(store.id));

  const isStoreSelected = (storeId: number | string) => {
    const foundStore: GlobalSpecialStore | undefined = (
      storesWatch as GlobalSpecialStore[]
    ).find(
      (store: GlobalSpecialStore) =>
        StoreIds.asNumber(store.id) === StoreIds.asNumber(storeId)
    );

    return foundStore?.enabled || false;
  };

  const numStoresSelected = (storesWatch as GlobalSpecialStore[]).filter(
    (store: GlobalSpecialStore) => store.enabled
  ).length;

  const DEFAULT_STORES_IN_VIEW = 10;

  const handleSelectAll = (selected: boolean) => {
    const update = (storesWatch as GlobalSpecialStore[]).map(
      (store: GlobalSpecialStore) => ({
        ...store,
        enabled: selected,
      })
    );

    if (!selected) {
      setShowToggleOffWarning(true);
    }

    replace(update);
  };

  const handleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };
  const storesInViewIndex = !showAll
    ? DEFAULT_STORES_IN_VIEW
    : storeSelectFields.length;
  const storesInView = useMemo(
    () =>
      (storeSelectFields as GlobalSpecialStore[]).slice(0, storesInViewIndex),
    [storeSelectFields, showAll]
  );

  const storesList = () => {
    return (
      <>
        {storesInView.map((store: GlobalSpecialStore, index: number) => {
          const isChecked = isStoreSelected(store.id);
          const isHidden = !stores?.find(({ id }: any) => id === store.id);

          const changeHandler = (checked: boolean) => {
            if (isTogglingAll.current) return;

            setValue(`stores.${index}.enabled`, checked);
          };

          return (
            <StoreRow
              key={store._key}
              isHidden={!isHidden}
              isChecked={isChecked}
              index={index}
              store={store}
              storeAddress={concatAddress(store)}
              handleChange={changeHandler}
            />
          );
        })}

        {stores && stores.length > 10 && (
          <Flex alignItems="center" justifyContent="center" mt={24}>
            <Button
              mr={12}
              onClick={handleShowAll}
              label={`${showAll ? 'Show less' : 'Show all'}`}
              variant="primary"
            />
          </Flex>
        )}
      </>
    );
  };

  const onSelectStores = (storeIds: (string | number)[]) => {
    if (storeIds.length) {
      const currentStoreIds = storesInView.map(
        (store: GlobalSpecialStore) => store.id && StoreIds.asNumber(store.id)
      );

      const isCurrentStore = (id: number) => {
        return currentStoreIds.indexOf(id) >= 0;
      };

      /**
      Update stores list with newly selected stores but we need to compare
      against stores already shown to user since lists come from separate sources
      */
      const newStores: any =
        storesData
          ?.filter(
            (store: any) =>
              store.id !== undefined &&
              storeIds.includes(StoreIds.asNumber(store.id))
          )
          .map((store: any) => ({
            ...omit(store, [
              'allow_group_specials',
              'cart_limit_policy',
              'photo',
            ]),
            can_edit: true,
            enabled: isCurrentStore(store.id)
              ? storesWatch.find(
                  (currentStore: GlobalSpecialStore) =>
                    currentStore.id === store.id
                ).enabled
              : true,
          })) || [];

      // Update form field
      replace(newStores);

      clearErrors();
      trigger('email'); // Re-validate email field if needed
    } else {
      replace([]);
      setError('stores', { message: 'Please select at least 1 store.' });
    }

    setStoreSelectModalOpen(false);
  };

  const storesSubtext = `Available at ${numStoresSelected} ${pluralise(
    numStoresSelected,
    'store'
  )}`;

  return (
    <>
      <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
        <Card.Content>
          <Flex
            mb={8}
            justifyContent="space-between"
            p={24}
            flexDirection="column"
          >
            {!!errors['stores']?.message && (
              <Banner
                full
                variant="error"
                icon={<ErrorIcon />}
                label={errors['stores']?.message
                  ?.toString()
                  ?.replace(/stores/, '')}
                mt={16}
                mb={16}
              />
            )}
            <Flex justifyContent="space-between">
              <Flex mb={showToggleOffWarning ? 24 : 40} flexDirection="column">
                <Typography variant="header-bold">Stores</Typography>
                <Typography color="grays-mid">{storesSubtext}</Typography>
              </Flex>
              <Flex>
                {/* TODO: should any of these be disabled? */}
                <Button
                  mr={12}
                  onClick={() => handleSelectAll(true)}
                  label="Enable all"
                  variant="tertiary"
                />
                <Button
                  mr={12}
                  onClick={() => handleSelectAll(false)}
                  label="Disable all"
                  variant="tertiary"
                />
                <Button.Icon
                  icon={<EditIcon />}
                  onClick={() => setStoreSelectModalOpen(true)}
                  label="Edit stores"
                  variant="secondary"
                  data-testid="stores-edit"
                />
              </Flex>
            </Flex>
            {showToggleOffWarning && (
              <Banner
                icon={<InfoIcon />}
                label="Toggling off all stores will disable special"
                onDismiss={() => setShowToggleOffWarning(false)}
                typography="body"
                variant="info"
              />
            )}
            {!specialIsLoading ? (
              <Flex
                flexDirection="column"
                mt={showToggleOffWarning ? 12 : 0}
                mb={32}
              >
                {storesList()}
              </Flex>
            ) : (
              <StoreSelectorSkeleton />
            )}
          </Flex>
        </Card.Content>
      </Card>

      {storeSelectModalOpen && (
        <StoreSelectModal
          storesData={storesForSelectModal || []}
          isFetchingStores={isFetchingStores}
          onSubmit={onSelectStores}
          selectedStoreIds={selectedStoreIds}
          submitButtonType={SUBMIT_BUTTON_VARIANTS.select}
          closeModal={() => setStoreSelectModalOpen(false)}
        />
      )}
    </>
  );
};

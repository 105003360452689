import type { SortingState } from '@tanstack/react-table';
import get from 'lodash/get';
import set from 'lodash/set';

import type {
  AbbreviatedSpecialV2,
  SpecialRulesV2,
} from '@jane/business-admin/types';
import type { ProductConditions } from '@jane/shared/models';

export const getDiscountAmount = (row: AbbreviatedSpecialV2) => {
  let isPercent = false;
  let amount = 0;

  switch (row.discount_type) {
    case 'percent':
      amount = row.discount_percent || 0;
      isPercent = true;
      break;
    case 'dollar_amount':
      amount = row.discount_dollar_amount || 0;
      break;
    case 'target_price':
      amount = row.discount_target_price || 0;
      break;
  }

  if (isPercent) {
    return `${amount}%`;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(amount);
};

export const specialsSortParams = (sorting: SortingState) => {
  const isSorted = Array.isArray(sorting) && sorting.length > 0;

  if (!isSorted) return {};

  const sort = sorting[0].id;
  const order = sorting[0].desc ? 'desc' : 'asc';

  return { sort, order };
};

export const buildRules = ({
  excludeBrand = false,
  excludeCategory = false,
  excludeProduct = false,
  includeBrand = false,
  includeCategory = false,
  includeLineage = false,
  includePrices = false,
  includeProduct = false,
  includeWeight = false,
  includeWeightAndPrice = false,
}: {
  excludeBrand?: boolean;
  excludeCategory?: boolean;
  excludeProduct?: boolean | any;
  includeBrand?: boolean | any;
  includeCategory?: boolean | any;
  includeLineage?: boolean | any;
  includePrices?: boolean | any;
  includeProduct?: boolean | any;
  includeWeight?: boolean | any[];
  includeWeightAndPrice?: boolean | any[];
}) => {
  const rules: SpecialRulesV2 = {};
  if (excludeBrand) {
    rules['excludes'] = [
      {
        brands:
          typeof excludeBrand === 'boolean' ? ['Test Brand'] : excludeBrand,
      },
    ];
  }
  if (excludeCategory) {
    if (rules['excludes']) {
      rules['excludes'][0]['kinds'] = [{ kind: 'flower' }];
    } else {
      rules['excludes'] = [
        {
          kinds: [{ kind: 'flower' }],
        },
      ];
    }
  }
  if (excludeProduct) {
    const productRule =
      typeof excludeProduct === 'boolean' ? [1] : excludeProduct;
    set(rules, 'excludes.0.product_ids', productRule);
  }
  if (includeBrand) {
    const brandRule =
      typeof includeBrand === 'boolean' ? ['Test Brand'] : includeBrand;
    set(rules, 'includes.0.brands', brandRule);
  }
  if (includeCategory) {
    const categoryRule =
      typeof includeCategory === 'boolean'
        ? [{ kind: 'flower' }]
        : includeCategory;
    set(rules, 'includes.0.kinds', categoryRule);
  }
  if (includeLineage) {
    const lineageRule =
      typeof includeLineage === 'boolean' ? ['cbd'] : includeLineage;
    set(rules, 'includes.0.lineages', lineageRule);
  }
  if (includePrices) {
    const priceRule =
      typeof includePrices === 'boolean'
        ? {
            maximum_price: 100,
            minimum_price: 10,
          }
        : includePrices;
    rules['product_threshold'] = priceRule;
  }
  if (includeProduct) {
    const productRule =
      typeof includeProduct === 'boolean' ? [1] : includeProduct;
    set(rules, 'includes.0.product_ids', productRule);
  }
  if (includeWeight) {
    const weightRule =
      typeof includeWeight === 'boolean' ? ['half_gram'] : includeWeight;
    set(rules, 'includes.0.weights', weightRule);
  }
  if (includeWeightAndPrice) {
    const weightRule =
      typeof includeWeightAndPrice === 'boolean'
        ? [
            {
              price_id: 'half_gram',
              maximum_price: 20,
              minimum_price: 10,
            },
          ]
        : includeWeightAndPrice;
    set(rules, 'includes.0.weights_and_prices', weightRule);
  }
  return rules;
};

type BundleConditions = {
  brandConditions: boolean | any;
  excludedProductConditions: boolean | any;
  includedProductConditions: boolean | any;
  kindConditions: boolean | any;
  lineageConditions: boolean | any;
  priceConditions: boolean | any;
  weightConditions: boolean | any;
};
export const buildBundleConditions = (conditions: BundleConditions) => {
  const returnedConditions: ProductConditions = {};

  const brandCondition = get(conditions, `brandConditions`);
  if (brandCondition) {
    set(returnedConditions, `brands`, brandCondition);
  }

  const excludedProductConditions = get(
    conditions,
    `excludedProductConditions`
  );
  if (excludedProductConditions) {
    set(returnedConditions, `excluded_product_ids`, excludedProductConditions);
  }

  const includedProductConditions = get(
    conditions,
    `includedProductConditions`
  );
  if (includedProductConditions) {
    set(returnedConditions, `included_product_ids`, includedProductConditions);
  }

  const kindConditions = get(conditions, `kindConditions`);
  if (kindConditions) {
    set(returnedConditions, `kinds`, kindConditions);
  }

  const lineageConditions = get(conditions, `lineageConditions`);
  if (lineageConditions) {
    set(returnedConditions, `lineage`, lineageConditions[0]);
  }

  const priceConditions = get(conditions, `priceConditions`);
  if (priceConditions) {
    set(returnedConditions, `product_threshold`, priceConditions);
  }

  const weightConditions = get(conditions, `weightConditions`);
  if (weightConditions) {
    set(returnedConditions, `weights`, weightConditions);
  }

  return returnedConditions;
};

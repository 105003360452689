import { useContext, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Flex,
  Modal,
  SearchField,
  SearchIcon,
  Typography,
} from '@jane/shared/reefer';

import { CategoryList, StyledCategoryList } from './CategoryList';
import { CategorySelectInputModalContext } from './CategorySelectInputModalProvider';
import { getModalCategoriesToShow } from './util/rules';
import { searchCategoryTree } from './util/search';
import { countKeyTypes, pluralizeCounts } from './util/selectedText';

export type CategoryOption = {
  label: string;
  subItems?: CategoryOption[];
  value: string;
};

export type CategorySelectInputModalProps = {
  categoryOptions: CategoryOption[];
  onDirty?: (dirtyState: boolean) => void;
  onSave: (selectedCategories: any) => void;
};

export const isString = (value: string) => typeof value === 'string';

export const CategorySelectInputModal = ({
  categoryOptions,
  onSave,
}: CategorySelectInputModalProps) => {
  const {
    setSelectionMap,
    selectionMap,
    indeterminates,
    onDirty,
    setExpandAll,
    setHideSubItems,
  } = useContext(CategorySelectInputModalContext);

  const [categoryQuery, setCategoryQuery] = useState('');

  const [selectedText, setSelectedText] = useState('');

  const filteredCategories = useMemo(() => {
    return searchCategoryTree(categoryOptions, categoryQuery);
  }, [categoryOptions, categoryQuery]);

  const handleSelectAllCategories = () => {
    onDirty && onDirty(true);

    Array.from(selectionMap.keys()).forEach((selectionMapKey: string) => {
      selectionMap.set(selectionMapKey, true);
    });

    setSelectionMap(new Map(selectionMap));
  };

  const handleSelectNoneCategories = () => {
    onDirty && onDirty(true);

    Array.from(selectionMap.keys()).forEach((selectionMapKey: string) => {
      selectionMap.set(selectionMapKey, false);
    });

    setSelectionMap(new Map(selectionMap));
  };

  useEffect(() => {
    // How do we let someone pass in their own text formatter?
    const keyTypes = countKeyTypes(selectionMap, indeterminates);
    const formattedCounts = pluralizeCounts(keyTypes);

    setSelectedText(formattedCounts);
  }, [indeterminates, selectionMap]);

  const handleSubmit = () => {
    const modalCategoriesToShow: string[] = getModalCategoriesToShow(
      selectionMap,
      indeterminates
    );

    onSave(modalCategoriesToShow);
  };

  return (
    <>
      <Modal.Header
        title="Select categories"
        actions={
          <Button variant="primary" label="Save" onClick={handleSubmit} />
        }
      />
      <Modal.Content>
        <SearchField
          placeholder="Search"
          label="categorySearch"
          name="categorySearch"
          startIcon={<SearchIcon color="grays-black" />}
          mb={24}
          value={categoryQuery}
          onChange={(query) => {
            // Expand all items when searching
            setExpandAll(!!query);
            setCategoryQuery(query);
            if (!query) {
              // Collapse all items when search is cleared
              setHideSubItems({});
            }
          }}
        />
        <StyledCategoryList
          aria-label={`${filteredCategories[0]?.label}: sub items`}
          query={categoryQuery}
        >
          <CategoryList
            isLastCategory={false}
            query={categoryQuery}
            filteredCategories={filteredCategories}
            subList={false}
          />
        </StyledCategoryList>
      </Modal.Content>
      <Modal.Footer>
        <Flex justifyContent="space-between" alignItems="center">
          <Typography color="grays-mid">{selectedText}</Typography>
          <Flex gap={16}>
            <Button
              variant="tertiary"
              label="Select all"
              onClick={handleSelectAllCategories}
            />
            <Button
              variant="tertiary"
              label="Select none"
              onClick={handleSelectNoneCategories}
            />
          </Flex>
        </Flex>
      </Modal.Footer>
    </>
  );
};

import { useDebouncedTrack } from '@jane/business-admin/hooks';
import { EventNames, SettingNames, track } from '@jane/business-admin/util';
import type { JanePrinter as JanePrinterV2 } from '@jane/shared/models';
import { Card, Flex, Form, Modal, Typography } from '@jane/shared/reefer';

export const DetailSection = ({
  janePrinter,
}: {
  janePrinter?: JanePrinterV2 | null;
}) => {
  const trackTextChange = useDebouncedTrack(1000);

  const handleTextEdit = (section: string) => {
    return trackTextChange({
      event: EventNames.ModifiedSetting,
      revert: false,
      setting_name:
        section === 'name'
          ? SettingNames.PrinterName
          : SettingNames.PrinterMACAddress,
    });
  };

  const handleCheckboxChange = ({
    name,
    checked,
  }: {
    checked: boolean;
    name: string;
  }) => {
    const eventName = checked
      ? EventNames.SelectedCheckbox
      : EventNames.DeselectedCheckbox;

    track({
      event: eventName,
      checkbox_label: name,
    });
  };

  return (
    <Card border="grays-light" width={944} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <Typography variant="header-bold" mb={24}>
            Details
          </Typography>

          <Flex flexDirection="row">
            <Form.TextField
              label="Printer name"
              name="name"
              defaultValue={janePrinter?.name || ''}
              required
              mr={12}
              onChange={() => handleTextEdit('name')}
            />
            <Form.TextField
              label="MAC address"
              name="mac_address"
              defaultValue={janePrinter?.mac_address || ''}
              required
              ml={12}
              onChange={() => handleTextEdit('mac_address')}
            />
          </Flex>

          <Modal.ContentDivider />

          <Typography variant="body-bold">Printer settings</Typography>
          <Typography color="text-light" mb={12}>
            Select the order fulfillment types to send to this printer.
          </Typography>

          <Flex flexDirection="row">
            <Flex flexDirection="column" width="50%" mr={12}>
              <Form.CheckboxField
                defaultChecked={janePrinter?.pickup_order_checked_out}
                label="Pickup checkout"
                name="pickup_order_checked_out"
                mb={24}
                mt={12}
                onChange={(checked) =>
                  handleCheckboxChange({
                    name: 'pickup_order_checked_out',
                    checked,
                  })
                }
              />
              <Form.CheckboxField
                defaultChecked={janePrinter?.curbside_order_checked_out}
                label="Curbside checkout"
                name="curbside_order_checked_out"
                mb={24}
                onChange={(checked) =>
                  handleCheckboxChange({
                    name: 'curbside_order_checked_out',
                    checked,
                  })
                }
              />
              <Form.CheckboxField
                defaultChecked={janePrinter?.curbside_arrived}
                label="Curbside arrived"
                name="curbside_arrived"
                mb={24}
                onChange={(checked) =>
                  handleCheckboxChange({
                    name: 'curbside_arrived',
                    checked,
                  })
                }
              />
            </Flex>
            <Flex flexDirection="column" width="50%" ml={12}>
              <Form.CheckboxField
                defaultChecked={janePrinter?.kiosk_order_checked_out}
                label="Kiosk checkout"
                name="kiosk_order_checked_out"
                mb={24}
                mt={12}
                onChange={(checked) =>
                  handleCheckboxChange({
                    name: 'kiosk_order_checked_out',
                    checked,
                  })
                }
              />
              <Form.CheckboxField
                defaultChecked={janePrinter?.delivery_order_checked_out}
                label="Delivery checkout"
                name="delivery_order_checked_out"
                mb={24}
                onChange={(checked) =>
                  handleCheckboxChange({
                    name: 'delivery_order_checked_out',
                    checked,
                  })
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
};

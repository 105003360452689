import type { RumInitConfiguration } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';
import isEmpty from 'lodash/isEmpty';

import { config } from '@jane/shared/config';

export const initDatadogRum = (rumConfig: RumInitConfiguration) => {
  const { applicationId, clientToken, service } = rumConfig;
  // These env vars are only provided on production, so we don't init on dev env
  if (!isEmpty(applicationId) && !isEmpty(clientToken) && !isEmpty(service)) {
    const defaultConfig = {
      defaultPrivacyLevel: 'mask-user-input' as const,
      env: config.datadogEnv,
      sessionReplaySampleRate: 10,
      sessionSampleRate: 10,
      site: 'datadoghq.com',
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      version: config.buildVersion,
    };

    datadogRum.init({
      ...defaultConfig,
      ...rumConfig,
    });
    datadogRum.startSessionReplayRecording();
  } else {
    console.error(
      'Datadog RUM was not initialized. Missing required config: applicationId, clientToken, service'
    );
  }
};

import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Form, Typography } from '@jane/shared/reefer';

export const CartTotal = ({ name }: SpecialTypeProps['cart_total']) => {
  return (
    <Flex mb={24} width={'100%'} flexDirection="column">
      <>
        <Form.NumberField
          width="50%"
          name={`${name}.threshold`}
          label="Cart total threshold"
          mb={4}
        />
        <Typography color="grays-mid" variant="body" mt={16}>
          Enter the cart total the customer must reach to activate this special.
        </Typography>
      </>
    </Flex>
  );
};

import styled from '@emotion/styled';

export const ListContainer = styled.div<{ rowEnabled: boolean }>(
  ({ rowEnabled, theme }) => ({
    paddingBottom: 5,
    paddingTop: 32,
    background: rowEnabled ? 'transparent' : theme.colors.grays.ultralight,
    borderBottom: !rowEnabled
      ? `1px solid ${theme.colors.grays.light}`
      : 'none',
    // Weird margins to make background span entire width of container
    margin: '0 -64px',
    paddingLeft: 64,
    paddingRight: 64,
  })
);

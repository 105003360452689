import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

import type { StoreSettingsPayload } from '@jane/business-admin/types';

interface PosSyncMap {
  isJanePosSynced: boolean;
  posSource: string | null;
  posSynced: boolean;
}

interface Props {
  children: ReactNode;
  hasDuplicationErrors?: boolean;
  isReadOnly?: boolean;
  posSyncMap: PosSyncMap;
  storeSettings?: StoreSettingsPayload;
}

export const SpecialsModalContext = React.createContext<
  Omit<Props, 'children'>
>({
  posSyncMap: { posSource: '', posSynced: false, isJanePosSynced: false },
});

export const SpecialsModalProvider = ({
  children,
  hasDuplicationErrors,
  isReadOnly,
  posSyncMap,
  storeSettings,
}: Props) => {
  const contextValue = useMemo(
    () => ({
      posSyncMap,
      hasDuplicationErrors,
      isReadOnly,
      storeSettings,
    }),
    [posSyncMap, hasDuplicationErrors, isReadOnly, storeSettings]
  );

  return (
    <SpecialsModalContext.Provider value={contextValue}>
      {children}
    </SpecialsModalContext.Provider>
  );
};

import styled from '@emotion/styled';
import bbox from '@turf/bbox';
import type { FeatureCollection, Polygon } from 'geojson';
import type { Map as MapType } from 'mapbox-gl';
import { LngLatBounds } from 'mapbox-gl';
import type { Dispatch, SetStateAction } from 'react';
import {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useCreateGeofence,
  useUpdateGeofence,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useDebouncedTrack,
} from '@jane/business-admin/hooks';
import {
  FulfillmentSettingsContext,
  StoreDetailsContext,
} from '@jane/business-admin/providers';
import type { DeliveryGeofenceV2 } from '@jane/business-admin/types';
import {
  EventNames,
  GEOFENCE_DISCLAIMER_TEXT,
  ModalNames,
  SettingNames,
  getLeadTimeTrackSetting,
  getWindowIntervalTrackSetting,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { GeofenceMap, LoadingWrapper } from '@jane/shared/components';
import {
  Banner,
  Button,
  Flex,
  Form,
  FormValidationError,
  InfoIcon,
  Modal,
  useForm,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../../../ConfirmWrapperWithTracking';
import { DollarInput } from '../../../../../../../DollarInput';
import { HourAndMinuteSelector } from '../HourAndMinuteSelector';
import { LeadTimeSettingsSection } from '../LeadTimeSettingsSection';

interface FormData {
  cart_minimum?: number;
  display_windows_by_day?: boolean;
  fee?: number;
  geojson?: string;
  id?: number;
  interval_minutes?: number;
  last_call_minutes?: number;
  lead_time_minutes?: number;
  max_lead_time_minutes?: number;
  min_lead_time_minutes?: number;
  name?: string;
  window_minutes?: number;
}

const getBounds = (geojson?: FeatureCollection<Polygon>) => {
  const box = geojson && bbox(geojson);
  return (
    box &&
    new LngLatBounds([
      [box[0], box[1]],
      [box[2], box[3]],
    ])
  );
};

const FORM_ERROR_NAME = 'geofence-error';

const MapWrapper = styled.div({
  flex: 1,
  height: 'calc(100% + 80px)',
});

export const GeofenceModal = ({
  setOpen,
  geofence,
}: {
  geofence: DeliveryGeofenceV2 | null;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const createMode = !geofence?.id;
  const { storeId } = useContext(StoreDetailsContext);
  const { mutateAsync: createGeofence, isSuccess: createSuccess } =
    useCreateGeofence(storeId);
  const { mutateAsync: updateGeofence, isSuccess: updateSuccess } =
    useUpdateGeofence(storeId);

  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error ${createMode ? 'creating' : 'updating'} geofence. Please try again.`
  );

  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
    setError,
    setValue,
    watch,
  } = formMethods;
  const formGeoJSON = watch('geojson');
  const fulfillmentWindowOptions = [
    { label: 'minutes', value: 'minutes' },
    { label: 'day', value: 'day' },
  ];
  const formWindowBy = watch('display_windows_by_day');

  const {
    storePayload: { store },
  } = useContext(FulfillmentSettingsContext);
  const [showDiscard, setShowDiscard] = useState(false);

  // Logic for map methods copied from: libs/monolith/shared/src/lib/business/components/storeEdit/deliveryGeofence/editDeleteForm.tsx
  const [draw, setDraw] = useState<MapboxDraw | undefined>();
  const [map, setMap] = useState<MapType | undefined>();
  const [geoJSONFromMap, setGeoJSONFromMap] = useState<string>(
    geofence?.geojson || ''
  );

  useEffect(() => {
    // Update the geoJSON input when the map is updated
    setValue('geojson', geoJSONFromMap, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [geoJSONFromMap]);

  useEffect(() => {
    // Basically a hidden field to track geofence id, if it exists
    if (geofence?.id) {
      setValue('id', geofence.id);
    }
  }, [geofence?.id]);

  const onSubmit = (saveData: FormData) => {
    const submitMethod = () => {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: `${createMode ? 'Add' : 'Edit'} Geofence`,
        changed_attributes: Object.keys(dirtyFields),
      });
      return createMode ? createGeofence(saveData) : updateGeofence(saveData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData: saveData,
      onValidationError: (validationErrors: {
        geofence_schema?: Record<string, unknown>;
        update_schema?: Record<string, unknown>;
      }) => {
        const geofenceErrors = parseValidationErrors(
          validationErrors?.geofence_schema || {}
        );
        const updateErrors = parseValidationErrors(
          validationErrors?.update_schema || {}
        );
        throw new FormValidationError(FORM_ERROR_NAME, [
          ...geofenceErrors,
          ...updateErrors,
        ]);
      },
    });
  };

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      setOpen(false);
    }
  }, [createSuccess, updateSuccess]);

  const updateMapFromGeoJSON = () => {
    try {
      const geojson = JSON.parse(formGeoJSON);
      draw?.set(geojson);
      const bounds = geojson && getBounds(geojson);

      map && map.fitBounds(bounds);
    } catch (error) {
      if (typeof error === 'string') {
        setError('geojson', { type: 'custom', message: error });
      } else if (error instanceof Error) {
        setError('geojson', { type: 'custom', message: error.message });
      }
    }
  };

  const setGenericError = (hasError: boolean) => {
    if (hasError) {
      setError('geojson', {
        type: 'custom',
        message: 'There is an error with your GeoJSON.',
      });
    }
  };

  const handleCancel = useCallback(() => {
    if (!isDirty) return setOpen(false);
    setShowDiscard(true);
  }, [isDirty]);

  const trackName = useDebouncedTrack(1000);

  const handleLeadTimeTracking = (unitToUpdate: string, isMin: boolean) => {
    track({
      event: EventNames.ModifiedSetting,
      setting_name: getLeadTimeTrackSetting(unitToUpdate, isMin),
      revert: false,
      modal_name: ModalNames.Geofence,
    });
  };

  const handleWindowIntervalTracking = (
    unitToUpdate: string,
    windowOrInterval: string
  ) => {
    track({
      event: EventNames.ModifiedSetting,
      setting_name: getWindowIntervalTrackSetting(
        unitToUpdate,
        windowOrInterval
      ),
      revert: false,
      modal_name: ModalNames.Geofence,
    });
  };

  const center: [number, number] | undefined = useMemo(
    () =>
      typeof store.longitude === 'number' && typeof store.latitude === 'number'
        ? [store.longitude, store.latitude]
        : undefined,
    [store]
  );

  const parsedGeoJSON = useMemo(() => {
    if (geofence?.geojson) {
      try {
        return JSON.parse(geofence.geojson);
      } catch (error) {
        if (error instanceof Error) {
          setError('geojson', { type: 'custom', message: error.message });
        }
      }
    }
    return undefined;
  }, [geofence?.geojson]);

  useEffect(() => {
    // Hidden input since there are two dropdowns that control one field
    setValue('window_minutes', geofence?.window_minutes);
  }, [geofence?.window_minutes]);

  const onWindowChange = (value: number) => {
    setValue('window_minutes', value);
  };

  useEffect(() => {
    // Hidden input since there are two dropdowns that control one field
    setValue('interval_minutes', geofence?.interval_minutes);
  }, [geofence?.interval_minutes]);

  const onIntervalChange = (value: number) => {
    setValue('interval_minutes', value);
  };

  const dataMinutes = (
    dataMinutes: number,
    geofenceMinutes: number | null | undefined
  ) => {
    if (dataMinutes >= 0) return dataMinutes;
    else if (geofenceMinutes) return geofenceMinutes;
    return 0;
  };

  interface NewFields {
    [key: string]: boolean | number | null;
    display_windows_by_day: boolean;
    interval_minutes: number;
    lead_time_minutes: number;
    max_lead_time_minutes: number;
    min_lead_time_minutes: number;
    window_minutes: number;
  }

  const submitForm = useCallback(
    (data: any) => {
      let submitData = {
        ...data,
        geojson: JSON.parse(data.geojson || ''),
      };
      const newFields: NewFields = {
        display_windows_by_day: formWindowBy === 'day',
        lead_time_minutes: dataMinutes(
          data?.min_lead_time_minutes,
          geofence?.min_lead_time_minutes
        ),
        min_lead_time_minutes: dataMinutes(
          data?.min_lead_time_minutes,
          geofence?.min_lead_time_minutes
        ),
        max_lead_time_minutes: dataMinutes(
          data?.max_lead_time_minutes,
          geofence?.max_lead_time_minutes
        ),
        interval_minutes: data.interval_minutes || 0,
        window_minutes: data.window_minutes || 0,
      };

      // Number input returns undefined for empty input (infinite) value
      // Backend wants null for "infinite" value
      // Context: https://app.clickup.com/t/866a4k8yw
      newFields['max_orders_per_window'] =
        data?.max_orders_per_window === undefined
          ? null
          : data?.max_orders_per_window;
      submitData = { ...submitData, ...newFields };
      delete submitData.display;
      delete submitData.display_max_days;
      delete submitData.display_max_hours;
      delete submitData.display_max_minutes;
      delete submitData.display_min_days;
      delete submitData.display_min_hours;
      delete submitData.display_min_minutes;
      return onSubmit(submitData);
    },
    [formWindowBy]
  );

  return (
    <ConfirmWrapperWithTracking
      variant="full-screen"
      background="grays-white"
      open
      setOpen={setOpen}
      hasChanges={isDirty}
      showDiscardChanges={showDiscard}
      onCloseDiscardChanges={() => setShowDiscard(false)}
      modalName={`${createMode ? 'Add' : 'Edit'} Geofence`}
    >
      <Form.BaseForm
        name="Geofence settings form"
        formMethods={formMethods}
        onSubmit={submitForm}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={`${createMode ? 'Add' : 'Edit'} geofence`}
          actions={
            <>
              <Button
                onClick={handleCancel}
                variant="secondary"
                mr={12}
                label="Cancel"
              />
              <Form.SubmitButton
                variant="primary"
                label={createMode ? 'Save' : 'Update'}
              />
            </>
          }
        />

        <Modal.Content padding={false}>
          <Flex height="100%">
            <MapWrapper>
              <Suspense fallback={<LoadingWrapper isLoading />}>
                <GeofenceMap
                  geojson={parsedGeoJSON}
                  setDraw={setDraw}
                  setMap={setMap}
                  setGeoJSON={setGeoJSONFromMap}
                  setInvalidJSON={setGenericError}
                  center={center}
                />
              </Suspense>
            </MapWrapper>
            <Flex
              width="37%"
              flexDirection="column"
              overflow="hidden auto"
              p={40}
            >
              <Form.ErrorBanner name={FORM_ERROR_NAME} />
              {/* TODO: Maybe do this someday? Will need to use turfjs for area: https://turfjs.org/docs/#area
              <Flex width="100%" mb={24}>
              <Flex width="100%" flexDirection="column">
              <Typography color="grays-mid" mb={4}>
              Total area
                  </Typography>
                  <Typography>420 miles</Typography>
                </Flex>

                <Flex width="100%" flexDirection="column">
                  <Typography color="grays-mid" mb={4}>
                    Furthest point
                  </Typography>
                  <Typography>12.1 miles</Typography>
                </Flex>
              </Flex> */}
              <Form.TextField
                defaultValue={geofence?.name || undefined}
                label="Nickname"
                name="name"
                mb={24}
                onChange={() => {
                  trackName({
                    event: EventNames.ModifiedSetting,
                    setting_name: SettingNames.GeofenceName,
                    revert: false,
                    modal_name: ModalNames.Geofence,
                  });
                }}
              />
              <Flex width="100%" gap={20} mb={24}>
                <DollarInput
                  defaultValue={parseFloat(geofence?.fee || '0.0')}
                  label="Delivery fee"
                  name="fee"
                  required
                  onChange={() => {
                    track({
                      event: EventNames.ModifiedSetting,
                      setting_name: SettingNames.GeofenceDeliveryFee,
                      revert: false,
                      modal_name: ModalNames.Geofence,
                    });
                  }}
                />
                <DollarInput
                  defaultValue={parseFloat(geofence?.cart_minimum || '0.0')}
                  label="Minimum"
                  name="cart_minimum"
                  required
                  onChange={() => {
                    track({
                      event: EventNames.ModifiedSetting,
                      setting_name: SettingNames.GeofenceCartMinimum,
                      revert: false,
                      modal_name: ModalNames.Geofence,
                    });
                  }}
                />
              </Flex>

              <Banner
                label={GEOFENCE_DISCLAIMER_TEXT}
                icon={
                  <Flex alignItems="center" height="100%">
                    <InfoIcon />
                  </Flex>
                }
                variant={'info'}
                mb={24}
              />
              <Flex width="100%" gap={20}>
                <LeadTimeSettingsSection
                  defaultValue={geofence?.min_lead_time_minutes || 0}
                  isMin={true}
                  handleLeadTimeTracking={(settingUnit, isMin) =>
                    handleLeadTimeTracking(settingUnit, isMin)
                  }
                />
              </Flex>
              <Flex width="100%" gap={20}>
                <LeadTimeSettingsSection
                  defaultValue={geofence?.max_lead_time_minutes || 0}
                  isMin={false}
                  handleLeadTimeTracking={(settingUnit, isMin) =>
                    handleLeadTimeTracking(settingUnit, isMin)
                  }
                />
              </Flex>
              <Flex width="100%" gap={20} mb={24}>
                <Form.NumberField
                  defaultValue={geofence?.last_call_minutes || 0.0}
                  endUnit="minutes"
                  width="100%"
                  label="Last call"
                  name="last_call_minutes"
                  required
                  onChange={() => {
                    track({
                      event: EventNames.ModifiedSetting,
                      setting_name: SettingNames.GeofenceLastCallMinutes,
                      revert: false,
                      modal_name: ModalNames.Geofence,
                    });
                  }}
                />

                <Form.NumberField
                  defaultValue={geofence?.max_orders_per_window || undefined}
                  width="100%"
                  label="Max orders per window"
                  name="max_orders_per_window"
                  onChange={() => {
                    track({
                      event: EventNames.ModifiedSetting,
                      setting_name: SettingNames.GeofenceMaxOrdersPerWindow,
                      revert: false,
                      modal_name: ModalNames.Geofence,
                    });
                  }}
                />
              </Flex>
              <Flex width="100%" gap={20} mb={24}>
                <Form.SelectField
                  defaultValue={
                    geofence?.display_windows_by_day ? 'day' : 'minutes'
                  }
                  options={fulfillmentWindowOptions}
                  width="100%"
                  name="display_windows_by_day"
                  label="Delivery window by"
                  required
                  onChange={() => {
                    track({
                      event: EventNames.ModifiedSetting,
                      setting_name: SettingNames.GeofenceDisplayWindowsByDay,
                      revert: false,
                      modal_name: ModalNames.Geofence,
                    });
                  }}
                />
              </Flex>
              <Flex width="100%" gap={20}>
                <Flex width="100%" mb={24} flexDirection="column">
                  <HourAndMinuteSelector
                    defaultValue={geofence?.window_minutes || 0}
                    fulfillmentType="delivery"
                    disabled={formWindowBy === 'day'}
                    window={true}
                    canSetZeroValue
                    onDurationChange={onWindowChange}
                    handleWindowIntervalTracking={(
                      settingUnit,
                      windowOrInterval
                    ) =>
                      handleWindowIntervalTracking(
                        settingUnit,
                        windowOrInterval
                      )
                    }
                  />
                </Flex>
                <Flex width="100%" mb={24} flexDirection="column">
                  <HourAndMinuteSelector
                    defaultValue={geofence?.interval_minutes || 0}
                    fulfillmentType="delivery"
                    disabled={formWindowBy === 'day'}
                    window={false}
                    canSetZeroValue
                    onDurationChange={onIntervalChange}
                    handleWindowIntervalTracking={(
                      settingUnit,
                      windowOrInterval
                    ) =>
                      handleWindowIntervalTracking(
                        settingUnit,
                        windowOrInterval
                      )
                    }
                  />
                </Flex>
              </Flex>

              <Form.TextAreaField
                defaultValue={geofence?.geojson}
                name="geojson"
                label="GeoJSON text"
                rows={3}
                required
                validate={(val: any) => {
                  try {
                    JSON.parse(val);
                    return true;
                  } catch (error: any) {
                    setError('geojson', {
                      type: 'custom',
                      message: 'Unexpected end of JSON input',
                    });
                    return 'Unexpected end of JSON input';
                  }
                }}
                onChange={() => {
                  track({
                    event: EventNames.ModifiedSetting,
                    setting_name: SettingNames.GeofenceGeoJSONText,
                    revert: false,
                    modal_name: ModalNames.Geofence,
                  });
                }}
              />
              <Flex my={24} justifyContent="center" width="100%">
                <Button
                  label="Update Map Preview"
                  onClick={updateMapFromGeoJSON}
                />
              </Flex>
            </Flex>
          </Flex>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

import startCase from 'lodash/startCase';
import { useMemo } from 'react';

import type { NuProductType, ProductKind } from '@jane/shared/models';

type SubcategoryOptions = Record<
  ProductKind,
  Array<{ label: string; value: string }>
>;

export function useProductTypeSelectOptions(
  allProductTypes: NuProductType[] | undefined,
  addAllOption: boolean
) {
  const categoryOptions = useMemo(() => {
    if (!allProductTypes) return [];

    return allProductTypes.reduce<{ label: string; value: string }[]>(
      (opts, { product_type, product_subtype }) => {
        if (product_subtype) return opts;
        opts.push({ label: startCase(product_type), value: product_type });
        return opts;
      },
      []
    );
  }, [allProductTypes]);

  const subcategoryOptions = useMemo(() => {
    if (!allProductTypes) return {} as SubcategoryOptions;

    return allProductTypes.reduce(
      (grouped, { product_type, product_subtype }) => {
        const defaultArray = addAllOption
          ? [{ label: 'All', value: 'all' }]
          : [];
        grouped[product_type] = grouped[product_type] || defaultArray;

        if (product_subtype) {
          grouped[product_type].push({
            label: product_subtype,
            value: product_subtype,
          });
        }

        return grouped;
      },
      {} as SubcategoryOptions
    );
  }, [allProductTypes]);

  return { categoryOptions, subcategoryOptions };
}

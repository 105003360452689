import type { ReactNode } from 'react';
import React from 'react';

interface Props {
  children: ReactNode;
  filteredStores: any;
  isFetchingStores: boolean;
  searchFilter: string;
  setSearchFilter: any;
  setStateFilter: any;
  setTypeFilter: any;
  stateFilter: string;
  typeFilter: string;
}

export const StoreFiltersContext = React.createContext<Omit<Props, 'children'>>(
  {
    typeFilter: '',
    setTypeFilter: () => {
      return null;
    },
    stateFilter: '',
    setStateFilter: () => {
      return null;
    },
    searchFilter: '',
    setSearchFilter: () => {
      return null;
    },
    filteredStores: [],
    isFetchingStores: true,
  }
);

export const StoreFiltersProvider = ({
  children,
  typeFilter,
  setTypeFilter,
  stateFilter,
  setStateFilter,
  searchFilter,
  setSearchFilter,
  filteredStores,
  isFetchingStores,
}: Props) => (
  <StoreFiltersContext.Provider
    value={{
      typeFilter,
      setTypeFilter,
      stateFilter,
      setStateFilter,
      searchFilter,
      setSearchFilter,
      filteredStores,
      isFetchingStores,
    }}
  >
    {children}
  </StoreFiltersContext.Provider>
);

import type { InfiniteData } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import type {
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  fetchProductQueryKey,
  useFetchProducts,
  useUpdateProductVisibility,
} from '@jane/business-admin/data-access';
import { useHasPermissions } from '@jane/business-admin/hooks';
import { ProductsTableContext } from '@jane/business-admin/providers';
import type {
  MenuProductForProductsTable,
  ProductIndexResponse,
} from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import { Form, useForm, useToast } from '@jane/shared/reefer';

import { BaseProductTable } from './BaseProductTable';
import { EmptyState } from './EmptyStates';
import { buildPublishedAndHiddenColumns } from './PublishedAndHiddenColumns';

export const filterParams = (columnFilters: ColumnFiltersState) => {
  return Object.fromEntries(
    columnFilters
      .filter(({ id, value }) => Array.isArray(value) || id === 'name')
      .map(({ id, value }) => {
        if (id === 'content_type') {
          id = 'content';
        } else if (id === 'name') {
          id = 'query';
          return [id, value];
        }

        return [id, (value as unknown[]).join(',')];
      })
  );
};

export const sortParams = (sorting: SortingState) => {
  const isSorted = Array.isArray(sorting) && sorting.length > 0;
  if (!isSorted) return {};

  let id = sorting[0].id;
  const order = sorting[0].desc ? 'desc' : 'asc';

  if (id === 'content_type') {
    id = 'content';
  } else if (id === 'pos_sku') {
    id = 'sku';
  }

  return { sort: id, order: order };
};

export const PublishedProductTable = ({
  columnFiltersParent,
  isBulkEditTable = false,
  selectedProducts,
  setSelectedProducts,
  setColumnFiltersParent,
  bulkEditModalOpen,
}: {
  bulkEditModalOpen: boolean;
  columnFiltersParent: any;
  isBulkEditTable?: boolean;
  selectedProducts: RowSelectionState;
  setColumnFiltersParent?: any;
  setSelectedProducts: any;
}) => {
  const userCanEditProducts = useHasPermissions([Permission.EditProducts]);
  const { setCanEditProducts } = useContext(ProductsTableContext);

  useEffect(() => {
    setCanEditProducts(userCanEditProducts);
  }, [userCanEditProducts]);

  const formMethods = useForm();

  const { id: storeId = '' } = useParams<'id'>();
  const toast = useToast();
  const [sorting, setSorting] = useState<SortingState>([]);

  const { pathname } = useLocation();
  const openPublishedModal = (row: MenuProductForProductsTable) => {
    track({
      event: EventNames.OpenedModal,
      modal_name: ModalNames.UpdateMenuProduct,
      url: normalizePath(pathname, storeId),
    });
    navigate(`${row.id}`);
  };

  const {
    data,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useFetchProducts(storeId, {
    status: 'visible',
    ...filterParams(columnFiltersParent),
    ...sortParams(sorting),
  });

  const {
    mutateAsync,
    isSuccess: visibilitySuccess,
    isError: visibilityError,
  } = useUpdateProductVisibility(storeId);

  const updateProductVisibility = useCallback(
    (productId: string, visible: boolean) => {
      mutateAsync({ data: { visible }, productId });
    },
    []
  );

  const totalRowCount = data?.pages?.[0]?.meta?.total ?? 0;

  const columns = useMemo(
    () =>
      buildPublishedAndHiddenColumns({
        isBulkEditTable,
        isFetched,
        updateProductVisibility,
        totalRowCount,
        sorting,
      }),
    [
      JSON.stringify(data),
      isBulkEditTable,
      isFetched,
      updateProductVisibility,
      totalRowCount,
      sorting,
    ]
  );

  const allRows = useMemo(
    () =>
      data?.pages
        ?.flatMap((page) => page.meta.all_product_ids)
        .reduce((a, v) => ({ ...a, [v]: true }), {}) ?? {},
    [JSON.stringify(data)]
  );

  const productData = useMemo(
    () => data?.pages?.flatMap((page) => page.products) ?? [],
    [JSON.stringify(data)]
  );

  const queryClient = useQueryClient();
  const totalRowsFromCache = useMemo(() => {
    if (isFetched) {
      return (
        queryClient.getQueryData<InfiniteData<ProductIndexResponse>>(
          fetchProductQueryKey(storeId, {
            status: 'visible',
          })
        )?.pages?.[0]?.meta?.total ?? 0
      );
    }
    return 0;
  }, [isFetched, queryClient, storeId]);

  const reactTable = useReactTable({
    data: productData,
    columns,
    state: {
      columnFilters: columnFiltersParent,
      sorting,
      rowSelection: selectedProducts,
    },
    onColumnFiltersChange: setColumnFiltersParent,
    onSortingChange: setSorting,
    onRowSelectionChange: setSelectedProducts,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiRowSelection: true,
    enableRowSelection: true,
    getRowId: (row) => row.id.toString(),
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
  });

  useEffect(() => {
    if (visibilitySuccess) {
      toast.add({
        label: 'Product visibility successfully changed',
        variant: 'success',
      });
    }

    if (visibilityError) {
      toast.add({
        label: 'Product visibility change failed',
        variant: 'error',
      });
    }
  }, [visibilitySuccess, visibilityError]);

  useEffect(() => {
    if (!bulkEditModalOpen) {
      setColumnFiltersParent([]);
    }
  }, [storeId]);

  const navigate = useNavigate();
  const handleRowClick = (
    row: any,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (isBulkEditTable || !userCanEditProducts) {
      e.preventDefault();
      return;
    }

    openPublishedModal(row);
  };

  return isFetched && totalRowsFromCache === 0 ? (
    <EmptyState variant="published" />
  ) : (
    <Form.BaseForm formMethods={formMethods} onSubmit={() => null} name="test">
      <BaseProductTable
        allIds={allRows}
        table={reactTable}
        productData={productData}
        fetchNextPage={fetchNextPage}
        isBulkEditTable={isBulkEditTable}
        isFetched={isFetched}
        isFetching={isFetching && !isFetchingNextPage}
        isSuccess={isSuccess}
        handleRowClick={handleRowClick}
        hasNextPage={hasNextPage || false}
        tableType="published"
      />
    </Form.BaseForm>
  );
};

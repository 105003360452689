import styled from '@emotion/styled';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  MenuCategoryTabs,
  MenuRowHeader,
  MenuRowList,
  MenuRowModal,
  StoreMenuHeader,
} from '@jane/business-admin/components';
import type { MenuRow } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { spacing } from '@jane/shared/reefer-emotion';

const Wrapper = styled.div<{ myHighMenu: boolean }>(({ myHighMenu }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...spacing({ px: 64, pb: 40, pt: myHighMenu ? 24 : 40 }),
}));

export const StoreMenu = () => {
  const { id: storeId = '' } = useParams<'id'>();
  const { pathname } = useLocation();
  const myHighMenu = useFlag(FLAGS.myHighMenu);

  const [menuRowModalOpen, setMenuRowModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<MenuRow | undefined>();

  const openMenuRowModal = (row: MenuRow) => {
    track({
      event: EventNames.OpenedModal,
      modal_name: ModalNames.EditMenuRow,
      url: normalizePath(pathname, storeId),
    });

    setSelectedRow(row);
    setMenuRowModalOpen(true);
  };

  return (
    <>
      <Wrapper myHighMenu={myHighMenu}>
        <StoreMenuHeader />
        {myHighMenu && (
          <>
            <MenuCategoryTabs />
            <MenuRowHeader />
          </>
        )}
        <MenuRowList onOpenMenuRowModal={openMenuRowModal} />
      </Wrapper>
      {menuRowModalOpen && selectedRow && (
        <MenuRowModal
          row={selectedRow}
          closeModal={() => {
            track({
              event: EventNames.ClosedModal,
              modal_name: ModalNames.EditMenuRow,
              url: normalizePath(pathname, storeId),
            });
            setMenuRowModalOpen(false);
          }}
        />
      )}
    </>
  );
};

import styled from '@emotion/styled';

import type { MenuRow } from '@jane/business-admin/types';
import { CardCarousel } from '@jane/shared/components';
import { Card, Flex, SparkleIcon, Typography } from '@jane/shared/reefer';

import { ListContainer } from './ListContainer';
import { HeaderToggle } from './ListItemHeaders';
import { CARD_HEIGHT, CARD_WIDTH } from './utils';

interface Props {
  cardText: string;
  row: MenuRow;
  rowHelperText?: string;
}

const CustomCardContent = styled(Card.Content)(() => ({
  padding: 0,
}));

export const PreviewCardsListItem = ({
  cardText,
  row,
  rowHelperText,
}: Props) => {
  if (row.row_type !== 'magic_row' && row.row_type !== 'buy_again_row') {
    return null;
  }

  return (
    <ListContainer rowEnabled>
      <CardCarousel
        beforeHeader={
          <HeaderToggle
            row={row}
            rowLabel={row.custom_display_name || row.row_type}
            itemsLength={0}
            showProductsCTA={false}
            rowHelperText={rowHelperText}
          />
        }
        name={row.custom_display_name || row.row_type}
        mode="ideal-width"
        cardWidth={CARD_WIDTH}
        cardHeight={CARD_HEIGHT}
        cardWidthMobile={212}
        cardHeightMobile={328}
        mb={40}
        hideCarouselControls
      >
        {Array.from({ length: 6 }).map((_, idx) => (
          <CardCarousel.Card key={idx}>
            <Card width={CARD_WIDTH} border="grays-light" height={CARD_HEIGHT}>
              <CustomCardContent background="grays-ultralight">
                <Flex
                  background="grays-ultralight"
                  flexDirection={'column'}
                  alignItems="center"
                  height={'100%'}
                >
                  <Flex basis="60%" alignItems="center">
                    <SparkleIcon
                      color={row.enabled ? 'primary' : 'grays-light'}
                    />
                  </Flex>
                  <Flex
                    basis="40%"
                    alignItems="center"
                    justifyContent={'center'}
                    background="grays-white"
                    width={'100%'}
                  >
                    <Typography
                      variant="body"
                      color={row.enabled ? 'text-main' : 'grays-light'}
                    >
                      {cardText}
                    </Typography>
                  </Flex>
                </Flex>
              </CustomCardContent>
            </Card>
          </CardCarousel.Card>
        ))}
      </CardCarousel>
    </ListContainer>
  );
};

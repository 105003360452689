import capitalize from 'lodash/capitalize';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  useSaveStoreSchedules,
  useStoreSettings,
} from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { StoreSchedule } from '@jane/business-admin/types';
import {
  ALL_DAYS,
  EventNames,
  ModalNames,
  getTwentyFourHours,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import type { ReservationModeV2 } from '@jane/shared/models';
import {
  Button,
  Flex,
  Form,
  FormValidationError,
  Modal,
  Tabs,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import { CopyHoursPopover } from './CopyHoursPopover';
import { HourInputRow } from './HourInputRow';
import { HOURS_TABS, parseDateObj } from './helpers';

export type StoreHourFormItem = {
  friday_enabled: boolean;
  monday_enabled: boolean;
  saturday_enabled: boolean;
  sunday_enabled: boolean;
  thursday_enabled: boolean;
  tuesday_enabled: boolean;
  wednesday_enabled: boolean;
} & StoreSchedule;
export interface StoreHoursFormData {
  curbside: StoreHourFormItem;
  delivery: StoreHourFormItem;
  pickup: StoreHourFormItem;
  retail: StoreHourFormItem;
}

export const INITIAL_HOURS: StoreHourFormItem = {
  id: null,
  schedule_type: null,
  sunday_enabled: false,
  sunday_hours_end: null,
  sunday_hours_start: null,
  monday_enabled: false,
  monday_hours_end: null,
  monday_hours_start: null,
  tuesday_enabled: false,
  tuesday_hours_end: null,
  tuesday_hours_start: null,
  wednesday_enabled: false,
  wednesday_hours_end: null,
  wednesday_hours_start: null,
  thursday_enabled: false,
  thursday_hours_end: null,
  thursday_hours_start: null,
  friday_enabled: false,
  friday_hours_end: null,
  friday_hours_start: null,
  saturday_enabled: false,
  saturday_hours_end: null,
  saturday_hours_start: null,
};

const retailInitial = {
  ...INITIAL_HOURS,
  schedule_type: 'retail' as ReservationModeV2,
};
const pickupInitial = {
  ...INITIAL_HOURS,
  schedule_type: 'pickup' as ReservationModeV2,
};
const curbsideInitial = {
  ...INITIAL_HOURS,
  schedule_type: 'curbside' as ReservationModeV2,
};
const deliveryInitial = {
  ...INITIAL_HOURS,
  schedule_type: 'delivery' as ReservationModeV2,
};

const initialFormValue = {
  retail: retailInitial,
  pickup: pickupInitial,
  curbside: curbsideInitial,
  delivery: deliveryInitial,
};

export type CopyToOtherDaysParams = {
  dayToCopyFrom: string;
  daysToCopyTo: string[];
  tab: string;
};

const FORM_ERROR_NAME = 'hours-error';

export const HoursModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating store hours. Please try again.'
  );
  const toast = useToast();
  const { storeId } = useContext(StoreDetailsContext);
  const { data: storePayload, isFetching } = useStoreSettings(storeId);
  const { mutateAsync: saveSchedules, isSuccess: saveSuccess } =
    useSaveStoreSchedules(storeId);
  const changedFields = useRef(new Set());
  const isFirstChange = useRef(0);

  const formMethods = useForm();
  const {
    formState: { isDirty },
    setValue,
  } = formMethods;

  /*
    Intentionally doubling up on formData, since the tab structure of adding/removing
    inputs from the DOM will result in formData being reset when tabs are navigated.

    More info here (they recommend using a virtual DOM, we're just tracking data differenly instead):
    https://react-hook-form.com/advanced-usage#Workingwithvirtualizedlists
  **/
  const [tempFormData, setTempFormData] =
    useState<StoreHoursFormData>(initialFormValue);
  const originalFormData = useRef<StoreHoursFormData>(initialFormValue);
  const [selected, setSelected] = useState(HOURS_TABS[0]);

  const hourOptions = useMemo(() => {
    return getTwentyFourHours();
  }, []);

  useEffect(() => {
    const parsedData = {
      retail: storePayload?.retail_schedule
        ? parseDateObj(storePayload?.retail_schedule)
        : retailInitial,
      pickup: storePayload?.pickup_schedule
        ? parseDateObj(storePayload?.pickup_schedule)
        : pickupInitial,
      curbside: storePayload?.curbside_schedule
        ? parseDateObj(storePayload?.curbside_schedule)
        : curbsideInitial,
      delivery: storePayload?.delivery_schedule
        ? parseDateObj(storePayload?.delivery_schedule)
        : deliveryInitial,
    };
    setTempFormData(parsedData);
    originalFormData.current = parsedData;
  }, [
    JSON.stringify(storePayload?.retail_schedule),
    JSON.stringify(storePayload?.pickup_schedule),
    JSON.stringify(storePayload?.curbside_schedule),
    JSON.stringify(storePayload?.delivery_schedule),
  ]);

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Store hours updated.',
        variant: 'success',
      });
      setOpen(false);
    }
  }, [saveSuccess, setOpen]);

  const onSubmit = useCallback(() => {
    const submitMethod = () => {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.StoreHours,
        changed_attributes: Array.from(changedFields.current) as string[],
      });
      return saveSchedules(tempFormData);
    };
    return catchSubmitErrors({
      submitMethod,
      requestData: tempFormData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  }, [JSON.stringify(tempFormData)]);

  const setDataOnChange = (
    tab: string,
    value: string | boolean,
    field: string
  ) => {
    // Need to reset hours fields if field is disabled
    let hours_fields = {};
    if (field.includes('_enabled') && value === false) {
      const [day] = field.split('_');
      hours_fields = {
        [`${day}_hours_start`]: null,
        [`${day}_hours_end`]: null,
      };
    }

    // When the checkboxes are initialized, they fire this event, so skip the first 7 changes (since there are 7 checkboxes)
    if (isFirstChange.current < 7) {
      isFirstChange.current = isFirstChange.current + 1;
    } else {
      changedFields.current.add(`${tab}_${field}`);
    }

    setTempFormData((tempFormData) => {
      const tabEntry = tempFormData[tab as keyof StoreHoursFormData];
      return {
        ...tempFormData,
        [tab]: {
          ...tabEntry,
          ...hours_fields,
          [field]: value,
        },
      };
    });
  };

  const copyToOtherDays = ({
    tab,
    dayToCopyFrom,
    daysToCopyTo,
  }: CopyToOtherDaysParams) => {
    const startKey = `${dayToCopyFrom}_hours_start` as keyof StoreHourFormItem;
    const endKey = `${dayToCopyFrom}_hours_end` as keyof StoreHourFormItem;

    daysToCopyTo.forEach((copyToDay) => {
      // Updates the visually displayed input when copy happens
      setValue(`${tab}.${copyToDay}_enabled`, true, { shouldDirty: true });
      setValue(
        `${tab}.${copyToDay}_hours_start`,
        tempFormData[tab as keyof StoreHoursFormData][startKey]
      );
      setValue(
        `${tab}.${copyToDay}_hours_end`,
        tempFormData[tab as keyof StoreHoursFormData][endKey]
      );

      // Updates the actual formData when copying
      setTempFormData((tempFormData) => {
        const tabEntry = tempFormData[tab as keyof StoreHoursFormData];
        return {
          ...tempFormData,
          [tab]: {
            ...tabEntry,
            [`${copyToDay}_enabled`]: true,
            [`${copyToDay}_hours_start`]: tabEntry[startKey],
            [`${copyToDay}_hours_end`]: tabEntry[endKey],
          },
        };
      });
    });
  };

  const copyToOtherTabs = (tabsToCopyTo: string[]) => {
    tabsToCopyTo.forEach((copyToTab) => {
      changedFields.current.add(copyToTab);
      setTempFormData((tempFormData) => {
        const tabEntry = tempFormData[selected as keyof StoreHoursFormData];
        return {
          ...tempFormData,
          [copyToTab]: {
            ...tabEntry,
            schedule_type: copyToTab,
          },
        };
      });
    });
  };

  const copyHoursOptions = useMemo(() => {
    return HOURS_TABS.filter((tab) => tab !== selected).map((tab) => {
      return {
        value: tab,
        label: `${capitalize(tab)} hours`,
      };
    });
  }, [selected]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={setOpen}
      hasChanges={isDirty}
      modalName={ModalNames.StoreHours}
    >
      <Form.BaseForm
        name="hours form"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Hours"
          subtitle={storePayload?.store?.name}
          actions={<Form.SubmitButton variant="primary" label="Save" />}
        />

        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />

          <Tabs
            full
            onChange={(tab: string) => setSelected(tab)}
            value={selected}
          >
            {HOURS_TABS.map((tab) => (
              <Tabs.Tab
                key={tab}
                label={capitalize(tab)}
                value={tab}
              ></Tabs.Tab>
            ))}
          </Tabs>
          {HOURS_TABS.map(
            (tab, tabIndex) =>
              selected === tab && (
                <Flex flexDirection="column" key={tabIndex} py={24}>
                  {ALL_DAYS.map((day, index) => (
                    <HourInputRow
                      key={day}
                      tab={selected}
                      day={day}
                      hourOptions={hourOptions}
                      tempFormData={
                        tempFormData[selected as keyof StoreHoursFormData]
                      }
                      setTempDataOnChange={setDataOnChange}
                      copyToOtherDays={copyToOtherDays}
                      copyPopoverTopAlignment={index > 3 ? true : false}
                    />
                  ))}
                </Flex>
              )
          )}

          <div style={{ marginTop: -20, marginBottom: -20 }}>
            <Modal.ContentDivider />
          </div>

          <Flex justifyContent="end">
            <CopyHoursPopover
              target={<Button label="Copy to" variant="tertiary" />}
              label="Copy hours to other tabs"
              triggerCopy={copyToOtherTabs}
              options={copyHoursOptions}
              topAlignment={true}
            />
          </Flex>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

import { Flex, Grid } from '@jane/shared/reefer';

import { InputGroup } from '../../../../../../InputGroup';

export const LeadTimeSettingsSection = ({
  isMin,
  defaultValue,
  handleLeadTimeTracking,
}: {
  defaultValue?: number;
  handleLeadTimeTracking?: (settingUnit: string, isMin: boolean) => void;
  isMin: boolean;
}) => {
  return (
    <Flex mb={24} width="100%">
      <Grid.Item xs={12}>
        <InputGroup
          minutes={defaultValue}
          isMin={isMin}
          hideValidationDescriptionText
          handleLeadTimeTracking={handleLeadTimeTracking}
        />
      </Grid.Item>
    </Flex>
  );
};

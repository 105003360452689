import { useEffect, useRef, useState } from 'react';
import type { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import type { StoreSettingsPayload } from '@jane/business-admin/types';
import { config } from '@jane/shared/config';
import {
  Banner,
  CheckIcon,
  Flex,
  Form,
  Modal,
  Typography,
} from '@jane/shared/reefer';

import { SquareAuthorizationModal } from './SquareAuthorizationModal';

interface Props {
  isDirty: boolean;
  providerSquareEnabled: boolean;
  setValue: UseFormSetValue<FieldValues>;
  storeId: string;
  storePayload?: StoreSettingsPayload;
}

export const SQUARE_OAUTH_REDIRECT_SEARCH_PARAM = 'squareOAuthRedirect';

export const SquareSettings = ({
  isDirty,
  providerSquareEnabled,
  setValue,
  storeId,
  storePayload,
}: Props) => {
  const isSquareEnabled = (config.features || '')
    .split(',')
    .map((s: string) => s.trim())
    .includes('square');
  const [searchParams, setSearchParams] = useSearchParams();

  const [squareOAuthRedirect, setSquareOAuthRedirect] =
    useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const squareOAuthRedirectParam = searchParams.get(
      SQUARE_OAUTH_REDIRECT_SEARCH_PARAM
    );

    if (squareOAuthRedirectParam !== null) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView();
      }

      setSquareOAuthRedirect(true);

      searchParams.delete(SQUARE_OAUTH_REDIRECT_SEARCH_PARAM);
      setSearchParams(searchParams);
    }
  }, [squareOAuthRedirect, containerRef, searchParams, setSearchParams]);

  const [squareAuthorizationModalOpen, setSquareAuthorizationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const payloadSquareEnabled = storePayload?.square_integration?.enabled;

    if (isDirty && !payloadSquareEnabled && providerSquareEnabled) {
      setSquareAuthorizationModalOpen(true);
    }
  }, [
    isDirty,
    providerSquareEnabled,
    storePayload?.square_integration?.enabled,
  ]);

  useEffect(() => {
    const payloadLocationId = storePayload?.square_integration?.location_id;

    if (!isDirty && payloadLocationId !== undefined) {
      setValue('square_integration.location_id', payloadLocationId);
    }
  }, [isDirty, setValue, storePayload?.square_integration?.location_id]);

  if (!isSquareEnabled) {
    return null;
  }

  const authorized = storePayload?.square_integration?.authorized;

  const locations = storePayload?.square_integration?.locations || [];
  const locationOptions = locations.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  const closeAuthorizationModal = () => {
    setValue('square_integration.enabled', false);
    setSquareAuthorizationModalOpen(false);
  };

  return (
    <>
      <div ref={containerRef}>
        <Modal.ContentDivider />
        {squareOAuthRedirect && (
          <Flex mb={32}>
            <Banner
              icon={<CheckIcon />}
              label="Authorization successful"
              typography="body"
              variant="success"
              full
            />
          </Flex>
        )}
        <Form.SwitchField
          label={<Typography variant="body-bold">Square</Typography>}
          name="square_integration.enabled"
          mb={32}
        />
        {providerSquareEnabled && authorized && (
          <Form.SelectField
            label="Location"
            name="square_integration.location_id"
            options={locationOptions}
            required
            width="100%"
            data-testid="square-location-select"
          />
        )}
      </div>
      {squareAuthorizationModalOpen && (
        <SquareAuthorizationModal
          onClose={closeAuthorizationModal}
          storeId={storeId}
        />
      )}
    </>
  );
};

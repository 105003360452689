import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';

import { ProductTable } from '@jane/business-admin/components';
import { ProductsTableProvider } from '@jane/business-admin/providers';
import { spacing } from '@jane/shared/reefer-emotion';

const Wrapper = styled.div({
  display: 'flex',
  ...spacing({ px: 64, py: 40 }),
});

const Main = styled.div({
  width: '100%',
});

export const StoreProducts = () => {
  return (
    <Wrapper>
      <Main>
        <ProductsTableProvider>
          <ProductTable />
          {/* Where the details modals load */}
          <Outlet />
        </ProductsTableProvider>
      </Main>
    </Wrapper>
  );
};

import {
  FulfillmentCards,
  OrderAheadCard,
} from '@jane/business-admin/components';
import { Flex } from '@jane/shared/reefer';

export const StoreSettingsFulfillments = () => {
  return (
    <Flex flexDirection="column">
      <FulfillmentCards />
      <OrderAheadCard />
    </Flex>
  );
};

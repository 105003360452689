import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

// NOTE: THIS CODE IS AN ADAPTATION OF THE APPROACH DETAILED HERE
// https://devtrium.com/posts/how-keyboard-shortcut
interface KeyPressModifiers {
  alt?: boolean;
  ctrl?: boolean;
  meta?: boolean;
  shift?: boolean;
}

export const useKeyPress = (
  keys: string[],
  callback: (event: KeyboardEvent) => void,
  node: Node | null = null,
  modifiers: KeyPressModifiers = {}
) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent): any => {
      // check if modifier keys are desired + pressed
      if (modifiers.ctrl === true && event.ctrlKey !== true) {
        return null;
      }
      if (modifiers.shift === true && event.shiftKey !== true) {
        return null;
      }
      if (modifiers.meta === true && event.metaKey !== true) {
        return null;
      }
      if (modifiers.alt === true && event.altKey !== true) {
        return null;
      }

      // check if one of the key is part of the ones we want
      if (keys.some((key: string) => event.key === key)) {
        return callbackRef.current(event);
      }
    },
    [keys, modifiers]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode &&
      targetNode.addEventListener(
        'keydown',
        handleKeyPress as EventListenerOrEventListenerObject
      );

    // remove the event listener on calling component's unmount
    return () =>
      targetNode &&
      targetNode.removeEventListener(
        'keydown',
        handleKeyPress as EventListenerOrEventListenerObject
      );
  }, [handleKeyPress, node]);
};

import styled from '@emotion/styled';
import { useContext, useMemo, useState } from 'react';

import { StoreFiltersContext } from '@jane/business-admin/providers';
import { Flex, Form, Link } from '@jane/shared/reefer';

import { StoreSearch } from './StoreSearch';
import { StoreStateFilter } from './StoreStateFilter';
import { StoreTypeFilter } from './StoreTypeFilter';

const HeaderWrapper = styled.div(({ theme }) => ({
  background: theme.colors.grays.white,
}));

export const StoreFilters = ({
  disablePadding,
}: {
  disablePadding?: boolean;
}) => {
  const {
    searchFilter,
    typeFilter,
    stateFilter,
    setSearchFilter,
    setTypeFilter,
    setStateFilter,
  } = useContext(StoreFiltersContext);

  const anyFilterSet = useMemo(() => {
    return !!(searchFilter || typeFilter || stateFilter);
  }, [searchFilter, typeFilter, stateFilter]);

  const [stateSearch, setStateSearch] = useState('');

  const resetFilters = () => {
    setSearchFilter('');
    setTypeFilter('');
    setStateFilter('');
    setStateSearch('');
  };

  return (
    <HeaderWrapper>
      <Form name="stores-filter" onSubmit={() => null}>
        <Flex px={disablePadding ? 0 : 64} pt={disablePadding ? 0 : 40}>
          <StoreSearch />
          <StoreTypeFilter />
          <StoreStateFilter
            stateSearch={stateSearch}
            setStateSearch={setStateSearch}
          />
          {anyFilterSet ? (
            <Flex p={12} ml={8}>
              <Link onClick={resetFilters}>Clear filters</Link>
            </Flex>
          ) : null}
        </Flex>
      </Form>
    </HeaderWrapper>
  );
};

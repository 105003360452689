import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo } from 'react';

import { useImportTemplate } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { parseInvalidTemplateUrlError } from '@jane/business-admin/util';
import {
  Banner,
  Button,
  Flex,
  Form,
  Modal,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

export const TemplateSection = ({
  isCreateMode,
}: {
  isCreateMode?: boolean;
}) => {
  const {
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();
  const catchSubmitErrors = useCatchErrorsWithManager('dontmatta');

  const isValidTemplateURL = !errors['published_template_url'];
  const templateOverrides = watch('template_overrides');
  const templateUrl = watch('published_template_url');
  const contentSelector = watch('content_selector', '#app');
  const publishedHtml = watch('published_template_html');

  useEffect(() => {
    clearErrors('importError');
  }, [publishedHtml, contentSelector]);

  const {
    mutateAsync,
    data: importedTemplate,
    isLoading: isImporting,
    isSuccess: importSuccess,
  } = useImportTemplate();

  const onImportClick = () => {
    clearErrors('importError');

    const requestData = {
      template_url: templateUrl,
      content_selector: contentSelector,
    };
    catchSubmitErrors({
      submitMethod: () => mutateAsync(requestData),
      requestData,
      callback: (errors) => {
        const customValidationErrors = parseInvalidTemplateUrlError(errors);
        if (customValidationErrors) {
          setError('importError', customValidationErrors);
        }
      },
      onValidationError: () => null,
    });
  };

  useEffect(() => {
    if (importedTemplate) {
      setValue('published_template_html', importedTemplate.parsed_html, {
        shouldDirty: true,
      });
      setValue('published_template_html_updated_at', new Date());
    }
  }, [importedTemplate]);

  const importError = useMemo(
    () => get(errors, 'importError'),
    [JSON.stringify(errors)]
  );

  return (
    <>
      <Typography variant="body-bold">Template</Typography>
      <Typography color="grays-mid" mb={24}>
        These fields can be used to display templates.
      </Typography>
      {!isEmpty(importError) && (
        <Banner
          variant="error"
          label={`Import error: ${importError?.message}`}
          mb={24}
        />
      )}
      <Flex flexDirection="column" mb={24}>
        <Flex alignItems="center" gap={24}>
          <Form.TextField
            disableMobileInputStyling
            type="text"
            autocomplete="off"
            label="URL of template to import"
            name="published_template_url"
            helperText="e.g: https://littlegreenpotshop.com/template-page"
            disabled={isImporting}
          />
          <Button
            label="Import"
            variant="secondary"
            onClick={onImportClick}
            disabled={!templateUrl || isImporting}
          />
        </Flex>
        <Form.SwitchField
          label={
            <>
              <Typography variant="body-bold">
                Automatically import template
              </Typography>
              <Typography color="grays-mid">
                Jane will import your template every day at 12AM PST. We do not
                recommend enabling this option if the HTML field has been
                manually entered or modified.
              </Typography>
            </>
          }
          name="published_template_auto_import"
          mt={24}
        />
      </Flex>
      {(!isCreateMode || importSuccess) && (
        <Form.TextAreaField
          autocomplete="off"
          label="HTML"
          name="published_template_html"
          disabled={isImporting}
          rows={3}
          mb={24}
        />
      )}
      <Form.SwitchField
        label="Template overrides"
        name="template_overrides"
        mb={24}
        helperText="These fields can be used to resolve errors with template imports"
      />
      {!templateOverrides ? null : (
        <Form.TextField
          disableMobileInputStyling
          type="text"
          autocomplete="off"
          label="Content selector"
          name="content_selector"
          helperText="Defaults to #app"
          mb={24}
          disabled={isImporting}
          onChange={(value) => {
            if (!isValidTemplateURL) {
              clearErrors('published_template_url');
            }
            setValue('content_selector', value, { shouldDirty: true });
          }}
        />
      )}
      <Modal.ContentDivider />
    </>
  );
};

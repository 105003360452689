import floor from 'lodash/floor';
import type { FileWithPath } from 'react-dropzone';

import type { MenuProductForProductsTable } from '@jane/business-admin/types';
import type { ValidateImageFileOptions } from '@jane/shared-b2b/util';

export const VALID_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
export const MAX_IMAGE_SIZE = 2_048_000; // TODO: verify this with Jarred and change
export const FAMILIAR_MAX_IMAGE_SIZE = `${floor(
  MAX_IMAGE_SIZE / 1_000_000
)} MB`;

export const MAX_FILE_SIZE = 10 * (1024 * 1024); // 10MB

export const VALIDATION_OPTIONS: ValidateImageFileOptions = {
  fileSize: {
    maxSize: MAX_FILE_SIZE,
    message: 'Image is larger than the allowed 10MB.',
  },
  fileType: {
    allowedTypes: VALID_IMAGE_TYPES,
    message: 'Image is not a PNG or JPEG.',
  },
};

export const validateImage = (file: FileWithPath) =>
  VALID_IMAGE_TYPES.includes(file.type) && file.size <= MAX_IMAGE_SIZE;

export const getNewImages = ({
  photos,
  menuProduct,
}: {
  menuProduct?: MenuProductForProductsTable;
  photos: any;
}) => {
  return photos.reduce(
    (result: { [index: string]: any }, image: any, i: number) => {
      if (!menuProduct?.image_urls?.some((url: string) => image === url))
        result[`${i + 1}`] = image;
      return result;
    },
    {}
  );
};

// Update image positions that are currently assigned to menu product
export const orderImages = ({
  photos,
  menuProduct,
}: {
  menuProduct?: MenuProductForProductsTable;
  photos: any;
}) => {
  return photos.reduce(
    (
      result: Array<{ id: number | undefined; position: string }>,
      imageUrl: any,
      i: number
    ) => {
      const foundObject = menuProduct?.photos?.find(
        (obj) => obj?.urls.original === imageUrl
      );

      if (foundObject?.id) {
        result.push({ id: foundObject?.id, position: `${i + 1}` });
      }

      return result;
    },
    []
  );
};

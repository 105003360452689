import { useQuery } from '@tanstack/react-query';

import { janeApiV2 } from '@jane/shared/data-access';
import type { StoreCategory } from '@jane/shared/models';

const fetchCategories = async (): Promise<StoreCategory[]> => {
  const response = await janeApiV2.get<{ data: StoreCategory[] }>(
    `/business/specials/product_categories`
  );
  return response.data;
};
export const useFetchCategories = (enabled: boolean) =>
  useQuery({
    queryKey: ['product_categories'],
    queryFn: () => fetchCategories(),
    enabled,
  });

import { HardwareCard } from '@jane/business-admin/components';
import { Flex } from '@jane/shared/reefer';

export const StoreSettingsHardware = () => {
  return (
    <Flex flexDirection="column">
      <HardwareCard />
    </Flex>
  );
};

import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import * as t from 'io-ts';

import type {
  ProductIndexResponse,
  UnpublishedProduct,
} from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type { Id } from '@jane/shared/models';

import {
  type fetchProductsProps,
  invalidateAllProductQueries,
} from './products';
import { STORES_URL } from './stores';

type BulkPublishProductParams = {
  brand: string;
  brand_subtype: string;
  category: string;
  cbd_dosage_milligrams: number;
  description: string;
  dosage: string;
  kind: string;
  lineage: string;
  name: string;
  percent_cbd: number;
  percent_thc: number;
  size: string;
  subcategory: string;
  thc_dosage_milligrams: number;
  unmapped_product_id: string;
};

type UpdateUnpublishedProductParams = {
  product: Pick<UnpublishedProduct, 'unmapped_product_id'> &
    Omit<BulkPublishProductParams, 'unmapped_product_id'>;
};

type fetchUnpublishedProductsProps = fetchProductsProps & {
  unmapped_product_id?: string;
};

const fetchUnpublishedProducts = async (
  storeId: string,
  params: fetchUnpublishedProductsProps
): Promise<any> => {
  const urlParams = new URLSearchParams();
  params.sort && urlParams.append('sort', params.sort);
  params.order && urlParams.append('order', params.order);
  params.brand && urlParams.append('brand', params.brand);
  params.content && urlParams.append('content', params.content);
  params.query && urlParams.append('query', params.query);
  params.unmapped_product_id &&
    urlParams.append('unmapped_product_id', params.unmapped_product_id);
  params.category && urlParams.append('category', params.category);
  params.lineage && urlParams.append('lineage', params.lineage);
  params.status && urlParams.append('status', params.status);
  params.page && urlParams.append('page', params.page.toString());
  params.subcategory && urlParams.append('subcategory', params.subcategory);
  urlParams.append(
    'per_page',
    params.per_page !== undefined ? params.per_page.toString() : '50'
  );

  const url = `${STORES_URL}/${storeId}/unpublished_products?${urlParams.toString()}`;
  const data = await fetchWithDecode(
    janeApiV2.get<ProductIndexResponse>(url),
    t.any,
    url
  );

  return data;
};

export const fetchUnpublishedProductQueryKey = (
  storeId: string,
  params: fetchUnpublishedProductsProps
) => {
  return ['storeUnpublishedProducts', storeId, params];
};

export const useFetchUnpublishedProducts = (
  storeId: string,
  params: fetchUnpublishedProductsProps,
  disabled?: boolean
) => {
  return useInfiniteQuery<any>({
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchUnpublishedProducts(storeId, {
        sort: params.sort,
        order: params.order,
        brand: params.brand,
        content: params.content,
        query: params.query,
        unmapped_product_id: params.unmapped_product_id,
        category: params.category,
        lineage: params.lineage,
        status: params.status,
        subcategory: params.subcategory,
        per_page: params.per_page,
        page: pageParam,
      });
      return {
        ...data,
        pageParam,
      };
    },
    enabled: !disabled,
    queryKey: fetchUnpublishedProductQueryKey(storeId, params),
    getNextPageParam: (lastPage) => {
      const hasNextPage = lastPage?.meta.page < lastPage.meta.number_of_pages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    staleTime: Infinity,
    useErrorBoundary: true,
  });
};

const updateUnpublishedProduct = (
  storeId: Id,
  product: UpdateUnpublishedProductParams
): Promise<null> =>
  janeApiV2.post(`${STORES_URL}/${storeId}/unpublished_products/publish`, {
    ...product,
  });

export const useUpdateUnpublishedProduct = (storeId: Id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateUnpublishedProductParams) =>
      updateUnpublishedProduct(storeId, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      invalidateAllProductQueries(queryClient, storeId);
    },
  });
};

const bulkPublishProducts = (
  storeId: Id,
  products: BulkPublishProductParams[]
): Promise<null> =>
  janeApiV2.post(`${STORES_URL}/${storeId}/unpublished_products/bulk_publish`, {
    ...products,
  });

export const useBulkPublishProducts = (storeId: Id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: BulkPublishProductParams[]) =>
      bulkPublishProducts(storeId, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      invalidateAllProductQueries(queryClient, storeId);
    },
  });
};

const archiveUnpublishedProduct = (
  storeId: Id,
  unmapped_product_ids: string[]
): Promise<null> =>
  janeApiV2.post(`${STORES_URL}/${storeId}/unpublished_products/archive`, {
    unmapped_product_ids,
  });
export const useArchiveUnpublishedProduct = (storeId: Id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: string[]) =>
      archiveUnpublishedProduct(storeId, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      invalidateAllProductQueries(queryClient, storeId);
    },
  });
};

import { useQuery } from '@tanstack/react-query';

import { ThoughtspotLiveboardsResponseSchema } from '@jane/business-admin/types';
import type { ThoughtspotLiveboardsResponse } from '@jane/business-admin/types';
import { janeApiV2 } from '@jane/shared/data-access';
import { parseData } from '@jane/shared/util';

export const THOUGHTSPOT_HOST = 'https://janetechnologies.thoughtspot.cloud';
const BIZDASH_THOUGHTSPOT_URL = '/business/thoughtspot';

interface ThoughtspotTokenResponse {
  token: string;
}

export const createThoughtspotToken = (): Promise<ThoughtspotTokenResponse> =>
  janeApiV2.post<ThoughtspotTokenResponse>('/business/thoughtspot/tokens', {});

const fetchThoughtspotLiveboards = async () => {
  const data = await janeApiV2.get(`${BIZDASH_THOUGHTSPOT_URL}/liveboards`);
  const parsedData = parseData(ThoughtspotLiveboardsResponseSchema, data);
  return parsedData;
};

export const useThoughtspotLiveboards = ({
  enabled = true,
}: {
  enabled?: boolean;
}) =>
  useQuery<ThoughtspotLiveboardsResponse>({
    enabled,
    queryFn: () => fetchThoughtspotLiveboards(),
    queryKey: ['thoughtspot_liveboards'],
    useErrorBoundary: true,
    staleTime: Infinity,
  });

import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useStoreDiscountSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { ModalNames } from '@jane/business-admin/util';
import { Flex, Skeleton, Typography } from '@jane/shared/reefer';

import { CardSection } from '../../../../../CardSection';
import { EditableCard } from '../../../../../EditableCard';
import { DiscountsModal } from './DiscountsModal';

export const DiscountsCard = () => {
  const { id = '' } = useParams<'id'>();
  const {
    data: storeDiscountSettings,
    isFetching: isStoreDiscountSettingsFetching,
  } = useStoreDiscountSettings(id);
  const { canEditStore, storeName } = useContext(StoreDetailsContext);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.Discounts
  );

  const loyaltyStacking = useMemo(() => {
    const store_discount_setting = storeDiscountSettings;
    if (store_discount_setting) {
      return store_discount_setting.loyalty_points_stacking
        ? 'Enabled'
        : 'Disabled';
    } else {
      return 'Enabled';
    }
  }, [storeDiscountSettings]);

  const maxSpecialsPerCart = useMemo(() => {
    const store_discount_setting = storeDiscountSettings;
    if (store_discount_setting) {
      return store_discount_setting.max_specials_per_cart
        ? store_discount_setting.max_specials_per_cart
        : 'Unlimited';
    } else {
      return 'Unlimited';
    }
  }, [storeDiscountSettings]);

  return (
    <>
      <EditableCard
        title="Loyalty stacking and special limits"
        onEdit={() => openModal()}
        isLoading={isStoreDiscountSettingsFetching}
        disabled={!canEditStore}
      >
        <Flex mt={24}>
          <Flex flexDirection="column" width="50%">
            <CardSection label="Specials and loyalty redemption stacking">
              {isStoreDiscountSettingsFetching ? (
                <Skeleton animate>
                  <Skeleton.Bone mb={4} width={200} />
                </Skeleton>
              ) : (
                <Typography>{loyaltyStacking}</Typography>
              )}
            </CardSection>
          </Flex>
          <Flex flexDirection="column" width="50%">
            <CardSection label="Maximum number of specials per cart">
              {isStoreDiscountSettingsFetching ? (
                <Skeleton animate>
                  <Skeleton.Bone mb={4} width={200} />
                </Skeleton>
              ) : (
                <Typography>{maxSpecialsPerCart}</Typography>
              )}
            </CardSection>
          </Flex>
        </Flex>
      </EditableCard>
      {modalOpen && (
        <DiscountsModal
          open
          setOpen={(open) => closeModal()}
          storeName={storeName || ''}
          storeId={id || ''}
          storeDiscountSettings={storeDiscountSettings}
        />
      )}
    </>
  );
};

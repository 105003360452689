import styled from '@emotion/styled';

import { Button, ErrorIcon, Flex, Typography } from '@jane/shared/reefer';

const TitleText = styled(Typography)({
  fontSize: '32px',
});

export const ErrorView = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Flex width="432px" mx="auto" justifyContent="center">
      <Flex
        my={128}
        mx={40}
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        width="432px"
      >
        <ErrorIcon size="xxl" mb={24} color={'error'} />
        <Flex flexDirection="column" width="432px">
          <TitleText mb={8} variant="title-bold" textAlign="center">
            Oops!
          </TitleText>
          <Typography
            variant="body"
            mb={24}
            color="grays-dark"
            textAlign="center"
          >
            Seems like something went wrong. Please try again!
          </Typography>
          <Button
            full
            variant="primary"
            onClick={refreshPage}
            label="Refresh"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

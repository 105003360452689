import styled from '@emotion/styled';

import { DEFAULT_STATUS_MESSAGES } from '@jane/business-admin/util';
import type { CartStatusOptions } from '@jane/shared/models';
import { Flex, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

const Content = styled(Flex)(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.colors.grays.light,
  borderRadius: theme.borderRadius.lg,
  borderBottomLeftRadius: 0,
  ...spacing({ p: 16 }),
  width: 'fit-content',
}));

interface Props {
  message?: string | null;
  status?: CartStatusOptions;
}

export const StatusMessagePreview = ({ status, message }: Props) => {
  const defaultMessage = status ? DEFAULT_STATUS_MESSAGES[status] : undefined;

  return (
    <Content data-testid="status-message-preview">
      <Typography>{message || defaultMessage}</Typography>
    </Content>
  );
};

import { useContext, useMemo } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { ModalNames } from '@jane/business-admin/util';
import { Flex, Skeleton, Typography } from '@jane/shared/reefer';

import { CardSection } from '../../../../../CardSection';
import { EditableCard } from '../../../../../EditableCard';
import { TaxesAndFeesModal } from './TaxesAndFeesModal';

const SkeletonCard = () => (
  <EditableCard title="Taxes and fees" onEdit={() => null}>
    <Skeleton>
      <Skeleton.Bone height="20px" width="300px" mb={12} />
    </Skeleton>
  </EditableCard>
);

export const TaxesAndFeesCard = () => {
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.TaxesAndFees
  );
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const {
    data: storeSettings,
    isFetching,
    isLoading,
  } = useStoreSettings(storeId);

  const taxes_summary = useMemo(() => {
    if (!storeSettings || !storeSettings.store) return '';

    const { store } = storeSettings;

    if (store.tax_included) {
      return `All taxes included in price`;
    } else {
      return `${
        (store.sales_tax_rate || 0) * 100
      }% sales tax applied at checkout`;
    }
  }, [storeSettings]);

  const fees_summary = useMemo(() => {
    if (!storeSettings || !storeSettings.store) return '';

    const { store } = storeSettings;

    if (store.service_fee_amount) {
      return `$${store.service_fee_amount} service fee`;
    }

    return 'No service fee';
  }, [storeSettings]);

  if (isLoading) return <SkeletonCard />;

  return (
    <>
      {isFetching ? (
        <SkeletonCard />
      ) : (
        <EditableCard
          title="Taxes and fees"
          onEdit={() => openModal()}
          disabled={!canEditStore}
        >
          <Flex flexDirection="row" mt={24}>
            <CardSection label="Tax Behavior" width="50%">
              <Typography>{taxes_summary}</Typography>
            </CardSection>
            <CardSection label="Service Fee" width="50%">
              <Typography>{fees_summary}</Typography>
            </CardSection>
          </Flex>
        </EditableCard>
      )}

      <TaxesAndFeesModal
        open={modalOpen}
        onClose={() => closeModal()}
        subtitle={storeSettings?.store.name ?? ''}
        storeId={storeSettings?.store.id ?? 0}
        taxIncluded={storeSettings?.store?.tax_included}
        salesTaxRate={storeSettings?.store?.sales_tax_rate}
        serviceFeeAmount={storeSettings?.store?.service_fee_amount}
      />
    </>
  );
};

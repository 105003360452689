import styled from '@emotion/styled';

import { Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

const FooterWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  ...spacing({ pt: 16, pb: 72, px: 48 }),
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: theme.colors.grays.ultralight,
}));

export const StoresLandingFooter = () => (
  <FooterWrapper>
    <Typography variant="mini-bold">Content Provided by Jane</Typography>
    <Typography variant="mini" textAlign="center" mt={16}>
      Jane has been advised by our accessibility consultant that the content
      provided by Jane for implementation on Dispensary's websites, at the time
      it is provided, meets the Web Content Accessibility Guidelines (WCAG),
      version 2.0, Level AA.
    </Typography>
    <Typography variant="mini" textAlign="center" mt={16}>
      Any alteration of the content, add-ons or additional tools could bring the
      content or your website out of conformance with the WCAG and potentially
      out of compliance with applicable laws. Any such content, when added to or
      altering the content provided by Jane, may change the nature of the
      content, including the website’s conformance to the WCAG 2.0 Level AA.
      Jane encourages Dispensary to take appropriate steps to ensure your own
      website content meets the WCAG, as well as any other requirements of
      applicable law. Jane cannot ensure that once content has been incorporated
      into your website that all such content remains accessible and/or
      compliant with any applicable legal requirements; that is up to you and
      your own team.
    </Typography>
  </FooterWrapper>
);

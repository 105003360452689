import * as t from 'io-ts';

import { tReservationModeV2 } from '@jane/shared/models';

import { tId } from './common';

export const tStoreSchedule = t.interface({
  id: t.union([tId, t.null]),
  schedule_type: t.union([tReservationModeV2, t.null]),
  sunday_hours_start: t.union([t.string, t.null]),
  sunday_hours_end: t.union([t.string, t.null]),
  monday_hours_start: t.union([t.string, t.null]),
  monday_hours_end: t.union([t.string, t.null]),
  tuesday_hours_start: t.union([t.string, t.null]),
  tuesday_hours_end: t.union([t.string, t.null]),
  wednesday_hours_start: t.union([t.string, t.null]),
  wednesday_hours_end: t.union([t.string, t.null]),
  thursday_hours_start: t.union([t.string, t.null]),
  thursday_hours_end: t.union([t.string, t.null]),
  friday_hours_start: t.union([t.string, t.null]),
  friday_hours_end: t.union([t.string, t.null]),
  saturday_hours_start: t.union([t.string, t.null]),
  saturday_hours_end: t.union([t.string, t.null]),
});
export type StoreSchedule = t.TypeOf<typeof tStoreSchedule>;

import { GeneralSettingsSection } from './GeneralSettingsSection';
import { GoogleSSOSection } from './GoogleSSOSection';
// import { GoogleSearchConsoleSection } from './GoogleSearchConsoleSection';
import { SeoSection } from './SeoSection';
import { TemplateSection } from './TemplateSection';

export const BoostSubdomainConfigModalContent = ({
  isCreateMode,
}: {
  isCreateMode?: boolean;
}) => {
  return (
    <>
      <GeneralSettingsSection variant="subdomain" />
      <TemplateSection isCreateMode={isCreateMode} />
      <SeoSection />
      <GoogleSSOSection />
      {/*
        Temporarily removing these fields as they're added to the database
        but not used anywhere else in code - which is generating confusion
        for our customers. Can be uncommented and added back in whenever needed!
      */}
      {/* <GoogleSearchConsoleSection /> */}
    </>
  );
};

import styled from '@emotion/styled';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import {
  RumErrorBoundary as ErrorBoundary,
  ErrorView,
  StoreSettingsSideNav,
} from '@jane/business-admin/components';
import { spacing } from '@jane/shared/reefer-emotion';

const Wrapper = styled.div({
  display: 'flex',
  ...spacing({ px: 64, py: 40 }),
});

const Main = styled.div({
  width: '85%',
});

export const StoreSettings = () => {
  return (
    <Suspense
      fallback={
        <Wrapper>
          <StoreSettingsSideNav />
        </Wrapper>
      }
    >
      <Wrapper>
        <StoreSettingsSideNav />
        <Main>
          <ErrorBoundary fallback={ErrorView} scope="stores.settings">
            <Outlet />
          </ErrorBoundary>
        </Main>
      </Wrapper>
    </Suspense>
  );
};

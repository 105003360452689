import styled from '@emotion/styled';

import { Typography } from '@jane/shared/reefer';

export const TableRowWithBorder = styled.tr<{ hasBorder?: boolean }>(
  ({ theme, hasBorder }) => ({
    borderColor: theme.colors.grays.light,
    borderBottomWidth: hasBorder ? 1 : 0,
  }),
  {
    borderBottomStyle: 'solid',
  }
);

export const TableHeaderItem = ({ label }: { label: string }) => (
  <td>
    <Typography variant="caps" ml={16} mb={16} color="grays-mid">
      {label}
    </Typography>
  </td>
);

const TableContentItem = ({
  label,
  closed,
}: {
  closed?: boolean;
  label?: string | number;
}) => (
  <td>
    <Typography m={16} color={closed ? 'grays-mid' : 'grays-dark'}>
      {closed ? 'Closed' : label}
    </Typography>
  </td>
);

export const CollapsedBorderTable = styled.table({
  borderCollapse: 'collapse',
  width: '100%',
});

type TableHeader = {
  column_value: string;
  label: string;
};
export const TableWithBorderSeparator = ({
  name,
  headers,
  data,
}: {
  data: { [key: string]: string | number }[];
  headers: TableHeader[];
  name: string;
}) => (
  <CollapsedBorderTable aria-label={name}>
    <thead>
      <tr>
        {headers.map(({ label }: TableHeader) => (
          <TableHeaderItem label={label} />
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <TableRowWithBorder hasBorder={index !== data.length - 1}>
          {headers.map(({ column_value }: TableHeader) => (
            <TableContentItem label={item[column_value]} />
          ))}
        </TableRowWithBorder>
      ))}
    </tbody>
  </CollapsedBorderTable>
);

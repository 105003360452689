import * as t from 'io-ts';

const tCloudflareImageFile = t.interface({
  url: t.union([t.string, t.null]),
  small: t.interface({ url: t.union([t.string, t.null]) }),
  medium: t.interface({ url: t.union([t.string, t.null]) }),
  extraLarge: t.interface({ url: t.union([t.string, t.null]) }),
});
export const tCloudflareImage = t.interface({
  id: t.number,
  imageable_id: t.number,
  imageable_type: t.string,
  file: tCloudflareImageFile,
  file_processing: t.boolean,
  created_at: t.string,
  updated_at: t.string,
  position: t.union([t.number, t.null]),
  purpose: t.string,
});

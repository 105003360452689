/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const HideEye24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Hide Eye 24">
      <path
        fill="#0E0E0E"
        d="M20.707 4.707a1 1 0 0 0-1.414-1.414l-16 16a1 1 0 1 0 1.414 1.414l16-16ZM1.418 9.956c3.984-5.126 9.07-6.943 13.835-5.452L13.606 6.15A8.683 8.683 0 0 0 12 6c-2.987 0-6.197 1.574-9.003 5.184a1.33 1.33 0 0 0 0 1.632c.61.783 1.237 1.47 1.878 2.067L3.46 16.297a19.462 19.462 0 0 1-2.042-2.254 3.33 3.33 0 0 1 0-4.087ZM12 18c-.53 0-1.066-.05-1.606-.151l-1.648 1.647c4.766 1.491 9.852-.326 13.835-5.452a3.33 3.33 0 0 0 0-4.087 19.465 19.465 0 0 0-2.041-2.254l-1.415 1.414c.64.597 1.269 1.284 1.877 2.067a1.33 1.33 0 0 1 0 1.632C18.197 16.426 14.987 18 12 18Z"
        className="SVG"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: HideEye24,
  sm: HideEye24,
  lg: HideEye24,
  xl: HideEye24,
  xxl: HideEye24,
  xxxl: HideEye24,
};

export const HideEyeIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'hide-eye-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import * as t from 'io-ts';

import {
  tCanPayV2RemotePayIntegration,
  tCanpayV2Integration,
  tCheckoutAgreementV2,
  tCurbsidePickupSettingObject,
  tDeliveryConfigStrategy,
  tDeliveryZipcode,
  tFreeDeliveryConfigV2,
  tMedicalStoreDocumentationRequirement,
  tMonerisIntegration,
  tPayfirmaIntegration,
  tStatusMessageV2,
  tStoreChatSetting,
  tStoreCrmIntegration,
  tStoreDiscountSettings,
  tStoreDmSetting,
  tSubdomainSetting,
  tTipSetting,
} from '@jane/shared/models';

import { tAeropayIntegrationV2 } from './aeropayIntegration';
import { tAnalyticsIntegrationV2 } from './analyticsIntegration';
import { tBoostConfig } from './boostConfig';
import { tAbbreviatedCartLimitPolicy, tCartLimitPolicyV2 } from './cartLimit';
import { tCloudflareHostnameV2 } from './cloudflareHostname';
import { tCloudflareImage } from './cloudflareImage';
import { tId } from './common';
import { tDeliveryGeofenceV2 } from './deliveryGeofence';
import { tFulfillmentConfig } from './fulfillmentConfig';
import { tJanePayIntegration } from './janePayIntegration';
import { tKioskV2 } from './kiosk';
import { tMaxOrderSetting } from './maxOrderSetting';
import { tOwnershipIdentification } from './ownershipIdentification';
import { tPaymentOptionV2 } from './paymentOptionV2';
import { tSmsSetting } from './smsSetting';
import { tSquareIntegration } from './square';
import { tStoreDismissalMessage } from './storeDismissalMessage';
import { tStoreSchedule } from './storeSchedule';
import { tStrongholdIntegrationV2 } from './strongholdIntegrationV2';

export const tCustomLineageLabels = t.type({
  cbd: t.union([t.string, t.undefined]),
  hybrid: t.union([t.string, t.undefined]),
  indica: t.union([t.string, t.undefined]),
  none: t.union([t.string, t.undefined]),
  sativa: t.union([t.string, t.undefined]),
});
export type CustomLineageLabelsV2 = t.TypeOf<typeof tCustomLineageLabels>;

export const tStoreV2 = t.interface({
  address: t.string,
  address2: t.union([t.string, t.null]),
  allow_future_day_ordering: t.boolean,
  allow_off_hours_ordering: t.boolean,
  birth_date_required: t.boolean,
  boost_enabled: t.boolean,
  canpay_enabled: t.boolean,
  canpay_lane_id: t.union([t.string, t.null]),
  canpay_merchant_id: t.union([t.string, t.null]),
  canpay_store_code: t.union([t.string, t.null]),
  city: t.string,
  country_code: t.union([t.string, t.null]),
  customer_paperwork: t.union([t.string, t.null]),
  custom_navigation_color: t.union([t.string, t.null]),
  custom_theme_color: t.union([t.string, t.null]),
  delivery: t.boolean,
  delivery_id_required: t.boolean,
  delivery_fee_amount: t.number,
  delivery_radius: t.union([t.number, t.null]),
  description: t.union([t.string, t.null]),
  id: t.number,
  id_required: t.boolean,
  latitude: t.number,
  longitude: t.number,
  name: t.string,
  phone: t.string,
  pickup: t.boolean,
  pickup_id_required: t.boolean,
  recreational: t.boolean,
  sales_tax_rate: t.number,
  send_cart_status_updates_via_email: t.boolean,
  service_fee_amount: t.number,
  show_paperwork: t.boolean,
  state: t.string,
  tax_included: t.boolean,
  time_zone_identifier: t.string,
  wheelchair_access: t.boolean,
  white_label_enabled: t.boolean,
  zip: t.string,
});
export type StoreV2 = t.TypeOf<typeof tStoreV2>;

const tStoreProductsCount = t.partial({
  hidden_products_count: t.number,
  unmapped_products_count: t.number,
  visible_products_count: t.number,
});
export const tAbbreviatedStore = t.intersection([
  t.interface({
    address: t.string,
    address2: t.union([t.string, t.null]),
    cart_limit_policy: t.union([t.null, tAbbreviatedCartLimitPolicy]),
    city: t.union([t.string, t.null]),
    id: tId,
    name: t.string,
    photo: t.union([tCloudflareImage, t.null]),
    recreational: t.boolean,
    state: t.union([t.string, t.null]),
    zip: t.union([t.string, t.undefined]),
  }),
  tStoreProductsCount,
]);
export type AbbreviatedStoreV2 = t.TypeOf<typeof tAbbreviatedStore>;

export const tStoreSettingsPayload = t.interface({
  store: tStoreV2,
  aeropay_integration: t.union([tAeropayIntegrationV2, t.null, t.undefined]),
  analytics_integration: tAnalyticsIntegrationV2,
  boost_configs: t.array(tBoostConfig),
  canpay_v2_integration: t.union([tCanpayV2Integration, t.null, t.undefined]),
  canpay_v2remotepay_integration: t.union([
    tCanPayV2RemotePayIntegration,
    t.null,
    t.undefined,
  ]),
  cart_limit_policy: t.union([tCartLimitPolicyV2, t.null]),
  checkout_agreements: t.union([t.array(tCheckoutAgreementV2), t.null]),
  cloudflare_hostname: t.union([tCloudflareHostnameV2, t.undefined, t.null]),
  curbside_fulfillment_config: tFulfillmentConfig,
  curbside_schedule: t.union([tStoreSchedule, t.null]),
  custom_labels: tCustomLineageLabels,
  delivery_config: tDeliveryConfigStrategy,
  delivery_fulfillment_config: tFulfillmentConfig,
  delivery_geofences: t.array(tDeliveryGeofenceV2),
  delivery_schedule: t.union([tStoreSchedule, t.null]),
  delivery_zipcodes: t.array(tDeliveryZipcode),
  jane_pay_integration: t.union([tJanePayIntegration, t.null, t.undefined]),
  in_specials_stacking_beta: t.boolean,
  kiosk_photo: t.union([t.string, t.null]),
  kiosk: t.union([tKioskV2, t.null, t.undefined]),
  magic_row_enabled: t.boolean,
  medical_store_documentation_requirement: t.union([
    tMedicalStoreDocumentationRequirement,
    t.null,
  ]),
  moneris_integration: t.union([tMonerisIntegration, t.null, t.undefined]),
  ownership_identification: t.union([tOwnershipIdentification, t.null]),
  payfirma_integration: t.union([tPayfirmaIntegration, t.null, t.undefined]),
  payment_options: t.array(tPaymentOptionV2),
  photo: t.union([t.string, t.null]),
  pickup_fulfillment_config: tFulfillmentConfig,
  pickup_schedule: t.union([tStoreSchedule, t.null]),
  primary_gallery_image: t.union([t.string, t.null]),
  retail_schedule: t.union([tStoreSchedule, t.null]),
  square_integration: t.union([tSquareIntegration, t.null]),
  status_messages: t.union([t.array(tStatusMessageV2), t.null]),
  store_chat_setting: t.union([tStoreChatSetting, t.null]),
  store_crm_integrations: t.union([t.array(tStoreCrmIntegration), t.null]),
  store_curbside_pickup_setting: t.union([
    tCurbsidePickupSettingObject,
    t.null,
  ]),
  store_discount_setting: t.union([tStoreDiscountSettings, t.null]),
  store_dismissal_messages: t.union([t.array(tStoreDismissalMessage), t.null]),
  store_dm_settings: t.union([t.array(tStoreDmSetting), t.null, t.undefined]),
  store_free_delivery_config: t.union([tFreeDeliveryConfigV2, t.null]),
  store_reservation_slot_max_order_settings: t.union([
    t.array(tMaxOrderSetting),
    t.null,
  ]),
  store_sms_provider_setting: t.union([tSmsSetting, t.null]),
  store_subdomain_setting: t.union([tSubdomainSetting, t.null]),
  store_tip_settings: t.union([t.array(tTipSetting), t.null]),
  stronghold_integration: t.union([tStrongholdIntegrationV2, t.null]),
  id_upload_prohibited: t.boolean,
});
export type StoreSettingsPayload = t.TypeOf<typeof tStoreSettingsPayload>;

export const tStoreImagesPayload = t.interface({
  comms_banner_image: t.string,
  name: t.string,
  photo: t.string,
  primary_gallery_image: t.string,
});

export type StoreImagesPayload = t.TypeOf<typeof tStoreImagesPayload>;

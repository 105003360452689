import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  useStoreDiscountSettings,
  useStoreSettings,
} from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { BODY_LINE_HEIGHT, ModalNames } from '@jane/business-admin/util';
import { Skeleton, Tag, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { EditableCard } from '../../../../../EditableCard';
import { TableRowWithBorder } from '../../../../../TableWithBorderSeparator';
import { StackingModal } from './StackingModal';

const lengthFiveArray = Array(5).fill(null);

const StackingTable = styled.table({
  borderCollapse: 'collapse',
  ...spacing({ mb: 32 }),
});

const TableHeaderItem = ({ label }: { label: string }) => (
  <td width="50%">
    <Typography variant="caps" mb={16} ml={16} color="grays-mid">
      {label}
    </Typography>
  </td>
);

const TableContentItem = ({ label }: { label?: string }) => (
  <td width="50%">
    <Typography m={16}>{label}</Typography>
  </td>
);

const LoadingTableColumns = () => (
  <tr>
    <td>
      <Skeleton animate height="fit-content" width="320px">
        <Skeleton.Bone height={BODY_LINE_HEIGHT} m={20} />
      </Skeleton>
    </td>
    <td>
      <Skeleton animate height="fit-content" width="320px">
        <Skeleton.Bone height={BODY_LINE_HEIGHT} m={20} />
      </Skeleton>
    </td>
  </tr>
);

const TableRow = ({
  hasBorder = false,
  label,
  setKey,
  setting,
}: {
  hasBorder?: boolean;
  label: string;
  setKey: string;
  setting?: string;
}) => (
  <TableRowWithBorder key={setKey} hasBorder={hasBorder}>
    <>
      <TableContentItem label={label} />
      <TableContentItem
        label={
          setting === 'combinable'
            ? 'Only with other stackable specials'
            : capitalize(setting)
        }
      />
    </>
  </TableRowWithBorder>
);

export const StackingCard = () => {
  const { id = '' } = useParams<'id'>();
  const {
    data: storeDiscountSettings,
    isFetching: isStoreDiscountSettingsFetching,
  } = useStoreDiscountSettings(id);
  const { data: storeSettings } = useStoreSettings(id);
  const { canEditStore } = useContext(StoreDetailsContext);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.SpecialsStacking
  );

  return (
    <>
      <EditableCard
        title="Specials stacking"
        onEdit={() => openModal()}
        isLoading={isStoreDiscountSettingsFetching}
        disabled={!canEditStore}
        endAdornment={<Tag label="Beta" background="grays-black" />}
      >
        <StackingTable aria-label="stacking options table">
          <thead>
            <tr>
              <TableHeaderItem label="Type" />
              <TableHeaderItem label="Stacking allowed" />
            </tr>
          </thead>
          <tbody>
            {isStoreDiscountSettingsFetching ? (
              lengthFiveArray.map((_, i) => (
                <LoadingTableColumns key={`skeleton${i}`} />
              ))
            ) : (
              <>
                <TableRow
                  hasBorder
                  setKey="product_specials_stacking_setting"
                  label="Product specials"
                  setting={
                    storeDiscountSettings?.product_specials_stacking_setting
                  }
                />
                <TableRow
                  hasBorder
                  setKey="bulk_pricing_specials_stacking_setting"
                  label="Bulk specials"
                  setting={
                    storeDiscountSettings?.bulk_pricing_specials_stacking_setting
                  }
                />
                <TableRow
                  hasBorder
                  setKey="cart_total_specials_stacking_setting"
                  label="Cart total specials"
                  setting={
                    storeDiscountSettings?.cart_total_specials_stacking_setting
                  }
                />
                <TableRow
                  hasBorder
                  setKey="bundle_specials_stacking_setting"
                  label="Buy X get Y specials"
                  setting={
                    storeDiscountSettings?.bundle_specials_stacking_setting
                  }
                />
                <TableRow
                  setKey="group_specials_stacking_setting"
                  label="Qualified group specials"
                  setting={
                    storeDiscountSettings?.group_specials_stacking_setting
                  }
                />
              </>
            )}
          </tbody>
        </StackingTable>
      </EditableCard>
      {modalOpen && (
        <StackingModal
          open
          setOpen={() => closeModal()}
          storeName={storeSettings?.store?.name || ''}
          storeId={storeSettings?.store?.id.toString() || ''}
          storeDiscountSettings={storeDiscountSettings}
        />
      )}
    </>
  );
};

import type { SVGProps } from 'react';

import type { IconProps } from '@jane/shared/reefer';
import { Icon } from '@jane/shared/reefer';

const ColorSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <rect
        x="1"
        y="1"
        width="26"
        height="26"
        rx="13"
        stroke="#F7F7F7"
        stroke-width="2"
      />
      <mask
        id="mask0_2226_1857"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="24"
        height="24"
      >
        <circle cx="14" cy="14" r="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2226_1857)">
        <circle cx="18" cy="21" r="19" fill="url(#paint0_radial_2226_1857)" />
        <circle cx="18" cy="-6" r="19" fill="url(#paint1_radial_2226_1857)" />
        <circle cx="9" cy="29" r="19" fill="url(#paint2_radial_2226_1857)" />
        <circle cx="3" cy="2" r="19" fill="url(#paint3_radial_2226_1857)" />
        <circle cx="-1" cy="21" r="19" fill="url(#paint4_radial_2226_1857)" />
        <circle
          cx="12"
          cy="12"
          r="12"
          transform="matrix(-1 0 0 1 26 2)"
          fill="url(#paint5_radial_2226_1857)"
          fill-opacity="0.8"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_2226_1857"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18 21) rotate(90) scale(19)"
        >
          <stop stop-color="#FF0000" />
          <stop offset="1" stop-color="#FF0000" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2226_1857"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18 -6) rotate(90) scale(19)"
        >
          <stop stop-color="#00D1FF" />
          <stop offset="1" stop-color="#00FFF0" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_2226_1857"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9 29) rotate(90) scale(19)"
        >
          <stop stop-color="#FAFF00" />
          <stop offset="1" stop-color="#FAFF00" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_2226_1857"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3 2) rotate(90) scale(19)"
        >
          <stop stop-color="#7000FF" />
          <stop offset="1" stop-color="#00FFF0" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_2226_1857"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-1 21) rotate(90) scale(19)"
        >
          <stop stop-color="#FF00C7" />
          <stop offset="1" stop-color="#FF00C7" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_2226_1857"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12 12) rotate(90) scale(12)"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export const ColorIcon = (props: Omit<IconProps, 'icon'>) => {
  return <Icon icon={ColorSvg} {...props} />;
};

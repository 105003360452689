import * as t from 'io-ts';

// TODO: Commented out some stuff that's missing - seems it forgot to be included
export const tAeropayIntegrationV2 = t.interface({
  autocapture_on_checkout: t.union([t.boolean, t.undefined]),
  // custom_button_image_url: t.union([t.string, t.null, t.undefined]),
  // custom_payment_method_label: t.union([t.string, t.null, t.undefined]),
  enabled: t.union([t.boolean, t.undefined]),
  id: t.union([t.number, t.undefined]),
  location_uuid: t.union([t.string, t.null, t.undefined]),
  reservation_modes: t.union([
    t.array(t.string),
    t.type({ delivery: t.boolean, pickup: t.boolean, curbside: t.boolean }),
  ]),
});
export type AeropayIntegrationV2 = t.TypeOf<typeof tAeropayIntegrationV2>;

import type { RefObject } from 'react';
import { useEffect } from 'react';

import { useIntersectionObserver } from './useIntersectionObserver';

interface ObserverParams {
  fetchNextPage: () => void;
  rootMargin?: string;
  triggerRef: RefObject<HTMLElement>;
}

export const useLoadNextPageOnScroll = (
  { fetchNextPage, triggerRef, rootMargin = '300px' }: ObserverParams,
  deps: Array<any> = []
): IntersectionObserver => {
  const observer = useIntersectionObserver(
    (entries) => {
      const lastEntry = entries[0];

      if (lastEntry.isIntersecting) {
        fetchNextPage();
        observer.unobserve(lastEntry.target);
      }
    },
    { rootMargin },
    [fetchNextPage]
  );

  useEffect(() => {
    triggerRef.current && observer.observe(triggerRef.current);
    return () => observer.disconnect();
  }, [...deps]);

  return observer;
};

import type { MaskitoOptions } from '@maskito/core';

import { alwaysStartsWith } from './alwaysStartsWith';

export const urlMask: MaskitoOptions = {
  mask: /^https:\/\/.*$/,
  preprocessors: [alwaysStartsWith('https://')],
  postprocessors: [
    ({ value, selection }) => {
      // Removes duplicate 'https://' if user tries to type it again
      if (value.startsWith('https://https://')) {
        return {
          value: 'https://' + value.slice(16),
          selection: [16, 16],
        };
      }
      return { value, selection };
    },
  ],
};

import * as t from 'io-ts';

export const tManager = t.interface({
  id: t.number,
  email: t.string,
  phone: t.string,
  name: t.string,
  internal: t.boolean,
  // TODO: Make this an enum?
  role: t.string,
  first_name: t.string,
  last_name: t.string,
  accepted_terms_and_conditions: t.boolean,
  permissions: t.array(t.string),
  stores: t.array(t.number),
});

export type Manager = t.TypeOf<typeof tManager>;

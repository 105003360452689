import { useState } from 'react';

import {
  useClearQueue,
  useGeneratePassword,
  useUpdateJanePrinter,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import type { JanePrinterV2 } from '@jane/business-admin/types';
import { EventNames, ModalNames, track } from '@jane/business-admin/util';
import {
  Button,
  EditIcon,
  Flex,
  Link,
  SwitchField,
  Typography,
} from '@jane/shared/reefer';

export const PrinterSettingsSection = ({
  janePrinter,
  isInternal,
  storeId,
  onEditClick,
}: {
  isInternal: boolean;
  janePrinter: JanePrinterV2;
  onEditClick: (janePrinter: JanePrinterV2) => void;
  storeId: string;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating printer. Please try again.'
  );

  const {
    mutateAsync: updateJanePrinter,
    isLoading: isUpdating,
    isSuccess: isSuccessUpdateJanePrinter,
    isError: isErrorUpdateJanePrinter,
  } = useUpdateJanePrinter(storeId, janePrinter.id);
  const {
    mutateAsync: clearQueue,
    isLoading: isClearing,
    isSuccess: isSuccessClearQueue,
    isError: isErrorClearQueue,
  } = useClearQueue(storeId);

  useMutationStatusToasts({
    isMutating: isUpdating,
    isSuccess: isSuccessUpdateJanePrinter,
    isError: isErrorUpdateJanePrinter,
    successMessage: 'Printer updated',
    errorMessage: 'Error updating printer. Please try again.',
  });

  useMutationStatusToasts({
    isMutating: isClearing,
    isSuccess: isSuccessClearQueue,
    isError: isErrorClearQueue,
    successMessage: 'Queue cleared',
    errorMessage: 'Error clearing queue. Please try again.',
  });

  const onClearQueue = (janePrinterId: string) => {
    track({
      event: EventNames.ClickedButton,
      button_label: 'clear queue',
      modal_name: ModalNames.EditPrinter,
    });

    return catchSubmitErrors({
      submitMethod: () => clearQueue({ janePrinterId, storeId }),
      requestData: {},
      onValidationError: () => null,
      callback: () => null,
    });
  };

  const onChangeEnabled = (janePrinter: JanePrinterV2, value: boolean) => {
    track({
      event: EventNames.ToggleVisibility,
      final_state: value ? 'visible' : 'hidden',
      trigger_source_id: 'printer card',
      successful: true,
      object: 'printer',
    });

    return catchSubmitErrors({
      submitMethod: () =>
        updateJanePrinter({
          enabled: value,
          store_ids: janePrinter.store_jane_printers.map((s) => s.store_id),
        }),
      requestData: {},
      onValidationError: () => null,
      callback: () => null,
    });
  };

  const checkoutStrings = [];
  if (janePrinter.delivery_order_checked_out) {
    checkoutStrings.push('Delivery checkout');
  }
  if (janePrinter.curbside_order_checked_out) {
    checkoutStrings.push('Curbside checkout');
  }
  if (janePrinter.kiosk_order_checked_out) {
    checkoutStrings.push('Kiosk checkout');
  }
  if (janePrinter.pickup_order_checked_out) {
    checkoutStrings.push('Pickup checkout');
  }
  if (janePrinter.curbside_arrived) {
    checkoutStrings.push('Curbside arrived');
  }

  const { mutateAsync: generatePassword } = useGeneratePassword(storeId);
  const [password, setPassword] = useState('');
  const fetchPassword = (janePrinterId: string) => {
    track({
      event: EventNames.ClickedButton,
      button_label: 'show password',
      modal_name: ModalNames.EditPrinter,
    });

    generatePassword({ janePrinterId, storeId }).then((res) =>
      setPassword(res.password)
    );
  };

  return (
    <>
      <Flex flexDirection="row" alignItems="center" mb={40}>
        <Flex width="100%" flexDirection="column">
          <Typography variant="caps" color="grays-mid">
            PRINTER
          </Typography>
          <Typography variant="body-bold">{janePrinter.name}</Typography>
        </Flex>
        <Flex width="100%" flexDirection="row" justifyContent="flex-end">
          <Flex>
            <Button
              variant="tertiary"
              mr={16}
              label={
                <SwitchField
                  defaultChecked={janePrinter.enabled}
                  label={<Typography variant="body-bold">Enabled</Typography>}
                  name={`enabled-${janePrinter.id}`}
                  onChange={(value: boolean) =>
                    onChangeEnabled(janePrinter, value)
                  }
                />
              }
            />
          </Flex>
          <Flex>
            <Button
              variant="tertiary"
              onClick={() => onClearQueue(janePrinter.id)}
              label={
                <Typography variant="body-bold">
                  Clear queue ({janePrinter.number_of_queued_jobs})
                </Typography>
              }
            />
          </Flex>
          {isInternal && (
            <Flex>
              <Button.Icon
                icon={<EditIcon />}
                label="Edit printer"
                variant="secondary"
                data-testid="printers-edit"
                onClick={() => onEditClick(janePrinter)}
                ml={16}
              />
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
        <Flex width="33%" flexDirection="column">
          <Typography variant="caps" color="grays-mid">
            MAC ADDRESS
          </Typography>
          <Typography>{janePrinter.mac_address}</Typography>
        </Flex>
        <Flex width="33%" flexDirection="column">
          <Typography variant="caps" color="grays-mid">
            USERNAME
          </Typography>
          <Typography>{janePrinter.username}</Typography>
        </Flex>
        <Flex width="33%" flexDirection="column">
          <Typography variant="caps" color="grays-mid">
            PASSWORD
          </Typography>
          {password ? (
            <Typography>{password}</Typography>
          ) : (
            <Link onClick={() => fetchPassword(janePrinter.id)}>
              Show password
            </Link>
          )}
        </Flex>
        <Flex width="50%" flexDirection="column" my={24}>
          <Typography variant="caps" color="grays-mid">
            CHECKOUT SETTINGS
          </Typography>
          {checkoutStrings.length > 0 ? (
            <Typography>{checkoutStrings.join(', ')}</Typography>
          ) : (
            <Typography>None selected</Typography>
          )}
        </Flex>
      </Flex>
    </>
  );
};

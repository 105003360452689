import capitalize from 'lodash/capitalize';
import { useEffect, useMemo, useState } from 'react';

import { useDuplicatesValidation } from '@jane/business-admin/hooks';
import type { MaxOrderSetting } from '@jane/business-admin/types';
import {
  ALL_DAYS,
  EventNames,
  getTwentyFourHours,
  track,
} from '@jane/business-admin/util';
import {
  Button,
  Flex,
  Form,
  Grid,
  TrashIcon,
  Typography,
  useFieldArray,
  useFormContext,
} from '@jane/shared/reefer';
import { ISO8601, formatUtc } from '@jane/shared/util';

const FIELD_ARRAY_NAME = 'store_reservation_slot_max_order_settings';

const MaxOrderItem = ({
  hasDuplicateError,
  index,
  setting,
  removeItem,
  hourOptions,
}: {
  hasDuplicateError: boolean;
  hourOptions: { label: string; value: string }[];
  index: number;
  removeItem: (index: number) => void;
  setting: MaxOrderSetting;
}) => {
  const [formError, setFormError] = useState<string | undefined>();
  const { setValue } = useFormContext();
  const daysOptions = ALL_DAYS.map((day) => ({
    label: capitalize(day),
    value: capitalize(day),
  }));

  useEffect(() => {
    if (hasDuplicateError) {
      setFormError('An order limit for this reservation slot already exists.');
    } else {
      setFormError(undefined);
    }
  }, [hasDuplicateError]);

  const startTimeConverted = useMemo(() => {
    return formatUtc(setting.start_time, ISO8601, 'h:mm A');
  }, [setting.start_time]);

  const convertTimeOnChange = (time: string) => {
    setValue(
      `${FIELD_ARRAY_NAME}.${index}.start_time`,
      formatUtc(time, 'h:mm A', '')
    );
  };

  return (
    <Flex mb={24} flexDirection="column">
      <Flex justifyContent="space-between" alignItems="center" gap={24}>
        <Form.SwitchField
          defaultChecked={setting.enabled}
          labelHidden
          name={`${FIELD_ARRAY_NAME}.${index}.enabled`}
          label="Day Enabled"
        />
        <Form.SelectField
          defaultValue={setting.day_of_week}
          width="100%"
          name={`${FIELD_ARRAY_NAME}.${index}.day_of_week`}
          label="Day"
          options={daysOptions}
          required
        />
        <Form.SelectField
          defaultValue={startTimeConverted}
          width="100%"
          name={`display.${FIELD_ARRAY_NAME}.${index}.start_time_converted`}
          label="Time"
          options={hourOptions}
          required
          onChange={convertTimeOnChange}
        />
        <Form.NumberField
          defaultValue={setting.max_order_limit}
          width="100%"
          name={`${FIELD_ARRAY_NAME}.${index}.max_order_limit`}
          label="Order limit"
        />
        <Flex justifyContent="stretch">
          <Button.Icon
            onClick={() => removeItem(index)}
            label="remove day"
            icon={<TrashIcon />}
          />
        </Flex>
      </Flex>
      {formError && (
        <Typography ml={64} color="error" mt={16}>
          {formError}
        </Typography>
      )}
    </Flex>
  );
};

export const defaultNewSetting = (fulfillment_type: string) => ({
  enabled: true,
  day_of_week: 'Monday',
  start_time: formatUtc('12:00 AM', 'h:mm A', ''),
  max_order_limit: 1,
  reservation_mode: fulfillment_type,
});

export const MaxOrdersSection = ({
  fulfillmentType,
  maxOrdersPerReservationSlot,
  maxOrdersPerWindow,
}: {
  fulfillmentType: string;
  maxOrdersPerReservationSlot: MaxOrderSetting[] | null;
  maxOrdersPerWindow: number | null;
}) => {
  const { control, clearErrors, setError, setValue, trigger, watch } =
    useFormContext();
  const {
    fields: maxOrderSettings,
    append,
    remove,
  } = useFieldArray({
    control,
    name: FIELD_ARRAY_NAME,
  });
  const maxOrdersWatch = watch(FIELD_ARRAY_NAME);

  const hourOptions = useMemo(() => {
    return getTwentyFourHours();
  }, []);

  const { duplicateIndexes } = useDuplicatesValidation({
    fieldArrayData: maxOrdersWatch,
    mappingMethod: (option: MaxOrderSetting) => {
      const date = new Date(option.start_time);
      const justTime = `${date.getUTCHours()}:${date.getUTCMinutes()}`;
      return `${option.day_of_week} / ${justTime} / ${option.max_order_limit}`;
    },
    setError,
    clearErrors,
    trigger,
  });

  useEffect(() => {
    setValue(FIELD_ARRAY_NAME, maxOrdersPerReservationSlot);
  }, [maxOrdersPerReservationSlot, setValue]);

  const addNewMaxOrder = () => {
    append(defaultNewSetting(fulfillmentType));
  };

  const removeMaxOrder = (index: number) => {
    track({
      event: EventNames.DeleteStoreEntity,
      modal_name: `${capitalize(fulfillmentType)} Settings`,
      entity_name: 'max_orders_per_window',
    });
    remove(index);
  };

  return (
    <Flex flexDirection="column">
      <Typography mb={12} variant="body-bold">
        Max orders per window
      </Typography>
      <Grid.Container spacing={40}>
        <Grid.Item xs={4}>
          <Form.NumberField
            defaultValue={maxOrdersPerWindow || undefined}
            name="max_orders_per_window"
            label="Order limit"
            mb={32}
          />
        </Grid.Item>
        <Grid.Item xs={4}></Grid.Item>
      </Grid.Container>

      <Typography mb={8} variant="body-bold">
        Max orders per reservation slot
      </Typography>
      <Typography color="grays-mid" mb={24}>
        Create order limits by window per reservation slot. These
        reservation-slot-specific values will override any general max order
        limits your store has defined.
      </Typography>

      {maxOrderSettings.map((setting, index) => (
        <MaxOrderItem
          key={setting.id}
          setting={setting as unknown as MaxOrderSetting}
          index={index}
          removeItem={removeMaxOrder}
          hourOptions={hourOptions}
          hasDuplicateError={duplicateIndexes.has(index)}
        />
      ))}

      <Flex justifyContent="center" mb={32}>
        <Flex width={200}>
          <Button
            onClick={addNewMaxOrder}
            full
            label="Add new"
            variant="secondary"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

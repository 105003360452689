import styled from '@emotion/styled';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchMenuRows } from '@jane/business-admin/data-access';
import { useSelectedMenuRows } from '@jane/business-admin/hooks';
import type {
  AbbreviatedMenuRow,
  StoreSettingsPayload,
} from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  SearchSubjects,
  SettingNames,
  track,
} from '@jane/business-admin/util';
import {
  Button,
  CheckboxField,
  Flex,
  Modal,
  SearchField,
  Typography,
} from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';
import { CollapsedBorderTable } from '../../../../TableWithBorderSeparator';
import { LoadingMenuRowsTable, Row } from './MenuRowsCard';

const TableWrapper = styled.div({
  overflowY: 'auto',
  height: 'calc(100% - 32px)',
});

export const MenuRowsModal = ({
  initiallySelected,
  onClose,
  open,
  setSelected,
  store,
}: {
  initiallySelected: AbbreviatedMenuRow[];
  onClose: () => void;
  open: boolean;
  setSelected: (value: any) => void;
  store?: StoreSettingsPayload;
}) => {
  const { id = '' } = useParams<'id'>();
  const [customDisplayNameFilter, setCustomDisplayNameFilter] =
    useState<string>('');

  const { data: menuRows, isFetched: menuRowsFetched } = useFetchMenuRows(
    id,
    customDisplayNameFilter
  );

  const { selectedRows, isDirty, select, deselect, reset, selectAll } =
    useSelectedMenuRows(initiallySelected);

  const totalLoadedRows = menuRows?.length;
  const selectedCount = Object.keys(selectedRows).length;

  const onSubmit = () => {
    setSelected(Object.values(selectedRows));
    onClose();
  };

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={onClose}
      hasChanges={isDirty}
      modalName={ModalNames.EditProduct}
    >
      <>
        <Modal.Header
          title="Menu rows"
          subtitle={store?.store.name}
          actions={
            <Button
              label="Save"
              variant="primary"
              onClick={() => {
                onSubmit();
                track({
                  event: EventNames.ModifiedSetting,
                  modal_name: ModalNames.EditProduct,
                  revert: false,
                  setting_name: SettingNames.PdpMenuRows,
                });
              }}
            />
          }
        />
        <Modal.Content overflowY="hidden">
          <Flex mb={24}>
            <SearchField
              width="100%"
              placeholder="Search menu rows"
              onChange={(value) => {
                setCustomDisplayNameFilter(value);
                track({
                  event: EventNames.Search,
                  arguments: value,
                  subject: SearchSubjects.Products,
                });
              }}
              name="search-product-groups"
              label="Search menu rows"
              defaultValue={customDisplayNameFilter}
              isDebounced
              debounceDelay={750}
            />
          </Flex>
          <TableWrapper>
            {menuRowsFetched ? (
              <CollapsedBorderTable>
                <Table.Head>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Typography variant="caps" color="grays-mid">
                        Name
                      </Typography>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Typography variant="caps" color="grays-mid">
                        Visibility
                      </Typography>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {menuRows &&
                    menuRows.map((row, index) => {
                      const isLast = index + 1 === menuRows.length;
                      return (
                        <Row hideBottomBorder={isLast} key={`${row.id}-table`}>
                          <Table.Cell width="75%">
                            <Flex gap={16}>
                              <CheckboxField
                                name={row.custom_display_name}
                                label={row.custom_display_name}
                                labelHidden
                                checked={!!selectedRows[row.id]}
                                onChange={(value) => {
                                  if (value) {
                                    select(row);
                                  } else {
                                    deselect(row.id);
                                  }
                                  track({
                                    event: EventNames.SelectedCheckbox,
                                    checkbox_label: row.custom_display_name,
                                    modal_name: ModalNames.MenuRowsModal,
                                  });
                                }}
                              />
                              <Typography>{row.custom_display_name}</Typography>
                            </Flex>
                          </Table.Cell>
                          <Table.Cell>
                            <Typography>
                              {row.enabled ? 'On' : 'Off'}
                            </Typography>
                          </Table.Cell>
                        </Row>
                      );
                    })}
                </Table.Body>
              </CollapsedBorderTable>
            ) : (
              <LoadingMenuRowsTable arraySize={8} />
            )}
          </TableWrapper>
        </Modal.Content>
        <Modal.Footer>
          <Flex justifyContent="space-between" alignItems="center" mt={4}>
            <Typography color="grays-mid">{`${selectedCount} menu rows selected`}</Typography>
            <Flex gap={16}>
              <Button
                variant="tertiary"
                label="Select all"
                disabled={selectedCount === totalLoadedRows}
                onClick={() => {
                  menuRows && selectAll(menuRows);
                  track({
                    event: EventNames.ClickedButton,
                    button_label: 'Select all',
                    modal_name: ModalNames.MenuRowsModal,
                  });
                }}
              />
              <Button
                variant="tertiary"
                label="Select none"
                disabled={!selectedCount}
                onClick={() => {
                  reset();
                  track({
                    event: EventNames.ClickedButton,
                    button_label: 'Select none',
                    modal_name: ModalNames.MenuRowsModal,
                  });
                }}
              />
            </Flex>
          </Flex>
        </Modal.Footer>
      </>
    </ConfirmWrapperWithTracking>
  );
};

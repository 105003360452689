import { Fragment, useContext, useState } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import { AnalyticsPageContext } from '@jane/business-admin/providers';
import {
  EventNames,
  NavigationSourceIds,
  businessPaths,
  track,
} from '@jane/business-admin/util';
import { Tabs } from '@jane/shared/reefer';

import { SideNav, SideNavHeader } from '../SideNav';

export const AnalyticsSideNav = () => {
  const { pathname } = useLocation();
  const [selectedPath, setSelectedPath] = useState<string>(pathname);
  const match = useMatch(pathname);
  const analyticsContext = useContext(AnalyticsPageContext);
  const { navLiveboards = {}, isFetchingLiveboards = true } = analyticsContext;

  const trackNavigation = (path: string) => {
    track({
      event: EventNames.Navigated,
      from_url: pathname,
      to_url: path,
      trigger_source_id: NavigationSourceIds.AnalyticsSideNav,
    });
  };

  if (isFetchingLiveboards) return null;

  return (
    <SideNav aria-label="Analytics Navigation">
      {Object.entries(navLiveboards).map(([header, boards], index) => {
        if (!boards) return null;

        const topMargin = index === 0 ? 32 : 24;

        return (
          <Fragment key={`analytics-nav-section-${header.replace(/\s/, '-')}`}>
            {header === 'Competitive Overview' ? (
              <>
                <SideNavHeader
                  color="primary"
                  variant="caps-bold"
                  mt={topMargin}
                >
                  Premium Reports
                </SideNavHeader>
                <SideNavHeader mt={4}>{header}</SideNavHeader>
              </>
            ) : (
              <SideNavHeader mt={topMargin}>{header}</SideNavHeader>
            )}
            <Tabs
              direction="vertical"
              onChange={setSelectedPath}
              value={match?.pathname || selectedPath}
            >
              {boards.map((board) => {
                const path = businessPaths.analyticsRoutes(board.name ?? '');
                return (
                  <Tabs.Tab
                    value={board?.id}
                    key={board?.id || `board-${board.name.replace(/\s/, '-')}`}
                    label={board.name}
                    to={path}
                    onClick={() => trackNavigation(path)}
                  />
                );
              })}
            </Tabs>
          </Fragment>
        );
      })}
    </SideNav>
  );
};

import startCase from 'lodash/startCase';

import type { CartLimitRuleV2 } from '@jane/business-admin/types';

export function getPolicyRuleDetails(rules?: CartLimitRuleV2[]) {
  if (!rules || !rules.length) return;

  return rules
    .map((rule) => {
      const name = startCase(rule.product_group_name);
      return `${rule.limit_value}${rule.limit_unit} (${name})`;
    })
    .join(', ');
}

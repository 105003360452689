import { useEffect } from 'react';

import { Card, Flex, JaneLogo, Typography } from '@jane/shared/reefer';

export const StaticContent = ({
  header,
  content,
}: {
  content: string;
  header?: string;
}) => {
  useEffect(() => {
    //TODO: This is some janky shit. Figure out a better way to handle this.
    document.body.style.background =
      'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #5E1FF0';
    return () => {
      //TODO: This is some janky shit. Figure out a better way to handle this.
      document.body.style.background = '#FFF';
    };
  }, []);

  return (
    <Flex justifyContent="center">
      <Card mt={128} width={756}>
        <Card.Content background="grays-white">
          <Flex m={96} flexDirection="column" justifyContent="center">
            <Flex role="row" justifyContent="center">
              <JaneLogo size="lg" />
            </Flex>
            <Flex role="row" justifyContent="center" mt={-24} mb={40}>
              <Typography variant="caps-bold">BUSINESS</Typography>
            </Flex>
            {Boolean(header) && (
              <Typography variant="header" textAlign="center">
                {header}
              </Typography>
            )}
            <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
          </Flex>
        </Card.Content>
      </Card>
    </Flex>
  );
};

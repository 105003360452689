import styled from '@emotion/styled';
import range from 'lodash/range';
import startCase from 'lodash/startCase';
import type { ReactNode } from 'react';

import type { StatusMessageV2 } from '@jane/shared/models';
import { Skeleton, Typography } from '@jane/shared/reefer';

import { TableCell } from '../../../../TableCell';
import {
  CollapsedBorderTable,
  TableRowWithBorder,
} from '../../../../TableWithBorderSeparator';
import { StatusMessagePreview } from './StatusMessagePreview';

const SkeletonCell = () => (
  <TableCell>
    <Skeleton animate height="fit-content" width="100%">
      <Skeleton.Bone width="100%" height="60px" mr={16} />
    </Skeleton>
  </TableCell>
);

const SkeletonRows = ({ rows }: { rows: number }) => (
  <>
    {range(1, rows).map((row) => (
      <TableRow key={`skeleton-row-${row}`}>
        <SkeletonCell />
        <SkeletonCell />
        <SkeletonCell />
        <TableCell />
      </TableRow>
    ))}
  </>
);

const TableRow = styled(TableRowWithBorder)<{ enabled?: boolean }>(
  ({ enabled }) => ({
    opacity: enabled ? 1 : 0.3,
  })
);

const MessageRow = ({
  type,
  enabled,
  children,
}: {
  children: ReactNode;
  enabled: boolean;
  type: string;
}) => {
  return (
    <TableRow enabled={enabled} data-testid="message-row">
      <TableCell pl={0}>
        <Typography>{startCase(type)}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{enabled ? 'Enabled' : 'Disabled'}</Typography>
      </TableCell>
      <TableCell>{children}</TableCell>
      <TableCell />
    </TableRow>
  );
};

type StatusMessageRow = Pick<
  StatusMessageV2,
  'id' | 'cart_status' | 'enabled' | 'message_text'
>;

interface Props {
  emptyText?: string;
  isFetched?: boolean;
  isLoading?: boolean;
  statusMessages?: StatusMessageRow[] | null;
}

export const StatusMessageTable = ({
  emptyText = 'Notifications have not been set up for this store.',
  statusMessages,
  isFetched,
  isLoading,
}: Props) => {
  if (isFetched && (!statusMessages || !statusMessages.length)) {
    return <Typography variant="body">{emptyText}</Typography>;
  }

  return (
    <CollapsedBorderTable>
      <thead>
        <TableRowWithBorder>
          <TableCell pl={0} py={4} width="20%">
            <Typography variant="caps">Type</Typography>
          </TableCell>
          <TableCell py={4} width="20%">
            <Typography variant="caps">Status</Typography>
          </TableCell>
          <TableCell py={4}>
            <Typography variant="caps">Preview</Typography>
          </TableCell>
          <TableCell />
        </TableRowWithBorder>
      </thead>

      <tbody>
        {isLoading ? (
          <SkeletonRows rows={5} />
        ) : (
          statusMessages?.map((message) => (
            <MessageRow
              key={message.id}
              type={message.cart_status}
              enabled={message.enabled}
            >
              <StatusMessagePreview
                status={message.cart_status}
                message={message.message_text}
              />
            </MessageRow>
          ))
        )}
      </tbody>
    </CollapsedBorderTable>
  );
};

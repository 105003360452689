import styled from '@emotion/styled';
import take from 'lodash/take';
import pluralise from 'pluralise';

import type { DeliveryZipcode } from '@jane/shared/models';
import { spacing } from '@jane/shared/reefer-emotion';
import { formatCurrency } from '@jane/shared/util';

import { TableWithBorderSeparator } from '../../../../../../TableWithBorderSeparator';
import { SeeAllButton } from './SeeAllButton';

const TableWrapper = styled.div({
  ...spacing({ mt: 12 }),
  width: '100%',
});

export const ZipcodesTable = ({
  zipcodeData,
  onClickSeeAll,
}: {
  onClickSeeAll: () => void;
  zipcodeData: DeliveryZipcode[];
}) => {
  const tableData = take(zipcodeData, 3).map((config: DeliveryZipcode) => {
    return {
      ...config,
      fee: `${formatCurrency(config.fee)}`,
      min_amount: `${formatCurrency(config.min_amount)}`,
      lead_time_minutes: `${config.lead_time_minutes} ${pluralise(
        config.lead_time_minutes,
        'minute'
      )}`,
      last_call_minutes: `${config.last_call_minutes} ${pluralise(
        config.last_call_minutes,
        'minute'
      )}`,
    };
  });

  return (
    <TableWrapper>
      <TableWithBorderSeparator
        name={'Zipcode settings table'}
        headers={[
          {
            label: 'zipcode',
            column_value: 'zipcode',
          },
          { label: 'fee', column_value: 'fee' },
          { label: 'minimum', column_value: 'min_amount' },
          { label: 'lead time', column_value: 'lead_time_minutes' },
          { label: 'last call', column_value: 'last_call_minutes' },
        ]}
        data={tableData}
      />
      {zipcodeData.length > 3 && <SeeAllButton onClick={onClickSeeAll} />}
    </TableWrapper>
  );
};

import styled from '@emotion/styled';
import { useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  EventNames,
  businessPaths,
  normalizePath,
} from '@jane/business-admin/util';
import { Button, Flex, Modal, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import { trackWithCallbackAsync } from '@jane/shared/util';

import HeaderSvg from '../../assets/RequestFeedbackModalHeaderImage';
import { FeedbackModal } from './FeedbackModal';

const ModifiedModalContent = styled.div(() => {
  return {
    ...spacing({ pb: 40, pt: 12, px: 40 }),
  };
});

export const RequestFeedbackModal = ({
  requestFeedbackModalOpen,
  setRequestFeedbackModalOpen,
}: {
  requestFeedbackModalOpen: boolean;
  setRequestFeedbackModalOpen: (requestFeedbackModalOpen: boolean) => void;
}) => {
  const { pathname } = useLocation();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const redirectUrl = businessPaths.legacyRoot();

  const openFeedbackModal = () => {
    setRequestFeedbackModalOpen(false);
    setFeedbackModalOpen(true);
  };

  const { storeId } = useContext(StoreDetailsContext);
  const trackAndRedirect = useCallback(async () => {
    await trackWithCallbackAsync(
      EventNames.NavigatedToLegacy,
      {
        from_url: normalizePath(pathname, storeId),
        to_url: normalizePath(redirectUrl, storeId),
      },
      {
        send_immediately: true,
      }
    );
    window.location.assign(redirectUrl);
  }, [pathname, redirectUrl, storeId]);

  return (
    <>
      <Modal
        variant="dialogue"
        open={requestFeedbackModalOpen}
        onRequestClose={() => setRequestFeedbackModalOpen(false)}
        role="dialog"
        appId="root"
      >
        <HeaderSvg />
        <ModifiedModalContent>
          <Flex flexDirection="column" alignItems="center">
            <Typography variant="title-bold">Where can we improve?</Typography>
            <Typography>
              We&apos;d love your feedback on the new Business Dashboard beta!
            </Typography>

            <Flex mt={24} width="100%">
              <Button
                mr={16}
                full
                variant="tertiary"
                label="No thanks"
                onClick={trackAndRedirect}
                data-testid="no-thanks-redirect"
              />
              <Button full label="Sure" onClick={() => openFeedbackModal()} />
            </Flex>
          </Flex>
        </ModifiedModalContent>
      </Modal>
      <FeedbackModal
        shouldRedirect={true}
        feedbackModalOpen={feedbackModalOpen}
        setFeedbackModalOpen={setFeedbackModalOpen}
      />
    </>
  );
};

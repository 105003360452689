import { useEffect, useMemo } from 'react';

import { useProductTypes } from '@jane/business-admin/data-access';
import { useProductTypeIdMap } from '@jane/business-admin/hooks';
import type { CartLimitRuleV2 } from '@jane/business-admin/types';
import type { ProductKind, RuleProduct } from '@jane/shared/models';
import {
  Button,
  Flex,
  TrashIcon,
  Typography,
  useFieldArray,
  useFormContext,
} from '@jane/shared/reefer';

import { CategoryAndSubcategorySelect } from '../../../../../../../CategoryAndSubcategorySelect';

const DEFAULT_PRODUCT_TYPE = { product_type: 'vape' } as RuleProduct;
const SUBCATEGORY_DEFAULT_OPTION = { label: 'All', value: 'all' };

interface CategoryListProps {
  ruleProductTypes?: RuleProduct[];
}

export const CategoryList = ({ ruleProductTypes }: CategoryListProps) => {
  const { data: allProductTypes } = useProductTypes();
  const { control, setValue, setError, clearErrors, watch } =
    useFormContext<CartLimitRuleV2>();
  const {
    fields: productTypes,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'product_types',
    keyName: '_key',
  });
  const ruleType = watch('rule_type');

  const { getProductTypeId, size: idMapSize } =
    useProductTypeIdMap(allProductTypes);

  const defaultProductType = useMemo(() => {
    if (!idMapSize) return DEFAULT_PRODUCT_TYPE;

    return {
      id: getProductTypeId(DEFAULT_PRODUCT_TYPE.product_type, null),
      product_type: DEFAULT_PRODUCT_TYPE.product_type,
      product_subtype: 'all',
    } as RuleProduct;
  }, [idMapSize]);

  const duplicateTypes = useMemo(() => {
    clearErrors();
    const usedTypes: { [typeName: string]: number } = {};
    const duplicates = productTypes.reduce<Set<number>>(
      (prev, current, index) => {
        const currentSelection =
          `${current.product_type}-${current.product_subtype}` as any;
        if (usedTypes[currentSelection] === undefined) {
          usedTypes[currentSelection] = index;
        } else {
          setError('form-error' as any, { message: 'duplicates' });
          prev.add(index);
          prev.add(usedTypes[currentSelection]);
        }
        return prev;
      },
      new Set()
    );
    return Array.from(duplicates);
  }, [productTypes, clearErrors, setError]);

  useEffect(() => {
    if (productTypes.length || !idMapSize) return;

    if (ruleProductTypes && ruleProductTypes.length) {
      setValue(
        'product_types',
        ruleProductTypes.map((type) => ({
          ...type,
          // Make sure default is set to 'all' before selects are loaded
          product_subtype: type.product_subtype || 'all',
        }))
      );
    } else {
      setValue('product_types', [defaultProductType]);
    }
  }, [productTypes, ruleProductTypes, defaultProductType, idMapSize]);

  const categoryChangeHandler =
    (type: RuleProduct, index: number) => (value: ProductKind) => {
      update(index, {
        ...type,
        id: getProductTypeId(value, null),
        product_type: value as ProductKind,
        product_subtype: 'all',
      });
    };

  const subcategoryChangeHandler =
    (type: RuleProduct, index: number) => (value: string) => {
      const nullableValue = value === 'all' ? null : value;

      if (nullableValue === type.product_subtype) return;

      update(index, {
        ...type,
        id: getProductTypeId(type.product_type, value),
        product_type: type.product_type as ProductKind,
        product_subtype: nullableValue,
      });
    };

  const deleteOrMarkDestroyed = (index: number, productType: RuleProduct) => {
    if (productType.cart_limit_rule_product_type_id) {
      return update(index, {
        ...productType,
        _destroy: true,
      });
    }
    remove(index);
  };

  const filteredProductTypes = useMemo(
    () => productTypes.filter((type) => !type._destroy),
    [productTypes]
  );

  if (ruleType !== 'product') return null;

  return (
    <>
      {filteredProductTypes.map((type, index) => (
        <Flex flexDirection="column" width="100%" mb={32}>
          <Flex
            justifyContent="space-between"
            alignItems="end"
            gap={24}
            key={type._key}
          >
            <CategoryAndSubcategorySelect
              categoryProps={{
                defaultValue: type.product_type,
                onChange: categoryChangeHandler(type, index),
                name: `product_types.${index}.product_type`,
                label: 'Set category',
              }}
              subcategoryProps={{
                defaultValue:
                  type.product_subtype ?? SUBCATEGORY_DEFAULT_OPTION.value,
                onChange: subcategoryChangeHandler(type, index),
                name: `product_types.${index}.product_subtype`,
                label: 'Set subcategory',
              }}
            />

            {productTypes.length > 1 && (
              <Button.Icon
                icon={<TrashIcon color="grays-mid" />}
                label="Remove"
                data-testid="product-type-remove"
                onClick={() => deleteOrMarkDestroyed(index, type)}
              />
            )}
          </Flex>
          {duplicateTypes.includes(index) && (
            <Typography color="error" mt={12}>
              Please remove any duplicates before saving the rule.
            </Typography>
          )}
        </Flex>
      ))}

      <Flex justifyContent="center">
        <Button
          variant="secondary"
          label="Add another category"
          onClick={() => append(defaultProductType)}
        />
      </Flex>
    </>
  );
};

import { createConsumer } from '@rails/actioncable';

import { config } from '@jane/shared/config';

import { parseToken } from './jwt';

const buildUrl = (jwt: string) =>
  [config.actionCablePath, `?token=${parseToken(jwt)}`].join('');

export const initCable = (jwt: string) => createConsumer(buildUrl(jwt));

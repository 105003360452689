import { useEffect, useState } from 'react';

import { useCreateSquareAuthorization } from '@jane/business-admin/data-access';
import { Button, Flex, Modal, Typography, useToast } from '@jane/shared/reefer';
import { trackError } from '@jane/shared/util';

interface Props {
  onClose: () => void;
  storeId: string;
}

export const SquareAuthorizationModal = ({ onClose, storeId }: Props) => {
  const toast = useToast();

  const [squareAuthorizationUrl, setSquareAuthorizationUrl] = useState<
    string | undefined
  >();

  const { mutateAsync: createSquareAuthorization, isError } =
    useCreateSquareAuthorization(storeId);

  useEffect(() => {
    if (squareAuthorizationUrl === undefined) {
      (async () => {
        try {
          const squareAuthorizationResult = await createSquareAuthorization();
          if (squareAuthorizationResult) {
            setSquareAuthorizationUrl(squareAuthorizationResult.url);
          }
        } catch (err: any) {
          trackError('Error authorizing Square');
        }
      })();
    }
  }, [createSquareAuthorization, squareAuthorizationUrl]);

  useEffect(() => {
    if (isError) {
      toast.add({
        label: 'There was an error authorizing Square. Please try again.',
        variant: 'error',
      });
      onClose();
    }
  }, [isError, onClose, toast]);

  return (
    <Modal
      open
      onRequestClose={onClose}
      variant="dialogue"
      appId="payment-settings-modal"
    >
      <Modal.Content>
        <Flex width="100%" flexDirection="column" alignItems="center">
          <Typography variant="title-bold">Authorization needed</Typography>
          <Typography>
            You need to sign into Square to enable payments.
          </Typography>

          <Flex mt={24} width="100%">
            <Button
              mr={16}
              full
              variant="tertiary"
              label="Cancel"
              onClick={onClose}
            />
            <Button
              full
              variant="primary"
              label="Continue"
              href={squareAuthorizationUrl}
              target={undefined}
              data-testid="authorize-square-link"
            />
          </Flex>
        </Flex>
      </Modal.Content>
    </Modal>
  );
};

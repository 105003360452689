import * as t from 'io-ts';

import {
  EQUIVALENCY_TYPE,
  PRODUCT_TYPE,
  tRuleProduct,
} from '@jane/shared/models';

import { tId } from './common';

const tCartLimitPolicyStoreOptional = t.partial({
  address: t.string,
});

const tCartLimitPolicyStoreRequired = t.interface({
  id: tId,
  name: t.string,
  city: t.union([t.string, t.null]),
  state: t.union([t.string, t.null]),
  recreational: t.boolean,
});

const tCartLimitPolicyStore = t.intersection([
  tCartLimitPolicyStoreOptional,
  tCartLimitPolicyStoreRequired,
]);

const tCartLimitRuleRequiredV2 = t.interface({
  limit_unit: t.string,
  limit_value: t.string,
  product_group_name: t.string,
  product_types: t.array(tRuleProduct),
  rule_type: t.union([t.literal(PRODUCT_TYPE), t.literal(EQUIVALENCY_TYPE)]),
});

const tCartLimitRuleOptionalV2 = t.partial({
  id: tId,
  _destroy: t.union([t.null, t.boolean]),
});

export const tCartLimitRuleV2 = t.intersection([
  tCartLimitRuleRequiredV2,
  tCartLimitRuleOptionalV2,
]);

export const tCartLimitPolicyV2 = t.interface({
  id: tId,
  name: t.string,
  stores: t.array(tCartLimitPolicyStore),
  cart_limit_rules: t.array(tCartLimitRuleV2),
});

export const tAbbreviatedCartLimitPolicy = t.interface({
  id: tId,
  name: t.union([t.string, t.null]),
});

export type CartLimitRuleV2 = t.TypeOf<typeof tCartLimitRuleV2>;
export type CartLimitPolicyV2 = t.TypeOf<typeof tCartLimitPolicyV2>;
export type CartLimitPolicyStoreV2 = t.TypeOf<typeof tCartLimitPolicyStore>;

import capitalize from 'lodash/capitalize';

import { useDebouncedTrack } from '@jane/business-admin/hooks';
import type { MenuRow } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  SettingNames,
  track,
} from '@jane/business-admin/util';
import { JANE_DEFINED_ROWS } from '@jane/search/util';
import {
  Flex,
  Form,
  Link,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

import { CategoryAndSubcategorySelect } from '../../../../CategoryAndSubcategorySelect';
import { DynamicRowCard } from './DynamicRowCard';
import { OptionCardWithPill } from './OptionCardWithPill';

export const DetailsCard = ({
  defaultRowType,
  isCreateMode,
  row,
  rowProductType,
}: {
  defaultRowType?: string;
  isCreateMode: boolean;
  row?: MenuRow;
  rowProductType: any;
}) => {
  const {
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext();
  const rowType = watch('display.type');
  const customDisplayName = watch('custom_display_name');
  const trackTextChange = useDebouncedTrack(1000);
  const modalName = isCreateMode
    ? ModalNames.CreateMenuRow
    : ModalNames.EditMenuRow;

  const onClickOptionCardWithPill = (value: string) => {
    // Basically a hidden input, since there are no actual input elements in this component
    setValue('display.type', value, { shouldDirty: true });
  };

  return (
    <>
      <Typography variant="header-bold" mb={32}>
        Details
      </Typography>

      <Typography mb={16}>{isCreateMode ? 'Select r' : 'R'}ow type</Typography>

      {defaultRowType && JANE_DEFINED_ROWS.includes(defaultRowType) ? (
        <DynamicRowCard row_type={defaultRowType} />
      ) : (
        <Flex gap={20} mb={24}>
          <OptionCardWithPill
            pillColor="ultralight"
            title="Custom"
            subtitle="Individually choose products."
            disabled={isCreateMode ? false : rowType !== 'custom'}
            selected={rowType === 'custom'}
            onClick={onClickOptionCardWithPill}
            withBackground={false}
          />
          <OptionCardWithPill
            pillColor="ultralight"
            title="Subcategory"
            subtitle="Create menu rows based on product subcategory."
            disabled={isCreateMode ? false : rowType !== 'subcategory'}
            selected={rowType === 'subcategory'}
            onClick={onClickOptionCardWithPill}
            withBackground={false}
          />
        </Flex>
      )}

      {rowType && rowProductType.display && (
        <>
          {rowType === 'subcategory' && (
            <CategoryAndSubcategorySelect
              mb={24}
              allowAllOption={false}
              isLoading={!rowProductType.display}
              categoryProps={{
                name: 'display.category',
                defaultValue: rowProductType.display?.category || 'flower',
                onChange: () => {
                  track({
                    event: EventNames.ModifiedSetting,
                    modal_name: modalName,
                    revert: false,
                    setting_name: SettingNames.ProductCategory,
                  });
                },
              }}
              subcategoryProps={{
                name: 'display.subcategory',
                defaultValue:
                  rowProductType.display?.subcategory || 'Bottom Shelf',
                onChange: () => {
                  track({
                    event: EventNames.ModifiedSetting,
                    modal_name: modalName,
                    revert: false,
                    setting_name: SettingNames.ProductSubcategory,
                  });
                },
              }}
            />
          )}
          <Form.TextField
            label="Name"
            name="custom_display_name"
            required
            onChange={(value) => {
              const isRevert =
                value === row?.custom_display_name ||
                value === defaultRowType ||
                value === capitalize(defaultRowType);
              const valueNotChanged = !value || isRevert;

              if (!isDirty && valueNotChanged) return;

              trackTextChange({
                event: EventNames.ModifiedSetting,
                modal_name: modalName,
                revert: isRevert,
                setting_name: SettingNames.MenuRowDisplayName,
              });
            }}
          />
          {rowType === 'dynamic' &&
            customDisplayName &&
            customDisplayName !== defaultRowType && (
              <Flex flexDirection="column" mt={24}>
                <Typography variant="body">
                  Default:{' '}
                  {defaultRowType === 'specials'
                    ? capitalize(defaultRowType)
                    : defaultRowType}
                </Typography>
                <Flex mt={4} mb={8}>
                  <Link
                    onClick={() =>
                      setValue(
                        'custom_display_name',
                        defaultRowType === 'specials'
                          ? capitalize(defaultRowType)
                          : defaultRowType
                      )
                    }
                  >
                    Revert to default
                  </Link>
                </Flex>
              </Flex>
            )}
        </>
      )}
    </>
  );
};

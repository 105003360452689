import styled from '@emotion/styled';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { businessPaths } from '@jane/business-admin/util';
import { ConfirmChangeModal } from '@jane/shared/components';
import {
  Button,
  DismissIcon,
  Flex,
  Modal,
  Typography,
} from '@jane/shared/reefer';

const BorderedFlex = styled(Flex)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));

type ActionButtonProps = {
  disable?: boolean;
  loading?: boolean;
  onClick: () => void;
};

export type ModalPageWrapperProps = {
  children: React.ReactNode;
  hasUnsavedChanges?: boolean;
  preview?: ActionButtonProps;
  publish: ActionButtonProps;
  title: string;
};

const useForceFallback = () => {
  const navigate = useNavigate();
  return useCallback(() => {
    navigate(businessPaths.plus(), { replace: true });
  }, [navigate]);
};

export const ModalPageWrapper = ({
  hasUnsavedChanges,
  children,
  preview,
  publish,
  title,
}: ModalPageWrapperProps) => {
  const goBack = useForceFallback();
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const onRequestClose = useCallback(() => {
    if (hasUnsavedChanges) {
      setShowDiscardModal(true);
    } else {
      goBack();
    }
  }, [hasUnsavedChanges, goBack]);
  return (
    <>
      <Modal
        appId="parent"
        open
        variant="full-screen"
        onRequestClose={onRequestClose}
        background="grays-white"
        overflow="auto"
      >
        <BorderedFlex
          px={32}
          py={24}
          gap={16}
          flexDirection="row"
          alignItems="center"
        >
          <DismissIcon onClick={() => onRequestClose()} altText="Go back" />
          <Typography variant="header-bold" branded>
            {title}
          </Typography>

          <Flex ml="auto" flexDirection="row">
            {preview ? (
              <Button
                ml="auto"
                mr={16}
                label="Preview"
                type="button"
                variant="secondary"
                onClick={preview.onClick}
                disabled={preview.disable}
                loading={preview.loading}
              />
            ) : null}
            <Button
              ml="auto"
              label="Publish"
              type="button"
              variant="primary"
              onClick={publish?.onClick}
              disabled={publish.disable}
              loading={publish.loading}
            />
          </Flex>
        </BorderedFlex>
        {children}
      </Modal>
      <ConfirmChangeModal
        open={showDiscardModal}
        title={
          <Typography variant="header-bold" mb={8}>
            Discard changes?
          </Typography>
        }
        onConfirm={() => goBack()}
        onCancel={() => setShowDiscardModal(false)}
        confirmButtonVariant="destructive"
        confirmLabel="Discard"
        subtitle="You have made changes without publishing. If you continue, you will lose your progress."
      />
    </>
  );
};

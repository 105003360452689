import { useQuery } from '@tanstack/react-query';

import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';
import type { StoreCategory } from '@jane/shared/models';

/**
 * Legacy v1 categories API, used to get list of categories for store specials
 */

const fetchStoreCategories = (storeId: string): Promise<StoreCategory[]> =>
  api.get(`${config.apiPath}/stores/${storeId}/updated_categories`);
export const useFetchStoreCategories = (storeId: string, enabled: boolean) =>
  useQuery({
    queryKey: ['store_categories_legacy', storeId],
    queryFn: () => fetchStoreCategories(storeId),
    enabled,
  });

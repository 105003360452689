import get from 'lodash/get';
import { useContext, useEffect, useMemo } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialRulesV2 } from '@jane/business-admin/types';
import { BODY_LINE_HEIGHT, MODAL_CARD_WIDTH } from '@jane/business-admin/util';
import type { ProductThresholdConditions } from '@jane/shared/models';
import {
  Card,
  Flex,
  Form,
  Modal,
  Skeleton,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';
import { RULE_OPTIONS } from '@jane/shared/util';

import {
  ConditionType,
  FORM_FIELD_DEFAULT_MARGIN,
  getApplyToSelection,
} from '../form';
import { PriceConditions } from './PriceConditions';
import { ProductsConditions } from './ProductsConditions';
import { WeightAndPriceConditions } from './WeightAndPriceConditions';
import { BrandConditions } from './brandConditions/BrandConditions';
import { CategoryConditions } from './categoryConditions/CategoryConditions';
import { convertRulesToString } from './convertRulesToString';
import { ConditionByCheckboxes } from './generic_components/ConditionByCheckboxes';
import { LoadingCondition } from './generic_components/LoadingCondition';
import { useUpdateIncludedRule } from './hooks/useUpdateIncludedRule';

interface Props {
  fieldPrefix: string;
  headerText?: string;
  isGlobalSpecial?: boolean;
  isLoading: boolean;
  subHeaderText?: string;
}
export const ConditionsCard = ({
  fieldPrefix,
  headerText,
  isGlobalSpecial,
  isLoading,
  subHeaderText,
}: Props) => {
  const { setValue, watch, getValues } = useFormContext();
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);
  const applyToWatch = watch(`${fieldPrefix}.apply_to`);
  const specialTypeWatch = watch('special_type');
  const rulesWatch = watch(`${fieldPrefix}.rules`);

  const specialTypeFormatted = useMemo(() => {
    if (!specialTypeWatch || specialTypeWatch === 'select') return null;
    return specialTypeWatch.split('_').join(' ');
  }, [specialTypeWatch]);

  const prices = useMemo(() => {
    return get(rulesWatch, 'product_threshold', {});
  }, [convertRulesToString(rulesWatch)]);

  const onPricesUpdate = (prices: ProductThresholdConditions) => {
    setValue(`${fieldPrefix}.rules.product_threshold`, prices, {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    const newApplyTo = getApplyToSelection(rulesWatch);
    setValue(`${fieldPrefix}.apply_to`, newApplyTo);
  }, [specialTypeWatch]);

  const applyToOptions = [
    {
      id: RULE_OPTIONS[2].value,
      label: 'All Applicable Products',
      value: RULE_OPTIONS[0].value,
    },
    {
      id: RULE_OPTIONS[0].value,
      label: 'Selected Products',
      value: RULE_OPTIONS[1].value,
    },
    {
      id: RULE_OPTIONS[1].value,
      label: 'Advanced',
      value: RULE_OPTIONS[2].value,
    },
  ];

  const onCategoriesUpdate = (
    kinds: any,
    includesOrExcludes: 'includes' | 'excludes'
  ) => {
    const rules =
      rulesWatch && rulesWatch[includesOrExcludes]
        ? rulesWatch[includesOrExcludes]
        : null;

    if (['cart_total', 'product'].includes(specialTypeWatch)) {
      const firstCondition = rules?.[0] ?? {};

      const newRules = {
        ...rulesWatch,
        [includesOrExcludes]: [
          {
            ...firstCondition,
            kinds: kinds,
          },
        ],
      };

      if (specialTypeWatch === 'cart_total') {
        const cartTotalThresholdValue = getValues(
          'conditions.cart_total.threshold'
        );

        newRules['settings'] = {
          cart_threshold: cartTotalThresholdValue,
        };
      }
      setValue(`${fieldPrefix}.rules`, newRules, {
        shouldDirty: true,
      });
    } else {
      const firstCondition = rules?.[0] ?? {};
      setValue(
        `${fieldPrefix}.rules`,
        {
          ...rulesWatch,
          [includesOrExcludes]: [{ ...firstCondition, kinds }],
        },
        { shouldDirty: true }
      );
    }
  };

  const { data: weights, onUpdate: onWeightsUpdate } = useUpdateIncludedRule(
    'weights',
    rulesWatch,
    (newRules: SpecialRulesV2) =>
      setValue(`${fieldPrefix}.rules`, newRules, { shouldDirty: true })
  );

  const { data: lineage, onUpdate: onLineageUpdate } = useUpdateIncludedRule(
    'lineages',
    rulesWatch,
    (newRules: SpecialRulesV2) =>
      setValue(`${fieldPrefix}.rules`, newRules, { shouldDirty: true })
  );

  const { data: weightsAndPrices, onUpdate: onWeightsAndPricesUpdate } =
    useUpdateIncludedRule(
      'weights_and_prices',
      rulesWatch,
      (newRules: SpecialRulesV2) =>
        setValue(`${fieldPrefix}.rules`, newRules, { shouldDirty: true })
    );

  const isAnyPosSynced = isJanePosSynced || posSynced;
  const isDisabled = isAnyPosSynced || isReadOnly;

  if (
    specialTypeWatch !== 'product' &&
    specialTypeWatch !== 'cart_total' &&
    specialTypeWatch !== 'bundle' &&
    specialTypeWatch !== 'bulk_pricing'
  ) {
    return null;
  }

  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <Typography
            variant="header-bold"
            mb={subHeaderText ? 0 : FORM_FIELD_DEFAULT_MARGIN}
          >
            {headerText ? (
              headerText
            ) : (
              <>
                Conditions{' '}
                {!isLoading &&
                  specialTypeFormatted &&
                  `for ${specialTypeFormatted} special`}
              </>
            )}
          </Typography>
          {subHeaderText && (
            <Typography color={'grays-mid'} mb={FORM_FIELD_DEFAULT_MARGIN}>
              {subHeaderText}
            </Typography>
          )}
          {specialTypeWatch === 'bundle' &&
            fieldPrefix === 'display.required_rules' && (
              <Flex flexDirection="row" mb={FORM_FIELD_DEFAULT_MARGIN}>
                <Flex flexDirection="column" width="100%">
                  <Typography variant="body-bold" mb={24}>
                    Discount required products
                  </Typography>
                  <Form.CheckboxField
                    width={'100%'}
                    name="display.bundle.settings.allow_discounts_on_required_products"
                    label={'Also apply discount to required products'}
                  />
                </Flex>
                <Flex flexDirection="column" width="100%">
                  <Typography variant="body-bold" mb={24}>
                    Exclude required products from other specials
                  </Typography>
                  <Form.CheckboxField
                    width={'100%'}
                    name="display.bundle.settings.exclude_product_specials_from_bundle_qualifiers"
                    label={
                      'Prevent required products from being discounted by Product Specials'
                    }
                  />
                </Flex>
              </Flex>
            )}
          <Flex flexDirection="column">
            <Typography variant="body-bold" mb={FORM_FIELD_DEFAULT_MARGIN}>
              Apply to
            </Typography>
            {isLoading ? (
              <Skeleton direction="row" animate>
                <Skeleton.Bone height={BODY_LINE_HEIGHT} width="20%" />
                <Skeleton.Bone height={BODY_LINE_HEIGHT} ml={12} width="20%" />
                <Skeleton.Bone height={BODY_LINE_HEIGHT} ml={12} width="20%" />
              </Skeleton>
            ) : (
              <Form.RadioFieldGroup
                shouldUnregister={false}
                name={`${fieldPrefix}.apply_to`}
                row={true}
                options={applyToOptions}
                disabled={isDisabled}
              />
            )}
          </Flex>
          <Modal.ContentDivider />
          {isLoading ? (
            <>
              <LoadingCondition />
              <LoadingCondition />
              <LoadingCondition />
            </>
          ) : (
            <>
              {applyToWatch === RULE_OPTIONS[2].value && (
                <>
                  <CategoryConditions
                    fieldPrefix={fieldPrefix}
                    isGlobalSpecial={isGlobalSpecial}
                    type={ConditionType.Categories}
                    onConditionsUpdate={onCategoriesUpdate}
                  />
                  {specialTypeWatch !== 'bulk_pricing' &&
                    specialTypeWatch !== 'bundle' && (
                      <CategoryConditions
                        fieldPrefix={fieldPrefix}
                        isGlobalSpecial={isGlobalSpecial}
                        type={ConditionType.ExcludeCategories}
                        onConditionsUpdate={onCategoriesUpdate}
                      />
                    )}
                  <BrandConditions
                    fieldPrefix={fieldPrefix}
                    isGlobalSpecial={isGlobalSpecial}
                    type={ConditionType.Brands}
                  />
                  {specialTypeWatch !== 'bulk_pricing' &&
                    specialTypeWatch !== 'bundle' && (
                      <BrandConditions
                        fieldPrefix={fieldPrefix}
                        isGlobalSpecial={isGlobalSpecial}
                        type={ConditionType.ExcludeBrands}
                      />
                    )}
                  <ConditionByCheckboxes
                    fieldPrefix={fieldPrefix}
                    conditions={lineage}
                    onConditionsUpdate={onLineageUpdate}
                    type={ConditionType.Lineage}
                  />
                </>
              )}
              {applyToWatch === RULE_OPTIONS[1].value && (
                <ProductsConditions
                  fieldPrefix={fieldPrefix}
                  isGlobalSpecial={isGlobalSpecial}
                  type={ConditionType.Products}
                />
              )}
              {specialTypeWatch === 'bulk_pricing' ? (
                <WeightAndPriceConditions
                  fieldPrefix={fieldPrefix}
                  conditions={weightsAndPrices}
                  onConditionsUpdate={onWeightsAndPricesUpdate}
                  type={ConditionType.WeightAndPrice}
                />
              ) : (
                <>
                  <ConditionByCheckboxes
                    fieldPrefix={fieldPrefix}
                    conditions={weights}
                    onConditionsUpdate={onWeightsUpdate}
                    type={ConditionType.Weight}
                  />
                  <PriceConditions
                    fieldPrefix={fieldPrefix}
                    conditions={prices}
                    onConditionsUpdate={onPricesUpdate}
                    type={ConditionType.Price}
                    lastCondition={applyToWatch === RULE_OPTIONS[1].value}
                  />
                </>
              )}
              <ProductsConditions
                fieldPrefix={fieldPrefix}
                isGlobalSpecial={isGlobalSpecial}
                type={ConditionType.ExcludeProducts}
              />
            </>
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
};

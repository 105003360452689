import pluralise from 'pluralise';
import { useMemo } from 'react';

import type { CartLimitPolicyV2 } from '@jane/business-admin/types';
import { Button, Card, Flex, Typography } from '@jane/shared/reefer';

import { StoreDetails } from './StoreDetails';

export const StoreSection = ({
  policy,
  openSaveToModal,
}: {
  openSaveToModal: () => void;
  policy: CartLimitPolicyV2 | undefined;
}) => {
  const storeCountLabel = useMemo(
    () => policy?.stores.length || 0,
    [policy?.stores]
  );

  return (
    <Card border="grays-light" width={896}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <Flex justifyContent="space-between" alignItems="center" mb={24}>
            <Typography variant="header-bold">Stores</Typography>
            <Button
              variant="secondary"
              label="Edit"
              onClick={openSaveToModal}
              data-testid="stores-edit"
            />
          </Flex>

          <Typography mb={24}>
            Applies to {storeCountLabel} {pluralise(storeCountLabel, 'store')}
          </Typography>

          <Flex flexWrap="wrap">
            {policy?.stores.map((store) => (
              <StoreDetails key={store.id} store={store} mb={16} width="50%" />
            ))}
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
};

import { Suspense, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { StoreDetailsHeader } from '@jane/business-admin/components';
import {
  useManager,
  useStoreImages,
  useStoreSettings,
} from '@jane/business-admin/data-access';
import { useHasPermissions } from '@jane/business-admin/hooks';
import { StoreDetailsProvider } from '@jane/business-admin/providers';
import { Permission } from '@jane/shared/auth';

export const StoreDetail = () => {
  const { id = '' } = useParams<'id'>();
  const canEditStore = useHasPermissions([Permission.EditStore]);
  const { data: storeImagesPayload, isFetching } = useStoreImages(id);
  const { data: storePayload } = useStoreSettings(id);

  const { data, isLoading: managerLoading } = useManager();
  const { manager } = data || {};

  useEffect(() => {
    if (id) {
      janeMixpanel.register({
        store_id: id.toString(),
      });
    }
  }, [id, managerLoading, manager]);

  return (
    <Suspense fallback={<StoreDetailsHeader isLoading={true} />}>
      <StoreDetailsProvider
        storeId={id}
        canEditStore={canEditStore}
        storeName={storePayload?.store?.name}
      >
        <StoreDetailsHeader
          storeImagesPayload={storeImagesPayload}
          isLoading={isFetching}
        />
        <Outlet />
      </StoreDetailsProvider>
    </Suspense>
  );
};

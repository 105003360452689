import {
  GoogleAnalyticsCard,
  LoyaltyCard,
} from '@jane/business-admin/components';
import { Flex } from '@jane/shared/reefer';

export const StoreSettingsIntegrations = () => {
  return (
    <Flex flexDirection="column">
      <LoyaltyCard />
      <GoogleAnalyticsCard />
    </Flex>
  );
};

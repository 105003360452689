import * as t from 'io-ts';

import { tId } from './common';

const tStore = t.interface({
  id: tId,
  city: t.union([t.string, t.null]),
  name: t.string,
  recreational: t.boolean,
  state: t.union([t.string, t.null]),
  address: t.union([t.string, t.null]),
  zip: t.union([t.string, t.null]),
});

export const tStoreJanePrinterV2 = t.interface({
  id: t.string,
  store_id: t.number,
  store_name: t.string,
  jane_printer_id: t.string,
  store: tStore,
});

export type StoreJanePrinter = t.TypeOf<typeof tStoreJanePrinterV2>;

export const tJanePrinterV2 = t.intersection([
  t.interface({
    id: t.string,
    name: t.union([t.string, t.null]),
    enabled: t.boolean,
    mac_address: t.union([t.string, t.null]),
    delivery_order_checked_out: t.boolean,
    pickup_order_checked_out: t.boolean,
    curbside_order_checked_out: t.boolean,
    kiosk_order_checked_out: t.boolean,
    curbside_arrived: t.boolean,
    store_jane_printers: t.array(tStoreJanePrinterV2),
    username: t.string,
    number_of_queued_jobs: t.number,
  }),
  t.partial({
    store_ids: t.union([t.undefined, t.array(t.number)]),
  }),
]);

export type JanePrinterV2 = t.TypeOf<typeof tJanePrinterV2>;
export type JanePrinterStore = t.TypeOf<typeof tStore>;

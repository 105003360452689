import { AlertIcon, Flex, Typography } from '@jane/shared/reefer';

export const EmptyState = () => (
  <Flex
    width="auto"
    height="100%"
    mx={40}
    justifyContent="center"
    alignItems="center"
    border="grays-light"
    borderRadius="lg"
  >
    <Flex
      my={128}
      mx={40}
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
    >
      <AlertIcon size="xxl" mb={24} />
      <Flex flexDirection="column">
        <Typography mb={8} variant="header-bold" textAlign="center">
          No specials found
        </Typography>
        <Typography
          variant="body"
          mb={24}
          color="grays-dark"
          textAlign="center"
        >
          Hmm, we couldn't find and specials that matched you search. Please
          broaden your filters and try again.
        </Typography>
      </Flex>
    </Flex>
  </Flex>
);

import { useQuery } from '@tanstack/react-query';

import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';

interface StaticContent {
  text?: string;
}

const fetchTermsOfService = (): Promise<StaticContent> =>
  api.get(`${config.apiPath}/business_terms_of_service`);
export const useTermsOfService = () =>
  useQuery({
    queryKey: ['business_terms_of_service'],
    queryFn: fetchTermsOfService,
    staleTime: Infinity,
  });

const fetchPrivacyPolicy = (): Promise<StaticContent> =>
  api.get(`${config.apiPath}/business_privacy_policy`);
export const usePrivacyPolicy = () =>
  useQuery({
    queryKey: ['business_privacy_policy'],
    queryFn: fetchPrivacyPolicy,
    staleTime: Infinity,
  });

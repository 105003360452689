import styled from '@emotion/styled';

import { Flex, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

export const LineItemsGrid = styled.div({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '8px',
});

export const TipContainer = styled(Flex)(({ theme }) => ({
  backgroundColor: theme.colors.grays.ultralight,
  borderRadius: theme.borderRadius.lg,
  position: 'relative',
  width: '100%',
}));

export const Tip = styled.div(({ theme }) => ({
  backgroundColor: 'transparent',
  borderRadius: theme.borderRadius.lg,
  cursor: 'pointer',
  flex: 1,
  zIndex: 2,
  ...spacing({ py: 16 }),
}));

export const TransitionedTypography = styled(Typography)({
  transition: 'color 0.5s ease',
});

export const SlidingBackground = styled.div<{ index: number; length: number }>(
  ({ index, length, theme }) => ({
    backgroundColor: index === -1 ? 'transparent' : theme.colors.grays.black,
    borderRadius: theme.borderRadius.lg,
    height: '100%',
    left: index === -1 ? 0 : `${100 * (index / length)}%`,
    position: 'absolute',
    transition: 'left .5s ease',
    width: `${100 / length}%`,
    zIndex: 1,
  })
);

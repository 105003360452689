import styled from '@emotion/styled';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { StoreFiltersContext } from '@jane/business-admin/providers';
import type { AbbreviatedStoreV2 } from '@jane/business-admin/types';
import { Flex, SortDownIcon, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { StoreCard } from './StoreCard';

const TableWrapper = styled.div({
  ...spacing({ px: 64, py: 32 }),
});

const SortWrapper = styled.div({
  cursor: 'pointer',
  width: '50%',
  justifyContent: 'left',
});

const LoadingWrapper = styled.div({
  width: '100%',
  margin: 'auto',
});

const STORE_SKELETONS_COUNT = 10;
const INITIAL_VISIBLE_STORE_COUNT = 50;
const VISIBLE_STORE_INCREMENT = 50;

const formatStoreName = (name: string) =>
  name.replace(/[^a-zA-Z ]/g, '').toLowerCase();

const sortByNameAsc = (
  storeOne: AbbreviatedStoreV2,
  storeTwo: AbbreviatedStoreV2
) => {
  return formatStoreName(storeOne.name) > formatStoreName(storeTwo.name)
    ? 1
    : formatStoreName(storeTwo.name) > formatStoreName(storeOne.name)
    ? -1
    : 0;
};

const sortByNameDesc = (
  storeOne: AbbreviatedStoreV2,
  storeTwo: AbbreviatedStoreV2
) => {
  return formatStoreName(storeOne.name) < formatStoreName(storeTwo.name)
    ? 1
    : formatStoreName(storeTwo.name) < formatStoreName(storeOne.name)
    ? -1
    : 0;
};

export const StoresList = ({
  isLoading = false,
  isCountLoading = false,
}: {
  isCountLoading: boolean;
  isLoading: boolean;
}) => {
  const { filteredStores } = useContext(StoreFiltersContext);
  const [sortAlphaAsc, setSortAlphaAsc] = useState(false);
  const [visibleStoreCount, setVisibleStoreCount] = useState(
    INITIAL_VISIBLE_STORE_COUNT
  );
  const { ref, inView } = useInView();

  const sortedStores = useMemo(() => {
    return filteredStores.sort(
      (storeOne: AbbreviatedStoreV2, storeTwo: AbbreviatedStoreV2) => {
        return sortAlphaAsc
          ? sortByNameDesc(storeOne, storeTwo)
          : sortByNameAsc(storeOne, storeTwo);
      }
    );
  }, [filteredStores, sortAlphaAsc]);

  useEffect(() => {
    if (inView) {
      setVisibleStoreCount(visibleStoreCount + VISIBLE_STORE_INCREMENT);
    }
  }, [inView, visibleStoreCount]);

  return (
    <TableWrapper role="table" aria-label="Stores list">
      <Flex width="100%" py={24} role="row" alignItems={'center'}>
        <SortWrapper onClick={() => setSortAlphaAsc(!sortAlphaAsc)}>
          <Flex>
            <Typography role="columnheader" variant="caps">
              Store
            </Typography>
            <SortDownIcon
              color="primary"
              altText="Sort stores alphabetically"
              rotate={sortAlphaAsc ? 'down' : 'up'}
            />
          </Flex>
        </SortWrapper>
        <Flex width="50%">
          <Flex width="100%" justifyContent="center">
            <Typography role="columnheader" color="grays-mid" variant="caps">
              Products on Menu
            </Typography>
          </Flex>
          <Flex width="100%" justifyContent="center">
            <Typography role="columnheader" color="grays-mid" variant="caps">
              Hidden Products
            </Typography>
          </Flex>
          <Flex width="100%" justifyContent="center">
            <Typography role="columnheader" color="grays-mid" variant="caps">
              Unpublished Products
            </Typography>
          </Flex>
        </Flex>
      </Flex>

      {isLoading ? (
        <Flex flexDirection="column" role="rowgroup">
          {Array(STORE_SKELETONS_COUNT)
            .fill('')
            .map((_, i) => {
              return (
                <StoreCard isLoading={true} isCountLoading={true} key={i} />
              );
            })}
        </Flex>
      ) : (
        <Flex flexDirection="column" role="rowgroup">
          {sortedStores
            .slice(0, visibleStoreCount)
            .map((store: AbbreviatedStoreV2) => (
              <StoreCard
                key={store.id}
                store={store}
                isLoading={false}
                isCountLoading={isCountLoading}
              />
            ))}
          {visibleStoreCount < filteredStores.length ? (
            <LoadingWrapper ref={ref} />
          ) : null}
        </Flex>
      )}
    </TableWrapper>
  );
};

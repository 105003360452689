import type { PopoverProps } from '@jane/shared/reefer';
import { InfoIcon, Popover } from '@jane/shared/reefer';

interface Props extends Pick<PopoverProps, 'variant' | 'data-testid'> {
  children?: React.ReactNode;
  label: string;
  popoverContent: React.ReactNode;
}

export const InfoPopoverWrapper = ({
  'data-testid': testId,
  label,
  popoverContent,
  children,
  variant,
}: Props) => {
  return (
    <>
      {children}
      <Popover
        openOn="hover"
        label={`${label} more info`}
        target={<InfoIcon ml={12} data-testid={testId} />}
        variant={variant}
      >
        <Popover.Content label={`${label} more info`}>
          {popoverContent}
        </Popover.Content>
      </Popover>
    </>
  );
};

import { useContext, useMemo } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { ModalNames } from '@jane/business-admin/util';
import { Flex, Skeleton, Typography } from '@jane/shared/reefer';

import { CardSection } from '../../../../CardSection';
import { EditableCard } from '../../../../EditableCard';
import { SubdomainModal } from './SubdomainModal';

export const SubdomainCard = () => {
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.Subdomain
  );
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const { data: storeSettings, isFetching } = useStoreSettings(storeId);

  const hostname = useMemo(() => {
    if (!storeSettings || !storeSettings.cloudflare_hostname) return undefined;
    const { cloudflare_hostname } = storeSettings;
    return cloudflare_hostname.hostname || undefined;
  }, [storeSettings]);

  const home_url = useMemo(() => {
    if (!storeSettings || !storeSettings.store_subdomain_setting)
      return undefined;
    const { store_subdomain_setting } = storeSettings;
    return store_subdomain_setting.home_url || undefined;
  }, [storeSettings]);

  const subdomainSettings = useMemo(() => {
    return { home_url: home_url, hostname: hostname };
  }, [home_url, hostname]);

  return (
    <>
      <EditableCard
        title="Subdomain"
        isLoading={isFetching}
        onEdit={() => {
          openModal();
        }}
        showTextButton={!isFetching && !hostname}
        textButtonLabel={'Add subdomain'}
        disabled={!canEditStore}
      >
        <Flex>
          {isFetching ? (
            <Skeleton animate>
              <Skeleton.Bone height="20px" width="200px" mb={12} />
            </Skeleton>
          ) : hostname ? (
            <>
              <CardSection width="50%" label="Host name">
                <Typography>{hostname || 'n/a'}</Typography>
              </CardSection>
              <CardSection width="50%" label="Home url">
                <Typography>{home_url || 'n/a'}</Typography>
              </CardSection>
            </>
          ) : (
            <Typography>
              A subdomain has not been added for this store.
            </Typography>
          )}
        </Flex>
      </EditableCard>
      {modalOpen && (
        <SubdomainModal
          open={modalOpen}
          setOpen={() => closeModal()}
          settings={subdomainSettings}
          subtitle={storeSettings?.store.name || ''}
          storeId={storeSettings?.store.id || 0}
        />
      )}
    </>
  );
};

import omit from 'lodash/omit';
import { useEffect, useState } from 'react';

import type {
  StoreSchedule,
  StoreSettingsPayload,
} from '@jane/business-admin/types';
import { ALL_DAYS, RESERVATION_MODES } from '@jane/business-admin/util';
import { formatTime } from '@jane/shared/util';

const HOURS_TABS = ['retail', ...RESERVATION_MODES];

export const useParseHours = (
  storePayload: StoreSettingsPayload | undefined
) => {
  const [schedules, setSchedules] = useState<{ [key: string]: string[] }>({
    retail: new Array(ALL_DAYS.length).fill('Closed'),
    pickup: new Array(ALL_DAYS.length).fill('Closed'),
    curbside: new Array(ALL_DAYS.length).fill('Closed'),
    delivery: new Array(ALL_DAYS.length).fill('Closed'),
  });

  const processDays = (schedule: StoreSchedule) => {
    const days: string[] = [];
    ALL_DAYS.forEach((day) => {
      const start = (schedule as StoreSchedule)[
        `${day}_hours_start` as keyof StoreSchedule
      ];
      const end = (schedule as StoreSchedule)[
        `${day}_hours_end` as keyof StoreSchedule
      ];
      if (!start || !end) {
        days.push('Closed');
        return;
      }

      const formattedStart = formatTime(start as string, 'h:mm A', 'hh:mm');
      const formattedEnd = formatTime(end as string, 'h:mm A', 'hh:mm');
      days.push(`${formattedStart} - ${formattedEnd}`);
    });
    return days;
  };

  useEffect(() => {
    const parsedSchedules = {
      retail: new Array(ALL_DAYS.length).fill('Closed'),
      pickup: new Array(ALL_DAYS.length).fill('Closed'),
      curbside: new Array(ALL_DAYS.length).fill('Closed'),
      delivery: new Array(ALL_DAYS.length).fill('Closed'),
    };
    HOURS_TABS.forEach((tab) => {
      // Check if schedule exists for that tab
      const schedule =
        storePayload &&
        storePayload[`${tab}_schedule` as keyof StoreSettingsPayload];
      if (!schedule) {
        return;
      }

      // Check if all values are null
      const uniqueValues = new Set(
        Object.values(omit(schedule as object, ['id', 'schedule_type']))
      );
      if (uniqueValues.has(null) && uniqueValues.size === 1) {
        return;
      }

      // Go through all days and create array that corresponds to days of the week
      parsedSchedules[tab as keyof typeof parsedSchedules] = processDays(
        schedule as StoreSchedule
      );
    });
    setSchedules(parsedSchedules);
  }, [
    JSON.stringify(storePayload?.retail_schedule),
    JSON.stringify(storePayload?.pickup_schedule),
    JSON.stringify(storePayload?.curbside_schedule),
    JSON.stringify(storePayload?.delivery_schedule),
  ]);

  return schedules;
};

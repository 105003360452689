import { useMemo } from 'react';

import type {
  DeliveryGeofenceV2,
  FulfillmentConfig,
} from '@jane/business-admin/types';
import { BODY_LINE_HEIGHT, formatDuration } from '@jane/business-admin/util';
import { config } from '@jane/shared/config';
import type { FlexProps } from '@jane/shared/reefer';
import {
  Button,
  EditIcon,
  Flex,
  Image,
  Skeleton,
  TrashIcon,
  Typography,
} from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

const LoadingTextBlock = () => (
  <Skeleton direction="column" animate>
    <Skeleton.Bone height={BODY_LINE_HEIGHT} mt={8} mb={8} mr={4} width="60%" />
    <Skeleton.Bone height={BODY_LINE_HEIGHT} mr={4} width="60%" />
  </Skeleton>
);
export const GeofenceLoadingItem = () => {
  return (
    <Flex mb={24}>
      <Skeleton animate direction="row">
        <Skeleton.Bone height={80} width={80} borderRadius="lg" mr={24} />
        <LoadingTextBlock />
        <LoadingTextBlock />
        <LoadingTextBlock />
        <LoadingTextBlock />
        <LoadingTextBlock />
        <Flex>
          <Button.Icon label="Edit" icon={<EditIcon />} disabled={true} />
          <Button.Icon label="Delete" icon={<TrashIcon />} disabled={true} />
        </Flex>
      </Skeleton>
    </Flex>
  );
};

const buildImageURL = (geojson: string) => {
  const settings = {
    username: 'mapbox',
    style_id: 'streets-v11',
    overlay: `geojson(${encodeURIComponent(geojson)})`,
    width: 80,
    height: 80,
    padding: 12,
  };
  return `https://api.mapbox.com/styles/v1/${settings.username}/${settings.style_id}/static/${settings.overlay}/auto/${settings.width}x${settings.height}?padding=${settings.padding}&access_token=${config.mapboxAccessToken}`;
};

const isPlural = (minutes: number) => {
  return minutes > 1 ? 's' : '';
};

const formatMinutes = (minutes: number | null | undefined) => {
  return minutes === null || minutes === undefined
    ? 'N/A'
    : `${minutes} minute${isPlural(minutes)}`;
};

export interface GeofenceSettingItemProps extends FlexProps {
  config?: FulfillmentConfig;
  geofence: DeliveryGeofenceV2;
  index?: number;
  onDelete?: (geofenceId: number) => void;
  onEdit?: () => void;
  readonly?: boolean;
}

export const GeofenceSettingItem = ({
  geofence,
  readonly = true,
  index,
  onEdit,
  onDelete,
  config,
  ...flexProps
}: GeofenceSettingItemProps) => {
  const previewImage = useMemo(() => {
    return buildImageURL(geofence.geojson);
  }, [geofence.geojson]);

  const minMaxLeadTimeValue = (
    minutes: number | null,
    storeLevelMinutes: number | null | undefined,
    isMin: boolean
  ) => {
    return formatDuration(
      minutes && minutes > 0 ? minutes : storeLevelMinutes,
      (minutes === 0 || minutes === null) && storeLevelMinutes === 0
        ? isMin
          ? 'minimum'
          : 'maximum'
        : ''
    );
  };

  const showWindowInterval = () => {
    if (!geofence.display_windows_by_day && !config?.display_windows_by_day) {
      return true;
    } else if (geofence.display_windows_by_day) {
      return false;
    } else if (
      !geofence.display_windows_by_day &&
      !geofence.window_minutes &&
      !geofence.interval_minutes &&
      config?.display_windows_by_day
    ) {
      return false;
    } else {
      return true;
    }
  };

  const schedulingValue = (
    minutes: number | null,
    storeLevelMinutes: number | undefined,
    isWindow: boolean
  ) => {
    return minutes === 0 && storeLevelMinutes === 0
      ? `No ${isWindow ? 'window' : 'interval'}`
      : `${formatMinutes(
          minutes && minutes > 0 ? minutes : storeLevelMinutes
        )} ${isWindow ? 'window' : 'interval'}`;
  };

  return (
    <Flex as="li" alignItems="center" {...flexProps}>
      <Flex width="120px" mr={24}>
        <Image
          width="120px"
          height="120px"
          altText="Preview of geofence"
          src={previewImage}
          borderRadius="rounded"
        />
      </Flex>
      <Flex flexDirection="column" width="100%">
        <Flex mb={24}>
          <Flex flexDirection="column" width="100%">
            <Typography color="grays-mid" mb={8}>
              Name
            </Typography>
            <Typography>{geofence.name || geofence.id}</Typography>
          </Flex>
          <Flex flexDirection="column" width="100%">
            <Typography color="grays-mid" mb={8}>
              Delivery fee
            </Typography>
            <Typography>{`${formatCurrency(geofence.fee)}`}</Typography>
          </Flex>
          <Flex flexDirection="column" width="100%">
            <Typography color="grays-mid" mb={8}>
              Minimum
            </Typography>
            <Typography>{`${formatCurrency(
              geofence.cart_minimum
            )}`}</Typography>
          </Flex>
          <Flex flexDirection="column" width="100%">
            <Typography color="grays-mid" mb={8}>
              Min lead time
            </Typography>
            <Typography data-testid="min-lead-time">
              {minMaxLeadTimeValue(
                geofence.min_lead_time_minutes,
                config?.min_lead_time_minutes,
                true
              )}
            </Typography>
          </Flex>
        </Flex>
        <Flex>
          <Flex flexDirection="column" width="25%">
            <Typography color="grays-mid" mb={8}>
              Max lead time
            </Typography>
            <Typography data-testid="max-lead-time">
              {minMaxLeadTimeValue(
                geofence.max_lead_time_minutes,
                config?.max_lead_time_minutes,
                false
              )}
            </Typography>
          </Flex>
          <Flex flexDirection="column" width={'25%'}>
            <Typography color="grays-mid" mb={8}>
              Last call
            </Typography>
            <Typography>
              {formatMinutes(geofence.last_call_minutes || 0)}
            </Typography>
          </Flex>
          <Flex flexDirection="column" width="25%">
            <Typography color="grays-mid" mb={8}>
              Scheduling
            </Typography>
            {showWindowInterval() ? (
              <>
                <Typography data-testid="window-time">
                  {schedulingValue(
                    geofence.window_minutes,
                    config?.window_minutes,
                    true
                  )}
                </Typography>
                <Typography data-testid="interval-time">
                  {schedulingValue(
                    geofence.interval_minutes,
                    config?.interval_minutes,
                    false
                  )}
                </Typography>
              </>
            ) : (
              <Typography data-testid="daily-window">Daily window</Typography>
            )}
          </Flex>
        </Flex>
      </Flex>
      {!readonly && (
        <Flex>
          <Button.Icon label="Edit" onClick={onEdit} icon={<EditIcon />} />
          <Button.Icon
            label="Delete"
            onClick={() => onDelete && onDelete(geofence.id)}
            icon={<TrashIcon />}
          />
        </Flex>
      )}
    </Flex>
  );
};

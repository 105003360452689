import get from 'lodash/get';
import has from 'lodash/has';
import update from 'lodash/update';
import type { FieldErrors, FieldValues } from 'react-hook-form/dist/types';

import type { ApiRequestError } from '@jane/shared/data-access';
import { trackError } from '@jane/shared/util';

const redactValues = (
  redactedPaths?: string[][],
  requestData?: Record<string, any>
) => {
  if (!redactedPaths || !requestData) return requestData;

  const updateIfExists = (data: any, field: string[]) => {
    if (has(data, field)) {
      update(data, field, () => '***');
    }
  };

  const redactArrayFields = (field: string[]) => {
    const upToArray = field.slice(0, field.indexOf('array_index'));
    const restOfPath = field.slice(field.indexOf('array_index') + 1);
    const arrayValues = get(requestData, upToArray, []);
    arrayValues.map((value: any) => {
      return updateIfExists(value, restOfPath);
    });
  };

  redactedPaths.forEach((field) => {
    if (field.includes('array_index')) {
      redactArrayFields(field);
    } else {
      updateIfExists(requestData, field);
    }
  });
  return requestData;
};

export const catchSubmitErrors = async (
  submitMethod: () => Promise<any>,
  onValidationError: (validationErrors: any) => void,
  genericMessage: string,
  callback?: (validationErrors: any) => void,
  requestData?: Record<string, any>,
  managerData?: { email: string; id: number },
  redactedKeyPaths?: string[][]
) => {
  try {
    await submitMethod();
  } catch (err: any) {
    let trackResponse = err;
    let callbackData = err;

    if (err.message === 'Api Request Failed') {
      const response = (err as ApiRequestError).response;
      const body = await response.json();
      if (response.status === 400) {
        if (body.errors.error === 'Validation Error') {
          trackError('Business admin request validation error', {
            validationErrors: body.errors.validations,
            response: {
              status: response.status,
              statusText: response.statusText,
              body,
            },
            request: redactValues(redactedKeyPaths, requestData),
            manager: managerData,
          });
          return onValidationError(body.errors.validations);
        }
      }
      trackResponse = {
        status: response.status,
        statusText: response.statusText,
        body,
      };
      callbackData = body;
    }
    trackError(genericMessage, {
      response: trackResponse,
      request: redactValues(redactedKeyPaths, requestData),
      manager: managerData,
    });
    if (callback) {
      callback(callbackData);
    } else {
      throw new Error(genericMessage);
    }
  }
};

export const parseValidationErrors = (
  validationErrors: any,
  // Prepend/append is used to support nested form field names (i.e. stronghold_integration.enabled)
  {
    namePrepend,
    nameAppend,
  }: { nameAppend?: string; namePrepend?: string } = {}
) => {
  return Object.entries(validationErrors).map(([k, v]) => {
    const prepend = namePrepend ? `${namePrepend}.` : '';
    const append = nameAppend ? `.${nameAppend}` : '';
    const name = `${prepend}${k}${append}`;
    return { name, message: v as string };
  });
};

export const parseBannedPhraseError = (errorMessage: string) => {
  const bannedPhraseRegex = new RegExp(/(Phrase )(.*)( is not allowed)/gm);
  const matches = errorMessage.match(bannedPhraseRegex);
  if (matches?.length) {
    const splitError = errorMessage.split(' ');
    const fieldName = splitError[splitError.length - 1];
    return [
      {
        name: fieldName,
        message: matches.join(', '),
      },
    ];
  }
  return null;
};

export const parseInvalidTemplateUrlError = (validationErrors: any) => {
  const errors = validationErrors.errors;

  if (!errors) return null;

  const invalidTemplateMessageRegex = new RegExp(/(Import Error: )(.*)/gm);
  const invalidContentSelector = new RegExp(
    /(Import Error: )(.*)(content_selector: )(.*)/gm
  );
  const hasTemplateError = errors.match(invalidTemplateMessageRegex);
  const hasSelectorError = errors.match(invalidContentSelector);

  if (hasSelectorError?.length) {
    const formattedError = errors.split('Import Error: ');
    return {
      message: `${formattedError[1]}. Please expand the 'Template overrides' below to set a custom content_selector`,
    };
  } else if (hasTemplateError?.length) {
    const formattedError = errors.split('Import Error: ');
    return { message: formattedError[1] };
  }

  return null;
};

export const getFormErrorMessages = (
  errors: FieldErrors<FieldValues>,
  specificFieldNames?: string[]
) => {
  const errorMessages = [];
  for (const error in errors) {
    if (!specificFieldNames || specificFieldNames?.includes(error)) {
      errorMessages.push(
        errors[error as keyof FieldErrors<FieldValues>]?.message
      );
    }
  }
  return errorMessages;
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as t from 'io-ts';

import { tStaffMember } from '@jane/business-admin/types';
import type { StaffMember } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type { Id } from '@jane/shared/models';

import { STORES_URL } from './stores';

export const STAFF_MEMBERS_URL = '/business/staff';

const fetchStoreStaffMembers = (storeId: Id): Promise<any> => {
  const storeStaffUrl = `${STORES_URL}/${storeId}/staff`;

  return fetchWithDecode(
    janeApiV2.get(storeStaffUrl),
    t.interface({
      staff: t.array(tStaffMember),
    }),
    storeStaffUrl
  );
};
export const useStoreStaff = (storeId: Id) =>
  useQuery({
    queryFn: () => fetchStoreStaffMembers(storeId),
    queryKey: ['staff_members', storeId],
    useErrorBoundary: true,
  });

interface CreateStaffMemberResponse {
  message: string;
  staff_member: StaffMember;
}

const createStaffMember = (
  staffMember: StaffMember
): Promise<CreateStaffMemberResponse> =>
  janeApiV2.post(STAFF_MEMBERS_URL, {
    staff_member: staffMember,
  });
export const useCreateStaffMember = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: StaffMember) => createStaffMember(params),
    mutationKey: ['staff_members', 'create'],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['staff_members']);
    },
  });
};

const updateStaffMember = (
  id: number,
  staffMember: StaffMember
): Promise<null> =>
  janeApiV2.patch(`${STAFF_MEMBERS_URL}/${id}`, {
    staff_member: staffMember,
  });
export const useUpdateStaffMember = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: StaffMember) => updateStaffMember(id, params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['staff_members']);
    },
  });
};

const reinviteStaffMember = (id: number): Promise<null> =>
  janeApiV2.patch(`${STAFF_MEMBERS_URL}/${id}/resend_invitation`, {});
export const useReinviteStaffMember = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => reinviteStaffMember(id),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['staff_members', id]);
    },
  });
};

const deleteStaffMember = (id: number): Promise<null> =>
  janeApiV2.delete(`${STAFF_MEMBERS_URL}/${id}`, {});
export const useDeleteStaffMember = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteStaffMember(id),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['staff_members']);
    },
  });
};

import pluralise from 'pluralise';
import { useEffect, useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { useManager } from '@jane/business-admin/data-access';
import { useHasPermissions } from '@jane/business-admin/hooks';
import {
  EventNames,
  NavigationSourceIds,
  businessPaths,
  track,
} from '@jane/business-admin/util';
import type { Permission } from '@jane/shared/auth';
import { Banner } from '@jane/shared/reefer';
import { trackWithCallbackAsync } from '@jane/shared/util';

export interface AccessRequiredProps {
  permissions?: Permission[];
  requireClient?: boolean;
}

export const AccessRequired = ({
  permissions,
  requireClient = false,
  children,
}: PropsWithChildren<AccessRequiredProps>) => {
  const { pathname } = useLocation();
  const { data: currentManager, isFetched } = useManager();

  const hasPermission = useHasPermissions(permissions);

  const isLoggedIn = useMemo(() => {
    if (currentManager?.manager?.id) return true;
    return false;
  }, [currentManager]);

  const isClient = useMemo(() => {
    if (!requireClient || !isLoggedIn) return true;
    return currentManager?.manager?.role === 'client';
  }, [isLoggedIn, currentManager?.manager?.role, requireClient]);

  useEffect(() => {
    if (!isFetched) return;

    if (!isClient) {
      const trackThenRedirect = async () => {
        const options = {
          send_immediately: true,
        };
        await trackWithCallbackAsync(
          EventNames.AccessDenied,
          {
            reason: 'Manager role or beta user',
          },
          options
        );
        await trackWithCallbackAsync(
          EventNames.Navigated,
          {
            from_url: pathname,
            to_url: businessPaths.legacyRoot(),
            trigger_source_id: NavigationSourceIds.Automatic,
          },
          options
        );
        window.location.replace(businessPaths.legacyRoot());
      };
      trackThenRedirect();
    }
  }, [isFetched, isClient]);

  if (!isClient) {
    return null;
  }

  if (permissions?.length && !hasPermission) {
    const warningText = `This page can only be viewed when you have one of the following ${pluralise(
      (permissions || []).length,
      'permission'
    )}: ${(permissions || []).join(
      ', '
    )}. Contact your account administrator to get access.`;

    track({
      event: EventNames.AccessDenied,
      reason: 'Permissions',
    });

    return (
      <Banner full label={warningText} typography="header" variant="error" />
    );
  }

  return <div>{children}</div>;
};

import { useCallback, useEffect, useMemo, useState } from 'react';

import { BODY_LINE_HEIGHT } from '@jane/business-admin/util';
import { useSearch } from '@jane/search/data-access';
import type { AlgoliaProduct } from '@jane/search/types';
import {
  Skeleton,
  TypeAhead,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

import { RevertableInputWrapper } from '../../../../RevertableInputWrapper';

export const BrandsTypeAhead = ({
  isLoading,
  onChange,
  productBrand,
}: {
  isLoading: boolean;
  onChange: (value: string) => void;
  productBrand: string;
}) => {
  const [query, setQuery] = useState('');
  const { clearErrors, setError, watch, formState, trigger, setValue } =
    useFormContext();
  const { errors } = formState;
  const formBrand = watch('brand');

  const { data, isFetching } = useSearch<AlgoliaProduct>({
    indexPrefix: 'product-brand-',
    options: { query },
  });

  const options = useMemo(() => {
    return data?.hits.map((hit: any) => ({
      label: hit.name,
      id: hit.id,
    }));
  }, [data]);

  useEffect(() => {
    if (formBrand === '') {
      setError('brand-required-error', { message: 'required' });
    } else {
      clearErrors('brand-required-error');
      trigger('brand');
    }
  }, [formBrand, setError, clearErrors, trigger]);

  const onSelection = useCallback(
    (selection: string | string[]) => {
      const selectedBrand = options?.find(
        (option) => option.label === selection
      );
      setValue('brand_id', selectedBrand?.id);
      clearErrors('brand-required-error');
      trigger('brand');
    },
    [clearErrors, trigger, options, setValue]
  );

  useEffect(() => {
    if (!isFetching && formBrand) {
      const selectedBrand = options?.find(
        (option) => option.label === formBrand
      );
      setValue('brand_id', selectedBrand?.id);
    }
  }, [isFetching, options]);

  const onBlur = useCallback(
    (val: string) => {
      const includesValue = options?.find((option) => option.label === val);
      if (!includesValue) {
        setError('brand-required-error', { message: 'required' });
      }
    },
    [setError, options]
  );

  if (isLoading) {
    return (
      <Skeleton direction="column" animate>
        <Skeleton.Bone
          height={BODY_LINE_HEIGHT}
          mt={8}
          mb={8}
          mr={4}
          width="20%"
        />
        <Skeleton.Bone height={48} mr={4} width="100%" borderRadius="sm" />
      </Skeleton>
    );
  }

  return (
    <>
      <RevertableInputWrapper
        defaultValue={productBrand}
        name="brand"
        fromPOS={true}
      >
        <TypeAhead
          loading={isFetching}
          ariaLabel="Brand"
          formName="brand"
          listAriaLabel="Search Brands"
          isDebounced={true}
          onSelection={onSelection}
          onChange={(val) => {
            setQuery(val);
            onChange && onChange(val);
          }}
          optionType="button"
          options={options}
          labelHidden={false}
          onBlur={onBlur}
        />
      </RevertableInputWrapper>
      {errors['brand-required-error'] && (
        <Typography color="error">Please select a brand.</Typography>
      )}
    </>
  );
};

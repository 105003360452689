import pluralise from 'pluralise';
import { useParams } from 'react-router-dom';

import type { UpdateMenuTabOrderBody } from '@jane/business-admin/data-access';
import {
  useStoreMenu,
  useUpdateMenuTabOrder,
} from '@jane/business-admin/data-access';
import type { MenuTab } from '@jane/business-admin/types';
import { ModalNames, SettingNames } from '@jane/business-admin/util';

import { ArrangeModal } from './ArrangeModal';

export const ArrangeMenuTabsModal = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const { id = '' } = useParams<'id'>();
  const { mutateAsync: updateMenuTabOrder, isSuccess: updateTabOrderSuccess } =
    useUpdateMenuTabOrder(id);
  const { data: menuData } = useStoreMenu(id);

  return (
    <ArrangeModal<MenuTab, UpdateMenuTabOrderBody>
      allowDisablingForItem={(tab: MenuTab) => tab.tab_name !== 'featured'}
      closeModal={closeModal}
      enabledKey="enabled"
      formatItem={({ item }) => item}
      getItemEnabled={(item) => {
        if (item.menu_product_count === 0) {
          return false;
        }

        return item.enabled;
      }}
      getItemCountLabel={(item) => {
        if (item.tab_name === 'featured') {
          const activeCustomRowCount = menuData?.menu_rows.filter(
            (row) => !row.enabled && !row.is_category
          ).length;
          return activeCustomRowCount
            ? `${activeCustomRowCount} product ${pluralise(
                activeCustomRowCount,
                'group'
              )}`
            : '';
        }
        if (item.menu_product_count === 0) {
          return 'No inventory';
        }
        return `${item.menu_product_count} ${pluralise(
          item.menu_product_count,
          'product'
        )}`;
      }}
      getItemId={(tab: MenuTab) => `${tab.id}`}
      getItemToggleDisabled={(tab) => tab.menu_product_count === 0}
      menuDataKey="menu_tabs"
      modalName={ModalNames.ArrangeMenuTabs}
      rankKey="rank"
      settingName={SettingNames.MenuTabOrder}
      showHiddenIconForItem={(tab: MenuTab) => {
        if (tab.tab_name === 'featured') {
          return false;
        }
        return !tab.enabled || tab.menu_product_count === 0;
      }}
      title="Arrange category tabs"
      type="tab"
      updateMenuOrder={updateMenuTabOrder}
      updateMenuOrderSuccess={updateTabOrderSuccess}
    />
  );
};

import pluralise from 'pluralise';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  useCreateGlobalSpecial,
  useFetchGlobalSpecial,
  useUpdateGlobalSpecial,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useHasPermissions,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import {
  type GlobalSpecialV2,
  type SpecialRulesV2,
  SpecialStatus,
} from '@jane/business-admin/types';
import {
  EventNames,
  MODAL_CARD_WIDTH,
  ModalNames,
  businessPaths,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import type { ValueOf } from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import type { Photo, SpecialType } from '@jane/shared/models';
import {
  Button,
  Card,
  ErrorIcon,
  Flex,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../lib/ConfirmWrapperWithTracking';
import { ArchiveWithConfirm } from '../../../lib/specials/ArchiveWithConfirm';
import { ImagesCard } from '../../shared/specials/modal/ImagesCard';
import { PromoCodeCard } from '../../shared/specials/modal/PromoCodeCard';
import { ConditionsCard } from '../../shared/specials/modal/conditions/ConditionsCard';
import {
  convertRulesToBulkConditions,
  convertRulesToBundleConditions,
} from '../../shared/specials/modal/conditions/utils/legacyConditionsHelpers';
import {
  SHOW_PROMO_CODE_SPECIAL_TYPES,
  getBundleDisplay,
  getRules,
  parseRulesFromApplyTo,
} from '../../shared/specials/modal/form';
import { ScheduleCard } from '../../shared/specials/modal/schedule/ScheduleCard';
import { convert12HourTimeTo24HourTime } from '../../shared/specials/modal/schedule/ScheduleTimeValidation';
import {
  DEFAULT_SPECIAL_SCHEDULE,
  formatSchedule,
  parseSchedule,
} from '../../shared/specials/modal/schedule/scheduleHelpers';
import { DetailsCard } from './DetailsCard';
import { StoreSelectorCard } from './StoreSelectorCard';

const FORM_ERROR_NAME = 'global-special-edit-error';

type FormRules = {
  apply_to: 'ALL' | 'INDIVIDUAL' | 'CONDITIONS';
  rules: SpecialRulesV2;
};
type SpecialFormData = GlobalSpecialV2 & {
  display: {
    bundle: {
      max_applications_per_cart?: number | null;
      max_number_of_discounted_products?: number | null;
      settings?: any;
      threshold_number_of_items_in_cart?: number | null;
    };
    default_rules: FormRules | undefined;
    discounted_rules: FormRules | undefined;
    promo_code_enabled: boolean;
    required_rules: FormRules | undefined;
  };
  end_date: string | null | undefined;
  has_schedule_overrides: boolean;
  is_duplicate: boolean;
  name: string;
  photo: Photo[];
  start_date: string | null | undefined;
  use_store_close_time: boolean;
};

const Mode = {
  create: 'create',
  update: 'update',
  duplicate: 'duplicate',
} as const;

type Mode = ValueOf<typeof Mode>;

const modeToModalHeaderTitleMap: Record<Mode, string> = {
  [Mode.create]: 'Create Global Special',
  [Mode.duplicate]: 'Duplicate Global Special',
  [Mode.update]: 'Edit Global Special',
};

const modeToGenericErrorStringMap: Record<Mode, string> = {
  [Mode.create]: 'creating',
  [Mode.update]: 'updating',
  [Mode.duplicate]: 'duplicating',
};

const modeToSuccessMessageSuffixMap: Record<Mode, string> = {
  [Mode.create]: 'created',
  [Mode.duplicate]: 'created',
  [Mode.update]: 'updated',
};

const modeToActionMap: Record<Mode, 'create' | 'duplicate' | 'update'> = {
  [Mode.create]: 'create',
  [Mode.duplicate]: 'duplicate',
  [Mode.update]: 'update',
};

export const GlobalSpecialsModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [headerError, setHeaderError] = useState<string>();
  const { special_id: specialId = '' } = useParams<'special_id'>();
  const previousStatus = localStorage.getItem('currentSpecialStatus');
  const specialIdIsCreate = specialId === 'create';

  const { pathname } = useLocation();
  const pathIncludesDuplicate = pathname.includes('duplicate');

  const mode = pathIncludesDuplicate
    ? Mode.duplicate
    : specialIdIsCreate
    ? Mode.create
    : Mode.update;

  const genericErrorMessage = `Error ${modeToGenericErrorStringMap[mode]} special. Please try again.`;

  const navigate = useNavigate();
  const toast = useToast();

  const { data: special, isFetching: specialLoading } = useFetchGlobalSpecial(
    specialId,
    mode === Mode.create
  );

  const isArchived = special?.archived || false;
  // // Navigate and show error if there is no special when loading is finished
  useEffect(() => {
    if (mode !== Mode.create && !specialLoading && !special) {
      toast.add({
        label: 'Special could not be found.',
        variant: 'error',
      });
      navigate(
        businessPaths.specials(
          (previousStatus as SpecialStatus) || SpecialStatus.live
        )
      );
      setIsOpen(false);
    }
  }, [specialLoading, special]);

  const {
    mutateAsync: createSpecial,
    isLoading: isCreating,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
  } = useCreateGlobalSpecial();
  const {
    mutateAsync: updateSpecial,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateGlobalSpecial(specialId);

  useEffect(() => {
    if (specialId) setIsOpen(true);
  }, [specialId]);

  const userCanEditSpecials = useHasPermissions([Permission.EditSpecials]);
  const catchSubmitErrors = useCatchErrorsWithManager(genericErrorMessage);

  const format_conditions = (special_conditions: any) => {
    if (special?.special_type) {
      return { [special.special_type]: { ...special_conditions } };
    }

    return special_conditions;
  };

  const parsedSchedule = parseSchedule(special, mode === Mode.create);

  const isCreateMode = mode === Mode.create;
  const fetchedSpecialValues = {
    display: {
      ...getRules(isCreateMode, special),
      bundle: getBundleDisplay(isCreateMode, special),
      promo_code_enabled: !!special?.promo_code,
    },
    special_type: special?.special_type || '',
    name: special?.title || '',
    promo_code: special?.promo_code,
    multiple_use_promo_code:
      special?.promo_code && special?.multiple_use_promo_code,
    promo_code_max_number_of_uses:
      special?.promo_code_max_number_of_uses || undefined,
    conditions: format_conditions(special?.conditions) || {},
    discount_dollar_amount: special?.discount_dollar_amount || '',
    discount_percent: special?.discount_percent || '',
    discount_target_price: special?.discount_target_price || '',
    discount_type: special?.discount_type || '',
    enabled: !!special?.enabled,
    description: special?.description || '',
    reservation_modes: special?.reservation_modes,
    stacking_setting: special?.stacking_setting,
    stores: special?.stores || [],
    terms: special?.terms || '',
    photo:
      special?.photo && special.photo['urls']['original']
        ? [special.photo['urls']['original']]
        : undefined,
    ...parsedSchedule,
  };

  // TODO: repopulate form values with each ticket
  const formMethods = useForm({
    defaultValues: {
      display: {
        ...getRules(isCreateMode, special),
        bundle: getBundleDisplay(isCreateMode, special),
        promo_code_enabled: isCreateMode ? false : !!special?.promo_code,
      },
      special_type: isCreateMode ? '' : special?.special_type || '',
      name: isCreateMode ? '' : special?.title || '',
      promo_code: isCreateMode ? '' : special?.promo_code,
      multiple_use_promo_code: isCreateMode
        ? false
        : !!(special?.promo_code && special?.multiple_use_promo_code),
      promo_code_max_number_of_uses: isCreateMode
        ? undefined
        : special?.promo_code_max_number_of_uses || undefined,
      conditions: isCreateMode
        ? {
            cart_total: {
              threshold: 0,
            },
            bulk_pricing: {
              target_weight: 'ounce',
              max_applications_per_cart: 1,
            },
            qualified_group: {
              type: 'senior',
              required_age: 65,
            },
            bundle: {
              settings: {
                allow_discounts_on_required_products: false, // Allow qualifying products to be discounted
              },
              dependent: {
                max_number_of_discounted_products: 1, // Max products discounted
              },
              independent: {
                threshold_number_of_items_in_cart: 1, // Minimum products requirement
              },
              max_applications_per_cart: 1, // Max uses per order
            },
          }
        : format_conditions(special?.conditions) || {},
      discount_type: isCreateMode ? 'percent' : special?.discount_type || '',
      discount_dollar_amount: isCreateMode
        ? 0
        : special?.discount_dollar_amount || 0,
      discount_percent: isCreateMode ? 0 : special?.discount_percent || 0,
      discount_target_price: isCreateMode
        ? 0
        : special?.discount_target_price || 0,
      enabled: isCreateMode ? false : !!special?.enabled,
      description: isCreateMode ? '' : special?.description || '',
      reservation_modes: isCreateMode
        ? {
            delivery: true,
            pickup: true,
          }
        : {
            delivery: !!special?.reservation_modes?.delivery,
            pickup: !!special?.reservation_modes?.pickup,
          },
      terms: isCreateMode ? '' : special?.terms || '',
      stacking_setting: isCreateMode ? 'yes' : special?.stacking_setting,
      stores: isCreateMode ? [] : special?.stores || [],
      photo: isCreateMode
        ? undefined
        : special?.photo && special.photo['urls']['original']
        ? [special.photo['urls']['original']]
        : undefined,
      ...parsedSchedule,
    },
    values: isCreateMode ? undefined : fetchedSpecialValues,
  });

  // TODO: do we we need archiveSpecialLoading?
  const isMutating = isCreating || isUpdating;

  useMutationStatusToasts({
    isMutating: isCreating || isUpdating,
    isSuccess: isCreateSuccess || isUpdateSuccess,
    isError: isCreateError || isUpdateError,
    successMessage: `Special successfully ${modeToSuccessMessageSuffixMap[mode]}`,
    errorMessage: genericErrorMessage,
  });

  const {
    formState: { dirtyFields, isDirty },
    watch,
  } = formMethods;

  const specialTypeWatch = watch('special_type');
  const requiredProductsCount = watch(
    'display.bundle.threshold_number_of_items_in_cart'
  );
  const discountedProductsCount = watch(
    'display.bundle.max_number_of_discounted_products'
  );

  const getSubheaders = useMemo(() => {
    if (specialTypeWatch !== 'bundle') {
      return {
        requiredSubhead: undefined,
        discountedSubhead: undefined,
      };
    }

    let requiredSubhead;
    if (requiredProductsCount && requiredProductsCount > 0) {
      requiredSubhead = `${requiredProductsCount} ${pluralise(
        requiredProductsCount,
        'product'
      )} required to unlock Special.`;
    }

    let discountedSubhead;
    if (discountedProductsCount && discountedProductsCount > 0) {
      discountedSubhead = `${discountedProductsCount} ${pluralise(
        discountedProductsCount,
        'product'
      )} maximum to discount.`;
    }

    return {
      requiredSubhead,
      discountedSubhead,
    };
  }, [requiredProductsCount, discountedProductsCount, specialTypeWatch]);

  const isEditable = userCanEditSpecials;

  const specialIsLoading = mode !== Mode.create && specialLoading;

  const onSubmit = (formData: SpecialFormData) => {
    setHeaderError(undefined);
    const includePromoCode =
      (SHOW_PROMO_CODE_SPECIAL_TYPES as SpecialType[]).includes(
        formData['special_type']
      ) && formData['display']['promo_code_enabled'] === true;
    const includeScheduleOverride = formData['has_schedule_overrides'] === true;

    const updateOrDestroySpecialPhoto = () => {
      const photoId = special?.photo && special.photo['id'];
      return {
        id: photoId,
        file: formData['photo'][0] || '',
        // delete photo
        _destroy: photoId && !formData['photo'][0],
      };
    };

    const formatGlobalSchedule = (
      date?: string | null,
      time?: string | null,
      timezone = 'UTC'
    ) => {
      if (!date || !time) {
        return null;
      }

      const formatted = `${date} ${time} ${timezone}`;

      return formatted;
    };

    const {
      photo: __omitted2,
      start_date: __omitted3,
      end_date: __omitted4,
      conditions: __omitted5,
      ...desiredFormData
    } = formData;
    const schedule = includeScheduleOverride
      ? formatSchedule(formData['schedule'])
      : DEFAULT_SPECIAL_SCHEDULE;

    const requestData = {
      ...desiredFormData,
      promo_code: includePromoCode ? formData['promo_code'] : null,
      multiple_use_promo_code: includePromoCode
        ? !!formData['multiple_use_promo_code']
        : false,
      promo_code_max_number_of_uses: includePromoCode
        ? formData['promo_code_max_number_of_uses']
        : null,
      title: formData['name'],
      discount_dollar_amount: formData['discount_dollar_amount'] || 0,
      discount_percent: formData['discount_percent'] || 0,
      discount_target_price: formData['discount_target_price'] || 0,
      // TODO: Dates have to be formatted so the date and time are in start/end_date
      end_time: formatGlobalSchedule(
        formData['end_date'],
        convert12HourTimeTo24HourTime(formData['end_time'])
      ),
      start_time: formatGlobalSchedule(
        formData['start_date'],
        convert12HourTimeTo24HourTime(formData['start_time'])
      ),
      use_store_close_time: !!formData['use_store_close_time'],
      is_duplicate: pathIncludesDuplicate,
      photo_attributes: updateOrDestroySpecialPhoto(),
      ...schedule,
    };

    const specialType = formData['special_type'];

    if (specialType === 'bundle') {
      // If special is bundle, convert temporary rules to conditions so bundle special can be updated
      const discountedRules = parseRulesFromApplyTo(
        formData.display?.discounted_rules?.rules || {},
        formData.display?.discounted_rules?.apply_to || 'CONDITIONS'
      );
      const requiredRules = parseRulesFromApplyTo(
        formData.display?.required_rules?.rules || {},
        formData.display?.required_rules?.apply_to || 'CONDITIONS'
      );
      requestData['conditions'] = convertRulesToBundleConditions({
        discountedRules,
        requiredRules,
        max_applications_per_cart:
          formData.display.bundle.max_applications_per_cart || 0,
        max_number_of_discounted_products:
          formData.display.bundle.max_number_of_discounted_products || 0,
        threshold_number_of_items_in_cart:
          formData.display.bundle.threshold_number_of_items_in_cart || 0,
        settings: formData.display.bundle.settings,
        type: 'bundle',
      });
    } else if (specialType === 'bulk_pricing') {
      const rules = parseRulesFromApplyTo(
        formData.display?.default_rules?.rules || {},
        formData.display?.default_rules?.apply_to || 'CONDITIONS'
      );
      requestData['conditions'] = convertRulesToBulkConditions({
        rules,
        max_applications_per_cart:
          formData.conditions?.bulk_pricing?.max_applications_per_cart || 0,
        target_weight:
          formData.conditions?.bulk_pricing?.target_weight || 'each',
      });
    } else if (specialType === 'cart_total') {
      const rawThreshold = formData.conditions.cart_total?.threshold || 0;
      const threshold =
        typeof rawThreshold === 'string'
          ? parseFloat(rawThreshold)
          : rawThreshold;

      requestData['conditions'] = {
        threshold,
      };

      requestData['rules'] = {
        ...parseRulesFromApplyTo(
          formData.display?.default_rules?.rules || {},
          formData.display?.default_rules?.apply_to || 'CONDITIONS'
        ),
        settings: {
          cart_threshold: threshold,
        },
      };
    } else if (specialType === 'qualified_group') {
      // debugger;
      requestData['conditions'] = formData['conditions'][specialType];
    } else {
      requestData['rules'] = parseRulesFromApplyTo(
        formData.display?.default_rules?.rules || {},
        formData.display?.default_rules?.apply_to || 'CONDITIONS'
      );
    }

    const submitMethod = () => {
      track({
        action: modeToActionMap[mode],
        event: EventNames.EditedSpecial,
        changed_attributes: Object.keys(dirtyFields),
      });

      return mode === Mode.update
        ? updateSpecial(requestData)
        : createSpecial(requestData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      callback: () => {
        setHeaderError(genericErrorMessage);
        throw new Error(genericErrorMessage);
      },
    });
  };

  const onArchive = () => {
    setHeaderError(undefined);
  };

  const handleClose = () => {
    navigate(
      businessPaths.specials(
        (previousStatus as SpecialStatus) || SpecialStatus.live
      )
    );
  };

  const isSuccessfulExit = isCreateSuccess || isUpdateSuccess;

  useEffect(() => {
    if (!isSuccessfulExit) {
      return;
    }

    handleClose();
  }, [isSuccessfulExit]);

  return (
    <ConfirmWrapperWithTracking
      open={isOpen}
      setOpen={handleClose}
      hasChanges={isDirty}
      variant="full-screen"
      background="grays-ultralight"
      modalName={ModalNames['CreateSpecial']}
    >
      <Form.BaseForm
        name="special settings"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={modeToModalHeaderTitleMap[mode]}
          actions={
            <Flex alignItems="center">
              {headerError && (
                <Flex mr={12}>
                  <ErrorIcon color="error" mr={8} />
                  <Typography color="error">{headerError}</Typography>
                </Flex>
              )}
              {isEditable && (
                <>
                  {mode === Mode.update && (
                    <>
                      {!isArchived && (
                        <ArchiveWithConfirm
                          archiveButton={(onArchiveClick) => (
                            <Button
                              mr={12}
                              onClick={onArchiveClick}
                              label="Archive"
                              variant="secondary"
                              disabled={isMutating}
                            />
                          )}
                          onArchiveSpecial={onArchive}
                        />
                      )}
                      <Button
                        mr={12}
                        label="Duplicate"
                        variant="secondary"
                        to={businessPaths.globalSpecialDuplicate(specialId)}
                      />
                    </>
                  )}
                  {(!isArchived || (isArchived && mode === Mode.duplicate)) && (
                    <Form.SubmitButton
                      label="Publish"
                      variant="primary"
                      data-testid="special-edit-save"
                      disabled={specialIsLoading || isMutating}
                    />
                  )}
                </>
              )}
            </Flex>
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Flex alignItems="center" flexDirection="column">
            <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
              <Card.Content>
                <Flex p={24} flexDirection="column">
                  <DetailsCard
                    isEditable={isEditable}
                    isLoading={specialIsLoading}
                    isCreateMode={mode === Mode.create}
                    special={special}
                  />
                </Flex>
              </Card.Content>
            </Card>
            <ConditionsCard
              isGlobalSpecial={true}
              fieldPrefix={
                specialTypeWatch === 'bundle'
                  ? 'display.required_rules'
                  : 'display.default_rules'
              }
              isLoading={specialIsLoading}
              headerText={
                (specialTypeWatch === 'bundle' &&
                  'Conditions for required products') ||
                undefined
              }
              subHeaderText={getSubheaders['requiredSubhead']}
            />
            <PromoCodeCard
              isDuplicateMode={mode === Mode.duplicate}
              isCreateMode={mode === Mode.create}
              isGlobalSpecial={true}
              isLoading={specialIsLoading}
              specialPromoCode={special?.promo_code}
            />
            <ScheduleCard isLoading={specialIsLoading} />
            <ImagesCard isLoading={specialIsLoading} />
            {!specialIsLoading && (
              <StoreSelectorCard specialIsLoading={specialIsLoading} />
            )}
          </Flex>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

import * as t from 'io-ts';

import { tId } from './common';

export const tAnalyticsIntegrationV2 = t.interface({
  provider_id: t.union([t.string, t.null]),
  provider: t.union([t.string, t.null]),
  enabled: t.boolean,
  id: t.union([tId, t.null]),
  store_id: t.union([t.number, t.undefined]),
});
export type AnalyticsIntegrationV2 = t.TypeOf<typeof tAnalyticsIntegrationV2>;

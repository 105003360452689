import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  EventNames,
  businessPaths,
  normalizePath,
} from '@jane/business-admin/util';
import { Button, ErrorIcon, Flex, Typography } from '@jane/shared/reefer';
import { trackWithCallbackAsync } from '@jane/shared/util';

const TitleText = styled(Typography)({
  fontSize: '32px',
});

export const ComingSoon = ({
  classicViewPath,
}: {
  classicViewPath: string;
}) => {
  const { pathname } = useLocation();
  const { id = '' } = useParams<'id'>();

  const buttonHref = useMemo(() => {
    const href = businessPaths.legacyRoot() + classicViewPath;

    if (!classicViewPath.includes(':id') || !id) return href;

    return href.replace(':id', id);
  }, [id, classicViewPath]);

  const trackAndRedirect = useCallback(async () => {
    await trackWithCallbackAsync(
      EventNames.NavigatedToLegacy,
      {
        from_url: normalizePath(pathname, id),
        to_url: normalizePath(buttonHref, id),
      },
      {
        send_immediately: true,
      }
    );
    window.location.assign(buttonHref);
  }, [buttonHref, pathname, id]);

  return (
    <Flex width="432px" mx="auto" justifyContent="center">
      <Flex
        my={128}
        mx={40}
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        width="432px"
      >
        <ErrorIcon size="xxl" mb={24} />
        <Flex flexDirection="column" width="432px">
          <TitleText mb={8} variant="title-bold" textAlign="center">
            We&rsquo;re still working on it
          </TitleText>
          <Typography
            variant="body"
            mb={24}
            color="grays-dark"
            textAlign="center"
          >
            This page is still under construction, but don't worry, you can
            still access this functionality in the Classic View.
          </Typography>
          <Button
            full
            variant="primary"
            onClick={trackAndRedirect}
            label="Go back to Classic"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

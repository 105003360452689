import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  useDeleteStoreImage,
  useSaveStoreImages,
  useStoreImages,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  EventNames,
  FAMILIAR_MAX_IMAGE_SIZE,
  VALID_IMAGE_TYPES,
  normalizePath,
  track,
  validateImage,
} from '@jane/business-admin/util';
import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Button,
  Card,
  EditIcon,
  Link,
  List,
  PartnerLogo,
  Popover,
  Skeleton,
  useToast,
} from '@jane/shared/reefer';
import { ImagePreview } from '@jane/shared/util';

import { StoreBannerImage } from './StoreBannerImage';

type SaveStoreImagesCallback = (url: string, image_type: string) => void;

interface ImagePickerLinkProps {
  fieldName: string;
  label: string;
  onFailure: () => void;
  onSelect: () => void;
  saveStoreImages: SaveStoreImagesCallback;
}

const ImagePickerLink = ({
  label,
  fieldName,
  onFailure,
  onSelect,
  saveStoreImages,
}: ImagePickerLinkProps) => {
  const { storeId } = useContext(StoreDetailsContext);
  const pathname = window.location.pathname;

  const onDrop = useCallback(
    (files: File[]) => {
      const updateImage = async (files: File[]) => {
        onSelect();
        const file = files[0];

        if (!validateImage(file)) {
          return onFailure && onFailure();
        }

        const image = await ImagePreview.parse(file);

        saveStoreImages(image.url, fieldName);
      };

      track({
        event: EventNames.EditedPhoto,
        action: 'upload',
        file_size: `${files[0].size} bytes`,
        file_type: files[0].type,
        settings_name: fieldName,
        url: normalizePath(pathname, storeId),
      });

      updateImage(files);
    },
    [onSelect, saveStoreImages]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <div {...getRootProps()}>
      <Link variant="minimal" onClick={open}>
        {label}
      </Link>
      <input {...getInputProps()} />
    </div>
  );
};

const EditImagePopover = ({
  deleteStoreBanner,
  deleteStoreLogo,
  saveStoreImages,
  handleBadImageType,
  hasStoreBanner = false,
  hasStoreLogo = false,
}: {
  deleteStoreBanner: () => void;
  deleteStoreLogo: () => void;
  handleBadImageType: () => void;
  hasStoreBanner?: boolean;
  hasStoreLogo?: boolean;
  saveStoreImages: SaveStoreImagesCallback;
}) => (
  <Popover
    label="Edit store images"
    target={
      <Button.Icon label="Edit" variant="secondary" icon={<EditIcon />} />
    }
    alignment={{ horizontal: 'right' }}
  >
    {({ closePopover }: PopoverContextProps) => (
      <Popover.Content label="Edit store images options">
        <List label="store-images-list">
          <List.Item>
            <ImagePickerLink
              label="Change store photo"
              fieldName="primary_gallery_image"
              onSelect={closePopover}
              onFailure={() => {
                handleBadImageType();
                closePopover();
              }}
              saveStoreImages={saveStoreImages}
            />
          </List.Item>
          {hasStoreBanner && (
            <List.Item>
              <Link
                variant="minimal"
                onClick={() => {
                  deleteStoreBanner();
                  closePopover();
                }}
              >
                Delete store photo
              </Link>
            </List.Item>
          )}
          <List.Item>
            <ImagePickerLink
              label="Change logo"
              fieldName="photo"
              onSelect={closePopover}
              onFailure={() => {
                handleBadImageType();
                closePopover();
              }}
              saveStoreImages={saveStoreImages}
            />
          </List.Item>
          {hasStoreLogo && (
            <List.Item>
              <Link
                variant="minimal"
                onClick={() => {
                  deleteStoreLogo();
                  closePopover();
                }}
              >
                Delete logo
              </Link>
            </List.Item>
          )}
        </List>
      </Popover.Content>
    )}
  </Popover>
);

// Needed so the icon can be positioned absolutely
const StoreImageCardContainer = styled.div({
  display: 'flex',
  position: 'relative',
  marginBottom: 24,
});
const StoreLogoContainer = styled.div(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '45%',
  transform: 'translateY(-50%)',
  backgroundColor: '#fff',
  borderRadius: theme.borderRadius.lg,
}));
const EditImageContainer = styled.div({
  position: 'absolute',
  top: 35,
  // Weird value, but this lines it up with the rest of the card edit buttons
  right: 41,
});

export const StoreImageCard = () => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating store image. Please try again.',
    [['primary_gallery_image']]
  );

  const toast = useToast();
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const { data: images, isFetching } = useStoreImages(storeId);
  const isUploading = useIsMutating({
    mutationKey: ['save-store-images', storeId],
  });

  const {
    mutateAsync: saveStoreImages,
    isSuccess: saveStoreImageSuccess,
    isError: saveStoreImageError,
  } = useSaveStoreImages(storeId);

  const {
    mutateAsync: deleteStoreLogo,
    isSuccess: deleteStoreLogoSuccess,
    isError: deleteStoreLogoError,
    isLoading: isDeletingStoreLogo,
  } = useDeleteStoreImage(storeId, 'photo');

  const {
    mutateAsync: deleteStoreBanner,
    isSuccess: deleteStoreBannerSuccess,
    isError: deleteStoreBannerError,
    isLoading: isDeletingStoreBanner,
  } = useDeleteStoreImage(storeId, 'primary_gallery_image');

  useMutationStatusToasts({
    isMutating: isUploading,
    isSuccess: saveStoreImageSuccess,
    isError: saveStoreImageError,
    successMessage: 'Image updated',
    errorMessage: 'Image not updated',
  });

  useMutationStatusToasts({
    isMutating: isDeletingStoreLogo,
    isSuccess: deleteStoreLogoSuccess,
    isError: deleteStoreLogoError,
    successMessage: 'Logo removed',
    errorMessage: 'Error removing logo',
  });

  useMutationStatusToasts({
    isMutating: isDeletingStoreBanner,
    isSuccess: deleteStoreBannerSuccess,
    isError: deleteStoreBannerError,
    successMessage: 'Store photo removed',
    errorMessage: 'Error removing store photo',
  });

  const onSave = (url: string, image_type: string) => {
    return catchSubmitErrors({
      submitMethod: () =>
        saveStoreImages({ image: url, image_type: image_type }),
      requestData: { image: url, image_type: image_type },
      onValidationError: () => null,
      callback: () => null,
    });
  };

  const onDeleteLogo = () => {
    return catchSubmitErrors({
      submitMethod: () => deleteStoreLogo(),
      requestData: {},
      onValidationError: () => null,
      callback: () => null,
    });
  };

  const onDeleteBanner = () => {
    return catchSubmitErrors({
      submitMethod: () => deleteStoreBanner(),
      requestData: {},
      onValidationError: () => null,
      callback: () => null,
    });
  };

  const handleBadImageType = () =>
    toast.add({
      label: `Invalid image type or size! Image must be of type ${VALID_IMAGE_TYPES.map(
        (x) => x.replace(/image\//, '*.')
      )
        .join(', ')
        .replace(
          /, ([^,]*)$/,
          ' or $1'
        )} and smaller than ${FAMILIAR_MAX_IMAGE_SIZE}.`,
      variant: 'error',
    });

  return (
    <StoreImageCardContainer>
      <Card border="grays-light" height="200px" width="100%" flat>
        {isFetching || isUploading || !images ? (
          <Skeleton
            direction="column"
            animate={true}
            height="200px"
            width="100%"
          >
            <StoreLogoContainer>
              <Skeleton.Bone
                m={12}
                height="94px"
                width="94px"
                borderRadius="lg"
              />
            </StoreLogoContainer>
          </Skeleton>
        ) : (
          <>
            <StoreBannerImage
              primaryGalleryImage={images.primary_gallery_image}
            />

            <StoreLogoContainer>
              <PartnerLogo
                image={images.photo}
                size="lg"
                variant="store"
                name={images.name}
              />
            </StoreLogoContainer>
          </>
        )}
      </Card>
      <EditImageContainer>
        {canEditStore && (
          <EditImagePopover
            saveStoreImages={onSave}
            handleBadImageType={handleBadImageType}
            deleteStoreLogo={onDeleteLogo}
            deleteStoreBanner={onDeleteBanner}
            hasStoreLogo={!!images?.photo}
            hasStoreBanner={!!images?.primary_gallery_image}
          />
        )}
      </EditImageContainer>
    </StoreImageCardContainer>
  );
};

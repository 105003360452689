export class RuntimeError extends Error {
  override name = 'RuntimeErrorOccurred';
  public error: any;

  constructor(error: any) {
    super(
      ['Runtime Error', typeof error === 'string' ? error : undefined]
        .filter(Boolean)
        .join(': ')
    );
    this.error = error;
  }
}

import type { ReactNode } from 'react';

import type { PrepaymentProviders } from '@jane/shared-ecomm/providers';
import { useCheckout } from '@jane/shared-ecomm/providers';
import { Accordion } from '@jane/shared/components';
import { Box, Flex, useTabletMediaQuery } from '@jane/shared/reefer';

import type { PaymentOptions } from '../util/hooks';
import { PaymentMethods } from '../util/hooks';
import { useCheckoutPayments } from './CheckoutPaymentsProvider';
import { FakeRadio, PaymentsCard } from './checkoutPayments.styles';

const REQUIRE_LOG_IN = [
  PaymentMethods.hypur,
  PaymentMethods.paytender,
  PaymentMethods.stronghold,
];

interface CheckoutPaymentsAccordionItemProps {
  children?: ReactNode;
  header: ReactNode;
  id: PaymentOptions;
}

export const CheckoutPaymentsAccordionItem = ({
  children,
  header,
  id,
}: CheckoutPaymentsAccordionItemProps) => {
  const isMobile = useTabletMediaQuery({ width: 'max' });

  const {
    customer: { authenticated },
    guestCheckout: { preAuthSelection, setShowLoginModal, setPreAuthSelection },
  } = useCheckout();

  const { selectPaymentOption, selected } = useCheckoutPayments();

  const requiresLogin =
    !authenticated && (REQUIRE_LOG_IN as PaymentOptions[]).includes(id);
  const isSelected = selected === id || preAuthSelection === id;

  const handleSelectMethod = () => {
    if (requiresLogin) {
      selectPaymentOption(null);
      setPreAuthSelection(id as PrepaymentProviders);
      setShowLoginModal(true);
      return;
    }

    if (preAuthSelection !== 'janeGold') {
      setPreAuthSelection(null);
    }

    selectPaymentOption(id);
  };

  return (
    <PaymentsCard
      borderWidth="2px"
      mb={16}
      border={isSelected ? 'primary' : 'grays-ultralight'}
      width="100%"
      flat
    >
      <Accordion.Item id={id} expanded={isSelected && !requiresLogin}>
        <Accordion.Heading onClick={handleSelectMethod}>
          <Flex alignItems="center">
            <Box height={24} position="relative">
              <FakeRadio
                position="relative"
                height={24}
                width={24}
                selected={isSelected}
              >
                <Box
                  height={12}
                  width={12}
                  left={4}
                  top={4}
                  position="absolute"
                  background="grays-white"
                />
              </FakeRadio>
            </Box>

            <Box ml={16} width="100%">
              {header}
            </Box>
          </Flex>
        </Accordion.Heading>
        <Accordion.Content destroyOnClose={false}>
          {children && (
            <Box ml={isMobile ? 0 : 32} mt={16}>
              {children}
            </Box>
          )}
        </Accordion.Content>
      </Accordion.Item>
    </PaymentsCard>
  );
};

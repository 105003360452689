import styled from '@emotion/styled';
import { useMaskito } from '@maskito/react';
import type { ControllerRenderProps } from 'react-hook-form';

import type { BloomMainMenuForm } from '@jane/business-admin/types';
import { urlMask } from '@jane/business-admin/util';
import { Form } from '@jane/shared/reefer';

const UrlField = styled(Form.TextField)`
  div {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }

  &:focus-within {
    div {
      div:last-child {
        border-left: 1px solid #5e1ff0;
      }
    }
  }
`;

type PropType = {
  field:
    | ControllerRenderProps<
        BloomMainMenuForm,
        `menuItems.${number}.destination`
      >
    | ControllerRenderProps<
        BloomMainMenuForm,
        `menuItems.${number}.links.${number}.destination`
      >;
};

export const MainMenuUrlInput = ({ field }: PropType) => {
  const urlRef = useMaskito({ options: urlMask });
  return (
    <UrlField
      {...field}
      label=""
      defaultValue={field.value ?? ''}
      placeholder="Add URL"
      width="100%"
      ref={urlRef}
    />
  );
};

import styled from '@emotion/styled';
import { useContext, useMemo, useState } from 'react';

import {
  StaffFilterRole,
  StaffFilterStatus,
  StaffList,
  StaffMemberModal,
} from '@jane/business-admin/components';
import { useStoreStaff, useStores } from '@jane/business-admin/data-access';
import {
  useHasPermissions,
  useModalActionsWithTracking,
  useResultsById,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type {
  AbbreviatedStoreV2,
  StaffMember,
  StaffMemberWithIdAndStoreNames,
} from '@jane/business-admin/types';
import { ModalNames } from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import { Button, Flex, SearchField } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  ...spacing({ py: 40 }),
});

const Main = styled.div({
  width: '100%',
});

const HeaderWrapper = styled.div(({ theme }) => ({
  background: theme.colors.grays.white,
  ...spacing({ px: 64 }),
}));

export const StoreStaff = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const modalName = selectedId
    ? ModalNames.EditStaffMember
    : ModalNames.InviteStaffMember;
  const [filterNameEmail, setFilterNameEmail] = useState('');
  const [filterRole, setFilterRole] = useState<string[]>([]);
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.InviteStaffMember
  );
  const { storeId } = useContext(StoreDetailsContext);
  const { data: staffPayload } = useStoreStaff(storeId);
  const { data: storesPayload } = useStores();
  const storesById = useResultsById<AbbreviatedStoreV2>(storesPayload);
  const filteredByName = useMemo<StaffMember[]>(
    () =>
      (staffPayload?.staff ?? []).filter((staffMember: StaffMember) => {
        if (!filterNameEmail) return true;
        const search = filterNameEmail.toLowerCase();
        const { name = '', email = '' } = staffMember;
        return search
          ? name.toLowerCase().includes(search) ||
              email.toLowerCase().includes(search)
          : true;
      }),
    [staffPayload, filterNameEmail]
  );
  const staffMembers = useMemo<StaffMemberWithIdAndStoreNames[]>(() => {
    const storeName = (id?: string | number) =>
      storesById[id ?? 0]?.name?.trim() || '';
    const filteredStaff = filteredByName.filter((staffMember) => {
      const roleSet = new Set(filterRole);
      const roleMatches = roleSet.size ? roleSet.has(staffMember.role) : true;
      const shouldFilterStatus = filterStatus.length === 1;
      const statusValue = filterStatus[0] === 'registered';
      const registered = Boolean(staffMember?.registered);
      const statusMatches = shouldFilterStatus
        ? registered == statusValue
        : true;

      return roleMatches && statusMatches;
    });

    return filteredStaff.map<StaffMemberWithIdAndStoreNames>((staffMember) => {
      return {
        ...staffMember,
        id: staffMember.id ?? 0,
        store_names: [
          storeName(storeId),
          storeName(
            staffMember.store_ids.find(
              (id) => id.toString() !== storeId.toString()
            )
          ),
        ],
      };
    });
  }, [
    staffPayload?.staff,
    filteredByName,
    filterRole,
    filterStatus,
    storeId,
    storesById,
  ]);

  const selectedStaffMember = useMemo(() => {
    if (!selectedId) return null;
    return (
      staffMembers?.find((manager: StaffMember) => manager.id === selectedId) ??
      null
    );
  }, [selectedId, staffMembers]);

  const editStaffMember = (id: number) => {
    setSelectedId(id);
    openModal(ModalNames.EditStaffMember);
  };

  const openStaffInviteModal = () => {
    setSelectedId(null);
    openModal(ModalNames.InviteStaffMember);
  };

  const canInviteStaff = useHasPermissions([
    Permission.EditStaff,
    Permission.EditClientRole,
  ]);

  return (
    <Wrapper>
      <Main>
        <HeaderWrapper>
          <Flex justifyContent="space-between" width="100%" mb={40}>
            <Flex justifyContent="flex-start" gap={16}>
              <SearchField
                name="filter_name_email"
                placeholder="Search email or name"
                autoCapitalize="none"
                label="Search email or name"
                labelHidden
                defaultValue={filterNameEmail}
                isDebounced={false}
                onChange={setFilterNameEmail}
              />
              <StaffFilterRole
                staffMembers={filteredByName}
                onChange={setFilterRole}
                value={filterRole}
              />
              <StaffFilterStatus
                staffMembers={filteredByName}
                onChange={setFilterStatus}
                value={filterStatus}
              />
            </Flex>
            {canInviteStaff && (
              <Button
                variant="secondary"
                label="Invite"
                onClick={openStaffInviteModal}
              />
            )}
          </Flex>
        </HeaderWrapper>
        <Flex width="100%" flexDirection="column">
          <StaffList
            staffMembers={staffMembers}
            onRowClick={(id) => editStaffMember(id)}
          />
        </Flex>
        {modalOpen && (
          <StaffMemberModal
            open
            staffMember={selectedStaffMember}
            onClose={() => closeModal(modalName)}
          />
        )}
      </Main>
    </Wrapper>
  );
};

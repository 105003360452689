import { useMemo } from 'react';

import { useFetchAnyActiveJanePayIntegrations } from '@jane/business-admin/data-access';
import type { ManagerNotification } from '@jane/business-admin/types';

export const useShouldShowJanePayUpsell = (
  notification: ManagerNotification,
  storeId?: string
) => {
  const { data, isFetched } = useFetchAnyActiveJanePayIntegrations(storeId);

  const showJanePayUpsellCard = useMemo(() => {
    if (!notification || !isFetched) return false;

    const hasSeenJanePayUpsellNotification = notification.seen;
    const janePayActiveForAnyStore = data?.any_active_jane_pay_integrations;

    return !hasSeenJanePayUpsellNotification && !janePayActiveForAnyStore;
  }, [isFetched, notification, data]);

  return showJanePayUpsellCard;
};

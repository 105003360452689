import * as t from 'io-ts';

export const tStoreJanePrinter = t.interface({
  id: t.string,
  store_id: t.number,
  store_name: t.string,
  jane_printer_id: t.string,
});

export type StoreJanePrinter = t.TypeOf<typeof tStoreJanePrinter>;

export const tJanePrinter = t.interface({
  id: t.string,
  name: t.union([t.string, t.null]),
  enabled: t.boolean,
  mac_address: t.union([t.string, t.null]),
  delivery_order_checked_out: t.boolean,
  pickup_order_checked_out: t.boolean,
  curbside_order_checked_out: t.boolean,
  kiosk_order_checked_out: t.boolean,
  curbside_arrived: t.boolean,
  store_jane_printers: t.array(tStoreJanePrinter),
  username: t.string,
  number_of_queued_jobs: t.number,
});

export const tJanePrinters = t.union([t.null, t.array(tJanePrinter)]);

export type JanePrinter = t.TypeOf<typeof tJanePrinter>;

import type { ReservationMode, ReservationModeV2 } from '@jane/shared/models';

export const RESERVATION_MODES: ReservationModeV2[] = [
  'pickup',
  'curbside',
  'delivery',
];

export const CHECKOUT_AGREEMENT_RESERVATION_MODES: ReservationMode[] = [
  'pickup',
  'delivery',
];

export const WEEKEND_DAYS = ['saturday', 'sunday'];

export const WEEKDAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
];

export const ALL_DAYS = [WEEKEND_DAYS[1], ...WEEKDAYS, WEEKEND_DAYS[0]];

export const BODY_LINE_HEIGHT = 24;
export const MEDIUM_SKELETON_WIDTH = '100px';
export const LARGE_SKELETON_WIDTH = '150px';

export const MODAL_CARD_WIDTH = 868;

export const GEOFENCE_DISCLAIMER_TEXT =
  'If you do not configure specific values for lead times, windows, max orders per window, or intervals for a geofence, store level configurations will apply for that geofence.';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { StoreSchedule } from '@jane/business-admin/types';
import { parseApiTimezones } from '@jane/shared/util';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

export const today = dayjs();
export const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

interface ValidationParams {
  endDate: string;
  endOnStoreClose?: boolean;
  endTime?: string;
  startDate: string;
  startTime?: string;
  storeRetailSchedule?: StoreSchedule | null;
  timezone?: string;
}

const parseDateAndTime = (
  dateString: string,
  timezone?: string,
  time?: string
) => {
  const parserTemplate = time ? 'YYYY-MM-DD h:mm A' : 'YYYY-MM-DD';
  const combinedDateAndTime = [dateString, time].filter(Boolean).join(' ');

  return dayjs.tz(
    combinedDateAndTime,
    parserTemplate,
    timezone ? parseApiTimezones(timezone) : DEFAULT_TIME_ZONE
  );
};

export const convert24HourTimeTo12HourTime = (
  time: string | null | undefined
) => {
  if (!time) return '';
  if (time.endsWith('PM') || time.endsWith('AM')) return time;

  const split = time.split(':');
  const hours = parseInt(split[0], 10);
  const minutes = parseInt(split[1], 10);
  const suffix = hours >= 12 ? 'PM' : 'AM';
  const parsedHours = ((hours + 11) % 12) + 1;

  return `${parsedHours}:${minutes !== 0 ? minutes : '00'} ${suffix}`;
};

export const convert12HourTimeTo24HourTime = (
  time: string | null | undefined | boolean
) => {
  if (!time || time === true) return null;

  let [hour, minutes] = time.split(':');

  if (time.includes('PM') && hour !== '12') {
    hour = (parseInt(hour) + 12).toString(10);
  } else if (time.includes('AM') && hour === '12') {
    hour = '00';
  }

  const minutesInt = parseInt(minutes);

  if (minutesInt === 0) {
    minutes = '00';
  } else {
    minutes = minutesInt.toString(10);
  }

  return `${hour}:${minutes}`;
};

const dateMap = {
  '0': 'sunday_hours_end',
  '1': 'monday_hours_end',
  '2': 'tuesday_hours_end',
  '3': 'wednesday_hours_end',
  '4': 'thursday_hours_end',
  '5': 'friday_hours_end',
  '6': 'saturday_hours_end',
};

export const storeTimeForEndDateTime = (
  endDate: string,
  storeRetailSchedule?: StoreSchedule | null,
  timezone?: string
) => {
  const tempDate = parseDateAndTime(endDate, timezone);
  const mapped = dateMap[tempDate.day().toString() as keyof typeof dateMap];
  const retailEndTime = convert24HourTimeTo12HourTime(
    storeRetailSchedule?.[
      mapped as keyof typeof storeRetailSchedule
    ]?.toString()
  );

  return retailEndTime || '11:59 PM';
};

export const validateStartDateAndTime = ({
  endDate,
  endOnStoreClose,
  endTime,
  startDate,
  startTime,
  timezone,
  storeRetailSchedule,
}: ValidationParams): true | string => {
  if (!startDate || !endDate) return true;

  let parsedEndDate = null;

  if (endOnStoreClose) {
    const retailEndTime = storeTimeForEndDateTime(
      endDate,
      storeRetailSchedule,
      timezone
    );

    parsedEndDate = parseDateAndTime(endDate, timezone, retailEndTime);
  } else {
    parsedEndDate = parseDateAndTime(endDate, timezone, endTime);
  }

  const parsedStartDate = parseDateAndTime(startDate, timezone, startTime);

  return (
    parsedStartDate.isBefore(parsedEndDate) ||
    'Start date should be earlier than the end date'
  );
};

export const validateEndDateAndTime = ({
  endDate,
  endOnStoreClose,
  endTime,
  startDate,
  startTime,
  timezone,
  storeRetailSchedule,
}: ValidationParams): true | string => {
  if (!endDate) return true;

  let parsedEndDate = null;

  if (endOnStoreClose) {
    const retailEndTime = storeTimeForEndDateTime(
      endDate,
      storeRetailSchedule,
      timezone
    );

    parsedEndDate = parseDateAndTime(endDate, timezone, retailEndTime);
  } else {
    parsedEndDate = parseDateAndTime(endDate, timezone, endTime);
  }

  const parsedToday = parseDateAndTime(today.format('YYYY-MM-DD'), timezone);

  // end date is after today, regardless of whether or not there is a start date
  if (!parsedEndDate.isAfter(parsedToday)) {
    return 'End date should be later than the current date';
  }

  if (!startDate) return true;
  const parsedStartDate = parseDateAndTime(startDate, timezone, startTime);

  // end date is after start date
  if (parsedStartDate && !parsedEndDate.isAfter(parsedStartDate)) {
    return 'End date should be later than the start date';
  }

  return true;
};

export const validateTimeWindow = (
  endTime: string,
  startTime: string,
  variant: 'start' | 'end'
) => {
  if (!startTime || !endTime) return true;

  // since the "date" doesn't matter here, we're just using a
  // dummy date to compare times.
  const epochTime = '1970-01-01';
  const errorMessage =
    variant === 'end'
      ? 'End time should be later than start time'
      : 'Start time should be earlier than end time';

  const formattedStartTime = parseDateAndTime(
    epochTime,
    DEFAULT_TIME_ZONE,
    startTime
  );
  const formattedEndTime = parseDateAndTime(
    epochTime,
    DEFAULT_TIME_ZONE,
    endTime
  );

  return formattedStartTime.isBefore(formattedEndTime) || errorMessage;
};

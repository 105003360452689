import { useLocation } from 'react-router-dom';

import {
  ArchiveIcon,
  Button,
  CopyIcon,
  EditIcon,
  Flex,
  List,
  MoreIcon,
  Popover,
  Typography,
} from '@jane/shared/reefer';

import { ArchiveWithConfirm } from './ArchiveWithConfirm';

export const ActionsMenuCell = ({
  onArchiveSpecial,
  onEditStores,
  isArchived,
  isGlobal = false,
  id,
}: {
  id: number;
  isArchived?: boolean;
  isGlobal?: boolean;
  onArchiveSpecial: () => void;
  onEditStores?: () => void;
}) => {
  const { pathname } = useLocation();
  const duplicateUrl = `${pathname}/duplicate/${id}`;

  return (
    <Popover
      label="menu"
      alignment={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      target={<Button.Icon icon={<MoreIcon />} />}
    >
      {() => (
        <Popover.Content>
          <List label="special-row-actions-list">
            {isGlobal && (
              <List.Item ariaLabel="Edit Stores" onClick={onEditStores}>
                <Flex gap={8}>
                  <EditIcon />
                  <Typography>Edit Stores</Typography>
                </Flex>
              </List.Item>
            )}
            <List.Item to={duplicateUrl} ariaLabel="Duplicate">
              <Flex gap={8}>
                <CopyIcon />
                <Typography>Duplicate</Typography>
              </Flex>
            </List.Item>
            {!isArchived && (
              <ArchiveWithConfirm
                onArchiveSpecial={onArchiveSpecial}
                archiveButton={(onArchiveClick) => (
                  <List.Item ariaLabel="Archive" onClick={onArchiveClick}>
                    <Flex gap={8}>
                      <ArchiveIcon />
                      <Typography>Archive</Typography>
                    </Flex>
                  </List.Item>
                )}
              />
            )}
          </List>
        </Popover.Content>
      )}
    </Popover>
  );
};

import { useCallback, useReducer } from 'react';
import type { Reducer } from 'react';

import type { AbbreviatedMenuRow } from '@jane/business-admin/types';

// selectedRows
// dirtyState
// initialValues

/*
  set value
  reset
  set dirty
*/

type State = {
  isDirty: boolean;
  selectedRows: { [k: AbbreviatedMenuRow['id']]: AbbreviatedMenuRow };
};

const actionTypes = {
  select: 'select',
  deselect: 'deselect',
  reset: 'reset',
  selectAll: 'selectAll',
} as const;

type Actions =
  | { row: AbbreviatedMenuRow; type: typeof actionTypes.select }
  | { rowId: AbbreviatedMenuRow['id']; type: typeof actionTypes.deselect }
  | { type: typeof actionTypes.reset }
  | { rows: AbbreviatedMenuRow[]; type: typeof actionTypes.selectAll };

export const INITIAL_STATE: State = { isDirty: false, selectedRows: [] };

export const reducer: Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case actionTypes.select:
      return {
        ...state,
        isDirty: true,
        // todo check this vv
        selectedRows: {
          ...state.selectedRows,
          [action.row.id]:
            state.selectedRows['id' as unknown as number] || action.row,
        },
      };

    case actionTypes.deselect: {
      const { [action.rowId]: omit, ...selectedRows } = state.selectedRows;
      return {
        ...state,
        isDirty: true,
        selectedRows,
      };
    }

    case actionTypes.reset:
      return { ...state, isDirty: true, selectedRows: {} };

    case actionTypes.selectAll: {
      const newRowsData = action.rows.reduce<State['selectedRows']>(
        (acc, curr) => {
          acc[curr.id] = curr;

          return acc;
        },
        {}
      );
      const selectedRows = {
        ...state.selectedRows,
        ...newRowsData,
      };

      return {
        ...state,
        isDirty: true,
        selectedRows,
      };
    }
    default:
      return state;
  }
};

export const useSelectedMenuRows = (
  initialSelectedRows: AbbreviatedMenuRow[]
) => {
  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    selectedRows: initialSelectedRows.reduce<State['selectedRows']>(
      (acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      },
      {}
    ),
  });

  const select = useCallback((row: AbbreviatedMenuRow) => {
    dispatch({ type: actionTypes.select, row });
  }, []);

  const deselect = useCallback((rowId: AbbreviatedMenuRow['id']) => {
    dispatch({ type: actionTypes.deselect, rowId });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: actionTypes.reset });
  }, []);

  const selectAll = useCallback((rows: AbbreviatedMenuRow[]) => {
    dispatch({ type: actionTypes.selectAll, rows });
  }, []);

  return { ...state, select, deselect, reset, selectAll } as const;
};

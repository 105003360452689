import { useMutation, useQueryClient } from '@tanstack/react-query';

import type {
  SquareAuthorizationPayload,
  SquareAuthorizationUrlPayload,
} from '@jane/business-admin/types';
import { janeApiV2 } from '@jane/shared/data-access';

export const SQUARE_AUTHORIZATIONS_URL = '/business/square/authorizations';

const createSquareAuthorization = async (
  storeId: string
): Promise<SquareAuthorizationUrlPayload> => {
  const url = `${SQUARE_AUTHORIZATIONS_URL}/${storeId}`;
  const data = await janeApiV2.post<SquareAuthorizationUrlPayload>(url, {});
  return data;
};
export const useCreateSquareAuthorization = (storeId: string) => {
  return useMutation({
    mutationFn: () => createSquareAuthorization(storeId),
    onError: (error) => {
      console.error(error);
    },
  });
};

const updateSquareAuthorization = async (
  authenticityToken: string,
  code: string
): Promise<SquareAuthorizationPayload> => {
  const url = `${SQUARE_AUTHORIZATIONS_URL}`;
  const data = await janeApiV2.patch<SquareAuthorizationPayload>(url, {
    authenticity_token: authenticityToken,
    code: code,
  });
  return data;
};
export const useUpdateSquareAuthorization = (
  authenticityToken: string,
  code: string
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => updateSquareAuthorization(authenticityToken, code),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: (response) => {
      const storeId = response?.store_id.toString();
      queryClient.invalidateQueries(['stores', storeId]);
    },
  });
};

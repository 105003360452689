import { useEffect } from 'react';

import { useSaveStoreDiscountSettings } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { EventNames, ModalNames, track } from '@jane/business-admin/util';
import type { StoreDiscountSettings } from '@jane/shared/models';
import {
  Flex,
  Form,
  InfoIcon,
  Modal,
  Popover,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';

interface DiscountSettingsFormData {
  loyalty_points_stacking?: boolean;
  max_specials_per_cart?: number | null;
}

const maxSpecialsPerCartOptions = [
  { label: 'Unlimited', value: -1 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

const FORM_ERROR_NAME = 'specials-limits-error';

export const DiscountsModal = ({
  open,
  setOpen,
  storeDiscountSettings,
  storeId,
  storeName,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  storeDiscountSettings?: StoreDiscountSettings | null;
  storeId: string;
  storeName: string;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating specials limits settings. Please try again.'
  );

  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const formName = 'Specials limits settings form';
  const toast = useToast();

  const { mutateAsync: saveStoreDiscountSettings, isSuccess: saveSuccess } =
    useSaveStoreDiscountSettings(storeId);

  const onSubmit = async (data: DiscountSettingsFormData) => {
    // Special case for unlimited specials per cart
    if (Number(data.max_specials_per_cart) === -1) {
      data.max_specials_per_cart = null;
    }
    const submitMethod = () => {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.Discounts,
        changed_attributes: Object.keys(dirtyFields),
      });
      return saveStoreDiscountSettings({ discount_settings: data });
    };

    return catchSubmitErrors({
      submitMethod,
      requestData: data,
      onValidationError: (_) => {
        console.log('Not hit');
      },
    });
  };

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Specials limits settings updated',
        variant: 'success',
      });
      setOpen(false);
    }
  }, [saveSuccess, setOpen]);

  return (
    <ConfirmWrapperWithTracking
      setOpen={setOpen}
      open={open}
      hasChanges={isDirty}
      modalName={ModalNames.Discounts}
    >
      <Form.BaseForm
        name={formName}
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Specials stacking and limits"
          subtitle={storeName}
          actions={<Form.SubmitButton label="Save" variant="primary" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Typography variant="body-bold" mb={24}>
            Loyalty stacking
          </Typography>
          <Form.SwitchField
            defaultChecked={storeDiscountSettings?.loyalty_points_stacking}
            label="Allow specials to stack with loyalty reward redemptions"
            name={'loyalty_points_stacking'}
            mb={24}
          />
          <Modal.ContentDivider />
          <Flex flexDirection="row">
            <Typography variant="body-bold" mb={24} as="span">
              Specials limits
            </Typography>
            <Popover
              label="specials-stacking-info"
              openOn="hover"
              target={<InfoIcon size="sm" ml={8} mt={4} />}
              alignment={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              variant="info"
            >
              <Popover.Content label="Information about specials limits.">
                <Typography>
                  Jane will automatically give your customer the best
                  discount(s).
                </Typography>
              </Popover.Content>
            </Popover>
          </Flex>
          <Form.SelectField
            defaultValue={
              storeDiscountSettings?.max_specials_per_cart
                ? storeDiscountSettings?.max_specials_per_cart.toString()
                : '-1'
            }
            width="100%"
            name={`max_specials_per_cart`}
            label="Maximum number of specials per cart"
            options={maxSpecialsPerCartOptions}
            required
          />
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};

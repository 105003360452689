import { useEffect, useMemo, useState } from 'react';
import type {
  UseFormClearErrors,
  UseFormSetError,
  UseFormTrigger,
} from 'react-hook-form';

/**
 * Primarily for duplicates validation on a react-hook-form that is using a useFieldArray.
 * Useful for duplicates validation when there are multiple fields you'll need to compare across (i.e. day, time, order_limit)
 *
 * fieldArrayData: Should be the result of calling watch() on the fieldArray field
 *
 * mappingMethod: Consumes the data from fieldArrayData and should return a mapped string that can be used for comparison (i.e. {day, time, order_limit} turns into "day / time / order_limit")
 *
 * setError / clearErrors: Methods from useForm / useFormContext to disable / enable the submit button when there are validation errors
 */
export const useDuplicatesValidation = ({
  fieldArrayData,
  mappingMethod,
  setError,
  clearErrors,
  trigger,
}: {
  clearErrors: UseFormClearErrors<any>;
  fieldArrayData: any[];
  mappingMethod: (option: any) => string;
  setError: UseFormSetError<any>;
  trigger: UseFormTrigger<any>;
}) => {
  const [mappedOptions, setMappedOptions] = useState<string[]>([]);
  useEffect(() => {
    if (fieldArrayData) {
      setMappedOptions(fieldArrayData.map(mappingMethod));
    }
  }, [JSON.stringify(fieldArrayData)]);

  const duplicateIndexes = useMemo(() => {
    return mappedOptions.reduce<Set<number>>((prev, current, index) => {
      const foundDuplicate = mappedOptions.indexOf(current);
      if (foundDuplicate > -1 && foundDuplicate !== index) {
        prev.add(foundDuplicate);
        prev.add(index);
      }
      return prev;
    }, new Set());
  }, [mappedOptions]);

  useEffect(() => {
    if (duplicateIndexes.size) {
      // Disable submit button, error won't be shown to user
      setError('duplicate-error' as any, { message: 'duplicate error' });
    } else {
      clearErrors();
    }
    trigger('duplicate-error');
  }, [duplicateIndexes]);

  return { duplicateIndexes };
};

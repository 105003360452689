import type { JanePrinterStore } from '@jane/business-admin/types';
import { Flex, Tag, Typography } from '@jane/shared/reefer';
import type { FlexProps } from '@jane/shared/reefer';

interface Props extends FlexProps {
  store: JanePrinterStore;
}

export const StoreDetails = ({ store, ...flexProps }: Props) => {
  return (
    <Flex key={store.id} alignItems="center" {...flexProps}>
      <Tag
        label={store.recreational ? 'Rec' : 'Med'}
        color={store.recreational ? 'primary-dark' : 'palm-dark'}
        background={store.recreational ? 'primary-light' : 'palm-light'}
        mr={16}
      />

      <Flex flexDirection="column">
        <Typography variant="body-bold" data-testid="store-name">
          {store.name}
        </Typography>
        <Typography color="grays-mid">
          {store.address}, {store.city}, {store.state} {store.zip}
        </Typography>
        <Typography color="grays-mid">ID #{store.id}</Typography>
      </Flex>
    </Flex>
  );
};

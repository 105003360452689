export enum TipVariant {
  Dollar = 'dollar',
  Percentage = 'percentage',
}

export enum TipEnabledFor {
  Delivery = 'delivery',
  Pickup = 'pickup',
}

export const DEFAULT_TIP_AMOUNTS: Record<TipVariant, number[]> = {
  [TipVariant.Dollar]: [3, 5, 7],
  [TipVariant.Percentage]: [10, 15, 18],
};

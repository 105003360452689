import { useMemo } from 'react';

import type { NuProductType, ProductKind } from '@jane/shared/models';

type ProductTypeIdMap = Record<string, number>;

// Creates a map of "product_type:product_subtype" string keys and ID number values,
// along with a getter function to access them and automatically handle nulls.
export function useProductTypeIdMap(
  allProductTypes: NuProductType[] | undefined
) {
  const productTypeIdMap = useMemo(() => {
    if (!allProductTypes) return {} as ProductTypeIdMap;

    return allProductTypes.reduce(
      (map, { product_type, product_subtype, id }) => {
        const key = [product_type, product_subtype ?? 'all'].join('.');
        map[key] = id as number;
        return map;
      },
      {} as ProductTypeIdMap
    );
  }, [allProductTypes]);

  const size = useMemo(
    () => Object.keys(productTypeIdMap).length,
    [productTypeIdMap]
  );

  const getProductTypeId = (type: ProductKind, subtype: string | null) =>
    productTypeIdMap[[type, subtype ?? 'all'].join('.')];

  return { getProductTypeId, productTypeIdMap, size };
}

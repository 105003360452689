import * as t from 'io-ts';

import { tId } from './common';

const tStoreDismissalMessageRequired = t.interface({
  message: t.string,
});

const tStoreDismissalMessageOptional = t.partial({
  id: tId,
  _destroy: t.union([t.null, t.boolean]),
});

export const tStoreDismissalMessage = t.intersection([
  tStoreDismissalMessageRequired,
  tStoreDismissalMessageOptional,
]);

export type StoreDismissalMessage = t.TypeOf<typeof tStoreDismissalMessage>;

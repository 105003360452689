import type { Either } from 'fp-ts/Either';
import { fold, isLeft, mapLeft, right } from 'fp-ts/Either';
import { pipe } from 'fp-ts/pipeable';
import type * as T from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';

import { config } from '@jane/shared/config';
import { ValidationError } from '@jane/shared/models';

const decodeWith = <
  ApplicationType = any,
  EncodeTo = ApplicationType,
  DecodeFrom = unknown
>(
  type: T.Type<ApplicationType, EncodeTo, DecodeFrom>,
  input: DecodeFrom,
  url: string
) => {
  let validation = type.decode(input);

  // Copied from: libs/monolith/shared/src/lib/lib/loadable.ts
  if (isLeft(validation)) {
    const error = new Error(
      [
        PathReporter.report(validation),
        '█',
        `${url}`,
        '█',
        'Received:',
        JSON.stringify(input, null, 2),
      ].join('\n')
    );

    validation = right(input as any);
    console.error(error);
  }

  return pipe(
    validation,
    mapLeft((errors) => new ValidationError(errors, input))
  );
};

export const throwValidationErrorOrReturnValue = <T>(
  value: Either<ValidationError, T>
) =>
  fold<ValidationError, T, T>(
    (error) => {
      throw error;
    },
    (response) => response
  )(value);

export const fetchWithDecode = async (
  request: Promise<any>,
  type: T.Type<any>,
  url: string
) => {
  const response = await request;

  if (config.nodeEnv === 'production') {
    return response;
  }

  const data = decodeWith(type, response, url);

  return throwValidationErrorOrReturnValue(data);
};

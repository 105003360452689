/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Bell24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Bell 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M11 1a1 1 0 1 1 2 0v1h.05A6.95 6.95 0 0 1 20 8.95v1.495c0 2.318.686 4.584 1.972 6.513.867 1.3-.065 3.042-1.628 3.042H16a4 4 0 0 1-8 0H3.656c-1.563 0-2.495-1.742-1.628-3.042A11.74 11.74 0 0 0 4 10.445V8.95A6.95 6.95 0 0 1 10.95 2H11V1Zm-1 19a2 2 0 1 0 4 0h-4Zm2-16h-1.05A4.95 4.95 0 0 0 6 8.95v1.495A13.74 13.74 0 0 1 3.737 18h16.526A13.74 13.74 0 0 1 18 10.445V8.95A4.95 4.95 0 0 0 13.05 4H12Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Bell24,
  sm: Bell24,
  lg: Bell24,
  xl: Bell24,
  xxl: Bell24,
  xxxl: Bell24,
};

export const BellIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'bell-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

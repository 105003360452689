import styled from '@emotion/styled';

import { Form } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

export const SwitchWithBorder = styled(Form.SwitchField)(({ theme }) => ({
  border: '1px solid',
  ...spacing({ px: 16, py: 12 }),
  borderRadius: theme.borderRadius.sm,
  borderColor: theme.colors.grays.light,
}));

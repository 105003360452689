import { useMemo } from 'react';

import type { StaffMember } from '@jane/business-admin/types';
import {
  Button,
  CheckboxField,
  ChevronDownIcon,
  List,
  Popover,
} from '@jane/shared/reefer';

export const StaffFilterStatus = ({
  onChange,
  staffMembers = [],
  value,
}: {
  onChange: (value: string[]) => void;
  staffMembers?: StaffMember[];
  value: string[];
}) => {
  const availableStatuses = useMemo(
    () =>
      staffMembers.reduce<Set<boolean>>((result, staffMember) => {
        result.add(Boolean(staffMember.registered));
        return result;
      }, new Set()),
    [staffMembers]
  );
  const handleChange = (status: string, selected: boolean) => {
    const statuses = new Set(value);

    if (selected) {
      statuses.add(status);
    } else {
      statuses.delete(status);
    }

    onChange(Array.from(statuses));
  };

  return (
    <Popover
      label="Status"
      target={
        <Button
          label="Status"
          variant="tertiary"
          endIcon={<ChevronDownIcon size="sm" />}
        />
      }
    >
      <Popover.Content label="Status">
        <List label="staff-statuses">
          {availableStatuses.has(true) && (
            <List.Item>
              <CheckboxField
                label="Registered"
                name="filter_status_registered"
                onChange={(checked) => handleChange('registered', checked)}
                defaultChecked={value.includes('registered')}
              />
            </List.Item>
          )}
          {availableStatuses.has(false) && (
            <List.Item>
              <CheckboxField
                label="Unregistered"
                name="filter_status_unregistered"
                onChange={(checked) => handleChange('unregistered', checked)}
                defaultChecked={value.includes('unregistered')}
              />
            </List.Item>
          )}
        </List>
      </Popover.Content>
    </Popover>
  );
};

import * as t from 'io-ts';

export const tOwnershipIdentification = t.interface({
  id: t.number,
  bipoc: t.boolean,
  lgbtq: t.boolean,
  veteran: t.boolean,
  woman: t.boolean,
});
export type OwnershipIdentification = t.TypeOf<typeof tOwnershipIdentification>;

import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type {
  ColumnDef,
  RowSelectionState,
  SortingState,
  Table as TanstackTable,
} from '@tanstack/react-table';
import omit from 'lodash/omit';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import {
  useArchiveGlobalSpecial,
  useFetchGlobalSpecials,
  useStores,
  useToggleGlobalSpecialEnabled,
  useUpdateGlobalSpecial,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useDebouncedTrack,
  useLoadNextPageOnScroll,
  useModalActionsWithTracking,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import { GlobalSpecialsTableContext } from '@jane/business-admin/providers';
import type { AbbreviatedGlobalSpecialsV2 } from '@jane/business-admin/types';
import { SpecialStatus } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  SearchSubjects,
  blankArrayOfLength,
  normalizePath,
  specialsSortParams,
  track,
} from '@jane/business-admin/util';
import type { FilterNames } from '@jane/business-admin/util';
import { StoreSelectModal } from '@jane/shared-b2b/components';
import {
  Box,
  Button,
  Flex,
  Form,
  Link,
  Skeleton,
  useForm,
} from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';
import type { Id } from '@jane/shared/types';

import { EmptyState } from '../specials/EmptyStates';
import { SpecialStatusFilter } from '../specials/SpecialStatusFilter';
import { SpecialTypeFilter } from '../specials/SpecialTypeFilter';
import { buildGlobalSpecialsColumns } from './globalSpecialsColumns';

const TableWrapper = styled.div({
  overflowX: 'auto',
  height: 'calc(100vh - 193px)',
});

const StyledTable = styled(Table)({
  overflowY: 'auto',
  // Avoids 1px of text showing up when user scrolls the table
  marginTop: '-1px',
});

const ROW_HEIGHT = '72';
const StyledRow = styled(Table.Row)(
  {
    'td:last-child': {
      overflow: 'visible',
    },
  },
  ({ useDefaultCursor }: { useDefaultCursor?: boolean }) => ({
    height: `${ROW_HEIGHT}px`,
    cursor: useDefaultCursor ? 'default' : 'pointer',
  })
);

const length10Array = blankArrayOfLength(10);

const LoadingRow = ({
  index = 0,
  table,
}: {
  index?: number;
  table: TanstackTable<any>;
}) => (
  <StyledRow key={`${index}-row-loading`}>
    {table.getAllColumns().map((column) => (
      <Table.Cell key={`${column.id}-loading-cell`}>
        <Skeleton animate>
          <Skeleton.Bone />
        </Skeleton>
      </Table.Cell>
    ))}
  </StyledRow>
);

interface GlobalSpecialsTableProps {
  onEditSpecial?: (special: Partial<AbbreviatedGlobalSpecialsV2>) => void;
  openModal: ({ id }: { id?: Id }) => void;
  setBulkEditModalOpen: () => void;
}

export const GlobalSpecialsTable = ({
  onEditSpecial,
  openModal,
  setBulkEditModalOpen,
}: GlobalSpecialsTableProps) => {
  const { pathname } = useLocation();
  const { id = '' } = useParams<'id'>();
  const [searchParams] = useSearchParams();
  const [statusFilter, setStatusFilter] = useState<SpecialStatus>(
    (searchParams.get('status') as SpecialStatus) || SpecialStatus.live
  );

  const {
    bulkEditModalOpen,
    setBulkSelectedSpecials,
    columnFilters,
    setColumnFilters,
  } = useContext(GlobalSpecialsTableContext);

  const { data: storesData, isFetching: isFetchingStores } = useStores();
  const trackSearch = useDebouncedTrack(1000);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedSpecials, setSelectedSpecials] = useState<RowSelectionState>(
    {}
  );
  const formMethods = useForm({
    defaultValues: {
      query: columnFilters.find(({ id }) => id === 'title')?.value || '',
    },
  });

  const filterParams = useMemo(() => {
    return Object.fromEntries(
      columnFilters
        .filter(({ id }) => ['query', 'title', 'special_type'].includes(id))
        .map(({ id, value }) => {
          if (id === 'title') {
            return ['query', value];
          } else if (typeof value === 'string') {
            return [id, value];
          }

          return [id, (value as string[]).join(',')];
        })
    ) as Record<string, string | undefined>;
  }, [JSON.stringify(columnFilters)]);

  const showResultCounts = useMemo(
    () =>
      !!Object.values(filterParams).find((value) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }

        return !!value;
      }),
    [JSON.stringify(filterParams)]
  );

  const loadNextRef = useRef(null);

  const {
    data,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useFetchGlobalSpecials({
    status: statusFilter,
    ...filterParams,
    ...specialsSortParams(sorting),
  });

  const {
    mutateAsync: toggleSpecialEnabled,
    isLoading: toggleEnabledLoading,
    isSuccess: toggleEnabledSuccess,
    isError: toggleEnabledError,
  } = useToggleGlobalSpecialEnabled();

  useMutationStatusToasts({
    isMutating: toggleEnabledLoading,
    isSuccess: toggleEnabledSuccess,
    isError: toggleEnabledError,
    successMessage: 'Special successfully toggled',
    errorMessage: 'Error toggling special',
  });

  // Could be from updating special or bulk
  const isMutating = useIsMutating();

  const isFetchingFirstPage = useMemo(
    () =>
      isMutating > 0 ||
      toggleEnabledLoading ||
      (isFetching && !isFetchingNextPage),
    [isMutating, isFetching, isFetchingNextPage, toggleEnabledLoading]
  );

  const specialData: AbbreviatedGlobalSpecialsV2[] = useMemo(
    () => data?.pages?.flatMap((page) => page.specials) ?? [],
    [JSON.stringify(data)]
  );

  const countsByStatus: Record<SpecialStatus, number> | undefined = useMemo(
    () => data?.pages?.[0]?.meta?.counts_by_status,
    [JSON.stringify(data?.pages?.[0]?.meta?.counts_by_status)]
  );

  const allIds = useMemo(
    () =>
      (data?.pages?.[0]?.meta?.all_ids || []).reduce<RowSelectionState>(
        (previousValue, id) => ({
          ...previousValue,
          [id.toString()]: true,
        }),
        {}
      ),
    [JSON.stringify(data?.pages?.[0]?.meta?.all_ids)]
  );

  const {
    mutateAsync: archiveSpecial,
    isLoading: archiveSpecialLoading,
    isSuccess: archiveSpecialSuccess,
    isError: archiveSpecialError,
  } = useArchiveGlobalSpecial();

  useMutationStatusToasts({
    isMutating: archiveSpecialLoading,
    isSuccess: archiveSpecialSuccess,
    isError: archiveSpecialError,
    successMessage: 'Special successfully archived',
    errorMessage: 'Error archiving special',
  });

  const catchSubmitErrors = useCatchErrorsWithManager('');

  const onArchiveSpecial = (id: number) => {
    catchSubmitErrors({
      submitMethod: () => archiveSpecial({ specialId: String(id) }),
      requestData: {},
      // Error handled by toasts
      callback: () => null,
      onValidationError: () => null,
    });
  };

  const hasArchivedFilter = useMemo(
    () => statusFilter === SpecialStatus.archived,
    [statusFilter]
  );
  const hasUpcomingFilter = useMemo(
    () => statusFilter === SpecialStatus.upcoming,
    [statusFilter]
  );

  const totalRowCount = data?.pages?.[0]?.meta?.total ?? 0;

  const [selectedStores, setSelectedStores] = useState<string[]>([]);
  const [specialToUpdate, setSpecialToUpdate] =
    useState<Partial<AbbreviatedGlobalSpecialsV2>>();

  const onEditStores = (special: Partial<AbbreviatedGlobalSpecialsV2>) => {
    setSpecialToUpdate(special);

    if (special.stores && special.stores.length > 0) {
      const selectedStoresReduced = special.stores.reduce(
        (acc: string[], store) => {
          acc.push(StoreIds.asString(store.id));
          return acc;
        },
        []
      );
      setSelectedStores(selectedStoresReduced);
    }

    setSelectStoresModalOpen();
  };

  const columns: ColumnDef<AbbreviatedGlobalSpecialsV2>[] = useMemo(
    () =>
      buildGlobalSpecialsColumns({
        isFetched,
        sorting,
        toggleSpecialEnabled,
        totalRowCount,
        onArchiveSpecial,
        onEditStores,
        bulkEditModalOpen,
      }),
    [isFetched, sorting, totalRowCount, bulkEditModalOpen]
  );

  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >({});

  const table = useReactTable({
    data: specialData,
    columns,
    state: {
      columnFilters,
      columnVisibility,
      sorting,
      rowSelection: selectedSpecials,
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onRowSelectionChange: setSelectedSpecials,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: true,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString() || '0',
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
  });

  const currentSelections = table.getState().rowSelection;

  useEffect(() => {
    setBulkSelectedSpecials(
      Object.keys(currentSelections).map((special_id) => Number(special_id))
    );
  }, [currentSelections]);

  const titleColumn = useMemo(() => table?.getColumn('title'), [table]);

  const specialTypeColumn = useMemo(
    () => table?.getColumn('special_type'),
    [table]
  );

  const {
    modalOpen: selectStoresModalOpen,
    openModal: setSelectStoresModalOpen,
    closeModal: setSelectStoresModalClose,
  } = useModalActionsWithTracking(ModalNames.GlobalSpecialsSelectStores);

  const { mutateAsync: updateSpecial } = useUpdateGlobalSpecial(
    specialToUpdate?.id as Id
  );

  const onToggleAllStores = (selected: boolean) => {
    track({
      event: selected
        ? EventNames.SelectedCheckbox
        : EventNames.DeselectedCheckbox,
      checkbox_label: selected ? 'Select all stores' : 'Deselect all stores',
      modal_name: ModalNames.GlobalSpecialsSelectStores,
    });
  };

  const onToggleStore = (storeId: string, selected: boolean) => {
    track({
      event: selected
        ? EventNames.SelectedCheckbox
        : EventNames.DeselectedCheckbox,
      checkbox_label: `${selected ? 'Select' : 'Deselect'} store ID ${storeId}`,
      modal_name: ModalNames.GlobalSpecialsSelectStores,
    });
  };

  const onFilterChange = (filterName: FilterNames, value?: string) => {
    if (!value) {
      track({
        event: EventNames.DeselectedFilter,
        filter_name: filterName,
        all_selected_values: [],
        deselected_value: 'all',
        modal_name: ModalNames.GlobalSpecialsSelectStores,
        url: normalizePath(pathname, id),
      });
      return;
    }

    track({
      event: EventNames.SelectedFilter,
      filter_name: filterName,
      all_selected_values: [value],
      selected_value: value,
      modal_name: ModalNames.GlobalSpecialsSelectStores,
      url: normalizePath(pathname, id),
    });
  };

  const StoreIds = {
    asString: (id: number | string) => (typeof id === 'number' ? `${id}` : id),
    asNumber: (id: number | string) =>
      typeof id === 'string' ? parseInt(id, 10) : id,
  };

  const onSelectStores = (storeIds: number[]) => {
    const selectedStores: any =
      storesData
        ?.filter(
          (store: any) =>
            store.id !== undefined &&
            storeIds.includes((StoreIds.asNumber = store.id))
        )
        .map((store: any) => ({
          ...omit(store, [
            'allow_group_specials',
            'cart_limit_policy',
            'photo',
          ]),
          can_edit: true,
          enabled: true,
        })) || [];

    updateSpecial({ ...specialToUpdate, stores: selectedStores } as any);

    setSelectStoresModalClose();
  };

  useLoadNextPageOnScroll(
    {
      fetchNextPage,
      triggerRef: loadNextRef,
      rootMargin: '300px',
    },
    [data]
  );

  // Hide start/end columns and display 'archived on' column when status is "archived"
  // Display 'starts in' column with countdown on Upcoming tab
  useEffect(() => {
    setColumnVisibility({
      archived_at: hasArchivedFilter,
      start_date: !hasArchivedFilter,
      end_date: !hasArchivedFilter,
      next_occurrence: hasUpcomingFilter,
    });
  }, [hasArchivedFilter, hasUpcomingFilter, table]);

  return (
    <Box
      width="100%"
      mt={bulkEditModalOpen ? 40 : 0}
      ariaLabel="global-specials-table"
    >
      <Form.BaseForm
        name="specials"
        formMethods={formMethods}
        onSubmit={() => null}
      >
        <Flex justifyContent="space-between" mb={24} mx={64}>
          <Flex minWidth="320px" gap={16}>
            <Form.SearchField
              onChange={(value) => {
                titleColumn?.setFilterValue(value);
              }}
              placeholder="Search specials & promo codes"
              label="Search specials & promo codes"
              labelHidden
              name="query"
              isDebounced
              debounceDelay={750}
              width={325}
            />
            <SpecialTypeFilter column={specialTypeColumn} />
          </Flex>
          <Flex justifyContent="flex-end" gap={16}>
            {bulkEditModalOpen ? (
              <>
                <Link onClick={() => table.setRowSelection(allIds)}>
                  Select all
                </Link>
                <Link onClick={() => table.setRowSelection({})}>
                  Select none
                </Link>
              </>
            ) : (
              <>
                {searchParams.get('status') !== 'archived' && (
                  <Button
                    variant="secondary"
                    label="Bulk edit"
                    onClick={() => setBulkEditModalOpen()}
                  />
                )}
                <Button
                  variant="primary"
                  label="Create special"
                  onClick={() => openModal({})}
                />
              </>
            )}
          </Flex>
        </Flex>
        {!bulkEditModalOpen && (
          <SpecialStatusFilter
            isFetching={isFetching}
            value={statusFilter}
            onChange={setStatusFilter}
            showResultCounts={showResultCounts}
            countsByStatus={countsByStatus}
          />
        )}
        <TableWrapper>
          {!isFetchingFirstPage && Object.keys(allIds).length === 0 ? (
            <EmptyState />
          ) : (
            <StyledTable freezeFirstColumn scrollable>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeaderCell key={header.id}>
                        {header.isPlaceholder ? null : (
                          <Box
                            minWidth={
                              header.column.columnDef.id === 'title'
                                ? '400px'
                                : '100px'
                            }
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Box>
                        )}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>

              <Table.Body>
                {isFetchingFirstPage
                  ? length10Array.map((n) => (
                      <LoadingRow key={n} index={0} table={table} />
                    ))
                  : table.getRowModel().rows.map((row) => (
                      <StyledRow
                        key={row.id}
                        useDefaultCursor={false}
                        onClick={(event: React.BaseSyntheticEvent) => {
                          if (bulkEditModalOpen) {
                            row.toggleSelected();
                          } else {
                            // HACK: Prevent the edit modal from opening if the click event came from the context
                            // menu cell's popover target button. Working with Reefer team to find a better solution
                            if (event.target.parentNode.role === 'button') {
                              return;
                            }
                            onEditSpecial && onEditSpecial(row.original);
                          }
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <Table.Cell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        ))}
                      </StyledRow>
                    ))}
              </Table.Body>
              {isFetched && isSuccess ? (
                <>
                  {hasNextPage && (
                    <Table.Body>
                      <LoadingRow table={table} />
                    </Table.Body>
                  )}
                  <tfoot ref={loadNextRef}></tfoot>
                </>
              ) : (
                <></>
              )}
            </StyledTable>
          )}
        </TableWrapper>
      </Form.BaseForm>
      {selectStoresModalOpen && (
        <StoreSelectModal
          onSearchCallback={(query: string, successful?: boolean) =>
            trackSearch({
              event: EventNames.Search,
              arguments: query,
              subject: SearchSubjects.Stores,
              successful,
            })
          }
          storesData={storesData || []}
          isFetchingStores={isFetchingStores}
          selectedStoreIds={selectedStores}
          onSubmit={onSelectStores}
          closeModal={() => setSelectStoresModalClose()}
          onFilterChange={onFilterChange}
          onToggleAll={onToggleAllStores}
          onToggleStore={onToggleStore}
        />
      )}
    </Box>
  );
};

import startCase from 'lodash/startCase';
import { useContext, useMemo } from 'react';

import { useStoreSettings } from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { ModalNames, TipVariant } from '@jane/business-admin/util';
import { Flex, List, Skeleton, Typography } from '@jane/shared/reefer';

import { CardSection } from '../../../../../CardSection';
import { EditableCard } from '../../../../../EditableCard';
import { CheckoutOptionsModal } from './CheckoutOptionsModal';

const CARD_TITLE = 'Checkout options';

const SkeletonCard = () => {
  return (
    <EditableCard title={CARD_TITLE} onEdit={() => null}>
      <Skeleton>
        <Skeleton.Bone height={16} width={75} mb={16} />
        <Skeleton.Bone height={8} width="20%" mb={8} />
        <Skeleton.Bone height={8} width="20%" mb={8} />

        <Skeleton.Bone height={16} width={75} mt={24} mb={16} />
        <Skeleton.Bone height={8} width="75%" mb={12} />

        <Flex mt={24}>
          <Flex width="50%" flexDirection="column">
            <Skeleton.Bone height={16} width={75} mb={16} />
            <Skeleton.Bone height={8} width="50%" />
          </Flex>
          <Flex width="50%" flexDirection="column">
            <Skeleton.Bone height={16} width={75} mb={16} />
            <Skeleton.Bone height={8} width="50%" />
          </Flex>
        </Flex>
      </Skeleton>
    </EditableCard>
  );
};

export const CheckoutOptionsCard = () => {
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.CheckoutOptions
  );
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const { data: storeSettings, isFetching } = useStoreSettings(storeId);

  const {
    store,
    store_tip_settings,
    medical_store_documentation_requirement,
    id_upload_prohibited,
  } = storeSettings || {};
  const tip_settings = store_tip_settings?.at(0);

  const tip_amounts = useMemo(() => {
    if (!tip_settings) return;
    const prefix = tip_settings.variant === TipVariant.Dollar ? '$' : '';
    const postfix = tip_settings.variant === TipVariant.Percentage ? '%' : '';
    return tip_settings.custom_amounts
      .map((amount) => `${prefix}${amount}${postfix}`)
      .concat('Customer input')
      .join(', ');
  }, [tip_settings]);

  const hasAnyRequirements = useMemo(
    () =>
      store?.birth_date_required ||
      store?.delivery_id_required ||
      store?.pickup_id_required ||
      store?.birth_date_required ||
      (medical_store_documentation_requirement &&
        medical_store_documentation_requirement.require_medical_id !== 'none'),
    [medical_store_documentation_requirement, store]
  );

  const tippingAllowed = useMemo(() => {
    if (storeSettings?.store.country_code === 'US') {
      return Boolean(
        storeSettings?.stronghold_integration?.enabled ||
          storeSettings?.jane_pay_integration?.enabled
      );
    } else if (storeSettings?.store.country_code === 'CA') {
      return Boolean(
        storeSettings?.moneris_integration?.enabled ||
          storeSettings?.payfirma_integration?.enabled
      );
    }

    return false;
  }, [
    storeSettings?.stronghold_integration?.enabled,
    storeSettings?.jane_pay_integration?.enabled,
    storeSettings?.moneris_integration?.enabled,
    storeSettings?.payfirma_integration?.enabled,
    storeSettings?.store.country_code,
  ]);

  const reservationModeIdRequirement = useMemo(() => {
    if (store?.delivery_id_required && store?.pickup_id_required) {
      return 'Delivery and Pickup';
    } else if (store?.delivery_id_required) {
      return 'Delivery';
    } else {
      return 'Pickup';
    }
  }, [store?.delivery_id_required, store?.pickup_id_required]);

  return (
    <>
      {isFetching ? (
        <SkeletonCard />
      ) : (
        <EditableCard
          title={CARD_TITLE}
          onEdit={() => openModal()}
          disabled={!canEditStore}
        >
          <CardSection label="Requirements" mb={24}>
            {hasAnyRequirements ? (
              <List label="checkout-requirements">
                {!id_upload_prohibited &&
                  (store?.delivery_id_required ||
                    store?.pickup_id_required) && (
                    <List.Item px={0} py={0}>
                      <Typography>
                        Reservation mode ID requirement:{' '}
                        {startCase(reservationModeIdRequirement)}
                      </Typography>
                    </List.Item>
                  )}
                {store?.birth_date_required && (
                  <List.Item px={0} py={0}>
                    <Typography>Birthdate</Typography>
                  </List.Item>
                )}
                {!id_upload_prohibited &&
                  medical_store_documentation_requirement &&
                  medical_store_documentation_requirement.require_medical_id !==
                    'none' && (
                    <List.Item px={0} py={0}>
                      <Typography>
                        Medical store documentation:{' '}
                        {startCase(
                          medical_store_documentation_requirement.require_medical_id
                        )}
                      </Typography>
                    </List.Item>
                  )}
              </List>
            ) : (
              <Typography>N/A</Typography>
            )}
          </CardSection>

          {store?.show_paperwork && (
            <CardSection label="Reminder to customer" mb={24}>
              <Typography>
                {store?.customer_paperwork ? store.customer_paperwork : 'N/A'}
              </Typography>
            </CardSection>
          )}

          {tippingAllowed && (
            <Flex>
              <CardSection label="Tipping" width="50%">
                <Typography>
                  {tip_settings ? (
                    <>Enabled for {tip_settings.enabled_for}</>
                  ) : (
                    'N/A'
                  )}
                </Typography>
              </CardSection>

              <CardSection label="Accept tips in" width="50%">
                <Typography>{tip_amounts ? tip_amounts : 'N/A'}</Typography>
              </CardSection>
            </Flex>
          )}
        </EditableCard>
      )}

      <CheckoutOptionsModal
        open={modalOpen}
        onClose={() => closeModal()}
        store={store}
        tipSettings={tip_settings}
        medicalDocumentationSettings={medical_store_documentation_requirement}
        id_upload_prohibited={id_upload_prohibited}
        tippingAllowed={tippingAllowed}
      />
    </>
  );
};

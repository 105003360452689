import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, useFormContext } from '@jane/shared/reefer';
import { QUALIFIED_GROUP_TYPE_MAP, WEIGHT_OPTIONS } from '@jane/shared/util';

import { BulkPricing } from './special_types/BulkPricing';
import { Bundle } from './special_types/Bundle';
import { CartTotal } from './special_types/CartTotal';
import { QualifiedGroup } from './special_types/QualifiedGroup';

// Dev Note: Product has no subset of critieria so its left out of this list
const SPECIAL_TYPE_DATA: SpecialTypeProps = {
  bulk_pricing: {
    name: 'conditions.bulk_pricing',
    options: Object.values(WEIGHT_OPTIONS).flatMap((value) => {
      if (value.id === 'each') {
        return [];
      }
      return { ...value, value: value.id };
    }),
  },
  cart_total: {
    name: 'conditions.cart_total',
  },
  bundle: {
    name: 'conditions.bundle',
  },
  qualified_group: {
    name: 'conditions.qualified_group',
    options: Object.entries(QUALIFIED_GROUP_TYPE_MAP).map(([key, value]) => {
      return { label: value, value: key };
    }),
  },
};

export const SpecialTypeCriteria = ({
  isCreateMode = false,
}: {
  isCreateMode: boolean;
}) => {
  const { watch, getValues } = useFormContext();
  const specialTypeWatch = watch('special_type');
  const specialTypeValue = getValues('special_type');

  const specialType = specialTypeWatch || specialTypeValue;

  return (
    <Flex width={'100%'}>
      {specialType === 'bulk_pricing' ? (
        <BulkPricing
          name={SPECIAL_TYPE_DATA[specialType].name}
          options={SPECIAL_TYPE_DATA[specialType].options}
          isCreateMode={isCreateMode}
        />
      ) : null}
      {specialType === 'cart_total' ? (
        <CartTotal
          name={SPECIAL_TYPE_DATA[specialType].name}
          isCreateMode={isCreateMode}
        />
      ) : null}
      {specialType === 'qualified_group' ? (
        <QualifiedGroup
          name={SPECIAL_TYPE_DATA[specialType].name}
          options={SPECIAL_TYPE_DATA[specialType].options}
          isCreateMode={isCreateMode}
        />
      ) : null}
      {specialType === 'bundle' ? (
        <Bundle
          name={SPECIAL_TYPE_DATA[specialType].name}
          isCreateMode={isCreateMode}
        />
      ) : null}
    </Flex>
  );
};

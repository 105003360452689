import get from 'lodash/get';

/**
 * Utility to use for React dependency array where we need to keep track of any rule changes (instead of JSON.stringify)
 * @param rules Special rules
 * @returns Consolidated string of rules
 */
export const convertRulesToString = (rules?: any) => {
  if (!rules) {
    return '';
  }

  const excludes = get(rules, 'excludes[0]', {});
  const includes = get(rules, 'includes[0]', {});

  const compiledRules = [];

  if (rules['product_threshold']) {
    compiledRules.push(Object.values(rules['product_threshold']).join(','));
  }

  compiledRules.push('includes');
  for (const entry in includes) {
    if (entry === 'kinds') {
      includes[entry].forEach((kind: any) => {
        compiledRules.push(Object.values(kind).join(','));
      });
    } else {
      compiledRules.push(Object.values(includes[entry]).join(','));
    }
  }

  compiledRules.push('excludes');
  for (const entry in excludes) {
    if (entry === 'kinds') {
      excludes[entry].forEach((kind: any) => {
        compiledRules.push(Object.values(kind).join(','));
      });
    } else {
      compiledRules.push(Object.values(excludes[entry]).join(','));
    }
  }

  return compiledRules.join(':');
};

import { hex } from 'wcag-contrast';

export const contrastingColor = (color: string, colorsToCompare: string[]) =>
  colorsToCompare.reduce<{
    contrast: number;
    contrastingColor?: string;
  }>(
    (prevValue, contrastingColor) => {
      const newContrast = {
        contrast: hex(color, contrastingColor),
        contrastingColor: contrastingColor,
      };
      return newContrast.contrast > prevValue.contrast
        ? newContrast
        : prevValue;
    },
    {
      contrast: 0,
      contrastingColor: undefined,
    }
  ).contrastingColor;

import styled from '@emotion/styled';

import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const Grid = styled.div({
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: 'repeat(2, 1fr)',
  ...spacing({ mt: 16 }),
  '@media (min-width: 540px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  [mediaQueries.tablet('min')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@media (min-width: 1250px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});

export const GridItem = styled.div({
  gridColumn: 'span 2',
  ...spacing({ mb: 16 }),
  '@media (min-width: 540px)': {
    gridColumn: 'span 1',
  },
  [mediaQueries.tablet('min')]: {
    gridColumn: 'span 2',
  },
  '@media (min-width: 1250px)': {
    gridColumn: 'span 1',
  },
});

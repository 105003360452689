import { Modal } from '@jane/shared/reefer';

export const PrinterSupportModal = ({
  open,
  setOpen,
  subtitle,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  subtitle: string;
}) => {
  return (
    <Modal open={open} onRequestClose={() => setOpen(false)}>
      <Modal.Header title="Printer support" subtitle={subtitle} />
      <Modal.Content overflowY="unset">
        <iframe
          src="https://t9p7usq0sam.typeform.com/to/Z6aZAi32?typeform-embed=oembed&typeform-medium=embed-oembed&format=json&disable-auto-focus=true&typeform-source=cdn.iframe.ly"
          title="Jane Printer Troubleshooting"
          width="100%"
          height="100%"
          style={{
            border: 0,
            borderRadius: 24,
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

import { useContext, useEffect, useMemo, useState } from 'react';

import {
  useDeleteStoreBoostConfig,
  useStoreSettings,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useHasPermissions,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { BoostConfig } from '@jane/business-admin/types';
import { Permission } from '@jane/shared/auth';
import { ConfirmDeleteModal } from '@jane/shared/components';
import {
  Card,
  Flex,
  Link,
  Skeleton,
  Typography,
  useToast,
} from '@jane/shared/reefer';

import { EditableCard } from '../../../../../EditableCard';
import { BoostConfigItem } from './BoostConfigItem';
import { BoostConfigModal } from './BoostConfigModal';

export const BoostConfigCard = () => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error deleting configuration. Please try again.'
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState<number>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedBoostConfig, setSelectedBoostConfig] = useState<BoostConfig>();

  const userCanEditEmbedConfigs = useHasPermissions([
    Permission.EditEmbedConfigs,
  ]);
  const { storeId } = useContext(StoreDetailsContext);
  const { data: storeSettings, isFetching } = useStoreSettings(storeId);
  const toast = useToast();

  const {
    mutateAsync: deleteBoostConfig,
    isSuccess,
    isError,
  } = useDeleteStoreBoostConfig(storeId);

  useEffect(() => {
    if (!isFetching) {
      setIsDeleting(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess) {
      toast.add({
        label: 'Boost config deleted',
        variant: 'success',
      });
    }
    if (isError) {
      toast.add({
        label: 'Error deleting boost config. Please try again.',
        variant: 'error',
      });
    }
  }, [isSuccess, isError]);

  const configs = useMemo(() => {
    if (!storeSettings) return [];
    const { boost_configs } = storeSettings;
    return boost_configs;
  }, [storeSettings]);

  const boostEnabled = useMemo(
    () => storeSettings?.store.boost_enabled || false,
    [storeSettings]
  );

  const handleDeleteBoostConfig = (configId: number) => {
    setDeletingId(configId);
    setConfirmDeleteModalOpen(true);
  };

  const handleEditBoostConfig = (boostConfig: BoostConfig) => {
    setSelectedBoostConfig(boostConfig);
    setModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsDeleting(true);
    setConfirmDeleteModalOpen(false);
    setSelectedBoostConfig(undefined);
    setModalOpen(false);
    if (deletingId) {
      return catchSubmitErrors({
        submitMethod: () => deleteBoostConfig(deletingId),
        requestData: { id: deletingId },
        onValidationError: () => null,
        callback: () => {
          setIsDeleting(false);
        },
      });
    }
  };

  return (
    <>
      {userCanEditEmbedConfigs && (
        <EditableCard
          title="Boost"
          isLoading={isFetching}
          onEdit={() => setModalOpen(true)}
          showTextButton={!isFetching}
          disabled={!isFetching && !boostEnabled}
          textButtonLabel={'Add Configuration'}
        >
          <Flex flexDirection="column">
            {isFetching || isDeleting ? (
              <Skeleton animate>
                <Skeleton.Bone height="20px" width="200px" mb={12} />
              </Skeleton>
            ) : !boostEnabled || !configs.length ? (
              <Typography>
                Boost is our SEO enhanced menu. Boost indexes your products on
                Google for higher search rankings.
                {!boostEnabled && (
                  <>
                    <br />
                    Contact{' '}
                    <Link href="mailto:partnersuccess@iheartjane.com">
                      Jane Partner Success
                    </Link>{' '}
                    to get started.
                  </>
                )}
              </Typography>
            ) : (
              <Card.Group flat width="100%">
                {configs.map((boostConfig, index) => (
                  <BoostConfigItem
                    key={index}
                    index={index}
                    boostConfig={boostConfig}
                    paddingTop={index !== 0}
                    paddingBottom={index !== configs.length - 1}
                    onDelete={handleDeleteBoostConfig}
                    onEdit={() => handleEditBoostConfig(boostConfig)}
                  />
                ))}
              </Card.Group>
            )}
          </Flex>
        </EditableCard>
      )}
      {confirmDeleteModalOpen && (
        <ConfirmDeleteModal
          open={confirmDeleteModalOpen}
          setOpen={setConfirmDeleteModalOpen}
          confirmDelete={handleConfirmDelete}
        />
      )}
      {modalOpen && (
        <BoostConfigModal
          open={modalOpen}
          setOpen={() => {
            setSelectedBoostConfig(undefined);
            setModalOpen(false);
          }}
          boostConfig={selectedBoostConfig}
          subtitle={storeSettings?.store.name || ''}
          storeId={storeSettings?.store.id || 0}
          onDelete={handleDeleteBoostConfig}
        />
      )}
    </>
  );
};

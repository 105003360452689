import { DiscountsCard, StackingCard } from '@jane/business-admin/components';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Flex } from '@jane/shared/reefer';

export const StoreSettingsSpecials = () => {
  const hasSpecialsStackingAccess = useFlag(
    FLAGS.bbEditSpecialsStackingSettings
  );
  const specialsBetaAccess = useFlag(FLAGS.scSpecialsBetaAccess);

  return (
    <Flex flexDirection="column">
      {hasSpecialsStackingAccess && !specialsBetaAccess ? (
        <StackingCard />
      ) : null}
      <DiscountsCard />
    </Flex>
  );
};

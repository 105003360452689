import { useContext, useState } from 'react';

import {
  useFetchJanePrinters,
  useManager,
  useStorePrintersSettings,
  useStoreSettings,
} from '@jane/business-admin/data-access';
import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { JanePrinterV2 } from '@jane/business-admin/types';
import { ModalNames } from '@jane/business-admin/util';
import { Flex, Typography } from '@jane/shared/reefer';

import { EditableCard } from '../../../../EditableCard';
import { PrinterSupportModal } from './PrinterSupportModal';
import { LoadingOrderTickets, OrderTickets } from './orderTickets/OrderTickets';
import { PrinterSettingsModal } from './orderTickets/PrinterSettingsModal';
import { PrinterModal } from './printers/PrinterModal';
import { LoadingPrinters, Printers } from './printers/Printers';

export const HardwareCard = () => {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [selectedJanePrinter, setSelectedJanePrinter] =
    useState<JanePrinterV2 | null>();
  const modalName = selectedJanePrinter
    ? ModalNames.EditPrinter
    : ModalNames.AddPrinter;
  const { storeId } = useContext(StoreDetailsContext);
  const { data } = useManager();
  const { manager } = data || {};
  const isInternal = manager?.internal || false;

  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.AddPrinter
  );

  const {
    modalOpen: printerSettingsModalOpen,
    openModal: openPrinterSettingsModal,
    closeModal: closePrinterSettingsModal,
  } = useModalActionsWithTracking(ModalNames.OrderTickets);

  const onEditClick = (janePrinter: JanePrinterV2) => {
    setSelectedJanePrinter(janePrinter);
    openModal(ModalNames.EditPrinter);
  };

  const openAddPrinterModal = () => {
    setSelectedJanePrinter(null);
    openModal(ModalNames.AddPrinter);
  };

  const handlePrinterSettingsModalOpen = (isOpen: boolean) => {
    isOpen ? openPrinterSettingsModal() : closePrinterSettingsModal();
  };

  const { data: storePrintersSettings, isLoading } =
    useStorePrintersSettings(storeId);
  const { data: janePrinters, isLoading: isLoadingPrinters } =
    useFetchJanePrinters(storeId);
  const { data: storeSettings } = useStoreSettings(storeId);

  return (
    <>
      <EditableCard
        title="Order Tickets"
        isLoading={isLoading}
        showTextButton={!isInternal}
        textButtonLabel={isInternal ? '' : 'Contact support'}
        onEdit={() => openPrinterSettingsModal()}
      >
        <Flex pt={24}>
          {isLoading ? (
            <LoadingOrderTickets />
          ) : storePrintersSettings ? (
            <OrderTickets
              isInternal={isInternal}
              storePrintersSettings={storePrintersSettings}
            />
          ) : (
            <Typography>
              {isInternal
                ? 'Order tickets have not been set up for this store.'
                : 'Order tickets have not been set up for this store. Contact Jane Partner Success to have these configured.'}
            </Typography>
          )}
        </Flex>
      </EditableCard>
      <EditableCard
        title="Printers"
        isLoading={isLoadingPrinters}
        onEdit={openAddPrinterModal}
        showTextButton={true}
        textButtonLabel={
          isLoadingPrinters
            ? 'Contact support'
            : isInternal
            ? 'Add printer'
            : 'Contact support'
        }
      >
        <Flex pt={24}>
          {isLoadingPrinters ? (
            <LoadingPrinters />
          ) : janePrinters && janePrinters.length > 0 ? (
            <Printers
              isInternal={isInternal}
              janePrinters={janePrinters}
              onEditClick={onEditClick}
            />
          ) : (
            <Typography>
              {isInternal
                ? 'Printers have not been set up for this store.'
                : 'Printers have not been set up for this store. Contact Jane Partner Success to activate your printer or request a new printer.'}
            </Typography>
          )}
        </Flex>
      </EditableCard>
      {supportModalOpen && (
        <PrinterSupportModal
          open={supportModalOpen}
          setOpen={setSupportModalOpen}
          subtitle={storeSettings?.store.name || ''}
        />
      )}
      {printerSettingsModalOpen && (
        <PrinterSettingsModal
          open={printerSettingsModalOpen}
          setOpen={handlePrinterSettingsModalOpen}
          subtitle={storeSettings?.store.name || ''}
          storeId={storeSettings?.store.id || 0}
          printerSettings={storePrintersSettings}
        />
      )}
      {modalOpen && (
        <PrinterModal
          open={modalOpen}
          janePrinter={selectedJanePrinter}
          onClose={() => closeModal(modalName)}
        />
      )}
    </>
  );
};

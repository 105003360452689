import styled from '@emotion/styled';

import { Flex, Typography } from '@jane/shared/reefer';

const Circle = styled(Flex)<{ visible: boolean }>(({ visible, theme }) => {
  return {
    height: '24px',
    width: '24px',
    borderRadius: '100%',
    opacity: visible ? '100%' : '25%',
    backgroundColor: theme.colors.brand.purple.light,
    color: theme.colors.brand.purple.dark,
  };
});

export const ImageWrapper = styled.div<{ visible: boolean }>(({ visible }) => ({
  opacity: visible ? '100%' : '25%',
}));

export const DefaultBrandLogo = ({
  brand,
  visible,
}: {
  brand: string;
  visible: boolean;
}) => {
  const twoLetterBrandAcronym = brand
    .replace(/the /gi, '')
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), '')
    .slice(0, 2);

  return (
    <Circle justifyContent="center" alignItems="center" visible={visible}>
      <Typography variant="mini">{twoLetterBrandAcronym}</Typography>
    </Circle>
  );
};

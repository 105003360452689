import type { FormNumberFieldProps } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer';

/**
 * `DollarInput` components are used to validate dollar decimal amounts.
 * `DollarInput` components need to be used within a `Form`.
 * `DollarInput` components have a step amount of "0.01" and will only allow 2 integers after a decimal point.
 */

export const DollarInput = (fieldProps: FormNumberFieldProps) => (
  <Form.NumberField
    {...fieldProps}
    startUnit="$"
    width="100%"
    min={0}
    step={0.01}
    allowedDecimalPlaces={2}
  />
);

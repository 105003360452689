import { z } from 'zod';

export const zAbbreviatedMenuRow = z.object({
  id: z.number(),
  enabled: z.boolean(),
  custom_display_name: z.string(),
  row_type: z.string(),
  store_id: z.number(),
  roots_only: z.boolean(),
  applicability_rules: z
    .array(
      z.object({
        filter_type: z.string().optional(),
        filter_value: z.string().optional(),
      })
    )
    .optional(),
  enabled_for_menu_product: z.boolean().optional(),
  ranking: z.boolean().optional(),
});

export type AbbreviatedMenuRow = z.infer<typeof zAbbreviatedMenuRow>;

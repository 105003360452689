import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import {
  useFetchCurrentBloomMenu,
  useUpdateBloomMenu,
} from '@jane/business-admin/data-access';
import {
  Box,
  Flex,
  Link,
  SwitchField,
  Typography,
  useToast,
} from '@jane/shared/reefer';

import { ModalPageWrapper } from './components/ModalPageWrapper';

const AgeGateFormContainer = styled(Box)({
  paddingTop: '24px',
  paddingBottom: '40px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'none',
  borderRadius: '24px',
  boxShadow: `0px 2px 16px 0px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05)`,
  marginTop: '24px',
});

const FormInput = styled.input(({ theme }) => ({
  width: '100%',
  display: 'flex',
  padding: '12px 16px',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  borderRadius: '12px',
  border: `1px solid ${theme.colors.grays.light}`,
  background: `${theme.colors.grays.white}`,
}));

export const PlusAgeGate = () => {
  const toast = useToast();

  const [previewLoading, setPreviewLoading] = useState(false);
  const bloomMenuQuery = useFetchCurrentBloomMenu();

  const { mutate: updateBloomMenu, isLoading } = useUpdateBloomMenu(
    bloomMenuQuery.data?.id
  );

  const defaultCustomAgeGateMessage =
    'Are you over 21 years old or a valid medical cannabis patient?';

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      age_gate_message: '',
      age_gate_enabled: true,
      age_gate_cookie: '',
    },
  });

  const publish: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      updateBloomMenu(
        {
          theme: {
            ...bloomMenuQuery.data?.theme.bloom,
            age_gate_message: data['age_gate_message'],
            age_gate_enabled: data['age_gate_enabled'],
            age_gate_cookie: data['age_gate_cookie'],
          },
        },
        {
          onError: (e) => {
            toast.add({
              label: 'An error occurred.',
              variant: 'error',
            });
          },
          onSuccess: () => {
            toast.add({
              label: 'Menu updated!',
              variant: 'success',
            });
            reset({
              age_gate_message: data['age_gate_message'],
              age_gate_enabled: data['age_gate_enabled'],
              age_gate_cookie: data['age_gate_cookie'],
            });
          },
        }
      );
    },
    [updateBloomMenu, bloomMenuQuery, toast, reset]
  );

  const preview = useCallback(() => {
    setPreviewLoading(true);
    const data = getValues();
    updateBloomMenu(
      {
        theme_draft: {
          ...bloomMenuQuery.data?.theme.bloom,
          age_gate_message: data['age_gate_message'],
          age_gate_enabled: data['age_gate_enabled'],
          age_gate_cookie: data['age_gate_cookie'],
        },
      },
      {
        onError: (e) => {
          setPreviewLoading(false);
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          setPreviewLoading(false);
          if (!bloomMenuQuery.data?.menu_url) {
            return;
          }
          const previewUrl = new URL(bloomMenuQuery.data.menu_url);
          previewUrl.searchParams.set('draft', 'true');
          previewUrl.searchParams.set('age-gate', 'true');
          const newWindow = window.open(
            previewUrl,
            '_blank',
            'noopener,noreferrer'
          );
          if (newWindow) {
            newWindow.opener = null;
          }
        },
      }
    );
  }, [
    getValues,
    updateBloomMenu,
    bloomMenuQuery.data?.theme.bloom,
    bloomMenuQuery.data?.menu_url,
    toast,
  ]);

  useEffect(() => {
    if (bloomMenuQuery.isSuccess) {
      const userMsg = bloomMenuQuery.data.theme.bloom?.age_gate_message;
      const userCookie = bloomMenuQuery.data.theme.bloom?.age_gate_cookie || '';
      const enabled = bloomMenuQuery.data.theme.bloom?.age_gate_enabled ?? true;
      reset(
        {
          age_gate_message: userMsg,
          age_gate_cookie: userCookie,
          age_gate_enabled: enabled,
        },
        { keepDirtyValues: true }
      );

      if (!userMsg) {
        setValue('age_gate_message', defaultCustomAgeGateMessage, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
    }
  }, [
    bloomMenuQuery.data?.theme.bloom?.age_gate_message,
    bloomMenuQuery.data?.theme.bloom?.age_gate_enabled,
    bloomMenuQuery.data?.theme.bloom?.age_gate_cookie,
    bloomMenuQuery.isSuccess,
    reset,
    setValue,
  ]);

  return (
    <ModalPageWrapper
      hasUnsavedChanges={isDirty}
      preview={{
        onClick: preview,
        disable: isLoading,
        loading: isLoading && previewLoading,
      }}
      publish={{
        onClick: handleSubmit(publish),
        loading: isLoading && !previewLoading,
        disable: isLoading || !isValid || !isDirty,
      }}
      title="Age gate"
    >
      <Flex justifyContent="center">
        <Flex flexDirection="column" p={24} maxWidth={800}>
          <Typography>
            In the event your customer navigates to the Bloom menu directly and
            bypasses your main website, we want to make sure we're still
            compliant and display an age gap pop up. The settings below gives
            you the ability to customize the age gate pop up further.
          </Typography>
          <Controller
            name="age_gate_enabled"
            control={control}
            render={({ field }) => (
              <Flex flexDirection="row" gap={12} mt={24}>
                <SwitchField
                  label="Age gate toggle"
                  name="age_gate_enabled"
                  onChange={(toggle) => field.onChange(toggle)}
                  labelHidden
                  checked={field.value}
                />
                <Typography variant="body">Visible</Typography>
              </Flex>
            )}
          />

          <AgeGateFormContainer borderRadius="sm">
            <Controller
              name="age_gate_message"
              control={control}
              rules={{ required: true, pattern: /\S+/ }}
              render={({ field }) => (
                <Flex flexDirection="column" gap={12}>
                  <Typography variant="body-bold">Text</Typography>
                  <FormInput
                    type="text"
                    onChange={(message) => field.onChange(message)}
                    value={field.value}
                  />
                  <Link
                    onClick={() => field.onChange(defaultCustomAgeGateMessage)}
                  >
                    Revert to default text
                  </Link>
                </Flex>
              )}
            />
            <Controller
              name="age_gate_cookie"
              control={control}
              render={({ field }) => (
                <Flex flexDirection="column" gap={12} mt={24}>
                  <Typography variant="body-bold">
                    Cookie name (Optional)
                  </Typography>
                  <FormInput
                    type="text"
                    onChange={(message) => field.onChange(message)}
                    value={field.value}
                  />
                  <Typography color="grays-mid">
                    To locate your cookie name, access your age gate plugin's
                    settings and paste the name here. If you aren't using a
                    plugin, you will need to work with a technical engineer to
                    retrieve the cookie name with your browser's developer
                    tools.
                  </Typography>
                </Flex>
              )}
            />
          </AgeGateFormContainer>
        </Flex>
      </Flex>
    </ModalPageWrapper>
  );
};

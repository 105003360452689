import type { ColumnFiltersState } from '@tanstack/react-table';
import noop from 'lodash/noop';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

interface Props {
  bulkEditModalOpen: boolean;
  bulkSelectedSpecials: number[];
  children: ReactNode;
  columnFilters: ColumnFiltersState;
  setBulkSelectedSpecials: React.Dispatch<React.SetStateAction<number[]>>;
  setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
}

export const GlobalSpecialsTableContext = React.createContext<
  Omit<Props, 'children'>
>({
  bulkEditModalOpen: false,
  bulkSelectedSpecials: [],
  columnFilters: [],
  setBulkSelectedSpecials: noop,
  setColumnFilters: noop,
});

export const GlobalSpecialsTableProvider = ({
  bulkEditModalOpen,
  bulkSelectedSpecials,
  columnFilters,
  setBulkSelectedSpecials,
  setColumnFilters,
  children,
}: Props) => {
  const contextValue = useMemo(
    () => ({
      bulkEditModalOpen,
      bulkSelectedSpecials,
      columnFilters,
      setColumnFilters,
      setBulkSelectedSpecials,
    }),
    [
      bulkEditModalOpen,
      columnFilters,
      bulkSelectedSpecials,
      setBulkSelectedSpecials,
      setColumnFilters,
    ]
  );

  return (
    <GlobalSpecialsTableContext.Provider value={contextValue}>
      {children}
    </GlobalSpecialsTableContext.Provider>
  );
};

import styled from '@emotion/styled';

import { ChevronRightIcon, Flex, Link } from '@jane/shared/reefer';

const StyledLink = styled(Link)(({ theme }) => ({
  width: '100%',
  paddingLeft: 40,
  paddingRight: 40,
  paddingTop: 40,
  marginBottom: 40,
  borderTop: `1px solid ${theme.colors.grays.light}`,
}));

export const NavItem = ({ title, to }: { title: string; to: string }) => {
  return (
    <StyledLink to={to} variant="minimal" typography="body-bold">
      <Flex justifyContent="space-between">
        {title}
        <ChevronRightIcon />
      </Flex>
    </StyledLink>
  );
};

import * as t from 'io-ts';

import { tConsentRequirement } from './checkoutAgreementVersion';
import { tReservationMode, tReservationModeV2 } from './reservationMode';

export const tCheckoutAgreement = t.interface({
  id: t.number,
  checkout_agreement_id: t.number,
  label: t.string,
  external_link: t.union([t.string, t.null]),
  created_at: t.string,
  checkout_agreement_version_id: t.number,
  name: t.string,
  store_id: t.number,
  active: t.boolean,
  consent_requirement: tConsentRequirement,
  consent_default: t.union([t.boolean, t.null]),
  reservation_mode: t.union([tReservationMode, t.null]),
  updated_at: t.string,
});

export type CheckoutAgreement = t.TypeOf<typeof tCheckoutAgreement>;

export const tCheckoutAgreementV2 = t.interface({
  active: t.boolean,
  consent_default: t.union([t.boolean, t.null]),
  consent_requirement: tConsentRequirement,
  external_link: t.union([t.string, t.null]),
  id: t.number,
  label: t.string,
  name: t.string,
  reservation_mode: t.union([tReservationModeV2, t.null]),
});

export type CheckoutAgreementV2 = t.TypeOf<typeof tCheckoutAgreementV2>;

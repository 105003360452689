import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCompleteRegistration } from '@jane/business-admin/data-access';
import type { CompleteRegistrationParams } from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import type { StaffMember } from '@jane/business-admin/types';
import {
  EventNames,
  formatRole,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Banner,
  Flex,
  Form,
  FormValidationError,
  Link,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';

const FORM_ERROR_NAME = 'manager-registration-error';

const FormSkeleton = () => (
  <Skeleton animate direction="column">
    <Flex flexDirection="column" gap={64}>
      <Flex gap={40} justifyContent="center">
        <Skeleton.Bone width="45%" height={40} />
        <Skeleton.Bone width="45%" height={40} />
      </Flex>
      <Flex gap={40} justifyContent="center">
        <Skeleton.Bone width="45%" height={40} />
        <Skeleton.Bone width="45%" height={40} />
      </Flex>
      <Flex gap={40} justifyContent="center">
        <Skeleton.Bone width="45%" height={40} />
        <Skeleton.Bone width="45%" height={40} />
      </Flex>
      <Flex gap={40} justifyContent="center">
        <Skeleton.Bone width="45%" height={40} />
        <Skeleton.Bone width="45%" height={40} />
      </Flex>
      <Skeleton.Bone width="100%" height={50} />
    </Flex>
  </Skeleton>
);

export const ManagerRegistrationForm = ({
  staffMember,
  invitationToken,
  isLoading,
}: {
  invitationToken?: string;
  isLoading: boolean;
  staffMember?: StaffMember;
}) => {
  const navigate = useNavigate();
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error completing registration. Please try again.'
  );
  const {
    mutateAsync: completeRegistration,
    isLoading: isCompletingRegistration,
    isSuccess: isRegistrationSuccess,
    isError: isRegistrationError,
  } = useCompleteRegistration(invitationToken ?? '');

  const disableForm = !invitationToken || isLoading || !staffMember;

  useMutationStatusToasts({
    isMutating: isCompletingRegistration,
    isSuccess: isRegistrationSuccess,
    isError: isRegistrationError,
    successMessage: 'Registration complete.',
    errorMessage: 'Error completing registration. Please try again.',
  });

  useEffect(() => {
    // Identify and register this manager with mixpanel before they're authed,
    // with the properties we currently have access to prior to registration.
    if (staffMember?.id) {
      janeMixpanel.identify(staffMember.id.toString());

      janeMixpanel.people.set({
        $email: staffMember.email,
        'User Id': staffMember.id,
        'User Role': staffMember.role,
      });

      janeMixpanel.people.set_once({
        $created: new Date(),
      });

      janeMixpanel.register({
        user_role: staffMember.role,
      });
    }
  }, [staffMember]);

  useEffect(() => {
    if (!isCompletingRegistration && isRegistrationSuccess) {
      navigate('/login');
    }
  }, [isRegistrationSuccess, isCompletingRegistration]);

  const handleSubmit = (formData: CompleteRegistrationParams) => {
    const submitMethod = async () => {
      track({
        event: EventNames.CompletedRegistration,
        attributes: Object.entries(formData).reduce<string[]>(
          (values, [key, val]) => {
            if (key === 'password') return values;
            values.push(JSON.stringify(val));
            return values;
          },
          []
        ),
      });

      return completeRegistration(formData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData: formData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  const roleValue = useMemo(() => {
    if (!staffMember) return '';
    return formatRole(staffMember);
  }, [staffMember]);

  if (isLoading) {
    return <FormSkeleton />;
  }

  return (
    <>
      {!staffMember && (
        <Banner
          full
          label="Invalid invitation token. Please check your link and try again."
          variant="error"
          typography="body-bold"
          mb={40}
        />
      )}
      <Form
        formErrorName={FORM_ERROR_NAME}
        name="register"
        onSubmit={handleSubmit}
      >
        <Flex flexDirection="column" gap={40}>
          <Flex gap={40}>
            <Form.TextField
              disabled
              readOnly
              label="Email"
              name="email"
              defaultValue={staffMember?.email ?? ''}
            />
            <Form.TextField
              disabled
              readOnly
              label="Role"
              name="role"
              defaultValue={roleValue}
            />
          </Flex>
          <Flex gap={40}>
            <Form.TextField
              required
              disabled={disableForm}
              name="first_name"
              label="First name"
              defaultValue={staffMember?.first_name ?? ''}
            />
            <Form.TextField
              required
              disabled={disableForm}
              name="last_name"
              label="Last name"
              defaultValue={staffMember?.last_name ?? ''}
            />
          </Flex>
          <Flex gap={40}>
            <Form.TextField
              required
              disabled={disableForm}
              type="tel"
              name="phone"
              label="Phone number"
              defaultValue={staffMember?.phone ?? ''}
            />
            <Form.TextField
              required
              disabled={disableForm}
              type="password"
              name="password"
              label="Password"
              placeholder="Must be at least 10 characters"
            />
          </Flex>
          <Flex flexDirection="column" gap={24}>
            <Typography>
              Please read and agree to the{' '}
              <Link to="/terms">Terms of Service</Link> and{' '}
              <Link to="/privacy-policy">Privacy Policy</Link> in order to use
              Jane.
            </Typography>
            <Form.CheckboxField
              required
              disabled={disableForm}
              name="accepted_terms_and_conditions"
              label="I have read and agree to the Jane Terms of Service and Privacy Policy."
            />
          </Flex>
          <Form.SubmitButton
            data-testid="staff-registration-submit"
            label="Complete Registration"
            variant="primary"
            full
          />
        </Flex>
      </Form>
    </>
  );
};

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { hex } from 'wcag-contrast';

import {
  Flex,
  Form,
  Modal,
  Typography,
  effects,
  useFormContext,
} from '@jane/shared/reefer';

import { ReactComponent as HexIcon } from './HexIcon.svg';

const WCAG_RATIO = 4.5;
const ERRORS = {
  hexError: 'Color is not a valid hex color.',
  a11yError: 'Color does not pass accessibility standards.',
};
const LIGHTEN_RATIO = 0.8;

const PreviewColor = styled.div<{ customColor: string }>(
  ({ theme, customColor }) => ({
    backgroundColor: customColor,
    borderRadius: theme.borderRadius.sm,
    border: `1px solid ${theme.colors.grays.light}`,
    height: '48px',
    width: '48px',
  })
);

export const ColorPreviewTextbox = ({
  defaultColor,
  helperText,
  label,
  name,
  onChange,
  shouldValidateThemeColor = false,
}: {
  defaultColor: string;
  helperText: string;
  label: string;
  name: string;
  onChange?: (value: string, isValid: boolean) => void;
  shouldValidateThemeColor?: boolean;
}) => {
  const [previewColorState, setPreviewColorState] = useState('');
  const { clearErrors, trigger } = useFormContext();
  const theme = useTheme();

  const addHashToHexColor = (color: string) => {
    return color.startsWith('#') ? color : '#' + color;
  };

  const stripHashFromHexColor = (color: string) => {
    return color.startsWith('#') ? color.substring(1) : color;
  };

  useEffect(() => {
    setPreviewColorState(addHashToHexColor(defaultColor));
  }, [defaultColor]);

  const convertToSixCharHex = (color: string) => {
    const isShortHex = color.length === 3;
    if (!isShortHex) return color;

    if (color.startsWith('#')) {
      color = color.substring(1);
    }

    return color + color;
  };

  const validHexColor = (hex: string) => {
    if (hex.startsWith('#')) {
      hex = hex.substring(1);
    }

    const isValidHex =
      hex && (hex.match(/^[a-f0-9]{3}$/i) || hex.match(/^[a-f0-9]{6}$/i));

    return isValidHex;
  };

  const validateColor = (color: string, shouldValidateThemeColor?: boolean) => {
    const isValidHexColor = validHexColor(color);
    const strippedColor = stripHashFromHexColor(color);

    const isColorAccessible =
      hex(strippedColor, theme.colors.grays.white) >= WCAG_RATIO;
    const isLightenedColorAccessible =
      hex(
        strippedColor,
        effects.lighten(convertToSixCharHex(strippedColor), LIGHTEN_RATIO)
      ) >= WCAG_RATIO;

    const error = [
      !isValidHexColor && ERRORS.hexError,
      !isColorAccessible && ERRORS.a11yError,
      shouldValidateThemeColor &&
        !isLightenedColorAccessible &&
        ERRORS.a11yError,
    ]
      .filter(Boolean)
      .find((message: string | false | undefined) => message);

    return error || true;
  };

  const handleChange = (value: string) => {
    clearErrors(name);
    setPreviewColorState(addHashToHexColor(value));

    if (onChange) {
      const isValid = validateColor(value);
      onChange(value, isValid === true);
    }
  };

  useEffect(() => {
    trigger();
  }, []);

  return (
    <>
      <Flex width="50%">
        <Form.TextField
          name={name}
          label={label}
          helperText={helperText}
          defaultValue={defaultColor}
          endIcon={<HexIcon />}
          required
          validate={(value: string) =>
            validateColor(value, shouldValidateThemeColor)
          }
          onChange={handleChange}
        />
        <Flex flexDirection="column" ml={24}>
          <Typography mb={16}>Preview</Typography>
          <PreviewColor customColor={previewColorState} />
        </Flex>
      </Flex>
      <Modal.ContentDivider />
    </>
  );
};

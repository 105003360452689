import { Flex, Skeleton, Typography } from '@jane/shared/reefer';

export interface CardSectionProps extends React.ComponentProps<typeof Flex> {
  children: React.ReactNode;
  isLoading?: boolean;
  label: string;
}

export const CardSection = ({
  label,
  isLoading,
  children,
  ...boxProps
}: CardSectionProps) => {
  return (
    <Flex flexDirection="column" {...boxProps}>
      {isLoading ? (
        <Skeleton animate>
          <Skeleton.Bone mb={4} height={24} width={230} />
        </Skeleton>
      ) : (
        <Typography variant="caps" color="grays-mid" mb={4}>
          {label}
        </Typography>
      )}

      {children}
    </Flex>
  );
};

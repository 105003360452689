/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const ThumbsUp24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <g className="Thumbs Up 24">
      <g className="SVG">
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M11.949 6.306c.511-1.535.97-2.762 1.436-3.435.121-.175.21-.262.259-.304.034.024.083.064.149.13.474.473.62 1.218.552 2.191-.032.47-.11.931-.186 1.37l-.019.108c-.064.367-.14.796-.14 1.123a1 1 0 0 0 1.062.998c1.292-.08 3.089-.068 4.58.244.747.156 1.35.375 1.766.644.4.259.562.518.606.779.23 1.377.231 3.426-.178 5.26-.423 1.9-1.2 3.215-2.23 3.656-1.505.645-3.79.752-6.158.429-2.092-.287-4.087-.887-5.448-1.594v-7.599c.457-.12.997-.315 1.538-.661.962-.615 1.845-1.644 2.41-3.34ZM7.516 8.365c.307-.082.627-.202.946-.406.538-.344 1.155-.981 1.59-2.286.488-1.465 1.029-2.988 1.688-3.94.323-.467.807-.997 1.506-1.15.785-.172 1.458.196 1.961.7 1.107 1.106 1.212 2.611 1.133 3.743a13.79 13.79 0 0 1-.184 1.42c1.206-.013 2.62.06 3.894.327.868.182 1.739.465 2.445.922.721.467 1.33 1.158 1.491 2.13.27 1.623.269 3.93-.197 6.025-.452 2.029-1.425 4.214-3.395 5.059-1.995.855-4.71.914-7.217.571-2.1-.287-4.2-.877-5.8-1.65A2.995 2.995 0 0 1 5 21H3a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3h2c1.054 0 1.98.543 2.516 1.365Zm6.068-5.83s.004 0 .014.005a.048.048 0 0 1-.014-.005ZM2 10a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8Z"
          className="ThumbsUp"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: ThumbsUp24,
  sm: ThumbsUp24,
  lg: ThumbsUp24,
  xl: ThumbsUp24,
  xxl: ThumbsUp24,
  xxxl: ThumbsUp24,
};

export const ThumbsUpIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'thumbs-up-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { lazy } from 'react';

import type { MapContainerProps } from './mapContainer';

/** Code splitting to load mapboxgl only when called */
const MapContainer = lazy(() =>
  import('./mapContainer').then(({ MapContainer }) => ({
    default: MapContainer,
  }))
);

export const GeofenceMap = (props: MapContainerProps) => {
  return <MapContainer {...props} />;
};

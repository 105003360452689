import * as t from 'io-ts';

import { tProductKind, tProductLineage } from '@jane/shared/models';

export const tUnpublishedProduct = t.intersection([
  t.interface({
    unmapped_product_id: t.string,
    store_id: t.number,
    status: t.string,
    archived_time: t.union([t.string, t.null, t.undefined]),
    pos_data: t.partial({
      name: t.union([t.string, t.null]),
      description: t.union([t.string, t.null]),
      brand: t.union([t.string, t.null]),
      brand_subtype: t.union([t.string, t.null]),
      kind: t.union([tProductKind, t.null]),
      root_subtype: t.union([t.string, t.null]),
      amount: t.union([t.string, t.null]),
      dosage: t.union([t.string, t.null]),
      lab_results: t.partial({
        percent_cbd: t.union([t.number, t.null]),
        percent_thc: t.union([t.number, t.null]),
        thc_dosage_milligrams: t.union([t.number, t.null]),
        cbd_dosage_milligrams: t.union([t.number, t.null]),
      }),
      quantity_value: t.union([t.number, t.null]),
      quantity_units: t.union([t.string, t.null]),
      category: t.union([tProductLineage, t.null]),
      image_urls: t.array(t.string),
      inventories: t.union([
        t.array(
          t.partial({
            id: t.union([t.string, t.null]),
            price: t.union([t.number, t.null]),
            wholesale_price: t.union([t.number, t.null]),
            inventory_count: t.union([t.number, t.null]),
            equivalency: t.union([t.string, t.null]),
            weight: t.union([t.string, t.null]),
            bulk: t.union([t.string, t.null]),
            pos_identifier: t.union([t.string, t.null]),
            thc_potency: t.union([t.string, t.null]),
            cbd_potency: t.union([t.string, t.null]),
            total_stock: t.union([t.number, t.null]),
          })
        ),
        t.null,
      ]),
      sku: t.string,
      pricing: t.union([
        t.interface({
          per_gram: t.union([t.number, t.null]),
          per_ounce: t.union([t.number, t.null]),
          per_half_gram: t.union([t.number, t.null]),
          per_two_gram: t.union([t.number, t.null]),
          per_half_ounce: t.union([t.number, t.null]),
          per_eighth_ounce: t.union([t.number, t.null]),
          per_quarter_ounce: t.union([t.number, t.null]),
          per_each: t.union([t.number, t.null]),
        }),
        t.null,
      ]),
    }),
  }),
  t.partial({}),
]);

export type UnpublishedProduct = t.TypeOf<typeof tUnpublishedProduct>;

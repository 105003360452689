import styled from '@emotion/styled';

import { mediaQueries, shadows } from '@jane/shared/reefer-emotion';

export const StyledHeader = styled.header<{
  bloomUserExperience?: boolean;
}>(({ bloomUserExperience }) => ({
  boxShadow: shadows.divider,
  width: '100%',
  height: bloomUserExperience ? '40px' : '48px',
  display: 'grid',
  gridTemplateColumns: '1fr minmax(max-content, 1fr) 1fr',
  gridColumnGap: 8,
  [mediaQueries.desktop('sm', 'min')]: {
    height: bloomUserExperience ? '40px' : '80px',
  },
}));

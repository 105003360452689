import { useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { ModalNames } from '@jane/business-admin/util';
import { EventNames, normalizePath, track } from '@jane/business-admin/util';

/**
 *
 * @param modalName The majority of uses will provide this as a ModalName. Some modals use a state to set this, hence the overwriteModalName that you can provide when calling open/closeModal
 * @returns \{ modalOpen: boolean, openModal: function, closeModal: function \}
 */
export const useModalActionsWithTracking = (modalName: ModalNames | string) => {
  const { storeId } = useContext(StoreDetailsContext);
  const { pathname } = useLocation();

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(
    (overwriteModalName?: string) => {
      track({
        event: EventNames.OpenedModal,
        modal_name: overwriteModalName || modalName,
        url: normalizePath(pathname, storeId),
      });
      setModalOpen(true);
    },
    [storeId]
  );

  const closeModal = useCallback(
    (overwriteModalName?: string) => {
      track({
        event: EventNames.ClosedModal,
        modal_name: overwriteModalName || modalName,
        url: normalizePath(pathname, storeId),
      });
      setModalOpen(false);
    },
    [storeId]
  );

  return { modalOpen, openModal, closeModal };
};

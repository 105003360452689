import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo, useState } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { CustomLineageLabelsV2 } from '@jane/business-admin/types';
import {
  Button,
  CheckboxField,
  Flex,
  Modal,
  Typography,
} from '@jane/shared/reefer';
import type { PriceId } from '@jane/shared/types';
import { allWeightIds, labelForWeightId } from '@jane/shared/util';

import { ConditionType, FORM_FIELD_DEFAULT_MARGIN } from '../../form';

interface Props {
  conditions: string[];
  fieldPrefix: string;
  lastCondition?: boolean;
  onConditionsUpdate: (conditions: string[]) => void;
  type: ConditionType.Lineage | ConditionType.Weight;
}

/**
 * Component for each condition line item in building conditions for a Special. This particular component can handle Lineage or Weight
 *
 * @param conditions: Simple list of conditions to display, values from a special should be mapped to match a simple conditions array
 * @param fieldPrefix: Used for input name fields, to prevent duplicates when there are two conditions cards in the case of BOGO specials
 * @param onConditionsUpdate: Callback when item is removed by clicking "X" icon, or user clears all by clicking "Remove condition"
 * @param type: Type of condition
 * @param lastCondition: Removes bottom border if true
 */
export const ConditionByCheckboxes = ({
  conditions: conditionsProp,
  fieldPrefix,
  onConditionsUpdate,
  type,
  lastCondition,
}: Props) => {
  const {
    storeSettings,
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);
  const [expanded, setExpanded] = useState(!!conditionsProp.length);
  const [conditions, setConditions] = useState(conditionsProp);

  useEffect(() => {
    setExpanded(!!conditionsProp.length);
    setConditions(conditionsProp);
  }, [conditionsProp]);

  const weightOptions = useMemo(() => {
    const allWeights = allWeightIds('extract');
    return allWeights.map((weight: PriceId) => ({
      label: labelForWeightId(weight),
      value: weight,
    }));
  }, []);

  // TODO: Move to utils since Product DetailsCard also uses it
  const lineageOptions = (custom_lineage_labels: CustomLineageLabelsV2) => {
    const {
      indica = 'Indica',
      sativa = 'Sativa',
      hybrid = 'Hybrid',
      cbd = 'CBD',
    } = custom_lineage_labels;
    const baseLineageOptions = [
      {
        value: 'indica',
        label: indica,
      },
      {
        value: 'sativa',
        label: sativa,
      },
      {
        value: 'hybrid',
        label: hybrid,
      },
      {
        value: 'cbd',
        label: cbd,
      },
    ];

    return baseLineageOptions;
  };
  const options = useMemo(() => {
    if (type === ConditionType.Lineage) {
      return lineageOptions(
        storeSettings?.custom_labels || {
          indica: 'Indica',
          sativa: 'Sativa',
          hybrid: 'Hybrid',
          cbd: 'CBD',
          none: 'None',
        }
      );
    } else {
      return weightOptions;
    }
  }, [type, storeSettings?.custom_labels, weightOptions]);

  useEffect(() => {
    setConditions(conditionsProp);
  }, [conditionsProp]);

  const removeCondition = () => {
    setConditions([]);
    setExpanded(false);

    onConditionsUpdate([]);
  };

  const onCheckboxChange = (checked: boolean, value: string) => {
    const filteredConditions = [...conditions];
    if (!checked) {
      filteredConditions.splice(filteredConditions.indexOf(value), 1);
    } else {
      filteredConditions.push(value);
    }

    setConditions(filteredConditions);
    onConditionsUpdate(filteredConditions);
  };

  const isAnyPosSynced = isJanePosSynced || posSynced;
  const isDisabled = isAnyPosSynced || isReadOnly;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb={FORM_FIELD_DEFAULT_MARGIN}
      >
        <Typography variant="header">{capitalize(type)}</Typography>
        <Flex gap={24}>
          <Button
            label={expanded ? 'Remove condition' : 'Add condition'}
            onClick={() => {
              expanded ? removeCondition() : setExpanded(true);
            }}
            variant={expanded ? 'tertiary' : 'secondary'}
            disabled={isDisabled}
          />
        </Flex>
      </Flex>
      {expanded && (
        <>
          <Flex gap={12} flexWrap="wrap">
            {options.map((option) => (
              <CheckboxField
                key={`key_${option.value}`}
                mr={24}
                name={`${fieldPrefix}.${option.value}`}
                label={option.label}
                onChange={(value) => onCheckboxChange(value, option.value)}
                checked={conditions.includes(option.value)}
                disabled={isDisabled}
              />
            ))}
          </Flex>
          {!lastCondition && <Modal.ContentDivider />}
        </>
      )}
    </>
  );
};

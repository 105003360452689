import { useState } from 'react';

import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Button,
  CheckboxField,
  List,
  Popover,
  Typography,
} from '@jane/shared/reefer';

type Props = {
  label: string;
  options: { label: string; value: string }[];
  target: React.ReactNode;
  topAlignment?: boolean;
  triggerCopy: (selectedOptions: string[]) => void;
};
export const CopyHoursPopover = ({
  target,
  label,
  options,
  triggerCopy,
  topAlignment,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const onSubmit = (closePopover: () => void) => {
    triggerCopy(selectedOptions);
    closePopover();
    setSelectedOptions([]);
  };

  const onOptionChecked = (checked: boolean, value: string) => {
    if (checked) {
      setSelectedOptions((prevValue) => [...prevValue, value]);
    } else {
      const filteredOptions = selectedOptions.filter(
        (option) => option !== value
      );
      setSelectedOptions(filteredOptions);
    }
  };

  const alignment: {
    horizontal?: 'right' | 'center' | 'left';
    vertical?: 'top' | 'bottom';
  } = topAlignment
    ? { vertical: 'top', horizontal: 'right' }
    : { horizontal: 'right' };

  return (
    <Popover alignment={alignment} label={label} target={target}>
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content label={label}>
          <Typography variant="body-bold" mb={12}>
            Copy to
          </Typography>
          <List label={label}>
            {options.map(({ value, label: optionLabel }) => (
              <List.Item key={value}>
                <CheckboxField
                  onChange={(checked: boolean) =>
                    onOptionChecked(checked, value)
                  }
                  aria-label={`Copy to ${optionLabel}`}
                  label={optionLabel}
                  name={value}
                />
              </List.Item>
            ))}
          </List>
          <Button
            disabled={!selectedOptions.length}
            onClick={() => onSubmit(closePopover)}
            mt={12}
            label="Confirm"
            full
            variant="secondary"
          />
        </Popover.Content>
      )}
    </Popover>
  );
};

import styled from '@emotion/styled';
import type { Row } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';

import type { AbbreviatedSpecialV2 } from '@jane/business-admin/types';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Box,
  CheckboxField,
  Flex,
  GlobeIcon,
  Link,
  PosDeviceIcon,
  SwitchField,
  Typography,
} from '@jane/shared/reefer';

const CellContents = styled(Flex)({
  minWidth: 400,
  maxWidth: 430,
  wordWrap: 'break-word',
  whiteSpace: 'normal',
});

const CellLabel = styled(Typography)({
  '@supports (-webkit-line-clamp: 2)': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

interface SpecialCellProps {
  bulkEditModalOpen: boolean;
  isGlobalSpecials?: boolean;
  onChangeEnabled?: () => void;
  onToggleSelected: React.MouseEventHandler<HTMLInputElement>;
  row: Row<AbbreviatedSpecialV2>;
  showToggle?: boolean;
}
export const SpecialCell = ({
  row,
  onToggleSelected,
  showToggle = true,
  isGlobalSpecials = false,
  onChangeEnabled,
  bulkEditModalOpen,
}: SpecialCellProps) => {
  const { pathname } = useLocation();
  const url = `${pathname}/${row.original.id}`;

  const hasGlobalSpecialsAccess = useFlag(FLAGS.scGlobalSpecialsBeta);

  return (
    <Flex alignItems="center" justifyContent="space-between" maxWidth={400}>
      <CellContents alignItems="center">
        {bulkEditModalOpen ? (
          <Box pr={24}>
            <CheckboxField
              label={`${row.original.id}-checkbox`}
              name={`${row.original.id}-checkbox`}
              labelHidden
              checked={row.getIsSelected()}
              onClick={onToggleSelected}
            />
          </Box>
        ) : null}
        {!bulkEditModalOpen && showToggle && onChangeEnabled ? (
          <Box pr={0}>
            <SwitchField
              defaultChecked={!!row.original.enabled}
              name={`${row.original.id}-enabled`}
              label="Enabled?"
              labelHidden
              onClick={(evt) => {
                onChangeEnabled();
                evt.stopPropagation();
              }}
            />
          </Box>
        ) : null}
        <Flex flexDirection="column" alignItems="flex-start">
          <Link
            ariaLabel={`View details for '${row.original.title}'`}
            to={bulkEditModalOpen ? '' : url}
            onClick={(event) => {
              // In bulk edit mode, allow propagation so row checkbox is selected
              if (!bulkEditModalOpen) {
                event.stopPropagation();
              }
            }}
            variant="minimal"
          >
            <CellLabel variant="body-bold" mx={24} title={row.original.title}>
              <Flex alignItems="center">
                {hasGlobalSpecialsAccess &&
                  !isGlobalSpecials &&
                  (!row.original.store_specific ? (
                    <GlobeIcon
                      color="purple"
                      size="sm"
                      mr={4}
                      title="Synced from Global Special"
                    />
                  ) : row.original.pos_synced ? (
                    <PosDeviceIcon
                      color="purple"
                      size="sm"
                      mr={4}
                      title="Synced from POS"
                    />
                  ) : null)}
                {row.original.title}
              </Flex>
            </CellLabel>
            <CellLabel variant="body" color="grays-mid" mx={24}>
              {row.original.description}
            </CellLabel>
          </Link>
        </Flex>
      </CellContents>
    </Flex>
  );
};

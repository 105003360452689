import { useMutation, useQueryClient } from '@tanstack/react-query';

import type {
  DeleteObject,
  FulfillmentConfig,
  KioskV2,
  MaxOrderSetting,
} from '@jane/business-admin/types';
import { janeApiV2 } from '@jane/shared/data-access';
import type { DeliveryZipcode, ReservationModeV2 } from '@jane/shared/models';

import { STORES_URL } from '..';

export type ToggleFulfillmentsParams = {
  [key in ReservationModeV2]?: boolean;
} & {
  allow_future_day_ordering?: boolean;
  allow_off_hours_ordering?: boolean;
};

const toggleFulfillmentModes = async (
  data: ToggleFulfillmentsParams,
  storeId?: string
): Promise<null> =>
  await janeApiV2.patch<null>(`${STORES_URL}/${storeId}/fulfillments`, data);

export const useToggleFulfillmentModes = (
  storeId: string,
  toggledFn?: (fulfillmentCard: ToggleFulfillmentsParams) => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ToggleFulfillmentsParams) =>
      toggleFulfillmentModes(data, storeId),
    onError: (error, variables) => {
      toggledFn?.(variables);
      console.error(error);
    },
    onSuccess: (_, variables) => {
      toggledFn?.(variables);
      queryClient.invalidateQueries(['stores', storeId?.toString()]);
    },
  });
};

export type UpdateFulfillmentParams = Partial<FulfillmentConfig> & {
  curbside?: 'allowed' | 'mandatory' | 'not_allowed';
  delivery?: boolean;
  delivery_strategy?: 'radius' | 'zipcode' | 'geofence';
  delivery_zcodes?: (DeliveryZipcode | DeleteObject)[];
  pickup?: boolean;
  store_reservation_slot_max_order_settings: (MaxOrderSetting | DeleteObject)[];
};

const updateFulfillmentConfig = async (
  data: UpdateFulfillmentParams,
  fulfillmentType: ReservationModeV2,
  storeId?: string
): Promise<null> => {
  return await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/fulfillments/${fulfillmentType}`,
    data
  );
};

export const useUpdateFulfillmentConfig = (
  storeId: string,
  fulfillmentType: ReservationModeV2
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateFulfillmentParams) =>
      updateFulfillmentConfig(data, fulfillmentType, storeId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId?.toString()]);
    },
  });
};

export type KioskRequest = {
  kiosk_photo?: string;
  kiosk_settings?: Partial<Omit<KioskV2, 'enabled' | 'kiosk_photo'>>;
};
const updateKioskConfig = async (
  data: KioskRequest,
  storeId?: string
): Promise<null> =>
  await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/fulfillments/kiosk`,
    data
  );

export const useUpdateKiosk = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: KioskRequest) => {
      return updateKioskConfig(data, storeId);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId?.toString()]);
    },
  });
};

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Attempt to navigate back, or to the store's home menu if there is no history left -
 *   eg. you used a direct link to open this in a new tab.
 * @see https://stackoverflow.com/a/71647428/2108524
 */
export const useNavigateBack = (fallBackUrl = '/') => {
  const navigate = useNavigate();

  return useCallback(
    (
      /** The number of pages to simultaneously navigate back to */
      delta = 1
    ) => {
      if (window.history.length > 1) {
        const numPages = Math.min(window.history.length - 1, delta);
        return navigate(-1 * numPages);
      } else {
        return navigate(fallBackUrl, { replace: true });
      }
    },
    [navigate, fallBackUrl]
  );
};

import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { businessPaths } from '@jane/business-admin/util';
import {
  Box,
  Card,
  ChevronLeftIcon,
  Flex,
  RadioFieldGroup,
  Typography,
} from '@jane/shared/reefer';

const Wrapper = styled.div({
  display: 'flex',
  margin: '0 auto',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '896px',
  gap: '24px',
});

const CardGroup = styled(Card.Group)(({ theme }) => ({
  boxShadow: `0px 2px 16px 0px ${theme.colors.grays.hover}`,
}));

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '80px',
});

const ButtonDisplay = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '24px',
  backgroundColor: theme.colors.grays.ultralight,
  width: '176px',
  height: '128px',
}));

const StyledButton = styled.div<{ borderRadius: number }>(
  ({ borderRadius, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: `${borderRadius}px`,
    backgroundColor: theme.colors.grays.light,
    width: '96px',
    height: '48px',
    border: `2px solid ${theme.colors.grays.mid}`,
  })
);

type ButtonType = 'round-corner' | 'square-corner';

const BUTTON_RADIUS: Record<ButtonType, number> = {
  'round-corner': 24,
  'square-corner': 0,
};

export const ButtonConfigurations = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(businessPaths.plus());
  };

  const onSelection = (type: ButtonType) => {
    console.log('type:', type);

    // TODO: Request to BE to save selection; use value from BUTTON_RADIUS[type]
  };

  return (
    <Wrapper>
      <CardGroup width={896} mx="auto">
        <Card.Content>
          <Header>
            <ChevronLeftIcon
              ml={8}
              onClick={() => onBack()}
              altText="Go back"
            />
            <Typography ml={24} variant="header-bold" branded>
              Buttons
            </Typography>
          </Header>
        </Card.Content>
        <Card.Content border="grays-light" borderWidth="1px 0 0 0">
          <Flex
            gap={56}
            ariaLabel="button configuration selection"
            justifyContent="center"
            mt={24}
            mb={24}
          >
            <RadioFieldGroup
              labelHidden
              name="button-selections"
              defaultChecked="round-corner"
              onChange={onSelection}
              options={[
                {
                  label: '',
                  id: 'round-corner',
                  value: 'round-corner',
                  wrapper: (children) => (
                    <Flex
                      flexDirection="column"
                      gap={24}
                      alignItems="center"
                      ariaLabel="round corner button selection"
                    >
                      <Flex
                        flexDirection="column"
                        ariaLabel="round corner title"
                      >
                        <Typography variant="body-bold" textAlign="center">
                          Round corner
                        </Typography>
                        <Typography variant="body" textAlign="center">
                          (Default)
                        </Typography>
                      </Flex>
                      <Box height={24} position="relative">
                        {children}
                      </Box>
                      <ButtonDisplay>
                        <StyledButton
                          borderRadius={BUTTON_RADIUS['round-corner']}
                        >
                          <Typography variant="body-bold">Button</Typography>
                        </StyledButton>
                      </ButtonDisplay>
                    </Flex>
                  ),
                },
                {
                  label: '',
                  id: 'square-corner',
                  value: 'square-corner',
                  wrapper: (children) => (
                    <Flex
                      flexDirection="column"
                      gap={24}
                      alignItems="center"
                      ariaLabel="square corner button selection"
                    >
                      <Flex
                        flexDirection="column"
                        mb={24}
                        ariaLabel="square corner title"
                      >
                        <Typography variant="body-bold" textAlign="center">
                          Square corner
                        </Typography>
                      </Flex>
                      <Box height={24} position="relative">
                        {children}
                      </Box>
                      <ButtonDisplay>
                        <StyledButton
                          borderRadius={BUTTON_RADIUS['square-corner']}
                        >
                          <Typography variant="body-bold">Button</Typography>
                        </StyledButton>
                      </ButtonDisplay>
                    </Flex>
                  ),
                },
              ]}
            />
          </Flex>
        </Card.Content>
      </CardGroup>
    </Wrapper>
  );
};

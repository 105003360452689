import styled from '@emotion/styled';

import { Banner, ErrorIcon, List, Typography } from '@jane/shared/reefer';

export const BannerText = styled('p')({ whiteSpace: 'pre-wrap' });

export const ErrorBanner = ({
  closeBanner,
  messages,
}: {
  closeBanner: () => void;
  messages: string[];
}) => {
  return (
    <Banner
      icon={<ErrorIcon />}
      variant="error"
      full
      onDismiss={closeBanner}
      label={
        <>
          <Typography variant="body-bold">Image upload errors</Typography>
          <List label="image upload errors" p={0}>
            {messages.map((m) => (
              <li style={{ listStyleType: 'disc' }} key={m}>
                <Typography variant="body">{m}</Typography>
              </li>
            ))}
          </List>
        </>
      }
    />
  );
};

import styled from '@emotion/styled';
import { useState } from 'react';

import {
  Flex,
  Hide,
  SortDownIcon,
  SortUpIcon,
  Typography,
} from '@jane/shared/reefer';

const StyledFlex = styled(Flex)<{ isCollapsed?: boolean }>(
  ({ theme, isCollapsed }) => ({
    backgroundColor: theme.colors.grays.ultralight,
    padding: '16px 8px',
    borderTop: `1px solid ${theme.colors.grays.light}`,
    borderBottom: isCollapsed
      ? 'none'
      : `1px solid ${theme.colors.grays.light}`,
  })
);

export const ExpandableNav = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  const [isHidden, setIsHidden] = useState(false);
  return (
    <>
      <StyledFlex
        isCollapsed={isHidden}
        ariaLabel={title}
        onClick={() => setIsHidden(!isHidden)}
      >
        {isHidden ? <SortUpIcon /> : <SortDownIcon />}
        <Typography ml={8} variant="body-bold">
          {title}
        </Typography>
      </StyledFlex>
      <Hide isHidden={isHidden}>{children}</Hide>
    </>
  );
};

import type { CartStatusOptions } from '@jane/shared/models';

export const DEFAULT_STATUS_MESSAGES: Record<CartStatusOptions, string> = {
  verification:
    'Your pickup/delivery order scheduled for *date* from *time* has been sent to the store. You can reach them at *phone number* with any questions or concerns. Thank you!',
  preparing: 'Your items are being prepared by the store',
  delivery_started: 'Your reservation is on the way.',
  ready_for_pickup: 'Your reservation is ready for pickup.',
  dismissed:
    'Pickup request declined. Call *phone number* for more information.',
};

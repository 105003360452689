import { useQuery } from '@tanstack/react-query';

import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';
import type { StoreSpecial } from '@jane/shared/models';

/**
 * Legacy v1 specials APIs, only used on menu customization pages right now
 * TODO: Probably should add v2 endpoints for this at some point
 */

export type StoreSpecialsResponse = {
  specials: StoreSpecial[];
};

const fetchAllStoreSpecials = (
  storeId: string
): Promise<StoreSpecialsResponse> =>
  api.get(`${config.apiPath}/business/stores/${storeId}/specials`);
export const useFetchAllStoreSpecials = (storeId: string) =>
  useQuery({
    queryKey: ['specials_legacy', storeId],
    queryFn: () => fetchAllStoreSpecials(storeId),
  });

const fetchCurrentStoreSpecials = (
  storeId: string,
  pageOffset: number,
  limit = 15
): Promise<StoreSpecialsResponse> =>
  api.get(
    `${config.apiPath}/stores/${storeId}/specials/page?offset=${pageOffset}&limit=${limit}`
  );
export const useCurrentStoreSpecials = (
  storeId: string,
  pageOffset: number,
  limit = 15
) =>
  useQuery({
    queryKey: ['current_specials_legacy', storeId, pageOffset, limit],
    queryFn: () => fetchCurrentStoreSpecials(storeId, pageOffset, limit),
  });

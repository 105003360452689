import type { ConsentRequirement } from '@jane/shared/models';

export const MAX_LENGTH = 20_000;

export const AGREEMENT_TYPES = [
  'Terms of Service',
  'Marketing Opt-In',
  'Product Substitution Opt-In',
  'Customer Requirement',
  'Identification Requirement',
  'Payment Requirement',
  'Notification',
  'Other',
];

export const CONSENT_REQUIREMENTS: ConsentRequirement[] = [
  'required_always',
  'required_once',
  'optional_always',
  'optional_until_consented',
];

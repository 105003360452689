import { useTheme } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import type { UpdateCommsBannerParams } from '@jane/business-admin/data-access';
import {
  useStoreMenu,
  useStoreSettings,
  useStores,
  useUpdateCommsBanner,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useDebouncedTrack,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import {
  ErrorReasons,
  EventNames,
  ModalNames,
  SearchSubjects,
  SettingNames,
  normalizePath,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import type { FilterNames } from '@jane/business-admin/util';
import { StoreSelectModal } from '@jane/shared-b2b/components';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Button,
  Flex,
  Form,
  FormValidationError,
  Modal,
  Skeleton,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';
import { ImageDisplay } from './ImageDisplay';
import { EXPANDED_STATES, TextDisplay } from './TextDisplay';

const FORM_ERROR_NAME = 'comms-banner-errors';
const BANNER_TYPES = {
  text: 'text',
  image: 'image',
} as const;

const addHashToHexColor = (color: string) => {
  return color.startsWith('#') ? color : '#' + color;
};

export const CommsBannerModal = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const { pathname } = useLocation();
  const { id = '' } = useParams<'id'>();
  const { data: menuRowData, isFetched: menuDataFetched } = useStoreMenu(id);
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(id);
  const {
    mutateAsync: updateCommsBanner,
    isSuccess: updateCommsBannerSuccess,
  } = useUpdateCommsBanner(id);
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating communication banner. Please try again'
  );

  const commsBannerData = menuRowData?.communication_banner;

  const hasImageCommsBannerAccess = useFlag(FLAGS.scHasImageCommsBannerAccess);

  const isVisible = useMemo(() => {
    if (commsBannerData?.enabled === undefined) {
      return true;
    }
    return commsBannerData?.enabled;
  }, [commsBannerData]);

  const toast = useToast();
  const theme = useTheme();

  const stores: (number | string)[] = [];
  const formMethods = useForm({
    defaultValues: {
      [FORM_ERROR_NAME]: '',
      alt_text: commsBannerData?.alt_text || '',
      banner_type: commsBannerData?.banner_type || BANNER_TYPES.text,
      button_fill_color: commsBannerData?.button_fill_color
        ? addHashToHexColor(commsBannerData?.button_fill_color)
        : addHashToHexColor(theme.colors.grays.white),
      button_link: commsBannerData?.button_link || '',
      button_text_color: commsBannerData?.button_text_color
        ? addHashToHexColor(commsBannerData?.button_text_color)
        : addHashToHexColor(theme.colors.grays.black),
      button_text: commsBannerData?.button_text || '',
      enabled: isVisible,
      header_text_color: commsBannerData?.header_text_color
        ? addHashToHexColor(commsBannerData?.header_text_color)
        : addHashToHexColor(theme.colors.grays.black),
      image_opacity: commsBannerData?.image_opacity || 100,
      image: commsBannerData?.image || '',
      is_expanded: !!commsBannerData?.is_expanded,
      message: commsBannerData?.message || '',
      stores: stores,
      title: commsBannerData?.title || '',
    },
  });

  const {
    setValue,
    setError,
    getValues,
    watch,
    formState: { isDirty, dirtyFields, isValid, errors },
  } = formMethods;

  const bannerType = watch('banner_type');

  const {
    modalOpen: commsBannerPublishToModalOpen,
    openModal: setCommsBannerPublishToModalOpen,
    closeModal: setCommsBannerPublishToModalClose,
  } = useModalActionsWithTracking(ModalNames.CommsBannerPublishTo);

  const formatExpanded = (bannerType: string, isExpanded: string | boolean) => {
    if (bannerType === BANNER_TYPES.text) {
      return isExpanded === EXPANDED_STATES.expanded
        ? true
        : isExpanded === EXPANDED_STATES.collapsed
        ? false
        : isExpanded;
    } else {
      return true;
    }
  };

  const validateMessage = (message: string) => {
    if (!message || message === '<p><br></p>') {
      setError(FORM_ERROR_NAME, {
        type: 'onSubmit',
        message: 'Message is required.',
      });

      return;
    }
  };

  const setRequestData = (data: any) => {
    const {
      banner_type: bannerType,
      is_expanded: rawIsExpanded,
      message,
      [FORM_ERROR_NAME]: error,
      ...otherData
    } = data;

    if (bannerType === BANNER_TYPES.text) {
      validateMessage(message);
    }

    return {
      ...otherData,
      message,
      banner_type: bannerType,
      is_expanded: formatExpanded(bannerType, rawIsExpanded),
    };
  };

  const onSelectStores = (storeIds: number[]) => {
    setValue('stores', storeIds, { shouldDirty: true });
    const requestData = setRequestData(getValues() as UpdateCommsBannerParams);

    const image = getValues('image');

    if (bannerType === 'image' && !image) {
      setError(FORM_ERROR_NAME, {
        message:
          'Image is required for this type of banner. Please select `text` if you do not want to include an image.',
      });
      return;
    }

    const trackSelectStores = (eventProps = {}) => {
      track({
        event: EventNames.EditedStoreMenuConfig,
        modal_name: ModalNames.CommsBannerPublishTo,
        setting_name: 'Communication Banner',
        action: 'update',
        url: normalizePath(pathname, id),
        changed_attributes: Object.keys(dirtyFields),
        successful: true,
      });
    };

    const submitMethod = async () => {
      await updateCommsBanner(requestData);
      trackSelectStores();
    };

    return catchSubmitErrors({
      submitMethod,
      requestData: getValues(),
      onValidationError: (validationErrors: Record<string, unknown>) => {
        trackSelectStores({
          successful: false,
          error_reason: ErrorReasons.InvalidParams,
        });
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      callback: () => {
        trackSelectStores({
          successful: false,
          error_reason: ErrorReasons.ServerError,
        });

        throw new Error(
          'Error updating communication banner. Please try again'
        );
      },
    });
  };

  const onSubmit = (data: any) => {
    const { image, ...otherData } = data;
    const requestData = setRequestData(otherData);

    if (bannerType === 'image' && !image) {
      setError(FORM_ERROR_NAME, {
        message:
          'Image is required for this type of banner. Please select `text` if you do not want to include an image.',
      });
      return;
    }

    const trackSubmit = (eventProps = {}) => {
      track({
        event: EventNames.EditedStoreMenuConfig,
        modal_name: ModalNames.CommsBanner,
        setting_name: 'Communication Banner',
        action: 'update',
        url: normalizePath(pathname, id),
        changed_attributes: Object.keys(dirtyFields),
        successful: true,
        ...eventProps,
      });
    };

    const submitMethod = async () => {
      await updateCommsBanner(requestData);
      trackSubmit();
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.InvalidParams,
        });
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      callback: () => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.ServerError,
        });
        throw new Error(
          'Error updating communication banner. Please try again'
        );
      },
    });
  };

  const onFilterChange = (filterName: FilterNames, value?: string) => {
    if (!value) {
      track({
        event: EventNames.DeselectedFilter,
        filter_name: filterName,
        all_selected_values: [],
        deselected_value: 'all',
        modal_name: ModalNames.CommsBannerPublishTo,
        url: normalizePath(pathname, id),
      });
      return;
    }

    track({
      event: EventNames.SelectedFilter,
      filter_name: filterName,
      all_selected_values: [value],
      selected_value: value,
      modal_name: ModalNames.CommsBannerPublishTo,
      url: normalizePath(pathname, id),
    });
  };

  const onToggleAllStores = (selected: boolean) => {
    track({
      event: selected
        ? EventNames.SelectedCheckbox
        : EventNames.DeselectedCheckbox,
      checkbox_label: selected ? 'Select all stores' : 'Deselect all stores',
      modal_name: ModalNames.CommsBannerPublishTo,
    });
  };

  const onToggleStore = (storeId: string, selected: boolean) => {
    track({
      event: selected
        ? EventNames.SelectedCheckbox
        : EventNames.DeselectedCheckbox,
      checkbox_label: `${selected ? 'Select' : 'Deselect'} store ID ${storeId}`,
      modal_name: ModalNames.CommsBannerPublishTo,
    });
  };

  useEffect(() => {
    if (updateCommsBannerSuccess) {
      toast.add({
        label: 'Communication banner updated.',
        variant: 'success',
      });
      closeModal();
    }
  }, [updateCommsBannerSuccess]);

  const { data: storesData, isFetching: isFetchingStores } = useStores();
  const trackSearch = useDebouncedTrack(1000);

  return (
    <>
      <ConfirmWrapperWithTracking
        open
        setOpen={closeModal}
        variant="standard"
        hasChanges={isDirty}
        modalName={ModalNames.CommsBanner}
      >
        <Form.BaseForm
          name="comms banner modal"
          onSubmit={onSubmit}
          formMethods={formMethods}
          formErrorName={FORM_ERROR_NAME}
        >
          <Modal.Header
            title="Communication banner"
            subtitle={storeSettingsLoading ? '' : storePayload?.store.name}
            actions={
              <>
                <Button
                  variant="secondary"
                  label="Publish to"
                  ml={16}
                  onClick={() => {
                    validateMessage(getValues('message'));
                    if (!errors[FORM_ERROR_NAME]) {
                      setCommsBannerPublishToModalOpen();
                    }
                  }}
                  disabled={!isValid}
                />
                <Form.SubmitButton variant="primary" label="Publish" ml={16} />
              </>
            }
          />
          <Modal.Content>
            {menuDataFetched ? (
              <>
                <Form.ErrorBanner name={FORM_ERROR_NAME} />
                <Form.SwitchField
                  name="enabled"
                  label="Visible"
                  mb={hasImageCommsBannerAccess ? 0 : 24}
                  onChange={(visible) => {
                    // Prevent multiple track calls with the existing value when modal opens:
                    if (!isDirty && visible === commsBannerData?.enabled)
                      return;

                    track({
                      event: EventNames.ToggleVisibility,
                      final_state: visible ? 'visible' : 'hidden',
                      object: ModalNames.CommsBanner,
                      successful: true,
                      trigger_source_id: 'store banner',
                    });
                  }}
                />
                {hasImageCommsBannerAccess ? (
                  <>
                    <Typography mt={24} mb={16} variant="body-bold">
                      Type
                    </Typography>
                    <Form.RadioFieldGroup
                      name="banner_type"
                      row
                      options={[
                        {
                          id: BANNER_TYPES.text,
                          label: 'Text',
                          value: 'text',
                        },
                        {
                          id: BANNER_TYPES.image,
                          label: 'Image',
                          value: 'image',
                        },
                      ]}
                      defaultChecked={commsBannerData?.banner_type}
                      onChange={() => {
                        track({
                          event: EventNames.ModifiedSetting,
                          setting_name: SettingNames.CommsBannerType,
                          revert: false,
                          modal_name: ModalNames.CommsBanner,
                        });
                      }}
                    />
                    {bannerType === BANNER_TYPES.text ? (
                      <TextDisplay commsBannerData={commsBannerData} />
                    ) : (
                      <ImageDisplay commsBannerData={commsBannerData} />
                    )}
                  </>
                ) : (
                  <TextDisplay commsBannerData={commsBannerData} />
                )}
              </>
            ) : (
              <Skeleton animate>
                <Skeleton.Bone width="100px" />
                <Flex mt={64}>
                  <Skeleton.Bone width="112px" mr={24} />
                  <Skeleton.Bone width="112px" />
                </Flex>
                <Skeleton.Bone mt={64} />
                <Skeleton.Bone height="450px" mt={64} />
              </Skeleton>
            )}
          </Modal.Content>
        </Form.BaseForm>
      </ConfirmWrapperWithTracking>

      {commsBannerPublishToModalOpen && (
        <StoreSelectModal
          onSearchCallback={(query: string, successful?: boolean) =>
            trackSearch({
              event: EventNames.Search,
              arguments: query,
              subject: SearchSubjects.Stores,
              successful,
            })
          }
          storesData={storesData || []}
          isFetchingStores={isFetchingStores}
          selectedStoreIds={[id]}
          onSubmit={onSelectStores}
          closeModal={() => setCommsBannerPublishToModalClose()}
          onFilterChange={onFilterChange}
          onToggleAll={onToggleAllStores}
          onToggleStore={onToggleStore}
        />
      )}
    </>
  );
};

import styled from '@emotion/styled';

import { Box } from '@jane/shared/reefer';

export const StyledGrid = styled(Box)(({ gap = 4 }: { gap?: number }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap,
  width: '100%',
  justifyItems: 'stretch',
  justifyContent: 'flex-end',
}));

export const StyledBox = styled(Box)<{ isMarketplace: boolean }>(
  ({ isMarketplace }) => ({
    borderLeft: '0px',
    borderRight: '0px',
    borderTop: isMarketplace ? '0px' : '1px',
  })
);

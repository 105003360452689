import type { StaffMember } from '@jane/business-admin/types';

const commonRoleOptions = [
  { label: 'Client', value: 'client' },
  { label: 'Manager', value: 'manager' },
  { label: 'Staff', value: 'staff_member' },
];

/**
auth_user = manager operating the page

We want to show the list of roles based on the following criteria:

if auth_user has edit_staff and edit_client_role permission, they should see all 3 roles
if auth_user has only edit_staff and not edit_client_role permission, they should see only staff and manager
if auth_user has only edit_client_role and not edit_staff permission, they should see only client

No matter what the logged in user's permissions are, the dropdown should still display the staffmember's current role
*/

export const getRolesOptions = (
  userCanEditClient: boolean,
  userCanEditStaff: boolean,
  isCreateMode: boolean,
  staffMember: StaffMember | null
) => {
  const userCanEditAllRoles = userCanEditClient && userCanEditStaff;

  const filteredOptions = commonRoleOptions.filter((role) => {
    if (isCreateMode) {
      return (
        (userCanEditClient && role.value === 'client') ||
        (userCanEditStaff && role.value !== 'client')
      );
    } else {
      return (
        userCanEditAllRoles ||
        (userCanEditClient &&
          role.value === 'client' &&
          staffMember?.role === 'client') ||
        (userCanEditStaff &&
          role.value !== 'client' &&
          staffMember?.role !== 'client')
      );
    }
  });

  if (!filteredOptions.length)
    return commonRoleOptions.filter((role) => role.value === staffMember?.role);

  return filteredOptions;
};

export const shouldShowPermissions = (
  isCreateMode: boolean,
  staffMember: StaffMember | null,
  selectedRole: string,
  userCanEditClient: boolean
) => {
  if ((!isCreateMode && !staffMember?.permissions) || selectedRole !== 'client')
    return false;

  if (isCreateMode && userCanEditClient) return true;

  if (
    !isCreateMode &&
    (staffMember?.role === 'client' || selectedRole === 'client') &&
    userCanEditClient
  )
    return true;

  return false;
};

export const shouldShowDelete = (
  isCreateMode: boolean,
  staffMember: StaffMember | null,
  selectedRole: string,
  userCanEditClient: boolean,
  userCanEditStaff: boolean
) => {
  if (staffMember?.pushback_integration || isCreateMode) return false;

  if (
    (staffMember?.role === 'client' || selectedRole === 'client') &&
    !userCanEditClient
  )
    return false;

  if (
    (staffMember?.role !== 'client' || selectedRole !== 'client') &&
    !userCanEditStaff
  )
    return true;

  return true;
};

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';

import type { MenuProductToPreview } from '@jane/business-admin/types';
import { BODY_LINE_HEIGHT } from '@jane/business-admin/util';
import { useImage } from '@jane/shared/components';
import { Button, Flex, Image, Skeleton, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import { Table } from '@jane/shared/reefer-table';

import { CollapsedBorderTable } from '../../../../TableWithBorderSeparator';

const TableHeaders = () => {
  return (
    <Table.Head>
      <Row hideBottomBorder>
        <Table.Cell>
          <Typography variant="caps" color="grays-mid">
            Item
          </Typography>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell>
          <Typography variant="caps" color="grays-mid">
            Brand
          </Typography>
        </Table.Cell>
        <Table.Cell>
          <Typography variant="caps" color="grays-mid">
            Category
          </Typography>
        </Table.Cell>
        <Table.Cell>
          <Typography variant="caps" color="grays-mid">
            Subcategory
          </Typography>
        </Table.Cell>
      </Row>
    </Table.Head>
  );
};

const Row = styled.tr<{ hideBottomBorder: boolean }>(({ hideBottomBorder }) => {
  const theme = useTheme();

  return {
    '& td:first-of-type, th:first-of-type': {
      ...spacing({ pl: 24 }),
    },
    '& td:last-of-type, th:last-of-type': {
      ...spacing({ pr: 24 }),
    },
    '& td': {
      borderBottomColor: hideBottomBorder
        ? theme.colors.background
        : theme.colors.grays.light,
    },
    display: 'table-row',
    verticalAlign: 'middle',
    zIndex: 1,
  };
});

const ProductRow = ({
  isLast,
  product,
}: {
  isLast: boolean;
  product: MenuProductToPreview;
}) => {
  const { imageSrc } = useImage(product);

  const theme = useTheme();

  return (
    <Row hideBottomBorder={isLast}>
      <Table.Cell>
        <Image
          altText={product.name || 'product'}
          src={imageSrc}
          borderRadius={theme.borderRadius.sm?.toString()}
          border={true}
          height="64px"
          width="64px"
        />
      </Table.Cell>
      <Table.Cell>
        <Typography variant="body-bold">{product.name}</Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography>{product.brand}</Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography>
          {capitalize(product.kind) || capitalize(product.category || '')}
        </Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography>
          {startCase(product.kind_subtype || '') ||
            startCase(product.subcategory || '')}
        </Typography>
      </Table.Cell>
    </Row>
  );
};

const LoadingProductRow = ({ isLast }: { isLast: boolean }) => {
  return (
    <Row hideBottomBorder={isLast}>
      <Table.Cell>
        <Skeleton animate>
          <Skeleton.Bone height={64} width={64} />
        </Skeleton>
      </Table.Cell>
      <Table.Cell>
        <Skeleton animate>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width={100} />
        </Skeleton>
      </Table.Cell>
      <Table.Cell>
        <Skeleton animate>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="100%" />
        </Skeleton>
      </Table.Cell>
      <Table.Cell>
        <Skeleton animate>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="100%" />
        </Skeleton>
      </Table.Cell>
      <Table.Cell>
        <Skeleton animate>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="100%" />
        </Skeleton>
      </Table.Cell>
    </Row>
  );
};

export const MenuRowProductTable = ({
  isLoading,
  selectedProducts,
  totalNumberProductsSelected,
}: {
  isLoading: boolean;
  selectedProducts?: any[];
  totalNumberProductsSelected: number;
}) => {
  const [shownProducts, setShownProducts]: any[] = useState([]);
  const [showAllButtonText, setShowAllButtonText] = useState('Show all');

  useEffect(() => {
    // Reset button when selections change
    setShowAllButtonText('Show all');
  }, [selectedProducts]);

  const onShowAllClick = () => {
    if (shownProducts.length === 10) {
      setShownProducts(selectedProducts);
      setShowAllButtonText('Show less');
    } else {
      setShownProducts(selectedProducts?.slice(0, 10));
      setShowAllButtonText('Show all');
    }
  };

  useEffect(() => {
    setShownProducts(selectedProducts?.slice(0, 10) || []);
  }, [selectedProducts]);

  return isLoading || shownProducts.length > 0 ? (
    <>
      <CollapsedBorderTable>
        <TableHeaders />
        <Table.Body>
          {isLoading
            ? new Array(5)
                .fill(null)
                .map((_, index) => (
                  <LoadingProductRow
                    isLast={index == 4}
                    key={`loading-item-${index}`}
                  />
                ))
            : shownProducts.map((product: any, index: number) => {
                const isLast = index + 1 === totalNumberProductsSelected;

                return (
                  <ProductRow
                    key={product.id}
                    product={product}
                    isLast={isLast}
                  />
                );
              })}
        </Table.Body>
      </CollapsedBorderTable>
      {!isLoading && totalNumberProductsSelected > 10 ? (
        <Flex justifyContent="center" mt={24}>
          <Button
            label={showAllButtonText}
            variant="secondary"
            onClick={onShowAllClick}
          />
        </Flex>
      ) : null}
    </>
  ) : (
    <Typography>No products added.</Typography>
  );
};

import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { ModalNames } from '@jane/business-admin/util';
import { Button, Flex, Typography } from '@jane/shared/reefer';

import { ArrangeMenuRowsModal } from '../ArrangeMenuRowsModal';
import { MenuRowModal } from '../menuRowModal/MenuRowModal';

export const MenuRowHeader = () => {
  const {
    modalOpen: arrangeMenuRowsModalOpen,
    openModal: setArrangeMenuRowsModalOpen,
    closeModal: setArrangeMenuRowsModalClose,
  } = useModalActionsWithTracking(ModalNames.ArrangeMenuRows);
  const {
    modalOpen: menuRowModalOpen,
    openModal: setMenuRowModalOpen,
    closeModal: setMenuRowModalClose,
  } = useModalActionsWithTracking(ModalNames.CreateMenuRow);

  return (
    <Flex py={40} justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column">
        <Typography as="h2" variant="header-bold" branded>
          Menu rows
        </Typography>
        <Typography color="grays-black">
          Each customer will see these rows of products in the featured tab.
        </Typography>
      </Flex>
      <Flex>
        <Button
          label="Arrange"
          onClick={() => setArrangeMenuRowsModalOpen()}
          variant="secondary"
        />
        <Button
          label="Add menu row"
          ml={16}
          onClick={() => setMenuRowModalOpen()}
          variant="primary"
        />
      </Flex>
      {arrangeMenuRowsModalOpen && (
        <ArrangeMenuRowsModal
          closeModal={() => setArrangeMenuRowsModalClose()}
        />
      )}
      {menuRowModalOpen && (
        <MenuRowModal closeModal={() => setMenuRowModalClose()} />
      )}
    </Flex>
  );
};

import { StaticContent } from '@jane/business-admin/components';
import { useTermsOfService } from '@jane/business-admin/data-access';

export const TermsOfService = () => {
  const { data: termsOfService } = useTermsOfService();

  return (
    <StaticContent
      content={termsOfService?.text ?? ''}
      header="Terms of Service"
    />
  );
};

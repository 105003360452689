import styled from '@emotion/styled';

import { Box, Card } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

export const PaymentsCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.colors.grays.ultralight,
  borderRadius: theme.borderRadius.lg,
  ...spacing({ p: 24 }),
}));

export const FakeRadio = styled(Box)<{ selected: boolean }>(
  ({ selected, theme: { colors } }) => ({
    backgroundColor: selected ? colors.primary.main : colors.grays.white,
    border: `2px solid ${selected ? colors.primary.main : colors.grays.mid}`,
    borderRadius: '50%',
    display: 'block',
    div: {
      borderRadius: '50%',
    },
  })
);

import omit from 'lodash/omit';
import { useCallback, useEffect, useState } from 'react';

import { useSaveStoreTaxAndFees } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Flex,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import { DollarInput } from '../../../../../DollarInput';
import { PercentageInput } from '../../../../../PercentageInput';

interface Props {
  onClose: () => void;
  open: boolean;
  salesTaxRate?: number;
  serviceFeeAmount?: number;
  storeId: number;
  subtitle: string;
  taxIncluded?: boolean;
}

type FormData = {
  sales_tax_rate: number;
  service_fee_amount: number;
  service_fee_enabled: boolean;
  tax_included: boolean;
};

const taxBehaviorOptions = [
  {
    label: 'Taxes are included in product price',
    id: 'true',
    value: 'true',
  },
  {
    label: 'Apply taxes at checkout',
    id: 'false',
    value: 'false',
  },
];

const FORM_ERROR_NAME = 'taxes-and-fees-error';

export function TaxesAndFeesModal({
  subtitle,
  storeId,
  open,
  taxIncluded,
  salesTaxRate,
  serviceFeeAmount,
  onClose,
}: Props) {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating taxes and fees. Please try again.'
  );

  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const toast = useToast();
  const { mutateAsync: saveStoreTaxAndFees, isSuccess: saveSuccess } =
    useSaveStoreTaxAndFees(storeId);

  const [currentServiceFeeEnabled, setCurrentServiceFeeEnabled] = useState(
    !!serviceFeeAmount
  );

  const [currentTaxIncluded, setCurrentTaxIncluded] = useState(taxIncluded);

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Taxes and fees updated',
        variant: 'success',
      });
      onClose();
    }
  }, [saveSuccess]);

  const onSubmit = (data: FormData) => {
    data.service_fee_amount = currentServiceFeeEnabled
      ? data.service_fee_amount
      : 0;
    data.sales_tax_rate = currentTaxIncluded
      ? 0
      : (data.sales_tax_rate || 0) / 100;
    return catchSubmitErrors({
      submitMethod: () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.TaxesAndFees,
          changed_attributes: Object.keys(dirtyFields),
        });

        return saveStoreTaxAndFees(omit(data, 'service_fee_enabled'));
      },
      requestData: data,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  const toggleServiceFee = useCallback(
    (enabled: boolean) => setCurrentServiceFeeEnabled(enabled),
    []
  );

  const toggleTaxIncluded = useCallback(
    (included: string) => setCurrentTaxIncluded(included === 'true'),
    []
  );

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={onClose}
      hasChanges={isDirty}
      variant="standard-dialogue"
      modalName={ModalNames.TaxesAndFees}
    >
      <Form.BaseForm
        name="checkout options"
        onSubmit={onSubmit}
        formMethods={formMethods}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Taxes and fees"
          subtitle={subtitle}
          actions={<Form.SubmitButton label="Save" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Form.RadioFieldGroup
            name="tax_included"
            options={taxBehaviorOptions}
            onChange={toggleTaxIncluded}
            defaultChecked={
              currentTaxIncluded ? currentTaxIncluded.toString() : 'false'
            }
            legend={<Typography variant="body-bold">Tax behavior</Typography>}
          />
          {!currentTaxIncluded && (
            <Flex width="50%" pr={24}>
              <PercentageInput
                allowedDecimalPlaces={3}
                step={0.001}
                label="Sales tax rate"
                name="sales_tax_rate"
                defaultValue={(salesTaxRate || 0) * 100}
                placeholder="5.5"
                endUnit="%"
                width="100%"
                disabled={currentTaxIncluded}
                min={0}
                max={100}
              />
            </Flex>
          )}
          <Modal.ContentDivider />

          <Flex>
            <Flex flexDirection="column" width="50%" mr={24}>
              <Typography variant="body-bold" mb={24}>
                Service fee
              </Typography>
              <Form.CheckboxField
                label="Add service fee"
                name="service_fee_enabled"
                onChange={toggleServiceFee}
                defaultChecked={!!serviceFeeAmount}
              />
            </Flex>
            <Flex flexDirection="column" width="50%">
              <DollarInput
                label="Fee amount (dollars)"
                name="service_fee_amount"
                defaultValue={serviceFeeAmount}
                mb={16}
                disabled={!currentServiceFeeEnabled}
                required={currentServiceFeeEnabled}
              />
            </Flex>
          </Flex>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
}

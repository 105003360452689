import { useContext } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Form, Typography, useFormContext } from '@jane/shared/reefer';

export const CartTotal = ({
  isCreateMode,
  name,
}: SpecialTypeProps['cart_total'] & {
  isCreateMode: boolean;
}) => {
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);

  const { getValues } = useFormContext();

  const cartTotalValue = getValues(`${name}.threshold`);
  const showInputs = (isJanePosSynced || !posSynced) && !isReadOnly;

  return (
    <Flex mb={24} width={'100%'} flexDirection="column">
      {isCreateMode || (!isCreateMode && showInputs) ? (
        <>
          <Form.NumberField
            width="50%"
            name={`${name}.threshold`}
            label="Cart total threshold"
            mb={4}
          />
          <Typography color="grays-mid" variant="body" mt={16}>
            Enter the cart total the customer must reach to activate this
            special.
          </Typography>
        </>
      ) : (
        <Flex width="50%" flexDirection="column">
          <Typography
            color="grays-black"
            variant="body-bold"
            data-testid="non-janepos-synced-cart-total-threshold"
          >
            Cart total threshold
          </Typography>
          <Typography color="grays-black" variant="body" mt={4}>
            {cartTotalValue}
          </Typography>
        </Flex>
      )}
    </Flex>
  );
};

import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';

import type { CheckoutAgreementV2 } from '@jane/shared/models';
import type { FlexProps } from '@jane/shared/reefer';
import { Flex, List, Typography } from '@jane/shared/reefer';

type AgreementExcerpt = Pick<
  CheckoutAgreementV2,
  'consent_requirement' | 'reservation_mode'
>;
interface AgreementConditionsProps extends AgreementExcerpt {
  showHeading?: boolean;
}

export const AgreementConditions = ({
  consent_requirement,
  reservation_mode,
  showHeading = false,
  ...flexProps
}: AgreementConditionsProps & FlexProps) => {
  return (
    <Flex {...flexProps} flexDirection="column">
      {showHeading && (
        <Typography variant="caps" color="grays-mid" mb={8}>
          Conditions
        </Typography>
      )}
      <List label="Agreement Conditions">
        <List.Item px={0} pt={0} pb={12}>
          <Typography>
            {reservation_mode
              ? `${capitalize(reservation_mode)} Only`
              : 'All Reservation Modes'}
          </Typography>
        </List.Item>
        <List.Item px={0} pt={0} pb={12}>
          <Typography>{startCase(consent_requirement)}</Typography>
        </List.Item>
      </List>
    </Flex>
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Menu24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Menu 24">
      <path
        fill="#0E0E0E"
        d="M24 7a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h22a1 1 0 0 0 1-1Zm0 10a1 1 0 0 0-1-1H1a1 1 0 1 0 0 2h22a1 1 0 0 0 1-1Z"
        className="SVG"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Menu24,
  sm: Menu24,
  lg: Menu24,
  xl: Menu24,
  xxl: Menu24,
  xxxl: Menu24,
};

export const MenuIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'menu-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

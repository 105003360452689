/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Copy24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Copy 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M18 18v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-2Zm2-14H8v2h8a2 2 0 0 1 2 2v8h2V4ZM4 8h12v12H4V8Z"
        className="SVG"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Copy24,
  sm: Copy24,
  lg: Copy24,
  xl: Copy24,
  xxl: Copy24,
  xxxl: Copy24,
};

export const CopyIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'copy-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

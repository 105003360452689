import styled from '@emotion/styled';

import { ChevronRightIcon, Flex, Link } from '@jane/shared/reefer';

type PositionType = 'first' | 'last' | 'single';

const StyledLink = styled(Link)<{ position?: PositionType }>(
  ({ position }) => ({
    width: '100%',
    paddingLeft: 40,
    paddingRight: 40,
    marginTop: position === 'first' || position === 'single' ? 40 : 12,
    marginBottom: position === 'last' || position === 'single' ? 40 : 12,
  })
);

/**
 * @param {Object} NavItemObject - An object containing the following properties: position, title, to.
 * @param {PositionType} NavItemObject.position - The position of the expandable nav item in the list.
 * @param {string} NavItemObject.title - The title of the expandable nav item.
 * @param {string} NavItemObject.to - The route that the expandable nav item should navigate a user to on click.
 */
export const ExpandableNavItem = ({
  title,
  to,
  position,
}: {
  position?: PositionType;
  title: string;
  to: string;
}) => {
  return (
    <StyledLink
      position={position}
      to={to}
      variant="minimal"
      typography="body-bold"
    >
      <Flex justifyContent="space-between">
        {title}
        <ChevronRightIcon />
      </Flex>
    </StyledLink>
  );
};

import dayjs from 'dayjs';

import type {
  AbbreviatedStoreV2,
  GlobalSpecialStore,
} from '@jane/business-admin/types';
import type { DeliveryConfigStrategy } from '@jane/shared/models';
import type { DayJs } from '@jane/shared/types';
import { getTimeRange, pluralize } from '@jane/shared/util';

export const StrategyOptions = {
  isRadiusDelivery: 'radius',
  isZipcodeDelivery: 'zipcode',
  isGeofenceDelivery: 'geofence',
};

// Copied from: libs/monolith/shared/src/lib/business/helpers/store.ts
export function getStoreDeliveryOptions(
  delivery_config: DeliveryConfigStrategy
) {
  if (!delivery_config.delivery_strategy) {
    return {
      radius: true,
      zipcode: false,
      geofence: false,
    };
  }

  const radius =
    delivery_config.delivery_strategy === StrategyOptions.isRadiusDelivery;
  const zipcode =
    delivery_config.delivery_strategy === StrategyOptions.isZipcodeDelivery;
  const geofence =
    delivery_config.delivery_strategy === StrategyOptions.isGeofenceDelivery;

  return {
    radius,
    zipcode,
    geofence,
  };
}

export const formatPhoneNumber = (phone: string) => {
  const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match ? `(${match[1]}) ${match[2]}–${match[3]}` : null;
};

export function getTwentyFourHours() {
  return getTimeRange({
    start: dayjs().startOf('day'),
    end: dayjs().endOf('day'),
    increment: 15,
    unit: 'minutes',
    outputFormat: 'h:mm A',
  }).map((time: string | DayJs) => ({
    value: time.toString(),
    label: time.toString(),
  }));
}

export const concatAddress = (
  store: AbbreviatedStoreV2 | GlobalSpecialStore
): string => {
  return `${store.address}${store.address2 ? `, ${store.address2}` : ''}, ${
    store.city
  }, ${store.state} ${store.zip}`;
};

export function formatDuration(
  leadTimeMinutes: number | null | undefined,
  minOrMax: string
) {
  function formatValue(value: number, noun: string) {
    if (value === 0) {
      return '';
    }

    return pluralize({ noun, number: value });
  }

  if (leadTimeMinutes === null || leadTimeMinutes === undefined) {
    return 'N/A';
  }

  if (leadTimeMinutes === 0) {
    return `No ${minOrMax}`;
  }

  if (leadTimeMinutes < 60) {
    const minuteValue = formatValue(leadTimeMinutes, 'minute');
    return `${minuteValue} ${minOrMax}`;
  }

  let hours = Math.floor(leadTimeMinutes / 60);
  const minutes = leadTimeMinutes - hours * 60;

  if (hours < 24) {
    const hourValue = formatValue(hours, 'hour');
    const minuteValue = formatValue(minutes, 'minute');

    if (minuteValue !== '') {
      return `${hourValue}, ${minuteValue} ${minOrMax}`;
    } else {
      return `${hourValue} ${minOrMax}`;
    }
  }

  const days = Math.floor(leadTimeMinutes / 60 / 24);
  const dayValue = formatValue(days, 'day');

  hours = hours - days * 24;
  const hourValue = formatValue(hours, 'hour');
  const minuteValue = formatValue(minutes, 'minute');

  if (hourValue && minuteValue) {
    return `${dayValue}, ${hourValue}, ${minuteValue} ${minOrMax}`;
  } else if (hourValue) {
    return `${dayValue}, ${hourValue} ${minOrMax}`;
  } else {
    return `${dayValue}${minuteValue && `,`} ${minuteValue} ${minOrMax}`;
  }
}

import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useMemo, useState } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { BulkWeightCondition, PriceId } from '@jane/shared/models';
import {
  Button,
  CheckboxField,
  Flex,
  Modal,
  NumberField,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';
import { allWeightIds, labelForWeightId } from '@jane/shared/util';

import type { ConditionType } from '../form';
import { FORM_FIELD_DEFAULT_MARGIN } from '../form';

type OnChangeSignature = { weightValue: PriceId } & (
  | {
      type: 'maximum_price' | 'minimum_price';
      value: number | string | undefined;
    }
  | {
      type: 'price_id';
      value: boolean;
    }
);

const WeightAndPriceRow = ({
  condition,
  fieldPrefix,
  index,
  isDisabled,
  onChange,
  weight,
}: {
  condition: BulkWeightCondition | undefined;
  fieldPrefix: string;
  index: number;
  isDisabled: boolean;
  onChange: ({ type, value, weightValue }: OnChangeSignature) => void;
  weight: {
    label: string | undefined;
    value: PriceId;
  };
}) => {
  return (
    <Flex alignItems="center" mb={24}>
      <CheckboxField
        label={weight.label}
        name={`${fieldPrefix}.rules.includes[0].weights_and_prices[${index}].price_id`}
        mr={weight.label && weight.label.split('')?.length > 2 ? 24 : 32}
        defaultChecked={!!condition || undefined}
        onChange={(value) =>
          onChange({ type: 'price_id', value, weightValue: weight.value })
        }
        disabled={isDisabled}
      />
      {/* TODO: For some reason DollarInput works better here with allowedDecimalPlaces,
        but it also contains a Form.NumberField which we don't want here */}
      <NumberField
        startUnit="$"
        width="100%"
        step={0.01}
        allowedDecimalPlaces={2}
        name={`${fieldPrefix}.rules.includes[0].weights_and_prices[${index}].minimum_price`}
        label="Price minimum"
        mr={24}
        defaultValue={(!!condition && condition.minimum_price) || undefined}
        onChange={(value) =>
          onChange({ type: 'minimum_price', value, weightValue: weight.value })
        }
        disabled={!condition || isDisabled}
      />
      {/* TODO: For some reason DollarInput works better here with allowedDecimalPlaces,
        but it also contains a Form.NumberField which we don't want here */}
      <NumberField
        startUnit="$"
        width="100%"
        step={0.01}
        allowedDecimalPlaces={2}
        name={`${fieldPrefix}.rules.includes[0].weights_and_prices[${index}].maximum_price`}
        label="Price maximum"
        defaultValue={(!!condition && condition.maximum_price) || undefined}
        onChange={(value) =>
          onChange({
            type: 'maximum_price',
            value,
            weightValue: weight.value,
          })
        }
        disabled={!condition || isDisabled}
      />
    </Flex>
  );
};

interface Props {
  conditions: BulkWeightCondition[];
  fieldPrefix: string;
  lastCondition?: boolean;
  onConditionsUpdate: (conditions: any) => void;
  type: ConditionType.WeightAndPrice;
}

export const WeightAndPriceConditions = ({
  conditions: conditionsProp,
  fieldPrefix,
  onConditionsUpdate,
  type,
}: Props) => {
  const { setError, clearErrors, trigger } = useFormContext();
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);

  const isAnyPosSynced = isJanePosSynced || posSynced;
  const isDisabled = isAnyPosSynced || !!isReadOnly;

  const [validationError, setValidationError] = useState<string>();
  const [conditions, setConditions] = useState(conditionsProp);
  useEffect(() => {
    setExpanded(!emptyConditions);
    setConditions(conditionsProp);
  }, [JSON.stringify(conditionsProp)]);

  const emptyConditions = useMemo(() => {
    return isEmpty(conditionsProp);
  }, [conditionsProp]);
  const [expanded, setExpanded] = useState(!emptyConditions);

  const removeCondition = () => {
    trigger();

    setConditions([]);
    setExpanded(false);

    onConditionsUpdate([]);
  };

  const validateFields = (
    type: 'maximum_price' | 'minimum_price',
    value: number | string | undefined,
    condition: BulkWeightCondition
  ) => {
    setValidationError(undefined);
    clearErrors('price-error');
    trigger();

    const oppositeType =
      type === 'maximum_price' ? 'minimum_price' : 'maximum_price';

    if (!value || !condition[oppositeType]) return;

    const firstVal = Number(value);
    const oppositeVal = Number(condition[oppositeType]);

    if (firstVal === 0 && oppositeVal === 0) {
      trigger();
    } else if (type === 'minimum_price' && firstVal >= oppositeVal) {
      setError('price-error', { message: 'Price error' });
      setValidationError('Minimum price must be less than maximum price');
    } else if (type === 'maximum_price' && firstVal <= oppositeVal) {
      setError('price-error', { message: 'Price error' });
      setValidationError('Maximum price must be greater than minimum price');
    }
  };

  const onChange = ({ type, value, weightValue }: OnChangeSignature) => {
    const existingCondition = conditionsProp.find(
      (condition) => condition.price_id === weightValue
    );

    if (type !== 'price_id' && existingCondition) {
      validateFields(type, value, existingCondition);
    }

    let updatedValues = [...conditions];

    // unchecking
    if (!!existingCondition && type === 'price_id' && value === false) {
      updatedValues = updatedValues.filter(
        (value: BulkWeightCondition) =>
          value.price_id !== existingCondition?.price_id
      );
    }

    // checking
    if (!existingCondition && type === 'price_id' && value === true) {
      updatedValues = [
        ...conditions,
        {
          price_id: weightValue,
          maximum_price: undefined,
          minimum_price: undefined,
        },
      ];
    }

    // changing max or min price on existing condition
    if (
      existingCondition &&
      (type === 'maximum_price' || type === 'minimum_price')
    ) {
      existingCondition[type] =
        typeof value === 'string' ? parseInt(value, 10) : value;

      updatedValues = updatedValues.filter(
        (value: any) => value.price_id !== existingCondition?.price_id
      );
      updatedValues = [...updatedValues, existingCondition];
    }

    // changing max or min price on a nonexistent condition
    if (
      !existingCondition &&
      (type === 'maximum_price' || type === 'minimum_price')
    ) {
      const otherType =
        type === 'maximum_price' ? 'minimum_price' : 'maximum_price';
      updatedValues = [
        ...updatedValues,
        {
          price_id: weightValue,
          [type]: value,
          [otherType]: null,
        },
      ];
    }

    onConditionsUpdate(updatedValues);
  };

  const weightOptions = useMemo(() => {
    const allWeights = allWeightIds('extract');
    return allWeights.map((weight: PriceId) => ({
      label: labelForWeightId(weight),
      value: weight,
    }));
  }, []);

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb={FORM_FIELD_DEFAULT_MARGIN}
      >
        <Typography variant="header">{capitalize(type)}</Typography>
        <Flex gap={24}>
          <Button
            label={expanded ? 'Remove condition' : 'Add condition'}
            onClick={() => {
              expanded ? removeCondition() : setExpanded(true);
            }}
            variant={expanded ? 'tertiary' : 'secondary'}
            disabled={isDisabled}
          />
        </Flex>
      </Flex>
      {expanded && (
        <>
          {validationError && (
            <Typography color="error" mb={16}>
              {validationError}
            </Typography>
          )}
          {weightOptions.map((weight, i) => {
            const condition = conditionsProp.find(
              (condition) => condition.price_id === weight.value
            );

            return (
              <WeightAndPriceRow
                condition={condition}
                fieldPrefix={fieldPrefix}
                index={i}
                onChange={onChange}
                weight={weight}
                key={weight.value}
                isDisabled={isDisabled}
              />
            );
          })}
          <Modal.ContentDivider />
        </>
      )}
    </>
  );
};

import styled from '@emotion/styled';

import { Link, Modal, Typography } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const TruncatedTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.colors.info.main,
  flexGrow: 1,
  [mediaQueries.desktop('sm', 'max')]: {
    whiteSpace: 'unset',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}));

export const StyledLink = styled(Link)({
  flexShrink: 0,
});

export const ColoredSvg = styled.svg(({ theme }) => ({
  fill: theme.colors.info.main,
  marginRight: 8,
}));

export const StyledSpan = styled.span<{ pink?: boolean }>(
  ({ pink, theme }) => ({
    color: pink ? theme.colors.info.main : theme.colors.text.main,
    ...spacing({ mr: 16 }),
    [mediaQueries.desktop('sm', 'max')]: {
      ...spacing({ mr: 8 }),
    },
  })
);

export const StyledModalContent = styled(Modal.Content)({
  ...spacing({ p: 24 }),
});

import styled from '@emotion/styled';
import every from 'lodash/every';
import some from 'lodash/some';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';

import { useStores } from '@jane/business-admin/data-access';
import { useKeyPress, useNavigateAndTrack } from '@jane/business-admin/hooks';
import type { QuickAction } from '@jane/business-admin/types';
import {
  EventNames,
  NavigationSourceIds,
  track,
} from '@jane/business-admin/util';
import {
  Box,
  Card,
  Flex,
  List,
  Loading,
  Modal,
  SearchField,
  Tag,
  Typography,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

const ModalContent = styled.div({}, ({ theme }) => ({
  overflowY: 'hidden',
  overflowX: 'hidden',
  height: '564px',
  width: '600px',
}));

const StaticSearch = styled.div({}, ({ theme }) => ({
  position: 'fixed',
  zIndex: 100,
  background: theme.colors.background,
  width: '100%',
}));

const ScrollableContent = styled(Flex)({
  height: 'calc(100% - 80px)',
  overflowY: 'auto',
  marginTop: 80,
  // TODO: We can hide the scrollbar? Styling it doesn't really seem to work
  // '&::-webkit-scrollbar': {
  //   display: 'none',
  // },
});

const LoadingWrapper = styled.div({
  position: 'relative',
  padding: 24,
  width: '100%',
  margin: 'auto',
});

const ActionItemBox = styled(Box)<{ selected: boolean }>(
  ({ selected, theme }) => ({
    background: selected ? theme.colors.brand.grape.light : 'transparent',
    '&:hover': {
      background: selected ? theme.colors.brand.grape.light : 'rgba(0,0,0,.1)',
    },
  }),
  {
    ...spacing({ py: 12, px: 24 }),
  }
);

const INITIAL_VISIBLE_ACTION_COUNT = 50;
const VISIBLE_ACTION_INCREMENT = 50;
const ALL_ACTIONS: QuickAction[] = [
  {
    id: 'stores_index',
    title: 'All Stores',
    description: 'View all stores.',
    tags: ['stores', 'view', 'all', 'index'],
    url: '/stores',
  },
  {
    id: 'store_settings_details',
    title: 'Store > Settings > Details',
    description: 'View and edit store details settings.',
    tags: [
      'view',
      'edit',
      'settings',
      'details',
      'type (rec vs med)',
      'address',
      'hours',
      'photo',
      'banner',
    ],
    url: '/stores/{store_id}/settings/details',
  },
  {
    id: 'store_settings_checkout',
    title: 'Store > Settings > Checkout',
    description: 'View and edit store checkout settings.',
    tags: [
      'view',
      'create',
      'add',
      'edit',
      'delete',
      'settings',
      'checkout options',
      'requirements',
      'id',
      'medical id',
      'government id',
      'tipping',
      'cart limits',
      'checkout agreements',
      'payments',
      'janepay',
      'aeropay',
      'stronghold',
      'moneris',
      'canpay',
      'payfirma',
      'aeropay',
      'taxes',
      'fees',
      'service fee',
    ],
    url: '/stores/{store_id}/settings/checkout',
  },
  {
    id: 'store_settings_specials',
    title: 'Store > Settings > Specials',
    description: 'View and edit store specials settings.',
    tags: ['view', 'edit', 'settings', 'specials', 'stacking', 'maximum'],
    url: '/stores/{store_id}/settings/specials',
  },
  {
    id: 'store_settings_urls',
    title: 'Store > Settings > URLS & SEO',
    description: 'View and edit store URLs + SEO settings.',
    tags: [
      'view',
      'edit',
      'settings',
      'urls',
      'seo',
      'boost',
      'config',
      'subdomain',
      'wordpress',
      'plugin',
      'age gate',
    ],
    url: '/stores/{store_id}/settings/subdomain',
  },
  {
    id: 'store_settings_integrations',
    title: 'Store > Settings > Integrations',
    description: 'View and edit store integration settings.',
    tags: [
      'view',
      'edit',
      'settings',
      'integration',
      'loyalty',
      'google analytics',
      'ga',
      'alpineiq',
      'data owl',
      'springbig',
      'redemption',
      'tracking',
    ],
    url: '/stores/{store_id}/settings/integrations',
  },
  {
    id: 'store_settings_fulfillments',
    title: 'Store > Settings > Fulfillments',
    description: 'View and edit store fulfillment settings.',
    tags: [
      'view',
      'edit',
      'settings',
      'fulfillment',
      'enable',
      'disable',
      'pickup',
      'delivery',
      'curbside',
      'kiosk',
      'order ahead',
      'off hours ordering',
      'future ordering',
      'lead times',
      'last call',
      'orders per window',
      'orders per reservation slot',
      'radius',
      'zipcode',
      'geofence',
      'delivery fee',
      'delivery window',
      'order limit',
    ],
    url: '/stores/{store_id}/settings/fulfillments',
  },
  {
    id: 'store_settings_hardware',
    title: 'Store > Settings > Hardware',
    description: 'View and edit store hardware settings.',
    tags: [
      'view',
      'create',
      'edit',
      'settings',
      'hardware',
      'order ticket',
      'printer',
      'mac address',
      'test print',
    ],
    url: '/stores/{store_id}/settings/hardware',
  },
  {
    id: 'store_settings_notifications',
    title: 'Store > Settings > Notifications',
    description: 'View and edit store notification settings.',
    tags: [
      'view',
      'edit',
      'settings',
      'notifications',
      'sms',
      'cm',
      'twilio',
      'email',
      'reservation chat',
      'token',
      'phone number',
      'from',
    ],
    url: '/stores/{store_id}/settings/notifications',
  },
  {
    id: 'store_menu',
    title: 'Store > Menu',
    description: 'View and edit store menu rows, tabs, and appearance.',
    tags: [
      'menu rows',
      'view',
      'add',
      'create',
      'arrange',
      'edit',
      'modify',
      'delete',
      'remove',
      'manage',
      'customize appearance',
      'theme color',
      'navigation color',
      'menu format',
      'grid view',
      'list view',
      'product detail page',
      'colors',
      'communication banner',
      'filters',
      'labels',
      'category tabs',
      'recommended row',
      'subcategory',
    ],
    url: '/stores/{store_id}/menu',
  },
  {
    id: 'store_products_published',
    title: 'Store > Products > Published',
    description: 'View, edit, and hide published store products.',
    tags: [
      'products',
      'view',
      'edit',
      'bulk edit',
      'modify',
      'hide',
      'remove',
      'manage',
      'published',
      'auto publish',
      'visible',
      'pricing',
      'images',
      'lab results',
      'weights',
      'variants',
      'jane catalog',
      'custom',
      'name',
      'description',
      'size',
      'dosage',
      'category',
      'lineage',
      'brand',
      'thc',
      'thca',
      'cbd',
      'cbda',
    ],
    url: '/stores/{store_id}/products/published',
  },
  {
    id: 'store_products_hidden',
    title: 'Store > Products > Hidden',
    description: 'View, edit, and enable hidden store products.',
    tags: [
      'products',
      'view',
      'edit',
      'bulk edit',
      'modify',
      'enable',
      'remove',
      'manage',
      'hidden',
      'auto publish',
      'visible',
      'pricing',
      'images',
      'lab results',
      'weights',
      'variants',
      'jane catalog',
      'custom',
      'name',
      'description',
      'size',
      'dosage',
      'category',
      'lineage',
      'brand',
      'thc',
      'thca',
      'cbd',
      'cbda',
    ],
    url: '/stores/{store_id}/products/hidden',
  },
  {
    id: 'store_products_unpublished',
    title: 'Store > Products > Unpublished',
    description: 'View, edit, and publish unpublished store products.',
    tags: [
      'products',
      'view',
      'edit',
      'bulk edit',
      'modify',
      'modify',
      'publish',
      'remove',
      'manage',
      'unpublished',
      'auto publish',
      'visible',
      'pricing',
      'images',
      'lab results',
      'weights',
      'variants',
      'jane catalog',
      'custom',
      'name',
      'description',
      'size',
      'dosage',
      'category',
      'lineage',
      'brand',
      'thc',
      'thca',
      'cbd',
      'cbda',
    ],
    url: '/stores/{store_id}/products/unpublished',
  },
  {
    id: 'store_specials',
    title: 'Store > Specials',
    description: 'View, add, edit, and delete store specials.',
    tags: [
      'specials',
      'view',
      'add',
      'create',
      'edit',
      'bulk',
      'modify',
      'delete',
      'remove',
      'manage',
      'archive',
      'live',
      'upcoming',
      'expired',
      'disabled',
      'qualified group',
      'promo code',
      'bundle',
      'bulk',
      'cart total',
      'buy x get y',
      'discount',
      'start date',
      'end date',
      'schedule',
      'image',
    ],
    url: '/stores/{store_id}/specials',
  },
  {
    id: 'store_staff',
    title: 'Store > Staff',
    description: 'View, invite, edit, and delete store staff.',
    tags: [
      'staff',
      'view',
      'invite',
      'edit',
      'modify',
      'delete',
      'remove',
      'manage',
      'manager',
      'client',
      'staff member',
      'user',
      'phone number',
      'permissions',
      'stores access',
      'first name',
      'last name',
    ],
    url: '/stores/{store_id}/staff',
  },
  {
    id: 'analytics_kpis',
    title: 'Analytics > KPIs',
    description: 'View and download KPI analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'kpis',
      'sales, orders, and customers',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/kpis',
  },
  {
    id: 'analytics_sales',
    title: 'Analytics > Sales',
    description: 'View and download Sales analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'sales',
      'sales, orders, and customers',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/sales',
  },
  {
    id: 'analytics_customers',
    title: 'Analytics > Customers',
    description: 'View and download Customers analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'customers',
      'sales, orders, and customers',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/customers',
  },
  {
    id: 'analytics_fulfillment',
    title: 'Analytics > Fulfillment',
    description: 'View and download Fulfillment analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'fulfillment',
      'sales, orders, and customers',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/fulfillment',
  },
  {
    id: 'analytics_on_menu_merchandising',
    title: 'Analytics > On-Menu Merchandising',
    description:
      'View and download On-Menu Merchandising analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'on menu merchandising',
      'site traffic and conversion',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/on-menu-merchandising',
  },
  {
    id: 'analytics_jane_gold',
    title: 'Analytics > Jane Gold',
    description: 'View and download Jane Gold analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'jane gold',
      'site traffic and conversion',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/jane-gold',
  },
  {
    id: 'analytics_web_traffic',
    title: 'Analytics > Web Traffic',
    description: 'View and download Web Traffic analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'web traffic',
      'site traffic and conversion',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/web-traffic',
  },
  {
    id: 'analytics_marketing_attribution',
    title: 'Analytics > Marketing Attribution',
    description:
      'View and download Marketing Attribution analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'marketing attribution',
      'site traffic and conversion',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/marketing-attribution',
  },
  {
    id: 'analytics_off_menu_merchandising',
    title: 'Analytics > Off-Menu Merchandising',
    description:
      'View and download Off-Menu Merchandising analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'off menu merchandising',
      'site traffic and conversion',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/off-menu-merchandising',
  },
  {
    id: 'analytics_product_reviews',
    title: 'Analytics > Product Reviews',
    description: 'View and download Product Reviews analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'product reviews',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/product-reviews',
  },
  {
    id: 'analytics_store_reviews',
    title: 'Analytics > Store Reviews',
    description: 'View and download Store Reviews analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'store reviews',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/store-reviews',
  },
  {
    id: 'analytics_premium_trends',
    title: 'Analytics > Premium > Trends',
    description: 'View and download Premium Trends analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'premium reports',
      'competitive overview',
      'trends',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/trends',
  },
  {
    id: 'analytics_premium_pricing',
    title: 'Analytics > Premium > Pricing',
    description: 'View and download Premium Pricing analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'premium reports',
      'competitive overview',
      'pricing',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/pricing',
  },
  {
    id: 'analytics_premium_marketplace',
    title: 'Analytics > Premium > Marketplace',
    description:
      'View and download Premium Marketplace analytics for your stores.',
    tags: [
      'store',
      'analytics',
      'insights',
      'data',
      'view',
      'download',
      'premium reports',
      'competitive overview',
      'marketplace',
      'xls',
      'pdf',
      'reports',
    ],
    url: '/analytics/marketplace',
  },
  //   {
  //     id: 'plus_beta',
  //     title: 'Plus (beta)',
  //     description: 'View and edit your Bloom menus.',
  //     tags: [
  //       'store',
  //       'plus',
  //       'bloom menus',
  //       'view',
  //       'edit',
  //       'my stores',
  //       'fonts > headings',
  //       'fonts > paragraph',
  //       'fonts > preview',
  //       'colors > color scheme',
  //       'colors > lineage tags',
  //       'upload logo',
  //       'age gate',
  //       'main menu',
  //       'hamburger menu',
  //       'footer',
  //     ],
  //     url: '/analytics/fulfillment',
  //   },
  {
    id: 'marketing_business_solutions',
    title: 'Business Solutions',
    description: "See all of Jane's offerings for your business.",
    tags: [
      'explore',
      'business solutions',
      'kiosk',
      'pos',
      'point of sale',
      'payments',
      'media',
      'ads',
      'off menu',
      'jane gold',
      'analytics',
    ],
    url: '/marketing/business-solutions',
  },
];

export const QuickActionModal = ({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigateAndTrack();
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visibleActionCount, setVisibleActionCount] = useState(
    INITIAL_VISIBLE_ACTION_COUNT
  );
  const { data: allStores } = useStores({ includeCounts: false });
  const { ref, inView } = useInView();

  const location = useLocation();
  const currentStoreId = useMemo(() => {
    const tabRegex = /\/stores\/(\d*)\/(.*)/;
    const matches = location.pathname.match(tabRegex);
    return matches ? matches[1] : '';
  }, [location.pathname]);

  const filteredActions = useMemo(() => {
    if (ALL_ACTIONS?.length) {
      return ALL_ACTIONS.filter((action: QuickAction) =>
        every(
          searchFilter.split(' '),
          (s) =>
            action.title.toLowerCase().includes(s) ||
            action.description.toLowerCase().includes(s) ||
            some(action.tags, (t) => t.toLowerCase().includes(s))
        )
      );
    }
    return [];
  }, [searchFilter]);

  const selectedAction = useMemo(
    () => filteredActions[selectedIndex],
    [filteredActions, selectedIndex]
  );

  const defaultStoreId = useMemo(() => {
    if (currentStoreId) {
      return currentStoreId.toString();
    } else if (allStores && allStores.length > 0) {
      return allStores[0].id.toString();
    } else {
      return '';
    }
  }, [currentStoreId, allStores]);

  const onNavigate = (storeId: string, action: QuickAction) => {
    setOpen(false);
    const toUrl = action.url.replace('{store_id}', storeId);
    track({
      event: EventNames.QuickAction, //TODO: Update
      action: action.id,
      to_url: toUrl,
    });
    navigate(toUrl, NavigationSourceIds.QuickActionKeyboard);
  };

  const navigateToStoreKeyboard = useMemo(() => {
    return () => {
      if (selectedAction) {
        onNavigate(defaultStoreId, selectedAction);
      }
    };
  }, [selectedAction, defaultStoreId]);

  const navigateQuickActionOnClick = useMemo(() => {
    return (action: QuickAction) => {
      onNavigate(defaultStoreId, action);
    };
  }, [defaultStoreId]);

  const incrementSelectedIndex = () => {
    if (selectedIndex < filteredActions.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const decrementSelectedIndex = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSearchFilter('');
  };

  useKeyPress(['ArrowDown'], incrementSelectedIndex);
  useKeyPress(['ArrowUp'], decrementSelectedIndex);
  useKeyPress(['Enter'], navigateToStoreKeyboard);

  useEffect(() => {
    if (inView) {
      setVisibleActionCount(visibleActionCount + VISIBLE_ACTION_INCREMENT);
    }
  }, [inView, visibleActionCount]);

  return (
    <Modal
      appId="root"
      variant="dialogue"
      overlayClose={true}
      onRequestClose={closeModal}
      open={isOpen}
    >
      <ModalContent>
        <StaticSearch>
          <Flex alignContent="center" mx={24} my={16} pb={0}>
            <SearchField
              width="100%"
              label=""
              name="search_input"
              placeholder="Jump to..."
              defaultValue={searchFilter}
              autoFocus={true}
              autocomplete="off"
              onChange={(val) => {
                setSearchFilter(val.toLowerCase());
                setSelectedIndex(0);
              }}
              isDebounced={false}
            />
          </Flex>
        </StaticSearch>
        <ScrollableContent flexDirection="column" pt={0} py={16}>
          <Flex width="100%" flexDirection="column" alignItems="center">
            <List label="List of quick actions" px={0} m={0}>
              {filteredActions
                ?.slice(0, visibleActionCount)
                .map((action: QuickAction, index: number) => (
                  <List.Item key={action.id} px={0} py={0}>
                    <ActionItemBox
                      width="100%"
                      height="100%"
                      selected={selectedIndex === index}
                    >
                      <Card
                        flat={true}
                        width="100%"
                        ariaLabel={`${action.title}`}
                        style={{ borderRadius: 0 }}
                        onClick={() => {
                          navigateQuickActionOnClick(action);
                        }}
                      >
                        <Flex flexDirection="column">
                          <Flex>
                            <Typography variant="body-bold">
                              {action.title}
                            </Typography>
                          </Flex>
                          <Typography color="grays-mid">
                            {action.description}
                          </Typography>
                          <Flex pt={4}>
                            {action.tags
                              .filter((tag: string) => {
                                if (searchFilter.length > 1) {
                                  return every(searchFilter.split(' '), (s) =>
                                    tag.toLowerCase().includes(s)
                                  );
                                } else {
                                  return false;
                                }
                              })
                              .map((tag: string) => (
                                <Tag
                                  background="primary-light"
                                  label={tag}
                                  key={tag}
                                ></Tag>
                              ))}
                          </Flex>
                        </Flex>
                      </Card>
                    </ActionItemBox>
                  </List.Item>
                ))}
              {filteredActions.length == 0 ? (
                <Flex flexDirection="column" alignItems="center">
                  <Flex p={12}>
                    <Typography variant="header-bold">
                      No actions found.
                    </Typography>
                  </Flex>
                </Flex>
              ) : null}
              {visibleActionCount < filteredActions.length ? (
                <LoadingWrapper ref={ref}>
                  <Loading color="purple" />
                </LoadingWrapper>
              ) : null}
            </List>
          </Flex>
        </ScrollableContent>
      </ModalContent>
    </Modal>
  );
};

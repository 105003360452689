import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useDebouncedTrack } from '@jane/business-admin/hooks';
import type { MenuRow } from '@jane/business-admin/types';
import { EventNames } from '@jane/business-admin/util';
import { useSearch } from '@jane/search/data-access';
import type { AlgoliaProduct } from '@jane/search/types';
import { JANE_DEFINED_ROWS } from '@jane/search/util';
import { CardCarousel, LegacyMenuProductCard } from '@jane/shared/components';
import type { MenuProduct, StoreSpecial } from '@jane/shared/models';
import { findSpecialForProduct } from '@jane/shared/util';

import { ListContainer } from './ListContainer';
import {
  HeaderEditButton,
  HeaderToggle,
  HeaderWithoutToggle,
} from './ListItemHeaders';
import { CARD_HEIGHT, CARD_WIDTH } from './utils';

interface Props {
  allStoreSpecials?: StoreSpecial[];
  openMenuRowModal: (row: MenuRow) => void;
  row: MenuRow;
  rowFacet: any;
  rowLabel: string;
  shouldUseAlgolia: boolean;
}
export const ListItem = ({
  allStoreSpecials,
  openMenuRowModal,
  row,
  rowFacet,
  rowLabel,
  shouldUseAlgolia,
}: Props) => {
  const { id: storeId = '' } = useParams<'id'>();
  const kindAndRootType = rowFacet?.kind || rowFacet?.name || row.row_type;
  const track = useDebouncedTrack(500);

  // TODO: Move to custom hook (also include best_selling sorting in there)
  const { data: searchResults, isFetching } = useSearch<AlgoliaProduct>({
    enabled: shouldUseAlgolia,
    indexPrefix: 'menu-products-',
    staticFilters: [
      `store_id = ${storeId}`,
      `kind:"${kindAndRootType}" OR root_types:"${kindAndRootType}"`,
    ]
      .filter(Boolean)
      .join(' AND '),
    // We only show 16 items before the "See All" link
    hitsPerPage: 16,
  });

  const results = useMemo(() => {
    const items = shouldUseAlgolia
      ? searchResults?.hits || []
      : row?.menu_products_to_preview || [];

    if (row.row_type === 'best_selling') {
      // TODO: Use util from kiosk to sort? Currently has a bug for 0 ranked best_sellers though
      items.sort(
        (a, b) => (a.best_seller_rank || 0) - (b.best_seller_rank || 0)
      );
    }

    return items;
  }, [searchResults, shouldUseAlgolia, row]);

  const totalResults = useMemo(() => {
    return shouldUseAlgolia
      ? searchResults?.nbHits || 0
      : row?.menu_products_ids?.in_stock.length || 0;
  }, [searchResults, shouldUseAlgolia, row]);

  const showEditButton = useMemo(() => !row.is_category, [row]);
  const showToggleHeader = useMemo(() => !row.is_category, [row]);
  const showProductsCTA = useMemo(() => {
    return (
      !JANE_DEFINED_ROWS.includes(row.row_type) &&
      !results.length &&
      !row.applicability_rules?.length
    );
  }, [results, row]);
  const showMenuProducts = useMemo(
    () => row.is_category || (!row.is_category && row.enabled),
    [row]
  );
  const categoryRowWithInventory = useMemo(
    () => !!row.is_category && totalResults > 0,
    [row, totalResults]
  );

  return (
    <ListContainer rowEnabled={categoryRowWithInventory || !!row.enabled}>
      <CardCarousel
        afterHeader={
          showEditButton && (
            <HeaderEditButton
              onOpenMenuRow={() => openMenuRowModal(row)}
              showCTAButton={showProductsCTA}
            />
          )
        }
        beforeHeader={
          showToggleHeader ? (
            <HeaderToggle
              row={row}
              rowLabel={rowLabel}
              itemsLength={totalResults}
              showProductsCTA={showProductsCTA}
            />
          ) : (
            <HeaderWithoutToggle
              rowLabel={rowLabel}
              itemsLength={totalResults}
              row={row}
            />
          )
        }
        isLoading={isFetching}
        name={rowLabel}
        mode="ideal-width"
        cardWidth={CARD_WIDTH}
        cardHeight={CARD_HEIGHT}
        cardWidthMobile={212}
        cardHeightMobile={328}
        mb={showMenuProducts && categoryRowWithInventory ? 40 : 0}
        onScroll={(direction: string) => {
          track({
            event: EventNames.HorizontalScroll,
            trigger_source_id: `${direction} arrow`,
          });
        }}
      >
        {!!results.length &&
          showMenuProducts &&
          results.map((product: any) => {
            return (
              <CardCarousel.Card
                key={`${row.row_type}-${product.id}-${product.product_id}`}
              >
                <LegacyMenuProductCard
                  appMode="embedded"
                  appliedWeightFilter=""
                  currentSpecial={findSpecialForProduct(
                    product,
                    allStoreSpecials,
                    'embedded'
                  )}
                  cartIsOpen={false}
                  cartProducts={[]}
                  disableInteraction={true}
                  disabledState={!row.is_category && !row.enabled}
                  hideActions={true}
                  listView={false}
                  product={product as MenuProduct}
                  routeAppMode={{ type: 'embedded' } as any}
                  routePartnerHostedConfig={{ isPartnerHosted: false }}
                  store={{} as any}
                  userLocation={{ cityState: 'CA' } as any}
                />
              </CardCarousel.Card>
            );
          })}
      </CardCarousel>
    </ListContainer>
  );
};

/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Archive24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Archive 24">
      <path
        fill="#0E0E0E"
        d="M4 22a1.93 1.93 0 0 1-1.413-.587A1.928 1.928 0 0 1 2 20V6.5c0-.25.042-.475.125-.675.083-.2.192-.392.325-.575l1.4-2.7c.133-.183.3-.321.5-.413C4.55 2.046 4.767 2 5 2h14c.233 0 .45.046.65.137.2.092.367.23.5.413l1.4 2.7c.133.183.242.375.325.575.083.2.125.425.125.675V20a1.93 1.93 0 0 1-.587 1.413A1.928 1.928 0 0 1 20 22H4Zm.4-16h15.2l-.85-2H5.25L4.4 6ZM4 20h16V8H4v12Zm8-2a1.1 1.1 0 0 0 .375-.063.871.871 0 0 0 .325-.212l2.6-2.6a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-.9.9V11a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 10a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 11v3.625l-.9-.9a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275.948.948 0 0 0-.275.7.95.95 0 0 0 .275.7l2.6 2.6c.1.1.208.17.325.212A1.1 1.1 0 0 0 12 18Z"
        className="Vector"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Archive24,
  sm: Archive24,
  lg: Archive24,
  xl: Archive24,
  xxl: Archive24,
  xxxl: Archive24,
};

export const ArchiveIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'archive-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { useContext } from 'react';

import { FulfillmentSettingsContext } from '@jane/business-admin/providers';
import { Flex, Form } from '@jane/shared/reefer';

import { DollarInput } from '../../../../../../../DollarInput';

export const RadiusSettings = ({ minimum }: { minimum: number }) => {
  const { storePayload } = useContext(FulfillmentSettingsContext);
  const { store } = storePayload;

  return (
    <Flex mt={32} justifyContent="space-between" gap={40}>
      <Form.NumberField
        defaultValue={store.delivery_radius || 0}
        width="100%"
        name="delivery_radius"
        endUnit="miles"
        min={1}
        label="Delivery radius"
      />
      <DollarInput
        defaultValue={store.delivery_fee_amount}
        name="delivery_fee_amount"
        label="Delivery fee"
      />
      {/* Don't use DollarInput - backend only accepts whole numbers */}
      <Form.NumberField
        defaultValue={minimum}
        width="100%"
        startUnit="$"
        name="cart_minimum"
        label="Delivery minimum"
      />
    </Flex>
  );
};

import { Flex, Link, Typography } from '@jane/shared/reefer';
import type { FlexProps } from '@jane/shared/reefer';

interface AgreementReportLinkProps {
  onClick: () => void;
  showHeading?: boolean;
}

export const AgreementReportLink = ({
  onClick,
  showHeading = false,
  ...flexProps
}: AgreementReportLinkProps & FlexProps) => (
  <Flex flexDirection="column" {...flexProps}>
    {showHeading && (
      <Typography variant="caps" color="grays-mid" mb={8}>
        Report
      </Typography>
    )}
    <Link onClick={onClick}>Send opt-in report</Link>
  </Flex>
);

import type { MaskitoPreprocessor } from '@maskito/core';

export const alwaysStartsWith =
  (prefix: string): MaskitoPreprocessor =>
  ({ elementState, data }, action) => {
    if (
      action === 'insert' &&
      // inserting from the front of the string
      elementState.selection[0] === 0 &&
      // characters to be inserted don't currently start with the prefix
      !data.startsWith(prefix)
    ) {
      return {
        elementState: {
          selection: [prefix.length, prefix.length],
          value: prefix,
        },
        data,
      };
    } else {
      return { elementState, data };
    }
  };

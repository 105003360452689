import { Flex, HideEyeIcon, Typography } from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

export const WeightCell = ({
  columnPrice,
  includesPrice,
  unavailablePrices = [],
  visible,
}: {
  columnPrice: string;
  includesPrice: string;
  unavailablePrices?: {
    id: number;
    menu_product_id: number;
    price_id: string;
  }[];
  visible: boolean;
}) => {
  const price = columnPrice !== null ? formatCurrency(columnPrice) : '';

  return (
    <Flex flexDirection="row" gap={4}>
      <Typography color={visible ? 'grays-black' : 'grays-light'}>
        {price}
      </Typography>
      {unavailablePrices.map((obj) => obj.price_id).includes(includesPrice) &&
      columnPrice !== null ? (
        <HideEyeIcon color={visible ? 'grays-mid' : 'grays-light'} />
      ) : null}
    </Flex>
  );
};

interface Font {
  fontFamily?: string;
  url?: string;
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CSS_Font_Loading_API
 */
export const loadFont = async (font?: Font) => {
  if (!(font?.fontFamily && font?.url)) {
    return;
  }

  if (typeof FontFace === 'undefined') {
    throw Error('FontFace is undefined');
  }

  const externalFont = new FontFace(font.fontFamily, `url(${font.url})`);
  document.fonts.add(externalFont);
  return await externalFont.load();
};

import type { Address, DeliveryConstraints, Store } from '@jane/shared/models';

export const PrepaymentMethods = {
  aeropay: 'aeropay',
  aeropayWhiteLabel: 'aeropay_white_label',
  canpay: 'canpay',
  hypur: 'hypur',
  janePay: 'jane_pay',
  moneris: 'moneris',
  payfirma: 'payfirma',
  paytender: 'paytender',
  square: 'square',
  stronghold: 'stronghold',
} as const;

export type PrepaymentProviders =
  typeof PrepaymentMethods[keyof typeof PrepaymentMethods];

export interface FulfillmentSlot {
  name: string;
  value: { from: string; to: string };
}

interface ScheduleCheckout {
  deliveryAddress: Address | null;
  deliveryConstraints: DeliveryConstraints;
  reservationMode: 'delivery' | 'curbside' | 'pickup' | 'retail';
}

interface UnavailableSlots {
  [key: string]: string[];
}

export interface UnavailableSlotsByReservationMode {
  unavailable_curbside_slots: UnavailableSlots;
  unavailable_delivery_slots: UnavailableSlots;
  unavailable_pickup_slots: UnavailableSlots;
}

interface ScheduleMethods {
  fulfillmentSlots: (
    checkout: ScheduleCheckout,
    store: Store,
    unavailableSlots: UnavailableSlots | null
  ) => FulfillmentSlot[];
  isCurrentlyAcceptingReservations: (mode: 'delivery' | 'pickup') => boolean;
}

export interface StoreScheduleMethods {
  curbsideSchedule?: ScheduleMethods;
  deliverySchedule?: ScheduleMethods;
  fulfillmentSchedules?: ScheduleMethods;
  pickupSchedule?: ScheduleMethods;
  retailSchedule: ScheduleMethods;
}

export interface Customer {
  authenticated?: boolean;
  birthDate: string;
  email: string;
  externalId: string;
  firstName: string;
  id: number | null;
  lastName: string;
  nickname: string;
  phone: string;
}

export interface PreAuthOptions {
  consents: number[] | null;
  deliveryMessage: string | null;
  fulfillmentTime: string | null;
  pickupMessage: string | null;
  pickupMode: 'pickup' | 'curbside' | null;
}

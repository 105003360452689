import { useCallback, useContext, useEffect, useMemo } from 'react';

import {
  useStoreSettings,
  useUpdateCartLimitPolicyStores,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  CardNames,
  EventNames,
  ModalNames,
  track,
} from '@jane/business-admin/util';
import {
  Flex,
  Form,
  Skeleton,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';

import { CardSection } from '../../../../../CardSection';
import { EditableCard } from '../../../../../EditableCard';
import { CartLimitsModal } from './CartLimitsModal';

export const CartLimitsCard = () => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating cart limits. Please try again.'
  );
  const toast = useToast();
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.CartLimits
  );
  const { canEditStore, storeId } = useContext(StoreDetailsContext);
  const { data: storeSettings, isFetching } = useStoreSettings(storeId);
  const store = useMemo(() => storeSettings?.store, [storeSettings]);
  const policy = useMemo(
    () => storeSettings?.cart_limit_policy,
    [JSON.stringify(storeSettings)]
  );
  const {
    mutateAsync: updateStoreSelections,
    isLoading: isSaving,
    isSuccess,
    isError,
  } = useUpdateCartLimitPolicyStores();

  const formMethods = useForm();
  const { setValue } = formMethods;

  useEffect(() => {
    if (isSuccess) {
      toast.add({
        label: 'Cart limits updated',
        variant: 'success',
      });
    }
    if (isError) {
      toast.add({
        label: 'Error updating cart limits. Please try again.',
        variant: 'error',
      });
      // If API request tied to toggle fails, we set toggle back to true
      setValue('enabled', true);
    }
  }, [isSuccess, isError]);

  const handleToggle = useCallback(
    (checked: boolean) => {
      if (checked && !policy) return openModal();
      if (!policy) return;

      // For some reason this fires twice, and I'm not about that life
      if (!checked) {
        track({
          event: EventNames.EditedStoreSettings,
          card_name: CardNames.CartLimits,
          changed_attributes: ['active'],
        });
      }

      const requestData = {
        policyId: policy.id,
        stores: [{ id: storeId, selected: false }],
      };
      return catchSubmitErrors({
        submitMethod: () => updateStoreSelections(requestData),
        requestData,
        onValidationError: () => null,
        callback: () => null,
      });
    },
    [policy, storeId]
  );

  const startAdornment = useMemo(
    () => (
      <Form.BaseForm
        formMethods={formMethods}
        name="Cart limits toggle"
        onSubmit={() => {
          return null;
        }}
      >
        <Form.SwitchField
          key={`${modalOpen}-${!!policy}`}
          label="enabled"
          labelHidden
          name="enabled"
          defaultChecked={!!policy}
          disabled={isSaving}
          onChange={handleToggle}
        />
      </Form.BaseForm>
    ),
    [policy, modalOpen]
  );

  return (
    <>
      <EditableCard
        title="Cart limits"
        onEdit={() => openModal()}
        disabled={!policy || !canEditStore}
        startAdornment={!isFetching && canEditStore ? startAdornment : null}
      >
        <Flex pt={24}>
          {isFetching ? (
            <Skeleton>
              <Skeleton.Bone height={8} width={100} mb={8} />
              <Skeleton.Bone height={8} width="25%" />
            </Skeleton>
          ) : policy ? (
            <>
              <CardSection width="50%" label="Policy Name">
                <Typography>{policy?.name}</Typography>
              </CardSection>
              <CardSection width="50%" label="Rules">
                {(policy?.cart_limit_rules || []).map((rule, index) => (
                  <Flex pb={8} key={`${rule}_${index}`}>
                    <Typography>
                      {rule.limit_value +
                        rule.limit_unit +
                        ' ' +
                        rule.product_group_name +
                        ' products'}
                    </Typography>
                  </Flex>
                ))}
              </CardSection>
            </>
          ) : null}
        </Flex>
      </EditableCard>

      {modalOpen && (
        <CartLimitsModal
          open={modalOpen}
          onClose={() => closeModal()}
          store={store}
          selectedId={policy?.id}
        />
      )}
    </>
  );
};

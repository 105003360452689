import type * as t from 'io-ts';

export class ValidationError extends Error {
  override name = 'ValidationError';
  public validationErrors: t.ValidationError[];
  public invalidData: unknown;

  constructor(errors: t.ValidationError[], data: unknown) {
    super('Validation Error');
    this.validationErrors = errors;
    this.invalidData = data;
  }
}

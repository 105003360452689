import type { SpecialType } from '@jane/shared/models';
import type { ColorKey } from '@jane/shared/reefer';
import { Flex, Tag } from '@jane/shared/reefer';

interface SpecialTypeCellStylesMap {
  background: ColorKey;
  color: ColorKey;
  label: string;
}

export const specialTypeStyles: Record<SpecialType, SpecialTypeCellStylesMap> =
  {
    product: {
      background: 'primary-light',
      color: 'primary-dark',
      label: 'Products',
    },
    qualified_group: {
      background: 'palm-light',
      color: 'palm-dark',
      label: 'Qualified Group',
    },
    bundle: {
      background: 'ember-light',
      color: 'ember-dark',
      label: 'Buy X Get Y',
    },
    bulk_pricing: {
      background: 'pacific-light',
      color: 'pacific-dark',
      label: 'Bulk Pricing',
    },
    cart_total: {
      background: 'seafoam-light',
      color: 'seafoam-dark',
      label: 'Cart Total',
    },
  };

export const SpecialTypeCell = ({ specialType }: { specialType: string }) => {
  const { background, color, label } = specialTypeStyles[
    specialType as SpecialType
  ] || {
    background: 'grays-mid',
    color: 'grays-white',
    label: specialType,
  };

  return (
    <Flex minWidth={150}>
      <Tag background={background} color={color} label={label} />
    </Flex>
  );
};

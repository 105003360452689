/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Globe16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g className="Globe 16" clipPath="url(#a)">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M4.547 9c.198 2.09.988 3.774 1.762 4.967A6.208 6.208 0 0 1 1.88 9h2.667Zm1.81 0c.184 1.61.79 2.931 1.412 3.909.077.121.155.237.231.347.076-.11.154-.226.231-.347C8.853 11.93 9.46 10.61 9.643 9H6.357Zm3.286-2H6.357c.184-1.61.79-2.932 1.412-3.909.077-.121.155-.237.231-.347.076.11.154.226.231.347C8.853 4.068 9.46 5.39 9.643 7Zm1.81 2c-.198 2.09-.988 3.774-1.762 4.967A6.208 6.208 0 0 0 14.12 9h-2.667Zm2.667-2h-2.667c-.198-2.09-.988-3.774-1.762-4.967A6.208 6.208 0 0 1 14.12 7ZM4.547 7H1.88a6.208 6.208 0 0 1 4.43-4.967C5.535 3.226 4.745 4.91 4.547 7ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Z"
        className="Union"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Globe24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Globe 24">
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M6.781 13c.247 3.92 1.898 6.938 3.279 8.812A10.006 10.006 0 0 1 2.05 13h4.73Zm2.005 0c.202 2.81 1.229 5.093 2.276 6.739.322.505.643.946.938 1.319.295-.373.616-.814.938-1.32 1.047-1.645 2.074-3.929 2.276-6.738H8.786Zm6.428-2H8.786c.202-2.81 1.229-5.093 2.276-6.739.322-.505.643-.946.938-1.319.295.373.616.814.938 1.32C13.985 5.906 15.012 8.19 15.214 11Zm2.005 2c-.247 3.92-1.899 6.938-3.279 8.812A10.007 10.007 0 0 0 21.95 13h-4.73Zm4.732-2h-4.732c-.247-3.92-1.899-6.938-3.279-8.812A10.007 10.007 0 0 1 21.95 11ZM6.78 11H2.05a10.006 10.006 0 0 1 8.01-8.812C8.68 4.062 7.029 7.08 6.782 11ZM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Z"
        className="Union"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  sm: Globe16,
  md: Globe24,
  lg: Globe24,
  xl: Globe24,
  xxl: Globe24,
  xxxl: Globe24,
};

export const GlobeIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'globe-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { janeApiV2 } from '@jane/shared/data-access';
import type { CheckoutAgreementV2 } from '@jane/shared/models';

import { STORES_URL } from './stores';

const createCheckoutAgreement = async (
  data: CheckoutAgreementV2,
  storeId?: string
): Promise<CheckoutAgreementV2> => {
  return await janeApiV2.post<CheckoutAgreementV2>(
    `${STORES_URL}/${storeId}/checkout_agreements`,
    {
      checkout_agreement: data,
    }
  );
};

export const useCreateCheckoutAgreement = (storeId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CheckoutAgreementV2) => {
      return createCheckoutAgreement(data, storeId?.toString());
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId?.toString()]);
    },
  });
};

const updateCheckoutAgreement = async (
  data: CheckoutAgreementV2,
  storeId?: string
): Promise<null> => {
  return await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/checkout_agreements/${data.id}`,
    {
      checkout_agreement: data,
    }
  );
};

export const useUpdateCheckoutAgreement = (storeId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CheckoutAgreementV2) => {
      return updateCheckoutAgreement(data, storeId?.toString());
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId?.toString()]);
    },
  });
};

interface UpdateManyCheckoutAgreementsParams {
  active: number[];
  inactive: number[];
}
const updateManyCheckoutAgreements = async (
  data: UpdateManyCheckoutAgreementsParams,
  storeId?: string
): Promise<null> => {
  const activeIds = data['active'];
  const inactiveIds = data['inactive'];
  return await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/checkout_agreements/update_many`,
    {
      active_checkout_agreement_ids: activeIds,
      inactive_checkout_agreement_ids: inactiveIds,
    }
  );
};

export const useUpdateManyCheckoutAgreements = (storeId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateManyCheckoutAgreementsParams) => {
      return updateManyCheckoutAgreements(data, storeId?.toString());
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId?.toString()]);
    },
  });
};

export interface CreateCheckoutAgreementReportParams {
  end_date?: string;
  start_date?: string;
}
const createCheckoutAgreementReport = (
  data: CreateCheckoutAgreementReportParams,
  storeId?: string,
  agreementId?: string
): Promise<null> =>
  janeApiV2.post<null>(
    `${STORES_URL}/${storeId}/checkout_agreements/${agreementId}/report`,
    data
  );
export const useCreateCheckoutAgreementReport = (
  storeId?: string,
  agreementId?: string
) => {
  return useMutation({
    mutationFn: (data: CreateCheckoutAgreementReportParams) =>
      createCheckoutAgreementReport(data, storeId, agreementId),
    onError: (error) => {
      console.error(error);
    },
  });
};

import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';

import type { UnavailablePrice } from '@jane/business-admin/data-access';
import type { MenuProductForProductsTable } from '@jane/business-admin/types';
import {
  BODY_LINE_HEIGHT,
  EventNames,
  MODAL_CARD_WIDTH,
  track,
} from '@jane/business-admin/util';
import type { MenuProduct } from '@jane/shared/models';
import {
  Card,
  Flex,
  Skeleton,
  SwitchField,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';
import { WEIGHT_OPTIONS, formatCurrency } from '@jane/shared/util';

import {
  TableHeaderItem,
  TableRowWithBorder,
} from '../../../../TableWithBorderSeparator';

const PaddedSwitch = styled(SwitchField)({
  padding: 16,
});

interface PriceVariant {
  id: string;
  label: string;
  price?: string;
  val: string;
}

export const modifyPrices = (
  prices: UnavailablePrice[],
  enabled: boolean,
  val: string
) => {
  const index = prices.findIndex(({ price_id }: any) => {
    return `price_${price_id}` === val;
  });
  const modifiedPrices = [...prices];
  const isExistingPrice = !!modifiedPrices[index]?.id;

  if (isExistingPrice) {
    modifiedPrices.splice(index, 1, {
      ...modifiedPrices[index],
      _destroy: enabled,
    });
  } else {
    if (enabled) {
      modifiedPrices.splice(index, 1);
    } else {
      const withoutPrefix = val.substring(6);
      modifiedPrices.push({ price_id: withoutPrefix });
    }
  }

  return modifiedPrices;
};

const LoadingVariants = () => {
  return (
    <>
      {Array(3)
        .fill(null)
        .map((_, index) => {
          return (
            <TableRowWithBorder key={`loading_${index}`} hasBorder={true}>
              <td>
                <Skeleton animate width="100%">
                  <Skeleton.Bone
                    height={BODY_LINE_HEIGHT}
                    my={16}
                    width="30%"
                  />
                </Skeleton>
              </td>
              <td>
                <Skeleton animate width="100%">
                  <Skeleton.Bone
                    height={BODY_LINE_HEIGHT}
                    my={16}
                    width="30%"
                  />
                </Skeleton>
              </td>
            </TableRowWithBorder>
          );
        })}
    </>
  );
};

export const VariantsCard = ({
  menuProduct,
  isLoading,
}: {
  isLoading: boolean;
  menuProduct?: MenuProductForProductsTable;
}) => {
  const { setValue, watch } = useFormContext();

  const variants = useMemo(() => {
    if (!menuProduct) return [];
    return WEIGHT_OPTIONS.reduce<PriceVariant[]>((acc, price_variant) => {
      const priceKey = `price_${price_variant.id}` as keyof MenuProduct;
      if (menuProduct[priceKey as keyof MenuProductForProductsTable]) {
        acc.push({
          ...price_variant,
          price: menuProduct[
            priceKey as keyof MenuProductForProductsTable
          ] as string,
        });
      }
      return acc;
    }, []);
  }, [menuProduct]);

  const unavailablePrices = watch('unavailable_prices_attributes');
  const unavailPriceIndex = useCallback(
    (id: string) => {
      if (!unavailablePrices) return -1;
      return unavailablePrices.findIndex(({ price_id }: any) => {
        return `price_${price_id}` === id;
      });
    },
    [unavailablePrices]
  );

  const onToggle = useCallback(
    (val: string, enabled: boolean) => {
      const modifiedPrices = modifyPrices(unavailablePrices, enabled, val);
      setValue('unavailable_prices_attributes', modifiedPrices, {
        shouldDirty: true,
      });
    },
    [unavailablePrices]
  );

  const isEnabled = useCallback(
    (val: string) => {
      return (
        unavailPriceIndex(val) === -1 ||
        unavailablePrices[unavailPriceIndex(val)]?._destroy
      );
    },
    [unavailPriceIndex, unavailablePrices]
  );

  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <Typography variant="header-bold" mb={40}>
            Variants
          </Typography>
          <table style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <TableHeaderItem label="Weight" />
                <TableHeaderItem label="Price" />
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <LoadingVariants />
              ) : (
                variants.map(({ label, val, price }, index) => {
                  const isLast = index + 1 === variants.length;

                  return (
                    <TableRowWithBorder hasBorder={!isLast} key={val}>
                      <td>
                        <PaddedSwitch
                          defaultChecked={isEnabled(val)}
                          onChange={(enabled: boolean) => {
                            onToggle(val, enabled);
                            track({
                              event: EventNames.ToggleVisibility,
                              final_state: enabled ? 'visible' : 'hidden',
                              object: val,
                              trigger_source_id: 'variant visibility toggle',
                              successful: true,
                            });
                          }}
                          width="100%"
                          name={val}
                          label={label}
                        />
                      </td>
                      <td>
                        <Typography
                          color={isEnabled(val) ? 'grays-black' : 'grays-light'}
                          m={16}
                        >
                          {formatCurrency(price)}
                        </Typography>
                      </td>
                    </TableRowWithBorder>
                  );
                })
              )}
            </tbody>
          </table>
        </Flex>
      </Card.Content>
    </Card>
  );
};

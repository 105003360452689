import { useCallback } from 'react';

import { MODAL_CARD_WIDTH } from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import {
  Button,
  Card,
  Flex,
  Form,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

interface Props {
  isCreateMode: boolean;
  staffMemberPermissions: (string | number)[];
}

export const StaffMemberPermissions = ({
  isCreateMode,
  staffMemberPermissions,
}: Props) => {
  const { setValue } = useFormContext();

  const currentlyAvailablePermissions = Object.values(Permission).filter(
    (permission) => {
      return !['edit_client_role'].includes(permission);
    }
  );

  const formatPermission = (permission: string) => {
    return (
      permission &&
      permission
        .replace(/^edit_sms/g, 'edit_notifications')
        .replace(/^edit_|view_|_/g, ' ')
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (match) => match.toUpperCase())
    );
  };

  const hasPermission = useCallback(
    (permission: string): boolean => {
      return !!staffMemberPermissions?.includes(permission as string);
    },
    [staffMemberPermissions]
  );

  const handleSelectAll = (value: boolean) => {
    Object.values(Permission).forEach((permission: any) =>
      setValue(permission, value, { shouldDirty: true })
    );
  };

  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <Flex justifyContent="space-between" alignItems="center" mb={40}>
            <Typography variant="header-bold">Editing Permissions</Typography>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb={40}
            maxWidth={250}
          >
            <Button
              label="Select all"
              variant="secondary"
              onClick={() => handleSelectAll(true)}
            />
            <Button
              label="Select none"
              variant="secondary"
              onClick={() => handleSelectAll(false)}
            />
          </Flex>
          <Flex flexWrap="wrap">
            {currentlyAvailablePermissions.map((permission, index) => {
              return (
                <Flex
                  key={`${permission}-${index}`}
                  alignItems="center"
                  width="50%"
                  mb={16}
                >
                  <Form.CheckboxField
                    defaultChecked={
                      isCreateMode ? false : hasPermission(permission)
                    }
                    label={formatPermission(permission)}
                    name={permission}
                    mb={12}
                  />
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
};

import type { ReactNode } from 'react';
import React from 'react';

import type { StoreSettingsPayload } from '@jane/business-admin/types';

interface Props {
  children: ReactNode;
  deliveryStrategy: { geofence: boolean; radius: boolean; zipcode: boolean };
  setDeliveryStrategy: any;
  storePayload: StoreSettingsPayload;
}

export const FulfillmentSettingsContext = React.createContext<
  Omit<Props, 'children'>
>({
  storePayload: {} as StoreSettingsPayload,
  deliveryStrategy: { radius: false, zipcode: false, geofence: false },
  setDeliveryStrategy: () => {
    return null;
  },
});

export const FulfillmentSettingsProvider = ({
  children,
  storePayload,
  deliveryStrategy,
  setDeliveryStrategy,
}: Props) => (
  <FulfillmentSettingsContext.Provider
    value={{
      storePayload,
      deliveryStrategy,
      setDeliveryStrategy,
    }}
  >
    {children}
  </FulfillmentSettingsContext.Provider>
);

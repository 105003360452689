import { Flex, Skeleton } from '@jane/shared/reefer';

export const CheckboxSkeleton = () => (
  <Skeleton animate>
    <Skeleton.Bone mt={24} width="23%" />
    <Flex gap={24} mt={24}>
      <Skeleton.Bone width="23%" />
      <Skeleton.Bone width="23%" />
      <Skeleton.Bone width="23%" />
      <Skeleton.Bone width="23%" />
    </Flex>
    <Flex gap={24} mt={24}>
      <Skeleton.Bone width="23%" />
      <Skeleton.Bone width="23%" />
      <Skeleton.Bone width="23%" />
      <Skeleton.Bone width="23%" />
    </Flex>
  </Skeleton>
);

export const FourWideSkeleton = () => (
  <Skeleton animate>
    <Flex gap={24} mt={24}>
      <Skeleton.Bone width="23%" />
      <Skeleton.Bone width="23%" />
      <Skeleton.Bone width="23%" />
      <Skeleton.Bone width="23%" />
    </Flex>
  </Skeleton>
);

export const ThreeWideSkeleton = () => (
  <Skeleton animate>
    <Flex gap={24} mt={24}>
      <Skeleton.Bone width="32%" />
      <Skeleton.Bone width="32%" />
      <Skeleton.Bone width="31%" />
    </Flex>
  </Skeleton>
);

import { z } from 'zod';

const ThoughtspotBucketSchema = z.object({
  name: z.string(),
  id: z.string().optional(),
  category_name: z.string(),
});

export const ThoughtspotLiveboardSchema = z.record(
  z.string(),
  z.array(ThoughtspotBucketSchema).optional()
);

export const ThoughtspotLiveboardsResponseSchema = z.object({
  thoughtspot_user_buckets: z.array(z.string()),
  authorized_liveboards: ThoughtspotLiveboardSchema,
  nav_liveboards: ThoughtspotLiveboardSchema,
});

export type ThoughtspotBucket = z.infer<typeof ThoughtspotBucketSchema>;
export type ThoughtspotLiveboards = z.infer<typeof ThoughtspotLiveboardSchema>;
export type ThoughtspotLiveboardsResponse = z.infer<
  typeof ThoughtspotLiveboardsResponseSchema
>;

export enum ThoughtspotBucketNames {
  DEFAULT_LIVEBOARDS = 'default-liveboards',
  OFF_MENU = 'off-menu',
  PREMIUM_CONTENT = 'premium-content',
}

import styled from '@emotion/styled';

import { Card, Flex, Typography } from '@jane/shared/reefer';
import type { ColorKey } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

export type PillColor = 'grape' | 'ember' | 'sunset' | 'ultralight';

const getColorFromTheme = (theme: any, color: PillColor): string => {
  if (color !== 'ultralight') {
    return theme.colors.brand[color]?.light || '';
  } else {
    return theme.colors.grays[color] || '';
  }
};
// Kind of a pill or button, but with custom colors
export const PillWithCustomColors = styled.div<{
  color: PillColor;
  withBackground: boolean;
}>(({ color, theme, withBackground }) => {
  const backgroundColor = getColorFromTheme(theme, color);
  return {
    ...spacing({ py: 8 }),
    width: 120,
    backgroundColor: withBackground ? backgroundColor : '',
    borderRadius: 40,
    paddingTop: -12,
  };
});

const CardBackgroundColor = styled.div<{
  selected: boolean;
  withBackground: boolean;
}>(({ selected, withBackground }) => ({
  background: selected && withBackground ? 'rgba(94, 31, 240, 0.05)' : 'white',
}));

const CardDisabledWrapper = styled.div<{ disabled: boolean }>(
  ({ disabled }) => ({
    opacity: disabled ? 0.3 : 1,
    width: '100%',
  })
);

export const OptionCardWithPill = ({
  disabled,
  onClick,
  pillColor,
  selected,
  subtitle,
  title,
  withBackground,
}: {
  disabled: boolean;
  onClick: (value: string) => void;
  pillColor: PillColor;
  selected: boolean;
  subtitle: string;
  title: string;
  withBackground: boolean;
}) => {
  return (
    <CardDisabledWrapper disabled={disabled}>
      <Card
        ariaLabel={title}
        onClick={() => (disabled ? null : onClick(title.toLowerCase()))}
        border={selected ? 'purple-dark' : 'grays-light'}
        width="100%"
        flat
      >
        <CardBackgroundColor
          selected={selected}
          withBackground={withBackground}
        >
          <Card.Content background="transparent">
            <Flex p={8} flexDirection="column">
              <Flex>
                <PillWithCustomColors
                  withBackground={withBackground}
                  color={pillColor}
                >
                  <Typography
                    variant="body-bold"
                    color={`${pillColor}-dark` as ColorKey}
                  >
                    {title}
                  </Typography>
                </PillWithCustomColors>
              </Flex>
              <Typography color="grays-mid">{subtitle}</Typography>
            </Flex>
          </Card.Content>
        </CardBackgroundColor>
      </Card>
    </CardDisabledWrapper>
  );
};

import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { Typography } from '@jane/shared/reefer';
import type { TypographyProps } from '@jane/shared/reefer';

export const SideNav = styled.nav({
  width: '15%',
});

export const SideNavHeader = ({
  children,
  color = 'grays-mid',
  mt = 24,
  variant = 'caps',
}: {
  children: ReactNode;
  color?: TypographyProps['color'];
  mt?: TypographyProps['mt'];
  variant?: TypographyProps['variant'];
}) => (
  <Typography variant={variant} color={color} ml={16} mt={mt}>
    {children}
  </Typography>
);

import { GeneralSettingsSection } from './GeneralSettingsSection';
import { GoogleSSOSection } from './GoogleSSOSection';
import { PageContentSection } from './PageContentSection';
import { SeoSection } from './SeoSection';

export const BoostWordpressConfigModalContent = () => {
  return (
    <>
      <GeneralSettingsSection variant="wordpress" />
      <PageContentSection />
      <SeoSection />
      <GoogleSSOSection />
    </>
  );
};

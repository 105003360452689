import { Box, Button, Flex, InfoIcon, Typography } from '@jane/shared/reefer';

export const AnalyticsUpsellScreen = ({
  description,
  title,
}: {
  description: string;
  title: string;
}) => {
  return (
    <Box height="100%" border="grays-light" borderRadius="lg" ml={40}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <InfoIcon size="xl" mb={32} />
        <Typography variant="header-bold" mb={8}>
          {title}
        </Typography>
        <Typography mb={16} px={128} textAlign="center">
          {description}
        </Typography>
        <Button
          href="https://www.iheartjane.com/media#:~:text=Off-Menu%20Advertising"
          label="Get Started"
        />
      </Flex>
    </Box>
  );
};
